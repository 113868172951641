import React, { useContext } from "react";
import embLabels from "../../config/emb-labels-en.json";
import ACTIONS from "./Design_Asset_Actions.json";
import isTouchDevice from "../SE-Component/Tooltip";

import { AssetBlockContext } from "../CustomHooks/ContextApi";
import Tooltip from "../../utils/Tooltip";
import { useSelector } from "react-redux";

const PVAsset = () => {
  const tooltipData = useSelector(state => state?.tooltipData?.tooltipData);
  const {
    CBAssetList,
    siteType,
    PVDetails,
    selectedValues,
    CBAssetListDispatch,
    PVDetailsDispatch,
    assetEditStatusUpdatePv,
    updatePvArrayConfig,
    selectedAssetHandlerPv,
    removePvWithAlert,
    removePv,
    isCopyAllButtonDisabled,
    copyAllPvs,
    arrayMaxPowerFinder,
    defaultCbData,
    clientType,
    handleCbAssetRequired,
    handlePmAssetRequired
  } = useContext(AssetBlockContext);

  const pvArrayChangeHandler = (e) => {
    PVDetailsDispatch({
      type: ACTIONS.CONFIGURE_PV_ARRAY,
      payload: {
        data: e.target,
      },
    });

    assetEditStatusUpdatePv( 
      e,
      true,
      PVDetails.selectedPv,
      PVDetails.selectSubPv
    );
  };

  const onChangePvSubSelectionHandler = (selectSubPv) => {
    PVDetailsDispatch({
      type: ACTIONS.INDIVIDUAL_PV_SELECT,
      payload: {
        selectSubPv: selectSubPv,
        selectedPv: selectedValues.selectedPv,
        // externalId: gsxExternalId?.utilityExternalId
      },
    });
    selectedAssetHandlerPv(selectedValues.selectedPv, selectSubPv);
  };
  const projectTypeValue = JSON.parse(localStorage.getItem("projectType"));

  return (
    <div name="pv" className="col-md-12 p-0">
      <div className="col-md-12 pt-2 pl-0 se-white-background">
        <div className="col-md-12 p-0 pb-2 se-white-background d-flex justify-content-between">
          <span>
          <se-icon
            color="primary"
            className="se-icon icon_button se-life-green"
            size="medium"
          >
            solar_panel1
          </se-icon>
          <span className="ml-2 bold">PhotoVoltaic</span>
          </span>
          <span className="mt-2">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={removePv}
            >
              Remove
            </button>
          </span>
        </div>
      </div>

      <div className=" col-md-12 dropdown-divider"></div>

      <div className="col-md-12">
        <div className="row">
          <label className=" col-md-6 bold mt-1 se-font-14px">
            Configure PV Array
          </label>
          <div className="col-md-6 mt-1 mb-1 text-right">
            <button
              type="button"
              className="btn btn-secondary btn-sm se-btn-primary"
              onClick={updatePvArrayConfig}
            >
              <se-icon size="nano">action_save</se-icon>
              <span>&nbsp;</span>Save
            </button>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="exampleFormControlInput1">
            Name <span className="se-mandatory">*</span>
          </label>
          <input
            type="text"
            autoComplete="none"
            id="exampleFormControlInput1"
            className={"form-control form-control-sm "}
            name="groupName"
            placeholder="Group Name"
            value={PVDetails.pvConfig.groupName}
            onChange={pvArrayChangeHandler}
          />
          <div
            className="invalid-feedback-custom"
            style={
              PVDetails.pvConfig.groupName === "" ? {} : { display: "none" }
            }
          >
            {embLabels.nameRequired}
          </div>
          <div
            className="invalid-feedback-custom"
            style={
              PVDetails.pvConfig.groupName !== "" &&
              !/^[a-zA-Z0-9-_ ]+$/.test(PVDetails.pvConfig.groupName)
                ? {}
                : { display: "none" }
            }
          >
            {embLabels.notallowedAlphanumericValues}
          </div>
        </div>
        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">
            Array Max Power (kWp) <span className="se-mandatory">*</span>
          </label>
          <Tooltip
            position="left"
            iconColor="secondary"
            iconSize="small"
            // information="Sum of all PV inverter max power."
            information = {tooltipData?.arrayMaxPower?.data}
          />

          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput1"
            placeholder="Array Max Power (kWp)"
            maxLength="5"
            name="group_max_power"
            value={Math.abs(PVDetails.pvConfig.group_max_power)}
            onChange={pvArrayChangeHandler}
          />
          <div
            className="invalid-feedback-custom"
            style={
              !(parseInt(PVDetails.pvConfig.group_max_power) >= 0)
                ? {}
                : { display: "none" }
            }
          >
            Array Max Power(kWp) is required.
          </div>
          <div
            className="invalid-feedback-custom"
            style={
              PVDetails.pvConfig.group_max_power === "" ||
              parseInt(PVDetails.pvConfig.group_max_power) <=
                arrayMaxPowerFinder()
                ? { display: "none" }
                : {}
            }
          >
            Array Max Power should not exceed sum of all PV inverter max power.
          </div>
        </div>
          {/* Commenting because for future Int in Daas ANSI */}

        {[embLabels.GRID_CONNECTED, embLabels.GRID_CONNECTED_GSX].includes(
            siteType) && (
          <div className="form-group mb-1">
            {((siteType === embLabels.GRID_CONNECTED &&
                clientType === embLabels.GENERIC_PROJECT &&
                projectTypeValue?.projectTypeValue === "MGaaS IEC")) && (
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input asCompatible"
                    name="pmRequired"
                    id="pmRequiredValidation"
                    onChange={()=>handlePmAssetRequired(selectedValues.selectedPv)}
                    value={
                      CBAssetList?.[selectedValues?.selectedPv]?.uiData?.pmRequired
                    }
                    checked={
                      CBAssetList?.[selectedValues?.selectedPv]?.uiData?.pmRequired
                    }
                  />
                  <label
                    className="custom-control-label line-height-25"
                    htmlFor="pmRequiredValidation"
                  >
                    Enable resource energy monitoring
                  </label>
                  <Tooltip
                    position="left"
                    iconColor="secondary"
                    iconSize="small"
                    information="If the checkbox in not enabled, PM won't be the part of PV"
                  /> 
                </div>
              )}
            {((siteType === embLabels.GRID_CONNECTED &&
                clientType === embLabels.GENERIC_PROJECT &&
                projectTypeValue?.projectTypeValue === "MGaaS IEC")) && (
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input asCompatible"
                    name="cBRequired"
                    id="cBRequiredValidation"
                    onChange={()=>handleCbAssetRequired(selectedValues.selectedPv) }
                    value={
                      CBAssetList[selectedValues?.selectedPv]?.uiData?.cbRequired
                    }
                    checked={
                      CBAssetList[selectedValues?.selectedPv]?.uiData?.cbRequired
                    }
                  />
                  <label
                    className="custom-control-label line-height-25"
                    htmlFor="cBRequiredValidation"
                  >
                    Enable resource connection management
                  </label>
                  <Tooltip
                    position="left"
                    iconColor="secondary"
                    iconSize="small"
                    information="If the checkbox in not enabled, CB won't be the part of PV"
                  />
                </div>
              )}
          </div>
        )} 
        <div className=" col-md-12 dropdown-divider"></div>

        <div className="row form-group mt-2 mb-0">
          <label className=" col-md-6 bold mt-1 se-font-14px">
            Configure PV inverters{" "}
          </label>
          <div className="col-md-6 mt-1 mb-1 text-right">
            <button
              type="button"
              className="btn btn-secondary btn-sm se-btn-primary"
              disabled={
                isCopyAllButtonDisabled(PVDetails.selectedPv) ? "" : "disabled"
              }
              onClick={copyAllPvs}
            >
              <se-icon size="nano">action_saveas</se-icon>
              <span>&nbsp;</span>Copy All
            </button>
          </div>
        </div>
        <div className="form-group mb-1">
          <label
            className={
              parseInt(PVDetails.pvConfiguredCountInCB) ===
              parseInt(PVDetails.pvCountInCB)
                ? "se-life-green"
                : "se-warning"
            }
          >
            <se-icon color="primary" size="nano">
              information_circle
            </se-icon>
            Configured {PVDetails.pvConfiguredCountInCB} PV out of{" "}
            {PVDetails.pvCountInCB} PV{" "}
          </label>
        </div>

        {parseInt(selectedValues.selectedPv) === 3 &&
          PVDetails.pvset1Data.map((data, index) => (
            <div
              className={
                (PVDetails.selectSubPv === index + 1 &&
                PVDetails.displayPvContent
                  ? "selected-asset"
                  : data.status === false
                  ? "unconfigured"
                  : "") + " col-md-12  p-0"
              }
              key={data.name}
            >
              <div className="se-chip col-md-11 text-left d-flex justify-content-between">
               <span>
               <se-icon
                  size="small"
                  color="primary"
                  className="icon-small hydrated"
                >
                  solar_panel1
                </se-icon>
                <span
                data-testid = 'PvSubSelectionHandler'
                  className="align-top line-height-25 pointer d-inline-block displayPvChipName "
                  onClick={() => onChangePvSubSelectionHandler(index + 1)}
                >
                  {data.name}
                </span>
               </span>
               
                <span
                  className="pointer"
                  onClick={() => removePvWithAlert(index + 1)}
                >
                  <se-icon size="small" className="icon-small hydrated">
                    notification_error_plain
                  </se-icon>
                </span>
              </div>
            </div>
          ))}

        {parseInt(selectedValues.selectedPv) === 4 &&
          PVDetails.pvset2Data.map((data, index) => (
            <div
              className={
                (PVDetails.selectSubPv === index + 1 &&
                PVDetails.displayPvContent
                  ? "selected-asset"
                  : data.status === false
                  ? "unconfigured"
                  : "") + " col-md-12  p-0"
              }
              key={data.name}
            >
              <div className="se-chip col-md-11 text-left  d-flex justify-content-between">
               <span>
               <se-icon
                  size="small"
                  color="primary"
                  className="icon-small hydrated"
                >
                  solar_panel1
                </se-icon>
                <span
                  className="align-top line-height-25 pointer d-inline-block displayPvChipName "
                  onClick={() => onChangePvSubSelectionHandler(index + 1)}
                >
                  {data.name}
                </span>
               </span>
                <span
                  className="pointer"
                  onClick={() => removePvWithAlert(index + 1)}
                >
                  <se-icon size="small" className="icon-small hydrated">
                    notification_error_plain
                  </se-icon>
                </span>
              </div>
            </div>
          ))}

        {parseInt(selectedValues.selectedPv) === 5 &&
          PVDetails.pvset3Data.map((data, index) => (
            <div
              className={
                (PVDetails.selectSubPv === index + 1 &&
                PVDetails.displayPvContent
                  ? "selected-asset"
                  : data.status === false
                  ? "unconfigured"
                  : "") + " col-md-12  p-0"
              }
              key={data.name}
            >
              <div className="se-chip col-md-11 text-left d-flex justify-content-between">
                <span>
                <se-icon
                  size="small"
                  color="primary"
                  className="icon-small hydrated"
                >
                  solar_panel1
                </se-icon>
                <span
                  className="align-top line-height-25 pointer d-inline-block displayPvChipName "
                  onClick={() => onChangePvSubSelectionHandler(index + 1)}
                >
                  {data.name}
                </span>
                </span>
                <span
                  className="pointer"
                  onClick={() => removePvWithAlert(index + 1)}
                >
                  <se-icon size="small" className="icon-small hydrated">
                    notification_error_plain
                  </se-icon>
                </span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PVAsset;
