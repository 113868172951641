
import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";
import useCBMouserOver from "../../pages/CustomHooks/useCBMouserOver";
import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";

const Linebar = () => {
  const {
    CBAssetList: [
      { uiData },
      ,
      { uiData: existingUiData },
      { uiData: existingPvUiData },
    ],
    assetDetailsReset,
    UserScope,
    callSnackbar,
    CBAssetListDispatch,
    selectedValuesHandler,
    detailsBlock,
    loadSide,
    newSBCbConfigDataHandler,
  } = useContext(DesignAssetContext);
  const [mouseOverData, CBMouseHandler] = useCBMouserOver();

  const utilityCBSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      // const assetBlock = "cbAssetDetailsBlock";
      const assetBlock = "newSBCbAssetDetailsBlock";
      const selectedAssetValue = "selectedAssetCB";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_CB_SELECT,
        payload: {
          index: 0,
        },
      });
      selectedValuesHandler(0, selectedAssetValue, assetBlock);
      // cbConfigDataHandler(4);
      newSBCbConfigDataHandler(0);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  return (
    <svg>
      <g id="linebar">
        <g id="lines">
          {loadSide === "LOAD_SIDE" && (
            <line
              x1="350"
              y1="332"
              x2="350"
              y2="280"
              stroke="#333333"
              strokeWidth="3"
            />
          )}
          <line
            x1={
              loadSide === "LOAD_SIDE"
                ? "350"
                : existingPvUiData.existingPmRequired
                ? "410"
                : "250"
            }
            y1={loadSide === "LOAD_SIDE" ? "330" : "215"}
            x2="545.5"
            y2={loadSide === "LOAD_SIDE" ? "330" : "215"}
            stroke="#333333"
            strokeWidth="3"
          />
          <line
            x1="545"
            y1={loadSide === "LOAD_SIDE" ? "328" : "214"}
            x2="545"
            y2={loadSide === "LOAD_SIDE" ? "370" : "330"}
            stroke="#333333"
            stroke-width="3"
          ></line>
          {uiData.cbRequired ? (
            <g id="CB3">
              <svg
                width="75px"
                height="100px"
                viewBox="0 0 10 149"
                x={"510"}
                y={loadSide === "LOAD_SIDE" ? "370" : "330"}
                // onClick={utilityCBSelectHandler}
                className="pointer"
              >
                <g
                  id="Circuit Breaker"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Circuit Breaker 1"
                    transform="translate(-651.000000, -512.000000)"
                  >
                    <g
                      id="Circuit Breaker - closed"
                      transform="translate(604.000000, 514.000000)"
                      // onMouseOver={CBMouseHandler}
                      // onMouseOut={CBMouseHandler}
                    >
                      <g
                        id="Group-15"
                        strokeWidth="4"
                        fillRule="evenodd"
                        fill={
                          uiData.CBSelected
                            ? "rgba(65, 181, 230, 0.35)"
                            : mouseOverData
                            ? "rgba(65, 181, 230, 0.35)"
                            : "none"
                        }
                        stroke={uiData.CBSelected ? "#42B4E6" : "none"}
                      >
                        {/* <rect
                          id="Rectangle-Copy-20"
                          x="0"
                          y="12"
                          width="96"
                          height="112"
                        ></rect> */}
                        <g
                          id="Group-2"
                          transform="translate(48.000000, 0.000000)"
                          stroke={"#333333" }
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="4"
                        >
                          <line
                            x1="0.5"
                            y1="0"
                            x2="0.5"
                            y2="47.8298611"
                            id="Line-2"
                          ></line>
                        </g>
                      </g>
                      <line
                        x1="48.5"
                        y1="97.1701389"
                        x2="48.501111"
                        y2="145"
                        id="Line-2-Copy-15"
                        stroke={"#333333" }
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></line>
                      <path
                        d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                        id="Path-11"
                        strokeWidth="4"
                        strokeLinecap="round"
                        stroke={ "#333333" }
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </g>
          ) : (
            <line
              x1="545"
              y1={loadSide === "LOAD_SIDE" ? "358" : "320"}
              x2="545"
              y2={loadSide === "LOAD_SIDE" ? "470" : "430"}
              id="Line-2"
              strokeWidth="3"
              strokeDasharray="4 8"
              strokeLinecap="round"
              stroke="#333333"
            ></line>
          )}
        </g>
      </g>
    </svg>
  );
};

export default Linebar;