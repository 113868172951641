import React, { useContext } from "react";
import sld from "../../images/sld/Rectangle.svg";
import Ecc from "../../components/PVHYBRIDIZATION/Ecc";
import Busbar from "../../components/ReusableSvg/Busbar";
import Utility from "../../components/GRIDCONNECTED/Utility";
import EV from "../../components/GRIDCONNECTED/EV";
import PV2 from "../../components/GRIDCONNECTED/PV2";
import PV3 from "../../components/GRIDCONNECTED/PV3";

import Load from "../../components/GRIDCONNECTED/Load";
import PV from "../../components/ISLANDABLE/PV1";
// import Battery from "../../components/ISLANDABLE/Battery";
import Battery from "../../components/PvBessNr/Battery";
import embLabels from "../../config/emb-labels-en.json";
import { DesignAssetContext } from "../CustomHooks/ContextApi";
import Linebar from "../../components/GRIDCONNECTED/Linebar";

const GridConnectedRosette = () => {
  const { CBAssetList, CBLoadList, CBEVList, sldLoadCompleted,switchboardType, siteType } =
    useContext(DesignAssetContext);
  return (
    <div
      id="SLD-Image"
      className="col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0"
    >
        <svg viewBox="0 0 1300 800">
          <g>
            <svg x="0" y="0" viewBox="0 0 1300 800">
            <g id="grid">
              <svg>
                <image href={sld} x="0" y="0" />
              </svg>
            </g>

            <g
              id="components-sld"
              style={sldLoadCompleted ? {} : { display: "none" }}
            >
              <Ecc />
             { switchboardType === embLabels?.EXISTING_SWITCHBOARD && siteType === "GRID_CONNECTED" && <Linebar />}
              <Busbar secondBusbar={switchboardType === embLabels?.EXISTING_SWITCHBOARD && siteType === "GRID_CONNECTED"?true:false} />
              <Utility />
              {CBAssetList[2]?.uiData?.status && <Battery />}
              {CBAssetList[3]?.uiData?.status && <PV />}
              {CBAssetList[4]?.uiData?.status && <PV2 />}
              {CBAssetList[5]?.uiData?.status && <PV3 />}


              {CBLoadList.map((loadData, key) => (
                <Load
                  key={loadData?.uiData?.xAxis}
                  load={loadData?.uiData}
                  loadName={loadData?.configData?.name}
                  index={key}
                />
              ))}
              {CBEVList.map(
                (cbData, key) =>
                  cbData?.uiData?.status && (
                    <EV
                      key={cbData?.configData?.name}
                      index={key}
                      status={cbData?.uiData?.status}
                    />
                  )
              )}
            </g>
          </svg>
        </g>
      </svg>
    </div>
  );
};

export default GridConnectedRosette;
