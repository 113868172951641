import React from "react";

const MoxaTableHeading = () => {
  return (
    <table className="table table-bordered mb-0">
      <thead>
        <tr className="text-center">
          <th width="8%">Port</th>
          <th width="8%">Enable</th>
          <th width="10%">Member Type <span className="se-mandatory">*</span>
          </th>
          <th width="12%"> Access Member <span className="se-mandatory">*</span>
          </th>
          <th width="12%">Trunk Member</th>
          
        </tr>
      </thead>
    </table>
  );
};

export default MoxaTableHeading;
