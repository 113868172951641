import React, { useState, useRef } from "react";
import ProjectsAccordion from "../../components/Accordions/ProjectsAccordion";

const PlcAndHmiPackage = ({
    projectList,
    UserScope,
    hmiTagNew,
    plcTagNew,
    plcTagNewA2E,
    protectionKeyList,
    selectedFileHMI,
    selectedFilePLC,
    selectedFilePLCA2E,
    loading,
    fileVersion,
    fileChangedHandlerHMI,
    fileChangedHandlerPLC,
    fileChangedHandlerPLCA2E,
    versionUpdatePLCA2E,
    uploadPlcFileA2E,
    uploadHmiFile,
    uploadPlcFile,
    updateHmiVersion,
    versionUpdateHMI,
    updatePLCVersion,
    versionUpdatePLC,
    openEditModalPlc,
    openEditModalHmi,
    openEditModalA2E,
    openHmiEditModal,
    openPlcEditModal,
    closePlcEditModal,
    closeHmiEditModal,
    openPlcA2EEditModal,
    closePlcA2EEditModal,
}) => {
  const [hmiData, setHmiData] = useState({});
 
  const [plcData, setPlcData] = useState({});
  const [plcDataA2E, setPlcDataA2E] = useState({});
  const inputRefPlc = useRef(null);
   const inputRefHmi = useRef(null);
  const inputRefA2E = useRef(null);

  const isTouchDeviceA2E = () =>
    "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
      ? true
      : false;
  const isTouchDevice = () =>
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
      ? true
      : false;

  const closeModalHmi = () => {
    closeHmiEditModal();
    inputRefHmi.current.value = null;
  };
  const closeModalPlc = () => {
    closePlcEditModal();
    inputRefPlc.current.value = null;
  };
    const closeModalA2E = () => {
    closePlcA2EEditModal();
    inputRefA2E.current.value = null;
  };
 
   const handleChangePlcA2eValue = (e) => {
    setPlcDataA2E({
      ...plcDataA2E,
      value: e.target.value,
    });
  };
  const handleOpenEditModal = (plcType, data) => {
    if(plcType === "hmiVersion"){
        openHmiEditModal();
        setHmiData({
            ...data,
          });
    }
    if(plcType === "plcVersion"){
        openPlcEditModal();
    setPlcData({
      ...data,
    });
    }
    if(plcType === "a2ePlcVersion"){
      openPlcA2EEditModal();
      setPlcDataA2E({
        ...data,
      });
    }
  };

  const handleChangeHmiValue = (e) => {
    setHmiData({
      ...hmiData,
      value: e.target.value,
    });
  };
  
  const handleChangePlcValue = (e) => {
    setPlcData({
      ...plcData,
      value: e.target.value,
    });
  };
  const updatePlcFileandValueA2E = (value) => {
    return selectedFilePLCA2E !== null ? uploadPlcFileA2E(value) : undefined;
  };
  const updatePlcFileandValue = (value) => {
    return selectedFilePLC !== null ? uploadPlcFile(value) : undefined;
  };
  const updateHmiFileandValue = (value) => {
    return selectedFileHMI !== null ? uploadHmiFile(value) : undefined;
  };
  let pakagesData = {};
  protectionKeyList.forEach((p) => {
    let attr = p.projectType;
    if(attr !== null){
        if (!pakagesData[attr]) {
            pakagesData[attr] = [];
        }
        if (!pakagesData[attr][p.version]) {
            pakagesData[attr][p.version] = [];
        }
        pakagesData[attr][p.version].push(p);
    }
  });
  return (
    <ProjectsAccordion label="Upload PLC and HMI Packages">
      {Object.keys(pakagesData).sort().map((projectType, index) => {
        if (pakagesData[projectType] !== null) {
          return (
            <ProjectsAccordion key={index} label={projectType}>
              {Object.keys(pakagesData[projectType]).map((version,idx) => (
                <ProjectsAccordion key={idx} label={`MG FLEX Version ${version}`}>
                  <table className="table table-bordered mb-0">
                    <thead>
                      <tr>
                        <th>Package Type</th>
                        <th>Modified On</th>
                        <th>Modified By</th>
                        <th>File Name</th>
                        <th> EcoStruxure Machine Expert version</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pakagesData[projectType][version].map((versionList) => (
                        <tr key={versionList.id}>
                          <td>
                            {versionList.attribute === "plcVersion" || versionList.attribute === "a2ePlcVersion"
                              ? "M251 PLC Package"
                              : " Vijeo HMI Package"}
                          </td>
                          <td>{versionList.modifiedOn}</td>
                          <td>{versionList.modifiedBy}</td>
                          <td>{versionList.tag}</td>
                          <td>{versionList.value}</td>
                          <td>
                            <se-icon
                              onClick={() => handleOpenEditModal(versionList.attribute,versionList)}
                              size="small"
                              color="secondary"
                              className="se-life-green"
                            >
                              action_editor
                            </se-icon>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ProjectsAccordion>
              ))}
            </ProjectsAccordion>
          );
        }
      })}
      <se-dialog
        id="dialog-complex-delete-confirm"
        open={openEditModalHmi}
        can-backdrop="false"
      >
        <se-dialog-header>Edit PLC Project</se-dialog-header>
        <se-dialog-content
          className="se-dialog-content "
          style={{ minHeight: "150px" }}
        >
          {loading ? (
            <se-loading className="mt-3" loading={loading}></se-loading>
          ) : (
            <>
              <div className="row mt-1">
                <div className="col-md-12">
                  <div className="form-group row">
                    <label className="col-md-4 my-auto col-form-label">
                      PLC Package version
                    </label>
                    <div className="col-md-8 my-auto">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="plcFileVersion"
                        value={hmiData.version}
                        maxLength="25"
                        readOnly
                        // onChange={}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-1">
                {protectionKeyList.length > 0 && (
                  <div className="col-md-12">
                    <div className="form-group row">
                      <label className="col-md-4 my-auto col-form-label">
                        Vijeo SCADA version
                      </label>
                      <div className="col-md-8 my-auto">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="hmiFileVersion"
                          value={hmiData.value}
                          maxLength="25"
                          onChange={handleChangeHmiValue}
                        />
                      </div>
                      {/* <div className="col-md-3 my-auto pointer">
                <se-icon
                  size="medium"
                  color="primary"
                  title="Click to update"
                  onClick={
                    fileVersion.hmiFileVersion !== ""
                      ? versionUpdateHMI
                      : undefined
                  }
                  disabled={fileVersion.hmiFileVersion === "" ? true : false}
                >
                  notification_ok_stroke
                </se-icon>
              </div> */}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </se-dialog-content>

        {!loading && (
          <se-dialog-footer className="position-relative ">
            <div className="ml-0 position-absolute">
              <input
                type="file"
                onChange={fileChangedHandlerHMI}
                className="form-control-file form-control-sm fileNameDisplay"
                name="file"
                ref={inputRefHmi}
              />
            </div>

            <div className="ml-0 " style={{ marginRight: "0.3rem" }}>
              <se-button onClick={closeModalHmi}>Cancel</se-button>
              {UserScope.access.uploadFile && (
                <se-button
                  option="flat"
                  color="primary"
                  disabled={selectedFileHMI === null ? true : false}
                  onClick={() => updateHmiFileandValue(hmiData)}
                >
                  HMI Upload
                </se-button>
              )}
            </div>
          </se-dialog-footer>
        )}
      </se-dialog>
      <se-dialog
        id="dialog-complex-delete-confirm"
        open={openEditModalPlc}
        can-backdrop="false"
      >
        <se-dialog-header>Edit PLC Project</se-dialog-header>

        <>
          <se-dialog-content
            className="se-dialog-content "
            style={{ minHeight: "150px" }}
          >
            {loading ? (
              <se-loading className="mt-3" loading={loading}></se-loading>
            ) : (
              <>
                <div className="row mt-1">
                  <div className="col-md-12">
                    <div className="form-group row">
                      <label className="col-md-4 my-auto col-form-label">
                        PLC Package version
                      </label>
                      <div className="col-md-8 my-auto">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="plcFileVersion"
                          value={plcData.version}
                          maxLength="25"
                          readOnly
                        // onChange={}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  {protectionKeyList.length > 0 && (
                    <div className="col-md-12">
                      <div className="form-group row">
                        <label className="col-md-4 my-auto col-form-label">
                          EcoStruxure Machine Expert version
                        </label>
                        <div className="col-md-8 my-auto">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="plcValue"
                            value={plcData.value}
                            maxLength="25"
                            onChange={handleChangePlcValue}
                          />
                        </div>
                        {/* <div className="col-md-3 my-auto pointer">
                    <se-icon
                      size="medium"
                      color="primary"
                      title="Click to update"
                      onClick={versionUpdatePLC}
                    >
                      notification_ok_stroke
                    </se-icon>
                  </div> */}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </se-dialog-content>

          {!loading && (
            <se-dialog-footer className="position-relative">
              <div className="ml-0 position-absolute ">
                <input
                  type="file"
                  accept="application/zip"
                  onChange={fileChangedHandlerPLC}
                  className="form-control-file form-control-sm fileNameDisplay"
                  name="file"
                  ref={inputRefPlc}
                />
              </div>

              <div className="ml-0 " style={{ marginRight: "0.3rem" }}>
                <se-button onClick={closeModalPlc}>Cancel</se-button>
                {UserScope.access.uploadFile && (
                  <se-button
                    option="flat"
                    color="primary"
                    disabled={
                      selectedFilePLC === null || loading ? true : false
                    }
                    onClick={() => updatePlcFileandValue(plcData)}
                  >
                    PLC Upload
                  </se-button>
                )}
              </div>
            </se-dialog-footer>
          )}
        </>
      </se-dialog>
      <se-dialog
        id="dialog-complex-delete-confirm"
        open={openEditModalA2E}
        can-backdrop="false"
      >
        <se-dialog-header>Edit PLC Project</se-dialog-header>
        <se-dialog-content
          className="se-dialog-content "
          style={{ minHeight: "150px" }}
        >
          {loading ? (
            <se-loading className="mt-3" loading={loading}></se-loading>
          ) : (
            <>
              <div className="row mt-1">
                <div className="col-md-12">
                  <div className="form-group row">
                    <label className="col-md-4 my-auto col-form-label">
                      PLC Package version
                    </label>
                    <div className="col-md-8 my-auto">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="plcFileVersion"
                        value={plcDataA2E.version}
                        maxLength="25"
                        readOnly
                      // onChange={}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-1">
                {protectionKeyList.length > 0 && (
                  <div className="col-md-12">
                    <div className="form-group row">
                      <label className="col-md-4 my-auto col-form-label">
                        EcoStruxure Machine Expert version
                      </label>
                      <div className="col-md-8 my-auto">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="plcFileVersionA2E"
                          value={plcDataA2E.value}
                          maxLength="25"
                          onChange={handleChangePlcA2eValue}
                        />
                      </div>
                      {/* <div className="col-md-3 my-auto pointer">
                <se-icon
                  size="medium"
                  color="primary"
                  title="Click to update"
                  onClick={
                    fileVersion.plcFileVersionA2E !== ""
                      ? versionUpdatePLC
                      : undefined
                  }
                  disabled={fileVersion.plcFileVersionA2E === "" ? true : false}
                >
                  notification_ok_stroke
                </se-icon>
              </div> */}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </se-dialog-content>

        {!loading && (
          <se-dialog-footer className="position-relative">
            {/* <div className="row justify-content-md-between"> */}
            <div className="ml-0 position-absolute ">
              <input
                type="file"
                accept="application/zip"
                onChange={fileChangedHandlerPLCA2E}
                className="form-control-file form-control-sm fileNameDisplay"
                name="file"
                ref={inputRefA2E}
              />
            </div>

            <div className="ml-0" style={{ marginRight: "0.3rem" }}>
              <se-button onClick={closeModalA2E}>Cancel</se-button>
              {UserScope.access.uploadFile && (
                <se-button
                  option="flat"
                  color="primary"
                  disabled={
                    selectedFilePLCA2E === null || loading ? true : false
                  }
                  onClick={() => updatePlcFileandValueA2E(plcDataA2E)}
                >
                  PLC Upload
                </se-button>
              )}
            </div>
            {/* </div> */}
          </se-dialog-footer>
        )}
      </se-dialog>
    </ProjectsAccordion>
  );
};

export default PlcAndHmiPackage;

