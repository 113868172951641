import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";
import MyErrorBoundary from "../../utils/ErrorBoundary";
import useCBMouserOver from "../../pages/CustomHooks/useCBMouserOver";

const Utility = () => {
  const {
    CBAssetList: [{ uiData, configData, powerMeterData }],
    UserScope,
    assetDetailsDisplay,
    CBAssetListDispatch,
    assetDetailsReset,
    callSnackbar,
    selectedValuesHandler,
    assetConfigDataHandler,
    setAssetDetailsDisplay,
    pmConfigDataHandler,
    switchboardType,
    newSBCbConfigDataHandler
  } = useContext(DesignAssetContext);
  const [mouseOverData, CBMouseHandler] = useCBMouserOver();
  const utilitySelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "assetUtilityDetailsBlock";
      const selectedAssetValue = "selectedAsset";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_SELECT,
        payload: {
          index: 0,
          assetDetailsDisplay,
          setAssetDetailsDisplay,
        },
      });
      selectedValuesHandler(0, selectedAssetValue, assetBlock);
      assetConfigDataHandler(0);
    } else {
      callSnackbar(
        "error",
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };
  const utilityPMSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "pmAssetDetailsBlockGrid";
      const selectedAssetValue = "selectedAssetPM";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_PM_SELECT,
        payload: {
          index: 0,
        },
      });
      selectedValuesHandler(0, selectedAssetValue, assetBlock);
      pmConfigDataHandler(0);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };
  const utilityCBSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      // const assetBlock = "cbAssetDetailsBlock";
      const assetBlock = "newSBCbAssetDetailsBlock";
      const selectedAssetValue = "selectedAssetCB";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_CB_SELECT,
        payload: {
          index: 0,
        },
      });
      selectedValuesHandler(0, selectedAssetValue, assetBlock);
      // cbConfigDataHandler(4);
      newSBCbConfigDataHandler(0);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  return (
    <MyErrorBoundary>
  
    <>
    { switchboardType === "NEW_SWITCHBOARD"?
      <svg className="pointer">
      <g id="utility">
        <svg
          x={uiData.xAxis}
          y="141"
          width="195px"
          height="130px"
          viewBox="0 0 10 149"
          onClick={utilitySelectHandler}
        >
          <title>{configData.name}</title>
          <g
            id="Design"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Design/Assets-_-00"
              transform="translate(-604.000000, -372.000000)"
            >
              <g
                id="SLD/Production/Uniline/Undefined"
                transform="translate(604.000000, 372.000000)"
              >
                <g id="SLD/Production/Uniline/Tobedefine">
                  <g id="Group-7">
                    <rect
                      id="Rectangle"
                      x="0"
                      y="0"
                      width="96"
                      height="144"
                    ></rect>
                    <line
                      x1="48.5"
                      y1="96"
                      x2="48.5"
                      y2="144"
                      id="Line-2"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      stroke={uiData.configured ? "#333" : "#E47F00"}
                    ></line>
                    <circle
                      id="Oval"
                      style={uiData.selected ? {} : { display: "none" }}
                      stroke="#42B4E6"
                      strokeWidth="3"
                      cx="48"
                      cy="56"
                      r="55"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="rgba(0, 135, 205, 0.2)"
                    ></circle>
                    <circle
                      id="Oval"
                      strokeWidth="3"
                      fill="#FFFFFF"
                      cx="48"
                      cy="56"
                      r="46.5"
                      stroke={uiData.configured ? "#333" : "#E47F00"}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></circle>
                    <text
                      id="segment_energy_infra"
                      fontFamily="se-icon"
                      fontSize="56"
                      fontWeight="normal"
                      fill="#333333"
                    >
                      <tspan x="20" y="76">
                        segment_energy_infrastructure
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </g>
      <g id="power meter">
        <svg
          x="375"
          y="127.5"
          width="300px"
          height="140px"
          viewBox="0 0 100 160"
          onClick={utilityPMSelectHandler}
        >
          <title>{powerMeterData.name}</title>
          <line
            x1="30"
            y1="127"
            x2="80"
            y2="127"
            stroke={uiData.pmConfiguredGrid ? "#333" : "#E47F00"}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle
            id="Pm outer"
            stroke="#42B4E6"
            strokeWidth="3"
            cx="100"
            cy="127"
            r="26"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="rgba(0, 135, 205, 0.2)"
            style={uiData.pmSelected ? {} : { display: "none" }}
          ></circle>
          <circle
            id="pm inner"
            stroke={uiData.pmConfiguredGrid ? "#333" : "#E47F00"}
            strokeWidth="3"
            cx="100"
            cy="127"
            r="18.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="#FFFFFF"
          ></circle>
          <text
            id="PM"
            fontSize="16"
            fontWeight="normal"
            stroke="#333"
            fontFamily="Nunito-Regular, Nunito"
            fill="#333"
          >
            <tspan x="88" y="132">
              PM
            </tspan>
          </text>
        </svg>
      </g>
      {/* <g id="CB1">
        <line
          x1="505.5"
          y1="368"
          x2="505.5"
          y2="269"
          id="Line-2"
          strokeWidth="3"
          strokeDasharray="4 8"
          strokeLinecap="round"
          stroke="#333333"
        // strokeLinejoin="round"
        ></line>
      </g> */}

      <g id="CB3">
            <svg
              width="75px"
              height="100px"
              viewBox="0 0 10 149"
              x={"470.5"}
              y={"270"}
              onClick={utilityCBSelectHandler}
              className="pointer"
            >
              <g id="Circuit Breaker" fill="none" fillRule="evenodd">
                <g
                  id="Circuit Breaker 1"
                  transform="translate(-651.000000, -512.000000)"
                  onMouseOver={CBMouseHandler}
                  onMouseOut={CBMouseHandler}
                >
                  <g
                    id="Circuit Breaker - closed"
                    transform="translate(604.000000, 514.000000)"
                    fill={
                      uiData.CBSelected
                        ? "rgba(65, 181, 230, 0.35)"
                        : mouseOverData
                        ? "rgba(65, 181, 230, 0.35)"
                        : "none"
                    }
                    stroke={uiData.CBSelected ? "#42B4E6" : "none"}
                  >
                    <g id="Group-15" strokeWidth="4" fillRule="evenodd">
                      <rect
                        id="Rectangle-Copy-20"
                        x="0"
                        y="12"
                        width="96"
                        height="122"
                      ></rect>
                      <g
                        id="Group-2"
                        transform="translate(48.000000, 0.000000)"
                        stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="4"
                      >
                        <line
                          x1="0.5"
                          y1="0"
                          x2="0.5"
                          y2="47.8298611"
                          id="Line-2"
                        ></line>
                      </g>
                    </g>
                    <line
                      x1="48.5"
                      y1="97.1701389"
                      x2="48.501111"
                      y2="145"
                      id="Line-2-Copy-15"
                      stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></line>
                    <path
                      d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                      id="Path-11"
                      stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                      strokeWidth="4"
                      strokeLinecap="round"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
          </g>
    </svg>
    :    <svg className="pointer">
    <g id="utility">
      <svg
        x="100"
        y="41"
        width="195px"
        height="130px"
        viewBox="0 0 10 149"
        onClick={utilitySelectHandler}
      >
        <title>{configData.name}</title>
        <g
          id="Design"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Design/Assets-_-00"
            transform="translate(-604.000000, -372.000000)"
          >
            <g
              id="SLD/Production/Uniline/Undefined"
              transform="translate(604.000000, 372.000000)"
            >
              <g id="SLD/Production/Uniline/Tobedefine">
                <g id="Group-7">
                  <rect
                    id="Rectangle"
                    x="0"
                    y="0"
                    width="96"
                    height="144"
                  ></rect>
                  <line
                    x1="48.5"
                    y1="96"
                    x2="48.5"
                    y2="144"
                    id="Line-2"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    stroke={uiData.configured ? "#333" : "#E47F00"}
                  ></line>
                  <circle
                    id="Oval"
                    style={uiData.selected ? {} : { display: "none" }}
                    stroke="#42B4E6"
                    strokeWidth="3"
                    cx="48"
                    cy="56"
                    r="55"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="rgba(0, 135, 205, 0.2)"
                  ></circle>
                  <circle
                    id="Oval"
                    strokeWidth="3"
                    fill="#FFFFFF"
                    cx="48"
                    cy="56"
                    r="46.5"
                    stroke={uiData.configured ? "#333" : "#E47F00"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></circle>
                  <text
                    id="segment_energy_infra"
                    fontFamily="se-icon"
                    fontSize="56"
                    fontWeight="normal"
                    fill="#333333"
                  >
                    <tspan x="20" y="76">
                      segment_energy_infrastructure
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </g>
    <g id="power meter">
      <svg
        x="105"
        y="33"
        width="300px"
        height="140px"
        viewBox="0 0 100 160"
        onClick={utilityPMSelectHandler}
      >
        <title>{powerMeterData.name}</title>
        <line
          x1="30"
          y1="127"
          x2="80"
          y2="127"
          stroke={uiData.pmConfiguredGrid ? "#333" : "#E47F00"}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          id="Pm outer"
          stroke="#42B4E6"
          strokeWidth="3"
          cx="100"
          cy="127"
          r="26"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="rgba(0, 135, 205, 0.2)"
          style={uiData.pmSelected ? {} : { display: "none" }}
        ></circle>
        <circle
          id="pm inner"
          stroke={uiData.pmConfiguredGrid ? "#333" : "#E47F00"}
          strokeWidth="3"
          cx="100"
          cy="127"
          r="18.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="#FFFFFF"
        ></circle>
        <text
          id="PM"
          fontSize="16"
          fontWeight="normal"
          stroke="#333"
          fontFamily="Nunito-Regular, Nunito"
          fill="#333"
        >
          <tspan x="88" y="132">
            PM
          </tspan>
        </text>
      </svg>
    </g>
    <g id="CB1">
      <line
        x1="235.5"
        y1="278"
        x2="235.5"
        y2="168"
        id="Line-2"
        strokeWidth="3"
        strokeDasharray="4 8"
        strokeLinecap="round"
        stroke="#333333"
      // strokeLinejoin="round"
      ></line>
    </g>
  </svg>
    }
    </> 
</MyErrorBoundary>
  );
};

export default Utility;
