import React, { useContext } from "react";

import Accordion from "../../components/Accordions/Accordion";
import AutoGrid from "./AutoGrid";

import { DesignProgramContext } from "../CustomHooks/ContextApi";

import embLabels from "../../config/emb-labels-en.json";

const OptimizationEngine = () => {
  const { optimizationServer, clientType, configuredStatus, siteCreated,
    projectStatusData,UserScope } =
    useContext(DesignProgramContext);
  return (
    <>
    {
    (  clientType === embLabels.GREENSTRUXURE_PROJECT &&
      optimizationServer.optimizationType === "AUTOGRID") &&
      <div className={`row se-white-background pb-2 ${
        siteCreated || projectStatusData?.projectStatus === "COMPLETED"
          ? "se-block-disabled"
          : !UserScope.access.saveProgram
          ? "se-block-disabled"
          : ""
      }`}>
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-2 ">
        <span className="se-font-14px-bold se-life-green ">
          Optimization Engine
        </span>

        <div className="row ">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            {clientType === embLabels.GREENSTRUXURE_PROJECT &&
            optimizationServer.optimizationType === "AUTOGRID" ? (
              <Accordion allowMultipleOpen>
                <div
                  className="col-md-12"
                  label="Autogrid Flex &trade;"
                  status={configuredStatus.autogridConfiguredStatus}
                  displayStatus={
                    optimizationServer.optimizationType === "AUTOGRID"
                      ? "1"
                      : "0"
                  }
                  icon="notification_error_full_stroke"
                >
                  <AutoGrid />
                </div>

                <div
                  className="col-md-12"
                  label="dummy offgrid"
                  status={1}
                  displayStatus="0"
                  icon="notification_error_full_stroke"
                ></div>
              </Accordion>
            ) : (
              <div className="row">
                <label className="col-md-7 col-form-label pl-5">
                  No optimization Engine usecase
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    }
    </>
   
  );
};

export default OptimizationEngine;
