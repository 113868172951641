import React, { useContext } from "react";

import sld from "../../images/sld/Rectangle.svg";

import { DesignAssetContext } from "../CustomHooks/ContextApi";
import Ecc from "../../components/PvGensetAts/Ecc";
import Busbar from "../../components/PvBessNr/Busbar";
import Linebar from "../../components/PvBessNr/LineBar";
import Utility from "../../components/PvBessNr/Utility";
import Battery from "../../components/PvBessNr/Battery";
import EV from "../../components/GRIDCONNECTED/EV";
import PVExsisting from "../../components/PvBessNr/PVExsisting";
import BatteryExsisting from "../../components/PvBessNr/BatteryExsisting";

import PV1 from "../../components/PvGensetAts/PV1";
import PV2 from "../../components/PvGensetAts/PV2";

import Load from "../../components/PvBessNr/Load";

import embLabels from "../../config/emb-labels-en.json";
import PV3 from "../../components/PvGensetAts/PV3";
import { compose } from "@reduxjs/toolkit";

const PvBessNrSLD = () => {
  const {
    CBAssetList,
    sldLoadCompleted,
    siteType,
    architectureType,
    switchboardType,
    CBEVList,
  } = useContext(DesignAssetContext);


  return (
    <div
      id="SLD-Image"
      className="col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0"
    >
      {["GRID_CONNECTED_GSX", "GRID_CONNECTED"].includes(siteType) &&
      (switchboardType === "" ||
        switchboardType === embLabels.EXISTING_SWITCHBOARD) ? (
        <svg viewBox="0 0 1300 800">
          <g>
            <svg x="0" y="0" viewBox="0 0 1300 800">
              <g>
                <g id="grid">
                  <svg>
                    <image href={sld} x="0" y="0" />
                  </svg>
                </g>

                <g>
                  <svg viewBox="-75 -10 1300 800">
                    <g
                      id="components-sld"
                      style={sldLoadCompleted ? {} : { display: "none" }}
                    >
                      <Ecc />
                      <Busbar secondBusbar={true} />
                      <Linebar />
                      <Utility />
                      <Battery />
                      {CBAssetList[3].uiData.existingPmRequired && (
                        <PVExsisting />
                      )}
                      {CBAssetList[2].uiData.existingPmRequired && (
                        <BatteryExsisting />
                      )}
                      {CBAssetList[3].uiData.status && <PV1 />}
                      {CBAssetList[4].uiData.status && <PV2 />}
                      {CBAssetList[5].uiData.status && <PV3 />}
                      <Load />
                      {CBEVList.map(
                      (cbData, key) =>
                      cbData?.uiData?.status && (
                      <EV
                      key={cbData?.configData?.name}
                      index={key}
                      status={cbData?.uiData?.status}
                    />
                  )
              )}
                    </g>
                  </svg>
                </g>
              </g>
            </svg>
          </g>
        </svg>
      ) : (
        <svg viewBox="0 0 1300 800">
          <g>
            <svg x="0" y="0" viewBox="0 0 1300 800">
              <g id="grid">
                <svg>
                  <image href={sld} x="0" y="0" />
                </svg>
              </g>
              <g>
                <svg viewBox="-75 -10 1300 800">
                  <g
                    id="components-sld"
                    style={sldLoadCompleted ? {} : { display: "none" }}
                  >
                    <Ecc />
                    <Busbar />

                    <Utility />
                    <Battery />
                    {CBAssetList[3].uiData.status && <PV1 />}
                    {CBAssetList[4].uiData.status && <PV2 />}
                    {CBAssetList[5].uiData.status && <PV3 />}
                    <Load />
                    {CBEVList.map(
                    (cbData, key) =>
                    cbData?.uiData?.status && (
                      <EV
                      key={cbData?.configData?.name}
                      index={key}
                      status={cbData?.uiData?.status}
                    />
                  )
              )}
                  </g>
                </svg>
              </g>
            </svg>
          </g>
        </svg>
      )}
    </div>
  );
};

export default PvBessNrSLD;
