import React, { useContext } from "react";

import CBConfigDetails from "./CBConfigDetails";

import cbImage from "../../images/SEL751.png";
import embLabels from "../../config/emb-labels-en.json";

import isTouchDevice from "../SE-Component/Tooltip";
import { AssetBlockContext } from "../CustomHooks/ContextApi";

const HardWiredSBCbDetails = () => {
  const {
    // CBAssetList,
    // selectedValues,
    // setAssetSelection,
    // assetDetailsDisplay,
  } = useContext(AssetBlockContext);
  // const setAssetHandler = () => setAssetSelection(embLabels.CIRCUIT_BREAKER);
  return (
    <div name="CB" className="col-md-12 p-0">
      <div className="col-md-12 pt-2 se-white-background">
        <div className="row">
          <div className="col-md-8">
            <img
              src={cbImage}
              className="asset-image-icon"
              alt={"circuit breaker"}
            />

            <span className="align-top line-height-30px">Protection relay</span>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-sm-12 pt-2 se-grey-background se-border">
        <div className="row">
          <div className="col-md-3">
            <img
              src={cbImage}
              className="asset-image-display"
              alt={"circuit breaker"}
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-12 bold">SEL751</div>
            </div>
            <div className="row">
              <div className="col-md-9">Hardwired</div>
              <div className="col-md-3 text-right">
              
              </div>
              {/* <div className="col-md-12">
              Modbus TCP communication
              </div> */}
             
            </div>
          </div>
        </div>
      </div>
    
    </div>
  );
};

export default HardWiredSBCbDetails;
