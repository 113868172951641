import React, { useContext } from "react";
import { DesignProgramContext } from "../CustomHooks/ContextApi";
import isTouchDevice from "../SE-Component/Tooltip";

import embLabels from "../../config/emb-labels-en.json";
import Tooltip from "../../utils/Tooltip";
import { useSelector } from "react-redux";

const GensetOptimization = () => {
  const {
    siteType,
    architectureType,
    gensetDetails,
    gensetLoad,
    maxGensetLoad,
    batteryDetails,
    pvCurtailment,
    gensetLSIEnable,
    handleRadioButtonChange,
    handleGensetOptimizationConfirm,
    configuredStatusModified,
    handleChange,
    handleCheckBoxChange,
    handlepPVOpeningPopup,
  } = useContext(DesignProgramContext);
  const tooltipData = useSelector((state) => state?.tooltipData?.tooltipData);
  const gensetBackfeedProtectionStatusChangeHandlerYes = () => {
    handleGensetOptimizationConfirm();
    configuredStatusModified("gofpConfiguredStatus");
  };

  const gensetBackfeedProtectionStatusChangeHandlerNo = (e) => {
    handleRadioButtonChange(e);
    configuredStatusModified("gofpConfiguredStatus");
  };

  const gensetLoadChangeHandler = (e) => {
    handleChange(e);
    configuredStatusModified("gofpConfiguredStatus");
  };

  const optimizationFunctionChangeHandler = (e) => {
    handleCheckBoxChange(e);
    configuredStatusModified("gofpConfiguredStatus");
  };

  const gensetLSIChangeHandler = (e) => {
    handlepPVOpeningPopup(e);
    configuredStatusModified("gofpConfiguredStatus");
  };

  return (
    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-1">
      <div className="dropdown-divider"></div>
      <div className="row">
        <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 mb-2 ">
          <span className="bold">
            Genset Optimization & Backfeed Protection
          </span>
          {siteType === embLabels.GENSET_PVBESS_A2E && (
            <Tooltip
              position="right"
              iconColor="secondary"
              information=" Genset optimization & backfeed protection."
            />
          )}
        </div>

        <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 mb-2 ">
          <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="customRadioG1"
              name="gensetBackfeedProtectionStatus"
              className="custom-control-input islandableRadio asCompatible"
              value="Y"
              checked={gensetDetails.gensetBackfeedProtectionStatus === "Y"}
              onChange={gensetBackfeedProtectionStatusChangeHandlerYes}
            />
            <label
              className="custom-control-label line-height-25px"
              htmlFor="customRadioG1"
            >
              Yes
            </label>
          </div>

          <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="customRadioG2"
              name="gensetBackfeedProtectionStatus"
              className="custom-control-input islandableRadio asCompatible"
              value="N"
              disabled={siteType === embLabels.GENSET_PVBESS_A2E}
              checked={gensetDetails.gensetBackfeedProtectionStatus === "N"}
              onChange={gensetBackfeedProtectionStatusChangeHandlerNo}
            />
            <label
              className="custom-control-label line-height-25px"
              htmlFor="customRadioG2"
            >
              No
            </label>
          </div>
        </div>
      </div>

      {gensetDetails.gensetBackfeedProtectionStatus === "Y" && (
        <div className="row">
          {siteType === embLabels.GENSET_PVBESS_A2E ? null : (
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
              <div className="form-group row">
                <label
                  htmlFor="inputEmail11"
                  className="col-sm-3 col-form-label line-height-25"
                >
                  Targeted Min Genset Load (kW)
                  <span className="se-mandatory">*</span>
                  <Tooltip
                    position="right"
                    iconColor="secondary"
                    information="For optimum use of the Genset, make sure, the loading value of genset, i.e targeted minimum loading(kw) is 10-30% of maximum genset loading value (to be confirmed by the genset manufacturer)."
                  />
                </label>

                <div className="col-sm-5">
                  <input
                    type="text"
                    autoComplete="none"
                    className="form-control form-control-md"
                    name="gensetLoad"
                    placeholder="Targeted Min Genset Load (kW)"
                    maxLength="6"
                    value={gensetLoad}
                    onChange={gensetLoadChangeHandler}
                  />

                  {gensetLoad.toString() === "" ? (
                    <div className="invalid-feedback-custom">
                      Targeted Min Genset Load (kW) is required.
                    </div>
                  ) : (parseFloat(gensetLoad) / parseFloat(maxGensetLoad)) *
                      100 >
                      100.0 ||
                    (parseFloat(gensetLoad) / parseFloat(maxGensetLoad)) * 100 <
                      10.0 ? (
                    <div className="invalid-feedback-custom">
                      Targeted Min Genset load should be in range of 10% to 100%
                      of Max Power(kW) .
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <label className="col-sm-4 col-form-label line-height-25">
                  <span>
                    ({" "}
                    {(parseFloat(gensetLoad) / parseFloat(maxGensetLoad)) *
                      100 >=
                    0
                      ? Math.round(
                          (parseFloat(gensetLoad) / parseFloat(maxGensetLoad)) *
                            100
                        )
                      : "0"}
                    % of max power {maxGensetLoad} kW )
                  </span>
                </label>
              </div>
            </div>
          )}
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
            <span className="bold">
              Select optimization and protection functions
            </span>
          </div>
          <div className={`col-sm-8 col-md-8 col-lg-8 col-xl-8`}>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className={`custom-control-input asCompatible  ${
                  siteType === embLabels.GENSET_PVBESS_A2E
                    ? "se-checkbox-disabled"
                    : ""
                } `}
                name="batteryBuffering"
                id="customControlValidation4"
                checked={batteryDetails.batteryBuffering}
                disabled={batteryDetails.batteryBufferingStatus ? false : true}
                onChange={optimizationFunctionChangeHandler}
              />
              <label
                className={`custom-control-label line-height-25 ${
                  siteType === embLabels.GENSET_PVBESS_A2E
                    ? "se-block-disabled"
                    : ""
                }`}
                htmlFor="customControlValidation4"
              >
                Battery buffering
              </label>
              <span className="se-block-enabled">
                <se-tooltip
                  action={
                    [
                      embLabels.ISLANDABLE,
                      embLabels.GRID_CONNECTED,
                      embLabels.GENSET_PVBESS_A2E,
                    ].includes(siteType)
                      ? isTouchDevice()
                        ? "click"
                        : "hover"
                      : ""
                  }
                  position="right"
                >
                  <se-icon
                    option="raised"
                    color="secondary"
                    slot="tooltip"
                    disabled={
                      siteType === embLabels.PV_HYBRIDIZATION ||
                      (siteType === embLabels.ISLANDABLE_GSX &&
                        architectureType === embLabels.PV_GENSET_ATS)
                        ? true
                        : false
                    }
                  >
                    information_circle
                  </se-icon>
                  <se-tooltip-content>
                    {siteType === embLabels.GENSET_PVBESS_A2E
                      ? "Use extra production from solar to charge the BESS"
                      : tooltipData?.batteryBuffering?.data}
                  </se-tooltip-content>
                </se-tooltip>
              </span>
            </div>
          </div>

          {siteType === embLabels.GENSET_PVBESS_A2E && (
            <div className={`col-sm-8 col-md-8 col-lg-8 col-xl-8`}>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className={`custom-control-input asCompatible  ${
                    siteType === embLabels.GENSET_PVBESS_A2E
                      ? "se-checkbox-disabled"
                      : ""
                  } `}
                  name="pvCurtailment"
                  id="backFeedProtection"
                  checked={true}
                />
                <label
                  className={`custom-control-label line-height-25 ${
                    siteType === embLabels.GENSET_PVBESS_A2E
                      ? "se-block-disabled"
                      : ""
                  }`}
                  htmlFor="backFeedProtection"
                >
                  Back feed protection
                </label>
                <Tooltip
                  position="right"
                  iconColor="secondary"
                  information="Protect diesel generator against reverse power from the BESS."
                />
              </div>
            </div>
          )}

          <div className={`col-sm-8 col-md-8 col-lg-8 col-xl-8`}>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className={`custom-control-input asCompatible  ${
                  siteType === embLabels.GENSET_PVBESS_A2E
                    ? "se-checkbox-disabled"
                    : ""
                } `}
                name="pvCurtailment"
                id="customControlValidation2"
                value={pvCurtailment}
                checked={pvCurtailment}
                onChange={optimizationFunctionChangeHandler}
              />
              <label
                className={`custom-control-label line-height-25 ${
                  siteType === embLabels.GENSET_PVBESS_A2E
                    ? "se-block-disabled"
                    : ""
                }`}
                htmlFor="customControlValidation2"
              >
                PV curtailment
              </label>
              <Tooltip
                position="right"
                iconColor="secondary"
                information={
                  siteType === embLabels.GENSET_PVBESS_A2E
                    ? "When batteries are charged, PV production will be automatically adapted to the load consumption"
                    : `${tooltipData?.pVCurtailment?.data}`
                }
              />
            </div>
          </div>

          {siteType === embLabels.GENSET_PVBESS_A2E && (
            <div className={`col-sm-8 col-md-8 col-lg-8 col-xl-8`}>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className={`custom-control-input asCompatible  ${
                    siteType === embLabels.GENSET_PVBESS_A2E
                      ? "se-checkbox-disabled"
                      : ""
                  } `}
                  name="pvCurtailment"
                  id="bessGensetCloseTransition"
                  checked={true}
                />
                <label
                  className={`custom-control-label line-height-25 ${
                    siteType === embLabels.GENSET_PVBESS_A2E
                      ? "se-block-disabled"
                      : ""
                  }`}
                  htmlFor="bessGensetCloseTransition"
                >
                  BESS - Genset Close transition
                </label>
                <Tooltip
                  position="right"
                  iconColor="secondary"
                  information=" When transiting from BESS to Genset or from Genset to BESS,
                    close transition is ensured by the Microgrid controller."
                />
              </div>
            </div>
          )}

          {siteType === embLabels.GENSET_PVBESS_A2E && (
            <div className={`col-sm-8 col-md-8 col-lg-8 col-xl-8`}>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className={`custom-control-input asCompatible  ${
                    siteType === embLabels.GENSET_PVBESS_A2E
                      ? "se-checkbox-disabled"
                      : ""
                  } `}
                  name="pvCurtailment"
                  id="fuelSaving"
                  checked={true}
                />
                <label
                  className={`custom-control-label line-height-25 ${
                    siteType === embLabels.GENSET_PVBESS_A2E
                      ? "se-block-disabled"
                      : ""
                  }`}
                  htmlFor="fuelSaving"
                >
                  Fuel Saving
                </label>
                <Tooltip
                  position="right"
                  iconColor="secondary"
                  information="Save fuel from your generator by charging BESS while
                  generator is running and by stopping it to rely on BESS +
                  Solar power."
                />
              </div>
            </div>
          )}

          <div
            className={`col-sm-8 col-md-8 col-lg-8 col-xl-8 ${
              siteType === embLabels.GENSET_PVBESS_A2E
                ? "se-block-disabled hidden"
                : ""
            }`}
          >
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input asCompatible"
                name="gensetLSIEnable"
                id="customControlValidation3"
                value={gensetLSIEnable}
                checked={gensetLSIEnable}
                onChange={gensetLSIChangeHandler}
              />
              <label
                className="custom-control-label line-height-25"
                htmlFor="customControlValidation3"
              >
                PV breakers opening
              </label>
              <Tooltip
                position="right"
                iconColor="secondary"
                information={tooltipData?.pVBreakersOpening?.data}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GensetOptimization;
