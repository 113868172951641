import React from "react";
import isTouchDevice from "../pages/SE-Component/Tooltip";

const Tooltip = ({
  position,  
  iconColor,
  iconSize, 
  information,
}) => {
  return (
    <span className="se-block-enabled">
      <se-tooltip
        action={isTouchDevice() ? "click" : "hover"}
        position={position}
      >
        <se-icon
          option="raised"
          slot="tooltip"
          color={iconColor}
          size={iconSize}
                   
        >
           information_circle
        </se-icon>
        <se-tooltip-content style={{textAlign:"left"}}>{information}</se-tooltip-content>
      </se-tooltip>
    </span>
  );
};

export default Tooltip;
