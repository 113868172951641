import React from "react";

const Confirm = ({ confirm, onOk, onClose }) => (
  <se-dialog
    id="AlertDialog"
    open={confirm.confirmPopupStatus}
    can-backdrop="false"
  >
    <se-dialog-header>Confirmation</se-dialog-header>
    <se-dialog-content
      icon="information_circle"
      color="primary"
      className="se-header-font-size-16px se-life-green"
    >
      {confirm.confirmMessage}
    </se-dialog-content>
    <se-dialog-footer>
      <se-button onClick={onOk}>Yes</se-button>
      <se-button onClick={onClose}>No</se-button>
    </se-dialog-footer>
  </se-dialog>
);

export default Confirm;
