import React, { useState } from "react";
import MainLayout from "../layouts/MainLayout";
import LegalDocument from "./LegalDocument";

import { SeCheckbox, SeButton } from "@se/web-ui-react";

const TermsAndConditions = props => {
  const [isChecked, setIsChecked] = useState(false);

  const onCheckboxClick = event => {
    setIsChecked(event.target.selected);
  };

  const onButtonClick = () => {
    let { history } = props;
    history.push({
      pathname: "/project",
    });
  };
  return (
    <div className="legal-agreement">
      <MainLayout>
        <LegalDocument />

        <div className="agreement-form">
          <SeCheckbox
            onClick={onCheckboxClick}
            label="I agree to the above Terms and Conditions"
          />
          <SeButton
            disabled={!isChecked}
            onClick={onButtonClick}
            color="primary"
          >
            Continue
          </SeButton>
        </div>
      </MainLayout>
    </div>
  );
};

export default TermsAndConditions;
