//import 'react-app-polyfill/ie11';

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";
import "./map-custom.css";
import store from "./redux/store";
import { Provider } from "react-redux";
// applyPolyfills().then(() => {
//   defineCustomElements(window);
// });
/* estlint no-restricted-globals:0 */
let state = {};
window.setState = changes => {
  state = Object.assign({}, state, changes);
  ReactDOM.render(<Provider store={store}><App {...state} /></Provider>, document.getElementById("root"));
};

let iniialState = {
  location: window.location.pathname.replace(/("\/?|\/$)/g, ""),
};
window.setState(iniialState);
