import React, { useContext } from "react";
import useCBMouserOver from "../../pages/CustomHooks/useCBMouserOver";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";
import embLabels from "../../config/emb-labels-en.json";

const Ev = ({ index }) => {
  const [mouseOverData, CBMouseHandler] = useCBMouserOver();

  const {
    CBEVList: [{ uiData,uiData: ev, configData, cbConfigData,powerMeterData }],
    UserScope,
    CBEVListDispatch,
    assetDetailsReset,
    selectedValuesHandler,
    selectedAssetHandler,
    evConfigDataHandler,
    evCbConfigDataHandler,
    evpmConfigDataHandler,
    callSnackbar,
    switchboardType,
    siteType,
    projectTypeValue,
    loadSide,
  } = useContext(DesignAssetContext);

  const evSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "evDetailsBlock";
      const selectedAssetValue = "selectedEv";
      CBEVListDispatch({
        type: ACTIONS.EV_SELECT,
        payload: {
          index,
        },
      });
      selectedValuesHandler(index, selectedAssetValue, assetBlock);
      selectedAssetHandler(embLabels.EV_CHARGING_STATION, index);
      evConfigDataHandler(index);
    } else {
      callSnackbar(
        "error",
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const evCBSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "cbEvDetailsBlock";
      const selectedAssetValue = "selectedEvCB";
      CBEVListDispatch({
        type: ACTIONS.EV_CB_SELECT,
        payload: {
          index,
        },
      });

      selectedValuesHandler(index, selectedAssetValue, assetBlock);
      evCbConfigDataHandler(index);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };
  const evPMSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "pmAssetDetailsBlockGrid";
      const selectedAssetValue = "selectedEvPM";
      CBEVListDispatch({
        type: ACTIONS.EV_PM_SELECT,
        payload: {
          index,
        },
      });
      selectedValuesHandler(index, selectedAssetValue, assetBlock);
      evpmConfigDataHandler(index);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };
  return (
    <svg className={`pointer`}>
      <g id="ev">
        <svg
          x={(loadSide==='LOAD_SIDE'?120:ev.xAxis)}
          y={(loadSide==='LOAD_SIDE'?320:ev.yAxis)}
          width="149px"
          height="475px"
          viewBox="0 0 90 550"
          onClick={evSelectHandler}
        >
          <title>{configData.name}</title>
          <g
            id="Design"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Design/Assets-_-00"
              transform="translate(-604.000000, -372.000000)"
            >
              <g
                id="SLD/Production/Uniline/Undefined"
                transform="translate(604.000000, 372.000000)"
              >
                <g id="SLD/Production/Uniline/Tobedefine">
                  <g id="Group-7">
                    <rect
                      id="Rectangle"
                      x="0"
                      y="95"
                      width="96"
                      height="148"
                    ></rect>
                    <line
                      x1="48.5"
                      y1={ev.yAxis === 377 ? "0" : "96"}
                      x2="48.5"
                      y2="144"
                      id="Line-2"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      stroke={ev.configured ? "#333" : "#E47F00"}
                    ></line>

                    <circle
                      id="Oval"
                      stroke="#42B4E6"
                      strokeWidth="3"
                      cx="48"
                      cy="193.5"
                      r="55"
                      className={ev.selected ? "" : "hidden"}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="rgba(0, 135, 205, 0.2)"
                    ></circle>

                    <circle
                      id="Oval"
                      strokeWidth="3"
                      fill="#FFFFFF"
                      cx="48"
                      cy="193.5"
                      r="46.5"
                      stroke={ev.configured ? "#333" : "#E47F00"}
                    ></circle>

                    <text
                      id="segment_energy_infra"
                      fontFamily="se-icon"
                      fontSize="56"
                      fontWeight="normal"
                      fill="#333333"
                    >
                      <tspan x="20" y="210">
                        electric_vehicle_terminal
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </g>
      <g id="power meter" style={uiData.pmRequired ? {} : { display: "none" }}>
        <svg
          x={loadSide ==='LOAD_SIDE'?Number(ev.xDerPM)-130:ev.xDerPM}
          y={switchboardType==='NEW_SWITCHBOARD'?ev.yDerPM:(loadSide ==='LOAD_SIDE'?ev.yDerPM:(Number(ev.yDerPM)-40))}
          width="300px"
          height="140px"
          viewBox="0 0 100 160" 
          onClick={evPMSelectHandler}
        >
          <title>{powerMeterData.name}</title>

          <line
            x1="30"
            y1="127"
            x2="80"
            y2="127"
            stroke={uiData.pmConfiguredGrid ? "#333" : "#E47F00"}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle
            id="Pm outer"
            stroke="#42B4E6"
            strokeWidth="3"
            cx="100"
            cy="127"
            r="26"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="rgba(0, 135, 205, 0.2)"
            style={uiData.pmSelected ? {} : { display: "none" }}
          ></circle>
          <circle
            id="pm inner"
            stroke={uiData.pmConfiguredGrid ? "#333" : "#E47F00"}
            strokeWidth="3"
            cx="100"
            cy="127"
            r="18.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="#FFFFFF"
          ></circle>
          <text
            id="PM"
            fontSize="16"
            fontWeight="normal"
            stroke="#333"
            fontFamily="Nunito-Regular, Nunito"
            fill="#333"
          >
            <tspan x="88" y="132">
              PM
            </tspan>
          </text>
        </svg>
      </g>
      <g id="CB">
        <svg
          width="70px"
          height="100px"
          viewBox="0 0 10 149"
          x={switchboardType===embLabels.NEW_SWITCHBOARD?125:(projectTypeValue==="MGaaS ANSI"&&siteType==="ISLANDABLE"?(loadSide==='LOAD_SIDE'?165:295):680)}
          y={switchboardType===embLabels.NEW_SWITCHBOARD?300:(projectTypeValue==="MGaaS ANSI"&&siteType==="ISLANDABLE"?(loadSide==='LOAD_SIDE'?300:285):430)}
          onClick={evCBSelectHandler}
        >
          <title>{cbConfigData.name}</title>
          <g
            id="Circuit Breaker"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Circuit Breaker 1"
              transform="translate(-651.000000, -512.000000)"
            >
              <g
                id="Circuit Breaker - closed"
                transform="translate(604.000000, 514.000000)"
                onMouseOver={CBMouseHandler}
                onMouseOut={CBMouseHandler}
              >
                <g
                  id="Group-15"
                  strokeWidth="4"
                  fill={
                    ev.CBSelected
                      ? "rgba(65, 181, 230, 0.35)"
                      : mouseOverData
                      ? "rgba(65, 181, 230, 0.35)"
                      : "none"
                  }
                  stroke={ev.CBSelected ? "#42B4E6" : "none"}
                >
                  <rect
                    id="Rectangle-Copy-20"
                    x="0"
                    y="8"
                    width="96"
                    height="132"
                  ></rect>
                  <g
                    id="Group-2"
                    transform="translate(48.000000, 0.000000)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    stroke={ev.CBConfigured ? "#333" : "#E47F00"}
                    strokeWidth="4"
                  >
                    <line
                      x1="0.5"
                      y1="0"
                      x2="0.5"
                      y2="47.8298611"
                      id="Line-2"
                    ></line>
                  </g>
                </g>
                <line
                  x1="48.5"
                  y1="97.1701389"
                  x2="48.501111"
                  y2="145"
                  id="Line-2-Copy-15"
                  stroke={ev.CBConfigured ? "#333" : "#E47F00"}
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <path
                  d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                  id="Path-11"
                  stroke={ev.CBConfigured ? "#333" : "#E47F00"}
                  strokeWidth="4"
                  strokeLinecap="round"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </g>
    </svg>
  );
};

export default Ev;
