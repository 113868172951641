import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";
import embLabels from "../../config/emb-labels-en.json";
import MyErrorBoundary from "../../utils/ErrorBoundary"

const HybridAsset = () => {
  const {
    CBAssetList: [, , , , , , , { uiData, configData }],
    assetDetailsReset,
    UserScope,
    callSnackbar,
    CBAssetListDispatch,
    selectedValuesHandler,
    selectedAssetHandler,
    assetConfigDataHandler,
  } = useContext(DesignAssetContext);

  const hybridSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "assetHybridDetailsBlock";
      const selectedAssetValue = "selectedAsset";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_SELECT,
        payload: {
          index: 7,
        },
      });
      selectedValuesHandler(7, selectedAssetValue, assetBlock);
      selectedAssetHandler(embLabels?.ASSETS, 7);
      assetConfigDataHandler(7);
    } else {
      callSnackbar(
        "error",
        `${UserScope?.role} is not authorized to perform action on this page`
      );
    }
  };

  return (
    <MyErrorBoundary>

    <svg className="pointer">
      <g id="hybrid">
        <svg
          x={uiData?.xAxis}
          y={uiData?.yAxis}
          width="195px"
          height="130px"
          viewBox="0 0 10 149"
          onClick={hybridSelectHandler}
        >
          <title>{configData?.name}</title>
          <g id="Design" fill="none" fillRule="evenodd">
            <g
              id="Design/Assets-_-00"
              transform="translate(-892.000000, -372.000000)"
            >
              <g
                id="SLD/Production/Uniline/Tobedefine"
                transform="translate(892.000000, 372.000000)"
              >
                <g id="Group-7">
                  <rect
                    id="Rectangle"
                    x="0"
                    y="0"
                    width="96"
                    height="144"
                  ></rect>
                  <line
                    x1="48.5"
                    y1="96"
                    x2="48.5"
                    y2="144"
                    id="Line-2"
                    strokeWidth="4"
                    strokeLinecap="round"
                    stroke={uiData?.configured ? "#333333" : "#E47F00"}
                    strokeLinejoin="round"
                  ></line>
                  <circle
                    id="Oval"
                    stroke="#42B4E6"
                    strokeWidth="3"
                    cx="48"
                    cy="56"
                    r="55"
                    style={uiData?.selected ? {} : { display: "none" }}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="rgba(0, 135, 205, 0.2)"
                  ></circle>
                  <circle
                    id="Oval"
                    strokeWidth="3"
                    fill="#FFFFFF"
                    cx="48"
                    cy="55"
                    r="46.5"
                    stroke={uiData?.configured ? "#333333" : "#E47F00"}
                  ></circle>
                  <text
                    id="segment_energy_infra"
                    fontFamily="se-icon"
                    stroke="#333333"
                    fontSize="45"
                    fontWeight="normal"
                    fill="#0F0F0F"
                  >
                    <tspan x="22" y="87">
                      battery2_general
                    </tspan>
                    <tspan x="42" y="55">
                      solar_panel1
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </g>
      <g id="CB90">
        <line
          x1={uiData?.xAxisCB}
          x2={uiData?.xAxisCB}
          y1={uiData?.yAxisCB}
          y2="367"
          id="Line-2"
          strokeWidth="3"
          strokeDasharray="4 8"
          strokeLinecap="round"
          stroke="#333333"
        ></line>
      </g>
    </svg>
    </MyErrorBoundary>
  );
};

export default HybridAsset;
