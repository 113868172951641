import React, { useContext } from "react";

import CBConfigDetails from "./CBConfigDetails";

import cbImage from "../../images/CB.png";
import embLabels from "../../config/emb-labels-en.json";

import isTouchDevice from "../SE-Component/Tooltip";
import { AssetBlockContext } from "../CustomHooks/ContextApi";
import Tooltip from "../../utils/Tooltip";

const GsxNewSBCbDetails = () => {
  const {
    CBAssetList,
    selectedValues,
    setAssetSelection,
    assetDetailsDisplay,
  } = useContext(AssetBlockContext);
  const setAssetHandler = () => setAssetSelection(embLabels.CIRCUIT_BREAKER);
  
  return (
    <div name="CB" className="col-md-12 p-0">
      <div className="col-md-12 pt-2 se-white-background">
        <div className="row">
          <div className="col-md-8">
            <img
            src={ CBAssetList[selectedValues.selectedAssetCB].cbConfigData
              .cbAssetDetails[0]?.imageLink === null?cbImage:CBAssetList[selectedValues.selectedAssetCB].cbConfigData
              .cbAssetDetails[0]?.imageLink }
              className="asset-image-icon"
              alt={"circuit breaker"}
            />

            <span className="align-top line-height-30px bold">Circuit Breaker</span>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-sm-12 pt-2 se-grey-background se-border">
        <div className="row">
          <div className="col-md-3">
            <img
              src={ CBAssetList[selectedValues.selectedAssetCB].cbConfigData
                .cbAssetDetails[0]?.imageLink === null?cbImage:CBAssetList[selectedValues.selectedAssetCB].cbConfigData
                .cbAssetDetails[0]?.imageLink }
              //src={cbImage}
              className="asset-image-display"
              alt={"circuit breaker"}
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-12 bold">{CBAssetList[selectedValues.selectedAssetCB].cbConfigData
  .cbAssetDetails[0]?.manufacturer}</div>
            </div>
            <div className="row">
              <div className="col-md-9">Circuit Breaker</div>
              <div className="col-md-3 text-right">
              <Tooltip
                  position="left"
                  iconColor="secondary"
                  information= {assetDetailsDisplay.communicationModel ||
                    CBAssetList[selectedValues.selectedAssetCB].cbConfigData
                      .cbAssetDetails[0]?.communicationModel ||
                    "PowerPact NSX"}
                />
               
              </div>
              <div className="col-md-12">
                {assetDetailsDisplay.communicationModel ||
                  CBAssetList[selectedValues.selectedAssetCB].cbConfigData
                    .cbAssetDetails[0]?.communicationModel ||
                  "PowerPact NSX"}
              </div>
              <div className="row">
                <div className=" col-md col-sm col-lg text-center">
                  <se-button
                    option="outline"
                    size="small"
                    onClick={setAssetHandler}
                  >
                    Asset Selection
                  </se-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CBConfigDetails />
    </div>
  );
};

export default GsxNewSBCbDetails;
