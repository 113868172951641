import React, { useContext } from "react";
import sld from "../../images/sld/Rectangle.svg";
import Ecc from "../../components/PVHYBRIDIZATION/Ecc";
import Busbar from "../../components/ReusableSvg/Busbar";
import Genset from "../../components/A2E/Genset";
import HybridAsset from "../../components/A2E/HybridAsset";
import Load from "../../components/A2E/Load";

import { DesignAssetContext } from "../CustomHooks/ContextApi";

const A2ESLD = () => {
  const { CBAssetList, CBLoadList, sldLoadCompleted } =
    useContext(DesignAssetContext);
  return (
    <div
      id="SLD-Image"
      className="col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0"
    >
        <svg viewBox="0 0 1300 800">
          <g>
            <svg x="0" y="0" viewBox="0 0 1300 800">
            <g id="grid">
              <svg>
                <image href={sld} x="0" y="0" />
              </svg>
            </g>

            <g
              id="components-sld"
              style={sldLoadCompleted ? {} : { display: "none" }}
            >
              <Ecc />
              <Busbar secondBusbar={false} />

              {CBAssetList[1]?.uiData?.status && <Genset />}
              {CBAssetList[7]?.uiData?.status && <HybridAsset />}

              {CBLoadList.map((loadData, key) => (
                <Load
                  key={loadData?.uiData?.xAxis}
                  load={loadData?.uiData}
                  loadName={loadData?.configData?.name}
                  loadCbName={loadData?.cbConfigData?.name}
                  index={key}
                />
              ))}
            </g>
          </svg>
        </g>
      </svg>
    </div>
  );
};

export default A2ESLD;
