import _ from "lodash";
import ACTIONS from "../Design_Asset_Actions.json";

import embLabels from "../../../config/emb-labels-en.json";

const communicationDataSample = {
  ipaddress: "",
  subnetmask: "", 
  defaultGateway: "",
  modbusSlaveId: "",
};
const initialCBEVList = [
  {
    assetConnected: "EV",
    modbusSlaveId: "31",
    configData: {
      name: "",
      description: "",
      maximum_available_power: 0,
    },
    CBId: "CB_60",
    uiData: {
      status: false,
      CBSelected: false,
      CBConfigured: true,
      configured: false,
      detailsBlock: false,
      selected: false,
      pmSelected: false,
      pmConfigured: true,
      pmRequired: false,
      pmConfiguredGrid: false,
      xAxisCB: 645,
      yAxisCB: 290,
      xAxis: 600,
      yAxis: 292,
    },
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
      cbAssetDetails: [],
      cbCommunicationData: {},
    },
    contactorConfigData:{
      name: "",
      description: "",
      loadCategory: 0,
      loadPriority: 0,
      cbAssetDetails: [],
      cbCommunicationData: {},
    },
    communicationData: {},
    assetDetails: [],
    powerMeterData: {
      name: "",
      description: "",
      powerMeterModbusId: "",
      ipAddress: "",
      gatewayAddress: "",
      mask: "",
      pmAsset: {},
    },
  },
];

const CBEVListReducer = (state, action) => {

  switch (action.type) {
    case ACTIONS.ASSIGN_DEFAULT_VALUES:
      return assignDefaultValuesCBEVList(state, 
        action.payload.defaultValues,
        action.payload.cb,
        action.payload.contactor,
        action.payload.ev,
        action.payload.pmEV,
        );
    case ACTIONS.ADD_EV:
      return addEv(
        state,
        action.payload.defaultPreFill,
        action.payload.configData,
        action.payload.count,
        action.payload.cbCommunicationValues,
        action.payload.defaultCbData,
        action.payload.contactor,
        action.payload.ev,
        action.payload.pmEV,
      );
    case ACTIONS.UPDATE_CBEVLIST_COORDINATES:
      return updateCbEvListCoordinates(
        state,
        action.payload.coordinates,
        action.payload.count
      );
    case ACTIONS.UPDATE_CBEVLIST_COORDINATES_GRIDCONNECTED:
      return updateCbEvListCoordinatesGridConnected(
        state,
        action.payload.coordinates.cbEV,
        );
    case ACTIONS.UPDATE_EV:
      return updateEv(
        state,
        action.payload.componentsData,
        action.payload.evCommunicationData,
        action.payload.setEvCommunicationData
      );
    case ACTIONS.UPDATE_EV_CB:
      return updateEvCb(state, action.payload.componentsData);
    case ACTIONS.UPDATE_EV_PM:
      return updateEvPm(state, action.payload.componentsData);
    case ACTIONS.CLOSEDETAILS_EV:
      return closeDetailsEv(state);
    case ACTIONS.EV_SELECT:
      return evSelect(state, action.payload.index);
    case ACTIONS.EV_CB_SELECT:
      return evCbSelect(state, action.payload.index);
    case ACTIONS.UPDATE_EV_CONFIG:
      return updateEvConfig(
        state,
        action.payload.selectedEv,
        action.payload.configData
      );
    case ACTIONS.UPDATE_CB_CONTACTOR_CONFIG:
      return selectEVCbContactorAsset(
        state,
        action.payload.index,
        action.payload.selectedAsset
      );
      case ACTIONS.UPDATE_CB_CONTACTOR_CONFIG_DATA:
      return updateEVContactorConfigData(
        state,
        action.payload.index,
        action.payload.CBContactor
      );
      case ACTIONS.UPDATE_DER_CB_CONTACTOR:
        return updateDerCbContactor(
          state,
          action.payload.componentsData,
          action.payload.value
        );
    case ACTIONS.DUPLICATE_EV_UPDATE:
      return duplicateEvUpdate(state, action.payload.selectedEv);
    case ACTIONS.UPDATE_EV_CB_CONFIG:
      return updateEvCbConfig(
        state,
        action.payload.selectedEvCB,
        action.payload.cbConfigData
      );
    case ACTIONS.SELECT_EV_ASSET:
      return selectEvAsset(
        state,
        action.payload.index,
        action.payload.selectedAsset
      );
    case ACTIONS.SELECT_EV_CBASSET:
      return selectEvCbAsset(
        state,
        action.payload.index,
        action.payload.selectedAsset,
        action.payload.cbCommunicationValues
      );
    case ACTIONS.SELECT_SERIAL_ASSET:
      return selectSerialAsset(
        state,
        action.payload.index,
        action.payload.modbusSlaveId
      );
    case ACTIONS.SELECT_COMMUNICATING_ASSET:
      return selectCommunicatingAsset(
        state,
        action.payload.index,
        action.payload.preFillDefault
      );
    case ACTIONS.SELECT_EV_PMASSET:
      return selectEvPmAsset(
        state,
        action.payload.index,
        action.payload.selectedAsset,
        action.payload.preFillDefault
      );
    case ACTIONS.REMOVE_ASSET_EV:
      return removeAssetEv(
        state,
        action.payload.index,
        action.payload.configData
      );
    case ACTIONS.EV_ASSET_PM_REQUIRED:
      return evAssetPmRequired(state);
    case ACTIONS.EV_ASSET_CB_REQUIRED:
      return evAssetCBRequired(state);
    case ACTIONS.EV_PM_SELECT:
      return evPmSelect(state, action.payload.index);
    case ACTIONS.UPDATE_EV_POWERMETER_CONFIG:
      return updateEvPowerMeterConfig(
        state,
        action.payload.selectedAssetPM,
        action.payload.cbConfigData
      );
    default:
      return state;
  }
};

const assignDefaultValuesCBEVList = (CBEVList, defaultValues, cb,contactor,ev,pmEV) => {
  const evList = [...CBEVList];
  let circuitBreakersModbusSlaveIdList = defaultValues["Circuit Breaker"];
  evList[0].configData.name = defaultValues.EV_charging_station.name;
  evList[0].cbConfigData.name = `${defaultValues["Circuit Breaker"].name}_${defaultValues.EV_charging_station.name}`;
  evList[0].communicationData = { ...communicationDataSample };
  evList[0].communicationData.ipaddress =
    defaultValues.EV_charging_station.ipAddress;
  evList[0].communicationData.subnetmask =
    defaultValues.EV_charging_station.subnetMask;
  evList[0].communicationData.defaultGateway =
    defaultValues.EV_charging_station.defaultGateway;
  evList[0].communicationData.modbusSlaveId =
    defaultValues.EV_charging_station.modbusId;
  evList[0].modbusSlaveId = circuitBreakersModbusSlaveIdList["modbusId_CB_60"];
  evList[0].uiData.status = false;
  evList[0].contactorConfigData.name = "EV Contactor";
  evList[0].contactorConfigData.cbAssetDetails = [{ ...contactor }];
  evList[0].powerMeterData.name = defaultValues["Power Meter"].nameEV;
  evList[0].powerMeterData.ipAddress = defaultValues["Power Meter"].ipAddress_PM_EV;
  evList[0].powerMeterData.subnetMask = defaultValues["Power Meter"].subnetMask;
  evList[0].powerMeterData.defaultGateway = defaultValues["Power Meter"].defaultGateway;
  evList[0].powerMeterData.powerMeterModbusId =
    defaultValues["Power Meter"].modbusId_PM_EV;
  evList[0].cbConfigData.cbAssetDetails = [{ ...cb }];
  evList[0].assetDetails[0] = { ...ev };
  evList[0].powerMeterData.pmAsset = { ...pmEV };
  evList[0].cbConfigData.cbCommunicationData = { ...communicationDataSample };
  evList[0].uiData.pmConfiguredGrid = false;
  evList[0].uiData.CBConfigured = false;
  // return new state
  return evList;
};

const addEv = (
  CBEVList,
  defaultPreFill,
  configData,
  count,
  cbCommunicationValues,
  defaultCbData,
  contactor,
  ev,
  pmEV
) => {
  let newEvData = [...CBEVList];
  const totalLoads = count.loadCount + count.derCount + count.evCount;

  newEvData[0].modbusSlaveId =
    totalLoads % 7 === 0
      ? "255"
      : defaultPreFill["Circuit Breaker"].modbusId_CB_60;
  newEvData[0].uiData.status = true;
  newEvData[0].uiData.configured = true;
  newEvData[0].uiData.pmRequired = true;
  newEvData[0].uiData.pmConfiguredGrid = false;
  newEvData[0].uiData.CBConfigured = false;
  newEvData[0].uiData.cbRequired = true;
  newEvData[0].cbConfigData = { ...configData.sampleCbConfigData };
  newEvData[0].cbConfigData.cbAssetDetails = [];
  newEvData[0].cbConfigData.name = `${defaultPreFill["Circuit Breaker"].name}_${defaultPreFill.EV_charging_station.name}`;
  let ipAddress = 'ipAddress1'
  newEvData[0].cbConfigData.cbCommunicationData = {
    // ipaddress: getEvCbIp(cbCommunicationValues),
    ipaddress: defaultPreFill["Circuit Breaker"][ipAddress],
    subnetmask: defaultPreFill["Circuit Breaker"].subnetMask,
    defaultGateway: defaultPreFill["Circuit Breaker"].defaultGateway,
    modbusSlaveId:
      totalLoads % 7 === 0
        ? "255"
        : defaultPreFill["Circuit Breaker"].modbusId_CB_60,
  };
  newEvData[0].configData = { ...configData.evConfigData };
  newEvData[0].configData.name = defaultPreFill.EV_charging_station.name;
  newEvData[0].contactorConfigData.name = "EV Contactor";
  newEvData[0].contactorConfigData.cbAssetDetails[0] = { ...contactor };
  newEvData[0].assetDetails[0] = { ...ev };
  newEvData[0].powerMeterData.pmAsset = { ...pmEV };
  newEvData[0].cbConfigData.cbAssetDetails = [{ ...defaultCbData }];
  newEvData[0].communicationData = { ...communicationDataSample };
  newEvData[0].communicationData.ipaddress =(ev?.assetInfo==="Hardwired"||ev?.assetInfo==="Serial")?"":
    defaultPreFill.EV_charging_station.ipAddress;
  newEvData[0].communicationData.subnetmask =(ev?.assetInfo==="Hardwired"||ev?.assetInfo==="Serial")?"":
    defaultPreFill.EV_charging_station.subnetMask;
  newEvData[0].communicationData.defaultGateway =(ev?.assetInfo==="Hardwired"||ev?.assetInfo==="Serial")?"":
    defaultPreFill.EV_charging_station.defaultGateway;
  newEvData[0].communicationData.modbusSlaveId =
    defaultPreFill.EV_charging_station.modbusId;

  return newEvData;
};

const updateCbEvListCoordinates = (CBEVList, coordinates, count) => {
  const evCoordinates = [...CBEVList];
  let xLoad = coordinates.xLoad;
  let xLoadCB = coordinates.xLoadCB;
  let xLoadNew = coordinates.xLoadNew;
  let xLoadCBNew = coordinates.xLoadCBNew;

  let yLoad = 290;
  const totalLoads = count.loadCount + count.evCount;
  if (evCoordinates[0].uiData.status) {
    xLoad =
      count.loadCount > 11
        ? coordinates.loadDiff + (count.loadCount - 10.5) * 110 - 100
        : coordinates.xLoad + count.loadCount * 110;
    xLoadCB =
      count.loadCount > 11
        ? coordinates.loadDiff + (count.loadCount - 11) * 110
        : coordinates.xAxisLoadCBDiff + count.loadCount * 110;
    if (totalLoads === 12) {
      xLoad = xLoadNew;
      xLoadCB = xLoadCBNew;
    }
    yLoad = totalLoads > 11 ? 377 : 290;
    evCoordinates[0].uiData.xAxis = xLoad;
    evCoordinates[0].uiData.yAxis = yLoad;
    evCoordinates[0].uiData.xAxisCB = xLoadCB;
  }

  return evCoordinates;
};

const updateCbEvListCoordinatesGridConnected =( CBEVList, evData) => {
  const cbEvCor = [...CBEVList];

  cbEvCor[0].uiData.xAxis = evData.xDerEV;
  cbEvCor[0].uiData.yAxis = evData.yDerEV;
  cbEvCor[0].uiData.xDerPM = evData.xDerPM;
  cbEvCor[0].uiData.yDerPM = evData.yDerPM;
  cbEvCor[0].uiData.x1DerEVCB = evData.x1DerEVCB;
  cbEvCor[0].uiData.y1DerEVCB = evData.y1DerEVCB;
  cbEvCor[0].uiData.x2DerEVCB = evData.x2DerEVCB;
  cbEvCor[0].uiData.y2DerEVCB = evData.y2DerEVCB;
  return cbEvCor;
};

const updateEv = (
  CBEVList,
  componentsData,
  evCommunicationData,
  setEvCommunicationData
) => {
  const newEvData = [...CBEVList];
  const attributes = JSON.parse(componentsData.attributes);
  newEvData[0].uiData = JSON.parse(componentsData.uiAttributes);
  newEvData[0].uiData.selected = false;
  newEvData[0].uiData.CBSelected = false;
  newEvData[0].configData.name = componentsData.componentName;
  newEvData[0].configData.description = attributes.description;
  newEvData[0].configData.maximum_available_power =
    attributes.maximum_available_power;
  newEvData[0].assetType = componentsData.componentType;
  const newEvCom = { ...evCommunicationData };
  if (componentsData.communications[0] !== undefined) {
    newEvCom.ipaddress = componentsData.communications[0].ip;
    newEvCom.subnetmask = componentsData.communications[0].subnetmask;
    newEvCom.defaultGateway = componentsData.communications[0].defaultGateway;
    newEvCom.modbusSlaveId = componentsData.communications[0].modbusSlaveId;
    setEvCommunicationData(newEvCom);
  }
  newEvData[0].communicationData = newEvCom;
  if (componentsData.asset !== undefined) {
    newEvData[0].assetDetails.push(componentsData.asset);
  }
  return newEvData;
};

const updateEvCb = (CBEVList, componentsData) => {
  const newEvData = _.cloneDeep(CBEVList);
  const attributes = JSON.parse(componentsData.attributes);
  newEvData[0].cbConfigData.name = componentsData.componentName;
  newEvData[0].cbConfigData.description = attributes.description;
  newEvData[0].cbConfigData.motorized =
    parseInt(attributes.motorized) === 1 ? true : false;
  newEvData[0].cbConfigData.loadCategory = parseInt(attributes.loadCategory);
  newEvData[0].cbConfigData.loadPriority = parseInt(attributes.loadPriority);
  newEvData[0].cbConfigData.cbAssetDetails[0] = componentsData.asset;
  newEvData[0].modbusSlaveId = componentsData.communications[0].modbusSlaveId;
  if (componentsData.communications.length) {
    newEvData[0].cbConfigData.cbCommunicationData.ipaddress =
      componentsData.communications[0].ip;
    newEvData[0].cbConfigData.cbCommunicationData.subnetmask =
      componentsData.communications[0].subnetmask;
    newEvData[0].cbConfigData.cbCommunicationData.defaultGateway =
      componentsData.communications[0].defaultGateway;
    newEvData[0].cbConfigData.cbCommunicationData.modbusSlaveId =
      componentsData.communications[0].modbusSlaveId;
  }

  return newEvData;
};
const updateEvPm = (CBEVList, componentsData) => {
  const newEvData = _.cloneDeep(CBEVList);
  const attributes = JSON.parse(componentsData.attributes);

  newEvData[0].powerMeterData.name = componentsData.componentName;
  newEvData[0].powerMeterData.powerMeterModbusId =
    componentsData.communications[0].modbusSlaveId;
  newEvData[0].powerMeterData.ipAddress = componentsData.communications[0].ip;
  newEvData[0].powerMeterData.gatewayAddress =
    componentsData.communications[0].defaultGateway;
  newEvData[0].powerMeterData.mask =
    componentsData.communications[0].subnetmask;
  newEvData[0].powerMeterData.description = attributes.description;
  newEvData[0].powerMeterData.pmAsset = { ...componentsData.asset };

  return newEvData;
};

const closeDetailsEv = CBEVList => {
  const newCBEVList = Object.assign([], CBEVList);
  newCBEVList.forEach(ev => {
    ev.uiData.CBSelected = false;
    ev.uiData.selected = false;
    ev.uiData.detailsBlock = false;
    ev.uiData.pmSelected = false;
  });
  return newCBEVList;
};

const evSelect = (CBEVList, index) => {
  const newCBEVList = [...CBEVList];
  newCBEVList[index].uiData.selected = true;
  return newCBEVList;
};

const evCbSelect = (CBEVList, index) => {
  const newEvData = [...CBEVList];
  newEvData[index].uiData.CBSelected = true;
  return newEvData;
};

const updateEvConfig = (CBEVList, selectedEv, configData) => {
  const newCBEVList = [...CBEVList];

  newCBEVList[selectedEv].uiData.configured = true;
  newCBEVList[selectedEv].configData = configData;

  // newCBEVList[this.state.selectedEv].communicationData =
  //   this.state.evCommunicationData;
  return newCBEVList;
};
const updateEVContactorConfigData = (CBEVList,index,CBContactor)=>{
  const newCBEVList = _.cloneDeep(CBEVList);
  newCBEVList[index].contactorConfigData = {...CBContactor}
  return newCBEVList;
}

const updateDerCbContactor = (CBEVList,componentsData,value)=>{
  let newEvData = _.cloneDeep(CBEVList);
  const attributes = JSON.parse(componentsData.attributes);
  newEvData[0].contactorConfigData.name = componentsData.componentName;
  newEvData[0].contactorConfigData.description = attributes.description;
  newEvData[0].contactorConfigData.motorized =
    parseInt(attributes.motorized) === 1 ? true : false;
  newEvData[0].contactorConfigData.loadCategory = parseInt(attributes.loadCategory);
  newEvData[0].contactorConfigData.loadPriority = parseInt(attributes.loadPriority);
  newEvData[0].contactorConfigData.cbAssetDetails[0] = componentsData.asset;
  newEvData[0].modbusSlaveId = componentsData.communications[0].modbusSlaveId;
  if (componentsData.communications.length) {
    newEvData[0].contactorConfigData.cbCommunicationData.ipaddress =
      componentsData.communications[0].ip;
    newEvData[0].contactorConfigData.cbCommunicationData.subnetmask =
      componentsData.communications[0].subnetmask;
    newEvData[0].contactorConfigData.cbCommunicationData.defaultGateway =
      componentsData.communications[0].defaultGateway;
    newEvData[0].contactorConfigData.cbCommunicationData.modbusSlaveId =
      componentsData.communications[0].modbusSlaveId;
  }
  return newEvData;
}
const selectEVCbContactorAsset = (CBEVList, index, selectedAsset) => {
  const newCBEVList = _.cloneDeep(CBEVList);
  newCBEVList[index].contactorConfigData.cbAssetDetails[0] = selectedAsset;
  return newCBEVList;
};
const duplicateEvUpdate = (CBEVList, selectedEv) => {
  const newCBEVList = [...CBEVList];
  newCBEVList[selectedEv].uiData.configured = false;

  return newCBEVList;
};

const updateEvCbConfig = (CBEVList, selectedEvCB, cbData) => {
  const newCBEVList = [...CBEVList];
  newCBEVList[selectedEvCB].cbConfigData = {
    ...newCBEVList[selectedEvCB].cbConfigData,
    ...cbData,
  };
  newCBEVList[selectedEvCB].uiData.CBConfigured = true;

  return newCBEVList;
};

const selectEvAsset = (CBEVList, index, selectedAsset) => {
  const evData = [...CBEVList];
  evData[index].assetDetails[0] = selectedAsset;
  evData[index].uiData.configured = false;
  return evData;
};
const selectEvCbAsset = (
  CBEVList,
  index,
  selectedAsset,
  cbCommunicationValues
) => {
  const evData = _.cloneDeep(CBEVList);
  const evDataPrevious = CBEVList[index].cbConfigData;
  evData[index].cbConfigData.cbAssetDetails[0] = selectedAsset;
  evData[index].uiData.CBConfigured = false;

  const cbCommunicationData = getCBifeValues(
    evDataPrevious,
    selectedAsset,
    cbCommunicationValues
  );
  evData[index].cbConfigData.cbCommunicationData = cbCommunicationData;

  return evData;
};

const selectSerialAsset = (CBEVList, index, modbusSlaveId) => {
  const evData = [...CBEVList];
  evData[index].communicationData.ipaddress = "";
  evData[index].communicationData.subnetmask = "";
  evData[index].communicationData.defaultGateway = "";
  evData[index].communicationData.modbusSlaveId = modbusSlaveId;

  return evData;
};

const selectCommunicatingAsset = (CBEVList, index, preFillDefault) => {
  const evData = [...CBEVList];

  evData[index].communicationData.ipaddress = preFillDefault.ipAddress;
  evData[index].communicationData.subnetmask = preFillDefault.subnetMask;
  evData[index].communicationData.defaultGateway =
    preFillDefault.defaultGateway;
  evData[index].communicationData.modbusSlaveId = preFillDefault.modbusId;

  return evData;
};

const selectEvPmAsset = (CBEVList, index, selectedAsset, preFillDefault) => {
  const evData = _.cloneDeep(CBEVList);
  evData[index].powerMeterData.pmAsset = { ...selectedAsset };
  evData[index].uiData.pmConfiguredGrid = false;
  evData[index].powerMeterData.powerMeterModbusId =
    preFillDefault.modbusId_PM_EV;
  if (selectedAsset.assetInfo === embLabels.COMMUNICATING) {
    evData[index].powerMeterData.ipAddress = preFillDefault.ipAddress_PM_EV;
    evData[index].powerMeterData.defaultGateway = "192.168.50.254";
    evData[index].powerMeterData.subnetMask = "255.255.255.0";
  }
  if (selectedAsset.assetInfo === embLabels.SERIAL||selectedAsset.assetInfo === "Hardwired") {
    evData[index].powerMeterData.ipAddress = "";
    evData[index].powerMeterData.defaultGateway = "";
    evData[index].powerMeterData.subnetMask = "";
  }

  return evData;
};

const removeAssetEv = (CBEVList, index, configData) => {
  let newEvData = [...CBEVList];
  newEvData[index].uiData.status = false;
  newEvData[index].uiData.detailsBlock = false;
  newEvData[index].uiData.selected = false;
  newEvData[index].uiData.configured = false;
  newEvData[index].uiData.CBConfigured = true;
  newEvData[index].cbConfigData = { ...configData.sampleCbConfigData };
  newEvData[index].cbConfigData.cbAssetDetails = [];
  newEvData[index].cbConfigData.cbCommunicationData = {};
  newEvData[index].configData = { ...configData.evConfigData };
  newEvData[index].assetDetails = [];
  newEvData[index].communicationData = {};

  return CBEVList;
};

const evAssetCBRequired = CBEVList => {
  let newEvData = _.cloneDeep(CBEVList);
  newEvData[0].uiData.cbRequired = !newEvData[0].uiData.cbRequired;
  newEvData[0].uiData.CBConfigured = newEvData[0].uiData.cbRequired && false
  return newEvData;
};
const evAssetPmRequired = CBEVList => {
  let newEvData = _.cloneDeep(CBEVList);
  newEvData[0].uiData.pmConfiguredGrid = false;
  newEvData[0].uiData.pmRequired = !newEvData[0].uiData.pmRequired;

  return newEvData;
};

const evPmSelect = (CBEVList, index) => {
  const newEvAssetList = _.cloneDeep(CBEVList);
  newEvAssetList[index].uiData.pmSelected = true;
  return newEvAssetList;
};

const updateEvPowerMeterConfig = (CBEVList, selectedAssetPM, cbConfigData) => {
  const newPowerMeterData = _.cloneDeep(CBEVList);
  newPowerMeterData[selectedAssetPM].uiData.pmConfigured = true;
  newPowerMeterData[selectedAssetPM].uiData.pmConfiguredGrid = true;
  newPowerMeterData[selectedAssetPM].powerMeterData.name = cbConfigData.name;
  newPowerMeterData[selectedAssetPM].powerMeterData.description =
    cbConfigData.description;

  return newPowerMeterData;
};

const getEvCbIp = cbCommunicationValues => {
  const ifeIp1 = "192.168.50.21";
  const ifeIp2 = "192.168.50.22";
  const ifeIp3 = "192.168.50.23";
  const ifeIp4 = "192.168.50.24";

  const ifeSet1 = cbCommunicationValues.ifeIpSets[ifeIp1]?.[0] ?? 0;
  const ifeSet2 = cbCommunicationValues.ifeIpSets[ifeIp2]?.[0] ?? 0;
  const ifeSet3 = cbCommunicationValues.ifeIpSets[ifeIp3]?.[0] ?? 0;
  const ifeSet4 = cbCommunicationValues.ifeIpSets[ifeIp4]?.[0] ?? 0;

  const ifeSet1Max = cbCommunicationValues.ifeIpSets[ifeIp1]?.[1] ?? 0;
  const ifeSet2Max = cbCommunicationValues.ifeIpSets[ifeIp2]?.[1] ?? 0;
  const ifeSet3Max = cbCommunicationValues.ifeIpSets[ifeIp3]?.[1] ?? 0;
  const ifeSet4Max = cbCommunicationValues.ifeIpSets[ifeIp4]?.[1] ?? 0;

  const ipaddress =
    ifeSet1 !== ifeSet1Max
      ? ifeIp1
      : ifeSet2 !== ifeSet2Max
        ? ifeIp2
        : ifeSet3 !== ifeSet3Max
          ? ifeIp3
          : ifeSet4 !== ifeSet4Max
            ? ifeIp4
            : cbCommunicationValues.availableIps[0];
  return ipaddress;
};

const getCBifeValues = (
  evDataPrevious,
  selectedAsset,
  cbCommunicationValues
) => {
  const numberOfDevicesIFEPrevious = parseInt(
    evDataPrevious.cbAssetDetails[0]?.assetAttributes?.find(
      assetAttribute => assetAttribute.type === "numberOfDevicesIFE"
    )?.value ?? 7
  );
  const numberOfDevicesIFE = parseInt(
    selectedAsset?.assetAttributes?.find(
      assetAttribute => assetAttribute.type === "numberOfDevicesIFE"
    )?.value ?? 7
  );
  if (numberOfDevicesIFEPrevious === numberOfDevicesIFE) {
    return { ...evDataPrevious.cbCommunicationData };
  }

  if (numberOfDevicesIFE === 1) {
    const ipaddress = cbCommunicationValues.availableIps[0];
    return {
      ...evDataPrevious.cbCommunicationData,
      ipaddress: ipaddress,
      modbusSlaveId: "255",
    };
  }

  if (numberOfDevicesIFE === 7) {
    //     const currentIpIfe=cbCommunicationValues.ifeIpSets[
    //       evDataPrevious.cbCommunicationData.ipaddress
    //     ]?.[1]
    //     if(currentIpIfe===1) {
    // let ipaddress=evDataPrevious.cbCommunicationData.ipaddress
    //       let {ipaddress,...cbCommunicationValuesNew}=cbCommunicationValues
    //     }

    return {
      ...evDataPrevious.cbCommunicationData,
      ipaddress: getEvCbIp(cbCommunicationValues),
      modbusSlaveId: cbCommunicationValues.ifeIpSets[
        getEvCbIp(cbCommunicationValues)
      ]
        ? evDataPrevious.cbCommunicationData.modbusSlaveId
        : "255",
    };
  }
};

export { initialCBEVList, CBEVListReducer };
