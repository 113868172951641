 
import React, { useRef } from "react";
import domtoimage from "dom-to-image";

import ApiSession from "../api/ApiSession";
import DesignAsset from "./DesignAssetHook";
import useMountEffect from "./CustomHooks/useMountEffect";
const apiSession = new ApiSession();

const DesignAssetReport = ({ sldStatusCheck }) => {
  useMountEffect(() => {
    const captureSLD = () => {
      const scale = 3  ;
      let node = DesignAssetReportRef.current.FindNode();
      const style = {
        transform: 'scale('+scale+')',
        transformOrigin: 'top left',
        width: node.offsetWidth + "px",
         height: node.offsetHeight + "px"
    }
    
    const param = {
      bgcolor: "#FFFFFF" ,
         height: node.offsetHeight * scale,
         width: node.offsetWidth * scale,
          quality: 1,
         style
    }
      domtoimage.toPng(node, param).then(function (dataUrl) {
        fetch(dataUrl)
          .then(res => res.blob())
          .then(response => {
            let formData = new FormData();
            formData.append("file", response, "SLD.png");
            formData.append("projectId", sessionStorage.getItem("projectId"));
            // Post via axios or other transport method
            apiSession
              .uploadSLDImage(formData)
              .then(json => {
                sldStatusCheck(true);
              })
              .catch(error => {
                sldStatusCheck(true);
              });
          });
      });
    };
    setTimeout(() => {
      captureSLD();
    }, 10000);
  }, []);
  const DesignAssetReportRef = useRef(null);

  return (
    <div
      style={{
        marginTop: "40px",
      }}
    >
      <DesignAsset ref={DesignAssetReportRef} />;
    </div>
  );
};

export default DesignAssetReport;
