import React, { useEffect, useState } from "react";
import RoutingPlcTable from "./RoutingPlcTable";
import { useReducer } from "react";
import { initialRouterState, routerReducer } from "./RoutingPageReducer";
import { useDispatch, useSelector } from "react-redux";
import RoutingIpcTable from "./RoutingIpcTable";
import ApiSession from "../../api/ApiSession";
import { getSecureArchitectureDataRequest } from "../../redux/features/secureArchitectureDataSlice";
const apiSession = new ApiSession();
export const ACTIONS = {
  UPDATED_IPC_DATA: "UPDATED_IPC_DATA",
  FETCH_SUCCESS: "FETCH_SUCCESS",
  ENABLE_TEXTBOX: "ENABLE_TEXTBOX",
  ENABLE_TEXTBOX_ROUTER_METRIC: "ENABLE_TEXTBOX_ROUTER_METRIC",
  RESET_COMMUNICATION: "RESET_COMMUNICATION",
  UPDATE_VLAN_NAME: "UPDATE_VLAN_NAME",
};
const initialCommunicationState = {
  destinationIpAddress: "",
  interfaceVal: "",
  isModifiable: "",
  metric: "",
  vlanToRoute: "",
  cidr: "",
  id: "",
};
const communicationReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATED_IPC_DATA:
      return {
        ...state,
        destinationIpAddress: action.payload.destinationIpAddress,
        interfaceVal: action.payload.interfaceVal,
        isModifiable: action.payload.isModifiable,
        metric: action.payload.metric,
        vlanToRoute: action.payload.vlanToRoute,
        cidr: action.payload.cidr,
        id: action.payload.id,
      };
    case ACTIONS.ENABLE_TEXTBOX_ROUTER_METRIC:
      return {
        ...state,
        metric: action.payload.metric,
      };
    case "vlanToRoute":
      return {
        ...state,
        vlanToRoute: action.payload.vlanToRoute,
      };
    case "destinationIpAddress":
      return {
        ...state,
        destinationIpAddress: action.payload.destinationIpAddress,
      };

    case "interfaceVal":
      return {
        ...state,
        interfaceVal: action.payload.interfaceVal,
      };

    case "metric":
      return {
        ...state,
        metric: action.payload.metric,
      };
    case "cidr":
      return {
        ...state,
        cidr: action.payload.cidr,
      };
    case "id":
      return {
        ...state,
        id: action.payload.id,
      };
    case ACTIONS.RESET_COMMUNICATION:
      return initialCommunicationState;
    default:
      return state;
  }
};

const RoutingSettingsHook = ({ siteCreated, projectStatusData }) => {
  const secureArchitectureData = useSelector(
    (state) => state?.secureArchitectureData?.data
  );
  const [customRowCount, setCustomRowCount] = useState(0);
  const [routerCommunicationData, setRouterCommunicationData] = useState([]);
  const [routerCommunicationUpdateData, setRouterCommunicationUpdateData] =
    useState({
      id: "",
      vlanToRoute: "",
      interfaceVal: "",
      isModifiable: true,
      metric: "",
    });
  const dispatch = useDispatch();
  let updatedArray = [];

  const metricArray = Array.from({ length: 999 }, (_, index) => index + 1);
  const [isValidIp, setIsValidIp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [componentId, setComponentId] = useState({});
  const [selectedVlanName, setSelectedVlanName] = useState("GTWY");
  const [selectedInterface, setSelectedInterface] = useState("Eth1");
  const [selectedMetricValues, setSelectedMetricValues] = useState("");
  const [enableCustomRoute, setEnableCustomRoute] = useState(false);
  const [ipcRouterData, setIpcRouterData] = useState([]);
  const [ipcRouterRowData, setIpcRouterRowData] = useState({
    isModifiable: true,
  });
  const [routerState, routerDispatch] = useReducer(
    routerReducer,
    initialRouterState
  );
  const [communicationState, communicationDispatch] = useReducer(
    communicationReducer,
    initialCommunicationState
  );

  useEffect(() => {
    setIpcRouterData(secureArchitectureData?.routingIPCProjectMappingDtoList);
    let routerComInfo = {};
    let routerIpcComInfo = {};
    secureArchitectureData?.routingPLCProjectMappingDtoList?.map(
      (routerSettings) => {
        setComponentId((prevState) => ({
          ...prevState,
          [routerSettings.id]: false,
        }));
        routerComInfo[routerSettings.id] = routerSettings;
        return null;
      }
    );

    ipcRouterData?.map((routerSettings) => {
      setComponentId((prevState) => ({
        ...prevState,
        [routerSettings.id]: false,
      }));
      routerIpcComInfo[routerSettings.id] = routerSettings;
      return null;
    });
    setRouterCommunicationData(secureArchitectureData);
    routerDispatch({
      type: ACTIONS.FETCH_SUCCESS,
      payload: {
        routingPLCProjectMappingDtoList:
          secureArchitectureData?.routingPLCProjectMappingDtoList,
        routingIPCProjectMappingDtoList:
          secureArchitectureData?.routingIPCProjectMappingDtoList,
        securedCommunicationArchitecture:
          secureArchitectureData?.securedCommunicationArchitecture,
      },
    });
    setCustomRowCount(
      secureArchitectureData?.routingIPCProjectMappingDtoList?.length
    );
  }, [secureArchitectureData]);
  useEffect(() => {
    let routerIpcComInfo = {};
    ipcRouterData?.map((routerSettings) => {
      setComponentId((prevState) => ({
        ...prevState,
        [routerSettings.id]: false,
      }));
      routerIpcComInfo[routerSettings.id] = routerSettings;
      return null;
    });
  }, [ipcRouterData]);
  const handleChange = (data, e) => {
    secureArchitectureData?.routingIPCProjectMappingDtoList?.forEach(
      (routerSettings) => {
        updatedArray.push(routerSettings.metric);
      }
    );
    if (e.target.name === "metric" && e.target.value !== "") {
      const value = JSON.parse(e.target.value);
      if (data.metric === value) {
        setIsValidIp(false);
      } else {
        if (!updatedArray.includes(value)) {
          communicationDispatch({
            type: "id",
            payload: { ["id"]: data.id },
          });
          communicationDispatch({
            type: e.target.name,
            payload: { [e.target.name]: e.target.value },
          });
          setIsValidIp(false);
        } else {
          setIsValidIp(true);
        }
      }
    }
    if (
      ["vlanToRoute", "interfaceVal"].includes(e.target.name) &&
      e.target.value !== ""
    ) {
      communicationDispatch({
        type: "id",
        payload: { ["id"]: data.id },
      });
      communicationDispatch({
        type: e.target.name,
        payload: { [e.target.name]: e.target.value },
      });
      setIsValidIp(false);
    }
  };
  const enableMetricTextBox = (id, data) => {
    let routerIpcComInfo = {};
    secureArchitectureData?.routingIPCProjectMappingDtoList?.map(
      (routerSettings) => {
        setComponentId((prevState) => ({
          ...prevState,
          [routerSettings.id]: false,
        }));
        routerIpcComInfo[routerSettings.id] = routerSettings;
        return null;
      }
    );
    setEnableCustomRoute(false);
    communicationDispatch({
      type: ACTIONS.UPDATED_IPC_DATA,
      payload: data,
    });
    setComponentId((prevState) => ({ ...prevState, [data.id]: true }));
    setIsValidIp(false);
  };
  const cancelRowData = (data) => {
    setComponentId((prevState) => ({ ...prevState, [data.id]: false }));
    resetCommunicationSettings();
    communicationDispatch({
      type: ACTIONS.UPDATED_IPC_DATA,
      payload: data,
    });
  };
  const deleteRowData = (data) => {
    const projectId = sessionStorage.getItem("projectId");
    setComponentId((prevState) => ({ ...prevState, [data.id]: false }));
    resetCommunicationSettings();
    setLoading(true);
    const newArr = updatedArray.filter((num) => num !== data.metric);
    updatedArray = newArr;
    apiSession.deleteIpcRowData(data.id, projectId).then((response) => {
      dispatch(
        getSecureArchitectureDataRequest(sessionStorage.getItem("projectId"))
      );

      setTimeout(() => {
        setLoading(false);
      }, [1000]);
    });
  };
  const resetCommunicationSettings = () =>
    communicationDispatch({ type: ACTIONS.RESET_COMMUNICATION });

  const upDatePlc = (data, id, e) => {
    let routerComInfo = {};
    secureArchitectureData?.routingPLCProjectMappingDtoList?.map(
      (routerSettings) => {
        setComponentId((prevState) => ({
          ...prevState,
          [routerSettings.id]: false,
        }));
        routerComInfo[routerSettings.id] = routerSettings;
        return null;
      }
    );
    const plcDataNew = routerCommunicationData?.routingPLCProjectMappingDtoList;
    if (e.target.name === "vlanToRoute") {
      const updatePlcData = plcDataNew.find(
        (plc) => plc.vlanToRoute === e.target.value
      );
      const updatedRoutingConfigurations = plcDataNew?.map((config) => {
        if (id === config.secureComArchId) {
          return {
            ...config,
            ["vlanToRoute"]: e.target.value,
            ["destinationIpAddress"]: updatePlcData.destinationIpAddress,
          };
        } else {
          return {
            ...config,
          };
        }
      });
      setComponentId((prevState) => ({ ...prevState, [data.id]: true }));
      setRouterCommunicationData({
        ...routerCommunicationData,
        routingPLCProjectMappingDtoList: updatedRoutingConfigurations,
      });
    }
    if (e.target.name === "interfaceVal") {
      const gateway =
        e.target.value === "Eth1" ? "192.168.99.17" : "192.168.99.49";
      const updatedRoutingConfigurations = plcDataNew?.map((config) => {
        if (id === config.secureComArchId) {
          return {
            ...config,
            ["interfaceVal"]: e.target.value,
            ["gateWayViaVlan"]: gateway,
          };
        } else {
          return {
            ...config,
          };
        }
      });
      setComponentId((prevState) => ({ ...prevState, [data.id]: true }));
      setRouterCommunicationData({
        ...routerCommunicationData,
        routingPLCProjectMappingDtoList: updatedRoutingConfigurations,
      });
    }
  };
  const addCustomRouteRowCount = () => {
    let routerIpcComInfo = {};
    secureArchitectureData?.routingIPCProjectMappingDtoList?.map(
      (routerSettings) => {
        setComponentId((prevState) => ({
          ...prevState,
          [routerSettings.id]: false,
        }));
        routerIpcComInfo[routerSettings.id] = routerSettings;
        return null;
      }
    );
    if (customRowCount < 9) {
      setCustomRowCount((prevCount) => prevCount + 1);
      setEnableCustomRoute(true);
      const rowData = routerState.securedCommunicationArchitecture?.filter(
        (data) => data.vlan === selectedVlanName
      )[0];

      communicationDispatch({
        type: ACTIONS.UPDATED_IPC_DATA,
        payload: {
          destinationIpAddress: rowData.networkAddress,
          interfaceVal: selectedInterface,
          isModifiable: true,
          metric: selectedMetricValues,
          vlanToRoute: rowData.vlan,
          cidr: rowData.cidr,
        },
      });
    }
  };
  const cancelCustomRouteRow = () => {
    if (customRowCount > 0) {
      setEnableCustomRoute(false);
      setCustomRowCount(customRowCount - 1);
      // setCustomRowCount(customRowCount - 1);
      setIpcRouterRowData({ isModifiable: true });
      setSelectedMetricValues("");
    }
  };
  const updateIpcRowData = () => {
    const projectId = sessionStorage.getItem("projectId");
    delete communicationState.cidr;
    delete communicationState.destinationIpAddress;
    const ipcUpdatedData = {
      projectId: projectId,
      routingIPCProjectMappingDtoList: [communicationState],
    };
    setLoading(true);
    setEnableCustomRoute(false);
    apiSession.updateIpcRowData(ipcUpdatedData).then((response) => {
      if (response) {
        dispatch(
          getSecureArchitectureDataRequest(sessionStorage.getItem("projectId"))
        );
        console.log(response);
        setTimeout(() => {
          setLoading(false);
          setSelectedMetricValues("");
        }, [1000]);
      }
    });
    let routerIpcComInfo = {};
    secureArchitectureData?.routingIPCProjectMappingDtoList?.map(
      (routerSettings) => {
        setComponentId((prevState) => ({
          ...prevState,
          [routerSettings.id]: false,
        }));
        routerIpcComInfo[routerSettings.id] = routerSettings;
        return null;
      }
    );
  };
  const saveData = () => {
    // console.log(routerCommunicationData);
  };

  const handleIpcData = (e) => {
    const { name, value } = e.target;
    const ipc_id = routerState.securedCommunicationArchitecture?.filter(
      (data) => data.vlan === value
    )[0]?.id;
    if (name === "vlanToRoute") {
      setIpcRouterRowData((prev) => ({
        ...prev,
        id: ipc_id,
        [name]: value,
      }));
    }
  };
  return (
    <>
      <se-loading loading={loading} full-page="true"></se-loading>
      <div>
        <div
          className={
            siteCreated || projectStatusData?.projectStatus === "COMPLETED"
              ? "se-block-disabled row"
              : "row"
          }
        >
          <div className="col-md-12">
            <span className="se-font-14px-bold se-life-green">
              PLC Routing Table Settings
            </span>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr className="text-center">
                    <th>VLAN Name</th>
                    <th>Destination Address</th>
                    <th>Mask</th>
                    <th>Interface</th>
                    <th>Gateway</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {routerState &&
                    routerState?.routingPLCProjectMappingDtoList?.map(
                      (data) => (
                        <RoutingPlcTable
                          key={data.id}
                          data={data}
                          plcData={routerState?.routingPLCProjectMappingDtoList}
                          deleteRowData={deleteRowData}
                          upDatePlc={upDatePlc}
                          componentId={componentId}
                        />
                      )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          className={
            siteCreated || projectStatusData?.projectStatus === "COMPLETED"
              ? "se-block-disabled row"
              : "row"
          }
        >
          <div className="col-md-12">
            <span className="se-font-14px-bold se-life-green">
              IPC Routing Table Settings
            </span>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr className="text-center">
                    <th>VLAN Name</th>
                    <th>Destination Address</th>
                    <th>CIDR</th>
                    <th>Interface</th>
                    <th>Gateway</th>
                    <th>Metric</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {ipcRouterData?.map((data, index) => (
                    <RoutingIpcTable
                      key={data.id}
                      data={data}
                      index={index}
                      ipcData={ipcRouterData}
                      secureData={routerState?.securedCommunicationArchitecture}
                      deleteRowData={deleteRowData}
                      componentId={componentId}
                      enableMetricTextBox={enableMetricTextBox}
                      handleChange={handleChange}
                      updateIpcRowData={updateIpcRowData}
                      isValidIp={isValidIp}
                      cancelRowData={cancelRowData}
                    />
                  ))}
                  {customRowCount < 9 && (
                    <tr>
                      {
                        <>
                          {enableCustomRoute ? (
                            <>
                              <td>
                                <select
                                  className="col-sm-12 col-lg-12 col-md-12 col-xl-12  form-control form-control-sm"
                                  name="vlanToRoute"
                                  value={selectedVlanName}
                                  onChange={(e) => {
                                    setSelectedVlanName(e.target.value);
                                    handleChange(
                                      routerState.securedCommunicationArchitecture?.filter(
                                        (data) => data.vlan === selectedVlanName
                                      )[0],
                                      e
                                    );
                                  }}
                                >
                                  {routerState?.securedCommunicationArchitecture?.map(
                                    (vlanData) => (
                                      <option
                                        key={vlanData.id}
                                        value={vlanData.vlan}
                                      >
                                        {vlanData.vlan}
                                      </option>
                                    )
                                  )}
                                </select>
                              </td>
                              <td className="text-center">
                                {
                                  routerState.securedCommunicationArchitecture?.filter(
                                    (data) => data.vlan === selectedVlanName
                                  )[0]?.networkAddress
                                }
                              </td>
                              <td className="text-center">
                                {
                                  routerState.securedCommunicationArchitecture?.filter(
                                    (data) => data.vlan === selectedVlanName
                                  )[0]?.cidr
                                }
                              </td>
                              <td className="text-center">
                                {" "}
                                <select
                                  className="col-sm-12 col-lg-12 col-md-12 col-xl-12  form-control form-control-sm"
                                  name="interfaceVal"
                                  value={selectedInterface}
                                  onChange={(e) => {
                                    setSelectedInterface(e.target.value);
                                    handleIpcData(
                                      routerState.securedCommunicationArchitecture?.filter(
                                        (data) => data.vlan === selectedVlanName
                                      )[0]?.id,
                                      e
                                    );
                                  }}
                                >
                                  <option value="Eth1">Eth1</option>
                                  <option value="Eth2">Eth2</option>
                                </select>
                              </td>
                              <td className="text-center">
                                {selectedInterface === "Eth1"
                                  ? "192.168.99.33"
                                  : "192.168.99.17"}
                              </td>
                              <td>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    autoComplete="none"
                                    className={"form-control form-control-sm "}
                                    id="exampleFormControlInput2"
                                    name="metric"
                                    maxLength="3"
                                    placeholder="Enter metric value"
                                    value={selectedMetricValues}
                                    onChange={(e) => {
                                      handleChange(
                                        routerState.securedCommunicationArchitecture?.filter(
                                          (data) =>
                                            data.vlan === selectedVlanName
                                        )[0],
                                        e
                                      );
                                      setSelectedMetricValues(e.target.value);
                                    }}
                                  />
                                </div>
                                <div
                                  className="invalid-feedback-custom"
                                  style={
                                    selectedMetricValues === ""
                                      ? {}
                                      : { display: "none" }
                                  }
                                >
                                  Metric value is required.
                                </div>
                                {selectedMetricValues !== "" && (
                                  <div
                                    className="invalid-feedback-custom"
                                    style={isValidIp ? {} : { display: "none" }}
                                  >
                                    Already used this metric value.
                                  </div>
                                )}
                              </td>
                            </>
                          ) : (
                            <>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </>
                          )}
                          <td className="text-center">
                            {enableCustomRoute ? (
                              <>
                                <se-icon
                                  size="medium"
                                  color="primary"
                                  title="Click to update"
                                  onClick={
                                    selectedMetricValues !== "" && !isValidIp
                                      ? updateIpcRowData
                                      : undefined
                                  }
                                  disabled={
                                    selectedMetricValues !== "" && !isValidIp
                                      ? false
                                      : true
                                  }
                                >
                                  notification_ok_stroke
                                </se-icon>
                                <se-icon
                                  size="medium"
                                  title="Click to cancel"
                                  onClick={cancelCustomRouteRow}
                                  style={{ color: "red" }}
                                >
                                  action_delete_cross
                                </se-icon>
                              </>
                            ) : (
                              <>
                                <se-icon
                                  size="medium"
                                  title="Click to add"
                                  color={customRowCount === 8 ? "" : "primary"}
                                  onClick={
                                    customRowCount === 8
                                      ? undefined
                                      : addCustomRouteRowCount
                                  }
                                  disabled={customRowCount === 8 ? true : false}
                                >
                                  action_add_flat
                                </se-icon>
                              </>
                            )}
                          </td>
                        </>
                      }
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
            <button
              type="button"
              className="btn btn-secondary btn-sm se-btn-primary"
              onClick={saveData}
            >
              <se-icon size="nano">action_save</se-icon>
              <span>&nbsp;</span>Validate
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default RoutingSettingsHook;
