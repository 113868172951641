import ACTIONS from "../Design_Asset_Actions.json";
import _ from "lodash";

import embLabels from "../../../config/emb-labels-en.json";

const communicationDataSample = {
  ipaddress: "",
  subnetmask: "",
  defaultGateway: "",
  modbusSlaveId: "",
};
let count = 0;

const gensetAssetSample = {
  assetAttributes: [],
  assetEnabled: true,
  assetId: "",
  assetInfo: "",
  assetProperties: [],
  assetType: "",
  comments: "",
  communicationModel: "",
  imageLink: null,
  manufacturer: "",
};

const initialCBAssetList = [
  {
    modbusSlaveId: "255",
    assetType: "Utility",
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
      cbAssetDetails: [],
      cbCommunicationData: {},
    },
    configData: {
      name: "",
      description: "",
      utilityCO2: "",
      maximum_available_power: 0,
    },
    uiData: {
      configured: false,
      configurable: false,
      status: false,
      detailsBlock: false,
      cbRequired:false,
      selected: false,
      CBSelected: false,
      CBConfigured: false,
      pmSelected: false,
      pmConfigured: true,
      pmRequired: false,
      pmConfiguredGrid: false,
      xAxisCB: 250,
      yAxisCB: 192,
      xAxis: 150,
      yAxis: 70,
      x1pos: 600,
      y1pos: 301,
      x2pos: 600,
      y2pos: 156,
    },
    communicationData: {},
    powerMeterData: {
      name: "",
      description: "",
      powerMeterModbusId: "",
      ipAddress: "",
      gatewayAddress: "",
      mask: "",
      pmAsset: {},
    },
  },
  {
    modbusSlaveId: "2",
    assetType: "Genset",
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
      cbAssetDetails: [],
      cbCommunicationData: {},
    },
    configData: {
      name: "",
      description: "",
      min_power: "",
      max_power: "",
      gensetCO2: "",
      starting_timer: "",
      cooling_timer: "",
      minimum_running_period: "",
    },
    uiData: {
      configured: false,
      status: false,
      configurable: false,
      detailsBlock: false,
      selected: false,
      CBSelected: false,
      CBConfigured: false,
      pmSelected: false,
      pmConfigured: true,
      xAxisCB: 360,
      yAxisCB: 192,
      xAxisPM: 0,
      yAxisPM: 0,
      xAxis: 260,
      yAxis: 70,
      x1pos: 675,
      y1pos: 350,
      x2pos: 790,
      y2pos: 350,
    },
    assetDetails: [],
    communicationData: {},
    powerMeterData: {
      name: "",
      description: "",
      powerMeterModbusId: "",
      ipAddress: "",
      gatewayAddress: "",
      mask: "",
      pmAsset: {},
    },
    selectedAssetData: {},
  },
  {
    modbusSlaveId: "3",
    assetType: "BESS",
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
      cbAssetDetails: [],
      cbCommunicationData: {},
    },
    contactorConfigData: {
      name: "",
      description: "",
      loadCategory: 0,
      loadPriority: 0,
      cbAssetDetails: [],
      cbCommunicationData: {},
    },
    configData: {
      name: "",
      description: "",
      max_power: "",
      capacity: 0,
      soc_max: "",
      soc_min: "",
      soc_nominal_power: "",
      starting_timer: "",
    },
    uiData: {
      configured: false,
      status: false,
      detailsBlock: false,
      selected: false,
      CBConfigured: false,
      pmRequired: false,
      cbRequired: true,

      pmSelected: false,
      pmConfigured: true,
      existingPmSelected: false,
      existingPmConfigured: true,
      existingPmRequired: false,
      xAxisCB: 470,
      yAxisCB: 192,
      xAxis: 370,
      yAxis: 70,
      xAxisPM: 0,
      yAxisPM: 0,
      x1pos: 600,
      y1pos: 301,
      x2pos: 600,
      y2pos: 156,
    },
    assetDetails: [],
    communicationData: {},
    powerMeterData: {
      name: "",
      description: "",
      powerMeterModbusId: "",
      ipAddress: "",
      gatewayAddress: "",
      mask: "",
      pmAsset: {},
    },
    existingPowerMeterData: {
      name: "",
      description: "",
      powerMeterModbusId: "",
      ipAddress: "",
      gatewayAddress: "",
      mask: "",
      pmAsset: {},
    },
  },
  {
    modbusSlaveId: "4",
    assetType: "Inverter",
    pv: 1,
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
      cbAssetDetails: [],
      cbCommunicationData: {},
    },
    contactorConfigData: {
      name: "",
      description: "",
      loadCategory: 0,
      loadPriority: 0,
      cbAssetDetails: [],
      cbCommunicationData: {},
    },
    configData: {
      name: "",
      description: "",
    },
    uiData: {
      set: 1,
      configured: false,
      status: false,
      detailsBlock: false,
      selected: false,
      CBConfigured: false,
      cbRequired: true,
      pmSelected: false,
      pmConfigured: true,
      pmRequired: false,
      pmConfiguredGrid: false,
      existingPmSelected: false,
      existingPmConfigured: true,
      existingPmRequired: false,
      existingPmConfiguredGrid: false,
      count: 0,
      xAxisCB: 470,
      yAxisCB: 192,
      xAxis: 370,
      yAxis: 70,
      xAxisPM: 0,
      yAxisPM: 0,
      x1pos: 600,
      y1pos: 301,
      x2pos: 600,
      y2pos: 156,
    },
    communicationData: {},
    subPvData: [],
    powerMeterData: {
      name: "",
      description: "",
      powerMeterModbusId: "",
      ipAddress: "",
      gatewayAddress: "",
      mask: "",
      pmAsset: {},
    },
    existingPowerMeterData: {
      name: "",
      description: "",
      powerMeterModbusId: "",
      ipAddress: "",
      gatewayAddress: "",
      mask: "",
      pmAsset: {},
    },
  },
  {
    modbusSlaveId: "5",
    assetType: "Inverter",
    pv: 2,
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
      cbAssetDetails: [],
      cbCommunicationData: {},
    },
    contactorConfigData: {
      name: "",
      description: "",
      loadCategory: 0,
      loadPriority: 0,
      cbAssetDetails: [],
      cbCommunicationData: {},
    },
    configData: {
      name: "",
      description: "",
    },
    uiData: {
      set: 2,
      configured: false,
      status: false,
      detailsBlock: false,
      selected: false,
      CBConfigured: false,
      pmSelected: false,
      pmConfigured: true,
      cbRequired: true,
      count: 0,
      xAxisCB: 470,
      yAxisCB: 192,
      xAxis: 370,
      yAxis: 70,
    },
    communicationData: {},
    subPvData: [],
    powerMeterData: {
      name: "",
      description: "",
      powerMeterModbusId: "",
      ipAddress: "",
      gatewayAddress: "",
      mask: "",
      pmAsset: {},
    },
  },
  {
    modbusSlaveId: "6",
    assetType: "Inverter",
    pv: 3,
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
      cbAssetDetails: [],
      cbCommunicationData: {},
    },
    contactorConfigData: {
      name: "",
      description: "",
      loadCategory: 0,
      loadPriority: 0,
      cbAssetDetails: [],
      cbCommunicationData: {},
    },
    configData: {
      name: "",
      description: "",
    },
    uiData: {
      set: 3,
      configured: false,
      status: false,
      detailsBlock: false,
      selected: false,
      CBConfigured: false,
      cbRequired: true,
      pmSelected: false,
      pmConfigured: true,
      count: 0,
      xAxisCB: 470,
      yAxisCB: 192,
      xAxis: 370,
      yAxis: 70,
    },
    communicationData: {},
    subPvData: [],
    powerMeterData: {
      name: "",
      description: "",
      powerMeterModbusId: "",
      ipAddress: "",
      gatewayAddress: "",
      mask: "",
      pmAsset: {},
    },
  },
  {
    modbusSlaveId: "7",
    assetType: "CHP",
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
    },
    configData: {
      name: "",
      description: "",
      nominal_power: "",
    },
    uiData: {
      configured: false,
      status: false,
      configurable: false,
      detailsBlock: false,
      selected: false,
      CBSelected: false,
      CBConfigured: false,
      pmSelected: false,
      pmConfigured: true,
      xAxisCB: 360,
      yAxisCB: 192,
      xAxis: 260,
      yAxis: 70,
      x1pos: 600,
      y1pos: 301,
      x2pos: 600,
      y2pos: 156,
    },
    assetDetails: [],
    communicationData: {},
    powerMeterData: {
      name: "",
      description: "",
      powerMeterModbusId: "",
    },
  },
  {
    modbusSlaveId: "8",
    assetType: "Hybrid",
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
      cbAssetDetails: [],
      cbCommunicationData: {},
    },
    configData: {
      name: "",
      description: "",
      max_capacity: "",
      capacity: 0,
      max_solar_power: "",
      max_inverter_output_power: "",
      storage_capcity_hr: [],
      corresponding_name_plate_capacity: [],
    },
    uiData: {
      configured: false,
      status: false,
      detailsBlock: false,
      selected: false,
      CBConfigured: false,
      pmSelected: false,
      pmConfigured: true,
      xAxisCB: 470,
      yAxisCB: 192,
      xAxis: 370,
      yAxis: 70,
      x1pos: 600,
      y1pos: 301,
      x2pos: 600,
      y2pos: 156,
    },
    assetDetails: [],
    communicationDataBess: {},
    communicationDataPv: {},
  },
];
const projectTypeValue = JSON.parse(
  localStorage.getItem("projectType")
)?.projectTypeValue;

const CBAssetListReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.ASSIGN_DEFAULT_VALUES:
      return assignDefaultValuesCBAssetList(
        state,
        action.payload.defaultValues,
        action.payload.defaults,
        action.payload.type,
        action.payload.architecture,
        action.payload.genset,
        action.payload.pmUtility,
        action.payload.pmGenset,
        action.payload.pmPv,
        action.payload.bess,
        action.payload.hybrid,
        action.payload.cb,
        action.payload.pmBess,
        action.payload.gridSettings,
        action.payload.powerMeter,
        action.payload.contactor
      );
    case ACTIONS.ASSIGN_USECASE_UTILITY:
      return assignUseCaseDer(state, 0, action.payload.defaultValues);
    case ACTIONS.ASSIGN_USECASE_GENSET:
      return assignUseCaseDer(
        state,
        1,
        action.payload.defaultValues,
        action.payload.projectTypeA2E
      );
    case ACTIONS.ASSIGN_USECASE_BESS:
      return assignUseCaseDer(state, 2, action.payload.defaultValues);
    case ACTIONS.ASSIGN_USECASE_PV:
      return assignUseCaseDer(state, 3, action.payload.defaultValues);
    case ACTIONS.ASSIGN_USECASE_HYBRID:
      return assignUseCaseDer(state, 7, action.payload.defaultValues);
    case ACTIONS.UPDATE_CBASSETLIST_COORDINATES:
      return updateCbAssetListCoordinates(state, action.payload.coordinates);
    case ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED:
      return updateCbAssetListCoordinatesGridConnected(
        state,
        action.payload.coordinates
      );
    case ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVHYBRIDIZATION:
      return updateCbAssetListCoordinatesPvHybridization(
        state,
        action.payload.coordinates
      );
    case ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVGENSET_ATS:
      return updateCbAssetListCoordinatesPvGensetAts(state);
    case ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVBESS_NR:
      return updateCbAssetListCoordinatesPvBessNr(state, action.payload);
    case ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVGENSET_ATS_SETTING_MODE:
      return updateCbAssetListCoordinatesGensetPvBess(state, action.payload);
    case ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GENSET_PVBESS_A2E:
      return updateCbAssetListCoordinatesGensetPvBessA2E(state);
    case ACTIONS.UPDATE_UTILITY:
      return updateUtility(state, action.payload.componentsData);
    case ACTIONS.UPDATE_GENSET:
      return updateGenset(state, action.payload.componentsData);
    case ACTIONS.UPDATE_BESS:
      return updateBess(
        state,
        action.payload.componentsData,
        action.payload.batteryCommunicationData,
        action.payload.setBatteryCommunicationData
      );
    case ACTIONS.UPDATE_CHP:
      return updateChp(state, action.payload.componentsData);
    case ACTIONS.UPDATE_HYBRID:
      return updateHybrid(state, action.payload.componentsData);
    case ACTIONS.UPDATE_PV:
      return updatePv(
        state,
        action.payload.componentsData,
        action.payload.pvArray
      );
    case ACTIONS.ADD_GENSET:
      return addGenset(
        state,
        action.payload.defaultPreFill,
        action.payload.configData,
        action.payload.count,
        action.payload.cbCommunicationValues
      );
    case ACTIONS.ADD_BESS:
      return addBess(
        state,
        action.payload.defaultPreFill,
        action.payload.configData,
        action.payload.count,
        action.payload.cbCommunicationValues,
        action.payload.defaultCbData
      );
    case ACTIONS.ADD_BESS_EXISTIING:
      return addBessExisting(state);
    case ACTIONS.ADD_CHP:
      return addChp(
        state,
        action.payload.defaultPreFill,
        action.payload.configData,
        action.payload.siteType
      );
    case ACTIONS.REMOVE_ASSET_DER:
      return removeAssetDer(
        state,
        action.payload.index,
        action.payload.configData
      );
    case ACTIONS.REMOVE_ASSET_DER_PV:
      return removeAssetDerPv(state, action.payload.selectedPv);
    case ACTIONS.REMOVE_ASSET_EXISTING:
      return removeAssetExistingPm(state, action.payload.selectedPv);
    case ACTIONS.UPDATE_DER_CB:
      return updateDerCb(
        state,
        action.payload.componentsData,
        action.payload.value
      );
    case ACTIONS.UPDATE_DER_CB_CONTACTOR:
      return updateDerCbContactor(
        state,
        action.payload.componentsData,
        action.payload.value
      );
    case ACTIONS.UPDATE_DER_PM:
      return updateDerPm(
        state,
        action.payload.componentsData,
        action.payload.value
      );
    case ACTIONS.UPDATE_DER_EXISTING_PM:
      return updateDerExistingPm(
        state,
        action.payload.componentsData,
        action.payload.value
      );
    case ACTIONS.UPDATE_PV_CONFIRGURED:
      return updatePvConfigured(state, action.payload.PVDetails);
    case ACTIONS.CLOSEDETAILS_ASSET:
      return closeDetailsAsset(state);
    case ACTIONS.ASSET_SELECT:
      return assetSelect(state, action.payload.index);
    case ACTIONS.UPDATE_ASSET_CONFIG:
      return updateAssetConfig(
        state,
        action.payload.selectedAsset,
        action.payload.configData
      );
    case ACTIONS.DUPLICATE_DER_UPDATE:
      return duplicateDerUpdate(state, action.payload.selectedAsset);
    case ACTIONS.ASSET_CB_SELECT:
      return assetCbSelect(state, action.payload.index);
    case ACTIONS.ASSET_PM_SELECT:
      return assetPmSelect(state, action.payload.index);
    case ACTIONS.ASSET_PM_SELECT_EXISTING:
      return assetPmSelectExisting(state, action.payload.index);
    case ACTIONS.UPDATE_CB_CONFIG:
      return updateCbConfig(
        state,
        action.payload.selectedAssetCB,
        action.payload.cbConfigData
      );

    case ACTIONS.UPDATE_CB_CONTACTOR_CONFIG:
      return updateCbContactorConfig(
        state,
        action.payload.selectedAssetCB,
        action.payload.cbConfigData
      );
    case ACTIONS.UPDATE_POWERMETER_CONFIG:
      return updatePowerMeterConfig(
        state,
        action.payload.selectedAssetPM,
        action.payload.cbConfigData
      );
    case ACTIONS.UPDATE_EXISTING_POWERMETER_CONFIG:
      return updateExistingPowerMeterConfig(
        state,
        action.payload.selectedAssetPM,
        action.payload.cbConfigData
      );
    case ACTIONS.SELECT_DER_ASSET:
      return selectDerAsset(
        state,
        action.payload.index,
        action.payload.selectedAsset
      );
    case ACTIONS.SELECT_DER_CBASSET:
      return selectDerCbAsset(
        state,
        action.payload.index,
        action.payload.selectedAsset,
        action.payload.cbCommunicationValues,
        action.payload.CBValues,
        action.payload.defaultValues,
        action.payload.count
      );
    case ACTIONS.SELECT_DER_CB_CONTACTOR_ASSET:
      return selectDerCbContactorAsset(
        state,
        action.payload.index,
        action.payload.selectedAsset
      );
    case ACTIONS.SELECT_DER_PMASSET:
      return selectDerPmAsset(
        state,
        action.payload.index,
        action.payload.selectedAsset,
        action.payload.defaultValues
      );
    case ACTIONS.SELECT_DER_EXISTING_PMASSET:
      return selectDerExistingPmAsset(
        state,
        action.payload.index,
        action.payload.selectedAsset,
        action.payload.defaultValues,
        action.payload.existingPMBlock
      );
    case ACTIONS.SELECT_SERIAL_ASSET:
      return selectSerialAsset(
        state,
        action.payload.index,
        action.payload.modbusSlaveId
      );
    case ACTIONS.SELECT_COMMUNICATING_ASSET:
      return selectCommunicatingAsset(
        state,
        action.payload.index,
        action.payload.preFillDefault
      );
    case ACTIONS.INDIVIDUAL_PV_ASSET_SELECT:
      return individualPvAssetSelect(state, action.payload.selectedPv);
    case ACTIONS.REDUCE_PV_COUNT_FROM_CB:
      return reducePvCountFromCb(
        state,
        action.payload.selectedPv,
        action.payload.pvConStatusOne
      );
    case ACTIONS.ADD_PV_TO_CBASSET_LIST:
      return addPvToCbAssetList(
        state,
        action.payload.selectedPv,
        action.payload.PVDetails,
        action.payload.configData,
        action.payload.preFillDefault,
        action.payload.count,
        action.payload.cbCommunicationValues,
        action.payload.defaultCbData
      );
    case ACTIONS.ADD_PV_EXISTING:
      return addPvExistingToCbAssetList(state);
    case ACTIONS.PV_ASSET_PM_REQUIRED:
      return pvAssetPmRequired(
        state,
        action.payload.index,
        action.payload.defaultPmData,
        action.payload.defaultValues
      );
    case ACTIONS.CB_ASSET_REQUIRED:
      return cbAssetRequired(
        state,
        action.payload.index,
        action.payload.defaultCbData,
        action.payload.defaultValues,
        action.payload.dummyCbData
      );
    case ACTIONS.CB_ASSET_CONFIGURED_REQUIRED:
      return cbAssetConfiguredRequired(
        state,
        action.payload.index,
        action.payload.defaultCbData
      );
    case ACTIONS.SELECTED_ASSET:
      return setSelectedAsset(state, action.payload.selectedAsset);

    case ACTIONS.UPDATE_BATTERY_CAPACITY_ON_BATTERY_RACK:
      return updateBatteryCapacityUsingrack(
        state,
        action.payload.index,
        action.payload.batteryRack,
        action.payload.corresponding_name_plate_capacity_value
      );
    case ACTIONS.UPDATE_BATTERY_PCU_CAPACITY_ON_BATTERY_RACK:
      return updateBatteryPCUCapacityUsingrack(
        state,
        action.payload.index,
        action.payload.capacity
      );

    default:
      return state;
  }
};

const updateBatteryPCUCapacityUsingrack = (CBAssetList, index, capacity) => {
  const newCBassetList = _.cloneDeep(CBAssetList);
  newCBassetList[index].configData.capacity = capacity;
  return newCBassetList;
};

const updateBatteryCapacityUsingrack = (
  CBAssetList,
  index,
  batteryRack,
  corresponding_name_plate_capacity_value
) => {
  const newCBassetList = _.cloneDeep(CBAssetList);

  if (batteryRack === "2") {
    newCBassetList[index].configData.max_capacity = 240;
  } else {
    newCBassetList[index].configData.max_capacity = 120;
  }
  newCBassetList[index].configData.corresponding_name_plate_capacity_value =
    corresponding_name_plate_capacity_value;
  return newCBassetList;
};

const setSelectedAsset = (CBAssetList, seletedAsset) => {
  const CBAssestListData = _.cloneDeep(CBAssetList);

  CBAssestListData[1].selectedAssetData = { ...seletedAsset };
  return CBAssestListData;
};

const assignDefaultValuesCBAssetList = (
  CBAssetList,
  defaultValues,
  defaults,
  type,
  architectureType,
  genset,
  pmUtility,
  pmGenset,
  pmPv,
  bess,
  hybrid,
  cb,
  pmBess,
  gridSettings,
  powerMeter,
  contactor
) => {
  const derList = _.cloneDeep(CBAssetList);
  let circuitBreakersModbusSlaveIdList = defaultValues["Circuit Breaker"];
  const defaultsData = {};
  for (let defaultData of defaults) {
    defaultsData[defaultData["param"]] = defaultData["value"];
  }
  //* utility
  derList[0].configData.name = defaultValues.Utility.name;
  derList[0].configData.utilityCO2 = defaultValues.Utility.utilityCO2;
  derList[0].configData.maximum_available_power =
    defaultsData.maxAvailablePowerUtilityMin;

  derList[0].cbConfigData.name = `${defaultValues["Circuit Breaker"].name} ${defaultValues.Utility.name}`;
  derList[0].modbusSlaveId = circuitBreakersModbusSlaveIdList["modbusId_CB_10"];

  //* generator
  derList[1].configData.name = defaultValues.Generator.name;
  derList[1].configData.gensetCO2 = defaultValues.Generator.gensetCO2;
  // if(type === "GENSET_PVBESS_A2E"){
  //   derList[1].configData.max_power = "130";
  // }
  derList[1].configData.starting_timer = defaultValues.Generator.starting_timer;
  derList[1].configData.cooling_timer = defaultValues.Generator.cooling_timer;

  derList[1].configData.minimum_running_period =
    defaultValues.Generator.minimum_running_period;
  derList[1].cbConfigData.name = `${defaultValues["Circuit Breaker"].name} ${defaultValues.Generator.name}`;
  derList[1].modbusSlaveId = circuitBreakersModbusSlaveIdList["modbusId_CB_20"];
  derList[1].communicationData = { ...communicationDataSample };
  derList[1].communicationData.ipaddress = defaultValues.Generator.ipAddress;
  derList[1].communicationData.subnetmask = defaultValues.Generator.subnetMask;
  derList[1].communicationData.defaultGateway =
    defaultValues.Generator.defaultGateway;
  derList[1].communicationData.modbusSlaveId = defaultValues.Generator.modbusId;

  //* battery

  derList[2].configData.name = defaultValues.BESS.name;
  derList[2].configData.capacity = defaultsData.capacityBessMin;
  derList[2].configData.soc_max = defaultValues.BESS.soc_max;
  derList[2].configData.soc_min = defaultValues.BESS.soc_min;
  derList[2].configData.soc_nominal_power =
    defaultValues.BESS.soc_nominal_power ?? 60;
  derList[2].configData.min_power = defaultValues.BESS.max_power;
  derList[2].cbConfigData.name = `${defaultValues["Circuit Breaker"].name} ${defaultValues.BESS.name}`;
  derList[2].modbusSlaveId = circuitBreakersModbusSlaveIdList["modbusId_CB_30"];

  // commentedcode
  if (projectTypeValue !== "MGaaS IEC") {
    derList[2].communicationData = { ...communicationDataSample };
    derList[2].communicationData.ipaddress = defaultValues.BESS.ipAddress;
    derList[2].communicationData.subnetmask = defaultValues.BESS.subnetMask;
    derList[2].communicationData.defaultGateway =
      defaultValues.BESS.defaultGateway;
    derList[2].communicationData.modbusSlaveId = defaultValues.BESS.modbusId;
  }

  //* inverters

  derList[3].cbConfigData.name = `${defaultValues["Circuit Breaker"].name} ${defaultValues.PV_inverter.groupName} 1`;
  derList[3].modbusSlaveId = circuitBreakersModbusSlaveIdList["modbusId_CB_40"];
  derList[4].cbConfigData.name = `${defaultValues["Circuit Breaker"].name} ${defaultValues.PV_inverter.groupName} 2`;
  derList[4].modbusSlaveId = circuitBreakersModbusSlaveIdList["modbusId_CB_41"];
  derList[5].cbConfigData.name = `${defaultValues["Circuit Breaker"].name} ${defaultValues.PV_inverter.groupName} 3`;
  derList[5].modbusSlaveId = circuitBreakersModbusSlaveIdList["modbusId_CB_42"];

  //*CHP

  derList[6].configData.name = defaultValues.CHP.name;
  derList[6].configData.nominal_power = defaultValues.CHP.nominalPower;
  derList[6].cbConfigData.name = `${defaultValues["Circuit Breaker"].name} ${defaultValues.CHP.name}`;
  derList[6].modbusSlaveId = circuitBreakersModbusSlaveIdList["modbusId_CB_80"];
  derList[6].communicationData = { ...communicationDataSample };
  derList[6].communicationData.ipaddress = defaultValues.CHP.ipAddress;
  derList[6].communicationData.subnetmask = defaultValues.CHP.subnetMask;
  derList[6].communicationData.defaultGateway =
    defaultValues.CHP.defaultGateway;
  derList[6].communicationData.modbusSlaveId = defaultValues.CHP.modbusId;

  //* Circuit breaker
  derList[0].cbConfigData.cbCommunicationData = { ...communicationDataSample };
  derList[1].cbConfigData.cbCommunicationData = { ...communicationDataSample };
  derList[2].cbConfigData.cbCommunicationData = { ...communicationDataSample };
  derList[3].cbConfigData.cbCommunicationData = { ...communicationDataSample };
  derList[4].cbConfigData.cbCommunicationData = { ...communicationDataSample };
  derList[5].cbConfigData.cbCommunicationData = { ...communicationDataSample };

  //default data

  derList[0].cbConfigData.cbAssetDetails[0] = { ...cb };
  derList[1].cbConfigData.cbAssetDetails[0] = { ...cb };
  derList[2].cbConfigData.cbAssetDetails[0] = { ...cb };
  derList[3].cbConfigData.cbAssetDetails[0] = { ...cb };
  derList[4].cbConfigData.cbAssetDetails[0] = { ...cb };
  derList[5].cbConfigData.cbAssetDetails[0] = { ...cb };
  derList[7].cbConfigData.cbAssetDetails[0] = { ...cb };

  if (cb) {
    for (let i = 0; i < 5; i++) {
      const ipAddress = `ipAddress${i + 1}`;

      derList[i].cbConfigData.cbCommunicationData.ipaddress =
        cb?.assetInfo === "Serial" || cb?.assetInfo === "Hardwired"
          ? ""
          : defaultValues["Circuit Breaker"][ipAddress];
      derList[i].cbConfigData.cbCommunicationData.subnetmask =
        cb?.assetInfo === "Serial" || cb?.assetInfo === "Hardwired"
          ? ""
          : defaultValues["Circuit Breaker"]?.subnetMask;
      derList[i].cbConfigData.cbCommunicationData.defaultGateway =
        cb?.assetInfo === "Serial" || cb?.assetInfo === "Hardwired"
          ? ""
          : defaultValues["Circuit Breaker"]?.defaultGateway;
    }
    derList[0].cbConfigData.cbCommunicationData.modbusSlaveId =
      cb.assetInfo === "Hardwired"
        ? ""
        : cb.assetInfo === "Serial"
        ? defaultValues["Circuit Breaker"].ipAddress1?.split(".")[3]
        : defaultValues["Circuit Breaker"].modbusId_CB_10;
    derList[1].cbConfigData.cbCommunicationData.modbusSlaveId =
      cb.assetInfo === "Hardwired"
        ? ""
        : cb.assetInfo === "Serial"
        ? defaultValues["Circuit Breaker"].ipAddress2?.split(".")[3]
        : defaultValues["Circuit Breaker"].modbusId_CB_20;
    derList[2].cbConfigData.cbCommunicationData.modbusSlaveId =
      cb.assetInfo === "Hardwired"
        ? ""
        : cb.assetInfo === "Serial"
        ? defaultValues["Circuit Breaker"].ipAddress3?.split(".")[3]
        : defaultValues["Circuit Breaker"].modbusId_CB_30;
    derList[3].cbConfigData.cbCommunicationData.modbusSlaveId =
      cb.assetInfo === "Hardwired"
        ? ""
        : cb.assetInfo === "Serial"
        ? defaultValues["Circuit Breaker"].ipAddress4.split(".")[3]
        : defaultValues["Circuit Breaker"].modbusId_CB_40;
    derList[4].cbConfigData.cbCommunicationData.modbusSlaveId =
      cb.assetInfo === "Hardwired"
        ? ""
        : cb.assetInfo === "Serial"
        ? defaultValues["Circuit Breaker"].ipAddress5.split(".")[3]
        : defaultValues["Circuit Breaker"].modbusId_CB_41;
    derList[5].cbConfigData.cbCommunicationData.modbusSlaveId =
      cb.assetInfo === "Hardwired"
        ? ""
        : cb.assetInfo === "Serial"
        ? defaultValues["Circuit Breaker"].ipAddress6.split(".")[3]
        : defaultValues["Circuit Breaker"].modbusId_CB_42;
  }

  if (type === embLabels.PV_HYBRIDIZATION) {
    // power meter
    derList[0].powerMeterData.name = defaultValues["Power Meter"].nameUtility;
    derList[0].powerMeterData.powerMeterModbusId =
      defaultValues["Power Meter"].modbusId_PM_Utility;
    derList[1].powerMeterData.name = defaultValues["Power Meter"].nameGenset;
    derList[1].powerMeterData.powerMeterModbusId =
      defaultValues["Power Meter"].modbusId_PM_Genset;
    derList[1].configData.max_power = defaultValues.Generator.max_power;
    derList[3].powerMeterData.name = defaultValues["Power Meter"].namePV;
    derList[3].powerMeterData.powerMeterModbusId =
      defaultValues["Power Meter"].modbusId_PM_PV;
    derList[6].powerMeterData.name = defaultValues["Power Meter"].nameCHP;
    derList[6].powerMeterData.powerMeterModbusId =
      defaultValues["Power Meter"].modbusId_PM_CHP;

    // genset asset

    derList[1].assetDetails[0] = { ...gensetAssetSample };
    derList[1].assetDetails[0].assetAttributes = genset.assetAttributes;
    derList[1].assetDetails[0].assetEnabled = genset.assetEnabled;
    derList[1].assetDetails[0].assetId = genset.assetId;
    derList[1].assetDetails[0].assetInfo = genset.assetInfo;
    derList[1].assetDetails[0].assetProperties = genset.assetProperties;
    derList[1].assetDetails[0].assetType = genset.assetType;
    derList[1].assetDetails[0].comments = genset.comments;
    derList[1].assetDetails[0].communicationModel = genset.communicationModel;
    derList[1].assetDetails[0].imageLink = genset.imageLink;
    derList[1].assetDetails[0].manufacturer = genset.manufacturer;

    //cb default data
    derList[0].cbConfigData.cbAssetDetails[0] = { ...cb };
    derList[1].cbConfigData.cbAssetDetails[0] = { ...cb };
    derList[3].cbConfigData.cbAssetDetails[0] = { ...cb };
  }
  if (type === embLabels.GRID_CONNECTED) {
    derList[0].uiData.pmRequired = true;
    derList[0].uiData.cbRequired = true;
    derList[0].powerMeterData.name = defaultValues["Power Meter"].nameUtility;
    derList[0].powerMeterData.ipAddress =
      pmUtility?.assetInfo === "Serial" || pmUtility?.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"]?.ipAddress_PM_Utility;
    derList[0].powerMeterData.powerMeterModbusId =
      pmUtility?.assetInfo === "Hardwired"
        ? ""
        : pmUtility?.assetInfo === "Serial"
        ? defaultValues["Power Meter"]?.ipAddress_PM_Utility?.split(".")[3]
        : defaultValues["Power Meter"].modbusId_PM_Utility;
    derList[0].powerMeterData.gatewayAddress =
      pmUtility?.assetInfo === "Serial" || pmUtility?.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"]?.defaultGateway;
    derList[0].powerMeterData.mask =
      pmUtility?.assetInfo === "Serial" || pmUtility?.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"]?.subnetMask;
    derList[0].powerMeterData.pmAsset = { ...pmUtility };

    derList[2].powerMeterData.name = defaultValues["Power Meter"].nameBess;
    derList[2].powerMeterData.powerMeterModbusId =
      defaultValues["Power Meter"].modbusId_PM_BESS;

    derList[3].powerMeterData.name = defaultValues["Power Meter"].namePV;
    derList[3].powerMeterData.powerMeterModbusId =
      defaultValues["Power Meter"].modbusId_PM_PV;

    derList[4].powerMeterData.name = "PM_PV_Array_1";

    derList[4].powerMeterData.powerMeterModbusId =
      defaultValues["Power Meter"].modbusId_PM_PV;

    derList[5].powerMeterData.name = "PM_PV_Array_2";

    derList[5].powerMeterData.powerMeterModbusId =
      defaultValues["Power Meter"].modbusId_PM_PV;

    ///default pm
    derList[2].powerMeterData.pmAsset = { ...pmPv };
    derList[3].powerMeterData.pmAsset = { ...pmPv };
    derList[4].powerMeterData.pmAsset = { ...pmPv };
    derList[5].powerMeterData.pmAsset = { ...pmPv };
    derList[2].configData.name = "BESS";
    derList[2].contactorConfigData.name = "BESS Contactor";
    derList[3].contactorConfigData.name = "CB Photo Voltaic Branch Contactor1";
    derList[4].contactorConfigData.name = "CB Photo Voltaic Branch Contactor2";
    derList[5].contactorConfigData.name = "CB Photo Voltaic Branch Contactor3";
    derList[2].contactorConfigData.cbAssetDetails[0] = { ...contactor };
    derList[3].contactorConfigData.cbAssetDetails[0] = { ...contactor };
    derList[4].contactorConfigData.cbAssetDetails[0] = { ...contactor };
    derList[5].contactorConfigData.cbAssetDetails[0] = { ...contactor };

    derList[2].communicationDataBess = { ...communicationDataSample };
    derList[2].communicationDataPv = { ...communicationDataSample };
    // if(projectTypeValue === "MGaaS IEC"&&derList[2]?.assetDetails[0]?.communicationModel==="AC Coupled PCU"){
    //     derList[2].communicationDataBess.ipaddress =
    //     defaultValues.BESS.mbms_ipAddress;
    //   }else{
    //     derList[2].communicationDataBess.ipaddress =
    //     defaultValues.BESS.ipAddress;
    //   }

    if (projectTypeValue === "MGaaS IEC") {
      if (defaultValues.BESS.mbms_ipAddress) {
        derList[2].communicationDataBess.mbms_ipAddress =
          defaultValues.BESS.mbms_ipAddress;
      }
    }
    derList[2].communicationDataBess.ipaddress = defaultValues.BESS.ipAddress;
    derList[2].communicationDataBess.subnetmask = defaultValues.BESS.subnetMask;
    derList[2].assetDetails = [{...bess}]
    derList[2].communicationDataBess.defaultGateway =
      defaultValues.BESS.defaultGateway;
    derList[2].communicationDataBess.modbusSlaveId =
      defaultValues.BESS.modbusId;
    derList[2].communicationDataPv.ipaddress = defaultValues.BESS.pcs_ipAddress;
    derList[2].communicationDataPv.subnetmask = defaultValues.BESS.subnetMask;
    derList[2].communicationDataPv.defaultGateway =
      defaultValues.BESS.defaultGateway;
    derList[2].communicationDataPv.modbusSlaveId = defaultValues.BESS.modbusId;
  }

  if (
    type === embLabels.ISLANDABLE_GSX &&
    architectureType === embLabels.PV_GENSET_ATS &&
    gridSettings.mode !== "FUEL_SAVER"
  ) {
    // power meter
    derList[0].powerMeterData.name = defaultValues["Power Meter"].nameUtility;
    derList[0].powerMeterData.powerMeterModbusId =
      defaultValues["Power Meter"].modbusId_PM_Utility;
    derList[0].powerMeterData.pmAsset = { ...pmUtility };
    derList[1].powerMeterData.name = defaultValues["Power Meter"].nameGenset;
    derList[1].powerMeterData.powerMeterModbusId =
      defaultValues["Power Meter"].modbusId_PM_Genset;
    derList[1].configData.max_power = defaultValues.Generator.max_power;
    derList[1].powerMeterData.pmAsset = { ...pmGenset };
    derList[3].powerMeterData.name = defaultValues["Power Meter"].namePV;
    derList[3].powerMeterData.powerMeterModbusId =
      defaultValues["Power Meter"].modbusId_PM_PV_Communicating;
    derList[3].powerMeterData.ipAddress =
      defaultValues["Power Meter"].ipAddress_PM_Communicating;
    derList[3].powerMeterData.gatewayAddress = "192.168.50.254";
    derList[3].powerMeterData.mask = "255.255.255.0";
    derList[3].powerMeterData.pmAsset = { ...pmPv };

    // genset asset

    derList[1].assetDetails[0] = { ...gensetAssetSample };
    derList[1].assetDetails[0].assetAttributes = genset.assetAttributes;
    derList[1].assetDetails[0].assetEnabled = genset.assetEnabled;
    derList[1].assetDetails[0].assetId = genset.assetId;
    derList[1].assetDetails[0].assetInfo = genset.assetInfo;
    derList[1].assetDetails[0].assetProperties = genset.assetProperties;
    derList[1].assetDetails[0].assetType = genset.assetType;
    derList[1].assetDetails[0].comments = genset.comments;
    derList[1].assetDetails[0].communicationModel = genset.communicationModel;
    derList[1].assetDetails[0].imageLink = genset.imageLink;
    derList[1].assetDetails[0].manufacturer = genset.manufacturer;
  }

  if (
    (type === embLabels.ISLANDABLE_GSX &&
      architectureType === embLabels.PV_GENSET_ATS &&
      gridSettings.mode === "FUEL_SAVER") ||
    (type === embLabels.ISLANDABLE && projectTypeValue === "MGaaS ANSI")
  ) {
    // power meter
    const utilityPmAssetInfo = pmUtility?.assetInfo;
    const gensetPmAssetInfo = pmGenset?.assetInfo;
    const bessPmAssetInfo = pmBess?.assetInfo;
    const pvPmAssetInfo = pmPv?.assetInfo;
    const existingPmAssetInfo = powerMeter?.assetInfo;

    derList[0].powerMeterData.name = defaultValues["Power Meter"].nameUtility;
    derList[0].powerMeterData.pmAsset = { ...pmUtility };
    derList[0].powerMeterData.ipAddress =
      utilityPmAssetInfo === "Serial" || utilityPmAssetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].ipAddress_PM_Utility;
    derList[0].powerMeterData.powerMeterModbusId =
      utilityPmAssetInfo === "Hardwired"
        ? ""
        : utilityPmAssetInfo === "Serial"
        ? defaultValues["Power Meter"].ipAddress_PM_Utility?.split(".")[3]
        : defaultValues["Power Meter"].modbusId_PM_Utility;
    derList[0].powerMeterData.gatewayAddress =
      utilityPmAssetInfo === "Serial" || utilityPmAssetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].defaultGateway;
    derList[0].powerMeterData.mask =
      utilityPmAssetInfo === "Serial" || utilityPmAssetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].subnetMask;

    derList[1].powerMeterData.name = defaultValues["Power Meter"].nameGenset;
    derList[1].configData.max_power = defaultValues.Generator.max_power;
    derList[1].powerMeterData.pmAsset = { ...pmGenset };
    derList[1].powerMeterData.ipAddress =
      gensetPmAssetInfo === "Serial" || gensetPmAssetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].ipAddress_PM_Genset;
    derList[1].powerMeterData.powerMeterModbusId =
      gensetPmAssetInfo === "Hardwired"
        ? ""
        : gensetPmAssetInfo === "Serial"
        ? defaultValues["Power Meter"].ipAddress_PM_Genset?.split(".")[3]
        : defaultValues["Power Meter"].modbusId_PM_Genset;
    derList[1].powerMeterData.gatewayAddress =
      gensetPmAssetInfo === "Serial" || gensetPmAssetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].defaultGateway;
    derList[1].powerMeterData.mask =
      gensetPmAssetInfo === "Serial" || gensetPmAssetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].subnetMask;

    derList[3].powerMeterData.name = defaultValues["Power Meter"].namePV;
    derList[3].powerMeterData.ipAddress =
      pvPmAssetInfo === "Serial" || pvPmAssetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].ipAddress_PM_PV1;
    derList[3].powerMeterData.powerMeterModbusId =
      pvPmAssetInfo === "Hardwired"
        ? ""
        : pvPmAssetInfo === "Serial"
        ? defaultValues["Power Meter"].ipAddress_PM_PV1?.split(".")[3]
        : defaultValues["Power Meter"].modbusId_PM_PV;
    derList[3].powerMeterData.gatewayAddress =
      pvPmAssetInfo === "Serial" || pvPmAssetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].defaultGateway;
    derList[3].powerMeterData.mask =
      pvPmAssetInfo === "Serial" || pvPmAssetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].subnetMask;
    derList[3].powerMeterData.pmAsset = { ...pmPv };

    // genset asset

    derList[1].assetDetails[0] = { ...gensetAssetSample };
    derList[1].assetDetails[0].assetAttributes = genset.assetAttributes;
    derList[1].assetDetails[0].assetEnabled = genset.assetEnabled;
    derList[1].assetDetails[0].assetId = genset.assetId;
    derList[1].assetDetails[0].assetInfo = genset.assetInfo;
    derList[1].assetDetails[0].assetProperties = genset.assetProperties;
    derList[1].assetDetails[0].assetType = genset.assetType;
    derList[1].assetDetails[0].comments = genset.comments;
    derList[1].assetDetails[0].communicationModel = genset.communicationModel;
    derList[1].assetDetails[0].imageLink = genset.imageLink;
    derList[1].assetDetails[0].manufacturer = genset.manufacturer;

    //pm bess

    derList[2].powerMeterData.name = defaultValues["Power Meter"].nameBess;
    // derList[2].powerMeterData.mask =defaultValues["Circuit Breaker"].subnetMask;
    // derList[2].powerMeterData.gatewayAddress =defaultValues["Circuit Breaker"].defaultGateway;
    derList[2].powerMeterData.powerMeterModbusId =
      bessPmAssetInfo === "Hardwired"
        ? ""
        : bessPmAssetInfo === "Serial"
        ? defaultValues["Power Meter"].ipAddress_PM_BESS?.split(".")[3]
        : defaultValues["Power Meter"]?.modbusId_PM;
    derList[2].powerMeterData.ipAddress =
      bessPmAssetInfo === "Serial" || bessPmAssetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].ipAddress_PM_BESS;
    derList[2].powerMeterData.gatewayAddress =
      bessPmAssetInfo === "Serial" || bessPmAssetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].defaultGateway;
    derList[2].powerMeterData.mask =
      bessPmAssetInfo === "Serial" || bessPmAssetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].subnetMask;
    derList[2].powerMeterData.pmAsset = { ...pmBess };

    derList[4].powerMeterData.name = "PM_PV_Array_1";
    derList[4].powerMeterData.ipAddress =
      pvPmAssetInfo === "Serial" || pvPmAssetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].ipAddress_PM_PV2;
    derList[4].powerMeterData.powerMeterModbusId =
      pvPmAssetInfo === "Hardwired"
        ? ""
        : pvPmAssetInfo === "Serial"
        ? defaultValues["Power Meter"].ipAddress_PM_PV2?.split(".")[3]
        : defaultValues["Power Meter"].modbusId_PM_PV;
    derList[4].powerMeterData.mask =
      pvPmAssetInfo === "Serial" || pvPmAssetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].subnetMask;
    derList[4].powerMeterData.gatewayAddress =
      pvPmAssetInfo === "Serial" || pvPmAssetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].defaultGateway;

    derList[4].powerMeterData.pmAsset = { ...pmPv };

    // for pm array 3
    derList[5].uiData.pmRequired = true;
    derList[5].powerMeterData.name = "PM_PV_Array_2";
    derList[5].powerMeterData.ipAddress =
      pmPv.assetInfo === "Serial" || pmPv.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].ipAddress_PM_PV3;
    derList[5].powerMeterData.powerMeterModbusId =
      pmPv.assetInfo === "Hardwired"
        ? ""
        : pmPv.assetInfo === "Serial"
        ? defaultValues["Power Meter"].ipAddress_PM_PV3?.split(".")[3]
        : defaultValues["Power Meter"].modbusId_PM_PV;
    derList[5].powerMeterData.mask =
      pmPv.assetInfo === "Serial" || pmPv.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].subnetMask;
    derList[5].powerMeterData.gatewayAddress =
      pmPv.assetInfo === "Serial" || pmPv.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].defaultGateway;

    derList[5].powerMeterData.pmAsset = { ...pmPv };

    if (type === embLabels.ISLANDABLE && projectTypeValue === "MGaaS ANSI") {
      defaultValues["Power Meter"].ipAddress_PM_EXISTING_PV = "192.168.99.73";
      defaultValues["Power Meter"].ipAddress_PM_EXISTING_BESS = "192.168.99.72";
    }
    //existing pv

    derList[3].existingPowerMeterData.name =
      defaultValues["Power Meter"].nameExistingPv;
    derList[3].existingPowerMeterData.ipAddress =
      existingPmAssetInfo === "Serial" || existingPmAssetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].ipAddress_PM_EXISTING_PV;
    derList[3].existingPowerMeterData.powerMeterModbusId =
      existingPmAssetInfo === "Hardwired"
        ? ""
        : existingPmAssetInfo === "Serial"
        ? defaultValues["Power Meter"].ipAddress_PM_EXISTING_PV?.split(".")[3]
        : defaultValues["Power Meter"].modbusId_PM_EXISTING_PV;
    derList[3].existingPowerMeterData.mask =
      existingPmAssetInfo === "Serial" || existingPmAssetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].subnetMask;
    derList[3].existingPowerMeterData.gatewayAddress =
      existingPmAssetInfo === "Serial" || existingPmAssetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].defaultGateway;

    derList[3].existingPowerMeterData.pmAsset = { ...powerMeter };

    //existing Bess

    derList[2].existingPowerMeterData.name =
      defaultValues["Power Meter"].nameExistingBess;
    derList[2].existingPowerMeterData.ipAddress =
      existingPmAssetInfo === "Serial" || existingPmAssetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].ipAddress_PM_EXISTING_BESS;
    derList[2].existingPowerMeterData.powerMeterModbusId =
      existingPmAssetInfo === "Hardwired"
        ? ""
        : existingPmAssetInfo === "Serial"
        ? defaultValues["Power Meter"]?.ipAddress_PM_EXISTING_BESS?.split(
            "."
          )[3]
        : defaultValues["Power Meter"].modbusId_PM_EXISTING_BESS;
    derList[2].existingPowerMeterData.mask =
      existingPmAssetInfo === "Serial" || existingPmAssetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].subnetMask;
    derList[2].existingPowerMeterData.gatewayAddress =
      existingPmAssetInfo === "Serial" || existingPmAssetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].defaultGateway;

    derList[2].existingPowerMeterData.pmAsset = { ...powerMeter };
  }
  //adding the defaults  for asset pms
  if (
    (type === embLabels.GRID_CONNECTED_GSX &&
      architectureType === embLabels.PV_BESS_NR) ||
    (type === embLabels.GRID_CONNECTED && projectTypeValue === "MGaaS ANSI") || ((type === embLabels.GRID_CONNECTED && projectTypeValue === "DaaS ANSI"))
  ) {
    // power meter
    derList[0].uiData.pmRequired = true;
    derList[0].uiData.cbRequired = true;
    derList[0].powerMeterData.name = defaultValues["Power Meter"].nameUtility;
    derList[0].powerMeterData.ipAddress =
      pmUtility.assetInfo === "Serial" || pmUtility.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].ipAddress_PM_Utility;
    derList[0].powerMeterData.powerMeterModbusId =
      pmUtility.assetInfo === "Hardwired"
        ? ""
        : pmUtility.assetInfo === "Serial"
        ? defaultValues["Power Meter"].ipAddress_PM_Utility?.split(".")[3]
        : defaultValues["Power Meter"].modbusId_PM_Utility;
    derList[0].powerMeterData.mask =
      pmUtility.assetInfo === "Serial" || pmUtility.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].subnetMask;
    derList[0].powerMeterData.gatewayAddress =
      pmUtility.assetInfo === "Serial" || pmUtility.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].defaultGateway;
    derList[0].powerMeterData.pmAsset = { ...pmUtility };

    derList[2].assetDetails[0] = { ...bess };
    derList[2].cbConfigData.name = `${defaultValues["Circuit Breaker"].name} ${defaultValues.BESS.name}`;

    derList[3].uiData.pmRequired = true;
    derList[3].uiData.cbRequired = true;
    derList[3].powerMeterData.name = defaultValues["Power Meter"].namePV;
    derList[3].powerMeterData.ipAddress =
      pmPv.assetInfo === "Serial" || pmPv.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].ipAddress_PM_PV1;
    derList[3].powerMeterData.powerMeterModbusId =
      pmPv.assetInfo === "Hardwired"
        ? ""
        : pmPv.assetInfo === "Serial"
        ? defaultValues["Power Meter"].ipAddress_PM_PV1?.split(".")[3]
        : defaultValues["Power Meter"].modbusId_PM_PV;
    derList[3].powerMeterData.mask =
      pmPv.assetInfo === "Serial" || pmPv.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].subnetMask;
    derList[3].powerMeterData.gatewayAddress =
      pmPv.assetInfo === "Serial" || pmPv.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].defaultGateway;

    derList[3].powerMeterData.pmAsset = { ...pmPv };

    // derList[4].powerMeterData.name = defaultValues["Power Meter"].namePV;
    // for pm array 2
    derList[4].uiData.pmRequired = true;
    derList[4].uiData.cbRequired = true;
    derList[4].powerMeterData.name = "PM_PV_Array_1";
    derList[4].powerMeterData.ipAddress =
      pmPv.assetInfo === "Serial" || pmPv.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].ipAddress_PM_PV2;
    derList[4].powerMeterData.powerMeterModbusId =
      pmPv.assetInfo === "Hardwired"
        ? ""
        : pmPv.assetInfo === "Serial"
        ? defaultValues["Power Meter"].ipAddress_PM_PV2?.split(".")[3]
        : defaultValues["Power Meter"].modbusId_PM_PV;
    // derList[4].powerMeterData.mask =defaultValues["Circuit Breaker"].subnetMask;
    // derList[4].powerMeterData.gatewayAddress =defaultValues["Circuit Breaker"].defaultGateway;
    derList[4].powerMeterData.mask =
      pmPv.assetInfo === "Serial" || pmPv.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].subnetMask;
    derList[4].powerMeterData.gatewayAddress =
      pmPv.assetInfo === "Serial" || pmPv.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].defaultGateway;

    derList[4].powerMeterData.pmAsset = { ...pmPv };
    // for pm array 3
    derList[5].uiData.pmRequired = true;
    derList[5].uiData.cbRequired = true;
    derList[5].powerMeterData.name = "PM_PV_Array_2";
    derList[5].powerMeterData.ipAddress =
      pmPv.assetInfo === "Serial" || pmPv.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].ipAddress_PM_PV3;
    derList[5].powerMeterData.powerMeterModbusId =
      pmPv.assetInfo === "Hardwired"
        ? ""
        : pmPv.assetInfo === "Serial"
        ? defaultValues["Power Meter"].ipAddress_PM_PV3?.split(".")[3]
        : defaultValues["Power Meter"].modbusId_PM_PV;
    derList[5].powerMeterData.mask =
      pmPv.assetInfo === "Serial" || pmPv.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].subnetMask;
    derList[5].powerMeterData.gatewayAddress =
      pmPv.assetInfo === "Serial" || pmPv.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].defaultGateway;

    derList[5].powerMeterData.pmAsset = { ...pmPv };

    //for pm bess
    derList[2].uiData.pmRequired = true;
    derList[2].powerMeterData.name = defaultValues["Power Meter"].nameBess;
    derList[2].powerMeterData.ipAddress =
      pmBess.assetInfo === "Serial" || pmBess.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].ipAddress_PM_BESS;
    derList[2].powerMeterData.mask =
      pmBess.assetInfo === "Serial" || pmBess.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].subnetMask;
    derList[2].powerMeterData.gatewayAddress =
      pmBess.assetInfo === "Serial" || pmBess.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].defaultGateway;
    derList[2].powerMeterData.powerMeterModbusId =
      pmBess.assetInfo === "Hardwired"
        ? ""
        : pmBess.assetInfo === "Serial"
        ? defaultValues["Power Meter"].ipAddress_PM_BESS?.split(".")[3]
        : defaultValues["Power Meter"].modbusId_PM;
    derList[2].powerMeterData.pmAsset = { ...pmBess };

    //existing pv

    derList[3].existingPowerMeterData.name =
      defaultValues["Power Meter"].nameExistingPv ?? "PM_EXISTING_PV";
    derList[3].existingPowerMeterData.ipAddress =
      powerMeter?.assetInfo === "Serial" || powerMeter?.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].ipAddress_PM_EXISTING_PV;
    derList[3].existingPowerMeterData.powerMeterModbusId =
      powerMeter?.assetInfo === "Hardwired"
        ? ""
        : powerMeter?.assetInfo === "Serial"
        ? defaultValues["Power Meter"].ipAddress_PM_EXISTING_PV?.split(".")[3]
        : defaultValues["Power Meter"].modbusId_PM_EXISTING_PV;
    derList[3].existingPowerMeterData.mask =
      powerMeter?.assetInfo === "Serial" || powerMeter?.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].subnetMask;
    derList[3].existingPowerMeterData.gatewayAddress =
      powerMeter?.assetInfo === "Serial" || powerMeter?.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].defaultGateway;

    derList[3].existingPowerMeterData.pmAsset = { ...powerMeter };

    //existing Bess

    derList[2].existingPowerMeterData.name =
      defaultValues["Power Meter"].nameExistingBess ?? "PM_EXISTING_BESS";
    derList[2].existingPowerMeterData.ipAddress =
      powerMeter?.assetInfo === "Serial" || powerMeter?.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].ipAddress_PM_EXISTING_BESS;
    derList[2].existingPowerMeterData.powerMeterModbusId =
      powerMeter?.assetInfo === "Hardwired"
        ? ""
        : powerMeter?.assetInfo === "Serial"
        ? defaultValues["Power Meter"].ipAddress_PM_EXISTING_BESS?.split(".")[3]
        : defaultValues["Power Meter"].modbusId_PM_EXISTING_BESS;
    derList[2].existingPowerMeterData.mask =
      powerMeter?.assetInfo === "Serial" || powerMeter?.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].subnetMask;
    derList[2].existingPowerMeterData.gatewayAddress =
      powerMeter?.assetInfo === "Serial" || powerMeter?.assetInfo === "Hardwired"
        ? ""
        : defaultValues["Power Meter"].defaultGateway;

    derList[2].existingPowerMeterData.pmAsset = { ...powerMeter };
  }

  if (type === embLabels.GENSET_PVBESS_A2E) {
    // power meter

    derList[1].powerMeterData.name = defaultValues["Power Meter"].nameGenset;

    derList[1].powerMeterData.ipAddress =
      defaultValues["Power Meter"].ipAddress_PM_Utility;
    derList[1].powerMeterData.mask =
      defaultValues["Circuit Breaker"].subnetMask;
    derList[1].powerMeterData.gatewayAddress =
      defaultValues["Circuit Breaker"].defaultGateway;
    derList[1].powerMeterData.powerMeterModbusId =
      defaultValues["Power Meter"].modbusId_PM;

    derList[1].configData.max_power = defaultValues.Generator.max_power;
    if (type === "GENSET_PVBESS_A2E") {
      derList[1].configData.max_power = "130";
    }
    derList[1].powerMeterData.pmAsset = { ...pmGenset };
    // derList[1].powerMeterData.ipAddress =
    //   defaultValues["Power Meter"].ipAddress_PM_Genset;
    derList[1].cbConfigData.cbAssetDetails[0] = cb;

    derList[1].powerMeterData.gatewayAddress =
      defaultValues["Power Meter"].defaultGateway;
    derList[1].powerMeterData.mask = defaultValues["Power Meter"].subnetMask;

    derList[7].cbConfigData.cbAssetDetails[0] = cb;

    // genset asset

    derList[1].assetDetails[0] = { ...gensetAssetSample };
    derList[1].assetDetails[0].assetAttributes = genset.assetAttributes;
    derList[1].assetDetails[0].assetEnabled = genset.assetEnabled;
    derList[1].assetDetails[0].assetId = genset.assetId;
    derList[1].assetDetails[0].assetInfo = genset.assetInfo;
    derList[1].assetDetails[0].assetProperties = genset.assetProperties;
    derList[1].assetDetails[0].assetType = genset.assetType;
    derList[1].assetDetails[0].comments = genset.comments;
    derList[1].assetDetails[0].communicationModel = genset.communicationModel;
    derList[1].assetDetails[0].imageLink = genset.imageLink;
    derList[1].assetDetails[0].manufacturer = genset.manufacturer;

    derList[7].configData.name = defaultValues.Hybrid.name;
    derList[7].cbConfigData.name = `${defaultValues["Circuit Breaker"].name} ${defaultValues.Hybrid.name}`;
    derList[7].modbusSlaveId = "8";

    derList[7].communicationDataBess = { ...communicationDataSample };
    derList[7].communicationDataPv = { ...communicationDataSample };
    derList[7].communicationDataBess.ipaddress =
      defaultValues.Hybrid.ipAddressBess;
    derList[7].communicationDataBess.subnetmask =
      defaultValues.Hybrid.subnetMask;
    derList[7].communicationDataBess.defaultGateway =
      defaultValues.Hybrid.defaultGateway;
    derList[7].communicationDataBess.modbusSlaveId =
      defaultValues.Hybrid.modbusId_Bess;
    derList[7].communicationDataPv.ipaddress = defaultValues.Hybrid.ipAddressPv;
    derList[7].communicationDataPv.subnetmask = defaultValues.Hybrid.subnetMask;
    derList[7].communicationDataPv.defaultGateway =
      defaultValues.Hybrid.defaultGateway;
    derList[7].communicationDataPv.modbusSlaveId =
      defaultValues.Hybrid.modbusId_Pv;

    derList[7].assetDetails[0] = { ...hybrid };
    derList[7].configData.name = defaultValues.Hybrid.name;
    derList[7].configData.storage_capcity_hr =
      hybrid.assetAttributes.find(
        (assetType) => assetType.type === "usableStorageCapacityHr"
      )?.value ?? [];
    derList[7].configData.corresponding_name_plate_capacity =
      hybrid.assetAttributes.find(
        (assetType) => assetType.type === "usableStorageCapacity"
      )?.value ?? [];
    derList[7].configData.corresponding_name_plate_capacity_value =
      JSON.parse(
        hybrid.assetAttributes.find(
          (assetType) => assetType.type === "usableStorageCapacity"
        )?.value
      )["1.1"] ?? [];
    derList[7].configData.max_capacity =
      hybrid.assetAttributes.find(
        (assetType) => assetType.type === "max_capacity"
      )?.value ?? "";

    derList[7].configData.capacity =
      hybrid.assetAttributes.find((assetType) => assetType.type === "capacity")
        ?.value ?? "";
    derList[7].configData.max_solar_power =
      hybrid.assetAttributes.find(
        (assetType) => assetType.type === "max_solar_power"
      )?.value ?? "";
    derList[7].configData.max_inverter_output_power =
      hybrid.assetAttributes.find(
        (assetType) => assetType.type === "max_inverter_output_power"
      )?.value ?? "";
    if (type === "GENSET_PVBESS_A2E") {
      derList[1].configData.max_power =
        derList[7].assetDetails[0]?.assetAttributes.find(
          (attribute) => attribute.type === "gensetPower"
        )?.value;
    }
    derList[7].cbConfigData.cbCommunicationData = {
      ...communicationDataSample,
      ipaddress: "",
      subnetmask: "",
      defaultGateway: "",
      modbusSlaveId: "",
    };

    //cb default data
    derList[0].cbConfigData.cbAssetDetails[0] = { ...cb };
    derList[7].cbConfigData.cbAssetDetails[0] = { ...cb };
  }

  // return new state
  return derList;
};

const assignUseCaseDer = (CBAssetList, der, defaultValues, projectTypeA2E) => {
  const derList = _.cloneDeep(CBAssetList);
  derList[der].uiData.status = true;
  if (projectTypeA2E === "Access to Energy") {
    derList[der].uiData.CBConfigured = true;
  }

  derList[der].uiData.pmConfigured = true;
  if (der === 0) {
    derList[der].uiData.configured = false;
  }
  if (der === 3) {
    derList[3].uiData.status = true;
    derList[3].uiData.count = 3;
  }
  //   const modbusId =
  //     der === 0 || der === 7 ? "255" : der === 1 ? "2" : der === 2 ? "3" : "4";

  // //ip address
    // derList[der].cbConfigData.cbCommunicationData.ipaddress = "192.168.50.21";
    derList[der].cbConfigData.cbCommunicationData.ipaddress = defaultValues["Circuit Breaker"].ipAddress1;

    derList[der].cbConfigData.cbCommunicationData.subnetmask = "255.255.255.240";
    derList[der].cbConfigData.cbCommunicationData.defaultGateway =
      "192.168.99.49";
  //   derList[der].cbConfigData.cbCommunicationData.modbusSlaveId = modbusId;

  // return new state
  return derList;
};

const updateCbAssetListCoordinates = (CBAssetList, coordinates) => {
  let CBAssestListCor = Object.assign([], CBAssetList);
  let xDer = coordinates.xDer;
  let xDerCB = coordinates.xDerCB;
  if (CBAssetList[0].uiData.status) {
    CBAssestListCor[0].uiData.xAxis = coordinates.xDer;
    CBAssestListCor[0].uiData.xAxisCB = coordinates.xDerCB;
  }

  if (CBAssetList[1].uiData.status) {
    xDer = coordinates.derDiff + xDer;
    xDerCB = coordinates.derDiff + xDerCB;
    CBAssestListCor[1].uiData.yAxis = 70;
    CBAssestListCor[1].uiData.xAxis = xDer;
    CBAssestListCor[1].uiData.xAxisCB = xDerCB;
  }

  if (CBAssetList[2].uiData.status) {
    xDer = coordinates.derDiff + xDer;
    xDerCB = coordinates.derDiff + xDerCB;
    CBAssestListCor[2].uiData.yAxis = 70;
    CBAssestListCor[2].uiData.xAxis = xDer;
    CBAssestListCor[2].uiData.xAxisCB = xDerCB;
    CBAssestListCor[2].uiData.yAxisCB = 192;
  }
  if (CBAssetList[3].uiData.status) {
    xDer = coordinates.derDiff + xDer;
    xDerCB = coordinates.derDiff + xDerCB;
    CBAssestListCor[3].uiData.yAxis = 70;
    CBAssestListCor[3].uiData.xAxis = xDer;
    CBAssestListCor[3].uiData.xAxisCB = xDerCB;
    CBAssestListCor[3].uiData.yAxisCB = 192;
  }
  if (CBAssetList[4].uiData.status) {
    xDer = coordinates.derDiff + xDer;
    xDerCB = coordinates.derDiff + xDerCB;
    CBAssestListCor[4].uiData.yAxis = 70;
    CBAssestListCor[4].uiData.xAxis = xDer;
    CBAssestListCor[4].uiData.xAxisCB = xDerCB;
  }
  if (CBAssetList[5].uiData.status) {
    xDer = coordinates.derDiff + xDer;
    xDerCB = coordinates.derDiff + xDerCB;
    CBAssestListCor[5].uiData.yAxis = 70;
    CBAssestListCor[5].uiData.xAxis = xDer;
    CBAssestListCor[5].uiData.xAxisCB = xDerCB;
  }
  if (CBAssetList[6].uiData.status) {
    xDer = coordinates.derDiff + xDer;
    xDerCB = coordinates.derDiff + xDerCB;
    CBAssestListCor[6].uiData.yAxis = 70;
    CBAssestListCor[6].uiData.xAxis = xDer;
    CBAssestListCor[6].uiData.xAxisCB = xDerCB;
  }

  // return new state
  return CBAssestListCor;
};

const updateCbAssetListCoordinatesGridConnected = (
  CBAssetList,
  coordinates
) => {
  let CBAssestListCor = Object.assign([], CBAssetList);
  let xDer = Number(coordinates.xDerGrid);
  let yDer = Number(coordinates.yDerGrid);
  let xDerCB = coordinates.xDerCB;
  //let xAxisCB = 590;
  let xAxisCB = Number(coordinates.xDerCBDiff);
  let yAxisCB = Number(coordinates.yDerCBDiff);
  let xAxisPM = Number(coordinates.xDerGridPM);
  let yAxisPM = Number(coordinates.yDerGridPM);
  if (CBAssetList[0].uiData.status) {
    CBAssestListCor[0].uiData.xAxis = coordinates.xDerGrid;
    CBAssestListCor[0].uiData.xAxisCB = coordinates.xDerCB;
  }

  if (CBAssetList[2].uiData.status) {
    xDer = coordinates.derDiffGrid + xDer;
    xDerCB = coordinates.derDiffGrid + xDerCB;
    CBAssestListCor[2].uiData.yAxis = yDer;
    CBAssestListCor[2].uiData.xAxis = xDer;
    CBAssestListCor[2].uiData.xAxisCB = xAxisCB;
    CBAssestListCor[2].uiData.yAxisCB = yAxisCB;
    CBAssestListCor[2].uiData.xAxisPM = xAxisPM;
    CBAssestListCor[2].uiData.yAxisPM = yAxisPM;
    xAxisCB = xAxisCB + 120;
    xAxisPM = xAxisPM + 120;
    //yAxisPM = yAxisPM + 120;
  }
  if (CBAssetList[3].uiData.status) {
    xDer = coordinates.derDiffGrid + xDer;
    xDerCB = coordinates.derDiffGrid + xDerCB;
    CBAssestListCor[3].uiData.yAxis = yDer + 1;
    CBAssestListCor[3].uiData.xAxis = xDer;
    CBAssestListCor[3].uiData.xAxisCB = xDerCB;
    CBAssestListCor[3].uiData.xAxisCB = xAxisCB;
    CBAssestListCor[3].uiData.yAxisCB = yAxisCB;
    CBAssestListCor[3].uiData.xAxisPM = xAxisPM;
    CBAssestListCor[3].uiData.yAxisPM = yAxisPM;
    xAxisCB = xAxisCB + 120;
    xAxisPM = xAxisPM + 120;
  }

  if (CBAssetList[4].uiData.status) {
    xDer = coordinates.derDiffGrid + xDer;
    xDerCB = coordinates.derDiffGrid + xDerCB;
    CBAssestListCor[4].uiData.yAxis = yDer + 1;
    CBAssestListCor[4].uiData.xAxis = xDer;
    CBAssestListCor[4].uiData.xAxisCB = xDerCB;
    CBAssestListCor[4].uiData.xAxisCB = xAxisCB;
    CBAssestListCor[4].uiData.yAxisCB = yAxisCB;
    CBAssestListCor[4].uiData.xAxisPM = xAxisPM;
    CBAssestListCor[4].uiData.yAxisPM = yAxisPM;
    xAxisCB = xAxisCB + 120;
    xAxisPM = xAxisPM + 120;
  }
  if (CBAssetList[5].uiData.status) {
    xDer = coordinates.derDiffGrid + xDer;
    xDerCB = coordinates.derDiffGrid + xDerCB;
    CBAssestListCor[5].uiData.yAxis = yDer + 1;
    CBAssestListCor[5].uiData.xAxis = xDer;
    CBAssestListCor[5].uiData.xAxisCB = xDerCB;
    CBAssestListCor[5].uiData.xAxisCB = xAxisCB;
    CBAssestListCor[5].uiData.yAxisCB = yAxisCB;
    CBAssestListCor[5].uiData.xAxisPM = xAxisPM;
    CBAssestListCor[5].uiData.yAxisPM = yAxisPM;
    xAxisCB = xAxisCB + 120;
    xAxisPM = xAxisPM + 120;
  }
  // return new state
  return CBAssestListCor;
};

const updateCbAssetListCoordinatesPvHybridization = (
  CBAssetList,
  coordinates
) => {
  let CBAssestListCor = Object.assign([], CBAssetList);
  let xDer = coordinates.xDerPvHyb;
  let xDerCBPvHyb = coordinates.xDerCBPvHyb;

  if (CBAssetList[0].uiData.status) {
    CBAssestListCor[0].uiData.xAxis = xDer;
    CBAssestListCor[0].uiData.xAxisCB = xDerCBPvHyb;
    CBAssestListCor[0].uiData.yAxis = 141;
  }

  if (CBAssetList[0].uiData.status) {
    xDer = coordinates.derDiffPvHyb + xDer;
    xDerCBPvHyb = coordinates.derDiffPvHyb + xDerCBPvHyb;
    CBAssestListCor[1].uiData.xAxis = xDer;
    CBAssestListCor[1].uiData.yAxis = 141;
    CBAssestListCor[1].uiData.xAxisCB = xDerCBPvHyb;
    CBAssestListCor[1].uiData.yAxisCB = 270;
  }
  if (CBAssetList[3].uiData.status) {
    xDer = coordinates.derDiffPvHyb + xDer;
    xDerCBPvHyb = coordinates.derDiffPvHyb + xDerCBPvHyb;
    CBAssestListCor[3].uiData.xAxis = xDer;
    CBAssestListCor[3].uiData.yAxis = 142;
    CBAssestListCor[3].uiData.xAxisCB = xDerCBPvHyb;
    CBAssestListCor[3].uiData.yAxisCB = 270;
  }

  if (CBAssetList[6].uiData.status) {
    xDer = coordinates.derDiffPvHyb + xDer;
    xDerCBPvHyb = coordinates.derDiffPvHyb + xDerCBPvHyb;
    CBAssestListCor[6].uiData.xAxis = xDer;
    CBAssestListCor[6].uiData.yAxis = 141;
    CBAssestListCor[6].uiData.xAxisCB = xDerCBPvHyb;
    CBAssestListCor[6].uiData.yAxisCB = 270;
  }

  // return new state
  return CBAssestListCor;
};

const updateCbAssetListCoordinatesPvGensetAts = (CBAssetList) => {
  let CBAssestListCor = Object.assign([], CBAssetList);
  if (CBAssetList[0].uiData.status) {
    CBAssestListCor[0].uiData.xAxis = "124.5";
    CBAssestListCor[0].uiData.yAxis = "38.5";
  }
  if (CBAssetList[1].uiData.status) {
    CBAssestListCor[1].uiData.xAxis = "245.5";
    CBAssestListCor[1].uiData.yAxis = "39";
  }
  if (CBAssetList[3].uiData.status) {
    CBAssestListCor[3].uiData.xAxis = "645";
    CBAssestListCor[3].uiData.yAxis = "205";
    CBAssestListCor[3].uiData.xAxisCB = "745";
    CBAssestListCor[3].uiData.yAxisCB = "333";
    CBAssestListCor[3].uiData.xAxisPM = "650";
    CBAssestListCor[3].uiData.yAxisPM = "250";
  }
  if (CBAssetList[4].uiData.status) {
    CBAssestListCor[4].uiData.xAxis = "745";
    CBAssestListCor[4].uiData.yAxis = "205";
    CBAssestListCor[4].uiData.xAxisCB = "845";
    CBAssestListCor[4].uiData.yAxisCB = "333";
    CBAssestListCor[4].uiData.xAxisPM = "750";
    CBAssestListCor[4].uiData.yAxisPM = "250";

    CBAssestListCor[5].uiData.xAxis = "620";
    CBAssestListCor[5].uiData.yAxis = "205";
    CBAssestListCor[5].uiData.xAxisCB = "720.5";
    CBAssestListCor[5].uiData.yAxisCB = "331";
    CBAssestListCor[5].uiData.xAxisPM = "625";
    CBAssestListCor[5].uiData.yAxisPM = "250";
  }
  // return new state
  return CBAssestListCor;
};
const updateCbAssetListCoordinatesPvBessNr = (CBAssetList, switchType) => {
  let CBAssestListCor = Object.assign([], CBAssetList);
  CBAssestListCor[0].uiData.xAxis = "125";
  CBAssestListCor[0].uiData.yAxis = "10";
  CBAssestListCor[0].uiData.xAxisCB = "215";
  CBAssestListCor[0].uiData.yAxisCB = "185";

  CBAssestListCor[2].uiData.xAxis = "550";
  CBAssestListCor[2].uiData.yAxis = "204";
  CBAssestListCor[2].uiData.xAxisCB = "650";
  CBAssestListCor[2].uiData.yAxisCB = "330";
  CBAssestListCor[2].uiData.xAxisPM = "555";
  CBAssestListCor[2].uiData.yAxisPM = "195";

  if (switchType === "NEW_SWITCHBOARD") {
    CBAssestListCor[3].uiData.xAxis = "300";
    CBAssestListCor[3].uiData.yAxis = "68";
    CBAssestListCor[3].uiData.xAxisCB = "400";
    CBAssestListCor[3].uiData.yAxisCB = "190";
    CBAssestListCor[3].uiData.xAxisPM = "305";
    CBAssestListCor[3].uiData.yAxisPM = "60";

    CBAssestListCor[4].uiData.xAxis = "450";
    CBAssestListCor[4].uiData.yAxis = "70 ";
    CBAssestListCor[4].uiData.xAxisCB = "550";
    CBAssestListCor[4].uiData.yAxisCB = "195";
    CBAssestListCor[4].uiData.xAxisPM = "455";
    CBAssestListCor[4].uiData.yAxisPM = "58";

    CBAssestListCor[5].uiData.xAxis = "600";
    CBAssestListCor[5].uiData.yAxis = "68";
    CBAssestListCor[5].uiData.xAxisCB = "700";
    CBAssestListCor[5].uiData.yAxisCB = "193";
    CBAssestListCor[5].uiData.xAxisPM = "605";
    CBAssestListCor[5].uiData.yAxisPM = "57";

    if (!CBAssestListCor[3].uiData.status) {
      CBAssestListCor[4].uiData.xAxis = "325";
      CBAssestListCor[4].uiData.yAxis = "68";
      CBAssestListCor[4].uiData.xAxisCB = "425";
      CBAssestListCor[4].uiData.yAxisCB = "193";
      CBAssestListCor[4].uiData.xAxisPM = "330";
      CBAssestListCor[4].uiData.yAxisPM = "57";

      CBAssestListCor[5].uiData.xAxis = "470";
      CBAssestListCor[5].uiData.yAxis = "68";
      CBAssestListCor[5].uiData.xAxisCB = "570.5";
      CBAssestListCor[5].uiData.yAxisCB = "193";
      CBAssestListCor[5].uiData.xAxisPM = "475";
      CBAssestListCor[5].uiData.yAxisPM = "57";
    }
    if (!CBAssestListCor[4].uiData.status) {
      CBAssestListCor[5].uiData.xAxis = "450";
      CBAssestListCor[5].uiData.yAxis = "68";
      CBAssestListCor[5].uiData.xAxisCB = "550";
      CBAssestListCor[5].uiData.yAxisCB = "193";
      CBAssestListCor[5].uiData.xAxisPM = "455";
      CBAssestListCor[5].uiData.yAxisPM = "57";
    }
    if (
      !CBAssestListCor[3].uiData.status &&
      !CBAssestListCor[4].uiData.status
    ) {
      CBAssestListCor[5].uiData.xAxis = "350";
      CBAssestListCor[5].uiData.yAxis = "68";
      CBAssestListCor[5].uiData.xAxisCB = "450.5";
      CBAssestListCor[5].uiData.yAxisCB = "190";
      CBAssestListCor[5].uiData.xAxisPM = "355";
      CBAssestListCor[5].uiData.yAxisPM = "57";
    }
  } else {
    CBAssestListCor[3].uiData.xAxis = "680";
    CBAssestListCor[3].uiData.yAxis = "205";
    CBAssestListCor[3].uiData.xAxisCB = "780";
    CBAssestListCor[3].uiData.yAxisCB = "332";
    CBAssestListCor[3].uiData.xAxisPM = "685";
    CBAssestListCor[3].uiData.yAxisPM = "250";

    CBAssestListCor[4].uiData.xAxis = "810";
    CBAssestListCor[4].uiData.yAxis = "205";
    CBAssestListCor[4].uiData.xAxisCB = "910";
    CBAssestListCor[4].uiData.yAxisCB = "330";
    CBAssestListCor[4].uiData.xAxisPM = "815";
    CBAssestListCor[4].uiData.yAxisPM = "250";

    CBAssestListCor[5].uiData.xAxis = "940";
    CBAssestListCor[5].uiData.yAxis = "205";
    CBAssestListCor[5].uiData.xAxisCB = "1040.5";
    CBAssestListCor[5].uiData.yAxisCB = "330";
    CBAssestListCor[5].uiData.xAxisPM = "945";
    CBAssestListCor[5].uiData.yAxisPM = "250";
    if (!CBAssestListCor[3].uiData.status) {
      CBAssestListCor[4].uiData.xAxis = "670";
      CBAssestListCor[4].uiData.yAxis = "205";
      CBAssestListCor[4].uiData.xAxisCB = "770";
      CBAssestListCor[4].uiData.yAxisCB = "330";
      CBAssestListCor[4].uiData.xAxisPM = "675";
      CBAssestListCor[4].uiData.yAxisPM = "250";

      CBAssestListCor[5].uiData.xAxis = "800";
      CBAssestListCor[5].uiData.yAxis = "205";
      CBAssestListCor[5].uiData.xAxisCB = "900";
      CBAssestListCor[5].uiData.yAxisCB = "330";
      CBAssestListCor[5].uiData.xAxisPM = "805";
      CBAssestListCor[5].uiData.yAxisPM = "250";
    }
    if (!CBAssestListCor[4].uiData.status) {
      CBAssestListCor[5].uiData.xAxis = "800";
      CBAssestListCor[5].uiData.yAxis = "205";
      CBAssestListCor[5].uiData.xAxisCB = "900";
      CBAssestListCor[5].uiData.yAxisCB = "330";
      CBAssestListCor[5].uiData.xAxisPM = "805";
      CBAssestListCor[5].uiData.yAxisPM = "250";
    }
    if (
      !CBAssestListCor[3].uiData.status &&
      !CBAssestListCor[4].uiData.status
    ) {
      CBAssestListCor[5].uiData.xAxis = "680";
      CBAssestListCor[5].uiData.yAxis = "205";
      CBAssestListCor[5].uiData.xAxisCB = "780";
      CBAssestListCor[5].uiData.yAxisCB = "332";
      CBAssestListCor[5].uiData.xAxisPM = "685";
      CBAssestListCor[5].uiData.yAxisPM = "250";
    }
  }
  // if(switchType === "NEW_SWITCHBOARD"){
  //   CBAssestListCor[5].uiData.xAxis = "640";
  //   CBAssestListCor[5].uiData.yAxis = "205";
  //   CBAssestListCor[5].uiData.xAxisCB = "740.5";
  //   CBAssestListCor[5].uiData.yAxisCB = "331";
  //   CBAssestListCor[5].uiData.xAxisPM = "645";
  //   CBAssestListCor[5].uiData.yAxisPM = "250";
  // }else{
  //   CBAssestListCor[5].uiData.xAxis = "940";
  //   CBAssestListCor[5].uiData.yAxis = "205";
  //   CBAssestListCor[5].uiData.xAxisCB = "1040.5";
  //   CBAssestListCor[5].uiData.yAxisCB = "331";
  //   CBAssestListCor[5].uiData.xAxisPM = "945";
  //   CBAssestListCor[5].uiData.yAxisPM = "250";
  // }

  //   if (!CBAssestListCor[3].uiData.status && switchType === "NEW_SWITCHBOARD") {
  //     CBAssestListCor[4].uiData.xAxis = "750";
  //     CBAssestListCor[4].uiData.yAxis = "205";
  //     CBAssestListCor[4].uiData.xAxisCB = "850";
  //     CBAssestListCor[4].uiData.yAxisCB = "333";
  //     CBAssestListCor[4].uiData.xAxisPM = "755";
  //     CBAssestListCor[4].uiData.yAxisPM = "250";

  //     CBAssestListCor[5].uiData.xAxis = "500";
  //     CBAssestListCor[5].uiData.yAxis = "68";
  //     CBAssestListCor[5].uiData.xAxisCB = "600.5";
  //     CBAssestListCor[5].uiData.yAxisCB = "193";
  //     CBAssestListCor[5].uiData.xAxisPM = "505";
  //     CBAssestListCor[5].uiData.yAxisPM = "57";
  //   } else{
  //     CBAssestListCor[4].uiData.xAxis = "750";
  //     CBAssestListCor[4].uiData.yAxis = "205";
  //     CBAssestListCor[4].uiData.xAxisCB = "850";
  //     CBAssestListCor[4].uiData.yAxisCB = "333";
  //     CBAssestListCor[4].uiData.xAxisPM = "755";
  //     CBAssestListCor[4].uiData.yAxisPM = "250";

  //     CBAssestListCor[5].uiData.xAxis = "500";
  //     CBAssestListCor[5].uiData.yAxis = "68";
  //     CBAssestListCor[5].uiData.xAxisCB = "600.5";
  //     CBAssestListCor[5].uiData.yAxisCB = "193";
  //     CBAssestListCor[5].uiData.xAxisPM = "505";
  //     CBAssestListCor[5].uiData.yAxisPM = "57";
  //   }
  //   if (!CBAssestListCor[4].uiData.status && switchType === "NEW_SWITCHBOARD") {
  //       CBAssestListCor[5].uiData.xAxis = "500";
  //     CBAssestListCor[5].uiData.yAxis = "68";
  //     CBAssestListCor[5].uiData.xAxisCB = "600.5";
  //     CBAssestListCor[5].uiData.yAxisCB = "193";
  //     CBAssestListCor[5].uiData.xAxisPM = "505";
  //     CBAssestListCor[5].uiData.yAxisPM = "57";
  //   } else{
  //     CBAssestListCor[5].uiData.xAxis = "630";
  //   CBAssestListCor[5].uiData.yAxis = "68";
  //   CBAssestListCor[5].uiData.xAxisCB = "730.5";
  //   CBAssestListCor[5].uiData.yAxisCB = "193";
  //   CBAssestListCor[5].uiData.xAxisPM = "635";
  //   CBAssestListCor[5].uiData.yAxisPM = "57";
  //   }
  //   if (!CBAssestListCor[3].uiData.status && !CBAssestListCor[4].uiData.status && switchType === "NEW_SWITCHBOARD") {
  //     CBAssestListCor[5].uiData.xAxis = "350";
  //   CBAssestListCor[5].uiData.yAxis = "68";
  //   CBAssestListCor[5].uiData.xAxisCB = "450.5";
  //   CBAssestListCor[5].uiData.yAxisCB = "193";
  //   CBAssestListCor[5].uiData.xAxisPM = "355";
  //   CBAssestListCor[5].uiData.yAxisPM = "57";
  // }

  // return new state
  return CBAssestListCor;
};

const updateCbAssetListCoordinatesGensetPvBess = (CBAssetList, switchType) => {
  let CBAssestListCor = Object.assign([], CBAssetList);
  CBAssestListCor[0].uiData.xAxis = "124.5";
  CBAssestListCor[0].uiData.yAxis = "0";
  CBAssestListCor[0].uiData.xAxisCB = "215";
  CBAssestListCor[0].uiData.yAxisCB = "185";

  CBAssestListCor[2].uiData.xAxis = "550";
  CBAssestListCor[2].uiData.yAxis = "204";
  CBAssestListCor[2].uiData.xAxisCB = "650";
  CBAssestListCor[2].uiData.yAxisCB = "330";
  CBAssestListCor[2].uiData.xAxisPM = "555";
  CBAssestListCor[2].uiData.yAxisPM = "197";

  CBAssestListCor[1].uiData.xAxis = "680";
  CBAssestListCor[1].uiData.yAxis = "205";
  CBAssestListCor[1].uiData.xAxisCB = "764";
  CBAssestListCor[1].uiData.yAxisCB = "330";
  CBAssestListCor[1].uiData.xAxisPM = "677";
  CBAssestListCor[1].uiData.yAxisPM = "197";
  if (switchType === "NEW_SWITCHBOARD") {
    CBAssestListCor[3].uiData.xAxis = "680";
    CBAssestListCor[3].uiData.yAxis = "205";
    CBAssestListCor[3].uiData.xAxisCB = "780";
    CBAssestListCor[3].uiData.yAxisCB = "333";
    CBAssestListCor[3].uiData.xAxisPM = "335";
    CBAssestListCor[3].uiData.yAxisPM = "57";

    CBAssestListCor[4].uiData.xAxis = "570";
    CBAssestListCor[4].uiData.yAxis = "72";
    CBAssestListCor[4].uiData.xAxisCB = "670";
    CBAssestListCor[4].uiData.yAxisCB = "200";
    CBAssestListCor[4].uiData.xAxisPM = "575";
    CBAssestListCor[4].uiData.yAxisPM = "120";

    CBAssestListCor[5].uiData.xAxis = "700";
    CBAssestListCor[5].uiData.yAxis = "71";
    CBAssestListCor[5].uiData.xAxisCB = "800.5";
    CBAssestListCor[5].uiData.yAxisCB = "199";
    CBAssestListCor[5].uiData.xAxisPM = "705";
    CBAssestListCor[5].uiData.yAxisPM = "120";

    if (!CBAssestListCor[3].uiData.status) {
      CBAssestListCor[4].uiData.xAxis = "410";
      CBAssestListCor[4].uiData.yAxis = "72";
      CBAssestListCor[4].uiData.xAxisCB = "510";
      CBAssestListCor[4].uiData.yAxisCB = "200";
      CBAssestListCor[4].uiData.xAxisPM = "415";
      CBAssestListCor[4].uiData.yAxisPM = "120";

      CBAssestListCor[5].uiData.xAxis = "530";
      CBAssestListCor[5].uiData.yAxis = "71";
      CBAssestListCor[5].uiData.xAxisCB = "630.5";
      CBAssestListCor[5].uiData.yAxisCB = "199";
      CBAssestListCor[5].uiData.xAxisPM = "535";
      CBAssestListCor[5].uiData.yAxisPM = "120";
    }
    if (!CBAssestListCor[4].uiData.status) {
      CBAssestListCor[5].uiData.xAxis = "560";
      CBAssestListCor[5].uiData.yAxis = "72";
      CBAssestListCor[5].uiData.xAxisCB = "660";
      CBAssestListCor[5].uiData.yAxisCB = "200";
      CBAssestListCor[5].uiData.xAxisPM = "565";
      CBAssestListCor[5].uiData.yAxisPM = "120";
    }
    if (
      !CBAssestListCor[3].uiData.status &&
      !CBAssestListCor[4].uiData.status
    ) {
      CBAssestListCor[5].uiData.xAxis = "440";
      CBAssestListCor[5].uiData.yAxis = "72";
      CBAssestListCor[5].uiData.xAxisCB = "540";
      CBAssestListCor[5].uiData.yAxisCB = "200";
      CBAssestListCor[5].uiData.xAxisPM = "445";
      CBAssestListCor[5].uiData.yAxisPM = "120";
    }
  } else {
    CBAssestListCor[3].uiData.xAxis = "785";
    CBAssestListCor[3].uiData.yAxis = "205";
    CBAssestListCor[3].uiData.xAxisCB = "885";
    CBAssestListCor[3].uiData.yAxisCB = "330";
    CBAssestListCor[3].uiData.xAxisPM = "790";
    CBAssestListCor[3].uiData.yAxisPM = "250";

    CBAssestListCor[4].uiData.xAxis = "900";
    CBAssestListCor[4].uiData.yAxis = "205";
    CBAssestListCor[4].uiData.xAxisCB = "1000";
    CBAssestListCor[4].uiData.yAxisCB = "330";
    CBAssestListCor[4].uiData.xAxisPM = "905";
    CBAssestListCor[4].uiData.yAxisPM = "250";

    CBAssestListCor[5].uiData.xAxis = "1020";
    CBAssestListCor[5].uiData.yAxis = "205";
    CBAssestListCor[5].uiData.xAxisCB = "1120";
    CBAssestListCor[5].uiData.yAxisCB = "330";
    CBAssestListCor[5].uiData.xAxisPM = "1025";
    CBAssestListCor[5].uiData.yAxisPM = "250";
    if (!CBAssestListCor[3].uiData.status) {
      CBAssestListCor[4].uiData.xAxis = "800";
      CBAssestListCor[4].uiData.yAxis = "205";
      CBAssestListCor[4].uiData.xAxisCB = "900";
      CBAssestListCor[4].uiData.yAxisCB = "330";
      CBAssestListCor[4].uiData.xAxisPM = "805";
      CBAssestListCor[4].uiData.yAxisPM = "250";

      CBAssestListCor[5].uiData.xAxis = "930";
      CBAssestListCor[5].uiData.yAxis = "205";
      CBAssestListCor[5].uiData.xAxisCB = "1030";
      CBAssestListCor[5].uiData.yAxisCB = "330";
      CBAssestListCor[5].uiData.xAxisPM = "935";
      CBAssestListCor[5].uiData.yAxisPM = "250";
    }
    if (!CBAssestListCor[4].uiData.status) {
      CBAssestListCor[5].uiData.xAxis = "900";
      CBAssestListCor[5].uiData.yAxis = "205";
      CBAssestListCor[5].uiData.xAxisCB = "1000";
      CBAssestListCor[5].uiData.yAxisCB = "330";
      CBAssestListCor[5].uiData.xAxisPM = "905";
      CBAssestListCor[5].uiData.yAxisPM = "250";
    }
    if (
      !CBAssestListCor[3].uiData.status &&
      !CBAssestListCor[4].uiData.status
    ) {
      CBAssestListCor[5].uiData.xAxis = "810";
      CBAssestListCor[5].uiData.yAxis = "205";
      CBAssestListCor[5].uiData.xAxisCB = "910";
      CBAssestListCor[5].uiData.yAxisCB = "330";
      CBAssestListCor[5].uiData.xAxisPM = "815";
      CBAssestListCor[5].uiData.yAxisPM = "250";
    }
  }
  // CBAssestListCor[1].uiData.xAxis = "426";
  // CBAssestListCor[1].uiData.yAxis = "130";
  // CBAssestListCor[1].uiData.xAxisCB = "517";
  // CBAssestListCor[1].uiData.yAxisCB = "260";
  // CBAssestListCor[1].uiData.xAxisPM = "422.5";
  // CBAssestListCor[1].uiData.yAxisPM = "120";

  // if (!CBAssestListCor[3].uiData.status) {
  //   CBAssestListCor[4].uiData.xAxis = "880";
  //   CBAssestListCor[4].uiData.yAxis = "205";
  //   CBAssestListCor[4].uiData.xAxisCB = "980";
  //   CBAssestListCor[4].uiData.yAxisCB = "331";
  //   CBAssestListCor[4].uiData.xAxisPM = "885";
  //   CBAssestListCor[4].uiData.yAxisPM = "250";
  // } else {
  //   CBAssestListCor[4].uiData.xAxis = "900";
  //   CBAssestListCor[4].uiData.yAxis = "205";
  //   CBAssestListCor[4].uiData.xAxisCB = "1000";
  //   CBAssestListCor[4].uiData.yAxisCB = "331";
  //   CBAssestListCor[4].uiData.xAxisPM = "905";
  //   CBAssestListCor[4].uiData.yAxisPM = "250";

  //   CBAssestListCor[5].uiData.xAxis = "1020";
  //   CBAssestListCor[5].uiData.yAxis = "205";
  //   CBAssestListCor[5].uiData.xAxisCB = "1120";
  //   CBAssestListCor[5].uiData.yAxisCB = "324";
  //   CBAssestListCor[5].uiData.xAxisPM = "1025";
  //   CBAssestListCor[5].uiData.yAxisPM = "245";
  // }

  // return new state
  return CBAssestListCor;
};

const updateCbAssetListCoordinatesGensetPvBessA2E = (CBAssetList) => {
  let CBAssestListCor = Object.assign([], CBAssetList);

  CBAssestListCor[1].uiData.xAxis = "426";
  CBAssestListCor[1].uiData.yAxis = "130";
  CBAssestListCor[1].uiData.xAxisCB = "517";
  CBAssestListCor[1].uiData.yAxisCB = "260";
  CBAssestListCor[1].uiData.xAxisPM = "422.5";
  CBAssestListCor[1].uiData.yAxisPM = "120";

  CBAssestListCor[7].uiData.xAxis = "570";
  CBAssestListCor[7].uiData.yAxis = "133";
  CBAssestListCor[7].uiData.xAxisCB = "705.5";
  CBAssestListCor[7].uiData.yAxisCB = "260";

  // return new state
  return CBAssestListCor;
};

const updateUtility = (CBAssetList, componentsData) => {
  const CBAssestListData = _.cloneDeep(CBAssetList);
  const attributes = JSON.parse(componentsData.attributes);
  CBAssestListData[0].uiData = JSON.parse(componentsData.uiAttributes);
  CBAssestListData[0].uiData.selected = false;
  CBAssestListData[0].uiData.CBSelected = false;
  CBAssestListData[0].uiData.pmSelected = false;
  CBAssestListData[0].configData = attributes;
  CBAssestListData[0].configData.name = componentsData.componentName;
  CBAssestListData[0].configData.description = attributes.description;
  CBAssestListData[0].configData.utilityCO2 = attributes.utilityCO2;
  CBAssestListData[0].configData.maximum_available_power =
    attributes.maximum_available_power;

  CBAssestListData[0].assetType = componentsData.componentType;
  // new state
  return CBAssestListData;
};
const updateGenset = (CBAssetList, componentsData) => {
  const CBAssestListData = _.cloneDeep(CBAssetList);
  const attributes = JSON.parse(componentsData.attributes);
  CBAssestListData[1].uiData = JSON.parse(componentsData.uiAttributes);
  CBAssestListData[1].uiData.selected = false;
  CBAssestListData[1].uiData.CBSelected = false;
  CBAssestListData[1].uiData.pmSelected = false;
  CBAssestListData[1].configData = attributes;
  CBAssestListData[1].configData.name = componentsData.componentName;
  CBAssestListData[1].configData.description = attributes.description;
  CBAssestListData[1].assetType = componentsData.componentType;
  const communicationDataNew = { ...communicationDataSample };
  if (componentsData.communications.length) {
    communicationDataNew.ipaddress = componentsData.communications[0].ip;
    communicationDataNew.subnetmask =
      componentsData.communications[0].subnetmask;
    communicationDataNew.defaultGateway =
      componentsData.communications[0].defaultGateway;
    communicationDataNew.modbusSlaveId =
      componentsData.communications[0].modbusSlaveId;
    CBAssestListData[1].communicationData = communicationDataNew;
  }
  if (componentsData.asset !== undefined) {
    CBAssestListData[1].assetDetails = [componentsData.asset];
  }

  // new state
  return CBAssestListData;
};
const updateBess = (
  CBAssetList,
  componentsData,
  batteryCommunicationData,
  setBatteryCommunicationData
) => {
  const CBAssestListData = _.cloneDeep(CBAssetList);
  const attributes = JSON.parse(componentsData.attributes);
  CBAssestListData[2].uiData = JSON.parse(componentsData.uiAttributes);
  CBAssestListData[2].uiData.selected = false;
  CBAssestListData[2].uiData.CBSelected = false;
  CBAssestListData[2].uiData.pmSelected = false;
  CBAssestListData[2].uiData.existingPmSelected = false;
  // CBAssestListData[2].uiData.pmRequired = true;


  CBAssestListData[2].configData = attributes;
  CBAssestListData[2].configData.name = componentsData.componentName;
  CBAssestListData[2].configData.description = attributes.description;
  CBAssestListData[2].configData.max_power = attributes.max_power;
  CBAssestListData[2].configData.capacity = attributes.capacity;
  CBAssestListData[2].configData.storage_capcity_hr_value =
    attributes.storage_capcity_hr_value;

  CBAssestListData[2].configData.soc_max = attributes.soc_max;
  CBAssestListData[2].configData.soc_min = attributes.soc_min;
  CBAssestListData[2].configData.soc_nominal_power =
    attributes.soc_nominal_power;
  CBAssestListData[2].assetType = componentsData.componentType;

  const newBatteryCom = { ...batteryCommunicationData };
  if (componentsData.communications.length) {
    newBatteryCom.ipaddress = componentsData.communications[0].ip;
    newBatteryCom.subnetmask = componentsData.communications[0].subnetmask;
    newBatteryCom.defaultGateway =
      componentsData.communications[0].defaultGateway;
    newBatteryCom.modbusSlaveId =
      componentsData.communications[0].modbusSlaveId;
    setBatteryCommunicationData(newBatteryCom);
  }
  // CBAssestListData[2].communicationData = newBatteryCom;
  if (componentsData.asset !== undefined) {
    // CBAssestListData[2].assetDetails.push(componentsData.asset);
    CBAssestListData[2].assetDetails = [componentsData.asset];
  }

  // if(CBAssestListData[2].communicationDataBess){
  //   if(componentsData.communications[0]?.ip !=null){
  //     CBAssestListData[2].communicationDataBess = {
  //       ipaddress: componentsData.communications[0]?.ip,
  //       subnetmask: componentsData.communications[0]?.subnetmask,
  //       defaultGateway: componentsData.communications[0]?.defaultGateway,
  //       modbusSlaveId: componentsData.communications[0]?.modbusSlaveId,
  //     };
  //   }
  //     }
      if(CBAssestListData[2].communicationDataPv){

      if(componentsData.communications[1]?.ip !=null){
        CBAssestListData[2].communicationDataPv = {
          ipaddress: componentsData.communications[1]?.ip,
          subnetmask: componentsData.communications[1]?.subnetmask,
          defaultGateway: componentsData.communications[1]?.defaultGateway,
          modbusSlaveId: componentsData.communications[1]?.modbusSlaveId,
        };
      }

      }
  // new state
  return CBAssestListData;
};

const updatePv = (CBAssetList, componentsData, pvArray) => {
  const CBAssestListData = _.cloneDeep(CBAssetList);
  CBAssestListData[pvArray].uiData = JSON.parse(componentsData.uiAttributes);
  CBAssestListData[pvArray].uiData.selected = false;
  CBAssestListData[pvArray].uiData.CBSelected = false;
  CBAssestListData[pvArray].uiData.pmSelected = false;
  CBAssestListData[pvArray].uiData.existingPmSelected = false;

  CBAssestListData[pvArray].assetType = componentsData.componentType;
  // return state
  return CBAssestListData;
};

const updateChp = (CBAssetList, componentsData) => {
  const CBAssestListData = _.cloneDeep(CBAssetList);
  const attributes = JSON.parse(componentsData.attributes);
  CBAssestListData[6].uiData = JSON.parse(componentsData.uiAttributes);
  CBAssestListData[6].uiData.selected = false;
  CBAssestListData[6].uiData.CBSelected = false;
  CBAssestListData[6].uiData.pmSelected = false;
  CBAssestListData[6].configData = attributes;
  CBAssestListData[6].configData.name = componentsData.componentName;
  CBAssestListData[6].configData.description = attributes.description;
  CBAssestListData[6].configData.nominal_power = attributes.nominal_power;
  CBAssestListData[6].assetType = componentsData.componentType;

  CBAssestListData[6].communicationData = {
    ipaddress: componentsData.communications[0].ip,
    subnetmask: componentsData.communications[0].subnetmask,
    defaultGateway: componentsData.communications[0].defaultGateway,
    modbusSlaveId: componentsData.communications[0].modbusSlaveId,
  };

  if (componentsData.asset !== undefined) {
    CBAssestListData[6].assetDetails.push(componentsData.asset);
  }

  return CBAssestListData;
};

const updateHybrid = (CBAssetList, componentsData) => {
  const CBAssestListData = _.cloneDeep(CBAssetList);

  const attributes = JSON.parse(componentsData.attributes);
  CBAssestListData[7].uiData = JSON.parse(componentsData.uiAttributes);
  CBAssestListData[7].uiData.selected = false;
  CBAssestListData[7].uiData.CBSelected = false;
  CBAssestListData[7].uiData.pmSelected = false;
  // CBAssestListData[7].configData = attributes;
  CBAssestListData[7].configData = {
    ...CBAssestListData["7"].configData,
    ...attributes,
  };
  CBAssestListData[7].configData.name = componentsData.componentName;
  CBAssestListData[7].configData.description = attributes.description;
  CBAssestListData[7].assetType = componentsData.componentType;

  CBAssestListData[7].communicationDataBess = {
    ipaddress: componentsData.communications[0]?.ip,
    subnetmask: componentsData.communications[0]?.subnetmask,
    defaultGateway: componentsData.communications[0]?.defaultGateway,
    modbusSlaveId: componentsData.communications[0]?.modbusSlaveId,
  };
  CBAssestListData[7].communicationDataPv = {
    ipaddress: componentsData.communications[1]?.ip,
    subnetmask: componentsData.communications[1]?.subnetmask,
    defaultGateway: componentsData.communications[1]?.defaultGateway,
    modbusSlaveId: componentsData.communications[1]?.modbusSlaveId,
  };

  if (componentsData.asset !== undefined) {
    CBAssestListData[7].assetDetails = [componentsData.asset];
  }

  return CBAssestListData;
};

const addGenset = (
  CBAssetList,
  defaultPreFill,
  configData,
  count,
  cbCommunicationValues
) => {
  const addGensetAdd = _.cloneDeep(CBAssetList);
  const totalLoads = count.loadCount + count.derCount + count.evCount;
  const modbusSlaveId =
    totalLoads % 7 === 0
      ? "255"
      : defaultPreFill["Circuit Breaker"]?.modbusId_CB_20;
  addGensetAdd[1].uiData.status = true;
  addGensetAdd[1].uiData.configured = false;
  addGensetAdd[1].uiData.CBConfigured = true;
  addGensetAdd[1].configData = { ...configData.gensetConfigData };
  addGensetAdd[1].configData.name = defaultPreFill?.Generator?.name;
  addGensetAdd[1].configData.gensetCO2 = defaultPreFill?.Generator?.gensetCO2;
  addGensetAdd[1].configData.starting_timer =
    defaultPreFill?.Generator?.starting_timer;
  addGensetAdd[1].configData.cooling_timer =
    defaultPreFill?.Generator?.cooling_timer;
  addGensetAdd[1].configData.minimum_running_period =
    defaultPreFill?.Generator?.minimum_running_period;
  addGensetAdd[1].cbConfigData = { ...configData.sampleCbConfigData };
  addGensetAdd[1].cbConfigData.cbAssetDetails = [];
  addGensetAdd[1].cbConfigData.cbCommunicationData = {
    ipaddress: getDerCbIp(cbCommunicationValues),
    subnetmask: defaultPreFill["Circuit Breaker"]?.subnetMask,
    defaultGateway: defaultPreFill["Circuit Breaker"]?.defaultGateway,
    modbusSlaveId: modbusSlaveId,
  };
  addGensetAdd[1].cbConfigData.name =
    defaultPreFill["Circuit Breaker"]?.name +
    " " +
    defaultPreFill?.Generator?.name;
  addGensetAdd[1].assetDetails = [];
  addGensetAdd[1].communicationData = { ...communicationDataSample };
  addGensetAdd[1].communicationData.ipaddress =
    defaultPreFill.Generator?.ipAddress;
  addGensetAdd[1].communicationData.subnetmask =
    defaultPreFill.Generator?.subnetMask;
  addGensetAdd[1].communicationData.defaultGateway =
    defaultPreFill.Generator?.defaultGateway;
  addGensetAdd[1].communicationData.modbusSlaveId =
    defaultPreFill.Generator?.modbusId;

  return addGensetAdd;
};

const addBess = (
  CBAssetList,
  defaultPreFill,
  configData,
  count,
  cbCommunicationValues,
  defaultCbData
) => {
  const addBessAdd = _.cloneDeep(CBAssetList);

  const totalLoads = count.loadCount + count.derCount + count.evCount;
  const modbusSlaveId =
    totalLoads % 7 === 0
      ? "255"
      : defaultPreFill["Circuit Breaker"].modbusId_CB_30;

  addBessAdd[2].uiData.status = true;
  addBessAdd[2].uiData.configured = false;
  addBessAdd[2].uiData.CBConfigured = true;

  addBessAdd[2].configData = { ...configData.batteryConfigData };
  addBessAdd[2].configData.name = defaultPreFill.BESS.name;
  addBessAdd[2].configData.max_power = "";
  addBessAdd[2].configData.capacity = 0;
  addBessAdd[2].configData.soc_max = defaultPreFill.BESS.soc_max;
  addBessAdd[2].configData.soc_min = defaultPreFill.BESS.soc_min;
  addBessAdd[2].configData.soc_nominal_power =
    defaultPreFill.BESS.soc_nominal_power;

  addBessAdd[2].cbConfigData = { ...configData.sampleCbConfigData };
  addBessAdd[2].cbConfigData.cbAssetDetails =
    defaultCbData === null ? [] : [{ ...defaultCbData }];

  addBessAdd[2].cbConfigData.cbCommunicationData = {
    ipaddress: getDerCbIp(cbCommunicationValues),
    subnetmask: defaultPreFill["Circuit Breaker"].subnetMask,
    defaultGateway: defaultPreFill["Circuit Breaker"].defaultGateway,
    modbusSlaveId: modbusSlaveId,
  };

  addBessAdd[2].cbConfigData.name =
    defaultPreFill["Circuit Breaker"].name + " " + defaultPreFill.BESS.name;

  addBessAdd[2].assetDetails = [];

  addBessAdd[2].communicationData = { ...communicationDataSample };
  addBessAdd[2].communicationData.ipaddress = defaultPreFill.BESS.ipAddress;
  addBessAdd[2].communicationData.subnetmask = defaultPreFill.BESS.subnetMask;
  addBessAdd[2].communicationData.defaultGateway =
    defaultPreFill.BESS.defaultGateway;
  addBessAdd[2].communicationData.modbusSlaveId = defaultPreFill.BESS.modbusId;

  return addBessAdd;
};

const addBessExisting = (CBAssetList) => {
  let CBAssetListNew = _.cloneDeep(CBAssetList);
  CBAssetListNew[2].uiData.existingPmRequired = true;
  CBAssetListNew[2].uiData.existingPmConfigured = false;

  return CBAssetListNew;
};
const addPvExistingToCbAssetList = (CBAssetList) => {
  let CBAssetListNew = _.cloneDeep(CBAssetList);
  CBAssetListNew[3].uiData.existingPmRequired = true;
  CBAssetListNew[3].uiData.existingPmConfigured = false;

  return CBAssetListNew;
};

const addChp = (CBAssetList, defaultPreFill, configData, siteType) => {
  const addChpAdd = _.cloneDeep(CBAssetList);

  addChpAdd[6].uiData.status = true;
  addChpAdd[6].uiData.configured = false;
  addChpAdd[6].uiData.CBConfigured = true;

  addChpAdd[6].configData = { ...configData.chpConfigData };

  addChpAdd[6].configData.name = defaultPreFill.CHP.name;
  addChpAdd[6].configData.nominal_power = defaultPreFill.CHP.nominalPower;
  if (siteType === embLabels.ISLANDABLE) {
    addChpAdd[6].cbConfigData = { ...configData.sampleCbConfigData };
    addChpAdd[6].cbConfigData.name = `${defaultPreFill["Circuit Breaker"].name} ${defaultPreFill.CHP.name}`;
  }
  if (siteType === embLabels.PV_HYBRIDIZATION) {
    addChpAdd[6].powerMeterData = { ...configData.powerMeterData };
    addChpAdd[6].powerMeterData.name = `${defaultPreFill["Power Meter"].nameCHP}`;
    addChpAdd[6].powerMeterData.powerMeterModbusId = `${defaultPreFill["Power Meter"].modbusId_PM_CHP}`;
  }

  addChpAdd[6].assetDetails = [];
  addChpAdd[6].communicationData = {
    ...communicationDataSample,
  };
  addChpAdd[6].communicationData.ipaddress = defaultPreFill.CHP.ipAddress;
  addChpAdd[6].communicationData.subnetmask = defaultPreFill.CHP.subnetMask;
  addChpAdd[6].communicationData.defaultGateway =
    defaultPreFill.CHP.defaultGateway;
  addChpAdd[6].communicationData.modbusSlaveId = defaultPreFill.CHP.modbusId;

  return addChpAdd;
};

const removeAssetDer = (CBAssetList, index, configData) => {
  const removeDerData = _.cloneDeep(CBAssetList);
  removeDerData[index].uiData.status = false;
  removeDerData[index].uiData.detailsBlock = false;
  removeDerData[index].uiData.selected = false;
  removeDerData[index].uiData.configured = false;
  removeDerData[index].uiData.CBConfigured = true;
  removeDerData[index].cbConfigData = { ...configData.cbConfigData };
  removeDerData[index].powerMeterData = { ...configData.powerMeterData };
  removeDerData[index].assetDetails = [];
  removeDerData[index].communicationData = {};
  if (index === 1) {
    removeDerData[1].configData = { ...configData.gensetConfigData };
  } else if (index === 2) {
    removeDerData[2].configData = { ...configData.batteryConfigData };
  }
  return removeDerData;
};

const removeAssetDerPv = (CBAssetList, selectedPv) => {
  let newPvData = [...CBAssetList];
  newPvData[selectedPv].uiData.status = false;
  newPvData[selectedPv].uiData.detailsBlock = false;
  newPvData[selectedPv].uiData.selected = false;
  newPvData[selectedPv].uiData.configured = false;
  newPvData[selectedPv].uiData.CBConfigured = false;
  newPvData[selectedPv].uiData.groupName = "";
  newPvData[selectedPv].uiData.count = 0;
  newPvData[selectedPv].configData = {};
  newPvData[selectedPv].cbConfigData = {};

  return newPvData;
};
const removeAssetExistingPm = (CBAssetList, selectedPm) => {
  let newPvData = [...CBAssetList];
  newPvData[selectedPm].uiData.existingPmRequired = false;
  newPvData[selectedPm].uiData.existingPmSelected = false;
  newPvData[selectedPm].uiData.existingPmConfigured = false;
  newPvData[selectedPm].uiData.existingPmConfiguredGrid = false;

  return newPvData;
};

const updateDerCb = (CBAssetList, componentsData, value) => {
  let CBAssestListData = _.cloneDeep(CBAssetList);
  const attributes = JSON.parse(componentsData.attributes);
  CBAssestListData[value].cbConfigData.name = componentsData.componentName;
  CBAssestListData[value].cbConfigData.description = attributes.description;
  CBAssestListData[value].cbConfigData.motorized =
    parseInt(attributes.motorized) === 1 ? true : false;
  CBAssestListData[value].cbConfigData.loadCategory = parseInt(
    attributes.loadCategory
  );
  CBAssestListData[value].cbConfigData.loadPriority = parseInt(
    attributes.loadPriority
  );
  CBAssestListData[value].cbConfigData.cbAssetDetails[0] = componentsData.asset;
  if (componentsData.communications.length) {
    CBAssestListData[value].cbConfigData.cbCommunicationData.ipaddress =
      componentsData.communications[0].ip;
    CBAssestListData[value].cbConfigData.cbCommunicationData.subnetmask =
      componentsData.communications[0].subnetmask;
    CBAssestListData[value].cbConfigData.cbCommunicationData.defaultGateway =
      componentsData.communications[0].defaultGateway;
    CBAssestListData[value].cbConfigData.cbCommunicationData.modbusSlaveId =
      componentsData.communications[0].modbusSlaveId;
  }
  return CBAssestListData;
};

const updateDerCbContactor = (CBAssetList, componentsData, value) => {
  let CBAssestListData = _.cloneDeep(CBAssetList);
  const attributes = JSON.parse(componentsData.attributes);
  CBAssestListData[value].contactorConfigData.name =
    componentsData.componentName;
  CBAssestListData[value].contactorConfigData.description =
    attributes.description;

  CBAssestListData[value].contactorConfigData.loadCategory = parseInt(
    attributes.loadCategory
  );
  CBAssestListData[value].contactorConfigData.loadPriority = parseInt(
    attributes.loadPriority
  );
  CBAssestListData[value].contactorConfigData.cbAssetDetails[0] =
    componentsData.asset;
  if (componentsData.communications.length) {
    CBAssestListData[value].contactorConfigData.cbCommunicationData.ipaddress =
      componentsData.communications[0].ip;
    CBAssestListData[value].contactorConfigData.cbCommunicationData.subnetmask =
      componentsData.communications[0].subnetmask;
    CBAssestListData[
      value
    ].contactorConfigData.cbCommunicationData.defaultGateway =
      componentsData.communications[0].defaultGateway;
    CBAssestListData[
      value
    ].contactorConfigData.cbCommunicationData.modbusSlaveId =
      componentsData.communications[0].modbusSlaveId;
  }
  return CBAssestListData;
};

const updateDerPm = (CBAssetList, componentsData, value) => {
  let name;

  if (value === 4) {
    name = `PM_PV_Array_1`;
  } else if (value === 2) {
    name = "PM_BESS";
  } else if (value === 3) {
    name = "PM_PV_Array";
  } else {
    name = componentsData.componentName;
  }

  let CBAssestListData = _.cloneDeep(CBAssetList);
  const attributes = JSON.parse(componentsData.attributes);
  CBAssestListData[value].powerMeterData.name = name;
  CBAssestListData[value].powerMeterData.powerMeterModbusId =
    componentsData.communications[0].modbusSlaveId;
  CBAssestListData[value].powerMeterData.ipAddress =
    componentsData.communications[0].ip;
  CBAssestListData[value].powerMeterData.gatewayAddress =
    componentsData.communications[0].defaultGateway;
  CBAssestListData[value].powerMeterData.mask =
    componentsData.communications[0].subnetmask;
  CBAssestListData[value].powerMeterData.description = attributes.description;
  CBAssestListData[value].powerMeterData.pmAsset = { ...componentsData.asset };

  return CBAssestListData;
};

const updateDerExistingPm = (CBAssetList, componentsData, value) => {
  let name = componentsData.componentName;

  let CBAssestListData = _.cloneDeep(CBAssetList);
  const attributes = JSON.parse(componentsData.attributes);
  CBAssestListData[value].existingPowerMeterData.name = name;
  CBAssestListData[value].existingPowerMeterData.powerMeterModbusId =
    componentsData.communications[0].modbusSlaveId;
  CBAssestListData[value].existingPowerMeterData.ipAddress =
    componentsData.communications[0].ip;
  CBAssestListData[value].existingPowerMeterData.gatewayAddress =
    componentsData.communications[0].defaultGateway;
  CBAssestListData[value].existingPowerMeterData.mask =
    componentsData.communications[0].subnetmask;
  CBAssestListData[value].existingPowerMeterData.description =
    attributes.description;
  CBAssestListData[value].existingPowerMeterData.pmAsset = {
    ...componentsData.asset,
  };

  return CBAssestListData;
};

const updatePvConfigured = (CBAssetList, PVDetails) => {
  const newPvData = _.clone(CBAssetList);
  const pvStatus1 = PVDetails.pvset1Data.every((data) => data.status);
  newPvData[3].uiData.configured = pvStatus1;
  const pvStatus2 = PVDetails.pvset2Data.every((data) => data.status);
  newPvData[4].uiData.configured = pvStatus2;
  const pvStatus3 = PVDetails.pvset3Data.every((data) => data.status);
  newPvData[5].uiData.configured = pvStatus3;
  return newPvData;
};

const closeDetailsAsset = (CBAssetList) => {
  const newCBAssetList = _.cloneDeep(CBAssetList);
  newCBAssetList.forEach((assetCb) => {
    assetCb.uiData.CBSelected = false;
    assetCb.uiData.selected = false;
    assetCb.uiData.detailsBlock = false;
    assetCb.uiData.pmSelected = false;
    assetCb.uiData.existingPmSelected = false;
  });

  return newCBAssetList;
};

const assetSelect = (CBAssetList, index) => {
  const newCBAssetList = _.cloneDeep(CBAssetList);
  newCBAssetList[index].uiData.selected = true;
  return newCBAssetList;
};
const selectedAssetConfig = (CBAssetList, selectedAsset, configData) => {
  const newCBAssetList = _.cloneDeep(CBAssetList);
  newCBAssetList[selectedAsset].configData = configData;
  return newCBAssetList;
};
const updateAssetConfig = (CBAssetList, selectedAsset, configData) => {
  const newCBAssetList = _.cloneDeep(CBAssetList);
  newCBAssetList[selectedAsset].uiData.configured = true;
  // if (selectedAsset === 7) {
  //   newCBAssetList[1].uiData.configured = true;
  // }
  newCBAssetList[selectedAsset].configData = configData;
  if (
    selectedAsset === 1 &&
    newCBAssetList[selectedAsset].assetDetails[0].assetInfo === "Hardwired"
  ) {
    newCBAssetList[selectedAsset].communicationData = {};
  }
  return newCBAssetList;
};
const duplicateDerUpdate = (CBAssetList, selectedAsset) => {
  const newCBAssetList = _.cloneDeep(CBAssetList);
  newCBAssetList[selectedAsset].uiData.configured = false;

  return newCBAssetList;
};

const assetCbSelect = (CBAssetList, index) => {
  const newCBAssetList = _.cloneDeep(CBAssetList);
  newCBAssetList[index].uiData.CBSelected = true;
  return newCBAssetList;
};

const assetPmSelect = (CBAssetList, index) => {
  const newCBAssetList = _.cloneDeep(CBAssetList);
  newCBAssetList[index].uiData.pmSelected = true;
  return newCBAssetList;
};

const assetPmSelectExisting = (CBAssetList, index) => {
  const newCBAssetList = _.cloneDeep(CBAssetList);
  newCBAssetList[index].uiData.existingPmSelected = true;
  return newCBAssetList;
};

const updateCbConfig = (CBAssetList, selectedAssetCB, cbConfigData) => {
  const newUtilitytData = _.cloneDeep(CBAssetList);
  newUtilitytData[selectedAssetCB].uiData.CBConfigured = true;
  newUtilitytData[selectedAssetCB].cbConfigData = {
    ...newUtilitytData[selectedAssetCB].cbConfigData,
    ...cbConfigData,
  };

  return newUtilitytData;
};

const updateCbContactorConfig = (
  CBAssetList,
  selectedAssetCB,
  contactorConfigData
) => {
  const newUtilitytData = _.cloneDeep(CBAssetList);
  newUtilitytData[selectedAssetCB].uiData.CBConfigured = true;
  newUtilitytData[selectedAssetCB].contactorConfigData = {
    ...newUtilitytData[selectedAssetCB].contactorConfigData,
    ...contactorConfigData,
  };

  return newUtilitytData;
};
const updatePowerMeterConfig = (CBAssetList, selectedAssetPM, cbConfigData) => {
  const newPowerMeterData = _.cloneDeep(CBAssetList);
  newPowerMeterData[selectedAssetPM].uiData.pmConfigured = true;
  newPowerMeterData[selectedAssetPM].uiData.pmConfiguredGrid = true;
  newPowerMeterData[selectedAssetPM].powerMeterData.name = cbConfigData.name;
  newPowerMeterData[selectedAssetPM].powerMeterData.description =
    cbConfigData.description;

  return newPowerMeterData;
};

const updateExistingPowerMeterConfig = (
  CBAssetList,
  selectedAssetPM,
  cbConfigData
) => {
  const newPowerMeterData = _.cloneDeep(CBAssetList);
  newPowerMeterData[selectedAssetPM].uiData.existingPmConfigured = true;
  newPowerMeterData[selectedAssetPM].uiData.existingPmConfiguredGrid = true;
  newPowerMeterData[selectedAssetPM].existingPowerMeterData.name =
    cbConfigData.name;
  newPowerMeterData[selectedAssetPM].existingPowerMeterData.description =
    cbConfigData.description;

  return newPowerMeterData;
};

const selectDerAsset = (CBAssetList, index, selectedAsset) => {
  const newCBassetList = _.cloneDeep(CBAssetList);
  let configDatanew = {};

  selectedAsset.assetAttributes.forEach((configData, index) => {
    switch (selectedAsset.assetAttributes[index].type) {
      case "max_capacity":
        configDatanew.max_capacity = selectedAsset.assetAttributes[index].value;
        break;
      case "capacity":
        configDatanew.capacity = selectedAsset.assetAttributes[index].value;
        break;
      case "max_solar_power":
        configDatanew.max_solar_power =
          selectedAsset.assetAttributes[index].value;
        break;
      case "max_inverter_output_power":
        configDatanew.max_inverter_output_power =
          selectedAsset.assetAttributes[index].value;
      case "usableStorageCapacityHr":
        configDatanew.storage_capcity_hr = JSON.parse(
          selectedAsset.assetAttributes[index].value
        );
        break;
      case "usableStorageCapacity":
        configDatanew.corresponding_name_plate_capacity =
          selectedAsset.assetAttributes[index].value;
        break;

      default:
        break;
    }
  });

  configDatanew.name = selectedAsset.assetType;

  newCBassetList[index].configData = {
    ...newCBassetList[index].configData,
    ...configDatanew,
  };
  newCBassetList[index].assetDetails[0] = selectedAsset;
  newCBassetList[index].uiData.configured = false;
  return newCBassetList;
};
const selectDerCbAsset = (
  CBAssetList,
  index,
  selectedAsset,
  cbCommunicationValues,
  CBValues,
  defaultValues,
  count,
) => {
  const newCBassetList = _.cloneDeep(CBAssetList);
  const newCBassetListPrevious = CBAssetList[index].cbConfigData;
  newCBassetList[index].cbConfigData.cbAssetDetails[0] = selectedAsset;
  newCBassetList[index].uiData.CBConfigured = false; 
  let modbusIdNames = [
    "modbusId_CB_10",
    "modbusId_CB_20",
    "modbusId_CB_30",
    "modbusId_CB_40",
    "modbusId_CB_41",
    "modbusId_CB_42",
    "modbusId_CB_50",
    "modbusId_CB_51",
    "modbusId_CB_52",
    "modbusId_CB_53",
    "modbusId_CB_54",
    "modbusId_CB_55",
    "modbusId_CB_56",
    "modbusId_CB_57",
    "modbusId_CB_58",
    "modbusId_CB_59",
  ];
  const assetInfo = selectedAsset?.assetInfo;
  let ipAddressName
  if((count.derCount+count.loadCount+count.evCount)<=7){
    ipAddressName = `ipAddress${1}`;
  }else if((count.derCount+count.loadCount+count.evCount)>=8&&(count.derCount+count.loadCount+count.evCount)<=14){
    ipAddressName = `ipAddress${2}`
  }else{
    ipAddressName = `ipAddress${3}`
  }
  const modbusId = modbusIdNames[index];
  const ipaddress =
    assetInfo === "Serial" || assetInfo === "Hardwired"
      ? ""
      : defaultValues["Circuit Breaker"][ipAddressName];
  const subnetmask =
    assetInfo === "Serial" || assetInfo === "Hardwired"
      ? ""
      : defaultValues["Circuit Breaker"]?.subnetMask;
  const defaultGateway =
    assetInfo === "Serial" || assetInfo === "Hardwired"
      ? ""
      : defaultValues["Circuit Breaker"]?.defaultGateway;
  const modbusSlaveId =
    assetInfo === "Hardwired"
      ? ""
      : assetInfo === "Serial"
      ? defaultValues["Circuit Breaker"][ipAddressName]?.split(".")[3]
      : defaultValues["Circuit Breaker"][modbusId];

  // const cbCommunicationData = getCBifeValues(
  //   newCBassetListPrevious,
  //   selectedAsset,
  //   cbCommunicationValues
  // );

  const cbCommunicationData = {
    ipaddress,
    subnetmask,
    defaultGateway,
    modbusSlaveId,
  };
  newCBassetList[index].cbConfigData.cbCommunicationData = cbCommunicationData;

  return newCBassetList;
};

const selectDerCbContactorAsset = (CBAssetList, index, selectedAsset) => {
  const newCBassetList = _.cloneDeep(CBAssetList);
  newCBassetList[index].contactorConfigData.cbAssetDetails[0] = selectedAsset;
  return newCBassetList;
};
const selectDerPmAsset = (CBAssetList, index, selectedAsset, defaultValues) => {
  const newCBassetList = _.cloneDeep(CBAssetList);
  newCBassetList[index].powerMeterData.pmAsset = { ...selectedAsset };

  let ipAddressName = [
    "ipAddress_PM_Utility",
    "ipAddress_PM_Genset",
    "ipAddress_PM_BESS",
    "ipAddress_PM_PV1",
    "ipAddress_PM_PV2",
    "ipAddress_PM_PV3",
    ,
    ,
    "ipAddress_PM_Communicating",
    "ipAddress_PM_EV",
  ];

  let modbusIdNames = [
    "modbusId_PM_Utility",
    "modbusId_PM_Genset",
    "modbusId_PM_BESS",
    "modbusId_PM_PV1",
    "modbusId_PM_PV2",
    "modbusId_PM_PV",

    "modbusId_PM_CHP",
    ,
    "modbusId_PM_EV",

    "modbusId_PM_PV_Communicating",
    "modbusId_PM",
  ];
  let modbusId = modbusIdNames[index]
    ? modbusIdNames[index]
    : modbusIdNames[modbusIdNames.length - 1];
  let ipAddress = ipAddressName[index];
  newCBassetList[index].uiData.pmConfiguredGrid = false;
  newCBassetList[index].powerMeterData.powerMeterModbusId =
    selectedAsset.assetInfo === "Hardwired"
      ? ""
      : selectedAsset.assetInfo === "Serial"
      ? defaultValues["Power Meter"][ipAddress]?.split(".")[3]
      : defaultValues["Power Meter"]?.[modbusId];

  if (selectedAsset.assetInfo === embLabels.COMMUNICATING) {
    newCBassetList[index].powerMeterData.ipAddress =
      defaultValues["Power Meter"][ipAddress];
    newCBassetList[index].powerMeterData.gatewayAddress =
      defaultValues["Power Meter"].defaultGateway;
    newCBassetList[index].powerMeterData.mask =
      defaultValues["Power Meter"].subnetMask;
  }
  if (selectedAsset.assetInfo === "Hardwired") {
    newCBassetList[index].powerMeterData.ipAddress = "";
    newCBassetList[index].powerMeterData.gatewayAddress = "";
    newCBassetList[index].powerMeterData.mask = "";
  }
  if (selectedAsset.assetInfo === embLabels.SERIAL) {
    newCBassetList[index].powerMeterData.ipAddress = "";
    newCBassetList[index].powerMeterData.gatewayAddress = "";
    newCBassetList[index].powerMeterData.mask = "";
  }

  return newCBassetList;
};

const selectDerExistingPmAsset = (
  CBAssetList,
  index,
  selectedAsset,
  defaultValues
) => {
  const newCBassetList = _.cloneDeep(CBAssetList);
  newCBassetList[index].existingPowerMeterData.pmAsset = { ...selectedAsset };

  let ipAddressName = [
    ,
    ,
    "ipAddress_PM_EXISTING_BESS",
    "ipAddress_PM_EXISTING_PV",
  ];

  let modbusIdNames = [
    ,
    ,
    "modbusId_PM_EXISTING_BESS",
    "modbusId_PM_EXISTING_PV",
  ];
  let modbusId = modbusIdNames[index]
    ? modbusIdNames[index]
    : modbusIdNames[modbusIdNames.length - 1];
  let ipAddress = ipAddressName[index];
  newCBassetList[index].uiData.pmConfiguredGrid = false;
  newCBassetList[index].existingPowerMeterData.powerMeterModbusId =
    selectedAsset.assetInfo === "Hardwired"
      ? ""
      : selectedAsset.assetInfo === "Serial"
      ? defaultValues["Power Meter"][ipAddress]?.split(".")[3]
      : defaultValues["Power Meter"][modbusId];

  if (selectedAsset.assetInfo === embLabels.COMMUNICATING) {
    newCBassetList[index].existingPowerMeterData.ipAddress =
      defaultValues["Power Meter"][ipAddress];
    newCBassetList[index].existingPowerMeterData.gatewayAddress =
      defaultValues["Power Meter"].defaultGateway;
    newCBassetList[index].existingPowerMeterData.mask =
      defaultValues["Power Meter"].subnetMask;
  }
  if (selectedAsset.assetInfo === "Hardwired") {
    newCBassetList[index].existingPowerMeterData.ipAddress = "";
    newCBassetList[index].existingPowerMeterData.gatewayAddress = "";
    newCBassetList[index].existingPowerMeterData.mask = "";
  }
  if (selectedAsset.assetInfo === embLabels.SERIAL) {
    newCBassetList[index].existingPowerMeterData.ipAddress = "";
    newCBassetList[index].existingPowerMeterData.gatewayAddress = "";
    newCBassetList[index].existingPowerMeterData.mask = "";
  }

  return newCBassetList;
};

const selectSerialAsset = (CBAssetList, index, modbusSlaveId) => {
  const newSerialData = _.cloneDeep(CBAssetList);
  newSerialData[index].communicationData.ipaddress = "";
  newSerialData[index].communicationData.subnetmask = "";
  newSerialData[index].communicationData.defaultGateway = "";
  newSerialData[index].communicationData.modbusSlaveId = modbusSlaveId;
  return newSerialData;
};

const selectCommunicatingAsset = (CBAssetList, index, preFillDefault) => {
  const newCommunicatingData = _.cloneDeep(CBAssetList);

  newCommunicatingData[index].communicationData.ipaddress =
    preFillDefault.ipAddress;
  newCommunicatingData[index].communicationData.subnetmask =
    preFillDefault.subnetMask;
  newCommunicatingData[index].communicationData.defaultGateway =
    preFillDefault.defaultGateway;
  newCommunicatingData[index].communicationData.modbusSlaveId =
    preFillDefault.modbusId;

  return newCommunicatingData;
};

const individualPvAssetSelect = (CBAssetList, selectedPv) => {
  let pvData = _.cloneDeep(CBAssetList);
  pvData[selectedPv].uiData.configured = false;
  return pvData;
};

const reducePvCountFromCb = (CBAssetList, selectedPv, pvConStatusOne) => {
  let newPvData = [...CBAssetList];
  newPvData[selectedPv].uiData.count = newPvData[selectedPv].uiData.count - 1;

  newPvData[selectedPv].uiData.configured = pvConStatusOne;

  return newPvData;
};

const addPvToCbAssetList = (
  CBAssetList,
  selectedPv,
  PVDetails,
  configData,
  preFillDefault,
  count,
  cbCommunicationValues,
  defaultCbData
) => {
  let newPvData = [...CBAssetList];

  let selectedCb = selectedPv === 3 ? 1 : selectedPv === 4 ? 2 : 3;
  let selectedCbNumber =
    selectedPv === 3 ? "CB_40" : selectedPv === 4 ? "CB_41" : "CB_42";

  const modbusSlaveId = preFillDefault["Circuit Breaker"][`modbusId_${selectedCbNumber}`];

  newPvData[selectedPv].uiData.status = true;
  newPvData[selectedPv].uiData.configured = false;
  newPvData[selectedPv].uiData.pmConfiguredGrid = false;

  newPvData[selectedPv].uiData.count =
    newPvData[selectedPv].uiData.count + PVDetails.pvCount;

  newPvData[selectedPv].configData = {};

  if (PVDetails.existOrNew === "new") {
    newPvData[selectedPv].uiData.CBConfigured = false;
    newPvData[selectedPv].cbConfigData = Object.assign(
      {},
      configData.sampleCbConfigData
    );
    newPvData[selectedPv].cbConfigData.cbAssetDetails =
      defaultCbData === null ? [] : [{ ...defaultCbData }];
    newPvData[selectedPv].cbConfigData.cbCommunicationData = {
      ipaddress: getDerCbIp(cbCommunicationValues),
      subnetmask: preFillDefault["Circuit Breaker"].subnetMask,
      defaultGateway: preFillDefault["Circuit Breaker"].defaultGateway,
      modbusSlaveId: modbusSlaveId,
    };

    newPvData[
      selectedPv
    ].cbConfigData.name = `${preFillDefault["Circuit Breaker"].name} ${preFillDefault.PV_inverter.groupName} ${selectedCb} `;
  }

  return newPvData;
};

const pvAssetPmRequired = (
  CBAssetList,
  index,
  defaultPmData,
  defaultValues
) => {
  let CBAssetListNew = _.cloneDeep(CBAssetList);
  CBAssetListNew[index].uiData.pmRequired =
    !CBAssetListNew[index].uiData.pmRequired;
  CBAssetListNew[index].uiData.pmConfiguredGrid = CBAssetListNew[index].uiData.pmRequired? false:true;
  CBAssetListNew[index].powerMeterData.pmAsset = defaultPmData
    ? { ...defaultPmData }
    : {};
  let ipAddressName = [
    "ipAddress_PM_Utility",
    "ipAddress_PM_Genset",
    "ipAddress_PM_BESS",
    "ipAddress_PM_PV1",
    "ipAddress_PM_PV2",
    "ipAddress_PM_PV3",
    ,
    ,
    "ipAddress_PM_Communicating",
    "ipAddress_PM_EV",
  ];

  let modbusIdNames = [
    "modbusId_PM_Utility",
    "modbusId_PM_Genset",
    "modbusId_PM_BESS",
    "modbusId_PM_PV",
    "modbusId_PM_PV1",
    "modbusId_PM_PV2",

    "modbusId_PM_CHP",
    ,
    "modbusId_PM_EV",

    "modbusId_PM_PV_Communicating",
    "modbusId_PM",
  ];
  let modbusId = modbusIdNames[index]
    ? modbusIdNames[index]
    : modbusIdNames[modbusIdNames.length - 1];
  let ipAddress = ipAddressName[index];

  CBAssetListNew[index].powerMeterData.powerMeterModbusId =
    defaultPmData.assetInfo === "Hardwired"
      ? ""
      : defaultPmData.assetInfo === "Serial"
      ? defaultValues["Power Meter"][ipAddress]?.split(".")[3]
      : defaultValues["Power Meter"]?.[modbusId];

  if (defaultPmData.assetInfo === embLabels.COMMUNICATING) {
    CBAssetListNew[index].powerMeterData.ipAddress =
      defaultValues["Power Meter"][ipAddress];
    CBAssetListNew[index].powerMeterData.gatewayAddress =
      defaultValues["Power Meter"].defaultGateway;
    CBAssetListNew[index].powerMeterData.mask =
      defaultValues["Power Meter"].subnetMask;
  }
  if (defaultPmData.assetInfo === "Hardwired") {
    CBAssetListNew[index].powerMeterData.ipAddress = "";
    CBAssetListNew[index].powerMeterData.gatewayAddress = "";
    CBAssetListNew[index].powerMeterData.mask = "";
  }
  if (defaultPmData.assetInfo === embLabels.SERIAL) {
    CBAssetListNew[index].powerMeterData.ipAddress = "";
    CBAssetListNew[index].powerMeterData.gatewayAddress = "";
    CBAssetListNew[index].powerMeterData.mask = "";
  }

  // derList[0].powerMeterData.ipAddress = (pmUtility?.assetInfo === "Serial" || pmUtility?.assetInfo === "Hardwired") ? "" : defaultValues["Power Meter"]?.ipAddress_PM_Utility;
  //   derList[0].powerMeterData.powerMeterModbusId = (pmUtility?.assetInfo === "Hardwired") ? "" :pmUtility?.assetInfo === "Serial"? defaultValues["Power Meter"]?.ipAddress_PM_Utility?.split(".")[3]: defaultValues["Power Meter"].modbusId_PM_Utility;
  //   derList[0].powerMeterData.gatewayAddress=(pmUtility?.assetInfo === "Serial" || pmUtility?.assetInfo === "Hardwired") ? "" : defaultValues["Power Meter"]?.defaultGateway;
  //   derList[0].powerMeterData.mask =(pmUtility?.assetInfo === "Serial" || pmUtility?.assetInfo === "Hardwired") ? "" : defaultValues["Power Meter"]?.subnetMask;

  return CBAssetListNew;
};

const cbAssetRequired = (
  CBAssetList,
  index,
  defaultCbData,
  defaultValues,
  dummyCbData
) => {
  let CBAssetListNew = _.cloneDeep(CBAssetList);
  CBAssetListNew[index].uiData.cbRequired =
    !CBAssetListNew[index]?.uiData?.cbRequired;
  if (CBAssetListNew[index]?.uiData?.cbRequired) {
    CBAssetListNew[index].cbConfigData.cbAssetDetails =
      defaultCbData === null ? [] : [{ ...defaultCbData }];
    CBAssetListNew[index].uiData.CBConfigured = false;
    const modbusId =
      index === 0 || index === 7
        ? "255"
        : index === 1
        ? "2"
        : index === 2
        ? "3"
        : "4";

    CBAssetListNew[index].cbConfigData.cbCommunicationData.ipaddress =
      defaultValues["Circuit Breaker"].ipAddress1;
    CBAssetListNew[index].cbConfigData.cbCommunicationData.subnetmask =
      "255.255.255.240";
    CBAssetListNew[index].cbConfigData.cbCommunicationData.defaultGateway =
      "192.168.99.49";
    CBAssetListNew[index].cbConfigData.cbCommunicationData.modbusSlaveId =
      modbusId;
  } else {
    CBAssetListNew[index].cbConfigData.cbAssetDetails =
      dummyCbData === null ? [] : [{ ...dummyCbData }];
    CBAssetListNew[index].uiData.CBConfigured = true;
    CBAssetListNew[index].uiData.motorized = false;
    CBAssetListNew[index].cbConfigData.cbCommunicationData.ipaddress = "";
    CBAssetListNew[index].cbConfigData.cbCommunicationData.subnetmask = "";
    CBAssetListNew[index].cbConfigData.cbCommunicationData.defaultGateway = "";
    CBAssetListNew[index].cbConfigData.cbCommunicationData.modbusSlaveId = "";
  }

  return CBAssetListNew;
};

const cbAssetConfiguredRequired = (CBAssetList, index, defaultCbData) => {
  let CBAssetListNew = _.cloneDeep(CBAssetList);
  CBAssetListNew[index].uiData.cbRequired =
    !CBAssetListNew[index].uiData.cbRequired;
  CBAssetListNew[index].cbConfigData.cbAssetDetails =
    defaultCbData === null ? [] : [{ ...defaultCbData }];
  CBAssetListNew[index].uiData.CBConfigured = false;

  return CBAssetListNew;
};
const getDerCbIp = (cbCommunicationValues) => {
  ///ip address
  const ifeIp1 = cbCommunicationValues?.allIps?.[0];
  const ifeIp2 = cbCommunicationValues?.allIps?.[1];
  const ifeIp3 = cbCommunicationValues?.allIps?.[2];
  const ifeIp4 = cbCommunicationValues?.allIps?.[3];
  // const ifeIp1 = "192.168.50.21";
  // const ifeIp2 = "192.168.50.22";
  // const ifeIp3 = "192.168.50.23";
  // const ifeIp4 = "192.168.50.24";

  const ifeSet1 = cbCommunicationValues?.ifeIpSets?.[ifeIp1]?.[0] ?? 0;
  const ifeSet2 = cbCommunicationValues?.ifeIpSets?.[ifeIp2]?.[0] ?? 0;
  const ifeSet3 = cbCommunicationValues?.ifeIpSets?.[ifeIp3]?.[0] ?? 0;
  const ifeSet4 = cbCommunicationValues?.ifeIpSets?.[ifeIp4]?.[0] ?? 0;

  const ifeSet1Max = cbCommunicationValues?.ifeIpSets?.[ifeIp1]?.[1] ?? 0;
  const ifeSet2Max = cbCommunicationValues?.ifeIpSets?.[ifeIp2]?.[1] ?? 0;
  const ifeSet3Max = cbCommunicationValues?.ifeIpSets?.[ifeIp3]?.[1] ?? 0;
  const ifeSet4Max = cbCommunicationValues?.ifeIpSets?.[ifeIp4]?.[1] ?? 0;

  const ipaddress =
    ifeSet1 !== ifeSet1Max
      ? ifeIp1
      : ifeSet2 !== ifeSet2Max
      ? ifeIp2
      : ifeSet3 !== ifeSet3Max
      ? ifeIp3
      : ifeSet4 !== ifeSet4Max
      ? ifeIp4
      : cbCommunicationValues?.availableIps?.[0];
  return ipaddress;
};

const getCBifeValues = (
  newCBassetListPrevious,
  selectedAsset,
  cbCommunicationValues
) => {
  const numberOfDevicesIFEPrevious = parseInt(
    newCBassetListPrevious.cbAssetDetails[0]?.assetAttributes?.find(
      (assetAttribute) => assetAttribute.type === "numberOfDevicesIFE"
    )?.value ?? 7
  );
  const numberOfDevicesIFE = parseInt(
    selectedAsset?.assetAttributes?.find(
      (assetAttribute) => assetAttribute.type === "numberOfDevicesIFE"
    )?.value ?? 7
  );
  if (numberOfDevicesIFEPrevious === numberOfDevicesIFE) {
    return { ...newCBassetListPrevious.cbCommunicationData };
  }

  if (numberOfDevicesIFE === 1) {
    const ipaddress = cbCommunicationValues.availableIps[0];
    return {
      ...newCBassetListPrevious.cbCommunicationData,
      ipaddress: ipaddress,
      modbusSlaveId: "255",
    };
  }
  if (numberOfDevicesIFE === 7) {
    return {
      ...newCBassetListPrevious.cbCommunicationData,
      ipaddress: getDerCbIp(cbCommunicationValues),
      modbusSlaveId: cbCommunicationValues.ifeIpSets[
        getDerCbIp(cbCommunicationValues)
      ]
        ? newCBassetListPrevious.cbCommunicationData.modbusSlaveId
        : "255",
    };
  }
};

export { initialCBAssetList, CBAssetListReducer };
