import React, { useCallback, useRef, useState } from "react";
import Resizer from "react-image-file-resizer";

import Snackbar from "../../pages/SE-Component/Snackbar";
import useMountEffect from "../../pages/CustomHooks/useMountEffect";
import MyErrorBoundary from "../../utils/ErrorBoundary";

const inActiveComments =
  "Not available as standard, Contact Schneider-Electric support team for integration in your application";
let manufacturerListAll = [];

const AddAsset = ({
  addAssetPopupStatus,
  addStatus,
  assetsType,
  manufacturerList,
  updatedAsset,
  onCloseAssetPopUp,
  onUpdateAsset,
  getAssetDetailsAttribute,
}) => {
  const [loading, setLoading] = useState(true);
  const [newManufacturer, setNewManufacturer] = useState(false);
  const [checkBoxIsShown, setCheckBoxIsShown] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [assetDetails, setAssetDetails] = useState({
    assetId: 0,
    assetType: 2,
    assetName: "",
    manufacturer: "",
    communicationModel: "",
    version: "",
    assetInfo: "",
    imagePreviewUrl: null,
    imageLink: null,
    validated: true,
    comments: "",
    availability: "",
  });
  const [availableTypes, setAvailableTypes] = useState({
    generic: false,
    greenStruxure: false,
    A2E: false,
  });
  const [errorStatus, setErrorStatus] = useState({
    commentsErrorStatus: false,
    assetTypeErrorStatus: false,
    versionErrorStatus: false,
    communicationModelErrorStatus: false,
    manufacturerErrorStatus: false,
    assetInfoErrorStatus: false,
    availabilityErrorStatus: false,
  });

  const [snackBar, setSnackBar] = useState({ type: "", message: "" });

  const snackBarRef = useRef(null);

  useMountEffect(() => {
    setLoading(false);
    if (addStatus === false) {
      setAssetDetails(prevState => ({
        ...prevState,
        assetId: updatedAsset.assetId,
        assetInfo: updatedAsset.assetInfo,
        validated: updatedAsset.validated,
        version: updatedAsset.version,
        manufacturer: updatedAsset.manufacturer,
        communicationModel: updatedAsset.communicationModel,
        assetName: updatedAsset.assetName,
        assetType: updatedAsset.type.id,
        comments: updatedAsset.comments,
        imagePreviewUrl: updatedAsset.imageLink,
        imageLink: updatedAsset.imageLink,
        availability: updatedAsset.availability,
      }));
      setSelectedFile(updatedAsset.imageLink);
      const availabilityArray = updatedAsset.availability.split(",");
      if (availabilityArray.length) {
        setAvailableTypes(prevState => ({
          ...prevState,
          generic: availabilityArray.includes("Generic") ? true : false,
          greenStruxure: availabilityArray.includes("GreenStruxure")
            ? true
            : false,
          A2E: availabilityArray.includes("A2E"),
        }));
      }
    }
    let manufacturerDataList = [];
    assetsType.map(assetTypeData => {
      let manufacturerListNew = new Set(manufacturerList[assetTypeData.type]);
      manufacturerDataList[assetTypeData.id] = Array.from(manufacturerListNew);
      return null;
    });
    manufacturerListAll = manufacturerDataList;

    let filteredId = assetsType.filter(
      assetTypeData =>
        assetTypeData.id ===
        parseInt(
          addStatus === false ? updatedAsset.type.id : assetDetails.assetType
        )
    );

    setAssetDetails(prevState => ({
      ...prevState,
      assetName: filteredId[0].type,
    }));
    getAssetDetailsAttribute(
      addStatus === false ? updatedAsset.type.id : assetDetails.assetType
    );
  }, []);

  const handleChange = e => {
    const { name, value } = e.target;
    switch (name) {
      case "assetType":
        const filteredId = assetsType.filter(
          assetTypeData => assetTypeData.id === parseInt(value)
        );
        setAssetDetails(prevState => ({
          ...prevState,
          assetName: filteredId[0].type,
        }));
        getAssetDetailsAttribute(value);
        setErrorStatus(prevState => ({
          ...prevState,
          assetTypeErrorStatus: true,
        }));
        break;

      case "manufacturer":
        setErrorStatus(prevState => ({
          ...prevState,
          manufacturerErrorStatus: true,
        }));
        break;

      case "communicationModel":
        setErrorStatus(prevState => ({
          ...prevState,
          communicationModelErrorStatus: true,
        }));
        break;

      case "version":
        setErrorStatus(prevState => ({
          ...prevState,
          versionErrorStatus: true,
        }));
        break;

      case "assetInfo":
        setErrorStatus(prevState => ({
          ...prevState,
          assetInfoErrorStatus: true,
        }));
        break;

      case "comments":
        setErrorStatus(prevState => ({
          ...prevState,
          commentsErrorStatus: true,
        }));
        break;

      default:
        break;
    }
    setAssetDetails(prevState => ({
      ...prevState,

      [name]: value,
    }));
  };

  const handleCheckBoxChange = e => {
    const { name, checked } = e.target;

    setAssetDetails(prevState => ({
      ...prevState,
      [name]: checked,
    }));

    if (checked === false) {
      setAssetDetails(prevState => ({
        ...prevState,
        comments: inActiveComments,
      }));
    } else {
      setAssetDetails(prevState => ({
        ...prevState,
        comments: "",
      }));
    }
  };

  const enableNewManufacturer = () => setNewManufacturer(!newManufacturer);

  const fileChangedHandler = event => {
    if (event.target.files[0].size > 50000) {
      callSnackbar("error", "Image size should be less than 50kb");
      event.target.value = null;
    } else if (
      event.target.files[0].type !== "image/png" &&
      event.target.files[0].type !== "image/jpg" &&
      event.target.files[0].type !== "image/jpeg" &&
      event.target.files[0].type !== "image/gif" &&
      event.target.files[0].type !== "image/tiff"
    ) {
      callSnackbar(
        "error",
        "Invalid file format, Please select .jpg/.jpeg/.png/.tiff/.gif file"
      );
      event.target.value = null;
    } else {
      Resizer.imageFileResizer(
        event.target.files[0],
        50,
        50,
        "png",
        100,
        0,
        uri => {
          setSelectedFile(uri);
          setAssetDetails(prevState => ({
            ...prevState,
            imagePreviewUrl: uri,
          }));
        },
        "base64"
      );
    }
  };

  const removeAssetImage = () => {
    setSelectedFile(null);
    setAssetDetails(prevState => ({
      ...prevState,
      imagePreviewUrl: null,
    }));
  };

  const cancelPopup = () => {
    resetValues();
    onCloseAssetPopUp();
  };

  const resetValues = () => {
    setAssetDetails(prevState => ({
      ...prevState,
      assetType: 2,
      assetName: "",
      manufacturer: "",
      communicationModel: "",
      version: "",
      assetInfo: "",
      imagePreviewUrl: null,
      imageLink: null,
      validated: true,
      comments: "",
      availability: "",
    }));
    setErrorStatus(prevState => {
      return {
        ...prevState,
        commentsErrorStatus: false,
        assetTypeErrorStatus: false,
        versionErrorStatus: false,
        communicationModelErrorStatus: false,
        manufacturerErrorStatus: false,
        assetInfoErrorStatus: false,
        availabilityErrorStatus: false,
      };
    });
    setNewManufacturer(false);
  };

  const saveAsset = () => {
    setLoading(true);

    if (assetDetails.assetType === "") {
      callSnackbar("error", "Asset Type is mandatory");
      setLoading(false);
    } else if (assetDetails.manufacturer.trim() === "") {
      callSnackbar("error", "Asset Manufacturer is mandatory");
      setLoading(false);
    } else if (assetDetails.communicationModel.trim() === "") {
      callSnackbar("error", "Asset model is mandatory");
      setLoading(false);
    } else if (assetDetails.version.trim() === "") {
      callSnackbar("error", "Asset version is mandatory");
      setLoading(false);
    } else if (assetDetails.assetInfo.trim() === "") {
      callSnackbar("error", "Communication Model is mandatory");
      setLoading(false);
    } else if (
      !(
        availableTypes.generic ||
        availableTypes.greenStruxure ||
        availableTypes.A2E
      )
    ) {
      callSnackbar("error", "Asset availabilty is mandatory");
      setLoading(false);
    } else if (!assetDetails.comments || assetDetails.comments.trim() === "") {
      callSnackbar("error", "Asset comments is mandatory");
      setLoading(false);
    } else {
      let jsonData = {
        assetId: assetDetails.assetId,
        assetInfo: assetDetails.assetInfo,
        validated: assetDetails.validated,
        version: assetDetails.version,
        manufacturer: assetDetails.manufacturer,
        communicationModel: assetDetails.communicationModel,
        assetName: assetDetails.assetName,
        imageLink: selectedFile,
        type: {
          id: assetDetails.assetType,
        },
        comments: assetDetails.comments,
        availability: assetDetails.availability,
      };
      onUpdateAsset(jsonData);
    }
  };

  const showCheckBox = () => setCheckBoxIsShown(!checkBoxIsShown);

  const handleAvailabilityCheckBoxChange = e => {
    let availabilityArray = [];
    let availability = assetDetails.availability;
    const { name, checked, value } = e.target;

    if (availability.trim()) {
      availabilityArray = availability.split(",");
    }

    if (checked && availabilityArray.indexOf(value) === -1) {
      availabilityArray.push(value);
    }
    if (!checked) {
      availabilityArray = availabilityArray.filter(v => v !== value);
    }
    let newAvailability = availabilityArray.toString();
    setErrorStatus(prevState => ({
      ...prevState,
      availabilityErrorStatus: true,
    }));
    setAvailableTypes(prevState => ({
      ...prevState,
      [name]: checked,
    }));
    setAssetDetails(prevState => ({
      ...prevState,
      availability: newAvailability,
    }));
  };

  const callSnackbar = useCallback((type, message) => {
    setSnackBar({ type, message });
    snackBarRef.current.open = true;
  }, []);

  return (
    <MyErrorBoundary>
      
    <se-dialog
      id="dialog-complex"
      size="large"
      open={addAssetPopupStatus}
      can-backdrop="false"
    >
      <se-dialog-header>
        {addStatus ? "Add New Asset" : "Edit Asset"}
      </se-dialog-header>

      <se-dialog-content className="se-dialog-content">
        <se-loading loading={loading}></se-loading>
        <div className="container ">
          <div className="col-md-12">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group row ">
                      <label
                        htmlFor="inputEmail6"
                        className="col-md-4 col-form-label"
                      >
                        Asset Type<span className="se-mandatory">*</span>
                      </label>
                      <div className="col-md-8">
                        <select
                          className="col-md-12 form-control form-control-sm"
                          name="assetType"
                          value={assetDetails.assetType}
                          onChange={handleChange}
                        >
                          <option value="" disabled defaultValue hidden>
                            Select Asset Type
                          </option>
                          {assetsType.map(assetTypeData => (
                            <option
                              key={assetTypeData.id}
                              value={assetTypeData.id}
                            >
                              {assetTypeData.type === "PV_inverter"
                                ? "PV Inverter"
                                : assetTypeData.type === "EV_charging_station"
                                ? "EV Charging Station"
                                : assetTypeData.type}
                            </option>
                          ))}
                        </select>
                        {parseInt(assetDetails.assetType) <= 0 &&
                          errorStatus.assetTypeErrorStatus === true && (
                            <div className="invalid-feedback-custom">
                              Asset Type is required.
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        htmlFor="inputEmail2"
                        className="col-md-4 col-form-label"
                      >
                        Asset Manufacturer
                        <span className="se-mandatory">*</span>
                      </label>

                      {addStatus ? (
                        newManufacturer === true ? (
                          <div className="col-md-8 input-group">
                            <input
                              type="text"
                              autoComplete="none"
                              className="form-control form-control-sm"
                              name="manufacturer"
                              placeholder=""
                              maxLength="50"
                              value={assetDetails.manufacturer}
                              onChange={handleChange}
                            />
                            <span
                              className="input-group-append pointer"
                              onClick={enableNewManufacturer}
                            >
                              <se-icon size="medium" color="primary">
                                action_delete_cross
                              </se-icon>
                            </span>

                            {assetDetails.manufacturer.trim() === "" &&
                              errorStatus.manufacturerErrorStatus && (
                                <div className="invalid-feedback-custom">
                                  Asset Manufacturer is required.
                                </div>
                              )}
                          </div>
                        ) : (
                          <div className="col-md-8 input-group">
                            <select
                              className="col-md-12 form-control form-control-sm"
                              name="manufacturer"
                              value={assetDetails.manufacturer}
                              onChange={handleChange}
                            >
                              <option value="" disabled defaultValue hidden>
                                Select Asset Manufacturer
                              </option>
                              {manufacturerListAll.map(
                                (manufacturerName, index) =>
                                  manufacturerName.map(manufacturer =>
                                    index ===
                                    parseInt(assetDetails.assetType) ? (
                                      <option
                                        key={manufacturer}
                                        value={manufacturer}
                                      >
                                        {manufacturer}
                                      </option>
                                    ) : (
                                      ""
                                    )
                                  )
                              )}
                            </select>

                            <span
                              className="input-group-append pointer"
                              onClick={enableNewManufacturer}
                            >
                              <se-icon size="medium" color="primary">
                                action_editor
                              </se-icon>
                            </span>
                            {assetDetails.manufacturer.trim() === "" &&
                              errorStatus.manufacturerErrorStatus && (
                                <div className="invalid-feedback-custom">
                                  Asset Manufacturer is required.
                                </div>
                              )}
                          </div>
                        )
                      ) : (
                        <div className="col-md-8 input-group">
                          <input
                            type="text"
                            autoComplete="none"
                            className="form-control form-control-sm"
                            name="manufacturer"
                            placeholder=""
                            maxLength="50"
                            value={assetDetails.manufacturer}
                            onChange={handleChange}
                          />
                          {assetDetails.manufacturer.trim() === "" &&
                            errorStatus.manufacturerErrorStatus && (
                              <div className="invalid-feedback-custom">
                                Asset Manufacturer is required.
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        htmlFor="inputEmail3"
                        className="col-md-4 col-form-label"
                      >
                        Asset Model<span className="se-mandatory">*</span>
                      </label>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="none"
                          className="form-control form-control-sm"
                          name="communicationModel"
                          placeholder="Model Name"
                          maxLength="50"
                          value={assetDetails.communicationModel}
                          onChange={handleChange}
                        />

                        {assetDetails.communicationModel.trim() === "" &&
                          errorStatus.communicationModelErrorStatus && (
                            <div className="invalid-feedback-custom">
                              Asset Model is required.
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        htmlFor="inputEmail4"
                        className="col-md-4 col-form-label"
                      >
                        Version<span className="se-mandatory">*</span>
                      </label>
                      <div className="col-md-8">
                        <input
                          type="text"
                          autoComplete="none"
                          className="form-control form-control-sm"
                          name="version"
                          placeholder="version"
                          maxLength="10"
                          value={assetDetails.version}
                          onChange={handleChange}
                        />
                        {assetDetails.version.trim() === "" &&
                          errorStatus.versionErrorStatus && (
                            <div className="invalid-feedback-custom">
                              Version is required.
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group row ">
                      <label
                        htmlFor="inputEmail6"
                        className="col-md-4 col-form-label"
                      >
                        Communication Model
                        <span className="se-mandatory">*</span>
                      </label>
                      <div className="col-md-8">
                        <select
                          className="col-md-12 form-control form-control-sm"
                          name="assetInfo"
                          value={assetDetails.assetInfo}
                          onChange={handleChange}
                        >
                          <option value="" disabled defaultValue hidden>
                            Select Communication Model
                          </option>
                          <option value="Hardwired">Hardwired</option>
                          <option value="Communicating">Communicating</option>
                          <option value="Serial">Serial</option>
                        </select>
                        {assetDetails.assetInfo.trim() === "" &&
                          errorStatus.assetInfoErrorStatus && (
                            <div className="invalid-feedback-custom">
                              Communication Model is required.
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        htmlFor="inputEmail4"
                        className="col-md-4 col-form-label"
                      >
                        Asset Image
                      </label>

                      {assetDetails.imagePreviewUrl !== null ? (
                        <div className="col-md-2">
                          <img
                            src={assetDetails.imagePreviewUrl}
                            className="img-thumbnail asset-add-image"
                            alt="Asset icon"
                          />
                          <span
                            className="remove-asset-image"
                            style={{ display: "inline" }}
                            onClick={removeAssetImage}
                          >
                            &#215;
                          </span>
                        </div>
                      ) : (
                        <div className="col-md-6">
                          <label
                            className="add-asset-image"
                            title="click to upload"
                            htmlFor="uploadAssetImage"
                          >
                            &#43;
                          </label>
                          <input
                            type="file"
                            id="uploadAssetImage"
                            style={{ display: "none" }}
                            accept="image/x-png,image/gif,image/jpeg,image/jpg"
                            className="form-control-file form-control-sm"
                            name="assetImage"
                            placeholder=""
                            onChange={fileChangedHandler}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        htmlFor="inputEmail5"
                        className="col-md-4 col-form-label"
                      >
                        Active
                      </label>
                      <div className="col-md-8">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input asCompatible"
                            id="Checkbox1"
                            name="validated"
                            checked={assetDetails.validated}
                            onChange={handleCheckBoxChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="Checkbox1"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group row ">
                      <label
                        htmlFor="inputEmail6"
                        className="col-md-4 col-form-label"
                      >
                        Availability
                        <span className="se-mandatory">*</span>
                      </label>

                      <div className="multiselect">
                        <div
                          className="col-md-12 selectBox"
                          onClick={showCheckBox}
                        >
                          <select className="col-md-12 form-control form-control-sm">
                            <option>
                              {assetDetails.availability
                                ? assetDetails.availability
                                : "Select an Option"}
                            </option>
                          </select>
                          <div className="overSelect"></div>
                        </div>
                        {checkBoxIsShown && (
                          <div id="checkboxes">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input asCompatible"
                                id="genericCheckBox"
                                name="generic"
                                value={"Generic"}
                                checked={availableTypes.generic}
                                onChange={handleAvailabilityCheckBoxChange}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="genericCheckBox"
                              >
                                Generic
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input asCompatible"
                                id="greenStruxureCheckBox"
                                name="greenStruxure"
                                value={"GreenStruxure"}
                                checked={availableTypes.greenStruxure}
                                onChange={handleAvailabilityCheckBoxChange}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="greenStruxureCheckBox"
                              >
                                GreenStruxure
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input asCompatible"
                                id="A2ECheckBox"
                                name="A2E"
                                value={"A2E"}
                                checked={availableTypes.A2E}
                                onChange={handleAvailabilityCheckBoxChange}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="A2ECheckBox"
                              >
                                A2E
                              </label>
                            </div>
                          </div>
                        )}
                      </div>

                      {assetDetails.availability.trim() === "" &&
                        errorStatus.availabilityErrorStatus && (
                          <div className="invalid-feedback-custom">
                            Availability is required.
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group row">
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className="col-md-2 col-form-label"
                      >
                        Comments <span className="se-mandatory">*</span>
                      </label>
                      <div className="col-md-10">
                        <textarea
                          className="form-control form-control-sm"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          name="comments"
                          placeholder="Enter the message"
                          value={assetDetails.comments}
                          onChange={handleChange}
                        ></textarea>
                        {assetDetails.comments === "" &&
                          errorStatus.commentsErrorStatus && (
                            <div className="invalid-feedback-custom">
                              Comments is required.
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Snackbar snackBar={snackBar} ref={snackBarRef} />
      </se-dialog-content>

      <se-dialog-footer>
        <se-button onClick={cancelPopup}>Cancel</se-button>

        <se-button color="primary" onClick={saveAsset}>
          {addStatus ? "Submit" : "Update"}
        </se-button>
      </se-dialog-footer>
    </se-dialog>
    </MyErrorBoundary>
  );
};

export default AddAsset;
