import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";
import useCBMouserOver from "../../pages/CustomHooks/useCBMouserOver";
import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";
import MyErrorBoundary from "../../utils/ErrorBoundary";

const Linebar = () => {
  const {
    CBAssetList: [{ uiData: utilityUiData }, , , { uiData }],
    assetDetailsReset,
    UserScope,
    callSnackbar,
    selectedValuesHandler,
    detailsBlock,
    loadSide,
    siteType,
    CBAssetListDispatch,
    newSBCbConfigDataHandler,
  } = useContext(DesignAssetContext);
  const [mouseOverData, CBMouseHandler] = useCBMouserOver();

  const utilityCBSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      // const assetBlock = "cbAssetDetailsBlock";
      const assetBlock = "newSBCbAssetDetailsBlock";
      const selectedAssetValue = "selectedAssetCB";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_CB_SELECT,
        payload: {
          index: 0,
        },
      });
      selectedValuesHandler(0, selectedAssetValue, assetBlock);
      // cbConfigDataHandler(4);
      newSBCbConfigDataHandler(0);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  return (
    <MyErrorBoundary>
      <svg>
        <g id="linebar">
          <g id="lines">
            {loadSide === "LOAD_SIDE" && (
              <line
                x1="308"
                y1={siteType === "GRID_CONNECTED" ? "340" : "360"}
                x2="308"
                y2={siteType === "GRID_CONNECTED" ? "285" : "300"}
                stroke="#333333"
                strokeWidth="3"
              />
            )}
            <line
              x1={
                loadSide === "LOAD_SIDE"
                  ? "308"
                  : uiData.existingPmRequired
                  ? "235"
                  : "235"
              }
              y1={
                loadSide === "LOAD_SIDE"
                  ? siteType === "GRID_CONNECTED"
                    ? "339"
                    : "359"
                  : "212"
              }
              x2="635"
              y2={
                loadSide === "LOAD_SIDE"
                  ? siteType === "GRID_CONNECTED"
                    ? "339"
                    : "359"
                  : "212"
              }
              stroke="#333333"
              strokeWidth="3"

              // x1="250"
              // y1="210"
              // x2="696"
              // y2="210"
            />
            <line
              x1="636"
              y1={loadSide === "LOAD_SIDE" ? "390" : "210.5"}
              // y1="208.5"
              x2="635"
              y2={
                loadSide === "LOAD_SIDE"
                  ? siteType === "GRID_CONNECTED"
                    ? "338"
                    : "358"
                  : "375"
              }
              stroke="#333333"
              strokeWidth="3"
            />

            <g id="CB3">
              <svg
                width="75px"
                height="100px"
                viewBox="0 0 10 149"
                x={"601"}
                y={
                  loadSide === "LOAD_SIDE"
                    ? siteType === "GRID_CONNECTED"
                      ? "370"
                      : "390"
                    : "370"
                }
                onClick={utilityCBSelectHandler}
                className="pointer"
              >
                <g id="Circuit Breaker" fill="none" fillRule="evenodd">
                  <g
                    id="Circuit Breaker 1"
                    transform="translate(-651.000000, -512.000000)"
                    onMouseOver={CBMouseHandler}
                    onMouseOut={CBMouseHandler}
                  >
                    <g
                      id="Circuit Breaker - closed"
                      transform="translate(604.000000, 514.000000)"
                      fill={
                        utilityUiData.CBSelected
                          ? "rgba(65, 181, 230, 0.35)"
                          : mouseOverData
                          ? "rgba(65, 181, 230, 0.35)"
                          : "none"
                      }
                      stroke={utilityUiData.CBSelected ? "#42B4E6" : "none"}
                    >
                      <g id="Group-15" strokeWidth="4" fillRule="evenodd">
                        <rect
                          id="Rectangle-Copy-20"
                          x="0"
                          y="12"
                          width="96"
                          height="122"
                        ></rect>
                        <g
                          id="Group-2"
                          transform="translate(48.000000, 0.000000)"
                          stroke={
                            utilityUiData.CBConfigured ? "#333333" : "#E47F00"
                          }
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="4"
                        >
                          <line
                            x1="0.5"
                            y1="0"
                            x2="0.5"
                            y2="47.8298611"
                            id="Line-2"
                          ></line>
                        </g>
                      </g>
                      <line
                        x1="48.5"
                        y1="97.1701389"
                        x2="48.501111"
                        y2="145"
                        id="Line-2-Copy-15"
                        stroke={
                          utilityUiData.CBConfigured ? "#333333" : "#E47F00"
                        }
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></line>
                      <path
                        d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                        id="Path-11"
                        stroke={
                          utilityUiData.CBConfigured ? "#333333" : "#E47F00"
                        }
                        strokeWidth="4"
                        strokeLinecap="round"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </g>
          </g>
        </g>
      </svg>
    </MyErrorBoundary>
  );
};

export default Linebar;
