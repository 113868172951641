import React, { useEffect, useState } from "react";
import embLabels from "../../config/emb-labels-en.json";

const AddEquipment = (props) => {
  const {
    siteType,
    clientType,
    architectureType,
    onAddGensetVerify,
    onAddBattery,
    onAddPv,
    onAddCHPVerify,
    onAddLoad,
    onAddEv,
    onAddTransferSwitch,
    gridSettingMode,
    count,
    PVDetails,
    availableDer,
    useCases,
    projectStatus,
    siteCreated,
    handleSetLoadSide,
    loadSide,
    switchboardType,
    handleSwitchboardTypeChangeGSX,
    onAddPvExisiting,
    onAddBatteryExisting,
    allowSwitchBoard,
    CBAssetList: [, , { uiData: bessUiData }, { uiData: pv1Uidata }],
  } = props;
  const [photoVoltaicCount, setPhotoVoltaicCount] = useState("");
  const [openDisclamerPopUp, setOpenDisclamerPopUp] = useState({
    open: false,
    nonDer: "",
  });
  const projectTypeValue = JSON.parse(localStorage.getItem("projectType"));

  const checkDerDisabled = () => {
    const isDisabled =
      clientType === embLabels.GENERIC_PROJECT
        ? siteType === embLabels.PV_HYBRIDIZATION
          ? true
          : false
        : clientType === embLabels.GREENSTRUXURE_PROJECT
        ? siteType === embLabels.ISLANDABLE_GSX
          ? architectureType === embLabels.PV_GENSET_ATS
            ? true
            : false
          : siteType === embLabels.GRID_CONNECTED_GSX
          ? architectureType === embLabels.PV_BESS_NR
            ? true
            : false
          : false
        : clientType === embLabels.A2E_PROJECT
        ? siteType === embLabels.GENSET_PVBESS_A2E
          ? true
          : false
        : false;
    return isDisabled;
  };

  useEffect(() => {
    const pvCount =
      PVDetails?.pvset1Data?.length +
      PVDetails?.pvset2Data?.length +
      PVDetails?.pvset3Data?.length;

    if (useCases?.includes("PV_inverter")) {
      if (siteType === embLabels.PV_HYBRIDIZATION) {
        setPhotoVoltaicCount(`(${pvCount}/25)`);
      } else if (
        siteType === embLabels.GRID_CONNECTED ||
        (siteType === embLabels.ISLANDABLE_GSX &&
          architectureType === embLabels.PV_GENSET_ATS)
      ) {
        setPhotoVoltaicCount(`(${pvCount}/15)`);
      } else if (
        siteType === embLabels.GRID_CONNECTED_GSX &&
        architectureType === embLabels.PV_BESS_NR
      ) {
        setPhotoVoltaicCount(`(${pvCount}/15)`);
      } else {
        setPhotoVoltaicCount(`(${pvCount}/25)`);
      }
    } else {
      if (
        clientType === embLabels.A2E_PROJECT &&
        siteType === embLabels.GENSET_PVBESS_A2E
      ) {
        setPhotoVoltaicCount(`(1/1)`);
      } else {
        setPhotoVoltaicCount("");
      }
    }
  }, [PVDetails, useCases]);
  // useEffect(() => {
  //   setSwitchBoardTextChange(switchboardType);
  // }, [switchboardType]);

  const handleOpenDisclamerPopUp = (nonDer) => {
    setOpenDisclamerPopUp({
      open: true,
      nonDer: nonDer,
    });
  };

  const handleCloseDisclamerPopUp = () => {
    setOpenDisclamerPopUp({
      open: false,
      nonDer: "",
    });
  };

  const handleAddNonContoleDers = () => {
    if (openDisclamerPopUp.nonDer === "EXISTING_BESS") {
      onAddBatteryExisting();
    }
    if (openDisclamerPopUp.nonDer === "EXISTING_PV") {
      onAddPvExisiting();
    }

    handleCloseDisclamerPopUp();
  };
  return (
    <>
      <div
        className={`col-sm-2 col-md-2 col-lg-2 col-xl-2 se-white-background ${
          siteCreated || projectStatus === "COMPLETED"
            ? "se-block-disabled"
            : ""
        } `}
      >
        <div className="row border se-white-background se-block-header">
          {
            // siteType: 'GRID_CONNECTED_GSX', clientType: 'GREENSTRUXURE', switchboardType: 'NEW_SWITCHBOARD', architectureType: 'PV_BESS_NR
            // siteType: 'ISLANDABLE_GSX', clientType: 'GREENSTRUXURE', switchboardType: 'NEW_SWITCHBOARD', architectureType: 'PV_GENSET_ATS'
            // siteType: 'GRID_CONNECTED', clientType: 'GENERIC', switchboardType: 'NEW_SWITCHBOARD', architectureType: null

            ((siteType === embLabels.GRID_CONNECTED_GSX &&
              clientType === embLabels.GREENSTRUXURE_PROJECT) ||
              (siteType === embLabels.ISLANDABLE_GSX &&
                clientType === embLabels.GREENSTRUXURE_PROJECT) ||
              (siteType === embLabels.GRID_CONNECTED &&
                clientType === embLabels.GENERIC_PROJECT) ||
              (siteType === embLabels.ISLANDABLE &&
                clientType === embLabels.GENERIC_PROJECT &&
                projectTypeValue?.projectTypeValue === "MGaaS ANSI")) && (
              <>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0">
                  <span className="se-life-green bold">SWITCH BOARD</span>
                </div>

                <div
                  className={`col-md-12 border se-small-card pl-1 custom-icon-label ${
                    allowSwitchBoard ? "der-disabled" : ""
                  }`}
                  onClick={() => {
                    allowSwitchBoard ||
                      handleSwitchboardTypeChangeGSX(
                        switchboardType === "NEW_SWITCHBOARD"
                          ? "EXISTING_SWITCHBOARD"
                          : "NEW_SWITCHBOARD"
                      );
                  }}
                >
                  <span>
                    <se-icon
                      color="primary"
                      className="se-icon icon_button se-life-green"
                      size="small"
                    >
                      electricity_switchboard_lv
                    </se-icon>
                  </span>
                  <span className="ml-2 se-custom-font13 bold align-top ">
                    {switchboardType === "NEW_SWITCHBOARD"
                      ? "Add Existing SwitchBoard"
                      : "Remove Existing SwitchBoard"}{" "}
                  </span>
                </div>
                <div className=" col-md-12 dropdown-divider"></div>
              </>
            )
          }
          <div>
            <span className="se-life-green bold">ADD EQUIPMENT</span>
          </div>
          <div className=" col-md-12 dropdown-divider"></div>
          <div className="col-md-12 px-0 mb-2">
            <span className="inline se-font-14px ">DERs</span>
            <hr className="se-text-hr-75" />
          </div>

          {(useCases?.includes("BESS") ||
            (clientType === embLabels.A2E_PROJECT &&
              siteType === embLabels.GENSET_PVBESS_A2E)) && (
            <div
              className={`col-md-12 border se-small-card pl-1 custom-icon-label ${
                checkDerDisabled()
                  ? "der-disabled"
                  : siteType === embLabels.GRID_CONNECTED
                  ? `${bessUiData.status ? "der-disabled" : ""}`
                  : useCases?.includes("BESS")
                  ? "der-disabled"
                  : ""
              }`}
              onClick={checkDerDisabled() ? undefined : onAddBattery}
            >
              <se-icon
                color="primary"
                className="se-icon icon_button se-life-green"
              >
                battery2_half
              </se-icon>
              <span className="ml-2 se-font-14px-bold align-top ">
                Battery{" "}
                {useCases?.includes("BESS") ||
                (clientType === embLabels.A2E_PROJECT &&
                  siteType === embLabels.GENSET_PVBESS_A2E)
                  ? siteType === embLabels.GRID_CONNECTED
                    ? `${bessUiData.status ? "(1/1)" : "(0/1)"}`
                    : "(1/1)"
                  : "(0/0)"}{" "}
              </span>
            </div>
          )}
          {photoVoltaicCount && (
            <div
              className={`col-md-12 border se-small-card pl-1 custom-icon-label ${
                (clientType === embLabels.A2E_PROJECT &&
                  siteType === embLabels.GENSET_PVBESS_A2E) ||
                (PVDetails?.pvset1Data?.length +
                  PVDetails?.pvset2Data?.length +
                  PVDetails?.pvset3Data?.length ===
                15
                  ? true
                  : PVDetails?.pvset1Data?.length +
                      PVDetails?.pvset2Data?.length +
                      PVDetails?.pvset3Data?.length ===
                    25
                  ? true
                  : false)
                  ? "der-disabled"
                  : ""
              } `}
              onClick={
                siteType === embLabels.GENSET_PVBESS_A2E ? undefined : onAddPv
              }
            >
              <se-icon
                color="primary"
                className="se-icon icon_button  se-life-green"
              >
                solar_panel2
              </se-icon>
              <span className="ml-2 se-font-14px-bold align-top ">
                PhotoVoltaic {photoVoltaicCount ? photoVoltaicCount : "(0/0)"}
              </span>
            </div>
          )}

          {useCases?.includes("Generator") && (
            <div
              className={`col-md-12 border se-small-card pl-1 custom-icon-label ${
                checkDerDisabled()
                  ? "der-disabled"
                  : siteType === embLabels.GRID_CONNECTED
                  ? "der-disabled"
                  : useCases?.includes("Generator")
                  ? "der-disabled"
                  : ""
              }`}
              onClick={checkDerDisabled() ? undefined : onAddGensetVerify}
            >
              <se-icon
                color="primary"
                className="se-icon icon_button se-life-green"
              >
                genset_01
              </se-icon>
              <span className="ml-2 se-font-14px-bold align-top ">
                Genset{" "}
                {siteType === embLabels.GRID_CONNECTED
                  ? "(0/0)"
                  : useCases?.includes("Generator")
                  ? "(1/1)"
                  : "(0/0)"}
              </span>
            </div>
          )}

          {["none"].includes(siteType) && (
            <div
              className={`col-md-12 border se-small-card pl-1 der-disabled d-none custom-icon-label`}
              onClick={["none"].includes(siteType) ? onAddCHPVerify : undefined}
            >
              <se-icon
                color="primary"
                className="se-icon icon_button se-life-green"
              >
                system_heat
              </se-icon>
              <span className="ml-2 se-font-14px-bold align-top ">CHP</span>
            </div>
          )}

          {/* {(clientType === embLabels.A2E_PROJECT &&
            siteType === embLabels.GENSET_PVBESS_A2E) || (
              <div className="col-md-12 border se-small-card pl-1 der-disabled custom-icon-label">
                <se-icon
                  color="primary"
                  className="se-icon icon_button  se-life-green"
                >
                  calculation_launch_powerbalance
                </se-icon>
                <span className="ml-2 se-font-14px-bold">Hybrid</span>
              </div>
            )} */}

          <div className="col-md-12 px-0 mb-2">
            <span className="inline se-font-14px align-top ">Loads</span>
            <hr className="se-text-hr-75" />
          </div>

          {checkDerDisabled() ||
          // (siteType === embLabels.GRID_CONNECTED &&
          //   clientType === embLabels.GENERIC_PROJECT) ||
          (siteType === embLabels.ISLANDABLE &&
            projectTypeValue?.projectTypeValue !== "MGaaS ANSI")
            ? ""
            : (clientType === embLabels.A2E_PROJECT &&
                siteType === embLabels.GENSET_PVBESS_A2E) || (
                <div
                  className={`col-md-12 border se-small-card pl-1 custom-icon-label ${
                    checkDerDisabled()
                      ? "der-disabled"
                      : count.evCount === 1
                      ? "der-disabled"
                      : ""
                  }`}
                  onClick={checkDerDisabled() ? undefined : onAddEv}
                >
                  <se-icon
                    color="primary"
                    className="se-icon icon_button se-life-green"
                  >
                    electric_vehicle_terminal
                  </se-icon>
                  <span className="ml-2 se-font-14px-bold align-top ">
                    EV Cluster{" "}
                    {checkDerDisabled() ? "(0/0)" : `(${count.evCount}/1)`}{" "}
                  </span>
                </div>
              )}

          {(clientType === embLabels.GREENSTRUXURE_PROJECT &&
            siteType === embLabels.ISLANDABLE_GSX &&
            architectureType === embLabels.PV_GENSET_ATS &&
            gridSettingMode === "FUEL_SAVER") ||
          (siteType === embLabels.ISLANDABLE &&
            projectTypeValue?.projectTypeValue === "MGaaS ANSI") ? (
            <div
              className={`col-md-12 border se-small-card pl-1 custom-icon-label ${
                count.loadCount === 10
                  ? "der-disabled"
                  : count.loadCount === 22
                  ? "der-disabled"
                  : ""
              }`}
              onClick={onAddLoad}
            >
              <se-icon
                color="primary"
                className="se-icon icon_button se-life-green"
              >
                segment_building_small
              </se-icon>
              <span className="ml-2 se-font-14px-bold align-top ">
                Loads
                {`(${count.loadCount}/10)`}
              </span>
            </div>
          ) : (
            [
              embLabels.GENSET_PVBESS_A2E,
              embLabels.PV_HYBRIDIZATION,
              embLabels.GRID_CONNECTED_GSX,
              embLabels.GRID_CONNECTED,
              embLabels.ISLANDABLE,
              siteType === embLabels.ISLANDABLE_GSX,
            ].includes(siteType) &&
            projectTypeValue?.projectTypeValue !== "MGaaS ANSI" && (
              <div
                className={`col-md-12 border se-small-card pl-1 custom-icon-label ${
                  checkDerDisabled() ? "der-disabled" : ""
                } ${
                  count.loadCount === 10 &&
                  projectTypeValue?.projectTypeValue !== "MGaaS IEC"
                    ? "der-disabled"
                    : count.loadCount === 22
                    ? "der-disabled"
                    : [
                        embLabels.GENSET_PVBESS_A2E,
                        embLabels.PV_HYBRIDIZATION,
                        embLabels.GRID_CONNECTED_GSX,
                        embLabels.GRID_CONNECTED,
                      ].includes(siteType) ||
                      (clientType === embLabels.GREENSTRUXURE_PROJECT &&
                        siteType === embLabels.ISLANDABLE_GSX &&
                        architectureType === embLabels.PV_GENSET_ATS &&
                        gridSettingMode === "GENSET_MAIN_NO_BESS")
                    ? "der-disabled"
                    : ""
                }`}
                onClick={checkDerDisabled() ? undefined : onAddLoad}
              >
                <se-icon
                  color="primary"
                  className="se-icon icon_button se-life-green"
                >
                  segment_building_small
                </se-icon>
                <span className="ml-2 se-font-14px-bold align-top ">
                  Loads{" "}
                  {[
                    embLabels.GENSET_PVBESS_A2E,
                    embLabels.PV_HYBRIDIZATION,
                    embLabels.GRID_CONNECTED_GSX,
                    embLabels.GRID_CONNECTED,
                  ].includes(siteType) ||
                  (clientType === embLabels.GREENSTRUXURE_PROJECT &&
                    siteType === embLabels.ISLANDABLE_GSX &&
                    architectureType === embLabels.PV_GENSET_ATS &&
                    gridSettingMode === "GENSET_MAIN_NO_BESS")
                    ? `(${count.loadCount}/1)`
                    : clientType === embLabels.GREENSTRUXURE_PROJECT &&
                      siteType === embLabels.ISLANDABLE_GSX &&
                      architectureType === embLabels.PV_GENSET_ATS &&
                      gridSettingMode === "FUEL_SAVER"
                    ? `(${count.loadCount}/10)`
                    : siteType === embLabels.ISLANDABLE
                    ? `(${count.loadCount}/22)`
                    : "0/0"}
                </span>
              </div>
            )
          )}

          {((siteType === embLabels.GRID_CONNECTED_GSX &&
            clientType === embLabels.GREENSTRUXURE_PROJECT) ||
            (siteType === embLabels.ISLANDABLE_GSX &&
              clientType === embLabels.GREENSTRUXURE_PROJECT) ||
            (siteType === embLabels.GRID_CONNECTED &&
              clientType === embLabels.GENERIC_PROJECT) ||
            (siteType === embLabels.ISLANDABLE &&
              clientType === embLabels.GENERIC_PROJECT &&
              projectTypeValue?.projectTypeValue === "MGaaS ANSI")) && (
            <>
              <div className=" col-md-12 dropdown-divider"></div>
              <div className="">
                <span className="inline se-life-green bold">CONNECTIONS</span>
                {/* <hr className="se-text-hr-75" /> */}
              </div>
              <div className=" col-md-12 dropdown-divider"></div>
              <div
                className={`col-md-12  border se-small-card pl-1 custom-icon-label ${
                  switchboardType === embLabels.EXISTING_SWITCHBOARD
                    ? ""
                    : "der-disabled"
                }`}
              >
                <span className="py-1">
                  <se-icon
                    color="primary"
                    className="se-icon icon_button se-life-green py-1"
                  >
                    connection_ethernet
                  </se-icon>
                </span>
                <select
                  id="connection_side"
                  className={` ${
                    switchboardType === embLabels.EXISTING_SWITCHBOARD
                      ? ""
                      : "der-disabled"
                  } form-control  se-font-14px-bold form-control-sm border-0`}
                  onChange={handleSetLoadSide}
                  value={loadSide}
                  disabled={
                    switchboardType === embLabels.EXISTING_SWITCHBOARD
                      ? false
                      : true
                  }
                  style={{
                    fontSize: "14px !important",
                    fontWeight: "400 !important",
                  }}
                >
                  <option value={"LINE_SIDE"}>Line Side</option>
                  <option value={"LOAD_SIDE"}>Load Side</option>
                </select>
              </div>

              {false && (
                <>
                  <div className=" col-md-12 dropdown-divider"></div>
                  <div className="">
                    <span className="inline se-life-green bold">
                      NON-CONTROLLABLE DERs
                    </span>
                  </div>
                  <div className=" col-md-12 dropdown-divider"></div>

                  <div
                    className={`col-md-12 border se-small-card pl-1 custom-icon-label ${
                      bessUiData.existingPmRequired ||
                      switchboardType === "NEW_SWITCHBOARD"
                        ? "der-disabled"
                        : ""
                    }`}
                    // onClick={switchboardType==="NEW_SWITCHBOARD"||onAddBatteryExisting}
                    onClick={() => {
                      bessUiData.existingPmRequired ||
                        switchboardType === "NEW_SWITCHBOARD" ||
                        handleOpenDisclamerPopUp("EXISTING_BESS");
                    }}
                  >
                    <se-icon
                      color="primary"
                      className="se-icon icon_button se-life-green"
                    >
                      battery2_half
                    </se-icon>
                    <span className="ml-2 se-font-14px-bold align-top ">
                      Battery{" "}
                    </span>
                  </div>

                  <div
                    className={`col-md-12 border se-small-card pl-1 custom-icon-labe ${
                      pv1Uidata.existingPmRequired ||
                      switchboardType === "NEW_SWITCHBOARD"
                        ? "der-disabled"
                        : ""
                    }`}
                    onClick={() => {
                      pv1Uidata.existingPmRequired ||
                        switchboardType === "NEW_SWITCHBOARD" ||
                        handleOpenDisclamerPopUp("EXISTING_PV");
                    }}
                  >
                    <se-icon
                      color="primary"
                      className="se-icon icon_button  se-life-green"
                    >
                      solar_panel2
                    </se-icon>
                    <span className="ml-2 se-font-14px-bold align-top ">
                      PhotoVoltaic
                    </span>
                  </div>

                  <se-dialog
                    id="AlertDialog"
                    open={openDisclamerPopUp.open}
                    can-backdrop="false"
                  >
                    <se-dialog-header>Disclamer</se-dialog-header>
                    <se-dialog-content color="primary">
                      <div className="px-3">
                        <p
                          className="font-weight-bold"
                          style={{ fontSize: "12px" }}
                        >
                          You are selecting a non controllable DER which is not
                          the responsibility of EcoStruxure Microgrid Advisor.
                        </p>
                        <ul
                          style={{ listStyleType: "unset", fontSize: "12px" }}
                        >
                          <li className="font-weight-bold ml-5 ">
                            When grid tied, EcoStruxure Microgrid Advisor cannot
                            guarantee the performance of regulation functions
                            (i.e. export management) at Point of Common
                            Coupling.
                          </li>
                        </ul>
                      </div>
                    </se-dialog-content>
                    <se-dialog-footer>
                      <se-button onClick={handleCloseDisclamerPopUp}>
                        Cancel
                      </se-button>

                      <se-button
                        color="primary"
                        onClick={handleAddNonContoleDers}
                      >
                        Ok
                      </se-button>
                    </se-dialog-footer>
                  </se-dialog>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AddEquipment;
