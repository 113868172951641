const userScope = () => {
  const userSampleData = {
    name: "",
    role: "",
    access: {},
  };

  const userScope =
    JSON.parse(localStorage.getItem("scope")) !== null
      ? JSON.parse(localStorage.getItem("scope"))
      : userSampleData;

  return [userScope];
};

export default userScope;
