import React, { useContext, useEffect, useState } from "react";

import isTouchDevice from "../SE-Component/Tooltip";
import { InformationPageContext } from "../CustomHooks/ContextApi";
import embLabels from "../../config/emb-labels-en.json";
import Tooltip from "../../utils/Tooltip";
const SelectUseCaseGSX = () => {
  const [organizationValue, setOrganizationValue] = useState("");
  const {
    siteType,
    informationToolTIP,
    gridSettings,
    autogridGSX,
    handleOnGridFeatureChangeGSX,
    handleAutoGridGsxChange,
    optimizationEngine,
    handleOptimizationChange,
    handleOnGridFeatureChange,
    architectureType,
    organization,
    handleOrganizationChange,
    selectedOrganization,
  } = useContext(InformationPageContext);
  
  const isOnGridFeaturesEnabled = () => {
    const status =
      optimizationEngine === "EMA" &&
      gridSettings.remoteMonitoringControl &&
      siteType !== embLabels.PV_HYBRIDIZATION
        ? "enabled"
        : "disabled";

    return status === "enabled" ? true : false;
  };

  const projectType = JSON.parse(
    localStorage.getItem("projectType")
  )?.projectTypeValue;

  const handleOrganizationValueChange = (event) => {
    const {
      target: { value },
    } = event;
    setOrganizationValue(value);
    handleOrganizationChange(event);
  };
  useEffect(() => {
    if (selectedOrganization) {
      setOrganizationValue(selectedOrganization?.organizationKey);
    }
  }, [selectedOrganization]);


  return (
    <div className="row mt-2">
      <div className="col-md-12 mt-1">
        <span className="labelCircle mr-2 ">3</span>{" "}
        <span>Optimization Engine</span>
        <Tooltip
          position="right"
          iconColor="secondary"
          information="Select your Optimization Engine"
        />
      </div>
      <div className="col-md-12 mt-2">
        <div className="col-md-12 border-radius-5px ">
          <div className="row">
            <div className={"col-md-6 "}>
              <div className="row"></div>
              {gridSettings.remoteMonitoringControl && (
                <div className="row">
                  <div className="col-md-12">
                    {projectType !== "DaaS ANSI" && (
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              className="custom-control-input islandableRadio asCompatible"
                              name="optimizationEngine"
                              id="emaOptimization"
                              value="EMA"
                              checked={optimizationEngine === "EMA"}
                              onChange={handleOptimizationChange}
                              disabled={
                                siteType === embLabels.GRID_CONNECTED_GSX &&
                                architectureType === embLabels.PV_BESS_NR
                              }
                            />
                            <label
                              className="custom-control-label line-height-25"
                              htmlFor="emaOptimization"
                            >
                              Microgrid Advisor
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1 ">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input islandableRadio asCompatible"
                            name="optimizationEngine"
                            id="otherOptimization"
                            value="OTHER"
                            checked={optimizationEngine === "OTHER"}
                            onChange={handleOptimizationChange}
                          />
                          <label
                            className="custom-control-label line-height-25"
                            htmlFor="otherOptimization"
                          >
                            Other optimization
                          </label>
                        </div>
                      </div>
                      {optimizationEngine === "OTHER" && (
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ml-5 mt-1  mb-2 ">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input islandableRadio asCompatible"
                                id="gsxAutoGridValidation"
                                name="autogridGSX"
                                value={autogridGSX}
                                checked={autogridGSX}
                                // onClick={handleAutoGridGsxChange}
                                // onChange={handleAutoGridGsxChange}
                              />
                              <label
                                className="custom-control-label line-height-25"
                                htmlFor="autogridOptimization"
                              >
                                Autogrid Flex &trade;
                              </label>
                              <Tooltip
                                position="right"
                                iconColor="secondary"
                                iconSize="small"
                                //       information="To change the default Microgrid Advisor server
                                // to autogrid."
                                information={
                                  informationToolTIP?.autogridflexTooltipData
                                }
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ml-5 mt-1  mb-2 ">
                            <div className="form-group row ml-3">
                              <label
                                htmlFor="inputOrganization"
                                className="col-sm-2 col-form-label text-right"
                              >
                                Organization
                              </label>
                              <div className="col-sm-5">
                                <input
                                  type="password"
                                  name="organization"
                                  className="form-control form-control-md"
                                  autoComplete="new-password"
                                  value={organizationValue}
                                  onChange={handleOrganizationValueChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {/* Auto grid */}
              {/* <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input islandableRadio asCompatible"
                          id="gsxAutoGridValidation"
                          name="autogridGSX"
                          value={autogridGSX}
                          checked={autogridGSX}
                          onClick={handleAutoGridGsxChange}
                          onChange={handleAutoGridGsxChange}
                        />
                        <label
                          className="custom-control-label line-height-25"
                          htmlFor="gsxAutoGridValidation"
                        >
                          Autogrid
                        </label>

                        <se-tooltip
                          action={isTouchDevice() ? "click" : "hover"}
                          position="right"
                        >
                          <se-icon
                            option="raised"
                            color="secondary"
                            size="small"
                            slot="tooltip"
                          >
                            information_circle
                          </se-icon>
                          <se-tooltip-content>
                            To change the default Microgrid Advisor server to
                            autogrid
                          </se-tooltip-content>
                        </se-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {optimizationEngine === "EMA" && (
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-1">
                    <span>On Grid Optimization Features </span>
                  </div>
                </div>
              )}

              {isOnGridFeaturesEnabled() && (
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input asCompatible"
                        name="demandCharge"
                        id="customControlValidation1"
                        value={gridSettings.demandCharge}
                        checked={gridSettings.demandCharge}
                        onChange={handleOnGridFeatureChange}
                      />
                      <label
                        className="custom-control-label line-height-25"
                        htmlFor="customControlValidation1"
                      >
                        Demand Charge Optimization
                      </label>
                      <Tooltip
                        position="right"
                        iconColor="secondary"
                        iconSize="small"
                        information={informationToolTIP.demandChargeTooltipData}
                      />
                    </div>
                  </div>
                </div>
              )}

              {isOnGridFeaturesEnabled() && (
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input asCompatible"
                        name="tariffManagement"
                        id="customControlValidation2"
                        value={gridSettings.tariffManagement}
                        checked={gridSettings.tariffManagement}
                        onChange={handleOnGridFeatureChange}
                      />
                      <label
                        className="custom-control-label line-height-25"
                        htmlFor="customControlValidation2"
                      >
                        Time of Use Tariff optimization
                      </label>
                      <Tooltip
                        position="right"
                        iconColor="secondary"
                        iconSize="small"
                        information={
                          informationToolTIP.tariffManagementTooltipData
                        }
                      />
                    </div>
                  </div>
                </div>
              )}

              {isOnGridFeaturesEnabled() && (
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input asCompatible"
                        name="selfConsumption"
                        id="customControlValidation3"
                        value={gridSettings.selfConsumption}
                        checked={gridSettings.selfConsumption}
                        onChange={handleOnGridFeatureChange}
                      />
                      <label
                        className="custom-control-label line-height-25"
                        htmlFor="customControlValidation3"
                      >
                        Self Consumption
                      </label>
                      <Tooltip
                        position="right"
                        iconColor="secondary"
                        iconSize="small"
                        information={
                          informationToolTIP.selfConsumptionTooltipData
                        }
                      />
                    </div>
                  </div>
                </div>
              )}

              {isOnGridFeaturesEnabled() && (
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input asCompatible"
                        name="exportOptimization"
                        id="customControlValidation4"
                        value={gridSettings.exportOptimization}
                        checked={gridSettings.exportOptimization}
                        onChange={handleOnGridFeatureChange}
                      />
                      <label
                        className="custom-control-label line-height-25"
                        htmlFor="customControlValidation4"
                      >
                        Export predictive optimization
                      </label>
                      <Tooltip
                        position="right"
                        iconColor="secondary"
                        iconSize="small"
                        information={
                          informationToolTIP.exportOptimizationTooltipData
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              {optimizationEngine !== "EMA" && (
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-1">
                    <span>Real Time Control</span>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input asCompatible"
                      name="noExport"
                      id="customControlValidation51"
                      value={gridSettings.noExport}
                      checked={gridSettings.noExport}
                      onChange={handleOnGridFeatureChangeGSX}
                    />
                    <label
                      className="custom-control-label line-height-25"
                      htmlFor="customControlValidation51"
                    >
                      Real Time Export Control (EMO Controller based)
                    </label>
                    <Tooltip
                      position="right"
                      iconColor="secondary"
                      iconSize="small"
                      information={
                        informationToolTIP.otherOptimizationTooltipData
                      }

                    />
                  </div>
                </div>
              </div>
            {/* {false && !["MGaaS ANSI", "DaaS ANSI"].includes(projectType)&&  <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input asCompatible"
                      name="minSocMgmt"
                      id="customControlValidation52"
                      value={gridSettings?.minSocMgmt}
                      checked={gridSettings?.minSocMgmt}
                      onChange={handleOnGridFeatureChangeGSX}
                    />
                    <label
                      className="custom-control-label line-height-25"
                      htmlFor="customControlValidation52"
                    >
                      Min SOC Mgmt 
                    </label>
                    <Tooltip
                      position="right"
                      iconColor="secondary"
                      iconSize="small"
                      information=""
                    />
                  </div>
                </div>
              </div>} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectUseCaseGSX;