import React from 'react'

const SecureCommunicationTable = ({data}) => {
  
  return (
   <tr className='text-center'>
    <td width="10%">{data.pvid}</td>
    <td width="10%">{data.vlan}</td>
    <td width="15%">{data.networkAddress}</td>
    <td width="12%">{data.netmask}</td>
    <td width="10%">{data.cidr}</td>
    <td width="20%">{data.hostRange}</td>
    <td width="10%">{data.hostAddress}</td>
    
   </tr>
  )
}

export default SecureCommunicationTable