import React, { memo } from "react";

const Alert = ({ alert, onClose }) => (
  <se-dialog
    id="AlertDialog"
    open={alert.alertPopUpStatus}
    can-backdrop={false}
    size="small"
  >
    <se-dialog-header>{alert.title}</se-dialog-header>

    <se-dialog-content className="se-header-font-size-16px">
      {alert.message}
    </se-dialog-content>

    <se-dialog-footer>
      <se-button onClick={onClose}>OK</se-button>
    </se-dialog-footer>
  </se-dialog>
);

export default memo(Alert);
