import React, { useState } from "react";

const News = ({
  UserScope,
  newsList,
  loading,
  newAddNews,
  updateNews,
  deleteNews,
}) => {
  const [newsDetails, setNewsDetails] = useState({
    newsSubject: "",
    newsBody: "",
    newsLink: "",
    newsId: "",
    newNewsSubject: "",
    newNewsBody: "",
    newNewsLink: "",
    newsDeleteId: "",
    deleteNewsConfirmPopUpStatus: false,
  });

  const addNewsDisplay = () =>
    setNewsDetails(prevState => ({ ...prevState, newsId: "0" }));

  const handleChangeNews = e => {
    const { name, value } = e.target;
    setNewsDetails(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const newCancelNews = () =>
    setNewsDetails(prevState => ({
      ...prevState,
      newsId: "",
      newNewsSubject: "",
      newNewsBody: "",
      newNewsLink: "",
    }));

  const resetEditNews = () =>
    setNewsDetails(prevState => ({
      ...prevState,
      newsId: "",
      newsSubject: "",
      newsBody: "",
      newsLink: "",
    }));

  const addNewNews = () => {
    newAddNews(newsDetails);
    newCancelNews();
  };

  const editNews = newsData => {
    setNewsDetails(prevState => ({
      ...prevState,
      newsSubject: newsData.subject,
      newsBody: newsData.body,
      newsLink: newsData.link,
      newsId: newsData.id,
    }));
  };

  const newsUpdate = () => {
    updateNews(newsDetails);
    resetEditNews();
  };

  const deleteNewsPopUp = newsId => {
    setNewsDetails(prevState => ({
      ...prevState,
      newsDeleteId: newsId,
      deleteNewsConfirmPopUpStatus: true,
    }));
  };

  const closeDeleteNewsPopUp = () => {
    setNewsDetails(prevState => ({
      ...prevState,
      newsDeleteId: "",
      deleteNewsConfirmPopUpStatus: false,
    }));
  };

  const removeNewsConfirm = () => {
    if (newsDetails.newsDeleteId) {
      deleteNews(newsDetails.newsDeleteId);
      closeDeleteNewsPopUp();
    } else {
      closeDeleteNewsPopUp();
    }
  };

  return (
   
     <div className="col-md-12 mt-2 pb-2">
      <div className="row">
        <div className="col-md-12 mt-2">
          <se-divider></se-divider>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-10 mt-2 mr-3">
          <span className="se-life-green se-font-14px-bold ">News</span>
        </div>
        <div className="col-md-1 ml-1">
          {UserScope.access.saveNews && newsDetails.newsId === "" && (
            <se-button
              option="raised"
              size="small"
              color="primary"
              icon="action_add_flat"
              onClick={addNewsDisplay}
              style={{}}
            >
              Add News
            </se-button>
          )}
        </div>
      </div>
      {newsDetails.newsId === "0" && (
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label
                htmlFor="inputEmail3"
                className="col-sm-3 col-form-label text-right"
              >
                Subject<span className="se-mandatory">*</span>
              </label>
              <div className="col-sm-12">
                <input
                  type="text"
                  autoComplete="none"
                  className="form-control form-control-md"
                  name="newNewsSubject"
                  placeholder="Subject"
                  value={newsDetails.newNewsSubject}
                  maxlength="50"
                  onChange={handleChangeNews}
                />
                <div
                  className="invalid-feedback-custom"
                  style={!newsDetails.newNewsSubject ? {} : { display: "none" }}
                >
                  Subject is required.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label
                htmlFor="inputEmail3"
                className="col-sm-3 col-form-label text-right"
              >
                Body<span className="se-mandatory">*</span>
              </label>
              <div className="col-sm-12">
                <input
                  type="text"
                  autoComplete="none"
                  className="form-control form-control-md"
                  name="newNewsBody"
                  placeholder="Body"
                  value={newsDetails.newNewsBody}
                  maxlength="100"
                  onChange={handleChangeNews}
                />
                <div
                  className="invalid-feedback-custom"
                  style={!newsDetails.newNewsBody ? {} : { display: "none" }}
                >
                  Body is required.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label
                htmlFor="inputEmail3"
                className="col-sm-3 col-form-label text-right"
              >
                Link
              </label>
              <div className="col-sm-12">
                <input
                  type="text"
                  autoComplete="none"
                  className="form-control form-control-md"
                  name="newNewsLink"
                  placeholder="Link"
                  value={newsDetails.newNewsLink}
                  maxlength="50"
                  onChange={handleChangeNews}
                />
              </div>
            </div>
          </div>
          <div className="col-md-2 my-auto">
            <span className="pointer">
              <se-icon
                size="medium"
                color="primary"
                title="Click to Save"
                disabled={
                  newsDetails.newNewsSubject.trim() === "" ||
                  newsDetails.newNewsBody.trim() === ""
                    ? true
                    : false
                }
                onClick={
                  newsDetails.newNewsSubject.trim() === "" ||
                  newsDetails.newNewsBody.trim() === ""
                    ? undefined
                    : addNewNews
                }
              >
                notification_ok_stroke
              </se-icon>
            </span>
            <span>&nbsp;</span>
            <span className="pointer">
              {" "}
              <se-icon
                size="medium"
                title="Click to cancel"
                onClick={newCancelNews}
              >
                action_delete_cross
              </se-icon>
            </span>
          </div>
        </div>
      )}

      <div className="row mt-1 pb-2">
        {newsList.length !== 0 && (
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-bordered mb-2">
                <thead>
                  <tr>
                    <th width="20%">Subject</th>
                    <th width="30%">Body</th>
                    <th width="10%">Link</th>
                    <th width="10%" className="text-center">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {newsList.map(newsData => (
                    <tr key={newsData.id}>
                      <td width="20%">
                        {newsData.id !== newsDetails.newsId ? (
                          <span>{newsData.subject}</span>
                        ) : (
                          <div className="form-group">
                            <input
                              type="text"
                              autoComplete="none"
                              className={"form-control form-control-sm "}
                              id="exampleFormControlInput1"
                              name="newsSubject"
                              maxLength="50"
                              value={newsDetails.newsSubject}
                              placeholder="Subject"
                              onChange={handleChangeNews}
                            />
                            <div
                              className="invalid-feedback-custom"
                              style={
                                !newsDetails.newsSubject
                                  ? {}
                                  : { display: "none" }
                              }
                            >
                              Subject is required.
                            </div>
                          </div>
                        )}
                      </td>
                      <td width="30%">
                        {newsData.id !== newsDetails.newsId ? (
                          <span>{newsData.body}</span>
                        ) : (
                          <div className="form-group">
                            <input
                              type="text"
                              autoComplete="none"
                              className={"form-control form-control-sm "}
                              id="exampleFormControlInput1"
                              name="newsBody"
                              maxLength="100"
                              value={newsDetails.newsBody}
                              placeholder="Body"
                              onChange={handleChangeNews}
                            />
                            <div
                              className="invalid-feedback-custom"
                              style={
                                !newsDetails.newsBody ? {} : { display: "none" }
                              }
                            >
                              Body is required.
                            </div>
                          </div>
                        )}
                      </td>
                      <td width="10%">
                        {newsData.id !== newsDetails.newsId ? (
                          <span>{newsData.link}</span>
                        ) : (
                          <div className="form-group">
                            <input
                              type="text"
                              autoComplete="none"
                              className={"form-control form-control-sm "}
                              id="exampleFormControlInput1"
                              name="newsLink"
                              maxLength="50"
                              value={newsDetails.newsLink}
                              placeholder="Link"
                              onChange={handleChangeNews}
                            />
                          </div>
                        )}
                      </td>
                      {newsData.id !== newsDetails.newsId ? (
                        <td width="10%" className="align-middle text-center">
                          {UserScope.access.saveNews === true ? (
                            <span className="pointer">
                              <se-icon
                                size="small"
                                title="Edit"
                                color="primary"
                                onClick={() => editNews(newsData)}
                              >
                                action_editor
                              </se-icon>
                            </span>
                          ) : (
                            ""
                          )}
                          <span>&nbsp; &nbsp;</span>
                          {UserScope.access.saveNews === true ? (
                            <span className="pointer">
                              <se-icon
                                size="small"
                                title="Delete"
                                color="primary"
                                onClick={() => deleteNewsPopUp(newsData.id)}
                              >
                                action_delete
                              </se-icon>
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                      ) : (
                        <td width="10%" className="align-middle text-center">
                          <span className="pointer">
                            <se-icon
                              size="medium"
                              color="primary"
                              title="Click to update"
                              disabled={
                                newsDetails.newsSubject.trim() === "" ||
                                newsDetails.newsBody.trim() === ""
                                  ? true
                                  : false
                              }
                              onClick={
                                newsDetails.newsSubject.trim() === "" ||
                                newsDetails.newsBody.trim() === ""
                                  ? undefined
                                  : newsUpdate
                              }
                            >
                              notification_ok_stroke
                            </se-icon>
                          </span>
                          <span>&nbsp;</span>
                          <span className="pointer">
                            {" "}
                            <se-icon
                              size="medium"
                              title="Click to cancel"
                              onClick={resetEditNews}
                            >
                              action_delete_cross
                            </se-icon>
                          </span>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {newsList.length === 0 && loading === false && (
          <div className="col-md-12 text-center">No News</div>
        )}
      </div>
      <se-dialog
        id="dialog-complex-delete-confirm"
        open={newsDetails.deleteNewsConfirmPopUpStatus}
        can-backdrop="false"
      >
        <se-dialog-header>Confirmation</se-dialog-header>
        <se-dialog-content>
          <span className="se-font-14px">
            Do you confirm to delete this News ?
          </span>
        </se-dialog-content>
        <se-dialog-footer>
          <se-button onClick={closeDeleteNewsPopUp}>Cancel</se-button>
          <se-button onClick={removeNewsConfirm}>OK</se-button>
        </se-dialog-footer>
      </se-dialog>
    </div>
  
  );
};

export default News;
