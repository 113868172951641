import React, { useContext } from "react";

import sld from "../../images/sld/Rectangle.svg";

import { DesignAssetContext } from "../CustomHooks/ContextApi";
import Ecc from "../../components/GensetPvBess/Ecc";
import Busbar from "../../components/GensetPvBess/Busbar";
import Linebar from "../../components/GensetPvBess/Linebar";
import Utility from "../../components/GensetPvBess/Utility";
import Battery from "../../components/GensetPvBess/Battery";
import PV1 from "../../components/GensetPvBess/PV1";
import Genset from "../../components/GensetPvBess/Genset";

import PV2 from "../../components/GensetPvBess/PV2";

import Load from "../../components/GensetPvBess/Load";
import embLabels from "../../config/emb-labels-en.json";
import PVExsisting from "../../components/GensetPvBess/PVExsisting";
import BatteryExsisting from "../../components/GensetPvBess/BatteryExsisting";
import PV3 from "../../components/GensetPvBess/PV3";
import Ev from "../../components/ISLANDABLE/Ev";

const GensetPvBessSLD = () => {
  const pType = JSON.parse(localStorage.getItem("projectType"));
  const {
    CBLoadList,
    CBAssetList,
    sldLoadCompleted,
    CBEVList,
    siteType,
    architectureType,
    switchboardType,
    loadSide,
  } = useContext(DesignAssetContext);
  return (
    <div
      id="SLD-Image"
      className="col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0"
    >
      {["ISLANDABLE_GSX", "ISLANDABLE"].includes(siteType) &&
      (switchboardType === "" ||
        switchboardType === embLabels.EXISTING_SWITCHBOARD) ? (
        <svg viewBox="0 0 1300 800">
          <g>
            <svg x="0" y="0" viewBox="0 0 1300 800">
              <g id="grid">
                <svg>
                  <image href={sld} x="0" y="0" />
                </svg>
              </g>

              <g>
                <svg viewBox="0 0 1300 800">
                  <g
                    id="components-sld"
                    style={sldLoadCompleted ? {} : { display: "none" }}
                  >
                    <Ecc />
                    <Busbar secondBusbar={true} />
                    <Linebar />
                    <Utility />
                    <Battery />
                    <Genset />
                    {CBAssetList[3].uiData.status && <PV1 />}
                    {CBAssetList[4].uiData.status && <PV2 />}
                    {CBAssetList[5].uiData.status && <PV3 />}
                    {CBAssetList[3].uiData.existingPmRequired && (
                      <PVExsisting />
                    )}
                    {CBAssetList[2].uiData.existingPmRequired && (
                      <BatteryExsisting />
                    )}

                    {CBLoadList.map((loadData, key) => (
                      <Load
                        key={loadData.uiData.xAxis}
                        load={loadData.uiData}
                        loadName={loadData.configData.name}
                        loadCbName={loadData.cbConfigData.name}
                        index={key}
                      />
                    ))}
                    {CBEVList.map(
                      (cbData, key) =>
                        cbData?.uiData?.status && (
                          <Ev
                            key={cbData?.configData?.name}
                            index={key}
                            status={cbData?.uiData?.status}
                          />
                        )
                    )}
                  </g>
                </svg>
              </g>
            </svg>
          </g>
        </svg>
      ) : (
        <svg viewBox="0 0 1300 800">
          <g>
            <svg x="0" y="0" viewBox="0 0 1300 800">
              <g id="grid">
                <svg>
                  <image href={sld} x="0" y="0" />
                </svg>
              </g>

              <g>
                <svg viewBox="-75 -10 1300 800">
                  <g
                    id="components-sld"
                    style={sldLoadCompleted ? {} : { display: "none" }}
                  >
                    <Ecc />
                    <Busbar secondBusbar={true} />

                    <Utility />
                    <Battery />
                    <Genset />
                    {CBAssetList[3].uiData.status && <PV1 />}
                    {CBAssetList[4].uiData.status && <PV2 />}
                    {CBAssetList[5].uiData.status && <PV3 />}

                    {CBLoadList.map((loadData, key) => (
                      <Load
                        key={loadData.uiData.xAxis}
                        load={loadData.uiData}
                        loadName={loadData.configData.name}
                        loadCbName={loadData.cbConfigData.name}
                        index={key}
                      />
                    ))}
                    {CBEVList.map(
                      (cbData, key) =>
                        cbData?.uiData?.status && (
                          <Ev
                            key={cbData?.configData?.name}
                            index={key}
                            status={cbData?.uiData?.status}
                          />
                        )
                    )}
                  </g>
                </svg>
              </g>
            </svg>
          </g>
        </svg>
      )}
    </div>
  );
};

export default GensetPvBessSLD;
