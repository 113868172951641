import React, {  useState } from "react";
import Pagination from "react-js-pagination";
import useMountEffect from "./CustomHooks/useMountEffect";
import APISESSION from "../api/ApiSession";
import DscTable from "./DscTable";
const apiSession = new APISESSION();
const Dsc = () => {

  const [searchInput, setSearchInput] = useState("");
  const [dscData, setDscData] = useState([]);
  const [filteredDscData, setFilteredDscData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [indexValue, setIndexValue] = useState("");
  const [expandedRow, setExpandedRow] = useState(-1);
  const [tableRows, setTableRows] = useState({
    activePage: 1,
    itemSliceFirst: 0,
    itemSliceLast: 10,
    itemsPerPage: 10,
  })
  useMountEffect(() => {
    setLoading(true);
    apiSession
      .getDscInfo()
      .then((response) => {
        if (response) {
          setDscData(response);
          setFilteredDscData(response)
          setLoading(false);
        }
      })
      .catch((error) => {
        if(error.response !== undefined){
        setLoading(false);
 }     });
  }, [refresh]);
  const  handlePageChange = (pageNumber)=> {
    if (expandedRow === indexValue) {
      setExpandedRow(-1); 
    }
    let itemLast = pageNumber * tableRows.itemsPerPage;
    let itemFirst = itemLast - tableRows.itemsPerPage;
    setTableRows(prev=>({
      ...prev,
      activePage: pageNumber,
      itemSliceFirst: itemFirst,
      itemSliceLast: itemLast,
      
    }))

}
  const handleSearchInput = (e) => {
    const { value } = e.target;
    let search = e.target.value;
    setSearchInput( value);
   
    const filteredData = dscData?.filter(dscdata=> dscdata?.subscriptionNumber?.toLowerCase().indexOf(search.toLowerCase()) !== -1  );
    setFilteredDscData(filteredData);
    if (value === "") {
      resetAssetList();
    }
    setTableRows({
      activePage: 1,
      itemSliceFirst: 0,
      itemSliceLast: tableRows.itemsPerPage,
    })
   
  };

 const resetAssetList = () =>{
  setFilteredDscData(dscData);
  setTableRows({
    activePage: 1,
    itemSliceFirst: 0,
    itemSliceLast: tableRows.itemsPerPage,
  })
 }
  const handleRefresh = async () => {
    setLoading(true);

    try {
      const response = await apiSession.refreshData();
      if (response.status === 200) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background">
      <se-loading loading={loading}></se-loading>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1 p-0">
          <div className="form-row justify-content-end align-items-center">
            <span
              className="pointer"
              style={searchInput ? {} : { display: "none" }}
              onClick={""}
            >
              <se-icon className="cheatSheetIcon se-icon icon_button ">
                data_refresh
              </se-icon>
            </span>
            &nbsp;&nbsp;
            <input
              className="form-control form-control-sm mr-sm-2 col-md-3 inline"
              type="text"
              name="searchInput"
              value={searchInput}
              placeholder="Search"
              onChange={handleSearchInput}
              aria-label="Search"
              autoComplete="none"
            />
            <se-button
              option="raised"
              size="small"
              color="primary"
              icon="data_refresh"
              onClick={handleRefresh}
            >
              Refresh
            </se-button>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12  mb-1 px-0">
          <div className="table-responsive">
           
            <DscTable data={filteredDscData}
             itemSliceDetails={tableRows} 
             setRefresh={setRefresh} 
             expandedRow={expandedRow}
              setExpandedRow={setExpandedRow}
              setIndexValue={setIndexValue}
              setLoading = {setLoading}
              />
          </div>

          <div
            className="col-sm-12 col-md-12 col-lg-12 col-xl-12 border listStyle mb-1 pt-2"
             style={dscData.length ? { display: "none" } : {}}
          >
            <p className="text-center se-font-16px-bold">No DSC data available</p>
          </div>

          {dscData?.length > tableRows.itemsPerPage ? <Pagination
                            activePage={tableRows.activePage}
                            itemsCountPerPage={tableRows.itemsPerPage}
                            totalItemsCount={dscData.length}
                            pageRangeDisplayed={5}
                            innerclassName="pagination pagination-users"
                            onChange={handlePageChange}
                        /> : ""
                        }
          <span className="float-right">
            count : {filteredDscData.length}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Dsc;
