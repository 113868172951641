import React, { useContext } from "react";

import PowerMeterDetails from "./PowerMeterDetails";
import CBConfigDetails from "./CBConfigDetails";

import cbImage from "../../images/CB.png";
import powerMeter from "../../images/power-meter-image.png";
import embLabels from "../../config/emb-labels-en.json";

import isTouchDevice from "../SE-Component/Tooltip";
import { AssetBlockContext } from "../CustomHooks/ContextApi";

const CircuitBreakerDetails = ({
  siteType,
  architectureType,
  selectedValues,
}) => {
  const { CBAssetList, setAssetSelection, assetDetailsDisplay } =
    useContext(AssetBlockContext);
  const setAssetHandler = () => setAssetSelection(embLabels.CIRCUIT_BREAKER);
 
  return (
    <div name="CB" className="col-md-12 p-0">
      <div className="col-md-12 pt-2 se-white-background">
        <div className="row">
          <div className="col-md-8">
            <img
              src={
                siteType === embLabels.PV_HYBRIDIZATION ||
                  (siteType === embLabels.ISLANDABLE_GSX &&
                    architectureType === embLabels.PV_GENSET_ATS) ||
                  (siteType === embLabels.GRID_CONNECTED_GSX &&
                    architectureType === embLabels.PV_BESS_NR)
                  ? powerMeter
                  : CBAssetList[selectedValues.selectedAssetCB]?.cbConfigData.cbAssetDetails[0]?.imageLink === undefined ?cbImage
                  :CBAssetList[selectedValues.selectedAssetCB]?.cbConfigData.cbAssetDetails[0]?.imageLink
              }
              className="asset-image-icon"
              alt={
                siteType === embLabels.PV_HYBRIDIZATION ||
                  (siteType === embLabels.ISLANDABLE_GSX &&
                    architectureType === embLabels.PV_GENSET_ATS) ||
                  (siteType === embLabels.GRID_CONNECTED_GSX &&
                    architectureType === embLabels.PV_BESS_NR)
                  ? "Power Meter"
                  : "circuit breaker"
              }
            />

            <span className="align-top line-height-30px bold">
              {siteType === embLabels.PV_HYBRIDIZATION ||
                (siteType === embLabels.ISLANDABLE_GSX &&
                  architectureType === embLabels.PV_GENSET_ATS) ||
                (siteType === embLabels.GRID_CONNECTED_GSX &&
                  architectureType === embLabels.PV_BESS_NR)
                ? "Power Meter"
                : "Circuit Breaker"}
            </span>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-sm-12 pt-2 se-grey-background se-border">
        <div className="row">
          <div className="col-md-3">
            <img
              src={
                siteType === embLabels.PV_HYBRIDIZATION ||
                  (siteType === embLabels.ISLANDABLE_GSX &&
                    architectureType === embLabels.PV_GENSET_ATS) ||
                  (siteType === embLabels.GRID_CONNECTED_GSX &&
                    architectureType === embLabels.PV_BESS_NR)
                  ? powerMeter
                  :  CBAssetList[selectedValues.selectedAssetCB]?.cbConfigData.cbAssetDetails[0]?.imageLink === undefined ?cbImage
                  :CBAssetList[selectedValues.selectedAssetCB]?.cbConfigData.cbAssetDetails[0]?.imageLink
              }
              className="asset-image-display"
              alt={
                siteType === embLabels.PV_HYBRIDIZATION ||
                  (siteType === embLabels.ISLANDABLE_GSX &&
                    architectureType === embLabels.PV_GENSET_ATS) ||
                  (siteType === embLabels.GRID_CONNECTED_GSX &&
                    architectureType === embLabels.PV_BESS_NR)
                  ? "Power Meter"
                  : "circuit breaker"
              }
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-12 bold">
                {siteType === embLabels.GRID_CONNECTED_GSX &&
                  architectureType === embLabels.PV_BESS_NR &&
                  selectedValues.selectedAssetPM === 0
                  ? "Socomec"
                  : CBAssetList[selectedValues.selectedAssetCB]?.cbConfigData.cbAssetDetails[0]?.manufacturer}
              </div>
            </div>
            <div className="row">
              <div className="col-md-9">
                {siteType === embLabels.PV_HYBRIDIZATION ||
                  (siteType === embLabels.ISLANDABLE_GSX &&
                    architectureType === embLabels.PV_GENSET_ATS) ||
                  (siteType === embLabels.GRID_CONNECTED_GSX &&
                    architectureType === embLabels.PV_BESS_NR &&
                    selectedValues.selectedAssetPM === 3)
                  ? "Power Meter"
                  : siteType === embLabels.GRID_CONNECTED_GSX &&
                    architectureType === embLabels.PV_BESS_NR &&
                    selectedValues.selectedAssetPM === 0
                    ? "B30"
                    : "Circuit Breaker"}
              </div>
              <div className="col-md-3 text-right">
              <span className="se-block-enabled">
                <se-tooltip
                  position="left"
                  action={isTouchDevice() ? "click" : "hover"}
                >
                  <se-icon option="raised" color="secondary" slot="tooltip">
                    information_circle
                  </se-icon>

                  <se-tooltip-content>
                    {siteType === embLabels.ISLANDABLE_GSX &&
                      architectureType === embLabels.PV_GENSET_ATS
                      ? selectedValues.selectedAssetPM === 3
                        ? "PM5563"
                        : "A9MEM3455"
                      : null}
                    {siteType === embLabels.GRID_CONNECTED_GSX &&
                      architectureType === embLabels.PV_BESS_NR &&
                      selectedValues.selectedAssetPM === 0 &&
                      "B30"}
                    {siteType === embLabels.GRID_CONNECTED_GSX &&
                      architectureType === embLabels.PV_BESS_NR &&
                      selectedValues.selectedAssetPM === 3 &&
                      "PM5563"}
                    {siteType === embLabels.PV_HYBRIDIZATION && "PM3250"}
                    {siteType === embLabels.ISLANDABLE
                      ? assetDetailsDisplay.communicationModel ||
                      CBAssetList[selectedValues.selectedAssetCB].cbConfigData
                        .cbAssetDetails[0]?.communicationModel ||
                      "PowerPact NSX"
                      : null}
                  </se-tooltip-content>
                </se-tooltip>
                </span>
              </div>
              <div className="col-md-12">
                {siteType === embLabels.ISLANDABLE_GSX &&
                  architectureType === embLabels.PV_GENSET_ATS
                  ? selectedValues.selectedAssetPM === 3
                    ? "PM5563"
                    : "A9MEM3455"
                  : null}
                {siteType === embLabels.GRID_CONNECTED_GSX &&
                  architectureType === embLabels.PV_BESS_NR &&
                  selectedValues.selectedAssetPM === 3 &&
                  "PM5563"}
                {siteType === embLabels.PV_HYBRIDIZATION && "PM3250"}
                {siteType === embLabels.ISLANDABLE
                  ? assetDetailsDisplay.communicationModel ||
                  CBAssetList[selectedValues.selectedAssetCB].cbConfigData
                    .cbAssetDetails[0]?.communicationModel ||
                  "PowerPact NSX"
                  : null}
              </div>

              {[embLabels.ISLANDABLE].includes(siteType) && (
                <div className="row">
                  <div className=" col-md col-sm col-lg text-center">
                    <se-button
                      option="outline"
                      size="small"
                      onClick={setAssetHandler}
                    >
                      Asset Selection
                    </se-button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {(siteType === embLabels.PV_HYBRIDIZATION ||
        (siteType === embLabels.ISLANDABLE_GSX &&
          architectureType === embLabels.PV_GENSET_ATS) ||
        (siteType === embLabels.GRID_CONNECTED_GSX &&
          architectureType === embLabels.PV_BESS_NR)) && <PowerMeterDetails />}

      {(siteType === embLabels.ISLANDABLE||siteType===embLabels.GRID_CONNECTED) && <CBConfigDetails />}
    </div>
  );
};

export default CircuitBreakerDetails;
