import React, { useContext } from "react";

import MicrogridType from "./MicrogridType";
import GenericSiteType from "./GenericSiteType";
import GSXSiteType from "./GSXSiteType";
import ArchitectureType from "./ArchitectureType";
import SelectUseCase from "./SelectUseCase";
import SelectUseCaseGSX from "./SelectUseCaseGSX";
import RemoteMonitoring from "./RemoteMonitoring"; 

import { InformationPageContext } from "../CustomHooks/ContextApi";

import embLabels from "../../config/emb-labels-en.json";

const ProjectType = ({siteCreated,projectStatusData}) => {
  const { UserScope, siteType, architectureType, findProjectTypeHandler, projectType } =
    useContext(InformationPageContext);
    const projectTypeValue = JSON.parse(localStorage.getItem("projectType"))?.projectTypeValue 
    
     return (
    <div
    className={`col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background ${UserScope?.access.saveProjects && projectStatusData?.projectStatus === "COMPLETED" || siteCreated ? "se-block-disabled" : ""}`}
      // className={` col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background ${UserScope.access.saveProjects ? "" : "se-block-disabled"}`}
    >
      <se-icon color="primary">product_selector</se-icon>
      <span className="ml-2 se-font-14px-bold se-life-green">
        Topology & Use Cases
      </span>
      <div className="dropdown-divider"></div>

      {(findProjectTypeHandler() === embLabels.GENERIC_PROJECT&&projectTypeValue==="MGaaS ANSI") && (
        <>
          <MicrogridType />
          <GenericSiteType />
          {siteType && <SelectUseCase />}
          {siteType && <RemoteMonitoring />}
        </>
      )}

{(findProjectTypeHandler() === embLabels.GENERIC_PROJECT&&projectTypeValue!=="MGaaS ANSI") && (
        <>
          <MicrogridType />
          <GenericSiteType />
          {siteType && <RemoteMonitoring />}
          {siteType && <SelectUseCase />}
        </>
      )}

      {findProjectTypeHandler() === embLabels.GREENSTRUXURE_PROJECT && (
        <>
          <MicrogridType />
          <GSXSiteType />
          
         { /* don't delete below commented lines */}
         {/* <ArchitectureType /> */}
          {architectureType === "PV_GENSET_ATS" || architectureType === "PV_BESS_NR" && <SelectUseCaseGSX />}
          {/* {architectureType === "PV_BESS_NR" && <SelectUseCase />} */}
          {architectureType && <RemoteMonitoring />}
          {architectureType && siteType === embLabels.ISLANDABLE_GSX && <SelectUseCase />}


        </>
      )}

      {findProjectTypeHandler() === embLabels.A2E_PROJECT && (
        <>
          <MicrogridType isDisabled />
          <GenericSiteType isA2E />
          {siteType && <RemoteMonitoring />}
        </>
      )}
    </div>
  );
};

export default ProjectType;