import React, { useState } from "react";
import gensetImage from "../images/Genset-image-SE.jpg";
import batteryImage from "../images/BESS-image-SE.png";
import pvCL60SE from "../images/CL60_SE.jpg";
import pvCL36SE from "../images/CL36_SE.jpg";
import pvCL125SE from "../images/CL125_SE.jpg";

const AssetSelectionDER = ({
  assetInfo,
  onSelectManufacturer,
  closeAssetSelectionBlock,
  onSelectAssetInModal,
}) => {
  const [disabledAssetErrorPopUp, setDisabledAssetErrorPopUp] = useState(false);
  const [selectedAssetValue, setSelectedAssetValue] = useState("");

  const handleCheckBoxChange = e => {
    let enabledStatus = false;
    assetInfo.assets.forEach(data => {
      if (parseInt(e.target.value) === parseInt(data.assetId)) {
        enabledStatus = data.assetEnabled;
      }
      if (document.getElementById(data.assetId)) {
        document.getElementById(data.assetId).checked = false;
      }
    });
    if (enabledStatus) {
      document.getElementById(e.target.value).checked = true;
      setSelectedAssetValue(e.target.value);
    } else {
      document.getElementById(e.target.value).checked = false;
      setDisabledAssetErrorPopUp(true);
      setSelectedAssetValue(0);
    }
  };
  const closePopUpAlertAsset = () => {
    setDisabledAssetErrorPopUp(false);
  };

  const assetSelectedFromList = () => {
    onSelectAssetInModal(selectedAssetValue);
    setSelectedAssetValue(0);
  };
  return (
    <se-dialog
      id="dialog-complex"
      size="large"
      open={assetInfo.assetSelected}
      can-backdrop="false"
    >
      <se-dialog-header>Asset Selection</se-dialog-header>

      <se-dialog-content className="se-dialog-content">
        <se-loading loading={assetInfo.manufacturerLoading}></se-loading>
        <div className="container se-custom-container-popup">
          <div className="row se-grey-background pl-1 sticky">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
              <div className="form-group row">
                <label
                  htmlFor="manufacturer"
                  className="col-sm-3 col-form-label bold"
                >
                  Asset Manufacturer
                </label>
                <div className="col-sm-9">
                  <select
                    id="manufacturer"
                    className="form-control form-control-sm"
                    value={assetInfo.selectedManufacturer}
                    onChange={onSelectManufacturer}
                  >
                    <option value="" disabled defaultValue hidden>
                      Select Manufacturer
                    </option>
                    {assetInfo.assetManufacturer.map((data, idx) => (
                      <option key={idx} value={data}>
                        {data}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {assetInfo.selectedManufacturer !== ""
              ? assetInfo.assets.map((data, index) =>
                assetInfo.selectedManufacturer === data.manufacturer ? (
                  <div
                    className="col-sm-12 col-md-12 col-lg-12 col-xl-12 border-bottom"
                    key={data.assetId}
                  >
                    <div className="row mt-1 mb-1 ">
                      <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1 d-flex align-items-center ">
                        <div className="custom-control custom-radio">
                          <input
                            type="checkbox"
                            className="custom-control-input asCompatible"
                            value={data.assetId}
                            name={data.assetId}
                            id={data.assetId}
                            onChange={handleCheckBoxChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={data.assetId}
                          ></label>
                        </div>
                      </div>
                      {data.imageLink !== null ? (
                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2 mt-2">
                          <img
                            src={data.imageLink}
                            alt={data.manufacturer}
                            height="65px"
                            width="65px"
                          />
                        </div>
                      ) : (
                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2 mt-2">
                          {data.assetType === "BESS" &&
                            data.manufacturer === "SCHNEIDER ELECTRIC" ? (
                            <img
                              src={batteryImage}
                              alt="SCHNEIDER ELECTRIC"
                              height="65px"
                            />
                          ) : data.assetType === "BESS" ? (
                            <se-icon color="primary" size="xlarge">
                              battery2_half
                            </se-icon>
                          ) : (
                            ""
                          )}

                          {data.assetType === "Generator" &&
                            data.manufacturer === "STANDARD GENSET TEMPLATE" ? (
                            <img
                              src={gensetImage}
                              height="65px"
                              alt="STANDARD GENSET TEMPLATE"
                            />
                          ) : data.assetType === "Generator" ? (
                            <se-icon color="primary" size="xlarge">
                              diesel_generator
                            </se-icon>
                          ) : (
                            ""
                          )}

                          {data.assetType === "PV_inverter" &&
                            data.manufacturer === "SCHNEIDER ELECTRIC" ? (
                            data.communicationModel === "CONEXT CL36" ? (
                              <img
                                src={pvCL36SE}
                                height="65px"
                                alt="CONEXT CL36"
                              />
                            ) : data.communicationModel === "CONEXT CL60" ? (
                              <img
                                src={pvCL60SE}
                                height="65px"
                                alt="CONEXT CL60"
                              />
                            ) : data.communicationModel === "CONEXT CL125" ? (
                              <img
                                src={pvCL125SE}
                                height="65px"
                                alt="CONEXT CL125"
                              />
                            ) : data.communicationModel === "CONEXT CL25" ? (
                              <img
                                src={pvCL60SE}
                                height="65px"
                                alt="CONEXT CL25"
                              />
                            ) : (
                              <se-icon color="primary" size="xlarge">
                                solar_panel1
                              </se-icon>
                            )
                          ) : data.assetType === "PV_inverter" ? (
                            <se-icon color="primary" size="xlarge">
                              solar_panel1
                            </se-icon>
                          ) : (
                            ""
                          )}
                        </div>
                      )}

                      <div className="col-sm col-md col-lg col-xl">
                        <div className="row">
                          <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 bold">
                            {data.manufacturer}
                          </div>
                          <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 bold">
                            {data.communicationModel}
                          </div>
                          {data.assetProperties.map(
                            (properties, indexProp) => (
                              <div key={indexProp} className="col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                                {properties.type}: {properties.value}
                              </div>
                            )
                          )}
                        </div>
                        <div className="row mt-1 mb-1 ">
                          {data.assetEnabled === true ? (
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                              <span>{data.comments}</span>
                            </div>
                          ) : (
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-info-disable">
                              <se-icon color="standard" size="small">
                                information_stroke{" "}
                              </se-icon>
                              {data.comments.trim() === "" ? (
                                <span>
                                  Not available as standard, Contact
                                  Schneider-Electric support team for
                                  integration in your application or
                                  {data.assetType === "Generator"
                                    ? " use “Standard genset template” "
                                    : data.assetType === "BESS"
                                      ? " use “Schneider Electric” BESS"
                                      : data.assetType === "PV_inverter"
                                        ? " use “Schneider Electric” or Standard PV Inverter Template"
                                        : ""}{" "}
                                  model
                                </span>
                              ) : (
                                <span>{data.comments}</span>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              )
              : ""}
          </div>
        </div>

        <se-dialog
          id="dialog-asset-select-alert"
          size="small"
          can-backdrop="false"
          open={disabledAssetErrorPopUp}
        >
          <se-dialog-content icon="information_stroke" icon-color="primary">
            Not available as standard, Contact Schneider-Electric support team
            for integration in your application.
          </se-dialog-content>
          <se-dialog-footer>
            <se-button onClick={closePopUpAlertAsset}>OK</se-button>
          </se-dialog-footer>
        </se-dialog>
      </se-dialog-content>
      <se-dialog-footer>
        <se-button onClick={closeAssetSelectionBlock}>Cancel</se-button>

        <se-button
          disabled={selectedAssetValue ? "false" : "true"}
          onClick={assetSelectedFromList}
          color="primary"
        >
          Select
        </se-button>
      </se-dialog-footer>
    </se-dialog>
  );
};

export default AssetSelectionDER;