import React from "react";

const DesignAssetDescription = ({ siteCreated, projectStatusData }) => (
  <div className="row se-white-background">
   
    <div className="col-sm-9 col-md-9 col-lg-9 col-xl-9 text-center se-sld-legendHeader">
      <div className="unconfigured">
        <div className="sld-legend" role="alert">
          <span className="sld-legend-icon">
            <se-icon className="icon-large">
              <span>electricity_polarity_negative_minus</span>
            </se-icon>
          </span>
          <span className="message">Unconfigured Asset</span>
        </div>
      </div>

      <div className="selected-asset">
        <div className="sld-legend">
          <span className="sld-legend-icon">
            <se-icon className="icon-large" color="secondary">
              <span>electricity_polarity_negative_minus</span>
            </se-icon>
          </span>
          <span className="message">Selected Asset</span>
        </div>
      </div>

      <div>
        <div className="sld-legend">
          <span className="sld-legend-icon">
            <se-icon className="icon-large hydrated">
              <span>electricity_polarity_negative_minus</span>
            </se-icon>
          </span>
          <span className="message">Configured Asset</span>
        </div>
      </div>
    </div>
    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 text-right pt-2 invisible">
      <button type="button" className="btn btn-outline-success btn-sm">
        IP Address Auto
      </button>
    </div>
  </div>
);

export default DesignAssetDescription;
