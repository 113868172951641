import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";
import embLabels from "../../config/emb-labels-en.json";
import MyErrorBoundary from "../../utils/ErrorBoundary";
const Busbar = ({ secondBusbar }) => {
  const {
    CBAssetList:[,,{uiData:existingUiData},{uiData},],
    coordinates: { busbarCordinates },
    CBAssetList,
    siteType,
    switchboardType,
    loadSide 
  } = useContext(DesignAssetContext);

  return (
    <MyErrorBoundary>
      
    <>
      {(["GENSET_PVBESS_A2E","GRID_CONNECTED_GSX","ISLANDABLE_GSX","GRID_CONNECTED","PV_HYBRIDIZATION","ISLANDABLE"].includes(siteType)  ) &&
      (switchboardType === "" || switchboardType === embLabels.EXISTING_SWITCHBOARD) ? (
        <svg>
          <g id="busbar">
            <line
             x1={ busbarCordinates.x11}
             y1={loadSide === "LOAD_SIDE" ? "300" : Number(busbarCordinates.y11)}
             x2={busbarCordinates.x21}
             y2={loadSide === "LOAD_SIDE" ? "300" :Number(busbarCordinates.y21)}
              // x1={ (uiData.existingPmRequired&&existingUiData.existingPmRequired)?"80":(uiData.existingPmRequired||existingUiData.existingPmRequired)?"118":busbarCordinates.x11}
              // y1={loadSide==="LOAD_SIDE" ?"300":busbarCordinates.y11}
              // x2={uiData.existingPmRequired?"440":busbarCordinates.x21}
              // y2={loadSide==="LOAD_SIDE" ?"300":busbarCordinates.y21}
              stroke="#333333"
              strokeWidth="3"
            />
            {secondBusbar && (
              <line
                x1={busbarCordinates.x12}
                y1={loadSide === "LOAD_SIDE" ? "490" : Number(busbarCordinates.y12)}
                x2={busbarCordinates.x22}
                y2={loadSide === "LOAD_SIDE" ? "490" : Number(busbarCordinates.y22)}
                stroke="#333333"
                strokeWidth="3"
              />
            )}
          </g>
        </svg>
      ) : (
        
        <svg>
          <g id="busbar">
            <line
              x1={busbarCordinates.x11}
              y1={busbarCordinates.y11}             
              x2={busbarCordinates.x21||"500"}
              y2={busbarCordinates.y21}
              stroke="#333333"
              strokeWidth="3"
              // x1="98"
              // y1="300"              
              // x2={((switchboardType !== "" ||switchboardType !== embLabels.EXISTING_SWITCHBOARD) && ["ISLANDABLE_GSX","ISLANDABLE"].includes(siteType)) ? Number(busbarCordinates.x22) + 130 : Number(busbarCordinates.x22)+30}
              // y2="300"
              // stroke="#333333"
              // strokeWidth="3"
            />
          </g>
        </svg>
      )}
    </>
    </MyErrorBoundary>
  );
};

export default Busbar;
