import React, { useEffect, useReducer, useState } from "react";

import TableHeading from "./SystemPages/TableHeading";
// import CircuitBreakerTable from "./SystemPages/CircuitBreaker";
// import DERtable from "./SystemPages/DERTable";
// import EVTable from "./SystemPages/EVTable";
// import PowerMeterTable from "./SystemPages/PowerMeterTable";
import SystemDeviceTable from "./SystemPages/SystemDeviceTable";
import SystemSupervisionTable from "./SystemPages/SystemSupervisionTable";
import SystemSettingTable from "./SystemPages/SystemSettingTable";

import embLabels from "../config/emb-labels-en.json";
import { withRouter } from "react-router-dom";
//reducer

import {
  initialSystemState,
  systemReducer,
} from "./DesignAssets/Reducers/SystemPageReducer";

//api call
import ApiSession from "../api/ApiSession";
import { upDateProjectType } from "../redux/features/projectTypeSlice";
import { useDispatch, useSelector } from "react-redux";
import { getSecureArchitectureDataRequest } from "../redux/features/secureArchitectureDataSlice";
const apiSession = new ApiSession();

// ACTIONS

export const ACTIONS = {
  FETCH_SUCCESS: "FETCH_SUCCESS",
  ENABLE_TEXTBOX: "ENABLE_TEXTBOX",
  ENABLE_TEXTBOX_SYSTEM: "ENABLE_TEXTBOX_SYSTEM",
  RESET_COMMUNICATION: "RESET_COMMUNICATION",
};

// Reducer functions

const initialCommunicationState = {
  ipAddress: "",
  mask: "",
  gatewayAddress: "",
  slaveId: "",
  comments: "",
};
const isValidName = (value) =>
  /^[a-zA-Z0-9_-\s]*$/.test(value) ? true : false;

const communicationReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.ENABLE_TEXTBOX:
      return {
        ...state,
        ipAddress: action.payload.ipAddress,
        mask: action.payload.mask,
        gatewayAddress: action.payload.gatewayAddress,
        slaveId: action.payload.slaveId,
        comments: action.payload.comments,
      };
    case ACTIONS.ENABLE_TEXTBOX_SYSTEM:
      return {
        ...state,
        ipAddress: action.payload.ipAddress,
        mask: action.payload.mask,
        gatewayAddress: action.payload.gatewayAddress,
        slaveId: action.payload.slaveId,
        comments: action.payload.comments,
      };
    case "ipAddress":
      return {
        ...state,
        ipAddress: action.payload.ipAddress,
      };

    case "mask":
      return {
        ...state,
        mask: action.payload.mask,
      };

    case "gatewayAddress":
      return {
        ...state,
        gatewayAddress: action.payload.gatewayAddress,
      };

    case "slaveId":
      return {
        ...state,
        slaveId: action.payload.slaveId,
      };
    case "comments":
      if (isValidName(action.payload.comments)) {
        return {
          ...state,
          comments: action.payload.comments,
        };
      }
      break;
    case ACTIONS.RESET_COMMUNICATION:
      return initialCommunicationState;
    default:
      return state;
  }
};

// System page component
const SystemPage = (props) => {
  // usestate
  // setting userScope
  let userSampleData = {
    name: "",
    role: "",
    access: "",
  };

  let userScope =
    JSON.parse(localStorage.getItem("scope")) !== null
      ? JSON.parse(localStorage.getItem("scope"))
      : userSampleData;

  const [UserScope] = useState(userScope);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(true);
  const [siteCreated, setSiteCreated] = useState(false);
  const [siteType, setSiteType] = useState("");
  const [clientType, setClientType] = useState("");
  const [architectureType, setArchitectureType] = useState("");
  const [editedAssetType, setEditedAssetType] = useState("");
  const [modbusSlaveIdArray, setModbusSlaveIdArray] = useState([]);
  const [systemInfoDERData, setSystemInfoDerData] = useState({});
  const [systemCommunicationData, setSystemCommunicationData] = useState({});
  const [componentId, setComponentId] = useState({});
  const [snackBar, setSnackBar] = useState({ type: "", message: "" });
  const [projectStatusData, setProjectStatusData] = useState();
  // usereducer
  const [systemState, systemDispatch] = useReducer(
    systemReducer,
    initialSystemState
  );

  const [communicationState, communicationDispatch] = useReducer(
    communicationReducer,
    initialCommunicationState
  );
  const secureArchitectureData = useSelector(
    (state) => state?.secureArchitectureData?.data
  );
  
  // useEffect
  useEffect(() => {
    if( ["MGaaS IEC", "DaaS IEC","DaaS ANSI"].includes(JSON.parse(localStorage.getItem("projectType"))?.projectTypeValue)){
      dispatch(
        getSecureArchitectureDataRequest(sessionStorage.getItem("projectId"))
      );
    }
    
  }, []);

  useEffect(() => {
    dispatch(
      upDateProjectType({
        projectType: props?.projectStatusData?.projectType,
        projectName: props?.projectStatusData?.projectName,
      })
    );
    if (sessionStorage.getItem("projectId") === "null") {
      let { history } = props;
      history.push({
        pathname: "/information",
      });
    } else {
      setLoading(true);
      // apiSession
      //   .getSystemInfo(sessionStorage.getItem("projectId"))
      //   .then(response => {
      //     let derComInfo = {};
      //     let systemComInfo = {};
      //     let modbusSlaveArray = [];
      //     setSiteType(response.siteType);
      //     setClientType(response.clientType);
      //     setArchitectureType(response.architecture);
      //     setSiteCreated(response.siteCreated);

      //     response.systemComponents.map(infoDER => {
      //       setComponentId(prevState => ({
      //         ...prevState,
      //         [infoDER.componentId]: false,
      //       }));
      //       derComInfo[infoDER.componentId] = infoDER;
      //       if (infoDER.network === "Circuit Breaker") {
      //         modbusSlaveArray.push(infoDER.slaveId);
      //       }
      //       return null;
      //     });

      //     response.systemSettings.map(sysSetting => {
      //       setComponentId(prevState => ({
      //         ...prevState,
      //         [sysSetting.id]: false,
      //       }));
      //       systemComInfo[sysSetting.id] = sysSetting;
      //       return null;
      //     });

      //     setModbusSlaveIdArray(modbusSlaveArray);
      //     setSystemInfoDerData(derComInfo);
      //     setSystemCommunicationData(systemComInfo);
      //     setLoading(false);

      //     // dispatch function

      //     systemDispatch({
      //       type: ACTIONS.FETCH_SUCCESS,
      //       payload: {
      //         systemInfoDER: response.systemComponents,
      //         systemCommunication: response.systemSettings,
      //       },
      //     });

      //     // for system communication validation
      //     localStorage.setItem("communicationValidated", response.systemsPage);
      //   })
      //   .catch(error => {
      //     setLoading(false);
      //   });
      if (props.projectInformationData) {
        let response = props.projectInformationData;
        let derComInfo = {};
        let systemComInfo = {};
        let modbusSlaveArray = [];
        setSiteType(response.siteType);
        setClientType(response.clientType);
        setArchitectureType(response.architecture);
        setSiteCreated(response.siteCreated);

        response?.systemComponents?.map((infoDER) => {
          setComponentId((prevState) => ({
            ...prevState,
            [infoDER.componentId]: false,
          }));
          derComInfo[infoDER.componentId] = infoDER;
          if (infoDER.network === "Circuit Breaker") {
            modbusSlaveArray.push(infoDER.slaveId);
          }
          return null;
        });

        response?.systemSettings?.map((sysSetting) => {
          setComponentId((prevState) => ({
            ...prevState,
            [sysSetting.id]: false,
          }));
          systemComInfo[sysSetting.id] = sysSetting;
          return null;
        });

        setModbusSlaveIdArray(modbusSlaveArray);
        setSystemInfoDerData(derComInfo);
        setSystemCommunicationData(systemComInfo);
        setLoading(false);

        // dispatch function

        systemDispatch({
          type: ACTIONS.FETCH_SUCCESS,
          payload: {
            systemInfoDER: response.systemComponents,
            systemCommunication: response.systemSettings,
          },
        });

        // for system communication validation
        localStorage.setItem("communicationValidated", response.systemsPage);
      } else {
        setLoading(false);
      }
    }
  }, [props]);

  // functions for  info table start //

  const enableTextBox = (value, dataAsset) => {
    setEditedAssetType(dataAsset?.type);
    systemState.systemInfoDER.forEach((infoDER) =>
      setComponentId((prevState) => ({
        ...prevState,
        [infoDER.componentId]: false,
      }))
    );
    systemState.systemCommunication.forEach((systemComInfo) =>
      setComponentId((prevState) => ({
        ...prevState,
        [systemComInfo.id]: false,
      }))
    );
    setComponentId((prevState) => ({ ...prevState, [value]: true }));
    // dispacth function

    if (!dataAsset) {
      communicationDispatch({
        type: ACTIONS.ENABLE_TEXTBOX,
        payload: {
          ipAddress: systemInfoDERData[value].systemSetting[0]?.ipAddress,
          mask: systemInfoDERData[value].systemSetting[0]?.mask,
          gatewayAddress:
            systemInfoDERData[value].systemSetting[0]?.gatewayAddress,
          slaveId: systemInfoDERData[value].systemSetting[0]?.slaveId,
          comments:
            systemInfoDERData[value].systemSetting[0]?.comments === null
              ? ""
              : systemInfoDERData[value].systemSetting[0]?.comments,
        },
      });
    } else {
      communicationDispatch({
        type: ACTIONS.ENABLE_TEXTBOX,
        payload: {
          ipAddress: dataAsset.ipAddress,
          mask: dataAsset.mask,
          gatewayAddress: dataAsset.gatewayAddress,
          slaveId: dataAsset.slaveId,
          comments: dataAsset.comments === null ? "" : dataAsset.comments,
        },
      });
    }
  };

  const handleChange = (e, data) => {
    communicationDispatch({
      type: e.target.name,
      payload: { [e.target.name]: e.target.value },
    });
  };

  const cancelUpdate = (componentId) => {
    setComponentId((prevState) => ({ ...prevState, [componentId]: false }));
    resetCommunicationSettings();
  };

  const resetCommunicationSettings = () =>
    communicationDispatch({ type: ACTIONS.RESET_COMMUNICATION });

  const checkForModbusDuplicate = (ip, slaveID, dataAsset) => {
    if (
      dataAsset.ipAddress !== communicationState.ipAddress ||
      dataAsset.slaveId !== communicationState.slaveId
    ) {
      const EXISTINGIP = [];
      for (let DERData of systemState.systemInfoDER) {
        if (DERData.network === "Circuit Breaker" || DERData.network === "Power Meter") {
          DERData.systemSetting.forEach((systemSetting) => {
            if (systemSetting.slaveId === slaveID) {
              EXISTINGIP.push(systemSetting.ipAddress);
            }
          });
        }
      }
      return EXISTINGIP.indexOf(ip) > -1 ? true : false;
    }
  };

  const checkForModbusDuplicateDer = (ip, slaveID, dataAsset) => {
    if (
      dataAsset.ipAddress !== communicationState.ipAddress ||
      dataAsset.slaveId !== communicationState.slaveId
    ) {
      const EXISTINGIP = [];
      for (let DERData of systemState.systemInfoDER) {
        if (DERData.network === "Inverter") {
          DERData.systemSetting.forEach((systemSetting) => {
            if (systemSetting.slaveId === slaveID) {
              EXISTINGIP.push(systemSetting.ipAddress);
            }
          });
        }
      }
      return EXISTINGIP.indexOf(ip) > -1 ? true : false;
    }
  };

  const checkForIpInIfe = (ip, numberOfDeviceIFE, dataAsset) => {
    if (dataAsset.ipAddress !== communicationState.ipAddress) {
      let count = 0;
      const cbTypes = ["Circuit Breaker"];
      const systemInfoDERNew = [...systemState.systemInfoDER];

      const CBIP = [];
      for (let DERData of systemInfoDERNew) {
        if (DERData.network === "Circuit Breaker") {
          DERData.systemSetting.forEach((systemSetting) => {
            CBIP.push(systemSetting.ipAddress);
          });
        }
      }

      CBIP.forEach((ipaddress) => ipaddress === ip && count++);
      let returnValue = count > numberOfDeviceIFE - 1 ? true : false;

      if (+numberOfDeviceIFE === 7) {
        const CBIPIfeOne = systemInfoDERNew.filter(
          (data) =>
            cbTypes.includes(data.network) &&
            data.numberOfDeviceIFE === "1" &&
            data.systemSetting[0]?.ipAddress === communicationState.ipAddress
        );
        if (CBIPIfeOne.length) returnValue = true;
      }

      return returnValue;
    }
  };

  const saveCommunicationsDer = (componentId, type, dataAsset) => {
    let filteredIpAddress = [];
    let systemInfoDERDataNew = Object.assign([], systemState.systemInfoDER);

    if (isValidSlaveID(communicationState.slaveId)) {
      if (
        communicationState.slaveId <= 255 &&
        parseInt(communicationState.slaveId) !== 0
      ) {
        if (dataAsset.slaveId !== communicationState.slaveId) {
          filteredIpAddress = systemInfoDERDataNew.filter((DERData) => {
            if (DERData.componentType === "Serial") {
              if (
                DERData.systemSetting.filter(
                  (data) => data.slaveId === communicationState.slaveId
                ).length
              ) {
                return DERData;
              }
            }
            return null;
          });

          if (filteredIpAddress.length) {
            callSnackbar("error", "Entered Duplicate Slave ID");
          } else {
            saveSystemInfoDer(componentId, type, dataAsset);
          }
        } else {
          saveSystemInfoDer(componentId, type, dataAsset);
        }
      } else {
        callSnackbar(
          "error",
          "Modbus Slave Id should be always between 1 - 255"
        );
      }
    } else {
      callSnackbar("error", "Invalid field entires");
    }
  };

  const getTypeRange = (type, vlan) => {
    const data = secureArchitectureData?.securedCommunicationArchitecture;
    const gatewayAddress = Number(
      data
        .filter((cb) => cb.vlan === vlan)[0]
        .hostAddress.split(".")
        .slice(-1)[0]
    );
    const range = data
      .filter((item) => item.vlan === vlan)[0]
      .hostRange.split("-");
    const startPoint = Number(range[0].split(".").slice(-1)[0]);
    const endPoint = Number(range[1].split(".").slice(-1)[0]);
    const selectedIp = Number(
      communicationState.ipAddress.split(".").slice(-1)[0]
    );
    return { startPoint, endPoint, selectedIp, gatewayAddress };
  };
  const updateCommunication = (
    componentId,
    type,
    numberOfDeviceIFE,
    dataAsset
  ) => {
    if (
      isValidIp(communicationState.ipAddress) &&
      isValidIp(communicationState.mask) &&
      isValidIp(communicationState.gatewayAddress) &&
      isValidSlaveID(communicationState.slaveId)
    ) {
      let filteredIpAddress = [];
      if (dataAsset?.ipAddress !== communicationState.ipAddress) {
        const systemInfoDerDataNew = [...systemState.systemInfoDER];
        filteredIpAddress = systemInfoDerDataNew.filter((DERData) => {
          if (type === "Circuit Breaker") {
            if (DERData.network !== "Circuit Breaker") {
              if (
                DERData.systemSetting.filter(
                  (data) => data.ipAddress === communicationState.ipAddress
                ).length
              )
                return DERData;
            }
            return null;
          }
          return null;
          // else {
          //   return DERData.ipAddress === communicationState.ipAddress;
          // }
        });
      }
      if (filteredIpAddress.length) {
        callSnackbar("error", "Entered duplicate Ipaddress");
      } else if (type === "Circuit Breaker") {
        if (
          checkForModbusDuplicate(
            communicationState.ipAddress,
            communicationState.slaveId,
            dataAsset
          )
        ) {
          callSnackbar(
            "error",
            "Duplicate Modbus Slave ID allowed only if their IPaddress are unique"
          );
        } else if (
          checkForIpInIfe(
            communicationState.ipAddress,
            numberOfDeviceIFE,
            dataAsset
          )
        ) {
          callSnackbar(
            "error",
            `Selected IFE can have maximum ${numberOfDeviceIFE} Circuit Breakers`
          );
        } else if (dataAsset.slaveId !== communicationState.slaveId) {
          if (
            communicationState.slaveId <= 255 &&
            parseInt(communicationState.slaveId) !== 0
          ) {
            const modbusSlaveIdArrayNew = [...modbusSlaveIdArray];
            modbusSlaveIdArrayNew.push(communicationState.slaveId);
            setModbusSlaveIdArray(modbusSlaveIdArrayNew);
            saveSystemInfoDer(componentId, type, dataAsset);
          } else {
            callSnackbar(
              "error",
              "Modbus Slave Id should be always between 1 - 255"
            );
          }
        } else {
          saveSystemInfoDer(componentId, type, dataAsset);
        }
      } else if (type !== "Circuit Breaker") {
        if (
          checkForModbusDuplicateDer(
            communicationState.ipAddress,
            communicationState.slaveId,
            dataAsset
          )
        ) {
          callSnackbar(
            "error",
            "Duplicate IPaddress allowed only if their Modbus Slave ID  are unique"
          );
        } else if (dataAsset.slaveId !== communicationState.slaveId) {
          if (
            communicationState.slaveId <= 255 &&
            parseInt(communicationState.slaveId) !== 0
          ) {
            const modbusSlaveIdArrayNew = [...modbusSlaveIdArray];
            modbusSlaveIdArrayNew.push(communicationState.slaveId);
            setModbusSlaveIdArray(modbusSlaveIdArrayNew);
            saveSystemInfoDer(componentId, type, dataAsset);
          } else {
            callSnackbar(
              "error",
              "Modbus Slave Id should be always between 1 - 255"
            );
          }
        } else {
          saveSystemInfoDer(componentId, type, dataAsset);
        }
      } else {
        saveSystemInfoDer(componentId, type, dataAsset);
      }
    } else {
      callSnackbar("error", "Invalid field entires");
    }
  };
  const saveCommunications = (
    componentId,
    type,
    numberOfDeviceIFE,
    dataAsset
  ) => {
    const data = secureArchitectureData?.securedCommunicationArchitecture;
    if (dataAsset?.type === "Circuit Breaker") {
      const { startPoint, endPoint, selectedIp, gatewayAddress } = getTypeRange(
        type,
        "Breakers"
      );
      if (gatewayAddress != selectedIp) {
        if (selectedIp >= startPoint && selectedIp <= endPoint) {
          updateCommunication(componentId, type, numberOfDeviceIFE, dataAsset);
        } else {
          callSnackbar(
            "error",
            `IP address range for ${dataAsset?.type} between ${
              data.filter((item) => item.vlan == "Breakers")[0].hostRange
            }`
          );
        }
      } else {
        callSnackbar(
          "error",
          `IP address and Gateway address both are not same`
        );
      }
    }
    if (["BESS","MBMS","PCS"].includes(dataAsset?.type)) {
      const { startPoint, endPoint, selectedIp, gatewayAddress } = getTypeRange(
        type,
        "BESS"
      );
      if (gatewayAddress != selectedIp) {
        if (selectedIp >= startPoint && selectedIp <= endPoint) {
          updateCommunication(componentId, type, numberOfDeviceIFE, dataAsset);
        } else {
          callSnackbar(
            "error",
            `IP address range for ${dataAsset?.type} between ${
              data.filter((item) => item.vlan == "BESS")[0].hostRange
            }`
          );
        }
      } else {
        callSnackbar(
          "error",
          `IP address and Gateway address both are not same`
        );
      }
    }
    if (dataAsset?.type === "Genset") {
      const { startPoint, endPoint, selectedIp, gatewayAddress } = getTypeRange(
        type,
        "GEN/CHP"
      );
      if (gatewayAddress != selectedIp) {
        if (selectedIp >= startPoint && selectedIp <= endPoint) {
          updateCommunication(componentId, type, numberOfDeviceIFE, dataAsset);
        } else {
          callSnackbar(
            "error",
            `IP address range for ${dataAsset?.type} between ${
              data.filter((item) => item.vlan == "GEN/CHP")[0].hostRange
            }`
          );
        }
      } else {
        callSnackbar(
          "error",
          `IP address and Gateway address both are not same`
        );
      }
    }
    if (dataAsset?.type === "INVERTER") {
      const { startPoint, endPoint, selectedIp, gatewayAddress } = getTypeRange(
        type,
        "PV"
      );
      if (gatewayAddress != selectedIp) {
        if (selectedIp >= startPoint && selectedIp <= endPoint) {
          updateCommunication(componentId, type, numberOfDeviceIFE, dataAsset);
        } else {
          callSnackbar(
            "error",
            `IP address range for ${dataAsset?.type} between ${
              data.filter((item) => item.vlan == "PV")[0].hostRange
            }`
          );
        }
      } else {
        callSnackbar(
          "error",
          `IP address and Gateway address both are not same`
        );
      }
    }
    if (dataAsset?.type === "EV_charging_station") {
      const { startPoint, endPoint, selectedIp, gatewayAddress } = getTypeRange(
        type,
        "EV"
      );
      if (gatewayAddress != selectedIp) {
        if (selectedIp >= startPoint && selectedIp <= endPoint) {
          updateCommunication(componentId, type, numberOfDeviceIFE, dataAsset);
        } else {
          callSnackbar(
            "error",
            `IP address range for ${dataAsset?.type} between ${
              data.filter((item) => item.vlan == "PV")[0].hostRange
            }`
          );
        }
      } else {
        callSnackbar(
          "error",
          `IP address and Gateway address both are not same`
        );
      }
    }
  };
  const checkFilteredIpAddress = (type)=>{
    const systemInfoDerDataNew = [...systemState.systemInfoDER];
   return  systemInfoDerDataNew.filter((DERData) => {
      if (type === "Power Meter") {
        if (DERData.network !== "Power Meter") {
          if (
            DERData.systemSetting.filter(
              (data) => data.ipAddress === communicationState.ipAddress
            ).length
          )
            return DERData;
        }
        return null;
      }
      return null;
    
    });
  }
  const isCommunicationValid = () => {
    const { ipAddress, mask, gatewayAddress, slaveId } = communicationState;
    return (
      isValidIp(ipAddress) &&
      isValidIp(mask) &&
      isValidIp(gatewayAddress) &&
      isValidSlaveID(slaveId)
    );
  };
  const isSameSlaveID = (componentId, type, dataAsset)=>{
    if ( communicationState.slaveId <= 255 && parseInt(communicationState.slaveId) !== 0) {
      const modbusSlaveIdArrayNew = [...modbusSlaveIdArray];
      modbusSlaveIdArrayNew.push(communicationState.slaveId);
      setModbusSlaveIdArray(modbusSlaveIdArrayNew);
      saveSystemInfoDer(componentId, type, dataAsset);
    } else {
      callSnackbar(
        "error",
        "Modbus Slave Id should be always between 1 - 255"
      );
    }
  }
  const updatePMCommunication = (
    componentId,
    type,
    dataAsset
  ) => {
    if (isCommunicationValid()) {
      let filteredIpAddress = [];
      if (dataAsset?.ipAddress !== communicationState.ipAddress) {
       
        filteredIpAddress = checkFilteredIpAddress(type);
      }
      if (filteredIpAddress.length) {
        callSnackbar("error", "Entered duplicate Ipaddress");
      } else if (type === "Power Meter") {
        if (
          checkForModbusDuplicate(communicationState.ipAddress, communicationState.slaveId, dataAsset
          )
        ) {
          callSnackbar(
            "error",
            "Duplicate Modbus Slave ID allowed only if their IPaddress are unique"
          );
        } else if (dataAsset.slaveId !== communicationState.slaveId) {
          isSameSlaveID(componentId, type, dataAsset);
        } else {
          saveSystemInfoDer(componentId, type, dataAsset);
        }
      } else {
        saveSystemInfoDer(componentId, type, dataAsset);
      }
    } else {
      callSnackbar("error", "Invalid field entires");
    }
  };
  
  const savePowerMeter = (componentId, type, equipment, dataAsset) => {
    const data = secureArchitectureData?.securedCommunicationArchitecture;
    const { startPoint, endPoint, selectedIp, gatewayAddress } = getTypeRange(
            type,
            "Meters"
          );
          if (gatewayAddress != selectedIp) {
            if (selectedIp >= startPoint && selectedIp <= endPoint) {
              updatePMCommunication(componentId, type, dataAsset);
            } else {
              callSnackbar(
                "error",
                `IP address range for ${dataAsset?.type} between ${
                  data.filter((item) => item.vlan == "Meters")[0].hostRange
                }`
              );
            }
          } else {
            callSnackbar(
              "error",
              `IP address and Gateway address both are not same`
            );
          }
  };
  const saveSystemInfoDer = (componentId, type, dataAsset) => {
    setLoading(true);
    localStorage.setItem("communicationValidated", true);

    const currentSystemSetting = systemInfoDERData[componentId].systemSetting;

    const currentSystemSettingIndex = currentSystemSetting.findIndex(
      (obj) => obj.type === dataAsset.type
    );

    currentSystemSetting[currentSystemSettingIndex] = {
      type: dataAsset.type,
      ipAddress: communicationState.ipAddress,
      mask: communicationState.mask,
      gatewayAddress: communicationState.gatewayAddress,
      slaveId: parseInt(communicationState.slaveId),
      comments: communicationState.comments,
      systemsPage: JSON.parse(localStorage.getItem("communicationValidated")),
    };

    const jsonBody = [];
    const innerJson = {
      componentId,
      systemSetting: currentSystemSetting,
    };
    jsonBody.push(innerJson);

    apiSession
      .updateSystemInfo(jsonBody)
      .then((response) => {
        const copyDerData = Object.assign({}, systemInfoDERData);
        copyDerData[componentId].systemSetting[
          currentSystemSettingIndex
        ].ipAddress = communicationState.ipAddress;
        copyDerData[componentId].systemSetting[currentSystemSettingIndex].mask =
          communicationState.mask;
        copyDerData[componentId].systemSetting[
          currentSystemSettingIndex
        ].gatewayAddress = communicationState.gatewayAddress;
        copyDerData[componentId].systemSetting[
          currentSystemSettingIndex
        ].slaveId = communicationState.slaveId;
        copyDerData[componentId].systemSetting[
          currentSystemSettingIndex
        ].comments = communicationState.comments;

        setSystemInfoDerData(copyDerData);
        setComponentId((prevState) => ({ ...prevState, [componentId]: false }));
        let modbusSlaveArray = [];
        systemState.systemInfoDER.map((infoDER) => {
          if (infoDER.network === "Circuit Breaker") {
            modbusSlaveArray.push(infoDER.systemSetting[0]?.slaveId);
          }
          return null;
        });
        setModbusSlaveIdArray(modbusSlaveArray);
        resetCommunicationSettings();
        callSnackbar(
          "success",
          `${type} commuincation settings updated successfully`
        );
        setLoading(false);
      })
      .catch((error) => {
        callSnackbar("error", "error in updation");
        setLoading(false);
      });
  };

  // functions for  info table end //

  // functions for  system table start //

  const enableTextBoxSystem = (value) => {
    systemState.systemInfoDER.forEach((infoDER) =>
      setComponentId((prevState) => ({
        ...prevState,
        [infoDER.componentId]: false,
      }))
    );
    systemState.systemCommunication.forEach((sysInfo) =>
      setComponentId((prevState) => ({ ...prevState, [sysInfo.id]: false }))
    );
    setComponentId((prevState) => ({ ...prevState, [value]: true }));

    // dispacth function

    communicationDispatch({
      type: ACTIONS.ENABLE_TEXTBOX_SYSTEM,
      payload: {
        ipAddress: systemCommunicationData[value].ipAddress,
        mask: systemCommunicationData[value].mask,
        gatewayAddress: systemCommunicationData[value].gateway,
        slaveId: systemCommunicationData[value].modbusSlave,
        comments:
          systemCommunicationData[value].comment === null
            ? ""
            : systemCommunicationData[value].comment,
      },
    });
  };

  const saveSystemCommunication = (rowId) => {
    localStorage.setItem("communicationValidated", true);
    let jsonBody = {
      id: rowId,
      ipAddress: communicationState.ipAddress,
      mask: communicationState.mask,
      gateway: communicationState.gatewayAddress,
      comment: communicationState.comments,
      systemsPage: JSON.parse(localStorage.getItem("communicationValidated")),
    };

    setLoading(true);
    apiSession
      .updateSystemSettings(jsonBody)
      .then((response) => {
        callSnackbar(
          "success",
          "System communication settings updated successfully"
        );
        setLoading(false);

        let copySysComData = Object.assign({}, systemCommunicationData);
        copySysComData[rowId].ipAddress = communicationState.ipAddress;
        copySysComData[rowId].mask = communicationState.mask;
        copySysComData[rowId].gateway = communicationState.gatewayAddress;
        copySysComData[rowId].comment = communicationState.comments;

        setSystemCommunicationData(copySysComData);

        setComponentId((prevState) => ({ ...prevState, [rowId]: false }));
        resetCommunicationSettings();
      })
      .catch(() => {
        callSnackbar("error", "Error in system communication setting updation");
        setLoading(false);
      });
  };

  // functions for  system table end //

  //---  validation functions start ---- //

  const validateSystemsPage = (systemsPage) => {
    let jsonBody = {
      projectId: sessionStorage.getItem("projectId"),
      systemsPage,
    };

    setLoading(true);
    apiSession
      .validateSystemSettings(jsonBody)
      .then(() => {
        setLoading(false);
        document.getElementById("dialog-communication-validation").open = true;
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 400) {
            callSnackbar(`error`, `${error.response.data.message}`);
          }
        } else {
          callSnackbar("error", "Duplicate values found");
        }
        localStorage.setItem("communicationValidated", true);
        setLoading(false);
      });
  };

  const onValidateCommunicationSetting = () => {
    const cbTypes = ["Circuit Breaker"];
    const derTypes = [
      "Utility",
      "Genset",
      "BESS",
      "Inverter",
      "CHP",
      "EV_charging_station",
    ];

    const CBIP = systemState.systemInfoDER
      .filter((data) => cbTypes.includes(data.network) && data.ipAddress)
      .map((data) => ({
        firstOctet: data.ipAddress.split(".")[0],
        secondOctet: data.ipAddress.split(".")[1],
        thirdOctet: data.ipAddress.split(".")[2],
        fourthOctet: data.ipAddress.split(".")[3],
      }));

    const CBMASK = systemState.systemInfoDER
      .filter((data) => cbTypes.includes(data.network) && data.mask)
      .map((data) => ({
        firstOctet: data.mask.split(".")[0],
        secondOctet: data.mask.split(".")[1],
        thirdOctet: data.mask.split(".")[2],
        fourthOctet: data.mask.split(".")[3],
      }));

    const DERIP = systemState.systemInfoDER
      .filter((data) => derTypes.includes(data.network) && data.ipAddress)
      .map((data) => ({
        firstOctet: data.ipAddress.split(".")[0],
        secondOctet: data.ipAddress.split(".")[1],
        thirdOctet: data.ipAddress.split(".")[2],
        fourthOctet: data.ipAddress.split(".")[3],
      }));

    const DERMASK = systemState.systemInfoDER
      .filter((data) => derTypes.includes(data.network) && data.mask)
      .map((data) => ({
        firstOctet: data.mask.split(".")[0],
        secondOctet: data.mask.split(".")[1],
        thirdOctet: data.mask.split(".")[2],
        fourthOctet: data.mask.split(".")[3],
      }));

    const SYSTEMIP = systemState.systemCommunication
      .filter((data) => data.ipAddress)
      .map((data) => ({
        firstOctet: data.ipAddress.split(".")[0],
        secondOctet: data.ipAddress.split(".")[1],
        thirdOctet: data.ipAddress.split(".")[2],
        fourthOctet: data.ipAddress.split(".")[3],
      }));

    const SYSTEMMASK = systemState.systemCommunication
      .filter((data) => data.mask)
      .map((data) => ({
        firstOctet: data.mask.split(".")[0],
        secondOctet: data.mask.split(".")[1],
        thirdOctet: data.mask.split(".")[2],
        fourthOctet: data.mask.split(".")[3],
      }));

    const ALLIP = [...CBIP, ...DERIP, ...SYSTEMIP];
    const ALLMASK = [...CBMASK, ...DERMASK, ...SYSTEMMASK];

    const ALL_THIRDOCTET_IPSAME = ALLIP.every(
      (value) => value["thirdOctet"] === ALLIP[0]["thirdOctet"]
    );

    //devices are in same IP range
    const SAME_IPRANGE = ALLMASK.every(
      (value) =>
        value["firstOctet"] === "255" &&
        value["secondOctet"] === "255" &&
        value["thirdOctet"] === "255" &&
        value["fourthOctet"] >= "0" &&
        value["fourthOctet"] <= "255"
    );

    //devices are in diff IP range
    const DIFF_IPRANGE = ALLMASK.every(
      (value) =>
        value["firstOctet"] === "255" &&
        value["secondOctet"] === "255" &&
        value["thirdOctet"] >= "0" &&
        value["thirdOctet"] <= "255" &&
        value["fourthOctet"] >= "0" &&
        value["fourthOctet"] <= "255"
    );
    //page level validation in system setting page
    //   systemState.systemInfoDER.forEach((obj) => {
    //     if(obj.systemSetting && obj.systemSetting.length > 0){
    //     let systemSetting = obj.systemSetting[0];

    //     let  ipAddress = systemSetting.ipAddress;
    //     let  gatewayAddress = systemSetting.gatewayAddress;

    //     if(ipAddress != gatewayAddress){
    //          console.log(`IP Address: ${ipAddress}`);
    //     console.log(`Gateway Address: ${gatewayAddress}`);
    //     }
    //     }
    // });

    if (ALL_THIRDOCTET_IPSAME) {
      if (SAME_IPRANGE) {
        localStorage.setItem("communicationValidated", false);
        validateSystemsPage(false);
      } else {
        localStorage.setItem("communicationValidated", true);
        callSnackbar("error", 'Update mask values to "255.255.255.X"');
      }
    } else {
      if (DIFF_IPRANGE) {
        localStorage.setItem("communicationValidated", false);
        validateSystemsPage(false);
      } else {
        localStorage.setItem("communicationValidated", true);
        callSnackbar("error", 'Update mask values to "255.255.X.Y"');
      }
    }
  };

  const onButtonClickValidated = () => {
    let { history } = props || {};

    history.push({
      pathname: "/bom",
    });
    // navigate('/bom', { replace: true });
    // return <Redirect to="/bom" />
  };

  const isValidIp = (value) =>
    /^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/.test(value)
      ? true
      : false;
  const isValidSlaveID = (value) =>
    /^(25[0-5]*|2[0-4][0-9]*|1[0-9][0-9]*|[1-9]?[0-9]?)$/.test(value)
      ? true
      : false;

  // ---  validation functions end ---- //

  //---  snackbar functions start ---- //

  const callSnackbar = (type, message) => {
    setSnackBar({ type, message });
    document.getElementById("custom-systempage").open = true;
  };

  // ---  snackbar functions end ---- //
  const isCBConnected = (data) => {
    const uniqueNetworks = [];
    data.forEach((item) => {
      const network = item.network;
      if (!uniqueNetworks.includes(network)) {
        uniqueNetworks.push(network);
      }
    });
    return uniqueNetworks;
  };
  // JSX CODE

  return (
    <>
      {siteType ? (
        <div className="row">
          {siteType ? (
            <div className="col-md-12 mt-1">
              <div
                className={
                  props?.siteCreated ||
                  props?.projectStatusData?.projectStatus === "COMPLETED"
                    ? "se-block-disabled row"
                    : !UserScope.access.saveSystemInfo
                    ? "se-block-disabled row"
                    : "row"
                }
              >
                <div
                  className="col-md-12 mt-1"
                  style={
                    siteType === embLabels.GENSET_PVBESS_A2E
                      ? { userSelect: "none", pointerEvents: "none" }
                      : {}
                  }
                >
                  {/* CIRCUIT BREAKER TABLE START */}
                  {(siteType === embLabels.ISLANDABLE ||
                    siteType === embLabels.GRID_CONNECTED ||
                    (siteType === embLabels.ISLANDABLE_GSX &&
                      architectureType === embLabels.PV_GENSET_ATS) ||
                    (siteType === embLabels.GRID_CONNECTED_GSX &&
                      architectureType === embLabels.PV_BESS_NR) ||
                    (siteType === embLabels.PV_HYBRIDIZATION &&
                      clientType === embLabels.GENERIC_PROJECT)) && (
                    <div className="row">
                      <div className="col-md-12">
                        <>
                          {isCBConnected(systemState.systemInfoDER).includes(
                            "Circuit Breaker"
                          ) && (
                            <>
                              <span className="se-font-14px-bold se-life-green">
                                {" "}
                                Circuit Breaker Communication Settings (Device
                                Network)
                              </span>
                              <TableHeading />
                            </>
                          )}
                        </>
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <tbody>
                              {systemState.systemInfoDER.map(
                                (data) =>
                                  data.network === "Circuit Breaker" && (
                                    <SystemSettingTable
                                      key={data.componentId}
                                      data={data}
                                      siteType={siteType}
                                      architectureType={architectureType}
                                      editedAssetType={editedAssetType}
                                      systemInfoDERData={systemInfoDERData}
                                      componentId={componentId}
                                      enableTextBox={enableTextBox}
                                      communicationState={communicationState}
                                      handleChange={handleChange}
                                      isValidIp={isValidIp}
                                      isValidSlaveID={isValidSlaveID}
                                      cancelUpdate={cancelUpdate}
                                      saveCommunications={saveCommunications}
                                      saveCommunicationsDer={
                                        saveCommunicationsDer
                                      }
                                      savePowerMeter={savePowerMeter}
                                    />
                                  )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                  {/*CONTACTOR TABLE START */}
                  {(siteType === embLabels.ISLANDABLE ||
                    siteType === embLabels.GRID_CONNECTED ||
                    (siteType === embLabels.ISLANDABLE_GSX &&
                      architectureType === embLabels.PV_GENSET_ATS) ||
                    (siteType === embLabels.GRID_CONNECTED_GSX &&
                      architectureType === embLabels.PV_BESS_NR) ||
                    (siteType === embLabels.PV_HYBRIDIZATION &&
                      clientType === embLabels.GENERIC_PROJECT)) && (
                    <div className="row">
                      <div className="col-md-12">
                        <>
                          {isCBConnected(systemState.systemInfoDER).includes(
                            "Contactor"
                          ) && (
                            <>
                              <span className="se-font-14px-bold se-life-green">
                                {" "}
                                Contactor Communication settings
                              </span>
                              <TableHeading />
                            </>
                          )}
                        </>
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <tbody>
                              {systemState.systemInfoDER.map(
                                (data) =>
                                  data.network === "Contactor" && (
                                    <SystemSettingTable
                                      key={data.componentId}
                                      data={data}
                                      siteType={siteType}
                                      architectureType={architectureType}
                                      editedAssetType={editedAssetType}
                                      systemInfoDERData={systemInfoDERData}
                                      componentId={componentId}
                                      enableTextBox={enableTextBox}
                                      communicationState={communicationState}
                                      handleChange={handleChange}
                                      isValidIp={isValidIp}
                                      isValidSlaveID={isValidSlaveID}
                                      cancelUpdate={cancelUpdate}
                                      saveCommunications={saveCommunications}
                                      saveCommunicationsDer={
                                        saveCommunicationsDer
                                      }
                                      savePowerMeter={savePowerMeter}
                                    />
                                  )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* POWER METER TABLE START */}
                  {([embLabels.GRID_CONNECTED,embLabels.ISLANDABLE].includes(siteType) ||
                    (siteType === embLabels.ISLANDABLE_GSX &&
                      architectureType === embLabels.PV_GENSET_ATS) ||
                    (siteType === embLabels.GRID_CONNECTED_GSX &&
                      architectureType === embLabels.PV_BESS_NR) ||
                    (clientType === embLabels.A2E_PROJECT &&
                      siteType === embLabels.GENSET_PVBESS_A2E)) && (
                    <div className="row">
                      <div className="col-md-12">
                        <span className="se-font-14px-bold se-life-green">
                          Power Meter Communication Settings
                        </span>
                        {[
                          embLabels.ISLANDABLE_GSX,
                          embLabels.GRID_CONNECTED_GSX,
                          embLabels.PV_HYBRIDIZATION,
                          embLabels.GRID_CONNECTED,
                          embLabels.GENSET_PVBESS_A2E,
                        ].includes(siteType) && <TableHeading />}
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <tbody>
                              {systemState.systemInfoDER.map(
                                (data) =>
                                  data.network === "Power Meter" && (
                                    <SystemSettingTable
                                      key={data.componentId}
                                      data={data}
                                      siteType={siteType}
                                      architectureType={architectureType}
                                      editedAssetType={editedAssetType}
                                      systemInfoDERData={systemInfoDERData}
                                      componentId={componentId}
                                      enableTextBox={enableTextBox}
                                      communicationState={communicationState}
                                      handleChange={handleChange}
                                      isValidIp={isValidIp}
                                      isValidSlaveID={isValidSlaveID}
                                      cancelUpdate={cancelUpdate}
                                      saveCommunications={saveCommunications}
                                      saveCommunicationsDer={
                                        saveCommunicationsDer
                                      }
                                      savePowerMeter={savePowerMeter}
                                    />
                                  )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* DER TABLE START */}

                  <div className="row">
                    <div className="col-md-12">
                      <span className="se-font-14px-bold se-life-green">
                        DER Communication Settings (Device Network)
                      </span>
                      <TableHeading />
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <tbody>
                            {systemState.systemInfoDER.map(
                              (data) =>
                                (data.network === "Utility" ||
                                  data.network === "Genset" ||
                                  data.network === "BESS" ||
                                  data.network === "Inverter" ||
                                  data.network === "CHP" ||
                                  data.network === "Hybrid" ||
                                  data.network === "EV_charging_station" ||
                                  data.network === "Load" ||
                                  data.network === "LOAD") && (
                                  <SystemSettingTable
                                    key={data.componentId}
                                    data={data}
                                    siteType={siteType}
                                    architectureType={architectureType}
                                    editedAssetType={editedAssetType}
                                    systemInfoDERData={systemInfoDERData}
                                    componentId={componentId}
                                    enableTextBox={enableTextBox}
                                    communicationState={communicationState}
                                    handleChange={handleChange}
                                    isValidIp={isValidIp}
                                    isValidSlaveID={isValidSlaveID}
                                    cancelUpdate={cancelUpdate}
                                    saveCommunications={saveCommunications}
                                    saveCommunicationsDer={
                                      saveCommunicationsDer
                                    }
                                    savePowerMeter={savePowerMeter}
                                  />
                                )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {/* System communication settings (Device Network) start */}
                  <div className="row">
                    <div className="col-md-12">
                      <span className="se-font-14px-bold se-life-green">
                        System Communication Settings (Device Network)
                      </span>
                      <TableHeading />
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <tbody>
                            {systemState.systemCommunication.map(
                              (data) =>
                                (data.name === "DER Maintenance 1" ||
                                  data.name === "DER Maintenance 2" ||
                                  data.name === "DER Maintenance 3" ||
                                  data.name === "PLC - ETH2") && (
                                  <SystemDeviceTable
                                    key={data.id}
                                    data={data}
                                    componentId={componentId}
                                    systemCommunicationData={
                                      systemCommunicationData
                                    }
                                    enableTextBoxSystem={enableTextBoxSystem}
                                    communicationState={communicationState}
                                    handleChange={handleChange}
                                    isValidIp={isValidIp}
                                    cancelUpdate={cancelUpdate}
                                    saveSystemCommunication={
                                      saveSystemCommunication
                                    }
                                  />
                                )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {/* System communication settings (Device Network) end */}

                  {/* System communication settings (Supervision Network) start*/}
                  <div className="row">
                    <div className="col-md-12">
                      <span className="se-font-14px-bold se-life-green">
                        System Communication Settings (Supervision Network)
                      </span>
                      <TableHeading />
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <tbody>
                            {systemState.systemCommunication.map(
                              (data) =>
                                (data.name === "Supervision Maintenance 1" ||
                                  data.name === "Supervision Maintenance 2" ||
                                  data.name === "Supervision Maintenance 3" ||
                                  data.name === "PLC - ETH1" ||
                                  data.name === "IPC - Port B (local)" ||
                                  data.name === "IPC - Port A (local)" ||
                                  data.name === "IPC - Port A" ||
                                  data.name === "Magelis") && (
                                  <SystemSupervisionTable
                                    key={data.id}
                                    data={data}
                                    systemCommunicationData={
                                      systemCommunicationData
                                    }
                                    componentId={componentId}
                                    enableTextBoxSystem={enableTextBoxSystem}
                                    communicationState={communicationState}
                                    handleChange={handleChange}
                                    isValidIp={isValidIp}
                                    cancelUpdate={cancelUpdate}
                                    saveSystemCommunication={
                                      saveSystemCommunication
                                    }
                                  />
                                )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {/* System communication settings (Supervision Network) end*/}
                </div>
              </div>

              {/* on button clicked ,onValidateCommunicationSetting*/}

              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right p-0">
                  <se-snackBar
                    id="custom-systempage"
                    type={snackBar.type}
                    message={snackBar.message}
                    icon="information_stroke"
                    duration="3000"
                  ></se-snackBar>
                  {clientType !== "A2E_PROJECT" && (
                    <se-button
                      option="flat"
                      color="primary"
                      padding="large"
                      onClick={onValidateCommunicationSetting}
                      className={`loading?"disabled":""`}
                    >
                      Validate
                    </se-button>
                  )}
                </div>
              </div>
              <se-dialog
                id="dialog-communication-validation"
                can-backdrop="false"
                size="small"
              >
                <se-dialog-header>
                  Communication validation status
                </se-dialog-header>
                <se-dialog-content
                 icon="information_circle"
                 size="nano"
                >
                  Communication is validated
                </se-dialog-content>
                <se-dialog-footer>
                  <se-button onClick={onButtonClickValidated}>OK</se-button>
                </se-dialog-footer>
              </se-dialog>
            </div>
          ) : (
            <se-loading loading={loading} full-page="true"></se-loading>
          )}
        </div>
      ) : (
        <se-loading loading={loading} full-page="true"></se-loading>
      )}
    </>
  );
};

export default withRouter(SystemPage);
