import React, { useContext } from "react";
import embLabels from "../../config/emb-labels-en.json";
import gensetImage from "../../images/Genset-image-SE.jpg";
import { AssetBlockContext } from "../CustomHooks/ContextApi";
import isTouchDevice from "../SE-Component/Tooltip";
import ACTIONS from "./Design_Asset_Actions.json";
import Tooltip from "../../utils/Tooltip";

const EvCluster = () => {
  const {
    CBEVList: [{ uiData }],
    CBEVListDispatch,
    siteType,
    assetDetailsDisplay,
    configData, 
    handleEvChange,
    updateEvConfig,
    defaultsMinMax,
    setAssetSelection,
    removeEv,
  } = useContext(AssetBlockContext);

  const setAssetHandler = () =>
    setAssetSelection(embLabels.EV_CHARGING_STATION);

  const evChangeHandler = e => {
    handleEvChange(e);
  };

  return (
    <div name="EV" className="col-md-12 p-0">
      <div className="col-md-12 pt-2 se-white-background">
        <div className="row">
          <div className="col-md-8 col-sm-8 col-lg-8 pb-2 ">
            <span>
              <se-icon
                color="primary"
                className="se-icon icon_button se-life-green"
                size="medium"
              >
                {" "}
                electric_vehicle_terminal
              </se-icon>
              <span className="ml-2 bold align-top line-height-30px text-break">
                EV cluster
              </span>
            </span>
          </div>
          <div className="col-md-4 col-sm-4 col-lg-4">
            <span className="float-right">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={removeEv}
              >
                Remove
              </button>
            </span>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-sm-12 pt-2 pb-1 se-grey-background se-border">
        <div
          className="row"
          style={assetDetailsDisplay.manufacturer ? {} : { display: "none" }}
        >
          <div className="col-md-3">
            {assetDetailsDisplay.imageLink === null ? (
              assetDetailsDisplay.manufacturer ===
              "STANDARD GENSET TEMPLATE" ? (
                <img
                  src={gensetImage}
                  className="asset-image-display"
                  alt={assetDetailsDisplay.manufacturer}
                />
              ) : (
                <se-icon color="primary" size="large">
                  electric_vehicle_terminal
                </se-icon>
              )
            ) : (
              <se-icon color="primary" size="large">
                electric_vehicle_terminal
              </se-icon>
            )}
          </div>

          <div className="col-md-9">
            <div className="row">
              <div className="col-md-12 bold ml-0">
                {assetDetailsDisplay.manufacturer}
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 text-break">
                {assetDetailsDisplay.communicationModel}
              </div>
              <div className="col-md-4 text-right">
                <Tooltip
                  position="left"
                  iconColor="secondary"
                  information={assetDetailsDisplay.communicationModel}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="row"
          style={assetDetailsDisplay.manufacturer ? { display: "none" } : {}}
        >
          <div className="ml-2 mb-2 asset-selection"> No assets selected</div>
        </div>
        <div className="row">
          <div className=" col-md col-sm col-lg text-center">
            <se-button option="outline" size="small" onClick={setAssetHandler} disabled="true">
              Asset Selection
            </se-button>
          </div>
        </div>
      </div>
      <div style={assetDetailsDisplay.manufacturer ? {} : { display: "none" }}>
        <div className="col-md-12 mt-2 se-white-background align-middle">
          <div className="row">
            <label className=" col-md-6 bold mt-1 pl-3 se-font-14px">
              Asset Settings
            </label>
            <div className="col-md-6 mt-1 mb-1 text-right">
              <button
                type="button"
                className="btn btn-secondary btn-sm se-btn-primary"
                onClick={updateEvConfig}
                // disabled="true"
              >
                <se-icon size="nano">action_save</se-icon>
                <span>&nbsp;</span>Save
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-12 pb-2 pt-2 se-grey-background">
        {siteType === embLabels.GRID_CONNECTED && (
          <></>
            // <div className="form-group mb-1 mt-2">
            //   <div className="custom-control custom-checkbox">
            //     <input
            //       type="checkbox"
            //       className="custom-control-input asCompatible"
            //       name="pmRequired"
            //       id="pmRequiredValidation"
            //       onChange={() =>
            //         CBEVListDispatch({ type: ACTIONS.EV_ASSET_PM_REQUIRED })
            //       }
            //       value={uiData.pmRequired}
            //       checked={uiData.pmRequired}
            //       disabled="true"
            //     />
            //     <label
            //       className="custom-control-label line-height-25"
            //       htmlFor="pmRequiredValidation"
            //     >
            //       Enable resource energy monitoring
            //     </label>
            //     <Tooltip 
            //         position ="left"
            //         iconColor ="secondary"
            //         information ="If the checkbox in not enabled, PM won't be the part of EV"
            //         />
            //   </div>
            // </div>
          )}
          {siteType === embLabels.GRID_CONNECTED && (
            <div className="form-group mb-1 mt-2">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input asCompatible"
                  name="cBRequired"
                  id="cBRequiredValidation"
                  onChange={() =>CBEVListDispatch({ type: ACTIONS.EV_ASSET_CB_REQUIRED })}
                  value={uiData.cbRequired}
                  checked={uiData.cbRequired}
                  // disabled="true"
                />
                <label
                  className="custom-control-label line-height-25"
                  htmlFor="cBRequiredValidation"
                >
                  Enable resource connection management
                </label>
                <Tooltip 
                    position ="left"
                    iconColor ="secondary"
                    information ="If the checkbox in not enabled, Circuit Breaker won't be the part of EV"
                    />
              </div>
            </div>
          )}
          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">
              Name <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput1"
              placeholder="Name"
              name="name"
              value={configData.configData.name}
              onChange={evChangeHandler}
            />
            <div
              className={`invalid-feedback-custom ${
                configData.configData.name === "" ? "" : "hidden"
              }`}
            >
              {embLabels.nameRequired}
            </div>
          </div>
          <div
            className="invalid-feedback-custom"
            style={configData.configData.name !==""  && !/^[a-zA-Z0-9-_ ]+$/.test(configData?.configData.name )? {} : { display: "none" }}
          >
            {embLabels.notallowedAlphanumericValues}
          </div>
          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">Description</label>
            <input
              type="text"
              autoComplete="none"
              className="form-control form-control-sm"
              id="exampleFormControlInput1"
              placeholder="Description"
              name="description"
              value={configData.configData.description}
              onChange={evChangeHandler}
            />
          </div>
          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">
              Maximum load power (kW){" "}
              <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput1"
              placeholder="Maximum load power"
              maxLength={4}
              name="maximum_available_power"
              value={configData.configData.maximum_available_power}
              onChange={evChangeHandler}
            />
            <div
              className={`invalid-feedback-custom ${
                configData.configData.maximum_available_power === ""
                  ? ""
                  : "hidden"
              }`}
            >
              Maximum load power (kW) is required.
            </div>

            {/* {configData.configData.maximum_available_power !== "" &&
              (parseInt(configData.configData.maximum_available_power) <
                defaultsMinMax.maxPowerEvMin ||
                parseInt(configData.configData.maximum_available_power) >
                  defaultsMinMax.maxPowerEvMax) && (
                <div className="invalid-feedback-custom">
                  Maximum load power should be in range (
                  {defaultsMinMax.maxPowerEvMin}-{defaultsMinMax.maxPowerEvMax}{" "}
                  kW).
                </div>
              )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvCluster;
