// import ACTIONS from "../Design_Asset_Actions.json";
export const ACTIONS = {
  FETCH_SUCCESS: "FETCH_SUCCESS",
  ENABLE_TEXTBOX: "ENABLE_TEXTBOX",
  ENABLE_TEXTBOX_SYSTEM: "ENABLE_TEXTBOX_SYSTEM",
  RESET_COMMUNICATION: "RESET_COMMUNICATION",
};

const initialSystemState = {
  systemInfoDER: [],
  systemCommunication: [],
};

const systemReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_SUCCESS:
      return {
        systemInfoDER: action.payload.systemInfoDER,
        systemCommunication: action.payload.systemCommunication,
      };

    default:
      return state;
  }
};


export { initialSystemState, systemReducer }