import moment from "moment-timezone";
import React, { useState } from "react";
const ProgressBar = ({ createdOn, data,isShow,isCompleted,statusName }) => {
 
const getDataFormat =(date)=>{
  let formatDate = moment(
    new Date(date + " UTC").toString()
  ).format("YYYY/MM/DD");
  if(formatDate === undefined){
    return "";
  }else{
    return formatDate;
  }

}
  return (
    <>
    
    <div
        className={`progress-custom   ${
          data?.projectStatus === "COMPLETED"
            ? "progress-custom-before-completed progress-custom-after-completed"
            : data?.projectStatus === "IN_PROGRESS"
            ? "progress-custom-before-inprogress progress-custom-after-inprogress"
            : "progress-custom-before-not-completed progress-custom-after-not-completed"
        }`}
        
      >
        <div className={`bar-info   ${data?.projectStatus === "IN_PROGRESS"?"in-progress-bar  ":" "}`}>
          {isShow && <div className="mob-inprogress"> Created on {getDataFormat(createdOn)}</div> }
          {data?.projectStatus === "IN_PROGRESS" && (
            <>
            <span>In Progress </span>{' '}
            <span>Last Modified Date {getDataFormat(data?.lastModificationDate)}</span>
            </>
          )}
          {data?.projectStatus === "COMPLETED" ? (
            <div className={`${data?.projectStatus === "COMPLETED"?"mob-in-progress-bar ":" "}`}>
            <span >{statusName}</span>
            <span >Last Modified Date {getDataFormat(data?.lastModificationDate)}</span>
            </div>
          ):(!isShow && data?.projectStatus !== "IN_PROGRESS" &&<div className={`text-center ${data?.projectStatus !== "IN_PROGRESS"?"d-none  ":""}`}>To Be Completed</div>)}
        </div>
      </div>
    </>
  );
};
export default ProgressBar;
