import React from "react";
import { useContext } from "react";
import embLabels from "../../config/emb-labels-en.json";
import { DesignProgramContext } from "../CustomHooks/ContextApi";
import isTouchDevice from "../SE-Component/Tooltip";
import { useSelector } from "react-redux";
const BessManagement = () => {
    
    const { 
      siteType,
      handlePortableGenset,
      isPortableGenset,
      portableGensetSelection,
      isPortableGensetSelection,
      gensetLSIEnable,
    } =useContext(DesignProgramContext);
    const tooltipData = useSelector(state => state?.tooltipData?.tooltipData);
  return (
    <>
      {siteType === embLabels.GRID_CONNECTED_GSX ? (
        <>
          <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input asCompatible"
                name="batteryBufferingExport"
                id="customControlValidation22"
                checked={isPortableGenset}
                onChange={handlePortableGenset}
              />
              <label
                className="custom-control-label line-height-25"
                htmlFor="customControlValidation22"
              >
                Portable genset connection
              </label>
              <span className="se-block-enabled">
              <se-tooltip
                action={
                  [embLabels.GRID_CONNECTED_GSX].includes(siteType)
                    ? isTouchDevice()
                      ? "click"
                      : "hover"
                    : ""
                }
                position="right"
              >
                <se-icon option="raised" color="secondary" slot="tooltip">
                  information_circle
                </se-icon>
                <se-tooltip-content>
                  {/* Emergency Genset Connection - Keep the PV curtailed, & BESS
                  stopped */}
                  {tooltipData?.portableGensetConnection?.data}
                </se-tooltip-content>
              </se-tooltip>
              </span>
            </div>
          </div>
          { isPortableGenset && <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 mt-2">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                <span className="bold">
                  Detection method for portable genset connection:
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2 ">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="portableGenset1"
                    name="portableGenset"
                    className="custom-control-input islandableRadio  asCompatible"
                    checked={isPortableGensetSelection === "HARDWIRED"}
                    onChange={portableGensetSelection}
                    disabled={""}
                    value ="HARDWIRED"
                  />
                  <label
                    className="custom-control-label line-height-25px"
                    htmlFor="portableGenset1"
                  >
                    Hardwired signal from transfer switch
                  </label>
                  <span className="se-block-enabled">
                  <se-tooltip
                action={
                  [embLabels.GRID_CONNECTED_GSX].includes(siteType)
                    ? isTouchDevice()
                      ? "click"
                      : "hover"
                    : ""
                }
                position="right"
              >
                <se-icon option="raised" color="secondary" slot="tooltip">
                  information_circle
                </se-icon>
                <se-tooltip-content>
                "Islanding detected with a Hardwired signal from manual transfer switch" 
                </se-tooltip-content>
                   </se-tooltip>
                   </span>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2 ">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="portableGenset2"
                    name="portableGenset"
                    className="custom-control-input islandableRadio asCompatible"
                    checked={isPortableGensetSelection === "POWER_METER"}
                    onChange={portableGensetSelection}
                    // style={
                    //   false? { cursor: "not-allowed" }
                    //     : { cursor: "pointer" }
                    // }
                    disabled={""}
                    value="POWER_METER"
                  />
                  <label
                    className="custom-control-label line-height-25px"
                    htmlFor="portableGenset2"
                  >
                    Power meter measurement
                  </label>
                  <span className="se-block-enabled">
                  <se-tooltip
                action={
                  [embLabels.GRID_CONNECTED_GSX].includes(siteType)
                    ? isTouchDevice()
                      ? "click"
                      : "hover"
                    : ""
                }
                position="right"
              >
                <se-icon option="raised" color="secondary" slot="tooltip">
                  information_circle
                </se-icon>
                <se-tooltip-content>
                  "Islanding detected with measurements from the power meter installed in the utility side of the manual transfer switch "
                </se-tooltip-content>
              </se-tooltip>
              </span>
                </div>
              </div>
            </div>
          </div>}
        </>
      ) : (
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-1">
          <div className="dropdown-divider"></div>

          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputEmail11"
                  className="col-sm-5 col-form-label"
                >
                  Maximum SoC of the battery(%)
                  <span className="se-mandatory">*</span>
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    autoComplete="none"
                    className="form-control form-control-md"
                    name="soc_max"
                    placeholder="Maximum SoC of the battery(%)"
                    maxLength="3"
                    // value={this.state.BESS.soc_max}
                    //onChange={e => this.handleChangeBESS(e)}
                    disabled
                  />
                  <div
                    className="invalid-feedback-custom"
                    // style={
                    //   this.state.BESS.soc_max.toString() !== ""
                    //     ? { display: "none" }
                    //     : {}
                    // }
                  >
                    Maximum SoC of the battery(%) is required.
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputEmail11"
                  className="col-sm-5 col-form-label"
                >
                  Minimum SoC of the battery(%)
                  <span className="se-mandatory">*</span>
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    autoComplete="none"
                    className="form-control form-control-md"
                    name="soc_min"
                    placeholder="Minimum SoC of the battery(%)"
                    maxLength="3"
                    //value={this.state.BESS.soc_min}
                    //onChange={e => this.handleChangeBESS(e)}
                    disabled
                  />
                  <div
                    className="invalid-feedback-custom"
                    // style={
                    //   this.state.BESS.soc_min.toString() !== ""
                    //     ? { display: "none" }
                    //     : {}
                    // }
                  >
                    Minimum SoC of the battery(%) is required.
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputEmail11"
                  className="col-sm-5 col-form-label"
                >
                  Period of BESS (Day)
                  <span className="se-mandatory">*</span>
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    autoComplete="none"
                    className="form-control form-control-md"
                    name="cycle_period"
                    placeholder="Period of BESS (Day)"
                    maxLength="3"
                    //value={this.state.BESS.cycle_period}
                    //onChange={e => this.handleChangeBESS(e)}
                  />
                  <div
                    className="invalid-feedback-custom"
                    // style={
                    //   this.state.BESS.cycle_period.toString() !== ""
                    //     ? { display: "none" }
                    //     : {}
                    // }
                  >
                    Period of BESS (Day) is required.
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputEmail11"
                  className="col-sm-5 col-form-label"
                >
                  SoC Hysteresis(%)
                  <span className="se-mandatory">*</span>
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    autoComplete="none"
                    className="form-control form-control-md"
                    name="soc_hysteresis"
                    placeholder="SoC Hysteresis(%)"
                    maxLength="3"
                    // value={this.state.BESS.soc_hysteresis}
                    //onChange={e => this.handleChangeBESS(e)}
                  />
                  <div
                    className="invalid-feedback-custom"
                    // style={
                    //   this.state.BESS.soc_hysteresis.toString() !==
                    //   ""
                    //     ? { display: "none" }
                    //     : {}
                    // }
                  >
                    SoC Hysteresis(%) is required.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-1">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputEmail11"
                  className="col-sm-5 col-form-label"
                >
                  SoC Emergency(%)
                  <span className="se-mandatory">*</span>
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    autoComplete="none"
                    className="form-control form-control-md"
                    name="soc_emergency"
                    placeholder="SoC Emergency(%)"
                    maxLength="3"
                    // value={this.state.BESS.soc_emergency}
                    //onChange={e => this.handleChangeBESS(e)}
                  />
                  <div
                    className="invalid-feedback-custom"
                    // style={
                    //   this.state.BESS.soc_emergency.toString() !==
                    //   ""
                    //     ? { display: "none" }
                    //     : {}
                    // }
                  >
                    SoC Emergency(%) is required.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputEmail11"
                  className="col-sm-5 col-form-label"
                >
                  SoC Essential(%)
                  <span className="se-mandatory">*</span>
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    autoComplete="none"
                    className="form-control form-control-md"
                    name="soc_essential"
                    placeholder="SoC Essential(%)"
                    maxLength="3"
                    // value={this.state.BESS.soc_essential}
                    //onChange={e => this.handleChangeBESS(e)}
                  />
                  <div
                    className="invalid-feedback-custom"
                    // style={
                    //   this.state.BESS.soc_essential.toString() !==
                    //   ""
                    //     ? { display: "none" }
                    //     : {}
                    // }
                  >
                    SoC Essential(%) is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BessManagement;
