import React from 'react';
import { withRouter } from 'react-router-dom';
import ProjectBackgroundImage from '../images/EMB.png';
const privacyPolicy = "https://www.se.com/ww/en/about-us/legal/data-privacy.jsp";

class RegistrationLayout extends React.Component {
    

    render() {
        const src= "url("+ProjectBackgroundImage+")";
        return (
            <se-container position="absolute" direction="column">
                <se-header app-title="Microgrid Build">
                    
                <se-sidemenu id="app2-sidemenu">
                    
                    <se-sidemenu-item item="Help & Examples">
                    </se-sidemenu-item>
                    <se-sidemenu-item item="About">
                    <se-about app-title="Microgrid Build" version={process.env.REACT_APP_VERSION} image-url={src}>
                    </se-about>
                    </se-sidemenu-item>
                    <se-sidemenu-item item="Legal">
                    <div className="col-md-12">
                        <div className="border mt-2 p-2 legal-border">
                            <h4>Schneider Data Privacy Policy</h4>
                            <a href={privacyPolicy} target="_blank" rel="noopener noreferrer" title="Legal Policy">
                                <h5>{privacyPolicy}</h5>
                            </a>
                        </div>   
                    </div>
                    </se-sidemenu-item>
                    <se-sidemenu-item item="Contact">
                    </se-sidemenu-item>
                </se-sidemenu>
                </se-header>
                <se-tabbar color="primary">
                </se-tabbar>
                {this.props.children}
            </se-container>
        );
    }
}

export default withRouter(RegistrationLayout);
