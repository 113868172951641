const API_CONFIG = {
  baseUrl: process.env.REACT_APP_BACKEND_API_URL,
  endpoints: {
    getScope: "auth/getScope",
    projectList: "auth/projects",
    projectTypes: "auth/projectTypes",
    secureArchitectureData: "auth/securedArchitecture",
    getToolTip: "auth/getToolTip",
    getProject: "auth/project",
    getProjectImage: "auth/getProjectImage",
    uploadProjectImage: "auth/uploadImage",
    updateProject: "auth/project",
    getAssetDefaultValues: "auth/getDefaultValues",
    useCase: "auth/useCase",
    components: "auth/component",
    getAssetList: "auth/getAssetCatalogue",
    generatePLCProgram: "auth/plcPlugin",
    downloadPLCProgram: "auth/downloadFiles",
    downloadHMI: "auth/downloadHmi",
    downloadMoxa: "/auth/moxa",
    downloadConnexium: "/auth/connexium",
    deleteProject: "auth/project",
    getUsers: "auth/getUsers",
    getRoles: "/auth/getRoles",
    addUsersToProject: "auth/addUser",
    removeUserFromProject: "auth/projectUsers",
    removeUser: "auth/deleteUser",
    changeRole: "auth/changeRole",
    getProjectRoles: "auth/getProjectRoles",
    deploymentStatus: "auth/deploymentStatus",
    unlock: "auth/unlock",
    getRegistrationDetails: "auth/registration",
    saveRegistration: "auth/registration",
    getTimezone: "auth/getTimezone",
    programData: "/auth/program",
    getSystemInfo: "/auth/getSystemInfo",
    saveSystemInfo: "/auth/saveSystemInfo",
    getTypeList: "/auth/getTypeList",
    mapDetails: "/auth/mapDetails",
    createEMASiteAPI: "auth/createEMAsite",
    addUsers: "auth/addUsers",
    acceptInvitationURL: "auth/invitation",
    cancelInvitationURL: "auth/cancel",
    changePasswordURL: "auth/changePassword",
    getUserImage: "auth/getUserImage",
    uploadUserImage: "auth/uploadUserImage",
    getUserDetails: "auth/getUserDetails",
    saveUserDetails: "auth/saveUserDetails",
    getBom: "auth/bom",
    globalParams: "auth/globalParams",
    getAllDocuments: "auth/getAllDocumentList",
    downloadDocument: "auth/downloadDocument",
    uploadPageDocument: "auth/uploadDocument",
    deleteDocument: "auth/deleteDocument",
    activateLicense: "/auth/license",
    contactAPI: "/auth/contact",
    assetsListAPI: "auth/assetsList",
    assetsTypeAPI: "auth/assetsType",
    saveAssetsAPI: "auth/saveAssets",
    getAssetByIdAPI: "auth/assetsById",
    uploadPLCPackageAPI: "auth/uploadFile",
    uploadHMIPackageAPI: "auth/uploadFile",
    downloadIpcAPI: "auth/ipc",
    getEmaSiteAPI: "auth/getSite",
    deleteEmaSiteAPI: "auth/deleteSite",
    saveFavouriteAPI: "auth/favourite",
    deleteFavouriteAPI: "auth/favourite",
    newsAPI: "/auth/news",
    reinviteToProjectAPI: "auth/reInviteProjectUser",
    reinviteToEMBAPI: "auth/reInviteUser",
    getContactAPI: "auth/getContact",
    saveContactAPI: "auth/saveContact",
    deleteContactAPI: "auth/deleteContact",
    logoutAPI: "auth/logout",
    auditAPI: "auth/audit",
    defaultsAPI: "auth/defaults",
    resetPLCAPI: "auth/resetPLC",
    analyticsAPI: "auth/analytics",
    reportAPI: "auth/report",
    deleteUserAPI: "auth/deleteUsers",
    uploadSLDAPI: "auth/uploadSLD",
    programSaveAPI: "auth/programSave",
    deleteProjectImageAPI: "auth/deleteImage",
    addSiteUserAPI: "auth/addSiteUser",
    deleteSiteUserAPI: "auth/deleteSiteUser",
    saveSystemSettings: "/auth/saveSystemSettings",
    validateSystems: "auth/validateSystems",
    copyProject: "auth/copy",
    getRegions: "auth/getRegion",
    getTemplate: "auth/getTemplate",
    createTemplate: "auth/createTemplate",
    deleteTemplate: "auth/deleteTemplate",
    updateTemplate: "auth/updateTemplate",
    changeTempRole: "auth/changeTempRole",
    projectStatus:"auth/projectStatus",
    changeProjectStatus:"auth/projectStatus",
    externalId:"auth/getIds",
    getDscInfo:"auth/dsc",
    refreshDsc:"auth/dscRefresh",
    resendDscMail :"auth/dscResendEmail",
    ipcRowData:"/auth/ipcRouting",
    getEmaRoles:"auth/getEmaUserRoles",
    getOrganization:"auth/organization",
    activeDsc:"auth/activateSubscription",
    checkHealthAPI:"actuator/health"
  },
};
export default API_CONFIG;
