import React, { useContext } from "react";
import embLabels from "../../config/emb-labels-en.json";
import batteryImage from "../../images/BESS-image-SE.png";
import ACTIONS from "./Design_Asset_Actions.json";

import { AssetBlockContext } from "../CustomHooks/ContextApi";
import isTouchDevice from "../SE-Component/Tooltip";
import Tooltip from "../../utils/Tooltip";

const BatteryAsset = () => {
  const {
    CBAssetList,
    CBAssetListDispatch,
    architectureType,
    assetDetailsDisplay,
    setAssetSelection,
    configData,
    handleBatteryChange,
    defaultsMinMax,
    updateBatteryConfig,
    checkBatteryRemoveCondition,
    gsxExternalId,
    siteType,
    selectedValues,
    clientType,
    defaultCbData,
    handleCbAssetRequired,
    handlePmAssetRequired
  } = useContext(AssetBlockContext);
  const setAssetHandler = () => setAssetSelection(embLabels.BESS);
  const projectTypeValue = JSON.parse(localStorage.getItem("projectType"));
  const batteryChangeHandler = (e) => {
    handleBatteryChange(e);
  };
  let values = CBAssetList[2]?.assetDetails[0]?.assetAttributes?.filter((item)=>item?.type==="max_power")[0]?.value
   values = typeof (values) === "string" ?JSON.parse(values):"";

  return (
    <div name="battery" className="col-md-12 p-0">
      <div className="col-md-12 pt-2 pl-4 se-white-background">
        <div className="row">
          <div className="col-md-8 pb-2 pl-0 ">
            <span>
              <se-icon
                color="primary"
                className="se-icon icon_button se-life-green"
                size="medium"
              >
                battery2_half
              </se-icon>
              <span className="ml-2 bold align-top line-height-30px">
                Battery
              </span>
            </span>
          </div>
          {architectureType === embLabels.PV_BESS_NR ||
          siteType === "ISLANDABLE_GSX" ? null : (
            <div className="col-md-4">
              <span className="float-right">
                <button
                  disabled={projectTypeValue === "MGaaS ANSI" && siteType === "ISLANDABLE" ?false:true}
                  type="button"
                  className="btn btn-secondary btn-sm"
                  onClick={projectTypeValue === "MGaaS ANSI" && siteType === "ISLANDABLE" ?undefined:checkBatteryRemoveCondition}
                >
                  Remove
                </button>
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="col-md-12 col-sm-12 pt-2 pb-2 se-grey-background se-border">
        <div
          className="row"
          style={assetDetailsDisplay.manufacturer ? {} : { display: "none" }}
        >
          <div className="col-md-3">
            {assetDetailsDisplay.imageLink === null ? (
              assetDetailsDisplay.manufacturer === "SCHNEIDER ELECTRIC" ? (
                <img
                  src={batteryImage}
                  className="asset-image-display"
                  alt={assetDetailsDisplay.manufacturer}
                />
              ) : (
                <se-icon color="primary" size="large">
                  battery2_half
                </se-icon>
              )
            ) : (
              <img
                src={assetDetailsDisplay.imageLink}
                className="asset-image-display"
                alt={assetDetailsDisplay.manufacturer}
              />
            )}
          </div>

          <div className="col-md-9">
            <div className="row">
              <div className="col-md-10 bold ml-2">
                {assetDetailsDisplay.manufacturer}
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 ml-2 text-break">
                {assetDetailsDisplay.communicationModel}
                <div className="bold">AC-Coupled System</div>
              </div>
              <div className="col-md-2">
                <Tooltip
                  position="left"
                  iconColor="secondary"
                  information="Battery"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="row"
          style={assetDetailsDisplay.manufacturer ? { display: "none" } : {}}
        >
          <div className="ml-2 mb-2 asset-selection"> No assets selected</div>
        </div>
        <div
          className="row"
          // style={
          //   architectureType === embLabels.PV_BESS_NR ? { display: "none" } : {}
          // }
        >
          <div className=" col-md col-sm col-lg text-center">
            <se-button option="outline" size="small" onClick={setAssetHandler}>
              Asset Selection
            </se-button>
          </div>
        </div>
      </div>

      <div style={assetDetailsDisplay.manufacturer ? {} : { display: "none" }}>
        <div className="col-md-12 pt-1 pb-1 se-white-background align-middle se-border">
          <div className="row">
            <label className="col-md-6 bold mt-2 mb-2 pl-3 se-font-14px">
              Asset Settings
            </label>
            <div className="col-md-6 mt-1 text-right">
              <button
                type="button"
                className="btn btn-secondary btn-sm se-btn-primary"
                onClick={updateBatteryConfig}
              >
                <se-icon size="nano">action_save</se-icon>
                <span>&nbsp;</span>Save
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-12 pt-2 pb-2 se-grey-background">
          {/* Commenting because for future Int in Daas ANSI */}
           {[embLabels.GRID_CONNECTED, embLabels.GRID_CONNECTED_GSX].includes(
            siteType
          ) && (
            <div className="form-group mb-1">
              {((
                // siteType === embLabels.GRID_CONNECTED &&
                (clientType === embLabels.GENERIC_PROJECT||clientType === embLabels.GREENSTRUXURE_PROJECT) &&
                projectTypeValue?.projectTypeValue === "MGaaS IEC")) && (
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input asCompatible"
                    name="pmRequired"
                    id="pmRequiredValidation"
                    onChange={()=>handlePmAssetRequired(selectedValues.selectedAsset)}
                    value={ 
                      CBAssetList[selectedValues.selectedAsset].uiData
                        .pmRequired
                    }
                    checked={
                      CBAssetList[selectedValues.selectedAsset].uiData
                        .pmRequired
                    }
                  />
                  <label
                    className="custom-control-label line-height-25"
                    htmlFor="pmRequiredValidation"
                  >
                    {"Enable resource energy monitoring"}
                  </label>
                  <Tooltip
                    position="left"
                    iconColor="secondary"
                    information=" If the checkbox in not enabled, PM won't be the part of
                    Battery"
                  />
                </div>
              )}

              {((siteType === embLabels.GRID_CONNECTED &&
                clientType === embLabels.GENERIC_PROJECT &&
                projectTypeValue?.projectTypeValue === "MGaaS IEC")) && (
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input asCompatible"
                    name="cBRequired"
                    id="cBRequiredValidation"
                    onChange={()=>handleCbAssetRequired(selectedValues.selectedAsset) }
                    value={
                      CBAssetList[selectedValues.selectedAsset].uiData
                        .cbRequired
                    }
                    checked={
                      CBAssetList[selectedValues.selectedAsset].uiData
                        .cbRequired
                    }
                  />
                  <label
                    className="custom-control-label line-height-25"
                    htmlFor="cBRequiredValidation"
                  >
                    {"Enable resource connection management"}
                  </label>
                  <Tooltip
                    position="left"
                    iconColor="secondary"
                    information=" If the checkbox in not enabled, CB won't be the part of
                    Battery"
                  />
                </div>
              )}
            </div>
          )} 

          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">
              Name <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput1"
              name="name"
              maxLength="50"
              value={configData.configData.name}
              onChange={batteryChangeHandler}
              placeholder="Name"
            />
            <div
              className="invalid-feedback-custom"
              style={!configData.configData.name ? {} : { display: "none" }}
            >
              {embLabels.nameRequired}
            </div>
            <div
              className="invalid-feedback-custom"
              style={
                configData.configData.name !== "" &&
                !/^[a-zA-Z0-9-_ ]+$/.test(configData?.configData.name)
                  ? {}
                  : { display: "none" }
              }
            >
              {embLabels.notallowedAlphanumericValues}
            </div>
          </div>

          {[embLabels.GRID_CONNECTED_GSX, embLabels.ISLANDABLE_GSX].includes(
            siteType
          ) &&
            (architectureType === embLabels.PV_BESS_NR ||
              architectureType === embLabels.PV_GENSET_ATS) && (
              <div className="form-group mb-1">
                <label htmlFor="exampleFormControlInput1">External ID</label>
                <input
                  type="text"
                  autoComplete="none"
                  className="form-control form-control-sm"
                  id="exampleFormControlInput1"
                  placeholder="External ID"
                  onChange={batteryChangeHandler}
                  name="externalId"
                  maxLength="64"
                  value={gsxExternalId.batteryExternalId}
                />
              </div>
            )}
          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">Description</label>
            <input
              type="text"
              autoComplete="none"
              className="form-control form-control-sm"
              id="exampleFormControlInput1"
              placeholder="Description"
              name="description"
              onChange={batteryChangeHandler}
              maxLength="100"
              value={configData.configData.description}
            />
          </div>
          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">
              Max Power(kW) <span className="se-mandatory">*</span>
            </label>
            { Array.isArray(values)?
            <select
              className="form-control form-control-sm"
              name="max_power"
              id="exampleFormControlInput1" 
              value={configData.configData.max_power}
              onChange={batteryChangeHandler}
            >
              {values?.length &&
                values.map((val) => (
                  <option value={val.toFixed(1)} key={val}>
                    {val.toFixed(1)}
                  </option>
                ))}
            </select>
            :
            <div>
              <input
                type="text"
                autoComplete="none"
                className={"form-control form-control-sm "}
                id="exampleFormControlInput1"
                name="max_power"
                maxLength="4"
                value={configData.configData.max_power}
                placeholder="Max Power(kW)"
                onChange={batteryChangeHandler}
              />
              <div
                className="invalid-feedback-custom"
                style={
                  !configData.configData.max_power ? {} : { display: "none" }
                }
              >
                Max Power(kW) is required.
              </div>
              {configData.configData.max_power !== "" &&
              (parseInt(configData.configData.max_power) <
                defaultsMinMax.maxPowerBessMin ||
                parseInt(configData.configData.max_power) >
                  defaultsMinMax.maxPowerBessMax) ? (
                <div className="invalid-feedback-custom">
                  Max Power should be in range(
                  {defaultsMinMax.maxPowerBessMin}-
                  {defaultsMinMax.maxPowerBessMax} kW).
                </div>
              ) : (
                ""
              )}
            </div>
            }
          </div>
          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">
              Capacity(kWh) <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput1"
              placeholder="Capacity(kWh)"
              maxLength={4}
              name="capacity"
              value={configData.configData.capacity}
              onChange={batteryChangeHandler}
            />
            <div
              className="invalid-feedback-custom"
              style={
                !(parseInt(configData.configData.capacity) >= 0)
                  ? {}
                  : { display: "none" }
              }
            >
              Capacity is required (kWh) .
            </div>
            {configData.configData.capacity !== "" &&
            (parseInt(configData.configData.capacity) <
              defaultsMinMax.capacityBessMin ||
              parseInt(configData.configData.capacity) >
                defaultsMinMax.capacityBessMax) ? (
              <div className="invalid-feedback-custom">
                Capacity should be in range(
                {defaultsMinMax.capacityBessMin}-
                {defaultsMinMax.capacityBessMax} kWh).
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">
              SOC Max (% of battery capacity in kWh) <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput1"
              placeholder="SOC Max (% of battery capacity in kWh)"
              name="soc_max"
              maxLength={4}
              value={configData.configData.soc_max}
              onChange={batteryChangeHandler}
            />
            <div
              className="invalid-feedback-custom"
              style={
                !(parseInt(configData.configData.soc_max) >= 0)
                  ? {}
                  : { display: "none" }
              }
            >
              SOC Max (% of battery capacity in kWh) is required.
            </div>
            {configData.configData.soc_max !== "" &&
            (parseInt(configData.configData.soc_max) <
              defaultsMinMax.socMaxMin ||
              parseInt(configData.configData.soc_max) >
                defaultsMinMax.socMaxMax) ? (
              <div className="invalid-feedback-custom">
                SOC Max should be in range(
                {defaultsMinMax.socMaxMin}-{defaultsMinMax.socMaxMax} % of Max
                power).
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">
              SOC Min (% of battery capacity in kWh) <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput1"
              placeholder="SOC Min (% of battery capacity in kWh)"
              maxLength={4}
              name="soc_min"
              value={configData.configData.soc_min}
              onChange={batteryChangeHandler}
            />
            <div
              className="invalid-feedback-custom"
              style={
                !(parseInt(configData.configData.soc_min) >= 0)
                  ? {}
                  : { display: "none" }
              }
            >
              SOC Min (% of battery capacity in kWh) is required.
            </div>
            {configData.configData.soc_min !== "" &&
            (parseInt(configData.configData.soc_min) <
              defaultsMinMax.socMinMin ||
              parseInt(configData.configData.soc_min) >
                defaultsMinMax.socMinMax) ? (
              <div className="invalid-feedback-custom">
                SOC Min should be in range(
                {defaultsMinMax.socMinMin}-{defaultsMinMax.socMinMax} % of Max
                power).
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="form-group mb-1"
            // style={
            //   architectureType === embLabels.PV_BESS_NR ? { display: "block" } : { display: "none" }
            // }
          >
            <label htmlFor="exampleFormControlInput1">
              SOC Nominal (% of battery capacity in kWh){" "}
              <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput1"
              placeholder="SOC Nominal (% of battery capacity in kWh)"
              maxLength={4}
              name="soc_nominal_power"
              value={configData.configData.soc_nominal_power}
              onChange={batteryChangeHandler}
            />
            <div
              className="invalid-feedback-custom"
              style={
                !(parseInt(configData.configData.soc_nominal_power) >= 0)
                  ? {}
                  : { display: "none" }
              }
            >
              SOC Nominal (% of battery capacity in kWh) is required.
            </div>
            {configData.configData.soc_nominal_power !== "" &&
            parseInt(configData.configData.soc_nominal_power) <
              defaultsMinMax.socNominalDefault ? (
              <div className="invalid-feedback-custom">
                SOC Nominal Min should be 60
                {/* SOC Min should be in range(
                {defaultsMinMax.socMinMin}-{defaultsMinMax.socMinMax} % of Max
                power). */}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatteryAsset;