// import ACTIONS from "../Design_Asset_Actions.json";
export const ACTIONS = {
  FETCH_SUCCESS: "FETCH_SUCCESS",
  ENABLE_TEXTBOX: "ENABLE_TEXTBOX",
  ENABLE_TEXTBOX_SYSTEM: "ENABLE_TEXTBOX_ROUTERSYSTEM",
  RESET_COMMUNICATION: "RESET_COMMUNICATION",
  UPDATE_VLAN_NAME: "UPDATE_VLAN_NAME",
};

const initialRouterState = {
  connexiumVlanConfigurations: [],
  moxaVlanConfigurations: [],
  projectId: "",
  securedCommunicationArchitecture: [],
  routingPLCProjectMappingDtoList: [],
  routingIPCProjectMappingDtoList: [],
};
// const updateRoutingSettings = (state, data) => {
//   const updateState = [...state.routerSettings];

//   updateState.forEach((settings, index) => {
//     if (data.secureComArchId === settings.secureComArchId) {
//       updateState[index] = data;
//     }
//   });
//   return { routerSettings: updateState };
// };
const routerReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_SUCCESS:
      return {
        routingPLCProjectMappingDtoList:
          action.payload.routingPLCProjectMappingDtoList,
        routingIPCProjectMappingDtoList:
          action.payload.routingIPCProjectMappingDtoList,
        connexiumVlanConfigurations: action.payload.connexiumVlanConfigurations,
        moxaVlanConfigurations: action.payload.moxaVlanConfigurations,
        projectId: action.payload.projectId,
        securedCommunicationArchitecture:
          action.payload.securedCommunicationArchitecture,
      };
    // case ACTIONS.UPDATE_VLAN_NAME:
    //   return updateRoutingSettings(state, action.payload.data);

    default:
      return state;
  }
};

export { initialRouterState, routerReducer };
