import {all} from 'redux-saga/effects';
import { watchGetProjectInfo } from './features/projectStatusInfoSlice';
import { watchAddProjectType, watchGetProjectType } from './features/projectTypeSlice';
import { watchGetSecureArchitectureData } from './features/secureArchitectureDataSlice';
import { watchGetToolTipData } from './features/getTooltipDataSlice';


export default function* rootSaga(){
    yield all([
        watchGetProjectInfo(),
        watchGetProjectType(),
        watchAddProjectType(),
        watchGetSecureArchitectureData(),
        watchGetToolTipData(),
    ])
}