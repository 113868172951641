import React, { useEffect, useState } from "react";
import MyErrorBoundary from "../../utils/ErrorBoundary";

const SiteAddUser = ({
  emaUserRoles,
  popUpstatus,
  message,
  addUserSiteList,
  closeAddUser,
}) => {
  const [mandatoryMsgDisplay, setMandatoryMsgDisplay] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("Email Required.");
  const [emailAddress, setEmailAddress] = useState("");
  const [role, setRole] = useState(0);
  const [, setRolePermission] = useState("");
  const [roleName, setRoleName] = useState("");
  const [rolesListInside, setRolesListInside] = useState([]);

  useEffect(() => {
    setRolePermission("");
    setRole(0);
    setRoleName("");
    setEmailAddress("");
    let rolesListInsideNew = [];
    emaUserRoles?.userRoles?.forEach(role => {
      role.permission = emaUserRoles.roleDetails[role.role];
      rolesListInsideNew.push(role);
    });
    setRolesListInside(rolesListInsideNew);
  }, [emaUserRoles]);


  const addUserToSite = () => {
    let patternCheckRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gim;
    if (patternCheckRegex.test(emailAddress)) {
      setMandatoryMsgDisplay(false);
      setEmailAddress("");
      addUserSiteList({ emailAddress: emailAddress, role:role });
    } else {
      setEmailErrorMessage("Enter Valid Email.");
      setEmailAddress("");
      setMandatoryMsgDisplay(true);
    }
  };

  const handleEmailChange = e => setEmailAddress(e.target.value);
  const handleRoleChange = e => {
    setRole(e.target.value);
    const rolePermission = rolesListInside.filter(
      role => role.tag === e.target.value
    )[0]?.permission;
    setRolePermission(rolePermission);
  };

  return (
    <MyErrorBoundary>
      
    <se-dialog
      id="dialog-complex"
      size="medium"
      open={popUpstatus}
      can-backdrop="false"
    >
      <se-dialog-header>{message}</se-dialog-header>

      <se-dialog-content className="se-dialog-content">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group row mb-0">
              <label
                htmlFor="emailaddress"
                className="col-sm-9 col-form-label ml-3"
              >
                Enter e-mail address

              </label>
            </div>
            <div className="form-group">
              <div className="col-sm-12">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control form-control-sm"
                  id="emailaddress"
                  name="emailAddress"
                  maxlength="50"
                  value={emailAddress}
                  onChange={handleEmailChange}
                />
                <div
                  className="invalid-feedback-custom"
                  style={
                    !emailAddress && mandatoryMsgDisplay
                      ? {}
                      : { display: "none" }
                  }
                >
                  {emailErrorMessage}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group ">
              <div className="col-sm-12">
                <select
                  className="form-control form-control-sm "
                  name="role"
                  id="roleSelection"
                  value={role}
                  onChange={handleRoleChange}
                >
                  <option value="0">Select the role</option>
                  {rolesListInside && rolesListInside.map(data => (
                    <option key={data.id} value={data.role}>
                      {" "}
                      Invited as {data.role} - "{data.permission}"
                    </option>
                  ))}
                </select>
                <div
                  className="invalid-feedback-custom"
                  style={
                    !role != 0 && mandatoryMsgDisplay
                      ? {}
                      : { display: "none" }
                  }
                >
                  Role is required.
                </div>
              </div>
            </div>
          </div>
        </div>
      </se-dialog-content>
      <se-dialog-footer>
        <se-button option="outline" onClick={closeAddUser}>
          Cancel
        </se-button>

        <se-button option="flat" onClick={addUserToSite}>
          Send Invitation
        </se-button>
      </se-dialog-footer>
    </se-dialog>
    </MyErrorBoundary>
  );
};

export default SiteAddUser;
