
import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";
import embLabels from "../../config/emb-labels-en.json";
const Busbar = ({ secondBusbar }) => {
  const {
    CBAssetList:[,,{uiData:existingBessUiData},{uiData}],
    CBAssetList,
    coordinates: { busbarCordinates },
    siteType,
    switchboardType,
    loadSide
  } = useContext(DesignAssetContext);
  // const {projectType} = useSelector(state => state.projectType);
  return (
    <>
      {(["GENSET_PVBESS_A2E","GRID_CONNECTED_GSX","ISLANDABLE_GSX","GRID_CONNECTED","PV_HYBRIDIZATION","ISLANDABLE"].includes(siteType)  ) &&
      (switchboardType === "" || switchboardType === embLabels.EXISTING_SWITCHBOARD) ? (
        <svg>

          <g id="busbar">
            <line
            

            x1={ busbarCordinates.x11}
            y1={loadSide === "LOAD_SIDE" ? "280" : Number(busbarCordinates.y11)}
            x2={busbarCordinates.x21}
            y2={loadSide === "LOAD_SIDE" ? "280" :Number(busbarCordinates.y21)}
              //   x1 ={busbarCordinates.x11}
              // // x1={existingBessUiData.existingPmRequired?"118":busbarCordinates.x11}
              // y1={busbarCordinates.y11}
              // x2 ={busbarCordinates.x21}
              // // x2={uiData.existingPmRequired?"430":busbarCordinates.x21}
              // y2={busbarCordinates.y21}
              stroke="#333333"
              strokeWidth="3"
            />
            {secondBusbar && (
              <line
                x1={busbarCordinates.x12}
                y1={loadSide === "LOAD_SIDE" ? "470" : Number(busbarCordinates.y12)}
                // x2={((switchboardType !== "" ||switchboardType !== embLabels.EXISTING_SWITCHBOARD) && siteType === "ISLANDABLE_GSX") ? Number(busbarCordinates.x22) + 130 : Number(busbarCordinates.x22)}
                x2={parseInt(busbarCordinates.x22)}
                y2={loadSide === "LOAD_SIDE" ? "470" :Number(busbarCordinates.y22)}
                // y2={loadSide==="LOAD_SIDE"?Number(busbarCordinates.y22)+40:busbarCordinates.y22}
                stroke="#333333"
                strokeWidth="3"
              />
            )}
          </g>
        </svg>
      ) : (
        
        <svg>
          <g id="busbar">
            <line
              x1={busbarCordinates.x12}
              y1={busbarCordinates.y12}
              x2={parseInt(busbarCordinates.x22)}
              y2={busbarCordinates.y22}

              // x2={((switchboardType !== "" ||switchboardType !== embLabels.EXISTING_SWITCHBOARD) && siteType === "ISLANDABLE_GSX") ? Number(busbarCordinates.x22) + 130 : siteType==="GRID_CONNECTED"?Number(busbarCordinates.x22)+130:Number(busbarCordinates.x22)}
              // y2={loadSide==="LOAD_SIDE"?Number(busbarCordinates.y22)+40:busbarCordinates.y22}

              stroke="#333333"
              strokeWidth="3"
            />
          </g>
        </svg>
      )}
    </>
  );
};

export default Busbar;