import { useReducer } from "react";
import embLabels from "../config/emb-labels-en.json";

export const coOrdinateValidator = (
  coOrdinates,
  switchType,
  siteType,
  projectTypeValue,
  count,
  rectangleWidth,
  loadSide = ""
) => {

  let { x11, y11, y21, x12, y22, y12, x21, x22 } = coOrdinates;
  if (
    siteType === embLabels.GRID_CONNECTED_GSX &&
    (projectTypeValue === "DaaS ANSI"||projectTypeValue === 'ANSI | Buildings | Autogrid Flex')
  ) {
    if (switchType === embLabels.NEW_SWITCHBOARD) {
      if (count.derCount === 2) {
        x11 = 158;
        y11 = 280;
        x21 = 400;
        y21 = 280;
        x12 = 800;
        y12 = 470;
        x22 = 520;
        y22 = 470;
      }
      if (count.derCount === 3) {
        x11 = 158;
        y11 = 285;
        x12 = 550;
        y12 = 300;
        x21 = 100;
        y21 = 300;
        x22 = 100;
        y22 = 300;
        rectangleWidth = 550;
      }
      if (count.derCount === 4) {
        x11 = 158;
        y11 = 285;
        x12 = 660;
        y12 = 300;
        x21 = 100;
        y21 = 300;
        x22 = 100;
        y22 = 300;
        rectangleWidth = 690;
      }
      if (count.derCount === 5) {
        x11 = 158;
        y11 = 285;
        x12 = 800;
        y12 = 300;
        x21 = 100;
        y21 = 300;
        x22 = 100;
        y22 = 300;
        rectangleWidth = 800;
      }
    } else if (switchType === "EXISTING_SWITCHBOARD") {
      if (count.derCount === 2) {
        x11 = 120;
        y11 = 285;
        x21 = 400;
        y21 = 285;
        x12 = 800;
        y12 = 430;
        x22 = 520;
        y22 = 430;
        rectangleWidth = 400;
      }
      if (count.derCount === 3) {
        x11 = 120;
        y11 = 285;
        x12 = 900;
        y12 = 428; //const
        x21 = 430;
        y21 = 285;
        x22 = 520;
        y22 = 428;
        rectangleWidth = 450;
      }
      if (count.derCount === 4) {
        x11 = 120;
        y11 = 285;
        x12 = 1000;
        y12 = 428;
        x21 = 430;
        y21 = 285;
        x22 = 520;
        y22 = 428; //existing sb
        rectangleWidth = 550;
      }

      if (count.derCount === 5) {
        x11 = 120;
        y11 = 285;
        x12 = 1100;
        y12 = 428;
        x21 = 430;
        y21 = 285;
        x22 = 520;
        y22 = 428;
        rectangleWidth = 700;
      }
      if(count.loadCount>=5){
        x11 = 120;
        y11 = 300;
        x21 = 750;
        y21 = 300;
        x12 = 650;
        y12 = 300;
        x22 = 100;
        y22 = 300;
      }
      if(count.loadCount===10){
        x11 = 700;
        y11 = 300;
        x21 = 120;
        y21 = 300;
        rectangleWidth = 700;
      }
    }
  } else if (
    siteType === embLabels.ISLANDABLE_GSX &&
    (projectTypeValue === "DaaS ANSI"||projectTypeValue === 'ANSI | Buildings | Autogrid Flex')
  ) {
    if (switchType === embLabels.NEW_SWITCHBOARD) {
      if (count.derCount === 3) {
        x11 = 650;
        y11 = 300;
        x21 = 110;
        y21 = 300;
        rectangleWidth = 650
        if(count.loadCount>=5){
          x11 = 700;
          y11 = 300;
          x21 = 110;
          y21 = 300;
          rectangleWidth = 720
        }
        if(count.loadCount===10){
          x11 = 700;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 720;
        }
      }
      if (count.derCount === 4) {
        x11 = 650;
        y11 = 300;
        x21 = 110;
        y21 = 300;
        rectangleWidth = 690;
        if(count.loadCount>=5){
          x11 = 750;
          y11 = 300;
          x21 = 110;
          y21 = 300;
          rectangleWidth = 750
        }
        if(count.loadCount===10){
          x11 = 800;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 800;
        }
      }
      if (count.derCount === 5) {
        x11 = 750;
        y11 = 300;
        x21 = 110;
        y21 = 300;
        rectangleWidth = 790;
        if(count.loadCount>=5){
          x11 = 750;
          y11 = 300;
          x21 = 110;
          y21 = 300;
          rectangleWidth = 790
        }
        if(count.loadCount===10){
          x11 = 800;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 800;
        }
      }
      if (count.derCount === 6) {
        x11 = 900;
        y11 = 300;
        x21 = 110;
        y21 = 300;
        rectangleWidth = 900;
        if(count.loadCount>=5){
          x11 = 900;
          y11 = 300;
          x21 = 110;
          y21 = 300;
          rectangleWidth = 900
        }
        if(count.loadCount===10){
          x11 = 900;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 900;
        }
      }
    } else if (switchType === "EXISTING_SWITCHBOARD") {            
      if (count.derCount === 3) {
        x11 = 100;
        y11 = 285;
        x12 = 1000;
        y12 = 428; //const
        x21 = 430;
        y21 = 285;
        x22 = 570;
        y22 = 428;
        rectangleWidth = 500;
        if(count.derCount>=2&&count.loadCount>=5){
          x12 = 1100;
          y12 = 428;
          x22 = 570;
          y22 = 428;
          rectangleWidth = 600
        }
        if(count.loadCount===10){
          x12 = 1150;
          y12 = 428;
          x22 = 570;
          y22 = 428;
          rectangleWidth = 650;
        }
      }
      if (count.derCount === 4) {
        x11 = 120;
        y11 = 285;
        x12 = 1100;
        y12 = 428;
        x21 = 430;
        y21 = 285;
        x22 = 570;
        y22 = 428; //existing sb
        rectangleWidth = 600;
        if(count.derCount>=2&&count.loadCount>=5){
          x12 = 1100;
          y12 = 428;
          x22 = 570;
          y22 = 428;
          rectangleWidth = 600
        }
        if(count.loadCount===10){
          x12 = 1150;
          y12 = 428;
          x22 = 570;
          y22 = 428;
          rectangleWidth = 650;
        }
      }

      if (count.derCount === 5) {
        x11 = 120;
        y11 = 285;
        x12 = 1100;
        y12 = 428;
        x21 = 430;
        y21 = 285;
        x22 = 570;
        y22 = 428;
        rectangleWidth = 650;
        if(count.derCount>=2&&count.loadCount>=5){
          // x11 = 120;
          // y11 = 300;
          // x21 = 750;
          // y21 = 300;
          x12 = 1100;
          y12 = 428;
          x22 = 570;
          y22 = 428;
          rectangleWidth = 600
        }
        if(count.loadCount===10){
          x12 = 1150;
          y12 = 428;
          x22 = 570;
          y22 = 428;
          rectangleWidth = 650;
        }
      }
      if (count.derCount === 6) {
        x11 = 120;
        y11 = 285;
        x12 = 1200;
        y12 = 428;
        x21 = 430;
        y21 = 285;
        x22 = 570;
        y22 = 428;
        rectangleWidth = 700;
        if(count.loadCount>=5){
          x12 = 1200;
          y12 = 428;
          x22 = 570;
          y22 = 428;
          rectangleWidth = 700
        }
        if(count.loadCount===10){
          x12 = 1200;
          y12 = 428;
          x22 = 570;
          y22 = 428;
          rectangleWidth = 700;
        }
      }    
    }
  } else if (
    siteType === embLabels.GRID_CONNECTED_GSX &&
    (projectTypeValue === "DaaS IEC"||projectTypeValue === 'IEC | Buildings | Autogrid Flex')
  ) {
    if (switchType === embLabels.NEW_SWITCHBOARD) {
      if (count.derCount === 3) {
        x12 = 120;
        y12 = 300;
        x22 = 500;
        y22 = 300;
        rectangleWidth = 550;
      }
      if (count.derCount === 4) {
        x12 = 120;
        y12 = 300;
        x22 = 650;
        y22 = 300;
        rectangleWidth = 670;
      }
    } else if (switchType === "EXISTING_SWITCHBOARD") {
      if (count.derCount === 3) {
        x11 = 120;
        y11 = 285;
        x12 = 900;
        y12 = 428; //const
        x21 = 430;
        y21 = 285;
        x22 = 520;
        y22 = 428;
        rectangleWidth = 450;
      }
      if (count.derCount === 4) {
        x11 = 120;
        y11 = 285;
        x12 = 1000;
        y12 = 428;
        x21 = 430;
        y21 = 285;
        x22 = 529;
        y22 = 428;
        rectangleWidth = 550;
      }

      if (count.derCount === 5) {
        x11 = 120;
        y11 = 285;
        x12 = 1100;
        y12 = 428;
        x21 = 430;
        y21 = 285;
        x22 = 570;
        y22 = 428;
        rectangleWidth = 700;
      }
      if (count.derCount === 6) {
        x11 = 120;
        y11 = 285;
        x12 = 1200;
        y12 = 428;
        x21 = 430;
        y21 = 285;
        x22 = 570;
        y22 = 428;
        rectangleWidth = 700;
      }
    }
  } else if (
    siteType === embLabels.ISLANDABLE_GSX &&
    (projectTypeValue === "DaaS IEC"||projectTypeValue === 'IEC | Buildings | Autogrid Flex')
  ) {
    if (switchType === embLabels.NEW_SWITCHBOARD) {
      if (count.derCount === 3) {
        x11 = 120;
        y11 = 300;
        x21 = 650;
        y21 = 300;
        rectangleWidth = 550;
        if(count.loadCount>=5){
          x11 = 700;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 700
        }
        if(count.loadCount===10){
          x11 = 700;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 700;
        }
      }
      if (count.derCount === 4) {
        x11 = 120;        
        y11 = 300;
        x21 = 650;
        y21 = 300;
        rectangleWidth = 670;
        if(count.loadCount>=5){
          x11 = 750;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 750
        }
        if(count.loadCount===10){
          x11 = 800;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 800;
        }
      }
      if (count.derCount === 5) {
        x11 = 120;        
        y11 = 300;
        x21 = 740;
        y21 = 300;
        rectangleWidth = 750;
        if(count.loadCount>=5){
          x11 = 750;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 750
        }
        if(count.loadCount===10){
          x11 = 800;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 800;
        }
      }
    } else if (switchType === "EXISTING_SWITCHBOARD") {
      if (count.derCount === 3) {
        x11 = 120;
        y11 = 285;
        x12 = 900;
        y12 = 428; //const
        x21 = 430;
        y21 = 285;
        x22 = 520;
        y22 = 428;
        rectangleWidth = 450;
      }
      if (count.derCount === 4) {
        x11 = 120;
        y11 = 285;
        x12 = 1000;
        y12 = 428;
        x21 = 430;
        y21 = 285;
        x22 = 560;
        y22 = 428;
        rectangleWidth = 550;
      }

      if (count.derCount === 5) {
        x11 = 120;
        y11 = 285;
        x12 = 1100;
        y12 = 428;
        x21 = 430;
        y21 = 285;
        x22 = 570;
        y22 = 428;
        rectangleWidth = 650;
      }
      if (count.derCount === 6) {
        x11 = 120;
        y11 = 285;
        x12 = 1200;
        y12 = 428;
        x21 = 430;
        y21 = 285;
        x22 = 570;
        y22 = 428;
        rectangleWidth = 700;
      }
      if(count.derCount >=2&&count.loadCount===10){
        x12 = 1200;
        y12 = 428;
        x22 = 570;
        y22 = 428;
        rectangleWidth = 700;
      }
    }
  } else if (
    siteType === embLabels.GRID_CONNECTED &&
    (projectTypeValue === "MGaaS IEC"||projectTypeValue === 'IEC | Buildings | Microgrid Advisor')
  ) {
    if (switchType === embLabels.NEW_SWITCHBOARD) {
      if (count.derCount === 2) {
        x11 = 400;
        y11 = 370;
        x21 = 700;
        y21 = 370;
        rectangleWidth = 380;
      }
      if (count.derCount === 3) {
        x11 = 400;
        y11 = 370;
        x21 = 800;
        y21 = 370;
        rectangleWidth = 500;
      }
      if (count.derCount === 4) {
        x11 = 400;
        y11 = 370;
        x21 = 930;
        y21 = 370;
        rectangleWidth = 620;
      }
      if (count.derCount === 5) {
        x11 = 400;
        y11 = 370;
        x21 = 1050;
        y21 = 370;
        rectangleWidth = 730;
      }
    } else if (switchType === "EXISTING_SWITCHBOARD") {
      if (count.derCount === 2) {
        x11 = 120;
        y11 = 285;
        x21 = 350;
        y21 = 285;
        x12 = 850;
        y12 = 470;
        x22 = 620;
        y22 = 470;
        rectangleWidth = 350;
      }
      if (count.derCount === 3) {
        x11 = 120;
        y11 = 285;
        x21 = 350;
        y21 = 285;
        x12 = 980;
        y12 = 470;
        x22 = 620;
        y22 = 470;
        rectangleWidth = 450;
      }
      if (count.derCount === 4) {
        x11 = 120;
        y11 = 285;
        x21 = 350;
        y21 = 285;
        x12 = 1050;
        y12 = 470;
        x22 = 620;
        y22 = 470;
        rectangleWidth = 500;
      }
      if (count.derCount === 5) {
        x11 = 120;
        y11 = 285;
        x21 = 350;
        y21 = 285;
        x12 = 1120;
        y12 = 470;
        x22 = 620;
        y22 = 470;
        rectangleWidth = 600;
      }
    }
  } else if (
    siteType === embLabels.GRID_CONNECTED &&
    (projectTypeValue === "MGaaS ANSI"||projectTypeValue === 'ANSI | Buildings | Microgrid Advisor')
  ) {
    if (switchType === embLabels.NEW_SWITCHBOARD) {
      if (count.derCount === 2) {
        x12 = 800;
        y12 = 470;
        x22 = 620;
        y22 = 470;
        rectangleWidth = 550;
      }
      if (count.derCount === 3) {
        x12 = 100;
        y12 = 300;
        x22 = 550;
        y22 = 300;
        rectangleWidth = 550;
      }
      if (count.derCount === 4) {
        x12 = 100;
        y12 = 300;
        x22 = 650;
        y22 = 300;
        rectangleWidth = 650;
      }
      if (count.derCount === 5) {
        x12 = 100;
        y12 = 300;
        x22 = 780;
        y22 = 300;
        rectangleWidth = 800;
      }
    } else if (switchType === "EXISTING_SWITCHBOARD") {
      if (count.derCount === 2) {
        x11 = 120;
        y11 = 285;
        x21 = 450;
        y21 = 285;
        x12 = 800;
        y12 = 470;
        x22 = 620;
        y22 = 470;
        rectangleWidth = 350;
      }
      if (count.derCount === 3) {
        x11 = 120;
        y11 = 285;
        x21 = 450;
        y21 = 285;
        x12 = 900;
        y12 = 430;
        x22 = 520;
        y22 = 430;
        rectangleWidth = 450;
      }
      if (count.derCount === 4) {
        x11 = 120;
        y11 = 285;
        x21 = 450;
        y21 = 285;
        x12 = 1000;
        y12 = 430;
        x22 = 520;
        y22 = 430;
        rectangleWidth = 550;
      }
      if (count.derCount === 5) {
        x11 = 120;
        y11 = 285;
        x21 = 450;
        y21 = 285;
        x12 = 1120;
        y12 = 430;
        x22 = 520;
        y22 = 430;
        rectangleWidth = 675;
      }
    }
  }else if (
    siteType === embLabels.ISLANDABLE &&
    (projectTypeValue === "MGaaS ANSI"||projectTypeValue === 'ANSI | Buildings | Microgrid Advisor')
  ) {
    if (switchType === embLabels.NEW_SWITCHBOARD) {
      if (count.derCount === 2) {
        x11 = 800;
        y11 = 470;
        x21 = 620;
        y21 = 470;
        rectangleWidth = 550;
      }
      if (count.derCount === 3) {
        x11 = 100;
        y11 = 300;
        x21 = 550;
        y21 = 300;
        rectangleWidth = 550;
      }
      if (count.derCount === 4) {
        x11 = 100;
        y11 = 300;
        x21 = 650;
        y21 = 300;
        rectangleWidth = 670;
        if((count.evCount+count.loadCount)>=6||count.loadCount>=5){
          x11 = 800;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 800
        }
        if((count.evCount+count.loadCount)===10||count.loadCount===10){
          x11 = 800;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 800;
        }
      }
      if (count.derCount === 5) {
        x11 = 100;
        y11 = 300;
        x21 = 780;
        y21 = 300;
        rectangleWidth = 800;
        if(count.loadCount>=5){
          x11 = 800;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 800
        }
        if(count.loadCount===10){
          x11 = 800;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 800;
        }
      }
      if (count.derCount === 6) {
        x11 = 100;
        y11 = 300;
        x21 = 880;
        y21 = 300;
        rectangleWidth = 900;
        if(count.loadCount>=5){
          x11 = 880;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 900
        }
        if(count.loadCount===10){
          x11 = 880;
          y11 = 300;
          x21 = 120;
          y21 = 300;
          rectangleWidth = 900;
        }
      }
    } else if (switchType === "EXISTING_SWITCHBOARD") {
      if (count.derCount === 2) {
        x11 = 120;
        y11 = 285;
        x21 = 400;
        y21 = 285;
        x12 = 800;
        y12 = 470;
        x22 = 620;
        y22 = 470;
        rectangleWidth = 350;
      }
      if (count.derCount === 3) {
        x11 = 120;
        y11 = 285;
        x21 = 400;
        y21 = 285;
        x12 = 900;
        y12 = 470;
        x22 = 620;
        y22 = 470;
        rectangleWidth = 350;
      }
      if (count.derCount === 4) {
        x11 = 120;
        y11 = 285;
        x21 = 400;
        y21 = 285;
        x12 = 1000;
        y12 = 430;
        x22 = 570;
        y22 = 430;
        rectangleWidth = 500;
        if(count.loadCount>=5){
          x12 = 1100;
          y12 = 428;
          x22 = 570;
          y22 = 428;
          rectangleWidth = 600
        }
        if(count.loadCount===10){
          x12 = 1150;
          y12 = 428;
          x22 = 570;
          y22 = 428;
          rectangleWidth = 650;
        }
      }
      if (count.derCount === 5) {
        x11 = 120;
        y11 = 285;
        x21 = 400;
        y21 = 285;
        x12 = 1120;
        y12 = 430;
        x22 = 570;
        y22 = 430;
        rectangleWidth = 600;
      }
      if (count.derCount === 6) {
        x11 = 120;
        y11 = 285;
        x21 = 400;
        y21 = 285;
        x12 = 1200;
        y12 = 430;
        x22 = 570;
        y22 = 430;
        rectangleWidth = 720;
      }
    }
  }
   else if (siteType === embLabels.GENSET_PVBESS_A2E) {
    x11 = 400;
    y11 = 360;
    x21 = 850;
    y21 = 360;
    rectangleWidth = 530
  }

  return {
    cooridinates: { x11, y11, x21, y21, x12, y12, x22, y22 },
    rectangleWidth,
    loadSide,
  };
};