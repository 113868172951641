import React, { useContext } from "react";

import isTouchDevice from "../SE-Component/Tooltip";
import embLabels from "../../config/emb-labels-en.json";
import { InformationPageContext } from "../CustomHooks/ContextApi";
import Tooltip from "../../utils/Tooltip";

const GenericSiteType = ({ isA2E }) => {
  const {
    projectType,
    informationToolTIP,
    siteType,
    handleSiteTypeChange,
    handlePackageMGSiteTypeChange,
    findProjectTypeHandler
  } = useContext(InformationPageContext);
  

const projectTypeValue = JSON.parse(localStorage.getItem("projectType"))?.projectTypeValue ;
  return ( 
    <>
      {projectType && (
        <div className="row">
          <div className="col-md-12 mt-1">
            <span className="labelCircle mr-2 ">2</span>{" "}
            {projectType === embLabels.STANDARD_MICROGRID ? (
              <span>Select your microgrid type</span>
            ) : (
              <span>Select your pre-configured site</span>
            )}
            <Tooltip
              position="right"
              iconColor="secondary"
              iconSize="small"
              information={projectType === embLabels.STANDARD_MICROGRID
                ? "Select your microgrid type."
                : "Select your pre-configured site."}
            />           
          </div>
        </div>
      )}

      {projectType === embLabels.STANDARD_MICROGRID && (
        <div className="row is-flex mt-2">
          <div className="col-md-4">
            <div
              className={`col-md-12 ${
                siteType === embLabels.GRID_CONNECTED
                  ? "border-green-radius-5px"
                  : "border-radius-5px"
              }`}
            >
              <div className="row align-items-center">
                <div className="col-md-2">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="customRadio1"
                        name="siteType"
                        className="custom-control-input islandableRadio asCompatible"
                        value="GRID_CONNECTED"
                        checked={
                          siteType === embLabels.GRID_CONNECTED ? true : false
                        }
                        onChange={handleSiteTypeChange}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customRadio1"
                      >
                        &zwnj;
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-10 p-2">
                  <div className="row">
                    <div className="col-md-12 mt-1">
                      <span className="bold">Always grid tied</span>
                    </div>
                    <div className="col-md-12 mt-1">
                      <span>
                        "{informationToolTIP.gridConnectedTooltipData}"
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* don't delete this code here */}
       { projectTypeValue !=="MGaaS IEC" &&   <div className={`col-md-4  ${projectTypeValue ==="MGaaS IEC"?"se-block-disabled":""}`}>
          {/* <div className={`col-md-4 `}> */}
            <div
              className={`col-md-12 ${
                siteType === embLabels.ISLANDABLE
                  ? "border-green-radius-5px"
                  : "border-radius-5px"
              }`}
            >
              <div className={`row align-items-center ${projectTypeValue ==="MGaaS IEC"?"mt-2":""}`}>
                <div className="col-md-2">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="customRadio2"
                        name="siteType"
                        className="custom-control-input islandableRadio asCompatible"
                        value="ISLANDABLE"
                        checked={
                          siteType === embLabels.ISLANDABLE ? true : false
                        }
                        onChange={projectTypeValue ==="MGaaS IEC"?undefined:handleSiteTypeChange}
                        // onChange={handleSiteTypeChange}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customRadio2"
                      >
                        &zwnj;
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-10 p-2">
                  <div className="row">
                    <div className="col-md-12 mt-1">
                      <span className="bold">Islandable site</span>
                    </div>
                    <div className="col-md-12 mt-1">
                    {/* <span>"{informationToolTIP.islandableTooltipData}"</span> */}
                     {projectTypeValue ==="MGaaS IEC"?<span>"Coming Soon H2 2024"</span>: <span>"{informationToolTIP.islandableTooltipData}"</span>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>}

         {(findProjectTypeHandler() === embLabels.GENERIC_PROJECT&& projectTypeValue!=="MGaaS ANSI" &&  projectTypeValue !=="MGaaS IEC") &&
          <div className={`col-md-4 ${projectTypeValue ==="MGaaS IEC"?"se-block-disabled":""}`}>
         {/* <div className={`col-md-4 `}> */}
            <div
              className={`col-md-12 ${
                siteType === embLabels.OFF_GRID
                  ? "border-green-radius-5px"
                  : "border-radius-5px"
              }`}
            >
              <div className={`row align-items-center  ${projectTypeValue ==="MGaaS IEC"?"mt-2":""}`}>
                <div className="col-md-2">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="customRadio3"
                        name="siteType"
                        className="custom-control-input islandableRadio asCompatible"
                        value="OFF_GRID"
                        checked={siteType === embLabels.OFF_GRID ? true : false}
                        onChange={projectTypeValue ==="MGaaS IEC"?undefined:handleSiteTypeChange}
                        // onChange={handleSiteTypeChange}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customRadio3"
                      >
                        &zwnj;
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-10 p-2">
                  <div className={`row `}>
                    <div className="col-md-12 mt-1">
                     <span className="bold">Always off grid site</span>
                    </div>
                    <div className="col-md-12 mt-1">
                    {/* <span>"{informationToolTIP.offGridTooltipData}"</span> */}
                     {projectTypeValue ==="MGaaS IEC" ?<span>"Coming Soon H2 2024"</span>:  <span>"{informationToolTIP.offGridTooltipData}"</span>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>}
        </div>
      )}

      {projectType === embLabels.PACKAGED_MICROGRID &&
        (!isA2E ? (
          <div className="row mt-2">
            <div className="col-md-4">
              <div
                className={`col-md-12 ${
                  siteType === embLabels.PV_HYBRIDIZATION
                    ? "border-green-radius-5px"
                    : "border-radius-5px"
                }`}
              >
                <div className="row align-items-center">
                  <div className="col-md-2">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio1"
                          name="siteType"
                          className="custom-control-input islandableRadio asCompatible"
                          value="PV_HYBRIDIZATION"
                          checked={
                            siteType === embLabels.PV_HYBRIDIZATION
                              ? true
                              : false
                          }
                          onChange={handlePackageMGSiteTypeChange}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadio1"
                        >
                          &zwnj;
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-10 p-2">
                    <div className="row">
                      <div className="col-md-12 mt-1">
                        <span className="bold">Genset/PV hybridization</span>
                      </div>
                      <div className="col-md-12 mt-1">
                        <span>
                          "{informationToolTIP.pvHybridizationToolTip}"
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row mt-2">
            <div className="col-md-4">
              <div
                className={`col-md-12 ${
                  siteType === embLabels.GENSET_PVBESS_A2E
                    ? "border-green-radius-5px"
                    : "border-radius-5px"
                }`}
              >
                <div className="row align-items-center">
                  <div className="col-md-2">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio5"
                          name="siteType"
                          className="custom-control-input islandableRadio asCompatible"
                          value="GENSET_PVBESS_A2E"
                          checked={
                            siteType === embLabels.GENSET_PVBESS_A2E
                              ? true
                              : false
                          }
                          onChange={handlePackageMGSiteTypeChange}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadio5"
                        >
                          &zwnj;
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-10 p-2">
                    <div className="row">
                      <div className="col-md-12 mt-1">
                        <span className="bold">Always off grid site</span>
                      </div>
                      <div className="col-md-12 mt-1">
                        <span>"{informationToolTIP.offGridTooltipData}"</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default GenericSiteType;
