import React from 'react'
import { useEffect } from 'react';
import ProgressBar from './progressBar';

const ProgressBarContainer = ({projectStatusData}) => {
 
  return ( 
    <div className='stickyProgressBar'>
      <div className="row wrap ml-0" style={{ backgroundColor:"white", paddingTop:"2px"}}>

      <div className="col-sm-12 col-md-4 col-lg-4 createdOn">
        <ProgressBar
          createdOn={projectStatusData ===undefined? "":projectStatusData?.createdOn}
          isShow="createdOn"
        />
      </div> 
      <div className="col-sm-12 col-md-4 col-lg-4 ">
        {projectStatusData ===undefined && <ProgressBar />}
        {projectStatusData?.projectStatus === "IN_PROGRESS" && (
          <ProgressBar
            data={
              projectStatusData?.projectStatus === "IN_PROGRESS"
                ? projectStatusData
                : {}
            }
          />
        )}

        {projectStatusData?.projectStatus === "COMPLETED" && (
          <ProgressBar statusName="In Progress " data={projectStatusData} />
        )}
      </div>
      <div className={`col-sm-12 col-md-4  col-lg-4 ${ projectStatusData?.projectStatus === "IN_PROGRESS"?"d-none":""}`}>
        <ProgressBar
          statusName="Completed "
          data={
            projectStatusData?.projectStatus === "COMPLETED"
              ? projectStatusData
              : {}
          }
        />
      </div>
      </div>
    </div>
  );
}

export default ProgressBarContainer;