import React from "react";

const MainLayout = props => {
  return (
    <se-container position="absolute" direction="column">
      <se-header app-title="Microgrid Build">
        <div slot="end"></div>
      </se-header>

      <se-tabbar color="primary"></se-tabbar>
      {props.children}
    </se-container>
  );
};

export default MainLayout;
