import React, { useContext } from "react";

import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import {
  SliderRail,
  KeyboardHandle,
  Track,
  Tick,
} from "../../components/component/SliderComponent";

import { DesignProgramContext } from "../CustomHooks/ContextApi";
import isTouchDevice from "../SE-Component/Tooltip";
import ErrorBoundary from '../../utils/ErrorBoundary'
import Tooltip from "../../utils/Tooltip";
import { generateRandom } from "../../utils/randomGenerator";
const sliderStyle = {
  position: "relative",
  width: "100%",
};

const LoadShedding = () => {
  const {
    loadsDisplay,
    loadShedding,
    loads,
    loadList,
    reconnectionTimers,
    defaultsMinMax,
    values,
    domain,
    batteryDetails,
    gensetDetails,
    BESS,
    powerReservePercentageBESS,
    genset,
    powerReservePercentageGenset,
    loadSheddingChangeHandler,
    onDrag,
    onDrop,
    onDragFrom,
    onDragOver,
    configuredStatusModified,
    handleChange,
    onUpdate,
    onChange,
    handleChangeBESS,
    handleChangePowerReservePercentage,
    handleChangeGenset,
  } = useContext(DesignProgramContext);

  const reconnectionTimerChangeHandler = e => {
    handleChange(e);
    configuredStatusModified("lsConfiguredStatus");
  };

  const BessChangeHandler = e => {
    handleChangeBESS(e);
    configuredStatusModified("lsConfiguredStatus");
  };

  const gensetChangeHandler = e => {
    handleChangeGenset(e);
    configuredStatusModified("lsConfiguredStatus");
  };

  const PowerChangeHandler = e => {
    handleChangePowerReservePercentage(e);
    configuredStatusModified("lsConfiguredStatus");
  };

  

  return (
    <ErrorBoundary>
    <>
      {loadsDisplay && (
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
          <div className="row mt-2">
            <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 mb-2 bold">
              Load Shedding Activation
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 mb-2 ">
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="customRadioL1"
                  name="loadSheddingStatus"
                  className="custom-control-input islandableRadio asCompatible"
                  value="Y"
                  checked={loadShedding.loadSheddingStatus === "Y"}
                  onChange={loadSheddingChangeHandler}
                />
                <label
                  className="custom-control-label line-height-25px"
                  htmlFor="customRadioL1"
                >
                  Yes
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="customRadioL2"
                  name="loadSheddingStatus"
                  className="custom-control-input islandableRadio asCompatible"
                  value="N"
                  checked={loadShedding.loadSheddingStatus === "N"}
                  onChange={loadSheddingChangeHandler}
                />
                <label
                  className="custom-control-label line-height-25px"
                  htmlFor="customRadioL2"
                >
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      )}

      {loadShedding.loadShedding === true && (
        <div className="col-md-12 mt-1">
          {loadsDisplay === true && (
            <div>
              <div className="row">
                <div className="col-md-12 mt-1 ">
                  <span>
                    Drag & drop your load accordingly with their sheddable
                    prioritization{" "}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-1">
                  <div
                    className="se-grey-background-loads  loads-min-height"
                    onDrop={event => onDrop(event, "loads")}
                    onDragOver={event => onDragOver(event)}
                  >
                    {loads.map(loadData =>
                      parseInt(
                        loadData.attributes[0].motorized.attributeValue
                      ) === 1 ? (
                        <span
                          key={loadData.resourceId}
                          className="se-chip align-middle "
                          draggable
                          onDrag={e => onDrag(e, loadData)}
                        >
                          <se-icon size="small" color="primary">
                            {loadData.resourceType === "Load"
                              ? "electrical_plug"
                              : "electrical_vehicle1"}
                          </se-icon>
                          <span className="align-top line-height-25">
                            {loadData.resourceName}
                          </span>
                        </span>
                      ) : (
                        ""
                      )
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 mt-1 ">
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm mt-2">
                      <thead>
                        <tr>
                          <th width="10%" className="text-center">
                            Priority
                          </th>
                          <th width="50%">Loads</th>
                          <th width="30%">Group</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-center">
                            {Array(loadList.emergencyList.length)
                              .fill(null)
                              .map((data, index) => (
                                <div
                                  className="col-md-12 min-height-35px  emergency bold"
                                  key={generateRandom() * 1000}
                                >
                                  {index + 1}
                                </div>
                              ))}
                          </td>
                          <td>
                            <div
                              className="col-md-12 loads-min-height"
                              onDrop={e => onDrop(e, "emergency")}
                              onDragOver={event => onDragOver(event)}
                            >
                              <div className="row">
                                {loadList.emergencyList.map(emergencyData => (
                                  <div
                                    className="col-md-12"
                                    key={emergencyData.resourceId}
                                  >
                                    <span
                                      className="se-chip align-middle "
                                      draggable
                                      onDrag={event =>
                                        onDragFrom(
                                          event,
                                          emergencyData,
                                          "emergency"
                                        )
                                      }
                                    >
                                      <se-icon size="small" color="primary">
                                        {emergencyData.resourceType === "Load"
                                          ? "electrical_plug"
                                          : "electrical_vehicle1"}
                                      </se-icon>
                                      <span className="align-top line-height-25">
                                        {emergencyData.resourceName}
                                      </span>
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </td>

                          <td className="align-middle">
                            <span className="align-middle emergency">
                              <se-icon size="small">
                                notification_critical
                              </se-icon>
                              Emergency
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td className="text-center">
                            {Array(loadList.essentialList.length)
                              .fill(null)
                              .map((data, index) => (
                                <div
                                  className="col-md-12 min-height-35px essential bold"
                                  key={generateRandom()* 1000}
                                >
                                  {loadList.emergencyList.length + index + 1}
                                </div>
                              ))}
                          </td>
                          <td>
                            <div
                              className="col-md-12 loads-min-height"
                              onDrop={event => onDrop(event, "essential")}
                              onDragOver={event => onDragOver(event)}
                            >
                              <div className="row">
                                {loadList.essentialList.map(
                                  (essentialData, index) => (
                                    <div
                                      className="col-md-12"
                                      key={essentialData.resourceId}
                                    >
                                      <span
                                        className="se-chip align-middle "
                                        draggable
                                        onDrag={event =>
                                          onDragFrom(
                                            event,
                                            essentialData,
                                            "essential"
                                          )
                                        }
                                      >
                                        <se-icon size="small" color="primary">
                                          {essentialData.resourceType === "Load"
                                            ? "electrical_plug"
                                            : "electrical_vehicle1"}
                                        </se-icon>
                                        <span className="align-top line-height-25">
                                          {essentialData.resourceName}
                                        </span>
                                      </span>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </td>

                          <td className="align-middle">
                            <span className="align-middle essential">
                              <se-icon size="small">
                                notification_critical
                              </se-icon>
                              Essential
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td className="text-center">
                            {Array(loadList.nonEssentialList.length)
                              .fill(null)
                              .map((data, index) => (
                                <div
                                  className="col-md-12 min-height-35px nonEssential bold"
                                  key={generateRandom() * 1000}
                                >
                                  {loadList.emergencyList.length +
                                    loadList.essentialList.length +
                                    index +
                                    1}
                                </div>
                              ))}
                          </td>
                          <td>
                            <div
                              className="col-md-12 loads-min-height"
                              onDrop={event => onDrop(event, "nonEssential")}
                              onDragOver={event => onDragOver(event)}
                            >
                              <div className="row">
                                {loadList.nonEssentialList.map(
                                  (nonEssentialData, index) => (
                                    <div
                                      className="col-md-12"
                                      key={nonEssentialData.resourceId}
                                    >
                                      <span
                                        className="se-chip align-middle "
                                        draggable
                                        onDrag={event =>
                                          onDragFrom(
                                            event,
                                            nonEssentialData,
                                            "nonEssential"
                                          )
                                        }
                                      >
                                        <se-icon size="small" color="primary">
                                          {nonEssentialData.resourceType ===
                                            "Load"
                                            ? "electrical_plug"
                                            : "electrical_vehicle1"}
                                        </se-icon>
                                        <span className="align-top line-height-25">
                                          {nonEssentialData.resourceName}
                                        </span>
                                      </span>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </td>

                          <td className="align-middle">
                            <span className="align-middle nonEssential">
                              <se-icon size="small">
                                notification_critical
                              </se-icon>
                              Non Essential
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                  <div className="form-group row">
                    <label
                      htmlFor="inputEmail11"
                      className="col-sm-7 col-form-label"
                    >
                      Delay before reconnecting a load after last shedding (s)
                      <span className="se-mandatory">*</span>
                    </label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        autoComplete="none"
                        className="form-control form-control-md"
                        name="reconnectionTimer"
                        placeholder="Delay before reconnecting a load after last shedding(s)"
                        maxLength="10"
                        value={reconnectionTimers.reconnectionTimer}
                        onChange={reconnectionTimerChangeHandler}
                      />
                      <div
                        className="invalid-feedback-custom"
                        style={
                          reconnectionTimers.reconnectionTimer.toString() !== ""
                            ? { display: "none" }
                            : {}
                        }
                      >
                        Delay before reconnecting a load after last shedding(s)
                        is required.
                      </div>

                      {reconnectionTimers.reconnectionTimer.toString() !== "" &&
                        (parseInt(reconnectionTimers.reconnectionTimer) <
                          defaultsMinMax.delayReconnectingMin ||
                          parseInt(reconnectionTimers.reconnectionTimer) >
                          defaultsMinMax.delayReconnectingMax) ? (
                        <div className="invalid-feedback-custom">
                          Delay before reconnecting a load after last
                          shedding(s) should be{" "}
                          {defaultsMinMax.delayReconnectingMin} -{" "}
                          {defaultsMinMax.delayReconnectingMax} s.
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                  <div className="form-group row">
                    <label
                      htmlFor="inputEmail11"
                      className="col-sm-7 col-form-label"
                    >
                      Delay before reconnecting a load after last load
                      reconnection (s)
                      <span className="se-mandatory">*</span>
                    </label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        autoComplete="none"
                        className="form-control form-control-md"
                        name="reconnection2Timer"
                        placeholder="Delay before reconnecting a load after last load reconnection(s)"
                        maxLength="10"
                        value={reconnectionTimers.reconnection2Timer}
                        onChange={reconnectionTimerChangeHandler}
                      />
                      <div
                        className="invalid-feedback-custom"
                        style={
                          reconnectionTimers.reconnection2Timer.toString() !==
                            ""
                            ? { display: "none" }
                            : {}
                        }
                      >
                        Delay before reconnecting a load after last load
                        reconnection(s) is required.
                      </div>
                      {reconnectionTimers.reconnection2Timer.toString() !==
                        "" &&
                        (parseInt(reconnectionTimers.reconnection2Timer) <
                          defaultsMinMax.delayReconnecting2Min ||
                          parseInt(reconnectionTimers.reconnection2Timer) >
                          defaultsMinMax.delayReconnecting2Max) ? (
                        <div className="invalid-feedback-custom">
                          Delay before reconnecting a load after last load
                          reconnection(s) should be{" "}
                          {defaultsMinMax.delayReconnecting2Min} -{" "}
                          {defaultsMinMax.delayReconnecting2Max} s.
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {batteryDetails.batteryBuffering && (
            <div className="row">
              <div className="col-md-12 mt-2">
                <div className="row">
                  <div className="col-md-6">
                    <span className="se-font-14px-bold ">
                      BESS Load and Energy Management
                    </span>
                    <Tooltip
                    position="top"
                    iconColor="secondary"
                    information="Load shedding applies to BESS state of charge when BESS is connected without genset. Groups of loads are shed based on State of charge thresholds. In addition, the controls ensure there is enough power reserve in the system. 
                    When BESS is overloaded, the control system starts shedding the load by priorities."
                  />                      
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pl-3 mt-2">
                    <span>
                      "Load Shedding depending on BESS State of Charge"
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 pr-5 mt-3">
                    {values !== "" ? (
                      <div
                        style={{
                          height: 120,
                          width: "100%",
                        }}
                      >
                        <Slider
                          mode={2}
                          step={1}
                          domain={domain}
                          rootStyle={sliderStyle}
                          onUpdate={onUpdate}
                          onChange={onChange}
                          values={values}
                        >
                          <Rail>
                            {({ getRailProps }) => (
                              <SliderRail getRailProps={getRailProps} />
                            )}
                          </Rail>
                          <Handles>
                            {({ handles, getHandleProps }) => (
                              <div className="slider-handles">
                                {handles.map((handle, index) =>
                                  index === 0 || index === 4 ? (
                                    <KeyboardHandle
                                      key={handle.id}
                                      handle={handle}
                                      domain={domain}
                                      getHandleProps={function () { }}
                                      values={values}
                                      index={index}
                                    />
                                  ) : (
                                    <KeyboardHandle
                                      key={handle.id}
                                      handle={handle}
                                      domain={domain}
                                      getHandleProps={getHandleProps}
                                      values={values}
                                      index={index}
                                    />
                                  )
                                )}
                              </div>
                            )}
                          </Handles>

                          <Tracks left={false} right={false}>
                            {({ tracks, getTrackProps }) => (
                              <div className="slider-tracks">
                                {tracks.map(({ id, source, target }) => (
                                  <Track
                                    key={id}
                                    source={source}
                                    target={target}
                                    getTrackProps={function () { }}
                                  />
                                ))}
                              </div>
                            )}
                          </Tracks>

                          <Ticks count={10}>
                            {({ ticks }) => (
                              <div className="slider-ticks">
                                {ticks.map(tick => (
                                  <Tick
                                    key={tick.id}
                                    tick={tick}
                                    count={ticks.length}
                                  />
                                ))}
                              </div>
                            )}
                          </Ticks>
                        </Slider>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-1 text-center pr-0 my-auto ">
                    <span className="se-legend-soc socMin"> </span>
                    &nbsp;
                    <span>
                      SoC Min<br></br>
                      {parseInt(
                        (parseInt(BESS.capacity) / 100) * parseInt(BESS.soc_min)
                      )}{" "}
                      Kwh
                    </span>
                  </div>
                  <div className="col-md-3 text-center my-auto ">
                    <span className="se-legend-soc soc1"> </span>
                    &nbsp;
                    <span>
                      Essential load shedding threshold
                      <br></br>
                      {parseInt(
                        (parseInt(BESS.capacity) / 100) *
                        parseInt(BESS.soc_emergency)
                      )}{" "}
                      Kwh
                    </span>
                  </div>
                  <div className="col-md-3 text-center my-auto ">
                    <span className="se-legend-soc soc2"> </span>
                    &nbsp;
                    <span>
                      Non-essential load shedding threshold
                      <br></br>
                      {parseInt(
                        (parseInt(BESS.capacity) / 100) *
                        parseInt(BESS.soc_essential)
                      )}{" "}
                      Kwh
                    </span>
                  </div>
                  <div className="col-md-3 text-center my-auto ">
                    <span className="se-legend-soc soc3"> </span>
                    &nbsp;
                    <span>
                      Non-essential reconnection threshold
                      <br></br>
                      {parseInt(
                        (parseInt(BESS.capacity) / 100) *
                        parseInt(BESS.soc_hysteresis)
                      )}{" "}
                      Kwh
                    </span>
                  </div>
                  <div className="col-md-1 text-center p-0  my-auto ">
                    <span className="se-legend-soc socMax"> </span>
                    &nbsp;
                    <span>
                      SoC Max <br></br>
                      {parseInt(
                        (parseInt(BESS.capacity) / 100) * parseInt(BESS.soc_max)
                      )}{" "}
                      Kwh
                    </span>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail11"
                            className="col-sm-7 col-form-label"
                          >
                            BESS Power reserve in kW{" "}
                            <span className="se-mandatory">*</span>
                            <Tooltip
                              position="right"
                              iconColor="secondary"
                              information="The power reserve is the percentage of DER power
                              that is kept as a reserve for site step loads.
                              When the real power reserve is smaller than this
                              power reserve, no more loads are reconnected."
                            />  
                           
                          </label>
                          <div className="col-sm-5">
                            <input
                              type="text"
                              autoComplete="none"
                              className="form-control form-control-md"
                              name="powerReserve"
                              placeholder="BESS Power reserve in kw"
                              maxLength="10"
                              value={BESS.powerReserve}
                              onChange={BessChangeHandler}
                            />

                            {BESS.powerReserve.toString() === "" ? (
                              <div className="invalid-feedback-custom">
                                BESS Power reserve in kw is required.
                              </div>
                            ) : (parseFloat(BESS.powerReserve) /
                              parseFloat(BESS.max_power)) *
                              100 >
                              50.0 ||
                              (parseFloat(BESS.powerReserve) /
                                parseFloat(BESS.max_power)) *
                              100 <
                              0 ? (
                              <div className="invalid-feedback-custom">
                                BESS Power reserve should not exceed more than
                                50% Max Power(kW) .
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail11"
                            className="col-sm-7 col-form-label"
                          >
                            BESS Power reserve(% of max power)
                            <Tooltip
                              position="right"
                              iconColor="secondary"
                              information="  The power reserve is the percentage of DER power
                              that is kept as a reserve for site step loads.
                              When the real power reserve is smaller than this
                              power reserve, no more loads are reconnected."
                            />  
                           
                          </label>
                          <div className="col-sm-5">
                            <input
                              type="text"
                              autoComplete="none"
                              className="form-control form-control-md"
                              name="powerReservePercentageBESS"
                              placeholder="BESS Power reserve(% of max power)"
                              maxLength="10"
                              value={powerReservePercentageBESS}
                              onChange={PowerChangeHandler}
                            />

                            {(parseFloat(BESS.powerReserve) /
                              parseFloat(BESS.max_power)) *
                              100 >
                              50.0 ||
                              (parseFloat(BESS.powerReserve) /
                                parseFloat(BESS.max_power)) *
                              100 <
                              0 ? (
                              <div className="invalid-feedback-custom">
                                BESS Power reserve should not exceed more than
                                50% Max Power(kW) .
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {gensetDetails.gensetAvailabilityStatus && (
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="row">
                  <div className="col-md-12">
                    <span className="se-font-14px-bold ">
                      Genset Load Management
                    </span>
                    <Tooltip
                    position="bottom"
                    iconColor="secondary"
                    information="Load shedding applies to genset power when it is the main anchor resource.
                     When the genset is overloaded, control system starts load shedding by priorities."
                  />  
                   
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail11"
                            className="col-sm-7 col-form-label"
                          >
                            <span className="line-height-25px">
                              Genset Power reserve in kW{" "}
                            </span>
                            <span className="se-mandatory">*</span>
                            <Tooltip
                              position="right"
                              iconColor="secondary"
                              information="The power reserve is the percentage of DER power
                              that is kept as a reserve for site step loads.
                              When the real power reserve is smaller than this
                              power reserve, no more loads are reconnected."
                            />                              
                          </label>
                          <div className="col-sm-5">
                            <input
                              type="text"
                              autoComplete="none"
                              className="form-control form-control-md"
                              name="powerReserve"
                              placeholder="Genset Power reserve in kW"
                              maxLength="10"
                              value={genset.powerReserve}
                              onChange={gensetChangeHandler}
                            />
                            {genset.powerReserve.toString() === "" ? (
                              <div className="invalid-feedback-custom">
                                Genset Power reserve in kW is required.
                              </div>
                            ) : (parseFloat(genset.powerReserve) /
                              parseFloat(genset.max_power)) *
                              100 >
                              50.0 ||
                              (parseFloat(genset.powerReserve) /
                                parseFloat(genset.max_power)) *
                              100 <
                              0 ? (
                              <div className="invalid-feedback-custom">
                                Genset Power reserve should not exceed more than
                                50% Max Power(kW) .
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail11"
                            className="col-sm-7 col-form-label"
                          >
                            <span className="line-height-25px">
                              Genset Power reserve(% of max power)
                            </span>
                            <Tooltip
                                position="right"
                                iconColor="secondary"
                                information="  The power reserve is the percentage of DER power
                                that is kept as a reserve for site step loads.
                                When the real power reserve is smaller than this
                                power reserve, no more loads are reconnected."
                              />                              
                          </label>
                          <div className="col-sm-5">
                            <input
                              type="text"
                              autoComplete="none"
                              className="form-control form-control-md"
                              name="powerReservePercentageGenset"
                              placeholder="Genset Power reserve(% of max power)"
                              maxLength="10"
                              value={powerReservePercentageGenset}
                              onChange={PowerChangeHandler}
                            />
                            {(parseFloat(genset.powerReserve) /
                              parseFloat(genset.max_power)) *
                              100 >
                              50.0 ||
                              (parseFloat(genset.powerReserve) /
                                parseFloat(genset.max_power)) *
                              100 <
                              0 ? (
                              <div className="invalid-feedback-custom">
                                Genset Power reserve should not exceed more than
                                50% Max Power(kW) .
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
    </ErrorBoundary>
  );
};

export default LoadShedding;
