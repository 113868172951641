import React, { useContext } from "react";
import useCBMouserOver from "../../pages/CustomHooks/useCBMouserOver";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";


const Linebar = () => {
  const {
    CBAssetList: [{ uiData, cbConfigData }],
    UserScope,
    assetDetailsDisplay,
    CBAssetListDispatch,
    assetDetailsReset,
    callSnackbar,
    selectedValuesHandler, 
    assetConfigDataHandler,
    setAssetDetailsDisplay,
    pmConfigDataHandler,
    newSBCbConfigDataHandler,
    detailsBlock

  } = useContext(DesignAssetContext);

  const [mouseOverData, CBMouseHandler] = useCBMouserOver();
  const [mouseOverDataHardWire, CBMouseHandlerHardWire] = useCBMouserOver();

  const utilityCBSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      // const assetBlock = "cbAssetDetailsBlock";
      const assetBlock = "newSBCbAssetDetailsBlock";
      const selectedAssetValue = "selectedAssetCB";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_CB_SELECT,
        payload: {
          index: 0,
        },
      });
      selectedValuesHandler(0, selectedAssetValue, assetBlock);
      // cbConfigDataHandler(4);
      newSBCbConfigDataHandler(0)
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const hardWiredCbSelect= () => {
    assetDetailsReset()
    if (UserScope.access.saveSLD) { 
     
      const assetBlock = "hardWiredCbDetailsBlockGrid";
      const selectedAssetValue = "";
      selectedValuesHandler(null, selectedAssetValue, assetBlock);
  
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  }
  return (
    <svg>
      <g id="linebar">
        <g id="lines">
          <line
            x1="310"
            y1="270"
            x2="696"
            y2="220"
            stroke="#333333"
            strokeWidth="3"
          />
          <line
            x1="696"
            y1="228.5"
            x2="695"
            y2="331"
            stroke="#333333"
            strokeWidth="3"
          />
           
         
         


           <g id="CB3">
        <svg
          width="75px"
          height="100px"
          viewBox="0 0 10 149"
          x={"660"}
          y={"330"}
         
        >
          
          <g
            id="Circuit Breaker"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Circuit Breaker 1"
              transform="translate(-651.000000, -512.000000)"
            >
              <g
                id="Circuit Breaker - closed"
                transform="translate(604.000000, 514.000000)"
              
              >
                <g
                  id="Group-15"
                  strokeWidth="4"
                  fillRule="evenodd"
                  fill={
                   
                         "none"
                  }
                  stroke={ "none"}
                >
                
                  <g
                    id="Group-2"
                    transform="translate(48.000000, 0.000000)"
                    stroke={  "#333333"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="4"
                  >
                    <line
                      x1="0.5"
                      y1="0"
                      x2="0.5"
                      y2="47.8298611"
                      id="Line-2"
                    ></line>
                  </g>
                </g>
                <line
                  x1="48.5"
                  y1="97.1701389"
                  x2="48.501111"
                  y2="145"
                  id="Line-2-Copy-15"
                  stroke={  "#333333"}
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <path
                  d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                  id="Path-11"
                  strokeWidth="4"
                  strokeLinecap="round"
                  stroke={  "#333333"}
                ></path>
              </g>
            </g>
          </g>
        </svg>


        
      </g>





          <g id="power meter" className="pointer">
          <svg
            x="519"
            y="247"
            
            width="300px"
            height="140px"
            viewBox="0 0 100 160"
            onClick={hardWiredCbSelect}
          >
            <title>PR</title>

            <line
              x1="30"
              y1="127"
              x2="80"
              y2="127"
              stroke="#333"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle
              id="Pm outer"
              stroke="#42B4E6"
              strokeWidth="3"
              cx="10"
              cy="127"
              r="26"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="rgba(0, 135, 205, 0.2)"
              style={ detailsBlock.hardWiredCbDetailsBlockGrid ? {} : { display: "none" }}
            ></circle>
            <circle
              id="pm inner"
              stroke="#333"
              strokeWidth="3"
              cx="10"
              cy="127"
              r="18.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="#FFFFFF"
            ></circle>
            <text
              id="PM"
              fontSize="16"
              fontWeight="normal"
              stroke="#333"
              fontFamily="Nunito-Regular, Nunito"
              fill="#333"
            >
              <tspan x="0" y="133">
                PR
              </tspan>
            </text>
          </svg>
        </g>


          <line
            x1="250.5"
            y1="170"
            x2="250.5"
            y2="200"
            stroke="#333333"
            strokeWidth="3"
          />
          <line
            x1="372"
            y1="170"
            x2="372"
            y2="200"
            stroke="#333333"
            strokeWidth="3"
          />
          <line
            x1="251"
            y1="200"
            x2="310"
            y2="230"
            stroke="#333333"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="310"
            y1="230"
            x2="310"
            y2="272"
            stroke="#333333"
            strokeWidth="3"
          />
          <rect
            id="Rectangle-Copy-24"
            stroke="#333333"
            fill="none"
            strokeWidth="3"
            x="230"
            y="188"
            width="160"
            height="60"
            rx="20"
          />

          <g id="CB1">
            <svg
              width="75px"
              height="90px"
              viewBox="0 0 10 149"
              x="269.5"
              y="270"
              onClick={utilityCBSelectHandler}
            >
              <title>{cbConfigData.name}</title>
              <g id="Circuit Breaker" fill="none" fillRule="evenodd">
                <g
                  id="Circuit Breaker 1"
                  // transform="translate(-651.000000, -512.000000)"
                  onMouseOver={CBMouseHandler}
                  onMouseOut={CBMouseHandler}
                >
                  <g
                    id="Circuit Breaker - closed"
                    // transform="translate(604.000000, 514.000000)"
                    fill={
                      uiData.CBSelected
                        ? "rgba(65, 181, 230, 0.35)"
                        : mouseOverData
                          ? "rgba(65, 181, 230, 0.35)"
                          : "none"
                    }
                    stroke={uiData.CBSelected ? "#42B4E6" : "none"}
                  >
                    <g id="Group-15" strokeWidth="4" fillRule="evenodd">
                      <rect
                        id="Rectangle-Copy-20"
                        x="-20"
                        y="0"
                        width="70"
                        height="100"
                      ></rect>

                    </g>
                    <line
                      y2="25.5"
                      x2="10"
                      y1="2.5"
                      x1="10"
                      strokeWidth="4"
                      stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                      fill="none"
                    />
                    <path
                      d="m10.5,54.0922l1,30"
                      strokeWidth="4"
                      stroke="#000"
                      fill="none"
                    />
                    <path
                      d="m10.11208,24.50709c15.60284,21.98582 0.70922,31.20567 0.70922,31.20567"
                      strokeWidth="4"

                      fill="none"
                      stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                    />
                    <line
                      y2="105"
                      x2="11.5"
                      y1="84"
                      x1="11.5"
                      strokeWidth="4"
                      stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                      fill="none"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Linebar;


