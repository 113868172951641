import React from "react";
import PropTypes from "prop-types";
import "./Accordion.scss";
import MyErrorBoundary from "../../utils/ErrorBoundary";

const ProjectsAccordion = ({ label, children , addItem = false, handleAddItem }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClickLabel = () => {
    setIsOpen(!isOpen);
  };

  return (
    <MyErrorBoundary>

    <div
      className={
        isOpen ? "accordionOpen  mb-1 w-100" : "accordionClose  mb-1 w-100"
      }
      style={{ padding: "0 15px" }}
    >
      <div className="accordionBorder">
        <div
          onClick={onClickLabel}
          className="col-md-12  w-100 py-2 "
          style={{ cursor: "pointer" }}
        >
          <div className="row align-items-center justify-content-between">
            <div className={`text-left ml-2`}>
              {!isOpen && <se-icon size="nano">arrow3_down</se-icon>}
              {isOpen && <se-icon size="nano">arrow3_up</se-icon>}
              <span className="ml-3 se-life-green se-font-14px-bold">
                {label}
              </span>
            </div>

            <div className="mr-4">
            {addItem &&
            <se-icon color="primary" onClick={handleAddItem}>action_add_flat</se-icon>
            }
            </div>
          </div>
        </div>
        {isOpen && <div className="accordionOpen">{children}</div>}       

      </div>
    </div>
    </MyErrorBoundary>
  );
};

export default ProjectsAccordion;

ProjectsAccordion.propTypes = {
  children: PropTypes.instanceOf(Object),
};
