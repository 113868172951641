import React, { useState } from "react";
import embLabels from "../../config/emb-labels-en.json";
const RoutingPlcTable = (props) => {
  const { data, plcData, upDatePlc, componentId, cancelUpdate } = props;

  const newData = { ...data };
  const [selectedVlanName, setSelectedVlanName] = useState(
    newData?.vlanToRoute
  );
  const [selectedInterface, setSelectedInterface] = useState(
    newData?.interfaceVal
  );
  // const cancelUpdateHandler = () => cancelUpdate(data.id);
  return (
    <>
      {/* {false && (
        <tr className="text-center">
          <td>
            <select
              className="col-sm-12 col-lg-12 col-md-12 col-xl-12  form-control form-control-sm"
              name="vlanToRoute"
              value={selectedVlanName}
              onChange={(e) => {
                setSelectedVlanName(e.target.value);
                upDatePlc(data, data.secureComArchId, e);
              }}
            >
              {plcData?.map((vlanData) => (
                <option
                  key={vlanData.secureComArchId}
                  value={vlanData.vlanToRoute}
                >
                  {vlanData.vlanToRoute}
                </option>
              ))}
            </select>
          </td>
          <td>
            {
              plcData?.filter(
                (data) => data.vlanToRoute === selectedVlanName
              )[0].destinationIpAddress
            }
          </td>
          <td>{data.netmask}</td>
          <td>
            <select
              className="col-sm-12 col-lg-12 col-md-12 col-xl-12  form-control form-control-sm"
              name="interfaceVal"
              value={selectedInterface}
              onChange={(e) => {
                setSelectedInterface(e.target.value);
                upDatePlc(data, data.secureComArchId, e);
              }}
            >
              <option value="Eth1">Eth1</option>
              <option value="Eth2">Eth2</option>
            </select>
          </td>
          <td>
            {
              plcData?.filter(
                (data) => data.interfaceVal === selectedInterface
              )[0].gateWayViaVlan
            }
          </td>
          <td>
            {console.log("componentId[data.id]", componentId[data.id])}
            {componentId[data.id] ? (
              <>
                <se-icon
                  size="medium"
                  color="primary"
                  title="Click to update"
                  onClick={""}
                  disabled={true}
                >
                  notification_ok_stroke
                </se-icon>
                <se-icon
                  size="medium"
                  title="Click to cancel"
                  onClick={cancelUpdateHandler}
                >
                  action_delete_cross
                </se-icon>
              </>
            ) : (
              <se-icon size="medium" disabled={true}>
                notification_ok_stroke
              </se-icon>
            )}
          </td>
        </tr>
      )} */}
      <tr className="text-center">
        <td>{newData.vlanToRoute}</td>
        <td>{newData.destinationIpAddress}</td>
        <td>{newData.netmask}</td>
        <td>{newData.interfaceVal}</td>
        <td>{newData.gateWayViaVlan}</td>
        <td>
          <se-icon size="medium" disabled={true}>
            notification_ok_stroke
          </se-icon>
        </td>
      </tr>
    </>
  );
};

export default RoutingPlcTable;
