
import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";
import embLabels from "../../config/emb-labels-en.json";
const Busbar = ({ secondBusbar }) => {
  const {
    CBAssetList,
    coordinates: { busbarCordinates },
    siteType,
    switchboardType,
    loadSide 
  } = useContext(DesignAssetContext);
  return (
    <>
      {(["GENSET_PVBESS_A2E","GRID_CONNECTED_GSX","ISLANDABLE_GSX","GRID_CONNECTED","PV_HYBRIDIZATION","ISLANDABLE"].includes(siteType)  ) &&
      (switchboardType === "" || switchboardType !== embLabels.EXISTING_SWITCHBOARD) ||(siteType ==="GRID_CONNECTED" && switchboardType === embLabels.EXISTING_SWITCHBOARD)? (
        <svg>

          <g id="busbar" className="a2elll">
            <line
              x1={busbarCordinates.x11}
              y1={busbarCordinates.y11}
              x2={busbarCordinates.x21}
              y2={busbarCordinates.y21}
              stroke="#333333"
              strokeWidth="3"
            />
            {secondBusbar && (
              <line
                x1={busbarCordinates.x12}
                y1={loadSide==="LOAD_SIDE" ?"470":busbarCordinates.y12}
                x2={parseInt(busbarCordinates.x22)}
                y2={loadSide==="LOAD_SIDE" ?"470":busbarCordinates.y22}
                stroke="#333333"
                strokeWidth="3"
              />
            )}
          </g>
        </svg>
      ) : (
        
        <svg>
          <g id="busbar" className="A2E">
            <line
            x1={busbarCordinates.x11}
            y1={busbarCordinates.y11}
            x2={busbarCordinates.x21}
            y2={busbarCordinates.y21}
              // x1="98"
              // y1="300"
              
              // x2={((switchboardType !== "" ||switchboardType === embLabels.NEW_SWITCHBOARD) && siteType === "ISLANDABLE_GSX") ? Number(busbarCordinates.x22) + 130 : Number(busbarCordinates.x22)+30}
              // y2="300"
              stroke="#333333"
              strokeWidth="3"
            />
          </g>
        </svg>
      )}
    </>
  );
};

export default Busbar;