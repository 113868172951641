import React, { useContext, useEffect, useState } from "react";
import embLabels from "../../config/emb-labels-en.json";

import { AssetBlockContext } from "../CustomHooks/ContextApi";
import ACTIONS from "./Design_Asset_Actions.json";


import isTouchDevice from "../SE-Component/Tooltip";
import Tooltip from "../../utils/Tooltip";

const HybridAsset = () => {


  const {
    configData,
    handleHybridChange,
    defaultsMinMax,
    assetDetailsDisplay,
    updateHybridConfig,
    setAssetSelection,
    CBAssetList,

    handleChangeBatteryRack,
    batteryRack,
    namePlateEnergy,
  } = useContext(AssetBlockContext);

const storageCapcityHr = typeof (configData.configData?.storage_capcity_hr) === "string" ?JSON.parse(configData.configData?.storage_capcity_hr):configData.configData?.storage_capcity_hr;
const hybridChangeHandler = (e) => {
    handleHybridChange(e);
  };

  const setAssetHandler = () => {
  
    setAssetSelection(embLabels.HYBRID);
  };
  
  return (
    <div name="hybrid" className="col-md-12 p-0">
      <div className="col-md-12 pt-2 pl-4 se-white-background">
        <div className="row">
          <div className="col-md-8 pb-2 pl-0 mt-3">
            <span>
              <se-icon color="primary" size="medium">
                battery2_general
              </se-icon>
            </span>
            <span className="hybrid__icon--modified">
              <se-icon color="primary" size="medium">
                solar_panel1
              </se-icon>
            </span>

            <span className="ml-2 bold align-top line-height-30px">Hybrid</span>
          </div>
        </div>
      </div>

      <div className="col-md-12 col-sm-12 pt-2 pb-2 se-grey-background se-border">
        <div
          className="row"
          style={assetDetailsDisplay.manufacturer ? {} : { display: "none" }}
        >
          <div className="col-md-3">
            <span className="hybrid__icon">
              <se-icon color="primary" size="medium">
                solar_panel1
              </se-icon>
            </span>
            <span>
              <se-icon color="primary" size="medium">
                battery2_general
              </se-icon>
            </span>
          </div>

          <div className="col-md-9">
            <div className="row">
              <div className="col-md-10 bold ml-2">
                {assetDetailsDisplay.manufacturer}
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 ml-2 text-break">
                {assetDetailsDisplay.communicationModel}
                <div className="bold">Hybrid System</div>
              </div>
              <div className="col-md-2">
                <Tooltip
                  position="left"
                  iconColor="secondary"
                  information="Hybrid"
                />                
              </div>
              <div className="row">
                <div className=" col-md col-sm col-lg text-center">
                  <se-button
                    option="outline"
                    size="small"
                    onClick={setAssetHandler}
                  >
                    Asset Selection
                  </se-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="col-md-12 se-white-background align-middle se-border">
          <div className="row">
            <label className=" col-md-6 bold mt-2 mb-2 pl-2 se-font-14px">
              Asset Settings
            </label>

            <div className="col-md-6 mt-1 text-right">
              <button
                type="button"
                className="btn btn-secondary btn-sm se-btn-primary"
                onClick={updateHybridConfig}
              >
                <se-icon size="nano">action_save</se-icon>
                <span>&nbsp;</span>Save
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-12 pt-2 pb-2 se-grey-background">
          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">
              Name <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput1"
              name="name"
              maxLength="50"
              placeholder="Name"
              value={configData.configData.name}
              onChange={hybridChangeHandler}
            />
            <div
              className="invalid-feedback-custom"
              style={!configData.configData.name ? {} : { display: "none" }}
            >
              {embLabels.nameRequired}
            </div>
          </div>
          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">Description</label>
            <input
              type="text"
              autoComplete="none"
              className="form-control form-control-sm"
              id="exampleFormControlInput1"
              name="description"
              maxLength="100"
              placeholder="Description"
              value={configData.configData.description}
              onChange={hybridChangeHandler}
            />
          </div>

          <div className="form-group mb-1">
            <label htmlFor="hybrid_pcu_ac_rated_power">
              Select Hybrid PCU AC Rated Power (kVA){" "}
              <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput2"
              name="capacity"
              maxLength="10"
              disabled
              placeholder="Capacity (kWh)"
              value={configData.configData.capacity}
              onChange={hybridChangeHandler}
            />
            {configData.configData.capacity === "" ? (
              <div className="invalid-feedback-custom">
                Hybrid PCU AC Rated Power (kVA) is required.
              </div>
            ) : (
              ""
            )}

            {configData.configData.capacity !== "" &&
            (parseInt(configData.configData.capacity) <
              defaultsMinMax.hybridCapacityMin ||
              parseInt(configData.configData.capacity) >
                defaultsMinMax.hybridCapacityMax) ? (
              <div className="invalid-feedback-custom">
                Capacity should be in range(
                {defaultsMinMax.hybridCapacityMin}-
                {defaultsMinMax.hybridCapacityMax} KWh).
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="form-group mb-1">
            <label htmlFor="storageCapacityHr">
              Select Storage Capacity(hr.){" "}
              <span className="se-mandatory">*</span>
              <Tooltip
                  position="left"
                  iconColor="secondary"
                  information="Storage capacities are defined here for 90% dept of
                  discharge of the battery"
                />
             
            </label>

            <select
              className="form-control form-control-sm"
              name="usableStorageCapacityHr"
              id="storageCapacityHr"
              value={namePlateEnergy}
              onChange={handleChangeBatteryRack}
            >
              {configData.configData?.storage_capcity_hr &&
                storageCapcityHr.map((hour) => (
                  <option value={hour.toFixed(1)} key={hour}>
                    {hour.toFixed(1)}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group mb-1">
            <label htmlFor="batteryrackselect">
              Corresponding Name Plate Capacity (kWh){" "}
              <span className="se-mandatory">*</span>
            </label>

            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput2"
              name="capacity"
              maxLength="10"
              disabled
              placeholder="Capacity (kWh)"
              value={
                configData.configData.corresponding_name_plate_capacity_value
              }
            />
          </div>

          {/* <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">
              Max Battery Capacity (kW DC){" "}
              <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput1"
              name="max_capacity"
              maxLength="10"
              disabled
              placeholder="Max battery capacity (kW DC)"
              value={configData.configData.max_capacity}
              onChange={hybridChangeHandler}
            />
            <div
              className="invalid-feedback-custom"
              style={
                !configData.configData.max_capacity ? {} : { display: "none" }
              }
            >
              Max battery capacity (kW DC).
            </div>
            {configData.configData.max_capacity !== "" &&
            (parseInt(configData.configData.max_capacity) <
              defaultsMinMax.hybridMaxCapacityMin ||
              parseInt(configData.configData.max_capacity) >
                defaultsMinMax.hybridMaxCapacityMax) ? (
              <div className="invalid-feedback-custom">
                Max battery capacity should be in range(
                {defaultsMinMax.hybridMaxCapacityMin}-
                {defaultsMinMax.hybridMaxCapacityMax} kW DC).
              </div>
            ) : (
              ""
            )}
          </div> */}

          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">
              Max Solar Power (KWp) <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput1"
              name="max_solar_power"
              maxLength="10"
              disabled
              placeholder="Max Solar Power (KWp)"
              value={configData.configData.max_solar_power}
              onChange={hybridChangeHandler}
            />
            <div
              className="invalid-feedback-custom"
              style={
                !configData.configData.max_solar_power
                  ? {}
                  : { display: "none" }
              }
            >
              Max Solar Power (KWp) is required
            </div>
            {configData.configData.max_solar_power !== "" &&
              (parseInt(configData.configData.max_solar_power) <
                defaultsMinMax.hybridMaxSolarPowerMin ||
                parseInt(configData.configData.max_solar_power) >
                defaultsMinMax.hybridMaxSolarPowerMax) ? (
              <div className="invalid-feedback-custom">
                Max Inverter output power(
                {defaultsMinMax.hybridMaxSolarPowerMin}-
                {defaultsMinMax.hybridMaxSolarPowerMax} kW AC).
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput2">
              Recommended Solar Power (kWp){" "}
              <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput2"
              name="max_inverter_output_power"
              maxLength="10"
              disabled
              placeholder="Max Inverter Output Power (kW AC)"
              value={configData.configData.max_inverter_output_power}
              onChange={hybridChangeHandler}
            />
            {configData.configData.max_inverter_output_power === "" ? (
              <div className="invalid-feedback-custom">
                Max Inverter Output Power (kW AC) is required.
              </div>
            ) : (
              ""
            )}

            {configData.configData.max_inverter_output_power !== "" &&
              (parseInt(configData.configData.max_inverter_output_power) <
                defaultsMinMax.hybridInverterPowerMin ||
                parseInt(configData.configData.max_inverter_output_power) >
                defaultsMinMax.hybridInverterPowerMax) ? (
              <div className="invalid-feedback-custom">
                Max Inverter output power(
                {defaultsMinMax.hybridInverterPowerMin}-
                {defaultsMinMax.hybridInverterPowerMax} kW AC).
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HybridAsset;
