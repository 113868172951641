import React, { useContext } from "react";
import { AssetBlockContext } from "../CustomHooks/ContextApi";
import embLabels from "../../config/emb-labels-en.json";
import Tooltip from "../../utils/Tooltip";
import { useSelector } from "react-redux";

const CBConfigDetails = () => {
  const {
    CBAssetList,
    updateCbConfigData,
    configData,
    handleCBConfigChange,
    assetEditStatusUpdateAssetCB,
    selectedValues,
    siteType,
    clientType,
  } = useContext(AssetBlockContext);
  const tooltipData = useSelector(state=>state?.tooltipData?.tooltipData)
  const cbConfigChangeHandler = e => { 
    handleCBConfigChange(e);
    assetEditStatusUpdateAssetCB(e, true, selectedValues?.selectedAssetCB);
  };
  const projectType = JSON.parse(localStorage.getItem("projectType"))?.projectTypeValue
 
const assetInfo = CBAssetList[selectedValues?.selectedAssetCB]?.cbConfigData
?.cbAssetDetails[0]?.assetInfo

const assetType =CBAssetList[selectedValues?.selectedAssetCB]?.assetType
  const isMotorisedDisbaled =
  siteType === embLabels.GRID_CONNECTED &&
  projectType === "MGaaS IEC" &&
  (assetType === "Utility" || (assetInfo === "Communicating" || assetInfo === "Serial"));

  
  return ( 
    <>
      <div className="col-md-12 mt-2 se-white-background align-middle">
        <div className="row">
          <label className="col-md-6 bold mt-2 mb-2 se-font-14px pl-3">
            Asset Settings
          </label>

          <div className="col-md-6 mt-1 text-right">
            <button
              type="button"
              className="btn btn-secondary btn-sm se-btn-primary"
              onClick={updateCbConfigData}
            >
              <se-icon size="nano">action_save</se-icon>
              <span>&nbsp;</span>Save
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-12 se-grey-background">
        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">
            Name <span className="se-mandatory">*</span>
          </label>

          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput1"
            placeholder="Name"
            name="name"
            maxLength="50"
            value={configData?.cbConfigData?.name}
            onChange={cbConfigChangeHandler}
          />
          <div
            className={`invalid-feedback-custom ${
              !configData?.cbConfigData?.name ? "" : "hidden"
            }`}
          >
            {embLabels?.nameRequired}
          </div>
        </div>
        <div className="form-group mb-1">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input asCompatible"
              name="motorized"
              id="customControlValidation4"
              value={configData?.cbConfigData?.motorized}
              checked={configData?.cbConfigData?.motorized}
              onChange={cbConfigChangeHandler}
              disabled={isMotorisedDisbaled}

            />
            <label
              className="custom-control-label line-height-25"
              htmlFor="customControlValidation4"
            >
              Motorized
            </label>
          
            <Tooltip
                  position="bottom"
                  iconColor="secondary"
                  iconSize="small"
                  information={tooltipData?.motorized?.data}
                />
          </div>
        </div>

        
        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">Description</label>
          <input
            type="text"
            autoComplete="none"
            className="form-control form-control-sm"
            id="exampleFormControlInput1"
            placeholder="Description"
            name="description"
            maxLength="100"
            value={configData?.cbConfigData?.description}
            onChange={cbConfigChangeHandler}
          />
        </div>
      </div>
    </>
  );
};

export default CBConfigDetails;