import React, { useContext, useEffect, useState } from "react";

import { AssetBlockContext } from "../CustomHooks/ContextApi";

import PowerMeterDetails from "./PowerMeterDetails";
import CBConfigDetails from "./CBConfigDetails";

import cbImage from "../../images/CB.png";
import powerMeter from "../../images/power-meter-image.png";
import embLabels from "../../config/emb-config.json"
import isTouchDevice from "../SE-Component/Tooltip";
import Tooltip from "../../utils/Tooltip";

const CbPmDetailsA2E = ({ detailsBlock }) => {
  const { CBAssetList, selectedValues } = useContext(AssetBlockContext);
  const [imageLink, setImageLink] = useState();
 useEffect(() => {
    if (CBAssetList[selectedValues.selectedAssetPM]) {
      const { pmAsset } =
        CBAssetList[selectedValues.selectedAssetPM].powerMeterData;
      const img = pmAsset?.imageLink === null ? powerMeter : pmAsset?.imageLink;
      setImageLink(img);
    }
  }, [CBAssetList]);

  return (
    <div name="CB" className="col-md-12 p-0">
      <div className="col-md-12 pt-2 se-white-background">
        <div className="row">
          <div className="col-md-8">
            <img
              // src={detailsBlock.pmAssetDetailsBlockA2E ? powerMeter : cbImage}

              src={imageLink}
              className="asset-image-icon"
              alt={
                detailsBlock.pmAssetDetailsBlockA2E
                  ? "Power Meter"
                  : "circuit breaker"
              }
              onError={(e) => {
                e.currentTarget.src = detailsBlock.pmAssetDetailsBlockA2E
                  ? powerMeter
                  : cbImage;
              }}
            />

            <span className="align-top line-height-30px bold">
              {detailsBlock.pmAssetDetailsBlockA2E
                ? "Power Meter"
                : "Circuit Breaker"}
            </span>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-sm-12 pt-2 se-grey-background se-border">
        <div className="row">
          <div className="col-md-3">
            <img
              // src={detailsBlock.pmAssetDetailsBlockA2E ? powerMeter : cbImage}
              src={imageLink}
              className="asset-image-display"
              alt={
                detailsBlock.pmAssetDetailsBlockA2E
                  ? "Power Meter"
                  : "circuit breaker"
              }
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-12 bold">
                {embLabels.SCHNEIDER_ELECTRIC}
              </div>
            </div>
            <div className="row">
              <div className="col-md-9">
                {detailsBlock.pmAssetDetailsBlockA2E
                  ? "Power Meter"
                  : "Circuit Breaker"}
              </div>
              <div className="col-md-3 text-right">
                <Tooltip
                  position="left"
                  iconColor="secondary"
                  information={
                    detailsBlock.pmAssetDetailsBlockA2E
                      ? CBAssetList[selectedValues.selectedAssetPM]
                          .powerMeterData.pmAsset?.assetInfo
                      : CBAssetList[selectedValues.selectedAssetCB].cbConfigData
                          .cbAssetDetails[0]?.assetInfo
                  }
                />
              </div>
              <div className="col-md-12">
                {detailsBlock.pmAssetDetailsBlockA2E
                  ? CBAssetList[selectedValues.selectedAssetPM].powerMeterData
                      .pmAsset?.communicationModel
                  : CBAssetList[selectedValues.selectedAssetCB].cbConfigData
                      .cbAssetDetails[0]?.communicationModel}
              </div>
            </div>
          </div>
        </div>
      </div>
      {detailsBlock.pmAssetDetailsBlockA2E && <PowerMeterDetails />}

      {detailsBlock.cbAssetDetailsBlockA2E && <CBConfigDetails />}
    </div>
  );
};

export default CbPmDetailsA2E;
