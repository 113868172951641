import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";
import useCBMouserOver from "../../pages/CustomHooks/useCBMouserOver";

import embLabels from "../../config/emb-labels-en.json";
import MyErrorBoundary from "../../utils/ErrorBoundary";

const Genset = () => {
  const {
    CBAssetList: [, { uiData, configData, powerMeterData, cbConfigData }],
    UserScope,
    CBAssetListDispatch,
    assetDetailsReset,
    callSnackbar,
    selectedValuesHandler,
    assetConfigDataHandler,
    selectedAssetHandler,
    pmConfigDataHandler,
    cbConfigDataHandler,
    newSBCbConfigDataHandler,
    siteType,
    switchboardType,
    loadSide
  } = useContext(DesignAssetContext);
  const [mouseOverData, CBMouseHandler] = useCBMouserOver();

  const gensetSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "assetGensetDetailsBlock";
      const selectedAssetValue = "selectedAsset";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_SELECT,
        payload: {
          index: 1,
        },
      });
      selectedValuesHandler(1, selectedAssetValue, assetBlock);
      selectedAssetHandler(embLabels.ASSETS, 1);
      assetConfigDataHandler(1);
    } else {
      callSnackbar(
        "error",
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const gensetPMSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "pmAssetDetailsBlockGrid";
      const selectedAssetValue = "selectedAssetPM";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_PM_SELECT,
        payload: {
          index: 1,
        },
      });
      selectedValuesHandler(1, selectedAssetValue, assetBlock);
      pmConfigDataHandler(1);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const gensetCBSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "newSBCbAssetDetailsBlock";
      const selectedAssetValue = "selectedAssetCB";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_CB_SELECT,
        payload: {
          index: 1,
        },
      });

      selectedValuesHandler(1, selectedAssetValue, assetBlock);
      newSBCbConfigDataHandler(1);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  return (
    <MyErrorBoundary>
      
    <>
    {["ISLANDABLE_GSX","ISLANDABLE"].includes(siteType)  &&
      (switchboardType ==="" || switchboardType === embLabels.EXISTING_SWITCHBOARD) ?(
        <svg className="pointer">
        <g id="genset">
          <svg
            x={uiData.xAxis}
            y={loadSide==="LOAD_SIDE"?Number(uiData.yAxis)+60:uiData.yAxis}
            width="220px"
            height="130px"
            viewBox="0 0 60 149"
            onClick={gensetSelectHandler}
          >
            <title>{configData.name}</title>
  
            <g id="Design" fill="none">
              <g
                id="Design/Assets-_-00"
                transform="translate(-748.000000, -372.000000)"
              >
                <g
                  id="SLD/Genset/Uniline/OK"
                  transform="translate(748.000000, 372.000000)"
                >
                  <rect id="Rectangle" x="0" y="0" width="96" height="144"></rect>
                  <line
                    x1="48.5"
                    y1="96"
                    x2="48.5"
                    y2="149"
                    id="Line-2"
                    stroke={uiData.configured ? "#333" : "#E47F00"}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <circle
                    id="Oval"
                    stroke="#42B4E6"
                    strokeWidth="3"
                    cx="48"
                    cy="56"
                    r="55"
                    style={uiData.selected ? {} : { display: "none" }}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="rgba(0, 135, 205, 0.2)"
                  ></circle>
                  <circle
                    id="Oval"
                    strokeWidth="3"
                    stroke={uiData.configured ? "#333" : "#E47F00"}
                    fill="#FFFFFF"
                    fillRule="evenodd"
                    cx="48"
                    cy="56"
                    r="46.5"
                  ></circle>
                  <text
                    id="segment_energy_infra"
                    fontFamily="se-icon"
                    fontSize="50"
                    stroke="#333"
                    fontWeight="normal"
                    fill="#0F0F0F"
                  >
                    <tspan x="25" y="74">
                      genset_01
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </svg>
        </g>
  
        <g id="CB2-opened">
          <svg
            width="75px"
            height="100px"
            viewBox="0 0 10 149"
            x={uiData.xAxisCB}
            y={loadSide==="LOAD_SIDE"?Number(uiData.yAxisCB)+60:uiData.yAxisCB}
            onClick={gensetCBSelectHandler}
          >
            <title className="se-custom-tooltip">{cbConfigData.name}</title>
            <g
              id="Design"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Design/Assets-_-00"
                transform="translate(-795.000000, -512.000000)"
              >
                <g
                  id="SLD/CB/Vertical/Uniline/Open/Black_NEMA"
                  transform="translate(748.000000, 514.000000)"
                  onMouseOver={CBMouseHandler}
                  onMouseOut={CBMouseHandler}
                >
                  <g
                    id="Group-19"
                    strokeWidth="4"
                    fillRule="evenodd"
                    fill={
                      uiData.CBSelected
                        ? "rgba(65, 181, 230, 0.35)"
                        : mouseOverData
                          ? "rgba(65, 181, 230, 0.35)"
                          : "none"
                    }
                    stroke={uiData.CBSelected ? "#42B4E6" : "none"}
                  >
                    <rect
                      id="Rectangle-Copy-4"
                      x="8"
                      y="12"
                      width="96"
                      height="122"
                    ></rect>
                    <line
                      x1="59.501111"
                      y1="3.55271368e-15"
                      x2="59.501111"
                      y2="48"
                      id="Line-2-Copy-13"
                      stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></line>
                    <line
                      x1="59.501111"
                      y1="96"
                      x2="59.501111"
                      y2="144"
                      id="Line-2-Copy-15"
                      stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></line>
                  </g>
                  <path
                    d="M59.9160156,48.3289474 C67.860026,64.1096491 67.860026,80 59.9160156,96"
                    id="Path-11"
                    stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                    strokeWidth="4"
                    strokeLinecap="round"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </g>
        <g id="power meter">
          <svg
            x={uiData.xAxisPM}
            y={loadSide==="LOAD_SIDE"?Number(uiData.yAxisPM)+60:uiData.yAxisPM}
            width="300px"
            height="140px"
            viewBox="0 0 100 160"
            onClick={gensetPMSelectHandler}
          >
            <title>{powerMeterData.name}</title>
  
            <line
              x1="29"
              y1="127"
              x2="80"
              y2="127"
              stroke={uiData.pmConfiguredGrid ? "#333" : "#E47F00"}
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle
              id="Pm outer"
              stroke="#42B4E6"
              strokeWidth="3"
              cx="100"
              cy="127"
              r="26"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="rgba(0, 135, 205, 0.2)"
              style={uiData.pmSelected ? {} : { display: "none" }}
            ></circle>
            <circle
              id="pm inner"
              stroke={uiData.pmConfiguredGrid ? "#333" : "#E47F00"}
              strokeWidth="3"
              cx="100"
              cy="127"
              r="18.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="#FFFFFF"
            ></circle>
            <text
              id="PM"
              fontSize="16"
              fontWeight="normal"
              stroke="#333"
              fontFamily="Nunito-Regular, Nunito"
              fill="#333"
            >
              <tspan x="88" y="132">
                PM
              </tspan>
            </text>
          </svg>
        </g>
      </svg>
      ):(
        <svg className="pointer">
        <g id="genset">
          <svg
            x="299.5"
            y="69"
            width="220px"
            height="130px"
            viewBox="0 0 60 149"
            onClick={gensetSelectHandler}
          >
            <title>{configData.name}</title>
  
            <g id="Design" fill="none">
              <g
                id="Design/Assets-_-00"
                transform="translate(-748.000000, -372.000000)"
              >
                <g
                  id="SLD/Genset/Uniline/OK"
                  transform="translate(748.000000, 372.000000)"
                >
                  <rect id="Rectangle" x="0" y="0" width="96" height="144"></rect>
                  <line
                    x1="48.5"
                    y1="96"
                    x2="48.5"
                    y2="149"
                    id="Line-2"
                    stroke={uiData.configured ? "#333" : "#E47F00"}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <circle
                    id="Oval"
                    stroke="#42B4E6"
                    strokeWidth="3"
                    cx="48"
                    cy="56"
                    r="55"
                    style={uiData.selected ? {} : { display: "none" }}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="rgba(0, 135, 205, 0.2)"
                  ></circle>
                  <circle
                    id="Oval"
                    strokeWidth="3"
                    stroke={uiData.configured ? "#333" : "#E47F00"}
                    fill="#FFFFFF"
                    fillRule="evenodd"
                    cx="48"
                    cy="56"
                    r="46.5"
                  ></circle>
                  <text
                    id="segment_energy_infra"
                    fontFamily="se-icon"
                    fontSize="50"
                    stroke="#333"
                    fontWeight="normal"
                    fill="#0F0F0F"
                  >
                    <tspan x="25" y="74">
                      genset_01
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </svg>
        </g>
  
        <g id="CB2-opened">
          <svg
            width="75px"
            height="100px"
            viewBox="0 0 10 149"
            x="383"
            y="200"
            onClick={gensetCBSelectHandler}
          >
            <title className="se-custom-tooltip">{cbConfigData.name}</title>
            <g
              id="Design"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Design/Assets-_-00"
                transform="translate(-795.000000, -512.000000)"
              >
                <g
                  id="SLD/CB/Vertical/Uniline/Open/Black_NEMA"
                  transform="translate(748.000000, 514.000000)"
                  onMouseOver={CBMouseHandler}
                  onMouseOut={CBMouseHandler}
                >
                  <g
                    id="Group-19"
                    strokeWidth="4"
                    fillRule="evenodd"
                    fill={
                      uiData.CBSelected
                        ? "rgba(65, 181, 230, 0.35)"
                        : mouseOverData
                          ? "rgba(65, 181, 230, 0.35)"
                          : "none"
                    }
                    stroke={uiData.CBSelected ? "#42B4E6" : "none"}
                  >
                    <rect
                      id="Rectangle-Copy-4"
                      x="9"
                      y="12"
                      width="96"
                      height="122"
                    ></rect>
                    <line
                      x1="59.501111"
                      y1="3.55271368e-15"
                      x2="59.501111"
                      y2="48"
                      id="Line-2-Copy-13"
                      stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></line>
                    <line
                      x1="59.501111"
                      y1="96"
                      x2="59.501111"
                      y2="144"
                      id="Line-2-Copy-15"
                      stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></line>
                  </g>
                  <path
                    d="M59.9160156,48.3289474 C67.860026,64.1096491 67.860026,80 59.9160156,96"
                    id="Path-11"
                    stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                    strokeWidth="4"
                    strokeLinecap="round"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </g>
        <g id="power meter">
          <svg
            x="296"
            y="60"
            width="300px"
            height="140px"
            viewBox="0 0 100 160"
            onClick={gensetPMSelectHandler}
          >
            <title>{powerMeterData.name}</title>
  
            <line
              x1="29"
              y1="127"
              x2="80"
              y2="127"
              stroke={uiData.pmConfiguredGrid ? "#333" : "#E47F00"}
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle
              id="Pm outer"
              stroke="#42B4E6"
              strokeWidth="3"
              cx="100"
              cy="127"
              r="26"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="rgba(0, 135, 205, 0.2)"
              style={uiData.pmSelected ? {} : { display: "none" }}
            ></circle>
            <circle
              id="pm inner"
              stroke={uiData.pmConfiguredGrid ? "#333" : "#E47F00"}
              strokeWidth="3"
              cx="100"
              cy="127"
              r="18.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="#FFFFFF"
            ></circle>
            <text
              id="PM"
              fontSize="16"
              fontWeight="normal"
              stroke="#333"
              fontFamily="Nunito-Regular, Nunito"
              fill="#333"
            >
              <tspan x="88" y="132">
                PM
              </tspan>
            </text>
          </svg>
        </g>
        
      </svg>
      )}
    </>
    </MyErrorBoundary>
   
  );
};

export default Genset;
