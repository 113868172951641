import React from "react";
import { SeCheckbox } from "@se/web-ui-react";
//import mediaPicture from "../../images/mediaPicture.png";
import seLifeIsOn from "../../images/SE_logotype_lifeison_green.svg";
import SeLogoLifeIsOn from "../../images/SE-Life-is-On.png";
import SeLogoLifeIsOn1 from "../../images/SE_Life.png";
import embLabels from "../../config/emb-labels-en.json";
import { useState, useEffect, useRef } from "react";



const ProjectInformation = ({
  UserScope,
  projectInformation,
  currencyList,
  loadingImage,
  imagePreviewUrl,
  mandatoryMsgDisplay,
  onProjectChangeHandler,
  onFileChangedHandler,
  onRemoveProjectImagePopUp,
  onFindProjectTypeHandler,
  informationToolTIP,
  projectStatusData,
  siteCreated
}) => {
  const [checkBoxFlag, setCheckBoxFlag] = useState(false);
 const IEC = ["DaaS IEC","MGaaS IEC","MGaaS AccessToEnergy"];
  // const shadowRef = useRef(null);
  const handleCheckbox = (e) => {

    setCheckBoxFlag(!checkBoxFlag)
  }
  // useEffect(() => {
  //   if (shadowRef.current !== null) {
  //     const shadow = shadowRef.current.shadowRoot;
  //     const el = shadow.querySelector('.checkmark');
  //     // if (el !== null) {
  //     //   el.textContent = 'Hello from React!';
  //     // }
  //   }
  // }, []);
  return (

    <div
      className={`${UserScope?.access.saveProjects && projectStatusData?.projectStatus === "COMPLETED" || siteCreated ? "se-block-disabled" : ""} col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-2`}
    >
      <se-icon color="primary">information_circle</se-icon>
      <span className="ml-2 se-font-14px-bold se-life-green">
        Project Information
      </span>
      <div className="dropdown-divider"></div>
      <div className="row">
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 se-white-background">
          <div className="form-group row">
            <label
              htmlFor="inputEmail3"
              className="col-sm-4 col-form-label text-right"
            >
              Project Type<span className="se-mandatory"></span>
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                autoComplete="none"
                className="form-control form-control-md"
                name="projectTypeValue"
                placeholder="Project Type"
                maxLength="100"
                value={
                  projectInformation?.projectDisplayName ||
                  projectInformation?.projectTypeDisplayValue
                }
                onChange={onProjectChangeHandler}
                disabled
              />
            </div>
          </div>
          <div
            className={` form-group row ${
              projectInformation?.projectId !== "" ? "" : "d-none"
            }`}
          >
            <label
              htmlFor="inputEmail3"
              className="col-sm-4 col-form-label text-right"
            >
              Project ID
            </label>
            <div className="col-sm-8">
              {/* {projectInformation.projectId} */}
              <input
                type="text"
                autoComplete="none"
                className="form-control form-control-md der-disabled"
                name="projectId"
                maxLength="60"
                value={projectInformation?.projectId}
                disabled
              />
            </div>
          </div>
         {!IEC.includes(projectInformation?.projectTypeValue) && <div className={` form-group row`}>
            <label
              htmlFor="inputEmail3"
              className="col-sm-4 col-form-label text-right"
            >
              Order Number
             
              <span className="se-block-enabled">
                <se-tooltip
                 
                  position="right"
                >
                  <se-icon
                    option="raised"
                    slot="tooltip"
                    color="secondary"                   
                  >
                    information_circle
                  </se-icon>
                  <se-tooltip-content style={{ textAlign: "left", display:"block", width:"295px" }}>
                  {informationToolTIP?.q2C}
                  </se-tooltip-content>
                </se-tooltip>
              </span>
            </label>
            <div className="col-sm-8">
              {/* {projectInformation.projectId} */}
              <input
                type="text"
                autoComplete="none"
                className="form-control form-control-md"
                name="q2cOrderNumber"
                maxLength="60"
                value={projectInformation?.q2cOrderNumber}
                onChange={onProjectChangeHandler}
              />

              <div
                className={`${
                  projectInformation?.q2cOrderNumber &&
                  !/^\d+(\.\d+)?$/.test(projectInformation?.q2cOrderNumber)
                    ? "invalid-feedback-custom"
                    : "hidden"
                }`}
              >
                {embLabels.allowednumericvalues}
              </div>
            </div>
          </div>}

          <div className="form-group row">
            <label
              htmlFor="inputEmail3"
              className="col-sm-4 col-form-label text-right"
            >
              Project Name<span className="se-mandatory">*</span>
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                autoComplete="none"
                className="form-control form-control-md"
                name="projectName"
                placeholder="Project Name"
                maxLength="100"
                value={projectInformation?.projectName}
                onChange={onProjectChangeHandler}
              />
              <div
                className={`${
                  !projectInformation?.projectName && mandatoryMsgDisplay
                    ? "invalid-feedback-custom"
                    : "hidden"
                }`}
              >
                Project Name is required.
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputEmail3"
              className="col-sm-4 col-form-label text-right"
            >
              Client Name<span className="se-mandatory">*</span>
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                autoComplete="none"
                className="form-control form-control-md"
                name="clientName"
                placeholder="Client Name"
                maxLength="100"
                value={projectInformation?.clientName}
                onChange={onProjectChangeHandler}
              />
              <div
                className={`${
                  !projectInformation?.clientName && mandatoryMsgDisplay
                    ? "invalid-feedback-custom"
                    : "hidden"
                }`}
              >
                Client Name is required.
              </div>
            </div>
          </div>

          <div className="form-group row ">
            <label
              htmlFor="inputEmail3"
              className="col-sm-4 col-form-label text-right"
            >
              Site Name<span className="se-mandatory">*</span>
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                autoComplete="none"
                className="form-control form-control-md"
                name="siteName"
                placeholder="Site Name"
                maxLength="100"
                value={projectInformation?.siteName}
                onChange={onProjectChangeHandler}
              />
              <div
                className={`${
                  !projectInformation?.siteName && mandatoryMsgDisplay
                    ? "invalid-feedback-custom"
                    : "hidden"
                }`}
              >
                Site Name is required.
              </div>
            </div>
          </div>

          {onFindProjectTypeHandler() === embLabels.GREENSTRUXURE_PROJECT && (
            <div className="form-group row ">
              <label
                htmlFor="inputEmail3"
                className="col-sm-4 col-form-label text-right"
              >
                External ID
              </label>
              <div className="col-sm-6">
                <input
                  type="text"
                  autoComplete="none"
                  className="form-control form-control-md"
                  name="externalId"
                  placeholder="External ID"
                  maxLength="64"
                  value={projectInformation.externalId}
                  onChange={onProjectChangeHandler}
                  disabled={!checkBoxFlag}
                />

              </div>
              <div className="col-sm-2 d-flex justify-content-center align-items-center">
                <label className="switch">
                  <input type="checkbox" onChange={(e) => handleCheckbox(e)} />
                  <span className="slider"></span>
                </label>
              </div>

            </div>
          )}

          <div className="form-group row mt-2">
            <label
              htmlFor="inputEmail3"
              className="col-sm-4 col-form-label text-right"
            >
              Currency<span className="se-mandatory">*</span>
            </label>
            <div className="col-sm-8">
              <select
                className="col-sm-12 col-lg-12 col-md-12 col-xl-12 form-control form-control-md"
                name="currency"
                value={projectInformation?.currency}
                onChange={onProjectChangeHandler}
              >
                {IEC.includes(projectInformation?.projectTypeValue) && <option value="" disabled selected hidden>
                  Select currency
                </option>}
                <optgroup label="Available">
                  {currencyList?.map(
                    (currencyData) =>
                      currencyData.status !== "D" && (
                        <option key={currencyData.id} value={currencyData.name}>
                          {currencyData.name} ({currencyData.symbol})
                        </option>
                      )
                  )}
                </optgroup>
               { !IEC.includes(projectInformation?.projectTypeValue) &&<optgroup label="Not Available">
                  {currencyList?.map(
                    (currencyData) =>
                      currencyData.status === "D" && (
                        <option
                          key={currencyData.id}
                          value={currencyData.name}
                          disabled={currencyData.status === "D" ? true : false}
                        >
                          {currencyData.name} ({currencyData.symbol})
                        </option>
                      )
                  )}
                </optgroup>}
              </select>
              <div
                className={`${
                  !projectInformation?.currency && mandatoryMsgDisplay
                    ? "invalid-feedback-custom"
                    : "hidden"
                }`}
              >
                Currency is required.
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputEmail3"
              className="col-sm-4 col-form-label text-right"
            >
              Temperature Unit
              <span className="se-mandatory">*</span>
            </label>
            <div className="col-sm-8">
              <select
                className="col-sm-12 col-lg-12 col-md-12 col-xl-12  form-control form-control-md"
                name="temperatureUnit"
                value={projectInformation?.temperatureUnit}
                onChange={onProjectChangeHandler}
              >
                {/* <option value="" disabled selected hidden>
                  Select Temperature Unit
                </option> */}
                <option>Celsius Degree °C</option>
                <option> Fahrenheit °F</option>
              </select>
              <div
                className={`${
                  !projectInformation?.temperatureUnit && mandatoryMsgDisplay
                    ? "invalid-feedback-custom"
                    : "hidden"
                }`}
              >
                Temperature Unit is required.
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputEmail3"
              className="col-sm-4 col-form-label text-right"
            >
              Description
            </label>
            <div className="col-sm-8">
              <textarea
                className="form-control form-control-md"
                id="validationTextarea"
                name="description"
                placeholder="Description"
                maxLength="1000"
                value={projectInformation?.description}
                onChange={onProjectChangeHandler}
              ></textarea>
            </div>
          </div>
        </div>
        <div
          className={`${loadingImage ? "se-block-disabled " : ""
            } row align-items-center justify-content se-grey-background-center`}
        >
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 fix-center">
            <div className=" center pointer">
              {imagePreviewUrl !== null ? (
                <div className="image-container">
                  <img src={imagePreviewUrl} alt="icon" width="200" />
                  <span
                    className="remove-project-image"
                    onClick={onRemoveProjectImagePopUp}
                    style={{ display: "inline" }}
                  >
                    &#215;
                  </span>
                </div>
              ) : (
                <div
                  className="image-container se-grey-background pointer"
                  style={{ width: "200px" }}
                >
                  <label
                    htmlFor="uploadImageProject"
                    title="click to upload image"
                  >
                    <img
                      //src={mediaPicture}
                      src={seLifeIsOn}
                      // src ={SeLogoLifeIsOn1}
                      title="click to upload image"
                      className="pointer"
                      width="200"
                      height="200"
                      alt="icon"
                    />
                    <div
                      style={{
                        position: "absolute",
                        bottom: "18px",
                        left: "44px",
                        // bottom:"12px",
                        // left:"69px",
                        width: "118%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        //color:"white",
                      }}
                      className="se-life-green"
                    >
                       <div>
                    <se-icon
                              size="small"
                              title="upload"
                              color="primary"
                              
                            >
                             action_upload_stroke
                            </se-icon>
                    </div>
                      <div>Max size 1MB</div>
                      <div>Type: .png, .gif, .jpeg, .jpg</div>
                    </div>
                   
                  </label>
                </div>
              )}
              {imagePreviewUrl === null && (
                <input
                  type="file"
                  id="uploadImageProject"
                  style={{ display: "none" }}
                  name="image"
                  accept="image/x-png,image/gif,image/jpeg,image/jpg"
                  className="mt-4"
                  onChange={onFileChangedHandler}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectInformation;
