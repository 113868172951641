
import React, { useContext } from "react";
import useCBMouserOver from "../../pages/CustomHooks/useCBMouserOver";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";
import embLabels from "../../config/emb-labels-en.json"
import MyErrorBoundary from "../../utils/ErrorBoundary";
const PV1 = () => {
  const {
    CBAssetList: [, , , { uiData, powerMeterData, cbConfigData }],
    CBAssetListDispatch,
    UserScope,
    pv1SelectHandler,
    newSBCbConfigDataHandler,
    assetDetailsReset,
    selectedValuesHandler,
    pmConfigDataHandler,
    padNumberFormat,
    PVDetails,
    callSnackbar,
    siteType,
    switchboardType,
    loadSide
  } = useContext(DesignAssetContext);

  const [mouseOverData, CBMouseHandler] = useCBMouserOver();

  const pv1PmSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      // const assetBlock = "cbAssetDetailsBlock";
      const assetBlock = "pmAssetDetailsBlockGrid";

      const selectedAssetValue = "selectedAssetPM";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_PM_SELECT,
        payload: {
          index: 3,
        },
      });
      selectedValuesHandler(3, selectedAssetValue, assetBlock);
      pmConfigDataHandler(3);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const pv1CBSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "newSBCbAssetDetailsBlock";
      const selectedAssetValue = "selectedAssetCB";

      CBAssetListDispatch({
        type: ACTIONS.ASSET_CB_SELECT,
        payload: {
          index: 3,
        },
      });
      selectedValuesHandler(3, selectedAssetValue, assetBlock);
      newSBCbConfigDataHandler(3);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };
  return (
    <MyErrorBoundary>
      
   <>
   {["ISLANDABLE_GSX","ISLANDABLE"].includes(siteType)  &&
     ( switchboardType ==="" ||switchboardType === embLabels.EXISTING_SWITCHBOARD) ?(
      <svg className="pointer">
      <g id="pv1">
        <svg
          x={uiData.xAxis}
          y={loadSide==="LOAD_SIDE"?Number(uiData.yAxis)+60:uiData.yAxis}
          width="195px"
          height="130px"
          viewBox="0 0 10 149"
          onClick={pv1SelectHandler}
        >
          <title>
            {PVDetails.pvset1Data[0] ? PVDetails.pvset1Data[0].groupName : ""}
          </title>
          <g
            id="Design"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Design/Assets-_-00"
              transform="translate(-1036.000000, -372.000000)"
            >
              <g
                id="SLD/PV/Uniline/Multiple_Done"
                transform="translate(1036.000000, 372.000000)"
              >
                <circle
                  id="Oval"
                  stroke="#42B4E6"
                  strokeWidth="3"
                  cx="48"
                  cy="56"
                  r="55"
                  style={uiData.selected ? {} : { display: "none" }}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="rgba(0, 135, 205, 0.2)"
                ></circle>
 
                <circle
                  id="Oval"
                  stroke={uiData.configured ? "#333333" : "#E47F00"}
                  strokeWidth="3"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                  cx="57"
                  cy="59"
                  r="43.5"
                ></circle>
 
                <line
                  x1="48.5"
                  y1="102.287109"
                  x2="48.5"
                  y2="144"
                  id="Line-2"
                  stroke={uiData.configured ? "#333333" : "#E47F00"}
                  strokeWidth="3"
                  strokeLinejoin="round"
                ></line>
                <circle
                  id="Oval"
                  stroke={uiData.configured ? "#333333" : "#E47F00"}
                  strokeWidth="3"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                  cx="48"
                  cy="55"
                  r="46.5"
                ></circle>
 
                <circle
                  id="Oval"
                  stroke={uiData.configured ? "#333333" : "#E47F00"}
                  strokeWidth="3"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                  cx="48"
                  cy="55"
                  r="46.5"
                ></circle>
 
                <g
                  id="999🔒Atoms/Notifications/Done"
                  transform="translate(66.000000, 2.000000)"
                >
                  <g
                    id="999🔒Atoms/Background/Round"
                    strokeWidth="1"
                    fillRule="evenodd"
                    fill={uiData.configured ? "#333333" : "#E47F00"}
                    stroke="#FFFFFF"
                  >
                    <circle
                      id="Oval"
                      strokeWidth="2.66666667"
                      cx="16"
                      cy="16"
                      r="18"
                    ></circle>
                  </g>
 
                  <text
                    id="3"
                    fontFamily="Nunito-Regular, Nunito"
                    fontSize="24"
                    fontWeight="normal"
                    fill="#FFFFFF"
                  >
                    <tspan x="2" y="24">
                      {padNumberFormat(uiData.count)}
                    </tspan>
                  </text>
                </g>
                <text
                  id="solar_panel1"
                  fontFamily="se-icon"
                  fontSize="56"
                  fontWeight="normal"
                  fill="#333333"
                >
                  <tspan x="20" y="69">
                    solar_panel1
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </svg>
      </g>
      <g id="CB4">
        <svg
          width="75px"
          height="100px"
          viewBox="0 0 10 160"
          x={uiData.xAxisCB}
          y={loadSide==="LOAD_SIDE"?Number(uiData.yAxisCB)+60:uiData.yAxisCB}
          onClick={pv1CBSelectHandler}
        >
          <title>{cbConfigData.name}</title>
          <g
            id="Circuit Breaker"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Circuit Breaker 1"
              transform="translate(-651.000000, -512.000000)"
            >
              <g
                id="Circuit Breaker - closed"
                transform="translate(604.000000, 514.000000)"
                onMouseOver={CBMouseHandler}
                onMouseOut={CBMouseHandler}
              >
                <g
                  id="Group-15"
                  strokeWidth="4"
                  fillRule="evenodd"
                  fill={
                    uiData.CBSelected
                      ? "rgba(65, 181, 230, 0.35)"
                      : mouseOverData
                        ? "rgba(65, 181, 230, 0.35)"
                        : "none"
                  }
                  stroke={uiData.CBSelected ? "#42B4E6" : "none"}
                >
                  <rect
                    id="Rectangle-Copy-20"
                    x="0"
                    y="12"
                    width="98"
                    height="132"
                  ></rect>
                  <g
                    id="Group-2"
                    transform="translate(48.000000, 0.000000)"
                    stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="4"
                  >
                    <line
                      x1="0.5"
                      y1="0"
                      x2="0.5"
                      y2="47.8298611"
                      id="Line-2"
                    ></line>
                  </g>
                </g>
                <line
                  x1="48.5"
                  y1="97.1701389"
                  x2="48.501111"
                  y2="160"
                  id="Line-2-Copy-15"
                  stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <path
                  d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                  id="Path-11"
                  strokeWidth="4"
                  strokeLinecap="round"
                  stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </g>      
      <g id="power meter">
        <svg
          // x="650"
          // y="250"
          x={uiData.xAxisPM}
          y={loadSide==="LOAD_SIDE"?"310":Number(uiData.yAxis)+40}
          width="300px"
          height="140px"
          viewBox="0 0 100 160"
          onClick={pv1PmSelectHandler}
        >
          <title>{powerMeterData.name}</title>
 
          <line
            x1="30"
            y1="127"
            x2="80"
            y2="127"
            stroke={uiData.pmConfiguredGrid ? "#333":"#E47F00"}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle
            id="Pm outer"
            stroke="#42B4E6"
            strokeWidth="3"
            cx="100"
            cy="127"
            r="26"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="rgba(0, 135, 205, 0.2)"
            style={uiData.pmSelected ? {} : { display: "none" }}
          ></circle>
          <circle
            id="pm inner"
            stroke={uiData.pmConfiguredGrid ? "#333" : "#E47F00"}
            strokeWidth="3"
            cx="100"
            cy="127"
            r="18.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="#FFFFFF"
          ></circle>
          <text
            id="PM"
            fontSize="16"
            fontWeight="normal"
            stroke="#333"
            fontFamily="Nunito-Regular, Nunito"
            fill="#333"
          >
            <tspan x="88" y="132">
              PM
            </tspan>
          </text>
        </svg>
      </g>
     </svg>):(
     <svg className="pointer">
     <g id="pv1">
       <svg
         x="430"
         y="72"
         width="195px"
         height="130px"
         viewBox="0 0 10 149"
         onClick={pv1SelectHandler}
       >
         <title>
           {PVDetails.pvset1Data[0] ? PVDetails.pvset1Data[0].groupName : ""}
         </title>
         <g
           id="Design"
           stroke="none"
           strokeWidth="1"
           fill="none"
           fillRule="evenodd"
         >
           <g
             id="Design/Assets-_-00"
             transform="translate(-1036.000000, -372.000000)"
           >
             <g
               id="SLD/PV/Uniline/Multiple_Done"
               transform="translate(1036.000000, 372.000000)"
             >
               <circle
                 id="Oval"
                 stroke="#42B4E6"
                 strokeWidth="3"
                 cx="48"
                 cy="56"
                 r="55"
                 style={uiData.selected ? {} : { display: "none" }}
                 strokeLinecap="round"
                 strokeLinejoin="round"
                 fill="rgba(0, 135, 205, 0.2)"
               ></circle>

               <circle
                 id="Oval"
                 stroke={uiData.configured ? "#333333" : "#E47F00"}
                 strokeWidth="3"
                 fill="#FFFFFF"
                 fillRule="evenodd"
                 cx="57"
                 cy="59"
                 r="43.5"
               ></circle>

               <line
                 x1="48.5"
                 y1="102.287109"
                 x2="48.5"
                 y2="144"
                 id="Line-2"
                 stroke={uiData.configured ? "#333333" : "#E47F00"}
                 strokeWidth="3"
                 strokeLinejoin="round"
               ></line>
               <circle
                 id="Oval"
                 stroke={uiData.configured ? "#333333" : "#E47F00"}
                 strokeWidth="3"
                 fill="#FFFFFF"
                 fillRule="evenodd"
                 cx="48"
                 cy="55"
                 r="46.5"
               ></circle>

               <circle
                 id="Oval"
                 stroke={uiData.configured ? "#333333" : "#E47F00"}
                 strokeWidth="3"
                 fill="#FFFFFF"
                 fillRule="evenodd"
                 cx="48"
                 cy="55"
                 r="46.5"
               ></circle>

               <g
                 id="999🔒Atoms/Notifications/Done"
                 transform="translate(66.000000, 2.000000)"
               >
                 <g
                   id="999🔒Atoms/Background/Round"
                   strokeWidth="1"
                   fillRule="evenodd"
                   fill={uiData.configured ? "#333333" : "#E47F00"}
                   stroke="#FFFFFF"
                 >
                   <circle
                     id="Oval"
                     strokeWidth="2.66666667"
                     cx="16"
                     cy="16"
                     r="18"
                   ></circle>
                 </g>

                 <text
                   id="3"
                   fontFamily="Nunito-Regular, Nunito"
                   fontSize="24"
                   fontWeight="normal"
                   fill="#FFFFFF"
                 >
                   <tspan x="2" y="24">
                     {padNumberFormat(uiData.count)}
                   </tspan>
                 </text>
               </g>
               <text
                 id="solar_panel1"
                 fontFamily="se-icon"
                 fontSize="56"
                 fontWeight="normal"
                 fill="#333333"
               >
                 <tspan x="20" y="69">
                   solar_panel1
                 </tspan>
               </text>
             </g>
           </g>
         </g>
       </svg>
     </g>
     <g id="CB4">
       <svg
         width="75px"
         height="100px"
         viewBox="0 0 10 160"
         x="530"
         y="199"
         onClick={pv1CBSelectHandler}
       >
         <title>{cbConfigData.name}</title>
         <g
           id="Circuit Breaker"
           stroke="none"
           strokeWidth="1"
           fill="none"
           fillRule="evenodd"
         >
           <g
             id="Circuit Breaker 1"
             transform="translate(-651.000000, -512.000000)"
           >
             <g
               id="Circuit Breaker - closed"
               transform="translate(604.000000, 514.000000)"
               onMouseOver={CBMouseHandler}
               onMouseOut={CBMouseHandler}
             >
               <g
                 id="Group-15"
                 strokeWidth="4"
                 fillRule="evenodd"
                 fill={
                   uiData.CBSelected
                     ? "rgba(65, 181, 230, 0.35)"
                     : mouseOverData
                       ? "rgba(65, 181, 230, 0.35)"
                       : "none"
                 }
                 stroke={uiData.CBSelected ? "#42B4E6" : "none"}
               >
                 <rect
                   id="Rectangle-Copy-20"
                   x="0"
                   y="12"
                   width="96"
                   height="132"
                 ></rect>
                 <g
                   id="Group-2"
                   transform="translate(48.000000, 0.000000)"
                   stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                   strokeLinecap="round"
                   strokeLinejoin="round"
                   strokeWidth="4"
                 >
                   <line
                     x1="0.5"
                     y1="0"
                     x2="0.5"
                     y2="47.8298611"
                     id="Line-2"
                   ></line>
                 </g>
               </g>
               <line
                 x1="48.5"
                 y1="97.1701389"
                 x2="48.501111"
                 y2="160"
                 id="Line-2-Copy-15"
                 stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                 strokeWidth="4"
                 strokeLinecap="round"
                 strokeLinejoin="round"
               ></line>
               <path
                 d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                 id="Path-11"
                 strokeWidth="4"
                 strokeLinecap="round"
                 stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
               ></path>
             </g>
           </g>
         </g>
       </svg>
     </g>
     <g id="power meter">
       <svg
         // x="650"
         // y="250"
         x="435"
         y="120"
         width="300px"
         height="140px"
         viewBox="0 0 100 160"
         onClick={pv1PmSelectHandler}
       >
         <title>{powerMeterData.name}</title>

         <line
           x1="30"
           y1="127"
           x2="80"
           y2="127"
           stroke={uiData.pmConfiguredGrid ? "#333":"#E47F00"}
           strokeWidth="3"
           strokeLinecap="round"
           strokeLinejoin="round"
         />
         <circle
           id="Pm outer"
           stroke="#42B4E6"
           strokeWidth="3"
           cx="100"
           cy="127"
           r="26"
           strokeLinecap="round"
           strokeLinejoin="round"
           fill="rgba(0, 135, 205, 0.2)"
           style={uiData.pmSelected ? {} : { display: "none" }}
         ></circle>
         <circle
           id="pm inner"
           stroke={uiData.pmConfiguredGrid ? "#333":"#E47F00"}
           strokeWidth="3"
           cx="100"
           cy="127"
           r="18.5"
           strokeLinecap="round"
           strokeLinejoin="round"
           fill="#FFFFFF"
         ></circle>
         <text
           id="PM"
           fontSize="16"
           fontWeight="normal"
           stroke="#333"
           fontFamily="Nunito-Regular, Nunito"
           fill="#333"
         >
           <tspan x="88" y="132">
             PM
           </tspan>
         </text>
       </svg>
     </g>
 
    </svg>)
     
   }
   </>
    </MyErrorBoundary>
  );
};

export default PV1;