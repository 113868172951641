import React, { useContext } from "react";
import { DesignProgramContext } from "../CustomHooks/ContextApi";
import isTouchDevice from "../SE-Component/Tooltip";
import Tooltip from "../../utils/Tooltip";

const BESSHibernating = () => {
  const {
    BESSHibernateEnabled,
    handleRadioButtonChange,
    configuredStatusModified,
    openBessHibernatepopup,
  } = useContext(DesignProgramContext);

  const bessHibernateChnageHandlerYes = () => {
    openBessHibernatepopup();
    configuredStatusModified("bhConfiguredStatus");
  };

  const bessHibernateChnageHandlerNo = e => {
    handleRadioButtonChange(e);
    configuredStatusModified("bhConfiguredStatus");
  };

  return (
    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-1">
      <div className="dropdown-divider"></div>
      <div className="row">
        <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 mb-2 ">
          <span className="bold">
            Do you want to activate hibernate BESS when SoC is below SoC Min ?
          </span>
          <Tooltip
            position="right"
            iconColor="secondary"
            information="If hibernating is activated, BESS will stop when SoC becomes lower
            than SoC Min. Manual action is needed to restart BESS."
          />
         
        </div>
        <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 mb-2 ">
          <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="customRadioGb11"
              name="BESSHibernateEnabled"
              className="custom-control-input islandableRadio asCompatible"
              checked={BESSHibernateEnabled === "Y"}
              value="Y"
              onChange={bessHibernateChnageHandlerYes}
            />
            <label
              className="custom-control-label line-height-25px"
              htmlFor="customRadioGb11"
            >
              Yes
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="customRadioGb22"
              name="BESSHibernateEnabled"
              className="custom-control-input islandableRadio asCompatible"
              checked={BESSHibernateEnabled === "N"}
              value="N"
              onChange={bessHibernateChnageHandlerNo}
            />
            <label
              className="custom-control-label line-height-25px"
              htmlFor="customRadioGb22"
            >
              No
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BESSHibernating;
