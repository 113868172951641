import React, { useCallback, useRef, useState } from "react";
import ApiSession from "../api/ApiSession";
import FileDownload from "js-file-download";
import Snackbar from "./SE-Component/Snackbar";

const SupportDocument = ({
  onClose,
  showSupportDocumentDialog,
  documentList,
}) => {
  const apiSession = new ApiSession();
  const [snackBar, setSnackBar] = useState({ type: "", message: "" });

  const snackBarRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const callSnackbar = useCallback((type, message) => {
    setSnackBar({ type, message });
    snackBarRef.current.open = true;
  }, []);

  const downloadDocument = (data) => {
    setLoading(true);
    apiSession
      .downloadDocument(data?.id)
      .then((response) => {
        FileDownload(response, data.blobDocumentName);
        setLoading(false);
        callSnackbar("success", "Downloaded successfully");
        onClose();
      })
      .catch(() => {
        callSnackbar("error", "Failed to Download Document");
        setLoading(false);
      });
  };
  return (
    <>
      <Snackbar snackBar={snackBar} ref={snackBarRef} />

      <se-dialog
        id="dialog-complex-delete-confirm"
        open={showSupportDocumentDialog}
        can-backdrop="false"
        style={{ minHeight: "10px" }}
      >
        <se-dialog-header>{"Support Document"}</se-dialog-header>
        <se-dialog-content
          className="se-dialog-content"
        //   style={{ minHeight: "150px" }}
        >
          {documentList.length > 0 ? (
            <table className="table table-bordered">
              <thead>
                <tr align="center">
                  <th>S.no.</th>
                  <th>Document Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {documentList?.map((item) => (
                  <tr key={item.id} align="center">
                    <td>{documentList.indexOf(item) + 1}.</td>
                    <td>{item.displayDocName}</td>
                    <td>
                      <se-icon
                        size="small"
                        color="primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => downloadDocument(item)}
                      >
                        action_download_stroke
                      </se-icon>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            "No Documents Found"
          )}
        </se-dialog-content>
        <se-dialog-footer>
          <se-button onClick={onClose}>Cancel</se-button>
          <div className={loading ? "loader__center" : ""}>
            <se-loading loading={loading}></se-loading>
          </div>
        </se-dialog-footer>
      </se-dialog>
    </>
  );
};

export default SupportDocument;
