import React, { useContext } from "react";
import embLabels from "../../config/emb-labels-en.json";

import { AssetBlockContext } from "../CustomHooks/ContextApi";

const LoadAsset = () => {
  const {
    configData,
    handleLoadChange,
    updateLoadConfig,
    removeLoad,
    calculateMaxAvailableLoadPower,
  } = useContext(AssetBlockContext);

  const loadChangeHandler = e => {
    handleLoadChange(e);
  };

  const [powerIsBetween, nominalPowerMin, nominalPowerMax] =
    calculateMaxAvailableLoadPower();

  return (
    <div name="CB" className="col-md-12 p-0">
      <div className="col-md-12 pt-2 se-white-background">
        <div className="row">
          <div className="col-md-8 ">
            <se-icon
              color="primary"
              className="se-icon icon_button se-life-green"
            >
              segment_building_small
            </se-icon>
            <span className="ml-2 bold align-top line-height-30px">Load</span>
          </div>
          <div className="col-md-4 ">
            <span className="float-right">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={removeLoad}
              >
                Remove
              </button>
            </span>
          </div>
        </div>
      </div>

      <div className="col-md-12 mt-2  pt-1 se-white-background align-middle se-border">
        <div className="row">
          <label className=" col-md-6 bold mt-1 pl-3 se-font-14px">
            Asset Settings
          </label>
          <div className="col-md-6 mt-1 mb-1 text-right">
            <button
              type="button"
              className="btn btn-secondary btn-sm se-btn-primary"
              onClick={updateLoadConfig}
            >
              <se-icon size="nano">action_save</se-icon>
              <span>&nbsp;</span>Save
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-12 pb-2 pt-2 se-grey-background">
        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">
            Name <span className="se-mandatory">*</span>
          </label>
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput1"
            placeholder="Name"
            name="name"
            value={configData.configData.name}
            onChange={loadChangeHandler}
          />
          <div
            className="invalid-feedback-custom"
            style={!configData.configData.name ? {} : { display: "none" }}
          >
            {embLabels.nameRequired}
          </div>
          <div
            className="invalid-feedback-custom"
            style={configData.configData.name !==""  && !/^[a-zA-Z0-9-_ ]+$/.test(configData?.configData.name )? {} : { display: "none" }}
          >
            {embLabels.notallowedAlphanumericValues}
          </div>
          
        </div>
        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">Description</label>
          <input
            type="text"
            autoComplete="none"
            className="form-control form-control-sm"
            id="exampleFormControlInput1"
            placeholder="Description"
            name="description"
            value={configData.configData.description}
            onChange={loadChangeHandler}
          />
        </div>
        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">
            Maximum load power (kW) <span className="se-mandatory">*</span>
          </label>
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput1"
            placeholder="Maximum load power"
            maxLength={8}
            name="maximum_available_power"
            value={configData.configData.maximum_available_power}
            onChange={loadChangeHandler}
          />
          <div
            className="invalid-feedback-custom"
            style={
              configData.configData.maximum_available_power === ""
                ? {}
                : { display: "none" }
            }
          >
            Maximum load power (kW) is required.
          </div>
          {configData.configData.maximum_available_power !== "" &&
            !powerIsBetween ? (
            <div className="invalid-feedback-custom">
              Maximum load power should be in range( {nominalPowerMin} -
              {nominalPowerMax} kW).
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default LoadAsset;
