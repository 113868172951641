import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";
import embLabels from "../../config/emb-labels-en.json";
const Load = () => {
  const {
    CBLoadList: [{ uiData }],
    siteType,
    switchboardType,
  } = useContext(DesignAssetContext);

  return (
    <>
    {siteType === "PV_HYBRIDIZATION" || (siteType === "GRID_CONNECTED_GSX" && (switchboardType ==="" ||switchboardType === embLabels.EXISTING_SWITCHBOARD)) ?
     <svg className="pointer" style={{ pointerEvents: "none" }}>
     <g id="CB1-load">
       <svg
         width="70px"
         height="95px"
         viewBox="0 0 10 149"
         x={uiData.xAxisCB}
         y={switchboardType===embLabels.EXISTING_SWITCHBOARD?uiData.yAxisCB+15:uiData.yAxisCB}
       >
         <g
           id="Circuit Breaker"
           stroke="none"
           strokeWidth="1"
           fill="none"
           fillRule="evenodd"
         >
           <g
             id="Circuit Breaker 1"
             transform="translate(-651.000000, -512.000000)"
           >
             <g
               id="Circuit Breaker - closed"
               transform="translate(604.000000, 514.000000)"
             >
               <g id="Group-15" strokeWidth="4" fill={"none"} stroke={"none"}>
                 <rect
                   id="Rectangle-Copy-20"
                   x="0"
                   y="8"
                   width="96"
                   height="124"
                 ></rect>
                 <g
                   id="Group-2"
                   transform="translate(48.000000, 0.000000)"
                   strokeLinecap="round"
                   strokeLinejoin="round"
                   strokeWidth="4"
                   stroke="#333333"
                 >
                   <line
                     x1="0.5"
                     y1="0"
                     x2="0.5"
                     y2="47.8298611"
                     id="Line-2"
                   ></line>
                 </g>
               </g>
               <line
                 x1="48.5"
                 y1="97.1701389"
                 x2="48.501111"
                 y2="145"
                 id="Line-2-Copy-15"
                 stroke="#333333"
                 strokeWidth="4"
                 strokeLinecap="round"
                 strokeLinejoin="round"
               ></line>
               <path
                 d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                 id="Path-11"
                 stroke="#333333"
                 strokeWidth="4"
                 strokeLinecap="round"
               ></path>
             </g>
           </g>
         </g>
       </svg>
     </g>
     <g id="load1">
       <svg
         x={uiData.xAxis}
         y={uiData.yAxis}
         width="149px"
         height="475px"
         viewBox="0 0 90 550"
       >
         <g
           id="Design"
           stroke="none"
           strokeWidth="1"
           fill="none"
           fillRule="evenodd"
         >
           <g
             id="Design/Assets-_-00"
             transform="translate(-604.000000, -372.000000)"
           >
             <g
               id="SLD/Production/Uniline/Undefined"
               transform="translate(604.000000, 372.000000)"
             >
               <g id="SLD/Production/Uniline/Tobedefine">
                 <g id="Group-7">
                   <rect
                     id="Rectangle"
                     x="0"
                     y="95"
                     width="96"
                     height="148"
                   ></rect>

                   <line
                     x1="48.5"
                     y1="96"
                     x2="48.5"
                     y2="144"
                     id="Line-2"
                     strokeWidth="3"
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     stroke="#333333"
                   ></line>

                   <circle
                     id="Oval"
                     stroke="#42B4E6"
                     strokeWidth="3"
                     cx="48"
                     cy="193.5"
                     r="55"
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     fill="rgba(0, 135, 205, 0.2)"
                     style={{ display: "none" }}
                   ></circle>

                   <circle
                     id="Oval"
                     strokeWidth="3"
                     fill="#FFFFFF"
                     cx="48"
                     cy="193.5"
                     r="46.5"
                     stroke="#333333"
                   ></circle>

                   <text
                     id="segment_energy_infra"
                     fontFamily="se-icon"
                     fontSize="56"
                     fontWeight="normal"
                     fill="#333333"
                   >
                     <tspan x="20" y="210">
                       segment_building_small
                     </tspan>
                   </text>
                 </g>
               </g>
             </g>
           </g>
         </g>
       </svg>
     </g>
   </svg>
    :
    <svg className="pointer" style={{ pointerEvents: "none" }}>
    <g id="CB1-load">
      <svg
        width="70px"
        height="98px"
        viewBox="0 0 10 149"
        x={uiData.xAxisCB}
        y={switchboardType===embLabels.NEW_SWITCHBOARD?uiData.yAxisCB+15:uiData.yAxisCB}
      >
        <g
          id="Circuit Breaker"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Circuit Breaker 1"
            transform="translate(-651.000000, -512.000000)"
          >
            <g
              id="Circuit Breaker - closed"
              transform="translate(604.000000, 514.000000)"
            >
              <g id="Group-15" strokeWidth="4" fill={"none"} stroke={"none"}>
                <rect
                  id="Rectangle-Copy-20"
                  x="0"
                  y="8"
                  width="96"
                  height="124"
                ></rect>
                <g
                  id="Group-2"
                  transform="translate(48.000000, 0.000000)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="4"
                  stroke="#333333"
                >
                  <line
                    x1="0.5"
                    y1="0"
                    x2="0.5"
                    y2="47.8298611"
                    id="Line-2"
                  ></line>
                </g>
              </g>
              <line
                x1="48.5"
                y1="97.1701389"
                x2="48.501111"
                y2="145"
                id="Line-2-Copy-15"
                stroke="#333333"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></line>
              <path
                d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                id="Path-11"
                stroke="#333333"
                strokeWidth="4"
                strokeLinecap="round"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </g>
    <g id="load1">
      <svg
        x={uiData.xAxis}
        y="310"
        width="149px"
        height="475px"
        viewBox="0 0 90 550"
      >
        <g
          id="Design"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Design/Assets-_-00"
            transform="translate(-604.000000, -372.000000)"
          >
            <g
              id="SLD/Production/Uniline/Undefined"
              transform="translate(604.000000, 372.000000)"
            >
              <g id="SLD/Production/Uniline/Tobedefine">
                <g id="Group-7">
                  <rect
                    id="Rectangle"
                    x="0"
                    y="95"
                    width="96"
                    height="148"
                  ></rect>

                  <line
                    x1="48.5"
                    y1="96"
                    x2="48.5"
                    y2="144"
                    id="Line-2"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    stroke="#333333"
                  ></line>

                  <circle
                    id="Oval"
                    stroke="#42B4E6"
                    strokeWidth="3"
                    cx="48"
                    cy="193.5"
                    r="55"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="rgba(0, 135, 205, 0.2)"
                    style={{ display: "none" }}
                  ></circle>

                  <circle
                    id="Oval"
                    strokeWidth="3"
                    fill="#FFFFFF"
                    cx="48"
                    cy="193.5"
                    r="46.5"
                    stroke="#333333"
                  ></circle>

                  <text
                    id="segment_energy_infra"
                    fontFamily="se-icon"
                    fontSize="56"
                    fontWeight="normal"
                    fill="#333333"
                  >
                    <tspan x="20" y="210">
                      segment_building_small
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </g>
  </svg>}
    </>
   
  );
};

export default Load;
