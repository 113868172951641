import { createContext } from "react";

const InformationPageContext = createContext();
const InformationPageProvider = InformationPageContext.Provider;

const DesignAssetContext = createContext();
const DesignAssetProvider = DesignAssetContext.Provider;
const AssetBlockContext = createContext();
const AssetBlockContextProvider = AssetBlockContext.Provider;

const DesignProgramContext = createContext();
const DesignProgramProvider = DesignProgramContext.Provider;

export {
  InformationPageContext,
  InformationPageProvider,
  DesignAssetContext,
  DesignAssetProvider,
  AssetBlockContext,
  AssetBlockContextProvider,
  DesignProgramContext,
  DesignProgramProvider,
};
