import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import loginBackground from '../images/EMB.png';
import auth from '../auth/Auth';
const authLogin = new auth(); 

class Login extends Component {
    componentDidMount(){
        localStorage.clear();
        authLogin.login();
    }

    onButtonClick() {
        
        // let { history } = this.props;
        // history.push({
        //     pathname: '/project'
        // });
    }

    render() {
        const src= "url("+loginBackground+")";
        return (
            <div>
                <se-loading loading="true"></se-loading>
                <se-authentication app-title="Microgrid Build" version={process.env.REACT_APP_VERSION} image-url={src}>
                </se-authentication>
            </div>
            
//             <se-authentication app-title="Microgrid Build" version={process.env.REACT_APP_VERSION} image-url={src}>
//                 {/* <form >
//                     <se-form-field label="Username" required="true" option="stacked">
//                         <input type="text" />
//                     </se-form-field>
//                     <se-form-field label="Password" required="true" option="stacked">
//                         <input type="password" />
//                     </se-form-field>
//                     <se-button  onClick={this.onButtonClick.bind(this)} option="login" value="myLoginValue">Login</se-button>
//                 </form> */}
//                 <form >
//   <div className="form-group mb-2">
//     <input type="email" className="form-control" id="email" placeholder="Email"/>
//   </div>
//   <div className="form-group mb-2">
//     <input type="password" className="form-control" id="pwd" placeholder="Password"/>
//   </div>
//   <button type="button" className="btn btn-success btn-md btn-block" onClick={this.onButtonClick.bind(this)} >Login</button>
//   <div className="form-group mt-2">
//   <a href="#">Forgot your password ?</a>
//   </div>
  
// </form>
//             </se-authentication>
        );
    }
}

export default withRouter(Login);