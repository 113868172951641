import React, { forwardRef, memo,  } from "react";

const Snackbar = forwardRef(({ snackBar }, ref) => (
  <se-snackbar
    ref={ref}
    id="snackbar"
    icon="information_stroke"
    type={snackBar.type}
    message={snackBar.message}
    duration="3000"
  ></se-snackbar>
));

export default memo(Snackbar);
