import { createSlice } from "@reduxjs/toolkit";
import {call, put, takeLatest} from 'redux-saga/effects'
import ApiSession from "../../api/ApiSession";
const apiSession = new ApiSession();
const initialState = {
   loading:false,
   data:null,
   error:null,
}
export const secureArchitectureDataSlice = createSlice({
    name:'secureArchitectureData',
    initialState,
    reducers:{
        getSecureArchitectureDataRequest:state=>{
            state.loading = true;
        },
        getSecureArchitectureData:(state, action )=>{
            state.loading = false;
            state.data = action.payload;
            state.error = null;
        },
        getSecureArchitectureDataFailure:(state,action)=>{
            state.loading = false;
            state.data = null;
            state.error = action.payload;
        }
    }
});
export const { getSecureArchitectureDataRequest, setSecureArchitectureData, setSecureArchitectureDataFailure } = secureArchitectureDataSlice.actions;

export function* secureArchitectureDataSaga(action){
    try {
    const projectData = yield call(
      [apiSession, apiSession.getSecureArchitectureData],
      action.payload
    );
    yield put(secureArchitectureDataSlice.actions.getSecureArchitectureData(projectData));
  } catch (error) {
    yield put(secureArchitectureDataSlice.actions.getSecureArchitectureDataFailure(error.message));
  }
}
export function* watchGetSecureArchitectureData(){
    yield takeLatest(getSecureArchitectureDataRequest.type, secureArchitectureDataSaga)
}
export default secureArchitectureDataSlice; 