import React, { Component } from "react";
import Slides from './newsSlider';

class SENews extends Component {

  constructor(props) {
    super(props);
    this.state = {
      newsList: this.props.newsList,
      newsLength: this.props.newsListLength
    }
  }

  componentDidMount() {
    this.setState({
      newsList: this.props.newsList,
      newsLength: this.props.newsListLength
    })
  }
  componentWillReceiveProps() {
    this.setState({
      newsList: this.props.newsList,
      newsLength: this.props.newsListLength
    })
  }

  render() {

    return [
      <div className="card">
        <div className="card-body">

          <h5 className="card-title mb-3">NEWS</h5>
          <div className="col-md-12 pl-0 pr-0 mb-2">
            {
              this.props.newsListLength > 0 ?
                <Slides listItems={this.props.newsList} />
                : ""

            }


          </div>


          {/* <div className="marqueeContainer">

            <span className="marquee">

              {
                newsList.map((newsData) => (
                  <span>
                    <p className="card-text-bold"> <b>{newsData.subject}</b> </p>
                    <p className="card-text"> {newsData.body}
                    { newsData.link === ""?
                     "":
                     <a href={newsData.link}  className="card-link mt-1 ml-3"> <u> open </u></a>
                    }
                      
                    </p>
                  </span>
                ))
              }

            </span>

          </div> */}


          <div className="col-md-12 pl-0 pr-0">
            {/* <p className="card-text-bold"><b>New Feature  </b> </p>
            <p className="card-text">  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
          <a href="https://se-emb-dev.azurewebsites.net/" className="card-link mt-1 ml-3"> <u> open </u></a>
            </p> */}

            {/* <h5 className="card-title">HELPS</h5>
            <div className="mt-0">
              <se-icon className="cheatSheetIcon se-icon" color="primary" >help </se-icon> <span className="ml-2">Online Help</span>
            </div> */}

            {/* <div className="mt-2">
              <se-icon className="cheatSheetIcon se-icon" color="primary" >media_player_playrun</se-icon> <span className="ml-2"><a className="tutorialText" rel="noopener noreferrer"  href="https://youtu.be/y-AGYJzZNdk" target="_blank">Tutorials</a></span>
            </div> */}

            <div className="mt-2">
              <se-icon className="cheatSheetIcon se-icon" color="primary" >help_readusermanuel</se-icon> <span className="ml-2 pointer" onClick={this.props.onBoardingClick}>Onboarding</span>
            </div>

          </div>


        </div>
      </div>
    ];
  }
}

export default SENews;
