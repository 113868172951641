import React, { useCallback, useState, useRef } from "react";
import ApiSession from "../api/ApiSession";
import Snackbar from "./SE-Component/Snackbar";
import ViewSubscriptionDetails from "../components/component/ViewSubscriptionDetails";

const DscTable = ({
  data,
  itemSliceDetails,
  setRefresh,
  expandedRow,
  setExpandedRow,
  setIndexValue,
  setLoading,
}) => {
  const apiSession = new ApiSession();
  const [open, setOpen] = useState(false);
  const [snackBar, setSnackBar] = useState({ type: "", message: "" });
  const snackBarRef = useRef(null);

  const [popPupStatus, setPopupStates] = useState(false);
  const [subcriptionNumber, setSubscriptionNumber] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  /* istanbul ignore next */
  const callSnackbar = useCallback((type, message) => {
    setSnackBar({ type, message });
    snackBarRef.current.open = true;
  }, []);
  /* istanbul ignore next */
  const handleRowClick = (index) => {
    if (expandedRow === index) {
      setExpandedRow(-1);
    } else {
      setExpandedRow(index);
      setIndexValue(index);
    }
  };
  /* istanbul ignore next */
  const handleResendDscMail = (dscId) => {
   try {
    apiSession
    .reSendDscMail(dscId)
    .then((response) => {
      callSnackbar("success", "Successfully resend the mail");
    })
    .catch((error) => {
      console.log(error);
    });
  console.log(dscId);
   } catch (error) {
    
   }
  };
  /* istanbul ignore next */
  const dateFormate = (dateStr) => {
    const date = new Date(dateStr);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${day}-${month}-${year}`;
  };
  const closeViewPopup = () => {
    setPopupStates(false);
  };
  const getSubscriptionDetailsDetails = (data, status) => {
    setPopupStates((prev) => !prev);
    if (data) {
      try {
        const subscriptionDetails = JSON.parse(data);
        subscriptionDetails.subscription.status = status;
        setSubscriptionDetails(subscriptionDetails);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.error("Data is undefined or null");
    }
  };
  const handlerDscActivation = () => {
    setLoading(true);
    apiSession.activateDsc(subcriptionNumber).then((res) => {
      if (res?.subscription?.status === "Activated") {
        setRefresh(true);
        setExpandedRow(-1);
      }
    });
    setOpen(false);
  };
  return (
    <>
      <table className="table table-bordered mb-0 text-center">
        <thead>
          <tr>
            <th width="10%">Subscription No</th>
            <th width="10%">Customer Name</th>
            <th width="10%">Country Po Reference</th>
            <th width="10%">Cost Type Reference</th>
            <th width="10%">Term Period</th>
            {/* <th width="9%">Auto Renew</th> */}
            <th width="10%">Activation Start Date</th>
            <th width="10%">Subscription End Date</th>
            <th width="10%">Status</th>
            <th width="10%">Action </th>
          </tr>
        </thead>
        <tbody>
          {data
            ?.slice(
              itemSliceDetails.itemSliceFirst,
              itemSliceDetails.itemSliceLast
            )
            .map((item, index) => (
              <React.Fragment key={index}>
                <tr className="text-center">
                  <td>{item.subscriptionNumber}</td>
                  <td>{item.customerName}</td>
                  <td>{item.customerPaymentRef}</td>
                  <td>{item.costTypeReference}</td>
                  <td>{item.currentTerm}</td>
                  {/* <td>{item.AutoRenew ?? "NO"}</td> */}
                  <td>{item.termStartDate}</td>
                  <td>{item.termEndDate}</td>
                  <td>
                    <div className="d-flex  align-items-center position-relative ">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <span
                        className="dot position-absolute top-0 start-0"
                        style={{
                          backgroundColor: `${
                            item.status === "Active"
                              ? "#3dcd58"
                              : item.status === "Expired"
                              ? "#DC0A0A"
                              : item.status === "Used"
                              ? "#0087CD"
                              : "#E47F00"
                          }`,
                        }}
                      ></span>
                      &nbsp;&nbsp;<span> {item.status}</span>{" "}
                    </div>
                  </td>
                  <td width="12%" className="align-middle text-center">
                    {expandedRow !== index ? (
                      //<span className="bold">&#43;</span>
                      <>
                        <span className="pointer">
                          <se-icon
                            size="small"
                            color="primary"
                            title="View More"
                            onClick={() =>
                              getSubscriptionDetailsDetails(
                                item.details,
                                item.status
                              )
                            }
                          >
                            body_eye
                          </se-icon>
                        </span>
                        <se-icon
                          size="small"
                          color="secondary"
                          className="bold"
                          onClick={() => handleRowClick(index)}
                        >
                          electricity_polarity_positive_plus
                        </se-icon>
                      </>
                    ) : (
                      //<span className="bold">&#8722;</span>
                      <>
                        <span className="pointer">
                          <se-icon
                            size="small"
                            color="primary"
                            title="View More"
                            onClick={() =>
                              getSubscriptionDetailsDetails(
                                item.details,
                                item.status
                              )
                            }
                          >
                            body_eye
                          </se-icon>
                        </span>
                        <se-icon
                          size="small"
                          color="secondary"
                          className="bold"
                          onClick={() => handleRowClick(index)}
                        >
                          electricity_polarity_negative_minus
                        </se-icon>
                      </>
                    )}
                  </td>
                </tr>
                {expandedRow === index && (
                  <tr>
                    <td colSpan="12">
                      <table width="100%" className="text-left borderless">
                        <thead>
                          <tr>
                            <td colSpan="12" style={{ borderBottom: "none" }}>
                              <div className="row m-0">
                                <div className="col-sm-8 col-md-18 col-lg-18 col-xl-8 text-left">
                                  <div>Installer Email: {item.shipToEmail}</div>
                                  <div>Cost Type: {item.costType}</div>
                                </div>
                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 text-right d-flex justify-content-end">
                                  <div className="d-flex align-items-end">
                                    <div></div>
                                    <div>
                                      <se-button
                                        option="flat"
                                        color="secondary"
                                        disabled={
                                          item?.status === "Active"
                                            ? false
                                            : true
                                        }
                                        // icon="mail_send_paper_plane_perspective"
                                        onClick={() => {
                                          setOpen(true);
                                          setSubscriptionNumber(
                                            item?.subscriptionNumber
                                          );
                                        }}
                                      >
                                        Activate
                                      </se-button>
                                    </div>
                                  </div>
                                  <div>
                                    <div>
                                      Purchase Date:{" "}
                                      {dateFormate(item.publishDate) ?? "NA"}
                                    </div>
                                    <div>
                                      <se-button
                                        option="flat"
                                        color="primary"
                                        icon="mail_send_paper_plane_perspective"
                                        onClick={() =>
                                          handleResendDscMail(item.id)
                                        }
                                      >
                                        Resend Mail
                                      </se-button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr className="table-secondary">
                            <th>Product Description</th>
                            <th>Type</th>
                            <th>Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item?.productList?.map((product, productIndex) => (
                            <tr key={productIndex}>
                              <td>
                                {product.sku}
                                <br />
                                {product.name}
                              </td>
                              <td>{product.type}</td>
                              <td>{product.quantity}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
        </tbody>
      </table>
      <Snackbar snackBar={snackBar} ref={snackBarRef} />
      {popPupStatus && (
        <ViewSubscriptionDetails
          viewSubscriptionDetails={subscriptionDetails}
          dscPopupStatus={popPupStatus}
          closeViewPopup={closeViewPopup}
        />
      )}
      {open && (
        <se-dialog
          id="dialog-simple-notitle"
          color="alternative"
          size="small"
          open={open}
        >
          <se-dialog-header color="primary">Alert </se-dialog-header>
          <se-dialog-content icon="information_circle" color="primary">
            {" "}
            Once activated, you cannot change the activation date.
          </se-dialog-content>
          <se-dialog-footer>
            <se-button onClick={() => setOpen(false)}>Cancel</se-button>
            <se-button color="primary" onClick={handlerDscActivation}>
              Ok
            </se-button>
          </se-dialog-footer>
        </se-dialog>
      )}
    </>
  );
};

export default DscTable;
