import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";
import MyErrorBoundary from "../../utils/ErrorBoundary";

const Load = ({ load, loadName, loadCbName, index }) => {
  const {
    assetDetailsReset,
    UserScope,
    callSnackbar,
    CBLoadListDispatch,
    selectedValuesHandler,
    loadConfigDataHandler,
  } = useContext(DesignAssetContext);

  const loadSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "loadDetailsBlock";
      const selectedAssetValue = "selectedLoad";
      CBLoadListDispatch({
        type: ACTIONS.LOAD_SELECT,
        payload: {
          index,
        },
      });
      selectedValuesHandler(index, selectedAssetValue, assetBlock);
      loadConfigDataHandler(index);
    } else {
      callSnackbar(
        "error",
        `${UserScope?.role} is not authorized to perform action on this page`
      );
    }
  };

  return (
    <MyErrorBoundary>

    <svg className="pointer">
      <g id="CB1-load" style={{ pointerEvents: "none" }}>
        {/* <line
          x1="500"
          y1="406"
          x2="560"
          y2="406"
          id="Line-2"
          stroke="#333"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="8,8"
        ></line> */}
        <svg
          width="70px"
          height="88px"
          viewBox="0 0 10 149"
          x={load?.xAxisCB}
          y={load?.yAxisCB}
        >
          <title>{loadCbName}</title>
          <g
            id="Design"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Design/Assets-_-00"
              transform="translate(-795.000000, -512.000000)"
            >
              <g
                id="SLD/CB/Vertical/Uniline/Open/Black_NEMA"
                transform="translate(748.000000, 514.000000)"
              >
                <g
                  id="Group-19"
                  strokeWidth="4"
                  fillRule="evenodd"
                  fill={"none"}
                  stroke={"none"}
                >
                  <rect
                    id="Rectangle-Copy-4"
                    x="0"
                    y="12"
                    width="96"
                    height="122"
                  ></rect>
                  <line
                    x1="48.501111"
                    y1="3.55271368e-15"
                    x2="48.501111"
                    y2="48"
                    id="Line-2-Copy-13"
                    stroke={"#333333"}
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                  <line
                    x1="48.501111"
                    y1="96"
                    x2="48.501111"
                    y2="144"
                    id="Line-2-Copy-15"
                    stroke={"#333333"}
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></line>
                </g>
                <path
                  d="M59.9160156,48.3289474 C67.860026,64.1096491 67.860026,80 59.9160156,96"
                  id="Path-11"
                  stroke={"#333333"}
                  strokeWidth="4"
                  strokeLinecap="round"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </g>
      <g id="load1">
        <svg
          x={load?.xAxis}
          y={load?.yAxis}
          width="149px"
          height="475px"
          viewBox="0 0 90 550"
          onClick={loadSelectHandler}
        >
          <title>{loadName}</title>
          <g
            id="Design"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Design/Assets-_-00"
              transform="translate(-604.000000, -372.000000)"
            >
              <g
                id="SLD/Production/Uniline/Undefined"
                transform="translate(604.000000, 372.000000)"
              >
                <g id="SLD/Production/Uniline/Tobedefine">
                  <g id="Group-7">
                    <rect
                      id="Rectangle"
                      x="0"
                      y="95"
                      width="96"
                      height="148"
                    ></rect>

                    <line
                      x1="48.5"
                      y1={load?.yAxis === 377 ? "0" : "96"}
                      x2="48.5"
                      y2="144"
                      id="Line-2"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      stroke={load?.configured ? "#333333" : "#E47F00"}
                    ></line>

                    <circle
                      id="Oval"
                      stroke="#42B4E6"
                      strokeWidth="3"
                      cx="48"
                      cy="193.5"
                      r="55"
                      style={load?.selected ? {} : { display: "none" }}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="rgba(0, 135, 205, 0.2)"
                    ></circle>

                    <circle
                      id="Oval"
                      strokeWidth="3"
                      fill="#FFFFFF"
                      cx="48"
                      cy="193.5"
                      r="46.5"
                      stroke={load?.configured ? "#333333" : "#E47F00"}
                    ></circle>

                    <text
                      id="segment_energy_infra"
                      fontFamily="se-icon"
                      fontSize="56"
                      fontWeight="normal"
                      fill="#333333"
                    >
                      <tspan x="20" y="210">
                        segment_building_small
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </g>
    </svg>
    </MyErrorBoundary>
  );
};

export default Load;
