import React from "react";

const SecureTableHeading = ({data}) => (
  
  <table className="table table-bordered mb-0">
    <thead>
      
      <tr className="text-center">      
        <th width="10%">PVID<span className="se-mandatory">*</span></th>
        <th width="10%">
          VLAN<span className="se-mandatory">*</span>
        </th>
        <th width="15%">
         NetworkAddress<span className="se-mandatory">*</span>
        </th>
        <th width="12%">
        Netmask
          <span className="se-mandatory">*</span>
        </th>
        <th width="10%">
          CIDR<span className="se-mandatory">*</span>
        </th>
        <th width="20%">
          Host Range
          <span className="se-mandatory">*</span>
        </th>
        <th width="10%" className="text-center">
        Gateway (moxa)
        <span className="se-mandatory">*</span>
        </th>
       
      </tr>
    </thead>
  </table>
);

export default SecureTableHeading;
