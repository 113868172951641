
import React, { Component } from "react";

class Slides extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
      currentIndex: 0,
      listItems: this.props.listItems,
      itemSliceFirst: 0,
      itemSliceLast: 4,
      defaultSlice: 4
    };
  }

  componentDidMount(){
  }


  componentWillReceiveProps() {
    this.setState({
      listItems: this.props.listItems,
      itemSliceFirst: 0,
      itemSliceLast: 4,
      defaultSlice: 4,
      slideIndex: 0,
      currentIndex: 0
    });

  }


  goTo = (direction) => {
    let index = 0;
    switch (direction) {

      case 'prev':
        index = this.state.currentIndex - this.state.defaultSlice;
        let currentIndexPrev = index < 0 ? this.state.listItems.length - 1 : index;
        this.setState({ currentIndex: currentIndexPrev });
        this.setState({ itemSliceLast: (currentIndexPrev + this.state.defaultSlice) }, () => {
          this.setState({ itemSliceFirst: currentIndexPrev })
        });


        break;
      case 'next':
        index = this.state.currentIndex + this.state.defaultSlice;
        let currentIndexNext = index >= this.state.listItems.length ? 0 : index;
        this.setState({ currentIndex: currentIndexNext });
        this.setState({ itemSliceLast: (currentIndexNext + this.state.defaultSlice) }, () => {
          this.setState({ itemSliceFirst: currentIndexNext })
        });
        break;

      default:
        break;
    }
    this.setState({
      slideIndex: this.state.currentIndex
    });

  };

  render() {
    return (
      <div className="slideshow-simple">
        <div className="item-slide">
          {
            this.state.listItems.slice(this.state.itemSliceFirst, this.state.itemSliceLast).map((newsData, idx) => (
              <span key={idx}>
                <p className="card-text-bold"> <b>{newsData.subject}</b> </p>
                <p className="card-text"> {newsData.body}
                  {newsData.link === "" ?
                    "" :
                    <a href={newsData.link} className="card-link mt-1 ml-3"> <u> open </u></a>
                  }

                </p>
              </span>

            ))
          }



        </div>

        {
          (this.state.listItems.length / this.state.defaultSlice) > 1 ?
            <div>
              {
                this.state.currentIndex - this.state.defaultSlice >= 0  ?
                <span className="pointer " onClick={(e) => this.goTo('prev')}><u>Prev</u></span>
                : ""

              }
             &nbsp;&nbsp;&nbsp;
              {
                (this.state.currentIndex + this.state.defaultSlice) <= this.state.listItems.length ?
             
                  <span className="pointer " onClick={(e) => this.goTo('next')}><u>Next </u></span>
               :""} 
              {/* <button type="button" className="btn btn-link btn-sm">Prev</button> */}
              {/* <button type="button" className="btn btn-link btn-sm" onClick={(e) => this.goTo('next')}>Next</button>        */}
            </div> : ""
        }

      </div>
    )
  }
}

export default Slides;
