export function assignIpAddress(CBAssetList,dummyCBData, defaultIpAddress, ){
    const numberOfDevices = parseInt(dummyCBData?.[0]?.value)??7
    let ipCount = 1;
    let circuitBreakerCount = 1;
    CBAssetList?.forEach((item) => {
        if(item?.componentType==="Circuit Breaker"&&item?.communications?.[0]?.ip!==''){
            item.communications[0].ip = ipCount===1?defaultIpAddress[`ifeIpAddress`]:defaultIpAddress[`ifeIpAddress${ipCount}`];
            if((circuitBreakerCount)%numberOfDevices===0) ipCount++;
            circuitBreakerCount++;
        }
    });
    return CBAssetList;
}