import React, { Component } from 'react';


class NonRegistered extends Component {
    
    render() {
        return (
            <se-container option="centered">
                
                <div className="container mb-1" >

                    <div className="row justify-content-md-center" >
                        <div className="col-md-8 col-sm-8 col-lg-8 se-white-background pt-2 mt-5 pl-0 pr-0 text-center error-msg">
                            <se-block-content>
                                <h4><se-icon size="small">notification_critical</se-icon> User  doesn't have valid access to login into Ecostruxure Microgrid Build. Please contact administrator </h4>
                                
                            </se-block-content>
                        </div>


                    </div>
                    
                </div>
            </se-container>

        )
    }
}

export default NonRegistered;