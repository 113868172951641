import ACTIONS from "../Design_Asset_Actions.json";

const initialCount = {
  derCount: 0,
  loadCount: 0,
  evCount: 0,
};

const countReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_DER_COUNT:
      return {
        ...state,
        derCount: state.derCount + action.payload.count,
      };
    case ACTIONS.UPDATE_LOAD_COUNT:
      return {
        ...state,
        loadCount: state.loadCount + action.payload.count,
      };
    case ACTIONS.UPDATE_EV_COUNT:
      return {
        ...state,
        evCount: state.evCount + action.payload.count,
      };

    default:
      return state;
  }
};

export { initialCount, countReducer };
