import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

const Ecc = () => {
  const {
    coordinates: {
      ecc: { width },
    },
    siteType,
  } = useContext(DesignAssetContext);
  return (
    <svg>
      <g id="ECC">
        <svg>
          <g
            id="ECC"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="ECC" transform="translate(-532.000000, -514.000000)">
              <g
                id="SLD/Infos/Microgrid_Background"
                transform="translate(532.000000, 514.000000)"
              >
                <rect
                  id="Rectangle-Copy-245"
                  stroke="#333333"
                  strokeWidth="3"
                  x="20"
                  y="195"
                  width={width}
                  height="180"
                  rx="16"
                />
                <text
                  id="AAA"
                  fontFamily="Nunito-Regular, Nunito"
                  fontSize="18"
                  fontWeight="normal"
                  fill="#333333"
                >
                  <tspan x="25" y="225">
                    Low Voltage{" "}
                  </tspan>
                  /
                  <tspan x="25" y="245">
                    Switchboard
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </svg>
      </g>
    </svg>
  );
};

export default Ecc;
