import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

import embLabels from "../../config/emb-labels-en.json";

const SLDEcc = () => {
  const {
    count: { derCount },
    siteType,
    switchboardType,
    CBAssetList,
    count,
    architectureType,
    rectangleWidth
  } = useContext(DesignAssetContext);

  
  return (
    <>
      {switchboardType === embLabels.EXISTING_SWITCHBOARD ? (
        <svg>
          <g id="ECC1">
            <svg>
              <g
                id="ECC1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="ECC1" transform="translate(-532.000000, -514.000000)">
                  <g
                    id="SLD/Infos/Microgrid_Background"
                    transform="translate(532.000000, 514.000000)"
                  >
                    <rect
                      id="Rectangle-Copy-24"
                      stroke="#333333"
                      strokeWidth="3"
                      x="40"
                      y="170"
                      // y={loadSide==="LOAD_SIDE"?"200":"170"}
                      // width="570"
                      // width={CBLoadList.length <= 3 ? "420" : CBLoadList.length === 4 ? "450" : "570"}
                      width={Number(rectangleWidth)-100}
                      //height={loadSide==="LOAD_SIDE"?"200.3":"205.3"}
                      height="205.3"
                      rx="16"
                      // height="205.3"
                    />
                    <text
                      id="AAA"
                      fontFamily="Nunito-Regular, Nunito"
                      fontSize="18"
                      fontWeight="normal"
                      fill="#333333"
                    >
                      <tspan
                        x="200"
                        //  y={loadSide==="LOAD_SIDE"?"390":"360"}
                        y="360"
                      >
                        Existing Switchboard
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </svg>
          </g>
          <g id="ECC2">
            <svg>
              <g
                id="ECC2"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="ECC2" transform="translate(-532.000000, -514.000000)">
                  <g
                    id="SLD/Infos/Microgrid_Background"
                    transform="translate(532.000000, 514.000000)"
                  >
                    <rect
                      id="Rectangle-Copy-24"
                      stroke="#333333"
                      strokeWidth="3"
                      x="590"
                      y="372"
                      //y={loadSide==="LOAD_SIDE"?"392":"332"}
                      // width={
                      //   architectureType === embLabels.PV_GENSET_ATS ? "310" : clientType === "GREENSTRUXURE" ? "550" : "400"
                      // }
                      width={
                        rectangleWidth
                      }
                      height="200.3"
                      rx="16"
                    />
                    <text
                      id="AAA"
                      fontFamily="Nunito-Regular, Nunito"
                      fontSize="18"
                      fontWeight="normal"
                      fill="#333333"
                    >
                      <tspan
                        x={
                          count.derCount === 4
                          ? "860"
                          : count.derCount === 5
                          ? "980"
                          : "700"
                        }
                        // y={loadSide==="LOAD_SIDE"?"580":"520"}
                        y="550"
                      >
                        Energy Control Center
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </svg>
          </g>
        </svg>
      ) : (
        <svg>
        {" "}
        <g id="ECC">
          {" "}
          <svg>
            {" "}
            <g
              id="ECC"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              {" "}
              <g id="ECC" transform="translate(-532.000000, -514.000000)">
                {" "}
                <g
                  id="SLD/Infos/Microgrid_Background"
                  transform="translate(532.000000, 514.000000)"
                >
                  {" "}
                  <rect
                    id="Rectangle-Copy-24"
                    stroke="#333333"
                    strokeWidth="3"
                    x="360"
                    y={`${
                      siteType === embLabels.GENSET_PVBESS_A2E ? "261" : "269"
                    }`}
                    width={
                      rectangleWidth
                    }
                    height={`${
                      siteType === embLabels.GENSET_PVBESS_A2E
                        ? "188"
                        : siteType === embLabels.GRID_CONNECTED
                        ? "196"
                        : "180"
                    }`}
                    rx="16"
                  />{" "}
                  <text
                    id="AAA"
                    fontFamily="Nunito-Regular, Nunito"
                    fontSize="18"
                    fontWeight="normal"
                    fill="#333333"
                  >
                    {" "}
                    <tspan x="392" y="310">
                      Low Voltage{" "}
                    </tspan>
                    /{" "}
                    <tspan x="392" y="330">
                      Switchboard
                    </tspan>{" "}
                  </text>{" "}
                </g>{" "}
              </g>{" "}
            </g>{" "}
          </svg>{" "}
        </g>{" "}
        </svg>
      )}
    </>
  );
};

export default SLDEcc;