import ACTIONS from "./Design_Asset_Actions.json";
import _ from "lodash";

import embLabels from "../../config/emb-labels-en.json";

const communicationDataSample = {
  ipaddress: "",
  subnetmask: "",
  defaultGateway: "",
  modbusSlaveId: "",
};

const initialCBLoadList = [
  {
    modbusSlaveId: "21",
    configData: {
      name: "",
      description: "",
      maximum_available_power: 0,
    },
    uiData: {
      status: false,
      configured: false,
      CBSelected: false,
      selected: false,
      CBConfigured: false,
      xAxisCB: 205,
      yAxisCB: 290,
      xAxis: 160,
      yAxis: 292,
      detailsBlock: false,
    },
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
      cbAssetDetails: [],
      cbCommunicationData: {},
    },
  },
];
const projectTypeValue = JSON.parse(
  localStorage.getItem("projectType")
)?.projectTypeValue;

const CBLoadListReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.ASSIGN_DEFAULT_VALUES:
      return assignDefaultValuesCBLoadList(
        state,
        action.payload.defaultValues,
        action.payload.defaults,
        action.payload.type,
        action.payload.cb,
        action.payload.dummyCB
      );
    case ACTIONS.ASSIGN_USECASE_LOAD:
      return assignUseCaseLoad(
        state,
        action.payload.defaultValues,
        action.payload.defaultCbData,
        action.payload.siteType,
      );
    case ACTIONS.UPDATE_CBLOADLIST_COORDINATES:
      return updateCbLoadListCoordinates(
        state,
        action.payload.coordinates,
        action.payload.count
      );
    case ACTIONS.UPDATE_CBLOADLIST_COORDINATES_GENSET_LOAD:
      return updateCbLoadListCoordinatesGensetLoad(
        state,
        action.payload.coordinates,
        action.payload.count
      );
    case ACTIONS.UPDATE_CBLOADLIST_COORDINATES_GRIDCONNECTED:
      return updateCbLoadListCoordinatesGridConnected(state);
    case ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVHYBRIDIZATION:
      return updateCbLoadListCoordinatesPvHybridization(state);
    case ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVGENSET_ATS_OP:
      return updateCbLoadListCoordinatesPvGensetAts(state, action.payload);
    case ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVBESS_NR:
      return updateCbLoadListCoordinatesPvBessNr(state, action.payload);
    case ACTIONS.UPDATE_LOAD:
      return updateLoad(
        state,
        action.payload.componentsData,
        action.payload.loadIndex,
        action.payload.siteType,
        action.payload.projectTypeValue,
      );
    case ACTIONS.UPDATE_LOAD_CB:
      return updateLoadCb(
        state,
        action.payload.componentsData,
        action.payload.loadCBIndex,
        action.payload.siteType,
        action.payload.projectTypeValue,
      );
    case ACTIONS.CLOSEDETAILS_LOAD:
      return closeDetailsLoad(state);
    case ACTIONS.LOAD_SELECT:
      return loadSelect(state, action.payload.index);
    case ACTIONS.LOAD_CB_SELECT:
      return loadCbSelect(state, action.payload.index);
    case ACTIONS.UPDATE_LOAD_CONFIG:
      return updateLoadConfig(
        state,
        action.payload.selectedLoad,
        action.payload.configData
      );
    case ACTIONS.DUPLICATE_LOAD_UPDATE:
      return duplicateLoadUpdate(state, action.payload.selectedLoad);
    case ACTIONS.UPDATE_LOAD_CB_CONFIG:
      return updateLoadCbConfig(
        state,
        action.payload.selectedLoadCB,
        action.payload.cbConfigData
      );
    case ACTIONS.ADD_LOAD:
      return addLoad(
        state,
        action.payload.defaultPreData,
        action.payload.count,
        action.payload.cbCommunicationValues,
        action.payload.defaultCbData
      );
    case ACTIONS.REMOVE_LOAD:
      return removeLoad(state, action.payload.selectedLoad);
    case ACTIONS.SELECT_LOAD_CBASSET:
      return selectLoadCbAsset(
        state,
        action.payload.index,
        action.payload.selectedAsset,
        action.payload.cbCommunicationValues,
        action.payload.defaultValues,
        action.payload.count,
      );
    case ACTIONS.UPDATE_MAXIMUM_LOAD_POWER:
      return updateMaximumLoadPower(
        state,
        action.payload.index,
        action.payload.loadPower
      );
    default:
      return state;
  }
};

const updateMaximumLoadPower = (CBLoadList, index, loadPower) => {
  const loadList = [...CBLoadList];

  loadList[index].configData.maximum_available_power = loadPower;
  //return new state
  return loadList;
};
const assignDefaultValuesCBLoadList = (
  CBLoadList,
  defaultValues,
  defaults,
  type,
  cb,
  dummyCB,
) => {
  const loadList = _.cloneDeep(CBLoadList);
  const defaultsData = {};
  for (let defaultData of defaults) {
    defaultsData[defaultData["param"]] = defaultData["value"];
  }
  const circuitBreakersModbusSlaveIdList = defaultValues["Circuit Breaker"];
  loadList[0].configData.name = `${defaultValues.Load.name} 1`;
  loadList[0].configData.maximum_available_power = defaultsData.maxPowerLoadMin;
  loadList[0].cbConfigData.name = `${defaultValues["Circuit Breaker"].name} ${defaultValues.Load.name} 1`;
  loadList[0].uiData.CBSelected = false;
  loadList[0].cbConfigData.motorized = true;
  loadList[0].modbusSlaveId =
    circuitBreakersModbusSlaveIdList["modbusId_CB_50"];
    if (type === embLabels.GRID_CONNECTED && projectTypeValue === "MGaaS ANSI"||
    (projectTypeValue === "DaaS ANSI"&&type === embLabels.GRID_CONNECTED_GSX)) {
      loadList[0].uiData.configured =true;
      loadList[0].uiData.CBConfigured =true;
    }
  // if (type === embLabels.GENSET_PVBESS_A2E || type=== embLabels.ISLANDABLE_GSX||type===embLabels.GRID_CONNECTED_GSX) {
  //   loadList[0].cbConfigData.cbAssetDetails[0] = cb;
  // }
 
  if (type !== embLabels.GRID_CONNECTED) {
    loadList[0].cbConfigData.cbAssetDetails[0] = { ...cb };
  }
  if (type === embLabels.GRID_CONNECTED && projectTypeValue === "MGaaS IEC") {
    loadList[0].cbConfigData.cbAssetDetails[0] = { ...dummyCB };
  }
  loadList[0].cbConfigData.cbCommunicationData = { ...communicationDataSample };
  let assetInfo =
    type === embLabels.GRID_CONNECTED && projectTypeValue === "MGaaS IEC"
      ? dummyCB?.assetInfo
      : cb?.assetInfo;
  const ipaddress =
    assetInfo === "Serial" || assetInfo === "Hardwired"
      ? ""
      : defaultValues["Circuit Breaker"].ipAddress1;
  const subnetmask =
    assetInfo === "Serial" || assetInfo === "Hardwired"
      ? ""
      : defaultValues["Circuit Breaker"]?.subnetMask;
  const defaultGateway =
    assetInfo === "Serial" || assetInfo === "Hardwired"
      ? ""
      : defaultValues["Circuit Breaker"]?.defaultGateway;
  const modbusSlaveId =
    assetInfo === "Hardwired"
      ? ""
      : assetInfo === "Serial"
      ? defaultValues["Circuit Breaker"].ipAddress7.split(".")[3]
      : defaultValues["Circuit Breaker"].modbusId_CB_50;
  loadList[0].cbConfigData.cbCommunicationData = {
    ipaddress,
    subnetmask,
    defaultGateway,
    modbusSlaveId,
  };
  //return new state
  return loadList;
};
const assignUseCaseLoad = (
  CBLoadList,
  defaultValues,
  defaultCbData,
  siteType,
  dummyCb
) => {
  const loadList = [...CBLoadList];
  loadList[0].uiData.status = true;
  loadList[0].uiData.configured = false;
  if (siteType === embLabels.GRID_CONNECTED && projectTypeValue === "MGaaS ANSI") {
    loadList[0].uiData.configured =true;
    loadList[0].uiData.CBConfigured =true;
  }
  //return new state
  return loadList;
};

const updateCbLoadListCoordinates = (CBLoadList, coordinates, count) => {
  let xLoad = coordinates.xLoad;
  let xLoadCB = coordinates.xLoadCB;
  let xLoadNew = coordinates.xLoadNew;
  let xLoadCBNew = coordinates.xLoadCBNew;
  let yLoad = 292;
  //  const totalLoads = count.loadCount + count.evCount;
  let newLoadData = [];
  let loadList = [...CBLoadList];
  if (siteType === embLabels.GRID_CONNECTED && projectTypeValue === "MGaaS ANSI") {
    loadList[0].uiData.configured =true;
    loadList[0].uiData.CBConfigured =true;
  }
  loadList.forEach((cbLoadData, key) => {
    if (key) {
      xLoad = coordinates.loadDiff + xLoad;
      xLoadCB = coordinates.loadDiff + xLoadCB;
      if (key > 11) yLoad = 377;

      if ((key === 11 && xLoad !== 1110) || xLoad === 1220) {
        xLoad = xLoadNew;
        xLoadCB = xLoadCBNew;
        yLoad = 377;
      }
    }

    cbLoadData.uiData.xAxis = xLoad;
    cbLoadData.uiData.yAxis = yLoad;
    cbLoadData.uiData.xAxisCB = xLoadCB;
    cbLoadData.uiData.yAxisCB = 290;
    newLoadData.push(cbLoadData);
    //return null;
  });

  return newLoadData;
};

const updateCbLoadListCoordinatesGensetLoad = (
  CBLoadList,
  coordinates,
  count
) => {
  let xLoad = 440;
  let xLoadCB = 485;
  let xLoadNew = 505;
  let xLoadCBNew = 650;
  let yLoad = 450;
  //  const totalLoads = count.loadCount + count.evCount;
  let newLoadData = [];
  let loadList = [...CBLoadList];

  loadList.forEach((cbLoadData, key) => {

    if (key) {
      xLoad = coordinates.loadDiff + xLoad;
      xLoadCB = coordinates.loadDiff + xLoadCB;
      if (key > 5) yLoad = 534;

      if ((key === 5 && xLoad !== 480) || xLoad === 495) {
        xLoad = xLoadNew;
        xLoadCB = xLoadCBNew;
        yLoad = 534;
      }
    }

    cbLoadData.uiData.xAxis = xLoad;
    cbLoadData.uiData.yAxis = yLoad;
    cbLoadData.uiData.xAxisCB = xLoadCB;
    cbLoadData.uiData.yAxisCB = 430;
    newLoadData.push(cbLoadData);
    //return null;
  });

  return newLoadData;
};

const updateCbLoadListCoordinatesGridConnected = (CBLoadList) => {
  let loadList = [...CBLoadList];
  loadList[0].uiData.xAxis = "377.5";
  loadList[0].uiData.yAxis = "385";

  return loadList;
};

const updateCbLoadListCoordinatesPvHybridization = (CBLoadList) => {
  let loadList = [...CBLoadList];

  loadList[0].uiData.xAxis = "398";
  loadList[0].uiData.yAxis = "366";
  loadList[0].uiData.xAxisCB = "442.5";
  loadList[0].uiData.yAxisCB = "360";

  return loadList;
};

const updateCbLoadListCoordinatesPvGensetAts = (CBLoadList, switchType) => {
  let loadList = [...CBLoadList];

  loadList[0].uiData.xAxis = "139";
  loadList[0].uiData.yAxis = "299";
  loadList[0].uiData.xAxisCB = "183.5";
  loadList[0].uiData.yAxisCB = switchType === embLabels.NEW_SWITCHBOARD ? 300 : 285.8956;

  return loadList;
};
const updateCbLoadListCoordinatesPvBessNr = (CBLoadList,switchType) => {
  let loadList = [...CBLoadList];
  loadList[0].uiData.xAxis = "139";
  loadList[0].uiData.yAxis = "300";
  loadList[0].uiData.xAxisCB = "184";
  loadList[0].uiData.yAxisCB =  switchType === embLabels.NEW_SWITCHBOARD ? 300 : 285;

  return loadList;
};

const updateLoad = (CBLoadList, componentsData, loadIndex,siteType,projectTypeValue) => {
  const attributes = JSON.parse(componentsData.attributes);
  let newLoadData = [...CBLoadList];
  if (loadIndex === 0) {
    newLoadData[0].uiData = JSON.parse(componentsData.uiAttributes);
    newLoadData[0].uiData.selected = false;
    newLoadData[0].uiData.CBSelected = false;
    newLoadData[0].configData = {};
    newLoadData[0].cbConfigData = {};
    newLoadData[0].configData.name = componentsData.componentName;
    newLoadData[0].configData.description = attributes.description;
    newLoadData[0].configData.maximum_available_power =
      attributes.maximum_available_power;
    newLoadData[0].assetType = componentsData.componentType;
    if(siteType===embLabels.GRID_CONNECTED&&projectTypeValue==="MGaaS ANSI") newLoadData[0].uiData.configured = true;
  } else {
    let loads = {};
    loads.uiData = JSON.parse(componentsData.uiAttributes);
    loads.uiData.selected = false;
    loads.uiData.CBSelected = false;
    loads.configData = {};
    loads.cbConfigData = {};
    loads.configData.name = componentsData.componentName;
    loads.configData.description = attributes.description;
    loads.configData.maximum_available_power =
      attributes.maximum_available_power;
    loads.assetType = componentsData.componentType;
    newLoadData.push(loads);
  }
  return newLoadData;
};

const updateLoadCb = (CBLoadList, componentsData, loadCBIndex,siteType,projectTypeValue) => {
  const CBLoadListUpdated = _.cloneDeep(CBLoadList);
  const attributes = JSON.parse(componentsData.attributes);
  if (componentsData?.communications?.[0] && CBLoadListUpdated?.[loadCBIndex]) {
    CBLoadListUpdated[loadCBIndex].modbusSlaveId =
      componentsData.communications[0].modbusSlaveId;
  }
  CBLoadListUpdated[loadCBIndex].cbConfigData.description =
    attributes.description;
  CBLoadListUpdated[loadCBIndex].cbConfigData.name =
    componentsData.componentName;
  CBLoadListUpdated[loadCBIndex].cbConfigData.motorized =
    parseInt(attributes.motorized) === 1 ? true : false;
  CBLoadListUpdated[loadCBIndex].cbConfigData.loadCategory = parseInt(
    attributes.loadCategory
  );
  CBLoadListUpdated[loadCBIndex].cbConfigData.loadPriority = parseInt(
    attributes.loadPriority
  );
  if(siteType===embLabels.GRID_CONNECTED&&projectTypeValue==="MGaaS ANSI") CBLoadListUpdated[loadCBIndex].uiData.CBConfigured = true;
  CBLoadListUpdated[loadCBIndex].cbConfigData.cbAssetDetails = [];
  CBLoadListUpdated[loadCBIndex].cbConfigData.cbAssetDetails[0] =
    componentsData.asset;
  CBLoadListUpdated[loadCBIndex].cbConfigData.cbCommunicationData = {
    ...communicationDataSample,
  };

  if (componentsData?.communications?.length && CBLoadListUpdated?.[loadCBIndex]) {
    CBLoadListUpdated[loadCBIndex].cbConfigData.cbCommunicationData.ipaddress =
      componentsData.communications[0].ip;
    CBLoadListUpdated[loadCBIndex].cbConfigData.cbCommunicationData.subnetmask =
      componentsData.communications[0].subnetmask;
    CBLoadListUpdated[
      loadCBIndex
    ].cbConfigData.cbCommunicationData.defaultGateway =
      componentsData.communications[0].defaultGateway;
    CBLoadListUpdated[
      loadCBIndex
    ].cbConfigData.cbCommunicationData.modbusSlaveId =
      componentsData.communications[0].modbusSlaveId;
  }

  return CBLoadListUpdated;
};

const closeDetailsLoad = (CBLoadList) => {
  const newCBLoadList = [...CBLoadList];
  newCBLoadList.forEach((load) => {
    load.uiData.selected = false;
    load.uiData.CBSelected = false;
    load.uiData.detailsBlock = false;
  });
  return newCBLoadList;
};

const loadSelect = (CBLoadList, index) => {
  const newCBLoadList = [...CBLoadList];
  newCBLoadList[index].uiData.selected = true;
  return newCBLoadList;
};

const loadCbSelect = (CBLoadList, index) => {
  const newCBLoadList = [...CBLoadList];
  newCBLoadList[index].uiData.CBSelected = true;
  return newCBLoadList;
};

const updateLoadConfig = (CBLoadList, selectedLoad, configData) => {
  const cbDataList = [...CBLoadList];
  cbDataList[selectedLoad].configData = configData;
  cbDataList[selectedLoad].uiData.configured = true;

  return CBLoadList;
};
const duplicateLoadUpdate = (CBLoadList, selectedLoad) => {
  const cbDataList = [...CBLoadList];
  cbDataList[selectedLoad].uiData.configured = false;

  return CBLoadList;
};

const updateLoadCbConfig = (CBLoadList, selectedLoadCB, cbConfigData) => {
  const cbDataList = [...CBLoadList];
  cbDataList[selectedLoadCB].cbConfigData = {
    ...cbDataList[selectedLoadCB].cbConfigData,
    ...cbConfigData,
  };
  cbDataList[selectedLoadCB].uiData.CBConfigured = true;

  return CBLoadList;
};

const addLoad = (
  CBLoadList,
  defaultPreData,
  count,
  cbCommunicationValues,
  defaultCbData
) => {
  const newLoadData = _.cloneDeep(CBLoadList);
  const totalLoads = count.loadCount + count.derCount + count.evCount;
  let cBMBData = defaultPreData["Circuit Breaker"];
  let cbModbusIndex = 50 + CBLoadList.length;
  if (cbModbusIndex > 59) cbModbusIndex += 1;
  let ipaddressName;
  if((count.derCount+count.loadCount+count.evCount)<=7){
    ipaddressName = `ipAddress${1}`;
   }else if((count.derCount+count.loadCount+count.evCount)>=8&&(count.derCount+count.loadCount+count.evCount)<=14){
     ipaddressName = `ipAddress${2}`;
   }else{
     ipaddressName = `ipAddress${3}`;
   }
  const ipaddress =
    defaultCbData?.assetInfo === "Serial" ||
    defaultCbData?.assetInfo === "Hardwired"
      ? ""
      : cBMBData[ipaddressName];
  const subnetmask =
    defaultCbData?.assetInfo === "Serial" ||
    defaultCbData?.assetInfo === "Hardwired"
      ? ""
      : cBMBData?.subnetMask;
  const defaultGateway =
    defaultCbData?.assetInfo === "Serial" ||
    defaultCbData?.assetInfo === "Hardwired"
      ? ""
      : cBMBData?.defaultGateway;
  const modbusSlaveId =
    defaultCbData?.assetInfo === "Hardwired"
      ? ""
      : defaultCbData?.assetInfo === "Serial"
      ? cBMBData.ipAddress7.split(".")[3]
      : cBMBData["modbusId_CB_" + cbModbusIndex];

  const cbCommunicationData = {
    subnetmask,
    ipaddress,
    defaultGateway,
    modbusSlaveId,
  };

  let newLoad = {
    assetConnected: "Load",
    modbusSlaveId: "21",
    configData: {
      name: "",
      description: "",
      maximum_available_power: 0,
    },
    uiData: {
      status: true,
      CBSelected: false,
      configured: false,
      CBConfigured: false,
      yAxisCB: 290,
      yAxis: 292,
    },
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
      cbAssetDetails: defaultCbData === null ? [] : [{ ...defaultCbData }],
      cbCommunicationData: {
        ...cbCommunicationData,
      },
    },
  };
  newLoadData.push(newLoad);

  let cbIndex = 50;
  let loadIndex = 1;
  let cofigNameArray = newLoadData.map(
    (loadData) => loadData?.configData?.name
  );
  cofigNameArray = cofigNameArray.filter((loadName) => loadName !== "");
  let missingLoadNumber = findMissingLoadNumber(cofigNameArray);

  newLoadData.map((loadData, key) => {
    newLoadData[key].modbusSlaveId =
      totalLoads % 7 === 0 ? "255" : cBMBData["modbusId_CB_" + cbIndex];
    if (newLoadData[key].configData.name === "") {
      if (missingLoadNumber) {
        newLoadData[
          key
        ].configData.name = `${defaultPreData.Load.name} ${missingLoadNumber}`;
      } else {
        newLoadData[
          key
        ].configData.name = `${defaultPreData.Load.name} ${loadIndex}`;
      }

      // load seection
    }
    if (newLoadData[key].cbConfigData.name === "") {
      newLoadData[
        key
      ].cbConfigData.name = `${defaultPreData["Circuit Breaker"].name} ${defaultPreData.Load.name} ${loadIndex}`;
    }
    cbIndex = cbIndex + 1;
    if (cbIndex === 60) {
      cbIndex = 61;
    }
    if (totalLoads % 7 === 0) {
      cbIndex = cbIndex + 1;
    }
    loadIndex = loadIndex + 1;
    return null;
  });
  let index = newLoadData.length - 1;
  newLoadData[index].uiData.status = true;
  newLoadData[index].uiData.configured = false;
  // newLoadData[index].uiData.CBConfigured = true;

  return newLoadData;
};

const findMissingLoadNumber = (array) => {
  function findMissingNumbers(arr) {
    let min = Math.min(...arr);
    let max = Math.max(...arr);
    let fullArr = Array.from({ length: max - min + 1 }, (_, i) => i + min);
    return fullArr.filter((num) => !arr.includes(num));
  }

  const numbersArray = array.map((element) =>
    Number(element.match(/\d+/)?.[0])
  );
  let missingNums = findMissingNumbers(numbersArray.filter(Number));
  const newElement = missingNums?.[0];

  return newElement;
};
const removeLoad = (CBLoadList, selectedLoad) => {
  const removeLoadData = [...CBLoadList];

  const newLoadData = removeLoadData.filter(
    (load, index) => index !== selectedLoad
  );

  // let modbusSlaveID = 20;
  // let count = 0;

  // newLoadData.forEach((loadData, key) => {
  //   count++;
  //   switch (true) {
  //     case count > 0 && count <= 10:
  //       modbusSlaveID = modbusSlaveID + 1;
  //       break;

  //     case count === 11:
  //       modbusSlaveID = 255;
  //       break;

  //     case count > 11 && count <= 16:
  //       modbusSlaveID = modbusSlaveID === 255 ? 1 : modbusSlaveID;
  //       modbusSlaveID = modbusSlaveID + 1;
  //       break;

  //     case count > 16 && count <= 22:
  //       modbusSlaveID = modbusSlaveID === 6 ? 20 : modbusSlaveID;
  //       modbusSlaveID = modbusSlaveID + 1;
  //       break;
  //     default:
  //       break;
  //   }
  //   newLoadData[key].modbusSlaveId = modbusSlaveID;
  // });
  return newLoadData;
};

const selectLoadCbAsset = (
  CBLoadList,
  index,
  selectedAsset,
  cbCommunicationValues,
  defaultValues,
  count
) => {
  const newCBLoadList = _.cloneDeep(CBLoadList);
  const newCBLoadListPrevious = CBLoadList[index].cbConfigData;
  newCBLoadList[index].cbConfigData.cbAssetDetails[0] = selectedAsset;
  newCBLoadList[index].uiData.CBConfigured = false;
  newCBLoadList[index].uiData.configured = false;
let ipAddressName;
  if((count.derCount+count.loadCount+count.evCount)<=7){
   ipAddressName = `ipAddress${1}`;
  }else if((count.derCount+count.loadCount+count.evCount)>=8&&(count.derCount+count.loadCount+count.evCount)<=14){
    ipAddressName = `ipAddress${2}`;
  }else{
    ipAddressName = `ipAddress${3}`;
  }
  const modbusId = `modbusId_CB_5${index}`;
  const ipaddress =
    selectedAsset?.assetInfo === "Serial" ||
    selectedAsset?.assetInfo === "Hardwired"
      ? ""
      : defaultValues["Circuit Breaker"][ipAddressName];
  const subnetmask =
    selectedAsset?.assetInfo === "Serial" ||
    selectedAsset?.assetInfo === "Hardwired"
      ? ""
      : defaultValues["Circuit Breaker"]?.subnetMask;
  const defaultGateway =
    selectedAsset?.assetInfo === "Serial" ||
    selectedAsset?.assetInfo === "Hardwired"
      ? ""
      : defaultValues["Circuit Breaker"]?.defaultGateway;
  const modbusSlaveId =
    selectedAsset.assetInfo === "Hardwired"
      ? ""
      : selectedAsset.assetInfo === "Serial"
      ? defaultValues["Circuit Breaker"][ipAddressName].split(".")[3]
      : defaultValues["Circuit Breaker"][modbusId];

  // const cbCommunicationData = getCBifeValues(
  //   newCBLoadListPrevious,
  //   selectedAsset,
  //   cbCommunicationValues
  // );

  const cbCommunicationData = {
    ipaddress,
    subnetmask,
    defaultGateway,
    modbusSlaveId,
  };
  newCBLoadList[index].cbConfigData.cbCommunicationData = cbCommunicationData;

  return newCBLoadList;
};

const getLoadCbIp = (cbCommunicationValues) => {
  const ifeIp1 = cbCommunicationValues.allIps[0];
  const ifeIp2 = cbCommunicationValues.allIps[1];
  const ifeIp3 = cbCommunicationValues.allIps[2];
  const ifeIp4 = cbCommunicationValues.allIps[3];

  // const ifeIp1 = "192.168.50.21";
  // const ifeIp2 = "192.168.50.22";
  // const ifeIp3 = "192.168.50.23";
  // const ifeIp4 = "192.168.50.24";

  const ifeSet1 = cbCommunicationValues.ifeIpSets[ifeIp1]?.[0] ?? 0;
  const ifeSet2 = cbCommunicationValues.ifeIpSets[ifeIp2]?.[0] ?? 0;
  const ifeSet3 = cbCommunicationValues.ifeIpSets[ifeIp3]?.[0] ?? 0;
  const ifeSet4 = cbCommunicationValues.ifeIpSets[ifeIp4]?.[0] ?? 0;

  const ifeSet1Max = cbCommunicationValues.ifeIpSets[ifeIp1]?.[1] ?? 0;
  const ifeSet2Max = cbCommunicationValues.ifeIpSets[ifeIp2]?.[1] ?? 0;
  const ifeSet3Max = cbCommunicationValues.ifeIpSets[ifeIp3]?.[1] ?? 0;
  const ifeSet4Max = cbCommunicationValues.ifeIpSets[ifeIp4]?.[1] ?? 0;

  const ipaddress =
    ifeSet1 !== ifeSet1Max
      ? ifeIp1
      : ifeSet2 !== ifeSet2Max
      ? ifeIp2
      : ifeSet3 !== ifeSet3Max
      ? ifeIp3
      : ifeSet4 !== ifeSet4Max
      ? ifeIp4
      : cbCommunicationValues.availableIps[0];

  return ipaddress;
};

const getCBifeValues = (
  newCBLoadListPrevious,
  selectedAsset,
  cbCommunicationValues
) => {
  const numberOfDevicesIFEPrevious = parseInt(
    newCBLoadListPrevious.cbAssetDetails[0]?.assetAttributes?.find(
      (assetAttribute) => assetAttribute.type === "numberOfDevicesIFE"
    )?.value ?? 7
  );
  const numberOfDevicesIFE = parseInt(
    selectedAsset?.assetAttributes?.find(
      (assetAttribute) => assetAttribute.type === "numberOfDevicesIFE"
    )?.value ?? 7
  );

  if (numberOfDevicesIFEPrevious === numberOfDevicesIFE) {
    return { ...newCBLoadListPrevious.cbCommunicationData };
  }

  if (numberOfDevicesIFE === 1) {
    const ipaddress = cbCommunicationValues.availableIps[0];
    return {
      ...newCBLoadListPrevious.cbCommunicationData,
      ipaddress: ipaddress,
      modbusSlaveId: "255",
    };
  }

  if (numberOfDevicesIFE === 7) {
    return {
      ...newCBLoadListPrevious.cbCommunicationData,
      ipaddress: getLoadCbIp(cbCommunicationValues),
      modbusSlaveId: cbCommunicationValues.ifeIpSets[
        getLoadCbIp(cbCommunicationValues)
      ]
        ? newCBLoadListPrevious.cbCommunicationData.modbusSlaveId
        : "255",
    };
  }
};

export { initialCBLoadList, CBLoadListReducer };
