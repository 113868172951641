import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";
import MyErrorBoundary from "../../utils/ErrorBoundary";

const Load = ({ load, loadName, index }) => {
  const {
    assetDetailsReset,
    UserScope,
    callSnackbar,
    CBLoadListDispatch,
    selectedValuesHandler,
    loadConfigDataHandler,
    switchboardType,
  } = useContext(DesignAssetContext);

  const loadSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "loadDetailsBlock";
      const selectedAssetValue = "selectedLoad";
      CBLoadListDispatch({
        type: ACTIONS.LOAD_SELECT,
        payload: {
          index,
        },
      });
      selectedValuesHandler(index, selectedAssetValue, assetBlock);
      loadConfigDataHandler(index);
    } else {
      callSnackbar(
        "error",
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  return (
    <MyErrorBoundary>
  
   <>
   { switchboardType === "NEW_SWITCHBOARD"?
     <svg className="pointer">
     <g id="load1">
       <svg
         x={load.xAxis}
         y={load.yAxis}
         width="149px"
         height="475px"
         viewBox="0 0 90 550"
         onClick={loadSelectHandler}
       >
         <title>{loadName}</title>
         <g
           id="Design"
           stroke="none"
           strokeWidth="1"
           fill="none"
           fillRule="evenodd"
         >
           <g
             id="Design/Assets-_-00"
             transform="translate(-604.000000, -372.000000)"
           >
             <g
               id="SLD/Production/Uniline/Undefined"
               transform="translate(604.000000, 372.000000)"
             >
               <g id="SLD/Production/Uniline/Tobedefine">
                 <g id="Group-7">
                   <rect
                     id="Rectangle"
                     x="0"
                     y="95"
                     width="96"
                     height="148"
                   ></rect>
                   <line
                     x1="48.5"
                     y1="96"
                     x2="48.5"
                     y2="144"
                     id="Line-2"
                     strokeWidth="3"
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     stroke={load.configured ? "#333" : "#E47F00"}
                   ></line>
                   <circle
                     id="Oval"
                     stroke="#42B4E6"
                     strokeWidth="3"
                     cx="48"
                     cy="193.5"
                     r="55"
                     style={load.selected ? {} : { display: "none" }}
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     fill="rgba(0, 135, 205, 0.2)"
                   ></circle>

                   <circle
                     id="Oval"
                     strokeWidth="3"
                     fill="#FFFFFF"
                     cx="48"
                     cy="193.5"
                     r="46.5"
                     stroke={load.configured ? "#333333" : "#E47F00"}
                   ></circle>

                   <text
                     id="segment_energy_infra"
                     fontFamily="se-icon"
                     fontSize="56"
                     fontWeight="normal"
                     fill="#333333"
                   >
                     <tspan x="20" y="210">
                       segment_building_small
                     </tspan>
                   </text>
                 </g>
               </g>
             </g>
           </g>
         </g>
       </svg>
       <line
         x1={load.x1pos}
         y1={load.y1pos}
         x2={load.x2pos}
         y2={load.y2pos}
         id="Line-2"
         strokeWidth="4"
         strokeDasharray="6 8"
         strokeLinecap="round"
         stroke={load.configured ? "#333333" : "#E47F00"}
       ></line>
     </g>
     <g id="CB50">
       <line
         x1="455"
         y1="463"
         x2="455"
         y2="367"
         id="Line-2"
         strokeWidth="3"
         strokeDasharray="4 8"
         strokeLinecap="round"
         stroke="#333333"
       ></line>
     </g>
   </svg>
   : <svg className="pointer">
   <g id="load1">
     <svg
       x={load.xAxis-290}
       y={load.yAxis- 90}
       width="149px"
       height="475px"
       viewBox="0 0 90 550"
       onClick={loadSelectHandler}
     >
       <title>{loadName}</title>
       <g
         id="Design"
         stroke="none"
         strokeWidth="1"
         fill="none"
         fillRule="evenodd"
       >
         <g
           id="Design/Assets-_-00"
           transform="translate(-604.000000, -372.000000)"
         >
           <g
             id="SLD/Production/Uniline/Undefined"
             transform="translate(604.000000, 372.000000)"
           >
             <g id="SLD/Production/Uniline/Tobedefine">
               <g id="Group-7">
                 <rect
                   id="Rectangle"
                   x="0"
                   y="95"
                   width="96"
                   height="148"
                 ></rect>
                 <line
                   x1="48.5"
                   y1="96"
                   x2="48.5"
                   y2="144"
                   id="Line-2"
                   strokeWidth="3"
                   strokeLinecap="round"
                   strokeLinejoin="round"
                   stroke={load.configured ? "#333" : "#E47F00"}
                 ></line>
                 <circle
                   id="Oval"
                   stroke="#42B4E6"
                   strokeWidth="3"
                   cx="48"
                   cy="193.5"
                   r="55"
                   style={load.selected ? {} : { display: "none" }}
                   strokeLinecap="round"
                   strokeLinejoin="round"
                   fill="rgba(0, 135, 205, 0.2)"
                 ></circle>

                 <circle
                   id="Oval"
                   strokeWidth="3"
                   fill="#FFFFFF"
                   cx="48"
                   cy="193.5"
                   r="46.5"
                   stroke={load.configured ? "#333333" : "#E47F00"}
                 ></circle>

                 <text
                   id="segment_energy_infra"
                   fontFamily="se-icon"
                   fontSize="56"
                   fontWeight="normal"
                   fill="#333333"
                 >
                   <tspan x="20" y="210">
                     segment_building_small
                   </tspan>
                 </text>
               </g>
             </g>
           </g>
         </g>
       </g>
     </svg>
     <line
       x1={load.x1pos}
       y1={load.y1pos}
       x2={load.x2pos}
       y2={load.y2pos}
       id="Line-2"
       strokeWidth="4"
       strokeDasharray="6 8"
       strokeLinecap="round"
       stroke={load.configured ? "#333333" : "#E47F00"}
     ></line>
   </g>
   <g id="CB50">
     <line
       x1="165"
       y1="285"
       x2="165"
       y2="375"
       id="Line-2"
       strokeWidth="3"
       strokeDasharray="4 8"
       strokeLinecap="round"
       stroke="#333333"
     ></line>
   </g>
 </svg>
   }
   </>
</MyErrorBoundary>
  );
};

export default Load;
