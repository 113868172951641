import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

const Busbar = () => {
  const {
    coordinates: {
      busbar: { x2 },
    },
  } = useContext(DesignAssetContext);
  return (
    <svg>
      <g id="busbar">
        <line x1="60" y1="290" x2={x2} y2="290" stroke="#333" strokeWidth="3" />
      </g>
    </svg>
  );
};

export default Busbar;
