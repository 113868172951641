import React, { Component } from 'react';
import APISESSION from '../api/ApiSession';
import mediaPicture from '../images/mediaPicture.png';
import mediaPictureBlank from '../images/mediaPictureBlank.png';
import Resizer from 'react-image-file-resizer';
const apiSession = new APISESSION();

class UserProfile extends Component {
    constructor(props) {        
        super(props);
        
        let userSampleData = {
            name: "",
            role: "",
            access: {}
        }
        this.state = {
            onLoadingStatus: false,
            imagePreviewUrl: null,
            imagePreviewUrlReplica: null,
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            location: "",
            businessType: null,
            city: "",
            state: "",
            zipCode: "",
            employer: "",
            role : "",
            editPage: false,
            userDataReplica: {},
            type: "",
            message: "",
            selectedFile: "",
            userScope: JSON.parse(localStorage.getItem("scope")) !== null ? JSON.parse(localStorage.getItem("scope")) : userSampleData,
            rolePermission : "",
            show: true,
        }
    }
    componentDidMount() {
        try {
            this.setState({ onLoadingStatus: true });
            this.fetchUserDetails();  
        } catch (error) {
            if(error.response !== undefined){
                
            }
        }


    }
    fetchUserDetails() {
        apiSession
          .getUserProfile()
          .then((response) => {
            this.setState({
              firstName: response.firstName,
              lastName: response.lastName,
              email: response.email,
              phoneNumber: response.phoneNumber,
              location: response.location,
              businessType: response.businessType,
              city: response.city,
              state: response.state,
              zipCode: response.zipCode,
              employer: response.employer,
              imagePreviewUrl: response.imageLink,
              imagePreviewUrlReplica: response.imageLink,
              role: response.globalRole,
              userDataReplica: response,
            });
            this.setState({ onLoadingStatus: false });
            if (this.state.userScope.access.getRoles === true) {
              this.getRoles();
            }
          })
          .catch((error) => {
            // if (error.response !== undefined) {
              this.setState({ onLoadingStatus: false });
              if (error?.response?.data) {
                if (error?.response?.data?.message === "Unauthorized") {
                  let { history } = this.props;
                  history.push({
                    pathname: "/",
                  });
                }
              }
            // }
          });

    }

   

    fileChangedHandler = event => {
        this.setState({onLoadingStatus : true});
        if ((event.target.files[0].size) > 1000000) {
            this.callSnackbar("error", "Image size should be less than 1MB");
            this.setState({onLoadingStatus : false});
        }
        else if (event.target.files[0].type !== "image/png" && event.target.files[0].type !== "image/jpg" && event.target.files[0].type !== "image/jpeg" && event.target.files[0].type !== "image/gif" && event.target.files[0].type !== "image/tiff") {
            this.callSnackbar("error", "Invalid file format, Please select .jpg/.jpeg/.png/.tiff/.gif file");
            event.target.value = null;
            this.setState({onLoadingStatus : false});
        }
        else {

            Resizer.imageFileResizer(
                event.target.files[0],
                200,
                200,
                'png',
                100,
                0,
                uri => {
                    this.setState({onLoadingStatus : false});
                    this.setState({
                        imagePreviewUrl: uri
                    });
                },
                'base64'
            );
            this.setState({onLoadingStatus : false});
        }

    }
    callSnackbar = (errorType, Body) => {
        this.setState({
            type: errorType,
            message: Body
        });
        document.getElementById('snackbar-userdetails').open = true;
    }
    enableEdit() {
        this.setState({
            editPage: true
        });
        let replicaUser = Object.assign({}, this.state.userDataReplica);
        replicaUser.firstName = this.state.firstName;
        replicaUser.lastName = this.state.lastName;
        replicaUser.employer = this.state.employer;
        replicaUser.location = this.state.location;
        replicaUser.phoneNumber = this.state.phoneNumber;
        replicaUser.businessType = this.state.businessType;
        replicaUser.city = this.state.city;
        replicaUser.state = this.state.state;
        replicaUser.zipCode = this.state.zipCode;
        this.setState({
            userDataReplica: replicaUser
        });
    }
    cancelEdit() {
        this.setState({
            editPage: false
        });
        this.setState({
            firstName: this.state.userDataReplica.firstName,
            lastName: this.state.userDataReplica.lastName,
            email: this.state.userDataReplica.email,
            phoneNumber: this.state.userDataReplica.phoneNumber,
            location: this.state.userDataReplica.location,
            businessType: this.state.userDataReplica.businessType,
            city: this.state.userDataReplica.city,
            state: this.state.userDataReplica.state,
            zipCode: this.state.userDataReplica.zipCode,
            employer: this.state.userDataReplica.employer,
            imagePreviewUrl: this.state.imagePreviewUrlReplica
        });

    }
    isValidName = value => (/^[a-zA-Z0-9_-\s]*$/.test(value) ? true : false);
    handleChange = (e) => {
        let phoneNumberRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
        switch (e.target.name) {
            case 'phoneNumber':
                if (e.target.value.length >= 2) {
                    if (phoneNumberRegex.test(e.target.value)) {
                        this.setState({
                            [e.target.name]: e.target.value
                        });
                    }

                } else {
                    this.setState({
                        [e.target.name]: e.target.value
                    });
                }

                break;
            default:
                if (this.isValidName(e.target.value)) {
                    this.setState({
                        [e.target.name]: e.target.value.replace(/ +(?= )/g, '')
                    });
                }
        }


    }




    saveUser() {
        this.setState({ onLoadingStatus: true });
        let userdetail = {
            "firstName": this.state.firstName,
            "lastName": this.state.lastName,
            "phoneNumber": this.state.phoneNumber,
            "location": this.state.location,
            "businessType": this.state.businessType,
            "city": this.state.city,
            "state": this.state.state,
            "zipCode": this.state.zipCode,
            "employer": this.state.employer,
            "imageLink": this.state.imagePreviewUrl
        }
        apiSession.saveDetails(userdetail).then(response => {
            this.callSnackbar("success", "Updated Successfully");
            this.setState({
                editPage: false
            });
            this.setState({ onLoadingStatus: false });

        }).catch(error => {
            // if (error.response !== undefined) {
                this.setState({ onLoadingStatus: false });
                if (error?.response?.data) {
                    if (error?.response?.data?.message === "Unauthorized") {
                        let { history } = this.props;
                        history.push({
                            pathname: '/'
                        });

                    }

                // }

            }
            this.callSnackbar("error", "Error in user details updation");


        });


    }
    saveUserDetails() {
        if (this.state.firstName.trim() !== "" && this.state.email.trim() !== "" && this.state.employer.trim() !== "" &&
            this.state.location.trim() !== "") {
            this.saveUser();
        }
        else {
            this.callSnackbar("error", "Please fill madatory fields");
        }
    }

    getRoles(){
        apiSession.getRoles().then(response => {
            this.setState({rolePermission : response.roleDetails[this.state.role]});
        }).catch(error=>{
            if(error.response!==undefined){
                
            }
        })

    }

    removeProfileImage(e){
        this.setState({imagePreviewUrl : null});
    }

    render() {
        return this.state.show ? (
          <>
            <se-container option="centered">
               <se-loading loading={this.state.onLoadingStatus} />
              <div className="container mb-1">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background pt-4 pr-5 pl-5 pb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <span>
                          <h5 className="se-life-green"> My Profile</h5>
                        </span>
                      </div>
                      <div className="col-md-9">
                        {this.state.editPage === false ? (
                          <div className="float-right">
                            <se-button
                              option="raised"
                              color="primary"
                              icon="action_editor"
                              onClick={this.enableEdit.bind(this)}
                            >
                              Edit
                            </se-button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-md-3">
                        <div className="row align-items-center justify-content se-grey-background-center  ">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 fix-center">
                            {this.state.editPage === true ? (
                              <label
                                className="pointer"
                                htmlFor="uploadProfileImage"
                                title="click to upload"
                              >
                                <img
                                  className="img-fluid pointer"
                                  title="click to upload"
                                  src={
                                    this.state.imagePreviewUrl
                                      ? this.state.imagePreviewUrl
                                      : mediaPicture
                                  }
                                  alt="icon"
                                ></img>{" "}
                              </label>
                            ) : (
                              <img
                                className="img-fluid"
                                src={
                                  this.state.imagePreviewUrl
                                    ? this.state.imagePreviewUrl
                                    : mediaPictureBlank
                                }
                                alt="icon"
                              ></img>
                            )}

                            {this.state.editPage === true ? (
                              this.state.imagePreviewUrl ? (
                                <span
                                  className="remove-image"
                                  onClick={(e) => this.removeProfileImage(e)}
                                  style={{ display: "inline" }}
                                >
                                  &#215;
                                </span>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}

                            {this.state.editPage === true ? (
                              <input
                                type="file"
                                id="uploadProfileImage"
                                style={{ display: "none" }}
                                name="image"
                                accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                onChange={this.fileChangedHandler}
                                className="mt-4"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-9">
                        {this.state.editPage === false ? (
                          <div className="row ">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 fix-center">
                              <h4>
                                {this.state.firstName +
                                  " " +
                                  this.state.lastName}
                              </h4>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="row mt-2 ">
                          <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                            Email
                          </div>
                          <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                            {this.state.email}
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                            <se-divider></se-divider>
                          </div>
                        </div>

                        {this.state.editPage === true ? (
                          <div className="row mt-2 ">
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                              First Name <span className="se-mandatory">*</span>
                            </div>
                            <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                              <div className="form-group row">
                                <div className="col-md-12">
                                  <input
                                    type="text"
                                    autoComplete="none"
                                    className="form-control"
                                    id="firstName"
                                    name="firstName"
                                    value={this.state.firstName}
                                    maxLength="50"
                                    placeholder="First Name"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                  <div
                                    className="invalid-feedback-custom"
                                    style={
                                      this.state.firstName === ""
                                        ? {}
                                        : { display: "none" }
                                    }
                                  >
                                    First Name is required.
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                              <se-divider></se-divider>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.editPage === true ? (
                          <div className="row mt-2 ">
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                              Last Name
                            </div>
                            <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                              <div className="form-group row">
                                <div className="col-md-12">
                                  <input
                                    type="text"
                                    autoComplete="none"
                                    className="form-control"
                                    id="lastName"
                                    name="lastName"
                                    value={this.state.lastName}
                                    maxLength="50"
                                    placeholder="Last Name"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                              <se-divider></se-divider>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="row mt-2 ">
                          <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                            Company Name
                            {this.state.editPage === true ? (
                              <span className="se-mandatory">*</span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                            {this.state.editPage === true ? (
                              <div className="form-group row">
                                <div className="col-md-12">
                                  <input
                                    type="text"
                                    autoComplete="none"
                                    className="form-control"
                                    id="employer"
                                    name="employer"
                                    value={this.state.employer}
                                    maxLength="50"
                                    placeholder="Company Name"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                  <div
                                    className="invalid-feedback-custom"
                                    style={
                                      this.state.employer === ""
                                        ? {}
                                        : { display: "none" }
                                    }
                                  >
                                    Company Name is required.
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <span>{this.state.employer}</span>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                            <se-divider></se-divider>
                          </div>
                        </div>

                        <div className="row mt-2 ">
                          <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                            Phone Number
                          </div>
                          <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                            {this.state.editPage === true ? (
                              <div className="form-group row">
                                <div className="col-md-12">
                                  <input
                                    type="text"
                                    autoComplete="none"
                                    className="form-control"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={this.state.phoneNumber}
                                    maxLength="15"
                                    placeholder="Phone Number"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                </div>
                              </div>
                            ) : (
                              <span>{this.state.phoneNumber}</span>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                            <se-divider></se-divider>
                          </div>
                        </div>

                        <div className="row mt-2 ">
                          <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                            Location{" "}
                            {this.state.editPage === true ? (
                              <span className="se-mandatory">*</span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                            {this.state.editPage === true ? (
                              <div className="form-group row">
                                <div className="col-md-12">
                                  <input
                                    type="text"
                                    autoComplete="none"
                                    className="form-control"
                                    id="location"
                                    name="location"
                                    value={this.state.location}
                                    maxLength="50"
                                    placeholder="Location"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                  <div
                                    className="invalid-feedback-custom"
                                    style={
                                      this.state.location === ""
                                        ? {}
                                        : { display: "none" }
                                    }
                                  >
                                    Location is required.
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <span>{this.state.location}</span>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                            <se-divider></se-divider>
                          </div>
                        </div>
                        <div className="row mt-2 ">
                          <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                            City
                          </div>
                          <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                            {this.state.editPage === true ? (
                              <div className="form-group row">
                                <div className="col-md-12">
                                  <input
                                    type="text"
                                    autoComplete="none"
                                    className="form-control"
                                    id="city"
                                    name="city"
                                    value={this.state.city}
                                    maxLength="50"
                                    placeholder="City"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                </div>
                              </div>
                            ) : (
                              <span>{this.state.city}</span>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                            <se-divider></se-divider>
                          </div>
                        </div>
                        <div className="row mt-2 ">
                          <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                            Zipcode
                          </div>
                          <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                            {this.state.editPage === true ? (
                              <div className="form-group row">
                                <div className="col-md-12">
                                  <input
                                    type="text"
                                    autoComplete="none"
                                    className="form-control"
                                    id="zipCode"
                                    name="zipCode"
                                    value={this.state.zipCode}
                                    maxLength="50"
                                    placeholder="Zipcode"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                </div>
                              </div>
                            ) : (
                              <span>{this.state.zipCode}</span>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                            <se-divider></se-divider>
                          </div>
                        </div>
                        <div className="row mt-2 ">
                          <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                            State
                          </div>
                          <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                            {this.state.editPage === true ? (
                              <div className="form-group row">
                                <div className="col-md-12">
                                  <input
                                    type="text"
                                    autoComplete="none"
                                    className="form-control"
                                    id="state"
                                    name="state"
                                    value={this.state.state}
                                    maxLength="50"
                                    placeholder="State"
                                    onChange={(e) => this.handleChange(e)}
                                  />
                                </div>
                              </div>
                            ) : (
                              <span>{this.state.state}</span>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                            <se-divider></se-divider>
                          </div>
                        </div>

                        <div className="row mt-2 ">
                          <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                            Permissions
                          </div>
                          <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                            <span>{this.state.rolePermission}</span>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                            <se-divider></se-divider>
                          </div>
                        </div>

                        {/* <div className="row mt-2 ">
                                        <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                                            Business Type {this.state.editPage === true ?
                                                <span className="se-mandatory">*</span>
                                                : ""}
                                        </div>
                                        <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                                            {this.state.editPage === true ?
                                                <div className="form-group row" style={this.state.editPage === true ? {} : { display: "none" }}>
                                                    <div className="col-md-12">
                                                        <select className={"form-control " + (this.state.businessType === "" ? "is-mandatory" : "")} name="businessType"
                                                            value={this.state.businessType}
                                                            onChange={e => this.handleChange(e)}>
                                                            <option value="">Business Type</option>
                                                            {
                                                                RawBusinessTypeList.businessTypeList.map((businessData, index) => (
                                                                    <option value={businessData} >{businessData}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <div className="invalid-feedback-custom" style={(this.state.businessType === "") ? {} : { display: "none" }}>
                                                            Business Type is required.
                                                            </div>
                                                    </div>
                                                </div>
                                                :
                                                <span style={this.state.editPage === false ? {} : { display: "none" }}>{this.state.businessType}</span>
                                            }
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                            <se-divider></se-divider>
                                        </div>

                                    </div> */}
                        {this.state.editPage === true ? (
                          <div className="row mt-2 ">
                            <div className="col-md-12 text-right">
                              <se-button
                                option="outline"
                                onClick={this.cancelEdit.bind(this)}
                              >
                                Cancel
                              </se-button>
                              <se-button
                                option="raised"
                                color="primary"
                                onClick={this.saveUserDetails.bind(this)}
                              >
                                Save
                              </se-button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <se-snackbar
                id="snackbar-userdetails"
                type={this.state.type}
                icon="information_stroke"
                message={this.state.message}
                duration="3000"
              ></se-snackbar>
            </se-container>
          </>
        ) : (
          <></>
        );
    }
}

export default UserProfile;