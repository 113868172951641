import React, { useContext } from "react";

import sld from "../../images/sld/Rectangle.svg";

import { DesignAssetContext } from "../CustomHooks/ContextApi";
import Ecc from "../../components/PvGensetAts/Ecc";
import Busbar from "../../components/ReusableSvg/Busbar";
import Linebar from "../../components/PvGensetAts/Linebar";
import Utility from "../../components/PvGensetAts/Utility";
import Genset from "../../components/PvGensetAts/Genset";
import PV1 from "../../components/PvGensetAts/PV1";
import Load from "../../components/ReusableSvg/Load";



const PvGensetAtsSLD = () => {
  const {sldLoadCompleted } = useContext(DesignAssetContext);

  return (
    <div
      id="SLD-Image"
      className="col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0"
    >
        <svg viewBox="0 0 1300 800">
          <g>
            <svg x="0" y="0" viewBox="0 0 1300 800">
            <g id="grid">
              <svg>
                <image href={sld} x="0" y="0" />
              </svg>
            </g>

            <g
              id="components-sld"
              style={sldLoadCompleted ? {} : { display: "none" }}
            >
              <Ecc />
              <Busbar secondBusbar={true} />
              <Linebar />
              <Utility />
              <Genset />
               <PV1 />
              <Load />
            </g>
          </svg>
        </g>
      </svg>
    </div>
  );
};

export default PvGensetAtsSLD;
