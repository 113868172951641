import React, { useState } from 'react'
import { useEffect } from 'react'
import ApiSession from '../api/ApiSession';

const CheckApiHealth = () => {
    const [status, setStatus] = useState();
    const apiSession = new ApiSession();
    useEffect(()=>{
        apiSession.getHealthStatus()
        .then(reps=>{
            setStatus(reps.status)
        })
        .catch()
    },[])
  return (
    <div>{status}</div>
  )
}

export default CheckApiHealth