import React from "react";
import isTouchDevice from "../SE-Component/Tooltip";
import plcKeyImage from "../../images/se-plc.png";
// import plcKeyImage from "../../images/SN_M251.jpg";
import Tooltip from "../../utils/Tooltip";

const serialNoRegex = /^[a-zA-Z0-9]{8}[0-9]{5}$/;

const EmoController = ({
  emoController,
  emoSerialNumber,
  handleSerialNo,
  handleSerialNoPopUpClose,
  handleSerialNoSave,
}) => {
  return (
    <se-dialog
      id="dialog-complex"
      size="medium"
      open={emoController.plcKeyPopUp}
      can-backdrop="false"
    >
      <se-dialog-header>EMO-M controller protection</se-dialog-header>
      <se-dialog-content>
        <div className="col-md-12 se-white-background">
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-6 col-form-label">
              Controller serial number (SN){" "}
              <span className="se-mandatory">* </span>
              <Tooltip 
                    position ="right"                   
                    iconColor ="secondary"
                    iconSize = "small"                   
                    information =" Enter 13 alphanumeric characters for serial number. Refer image below"
                    />
             
            </label>
            <div className="col-sm-6">
              <input
                type="text"
                autoComplete="none"
                className="form-control form-control-md"
                name="serialNo"
                placeholder="Please enter controller serial number (SN)"
                maxlength="13"
                disabled={emoSerialNumber || false}
                value={emoSerialNumber || emoController.serialNo}
                onChange={handleSerialNo}
              />
              {emoController.serialNo === "" &&
                emoController.KeyErrorStatusSerialNo && (
                  <div className="invalid-feedback-custom">
                    Controller serial number (SN) is required .
                  </div>
                )}
              {emoController.serialNo !== "" &&
                emoController.serialNo.length !== 13 && (
                  <div className="invalid-feedback-custom">
                    Invalid controller serial number. Maximum 13 characters and
                    last 5 characters should always be a decimal value 0-9
                  </div>
                )}
              {!serialNoRegex.test(emoController.serialNo) &&
                emoController.serialNo.length === 13 && (
                  <div className="invalid-feedback-custom">
                    Invalid controller serial number. Maximum 13 characters and
                    last 5 characters should always be a decimal value 0-9
                  </div>
                )}
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <label>Reference Image:</label> 
            </div>
            <div className="col-md-12 text-center">
              <img
                src={plcKeyImage}
                className="img-fluid height-250px"
                title="Serial key "
                alt="Serial key "
                height="400"
                
              ></img>
            </div>
          </div>
        </div>
      </se-dialog-content>
      <se-dialog-footer>
        <se-button onClick={handleSerialNoPopUpClose}>Cancel</se-button>
        <se-button onClick={handleSerialNoSave}>OK</se-button>
      </se-dialog-footer>
    </se-dialog>
  );
};

export default EmoController;
