import React, { useContext } from "react";
import { DesignProgramContext } from "../CustomHooks/ContextApi";
import isTouchDevice from "../SE-Component/Tooltip";
import Tooltip from "../../utils/Tooltip";
import { useSelector } from "react-redux";

const BessChargingLimitation = () => {
  const {
    BESSChargingEnabled,
    BESSCharging,
    handleRadioButtonChange,
    openBessChargingpopup,
    configuredStatusModified,
    handleCheckBoxChange,
  } = useContext(DesignProgramContext);
  const tooltipData = useSelector(state => state?.tooltipData?.tooltipData);
  const BessChargingLimitationChangeHandlerYes = () => {
    openBessChargingpopup();
    configuredStatusModified("bclConfiguredStatus");
  };
  const BessChargingLimitationChangeHandlerNo = e => {
    handleRadioButtonChange(e);
    configuredStatusModified("bclConfiguredStatus");
  };

  const BESSChargingCheckBoxChangeHandler = e => {
    handleCheckBoxChange(e);
    configuredStatusModified("bclConfiguredStatus");
  };

  return (
    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-1">
      <div className="dropdown-divider"></div>
      <div className="row">
        <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 mb-2 ">
          <span className="bold">
            Do you want to activate BESS charging limitation ?
          </span>
          <Tooltip
            position="right"
            iconColor="secondary"
            information="Target of this function is to prevent the BESS to be charged at a
            power that is higher than its maximum charging power. This
            parameter is automatically read from BESS through communication."
          />
        </div>
        <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 mb-2 ">
          <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="customRadioG11"
              name="BESSChargingEnabled"
              className="custom-control-input islandableRadio asCompatible"
              value="Y"
              checked={BESSChargingEnabled === "Y"}
              onChange={BessChargingLimitationChangeHandlerYes}
            />
            <label
              className="custom-control-label line-height-25px"
              htmlFor="customRadioG11"
            >
              Yes
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="customRadioG22"
              name="BESSChargingEnabled"
              className="custom-control-input islandableRadio asCompatible"
              value="N"
              checked={BESSChargingEnabled === "N"}
              onChange={BessChargingLimitationChangeHandlerNo}
            />
            <label
              className="custom-control-label line-height-25px"
              htmlFor="customRadioG22"
            >
              No
            </label>
          </div>
        </div>
      </div>

      {BESSChargingEnabled === "Y" && (
        <div className="row">
          <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input asCompatible"
                name="BESS_PV_limitation_enable"
                id="customControlValidation21"
                checked={BESSCharging.BESS_PV_limitation_enable}
                onChange={BESSChargingCheckBoxChangeHandler}
              />
              <label
                className="custom-control-label line-height-25"
                htmlFor="customControlValidation21"
              >
                PV curtailment
              </label>
              <Tooltip
                position="right"
                iconColor="secondary"
                information={tooltipData?.pVCurtailment?.data}
              />
            </div>
          </div>

          <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input asCompatible"
                name="BESS_PV_disconnection"
                id="customControlValidation3"
                checked={BESSCharging.BESS_PV_disconnection}
                onChange={BESSChargingCheckBoxChangeHandler}
              />
              <label
                className="custom-control-label line-height-25"
                htmlFor="customControlValidation3"
              >
                PV breakers opening
              </label>
              <Tooltip
                position="right"
                iconColor="secondary"
                information={tooltipData?.pVBreakersOpening?.data}
              />
             
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BessChargingLimitation;
