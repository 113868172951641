import React, { Component } from 'react';
import APISESSION from '../api/ApiSession';
const apiSession = new APISESSION();


class CancleInvitation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId : '',
            onLoadingStatus : true,
            cancelInvitationSuccess:false,
            cancelInvitationFailure : false
        }
    }
    componentDidMount() {
        this.setState({ onLoadingStatus: true });
        let querySearch = this.props.location.search;
        let query = querySearch.split("?userId=");
        let token = query[1];
        this.setState({userId : query[1]});
        this.cancelInvitation(token);
    }
    
    
    
   cancelInvitation(token){
        apiSession.cancelInvitation(token).then(response=>{
            this.setState({cancelInvitationSuccess : true});
            this.setState({ onLoadingStatus: false });
        }).catch(error=>{
            if (error.response !== undefined) {
            this.setState({cancelInvitationFailure : true});
            this.setState({ onLoadingStatus: false });
            }

        })


    }

    render() {
        return (
            <se-container option="centered" style={this.state.userId !== "" ? {} : { display: 'none' }}>
                <se-loading loading={this.state.onLoadingStatus} />
                <div className="container mb-1" >

                    <div className="row" style={ this.state.cancelInvitationFailure === true? {} : { display: 'none' }}>
                        <div className="col-md-12 col-sm-12 col-lg-12 se-white-background pr-5 pl-5 mt-5 text-center error-msg">
                            <se-block-content>
                                <h4><se-icon size="small">notification_critical_stroke</se-icon> Invitation Link expired or Error in Invitation. Please contact administrator </h4>
                                
                            </se-block-content>
                        </div>


                    </div>

                    

                    <div className="row" style={this.state.cancelInvitationSuccess === true? {} : { display: 'none' }}>
                        <div className="col-md-12 col-sm-12 col-lg-12 se-white-background pr-5 pl-5 mt-5 text-center succedd-msg">
                            <se-block-content>
                                <h4><se-icon color="primary" size="small">notification_critical_stroke</se-icon> Invitation cancelled successfull.</h4>
                                
                                
                            </se-block-content>
                        </div>


                    </div>
                    
                </div>
            </se-container>

        )
    }
}

export default CancleInvitation;