import React, { useContext } from "react";

import { AssetBlockContext } from "../CustomHooks/ContextApi";

import PowerMeterDetails from "./PowerMeterDetails";
import CBConfigDetails from "./CBConfigDetails";

import cbImage from "../../images/CB.png";
import powerMeter from "../../images/power-meter-image.png";

import embLabels from "../../config/emb-labels-en.json";

import isTouchDevice from "../SE-Component/Tooltip";
import Tooltip from "../../utils/Tooltip";

const ExistingPmDetailsGrid = ({ detailsBlock }) => {
  const { CBAssetList, CBEVList, selectedValues, setAssetSelection ,removeExistingPm} =
    useContext(AssetBlockContext);

  const isAssetSelected = () => {
    let isAsset;
    let assetInfo = "";
    let assetModel = "";
    if (
      selectedValues.selectedAssetPM !== "" ||
      selectedValues.selectedAssetCB !== ""
    ) {
      isAsset = CBAssetList[selectedValues.selectedAssetPM]?.existingPowerMeterData?.pmAsset
          ?.assetId
       

      assetInfo =  CBAssetList[selectedValues.selectedAssetPM]?.existingPowerMeterData?.pmAsset
          ?.assetInfo
      

      assetModel = CBAssetList[selectedValues.selectedAssetPM]?.existingPowerMeterData?.pmAsset
          ?.communicationModel
       
    }
   

    return [isAsset, assetInfo, assetModel];
  };

  const setAssetHandler = () => {
 
      setAssetSelection(embLabels.POWER_METER);
    
    
  };

  const [isAsset, assetInfo, assetModel] = isAssetSelected();
  
  return (
    <div name="CB" className="col-md-12 p-0">
      <div className="col-md-12 pt-2 se-white-background my-2">
        <div className="row">
          <div className="col-md-8">
            <img
              src={powerMeter}
              className="asset-image-icon"
              alt={"Power Meter"}
            />

            <span className="align-top line-height-30px bold">Power Meter</span>
          </div>
          <span className="float-right">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={removeExistingPm}
            >
              Remove
            </button>
          </span>
        </div>
      </div>
      <div className="col-md-12 col-sm-12 pt-2 se-grey-background se-border">
        <div className="row" style={!isAsset ? { display: "none" } : {}}>
          <div className="col-md-3">
            <img
              src={powerMeter}
              className="asset-image-display"
              alt={"Power Meter"}
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-12 bold">
                {
                  CBAssetList[selectedValues.selectedAssetPM]
                    ?.existingPowerMeterData?.pmAsset.manufacturer
                }
              </div>
            </div>
            <div className="row">
              <div className="col-md-9">Power Meter</div>
              <div className="col-md-3 text-right">
                <Tooltip
                  position="left"
                  iconColor="secondary"
                  information={assetInfo}
                />
              
              </div>
              <div className="col-md-12"> {assetModel}</div>
            </div>
          </div>
        </div>
        <div className="row" style={isAsset ? { display: "none" } : {}}>
          <div className="ml-2 mb-2 asset-selection"> No assets selected</div>
        </div>
        <div className="row">
          <div className=" col-md col-sm col-lg text-center">
            <se-button option="outline" size="small" onClick={setAssetHandler}>
              Asset Selection
            </se-button>
          </div>
        </div>
      </div>
      {isAsset && detailsBlock.existingPmAssetDetailsBlockGrid && (
        <PowerMeterDetails />
      )}
    </div>
  );
};

export default ExistingPmDetailsGrid;
