import React, { useContext, useEffect, useState } from "react";

import Accordion from "../../components/Accordions/Accordion";
import CalendarTariff from "mg-calendar-tariff/dist";
import { DesignProgramContext } from "../CustomHooks/ContextApi";

import embLabels from "../../config/emb-labels-en.json";
import isTouchDevice from "../SE-Component/Tooltip";
import LABEL_VALIDATIONS from "../../config/LABEL_VALIDATIONS.json";
import LABELS from "../../config/LABELS.json";
import moment from "moment";
import _ from "lodash";
import Tooltip from "../../utils/Tooltip";
import { useSelector } from "react-redux";

const OnGridOptimization = () => {
  const {
    clientType,
    gridSettings,
    configuredStatus,
    demandChargeOutput,
    siteType,
    maxExportPower,
    maxLimitEnergyExportPower,
    maxLimitEnergyImportPower,
    importStartHysteresis,
    importStopHysteresis,
    batteryDetails,
    pvCurtailmentExport,
    lossOfComm,
    lossOfCommEMA,
    utilityLSIEnable,
    templateSelected,
    architectureType,
    demandChargeDataCallback,
    tariffDynamicConfiguration,
    handleDynamicTariffConfiguration,
    exportManagementChange,
    configuredStatusModified,
    handleChange,
    handleCheckBoxChange,
    handlepPVOpeningPopup,
    enableTemplatePopUp,
    isBessOperatingMode,
    exportManagementBessMode,
    refresh,
    validateProgram,
    validResultFunc,
    siteCreated,
    projectStatusData,
    UserScope,
    sellTariff,
    deltaSellTariff,
    handleLimitExportImportOptimization,
    limitExport,
    limitImport,
    currency
  } = useContext(DesignProgramContext);
 
  const tooltipData = useSelector((state) => state?.tooltipData?.tooltipData);

  const exportManagementChangeHandler = () => {
    exportManagementChange();
    configuredStatusModified("emConfiguredStatus");
  };

  const maxExportImportPowerChangeHandler = (e) => {
    handleChange(e);
    configuredStatusModified("emConfiguredStatus");
  };

  const exportFunctionChangeHandler = (e) => {
    handleCheckBoxChange(e);
    configuredStatusModified("emConfiguredStatus");
  };

  const utilityLSIChangeHandler = (e) => {
    configuredStatusModified("emConfiguredStatus");
    handlepPVOpeningPopup(e);
  };

  const hysteresisChangeHandler = (e) => {
    handleChange(e);
    configuredStatusModified("realTimeControlEMA");
  };

  return (
    <>
      {(gridSettings.noExport === true ||
        gridSettings.exportOptimization === true ||
        gridSettings.tariffManagement === true ||
        gridSettings.selfConsumption === true ||
        gridSettings.demandCharge === true ||
        gridSettings.manualDemandResponse === true ) && (
        <div
          className={`row se-white-background pb-2 ${
            siteCreated || projectStatusData?.projectStatus === "COMPLETED"
              ? "se-block-disabled"
              : !UserScope.access.saveProgram
              ? "se-block-disabled"
              : ""
          }`}
        >
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-2 ">
            <span className="se-font-14px-bold se-life-green ">
              Energy Optimization
            </span>

            <div className="row ">
              {gridSettings.noExport === false &&
              gridSettings.exportOptimization === false &&
              gridSettings.tariffManagement === false &&
              gridSettings.selfConsumption === false &&
              gridSettings.demandCharge === false &&
              gridSettings.manualDemandResponse === false &&
              gridSettings.noImport === false ? (
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="row">
                    <label className="col-md-7 col-form-label pl-5">
                      No on-grid optimization usecase
                    </label>
                  </div>
                </div>
              ) : (
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                 
                  <Accordion allowMultipleOpen>
                    <div
                      className="col-md-12"
                      label="Demand Charge and Time of Use Tariff "
                      status={
                        configuredStatus.dcConfiguredStatus === 1 ||
                        configuredStatus.dynamicConfiguredStatus === 1 ||
                        configuredStatus.sellTariffConfiguredStatus === 1
                          ? 1
                          : configuredStatus.dcConfiguredStatus === 2 ||
                            configuredStatus.dynamicConfiguredStatus === 2 ||
                            configuredStatus.sellTariffConfiguredStatus === 2
                          ? 2
                          : 0
                      }
                      displayStatus={
                        gridSettings.demandCharge === true ||
                        gridSettings.tariffManagement === true
                          ? "1"
                          : "0"
                      }
                      icon="notification_error_full_stroke"
                      enableTemplatePopUp={enableTemplatePopUp}
                    >
                      <div className="row p-3">
                        <Accordion allowMultipleOpen>
                          <div
                            className="col-md-12"
                            label=" Static Tariff Configuration"
                            status={configuredStatus.dcConfiguredStatus}
                            displayStatus="1"
                            icon="notification_error_full_stroke"
                            enableTemplatePopUp={enableTemplatePopUp}
                          >
                            <CalendarTariff
                              lastSubmit={refresh}
                              validResult={validResultFunc}
                              demandChargeData={demandChargeOutput}
                              currency={currency}
                              draftCallback={demandChargeDataCallback}
                              demandChargeUseCase={gridSettings.demandCharge}
                              timeOfUseCase={gridSettings.tariffManagement}
                              // country={localStorage.getItem("userCountry")}
                              customLabels={LABELS}
                              weekdayLabels={_.map(
                                [1, 2, 3, 4, 5, 6, 0],
                                (day) => moment.weekdays()[day]
                              )}
                              templateSel={templateSelected}
                              VALIDATION_LABELS={LABEL_VALIDATIONS}
                            ></CalendarTariff>
                          </div>
                          <div
                            className="col-md-12 mt-2"
                            label=" Dynamic Tariff Configuration"
                            status={configuredStatus.dynamicConfiguredStatus}
                            displayStatus="1"
                            icon="notification_error_full_stroke"
                            enableTemplatePopUp={enableTemplatePopUp}
                          >
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                              <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1 ">
                                  <div className="row pl-3">
                                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                      <div className="custom-control custom-radio">
                                        <input
                                          type="radio"
                                          id="dayTariff24hHours"
                                          name="tariffType"
                                          className="custom-control-input islandableRadio  asCompatible"
                                          checked={
                                            tariffDynamicConfiguration === null
                                          }
                                          onClick={
                                            handleDynamicTariffConfiguration
                                          }
                                          value="null"
                                        />
                                        <label
                                          className="custom-control-label line-height-25px"
                                          htmlFor="dayTariff24hHours"
                                        >
                                          None
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                      <div className="custom-control custom-radio">
                                        <input
                                          type="radio"
                                          id="dayTariff24hHours"
                                          name="tariffType"
                                          className="custom-control-input islandableRadio  asCompatible"
                                          checked={
                                            tariffDynamicConfiguration?.tariffType ===
                                            "dynamic"
                                          }
                                          onClick={
                                            handleDynamicTariffConfiguration
                                          }
                                          value="dynamic"
                                        />
                                        <label
                                          className="custom-control-label line-height-25px"
                                          htmlFor="dayTariff24hHours"
                                        >
                                          24 hour day ahead Tariff
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                      <div className="custom-control custom-radio">
                                        <input
                                          type="radio"
                                          id="spotPrice"
                                          name="tariffType"
                                          className="custom-control-input islandableRadio  asCompatible"
                                          checked={
                                            tariffDynamicConfiguration?.tariffType ===
                                            "spotPrice"
                                          }
                                          onClick={
                                            handleDynamicTariffConfiguration
                                          }
                                          value="spotPrice"
                                        />
                                        <label
                                          className="custom-control-label line-height-25px"
                                          htmlFor="omieSpain"
                                        >
                                          Spot Price
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {tariffDynamicConfiguration?.tariffType ===
                                  "dynamic" && (
                                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1 mt-2">
                                    <div className="row pl-3">
                                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <span className="bold ">
                                          Platform :
                                        </span>
                                      </div>
                                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div className="row pl-4">
                                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <div className="custom-control custom-radio">
                                              <input
                                                type="radio"
                                                id="omieSpain"
                                                name="platformDayAhead"
                                                className="custom-control-input islandableRadio  asCompatible"
                                                checked={
                                                  tariffDynamicConfiguration
                                                    ?.dynamicHourTariff
                                                    ?.platformDayAhead ===
                                                  "spain"
                                                }
                                                onClick={
                                                  handleDynamicTariffConfiguration
                                                }
                                                value="spain"
                                              />
                                              <label
                                                className="custom-control-label line-height-25px"
                                                htmlFor="omieSpain"
                                              >
                                                OMIE Spain
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <div className="custom-control custom-radio">
                                              <input
                                                type="radio"
                                                id="portugal"
                                                name="platformDayAhead"
                                                className="custom-control-input islandableRadio  asCompatible"
                                                checked={
                                                  tariffDynamicConfiguration
                                                    ?.dynamicHourTariff
                                                    ?.platformDayAhead ===
                                                  "portugal"
                                                }
                                                onClick={
                                                  handleDynamicTariffConfiguration
                                                }
                                                value="portugal"
                                              />
                                              <label
                                                className="custom-control-label line-height-25px"
                                                htmlFor="portugal"
                                              >
                                                OMIE Portugal
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {tariffDynamicConfiguration?.tariffType ===
                                  "spotPrice" && (
                                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1 mt-2">
                                    <div className="row pl-3">
                                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <span className="bold">
                                          Platform :{" "}
                                        </span>
                                      </div>
                                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div className="row pl-3">
                                          <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                            <div className="custom-control custom-radio">
                                              <input
                                                type="radio"
                                                id="AEMO-Australia"
                                                name="platformSpotPrice"
                                                className="custom-control-input islandableRadio  asCompatible"
                                                checked={
                                                  tariffDynamicConfiguration
                                                    ?.spotPriceTariff
                                                    ?.platformSpotPrice ===
                                                  "aemo"
                                                }
                                                onClick={
                                                  handleDynamicTariffConfiguration
                                                }
                                                value="aemo"
                                              />
                                              <label
                                                className="custom-control-label line-height-25px"
                                                htmlFor="AEMO-Australia"
                                              >
                                                AEMO-Australia
                                              </label>
                                            </div>
                                          </div>
                                          {tariffDynamicConfiguration
                                            ?.spotPriceTariff
                                            ?.platformSpotPrice === "aemo" && (
                                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1">
                                              <div className="form-group row ">
                                                <label
                                                  htmlFor="scale"
                                                  className="col-sm-2 col-md-2 col-form-label text-right "
                                                >
                                                  Scale
                                                </label>

                                                <div className="col-sm-3">
                                                  <input
                                                    type="text"
                                                    autoComplete="none"
                                                    className="form-control form-control-sm"
                                                    name="scale"
                                                    placeholder="Enter Scale"
                                                    maxLength="50"
                                                    value={
                                                      tariffDynamicConfiguration
                                                        ?.spotPriceTariff?.scale
                                                    }
                                                    onChange={
                                                      handleDynamicTariffConfiguration
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="form-group row ">
                                                <label
                                                  htmlFor="region"
                                                  className="col-sm-2 col-md-2 col-form-label text-right "
                                                >
                                                  Region
                                                </label>

                                                <div className="col-sm-3">
                                                  <select
                                                    className="col-sm-12 col-lg-12 col-md-12 col-xl-12  form-control form-control-sm"
                                                    name="region"
                                                    id="region"
                                                    value={
                                                      tariffDynamicConfiguration
                                                        ?.spotPriceTariff
                                                        ?.region
                                                    }
                                                    onChange={
                                                      handleDynamicTariffConfiguration
                                                    }
                                                  >
                                                    {[
                                                      {
                                                        value: "vic1",
                                                        title: "Victoria",
                                                      },
                                                      {
                                                        value: "nsw1",
                                                        title:
                                                          "New South Wales",
                                                      },
                                                      {
                                                        value: "tas1",
                                                        title: "Tasmania",
                                                      },
                                                      {
                                                        value: "sa1",
                                                        title:
                                                          "South Australia",
                                                      },
                                                      {
                                                        value: "qld1",
                                                        title: "Queensland",
                                                      },
                                                    ].map((region) => (
                                                      <option
                                                        key={region.value}
                                                        value={region.value}
                                                      >
                                                        {region.title}
                                                      </option>
                                                    ))}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-md-12 mt-2"
                            label=" Sell Tariff Configuration"
                            status={configuredStatus.sellTariffConfiguration}
                            displayStatus="1"
                            icon="notification_error_full_stroke"
                            enableTemplatePopUp={enableTemplatePopUp}
                          >
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                              <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1 ">
                                  <div className="row pl-3 mt-1">
                                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                      <div className="custom-control custom-radio">
                                        <input
                                          type="radio"
                                          id="sellTariff1"
                                          name="sellTariff"
                                          className="custom-control-input islandableRadio  asCompatible"
                                          checked={sellTariff === "at0"}
                                          onClick={
                                            handleDynamicTariffConfiguration
                                          }
                                          value="at0"
                                        />
                                        <label
                                          className="custom-control-label line-height-25px"
                                          htmlFor="sellTariff1"
                                        >
                                          Selling price equals zero
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                      <div className="custom-control custom-radio">
                                        <input
                                          type="radio"
                                          id="sellTariff2"
                                          name="sellTariff"
                                          className="custom-control-input islandableRadio  asCompatible"
                                          checked={sellTariff === "likeBuy"}
                                          onClick={
                                            handleDynamicTariffConfiguration
                                          }
                                          value="likeBuy"
                                        />
                                        <label
                                          className="custom-control-label line-height-25px"
                                          htmlFor="sellTariff2"
                                        >
                                          Selling price equals purchase price
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  {sellTariff === "likeBuy" && (
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1 ">
                                      <div className="form-group row pl-3 mt-3">
                                        <label
                                          htmlFor="deltaSellTariff"
                                          className="col-sm-2 m-0 pt-2"
                                        >
                                          Delta Sell Tariff
                                        </label>
                                        <div className="col-sm-3">
                                          <input
                                            type="text"
                                            autoComplete="none"
                                            className="form-control form-control-sm"
                                            name="deltaSellTariff"
                                            placeholder="Enter delta sell tariff"
                                            maxLength="5"
                                            value={deltaSellTariff}
                                            onChange={
                                              handleDynamicTariffConfiguration
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion>
                      </div>
                    </div>

                    {[
                      embLabels.GRID_CONNECTED_GSX,
                      embLabels.ISLANDABLE_GSX,
                      embLabels.GRID_CONNECTED,
                      embLabels.ISLANDABLE,
                    ].includes(siteType) &&
                      gridSettings?.noExport === true && (
                        <div
                          className="col-md-12"
                          label="BESS Operating Modes"
                          status={configuredStatus.bessOperatingModes}
                          displayStatus={
                            [
                              "UNRESTRICTED",
                              "EXPORT_ONLY",
                              "IMPORT_ONLY",
                              "NO_EXCHANGE",
                            ]?.includes(isBessOperatingMode)
                              ? "1"
                              : "0"
                          }
                          icon="notification_error_full_stroke"
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-1">
                            <div className="row">
                              <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-2 ">
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="customRadioDegraded1"
                                    name="bessMode"
                                    className="custom-control-input islandableRadio  asCompatible"
                                    checked={
                                      isBessOperatingMode === "UNRESTRICTED"
                                    }
                                    onClick={exportManagementBessMode}
                                    value="UNRESTRICTED"
                                  />
                                  <label
                                    className="custom-control-label line-height-25px"
                                    htmlFor="customRadioDegraded1"
                                  >
                                    UNRESTRICTED
                                  </label>
                                  <se-tooltip
                                    action={
                                      true
                                        ? isTouchDevice()
                                          ? "click"
                                          : "hover"
                                        : ""
                                    }
                                    position="right"
                                  >
                                    <se-icon
                                      option="raised"
                                      color="secondary"
                                      slot="tooltip"
                                    >
                                      information_circle
                                    </se-icon>
                                    <se-tooltip-content>
                                      "BESS can charge from grid and PV. BESS
                                      can discharge to grid"
                                    </se-tooltip-content>
                                  </se-tooltip>
                                </div>
                              </div>

                              <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-2 ">
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="customRadioDegraded2"
                                    name="bessMode"
                                    className="custom-control-input islandableRadio  asCompatible"
                                    checked={
                                      isBessOperatingMode === "EXPORT_ONLY"
                                    }
                                    onClick={exportManagementBessMode}
                                    value="EXPORT_ONLY"
                                  />
                                  <label
                                    className="custom-control-label line-height-25px"
                                    htmlFor="customRadioDegraded2"
                                  >
                                    EXPORT ONLY
                                  </label>
                                  <se-tooltip
                                    action={
                                      true
                                        ? isTouchDevice()
                                          ? "click"
                                          : "hover"
                                        : ""
                                    }
                                    position="right"
                                  >
                                    <se-icon
                                      option="raised"
                                      color="secondary"
                                      slot="tooltip"
                                    >
                                      information_circle
                                    </se-icon>
                                    <se-tooltip-content>
                                      "BESS cannot charge from grid. It can only
                                      charge from PV production. BESS can
                                      discharge to grid"
                                    </se-tooltip-content>
                                  </se-tooltip>
                                </div>
                              </div>

                              <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-2 ">
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="customRadioDegraded3"
                                    className="custom-control-input islandableRadio  asCompatible"
                                    name="bessMode"
                                    checked={
                                      isBessOperatingMode === "IMPORT_ONLY"
                                    }
                                    onClick={exportManagementBessMode}
                                    value="IMPORT_ONLY"
                                  />
                                  <label
                                    className="custom-control-label line-height-25px"
                                    htmlFor="customRadioDegraded3"
                                  >
                                    IMPORT ONLY
                                  </label>
                                  <se-tooltip
                                    action={
                                      true
                                        ? isTouchDevice()
                                          ? "click"
                                          : "hover"
                                        : ""
                                    }
                                    position="right"
                                  >
                                    <se-icon
                                      option="raised"
                                      color="secondary"
                                      slot="tooltip"
                                    >
                                      information_circle
                                    </se-icon>
                                    <se-tooltip-content>
                                      "BESS can charge from grid and PV. BESS
                                      cannot discharge to grid"
                                    </se-tooltip-content>
                                  </se-tooltip>
                                </div>
                              </div>

                              <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-2 ">
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="customRadioDegraded4"
                                    name="bessMode"
                                    className="custom-control-input islandableRadio  asCompatible"
                                    checked={
                                      isBessOperatingMode === "NO_EXCHANGE"
                                    }
                                    onClick={exportManagementBessMode}
                                    value="NO_EXCHANGE"
                                  />
                                  <label
                                    className="custom-control-label line-height-25px"
                                    htmlFor="customRadioDegraded4"
                                  >
                                    NO EXCHANGE
                                  </label>
                                  <se-tooltip
                                    action={
                                      true
                                        ? isTouchDevice()
                                          ? "click"
                                          : "hover"
                                        : ""
                                    }
                                    position="right"
                                  >
                                    <se-icon
                                      option="raised"
                                      color="secondary"
                                      slot="tooltip"
                                    >
                                      information_circle
                                    </se-icon>
                                    <se-tooltip-content>
                                      "BESS cannot charge from grid. It can only
                                      charge from PV production. BESS cannot
                                      discharge to grid"
                                    </se-tooltip-content>
                                  </se-tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    {/* Limit Energy Import/Export (Based on EMA) */}
                    {gridSettings?.exportOptimization === true && (
                      <div
                        className="col-md-12"
                        label="Limit Energy Import/Export (Based on EMA)"
                        status={configuredStatus.emConfiguredStatus}
                        displayStatus="1"
                        icon="notification_error_full_stroke"
                      >
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-1">
                          <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                              <div className="row">
                               <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input asCompatible"
                                      name="limitEnergyImport"
                                      id="limitEnergyImport"
                                      value={limitImport}
                                      checked={limitImport}
                                      onChange={
                                        handleLimitExportImportOptimization
                                      }
                                    />
                                    <label
                                      className="custom-control-label line-height-25"
                                      htmlFor="limitEnergyImport"
                                    >
                                      IMPORT ONLY
                                    </label>
                                    <se-tooltip
                                    action={
                                      true
                                        ? isTouchDevice()
                                          ? "click"
                                          : "hover"
                                        : ""
                                    }
                                    position="right"
                                  >
                                    <se-icon
                                      option="raised"
                                      color="secondary"
                                      slot="tooltip"
                                    >
                                      information_circle
                                    </se-icon>
                                    <se-tooltip-content>
                                    IMPORT ONLY
                                    </se-tooltip-content>
                                  </se-tooltip>
                                  </div>
                                </div>
                                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input asCompatible"
                                      name="limitEnergyExport"
                                      id="limitEnergyExport"
                                      value={limitExport}
                                      checked={limitExport}
                                      onChange={
                                        handleLimitExportImportOptimization
                                      }
                                    />
                                    <label
                                      className="custom-control-label line-height-25"
                                      htmlFor="limitEnergyExport"
                                    >
                                      EXPORT ONLY
                                    </label>
                                    <se-tooltip
                                    action={
                                      true
                                        ? isTouchDevice()
                                          ? "click"
                                          : "hover"
                                        : ""
                                    }
                                    position="right"
                                  >
                                    <se-icon
                                      option="raised"
                                      color="secondary"
                                      slot="tooltip"
                                    >
                                      information_circle
                                    </se-icon>
                                    <se-tooltip-content>
                                    EXPORT ONLY
                                    </se-tooltip-content>
                                  </se-tooltip>
                                  </div>
                                </div>
                              </div>
                            </div>
                           { ((limitImport === true && limitExport === true) || limitExport === true) && 
                           <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                              <div className="form-group row">
                                <label
                                  htmlFor="inputEmail111"
                                  className="col-sm-3 col-form-label"
                                >
                                  Targeted Max Export Power (kW)
                                  <span className="se-mandatory">*</span>
                                  <span className="se-block-enabled">
                                    <se-tooltip
                                      action={
                                        isTouchDevice() ? "click" : "hover"
                                      }
                                      position="right"
                                    >
                                      <se-icon
                                        option="raised"
                                        color="secondary"
                                        slot="tooltip"
                                      >
                                        information_circle
                                      </se-icon>
                                      <se-tooltip-content>
                                        {
                                          tooltipData
                                            ?.limitEnergyImportTooltipData?.data
                                        }
                                      </se-tooltip-content>
                                    </se-tooltip>
                                  </span>
                                </label>
                                <div className="col-sm-5">
                                  <input
                                    type="text"
                                    autoComplete="none"
                                    className="form-control form-control-md"
                                    name="maxLimitEnergyExportPower"
                                    placeholder="Targeted Max Export Power (kW)"
                                    maxLength="4"
                                    value={maxExportPower}
                                    onChange={maxExportImportPowerChangeHandler}
                                  />
                                  {maxLimitEnergyExportPower?.toString() ===
                                  "" && (
                                    <div className="invalid-feedback-custom">
                                      Targeted Max Export Power (kW) is
                                      required.
                                    </div>
                                  )  }
                                </div>
                              </div>
                            </div>}
                           { (( limitImport === true && limitExport === true) || limitImport === true ) && 
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                              <div className="form-group row">
                                <label
                                  htmlFor="inputEmail1112"
                                  className="col-sm-3 col-form-label"
                                >
                                  Targeted Max Import Power (kW)
                                  <span className="se-mandatory">*</span>
                                  <span className="se-block-enabled">
                                    <se-tooltip
                                      action={
                                        isTouchDevice() ? "click" : "hover"
                                      }
                                      position="right"
                                    >
                                      <se-icon
                                        option="raised"
                                        color="secondary"
                                        slot="tooltip"
                                      >
                                        information_circle
                                      </se-icon>
                                      <se-tooltip-content>
                                        {
                                          tooltipData
                                            ?.limitEnergyExportTooltipData?.data
                                        }
                                      </se-tooltip-content>
                                    </se-tooltip>
                                  </span>
                                </label>
                                <div className="col-sm-5">
                                  <input
                                    id="inputEmail112"
                                    type="text"
                                    autoComplete="none"
                                    className="form-control form-control-md"
                                    name="maxLimitEnergyImportPower"
                                    placeholder="Targeted Max Import Power (kW)"
                                    maxLength="10"
                                    value={maxLimitEnergyImportPower}
                                    onChange={maxExportImportPowerChangeHandler}
                                  />
                                  {maxLimitEnergyImportPower?.toString() ===
                                  "" && (
                                    <div className="invalid-feedback-custom">
                                      Targeted Max Import Power (kW) is
                                      required.
                                    </div>
                                  ) }
                                </div>
                              </div>
                            </div>}
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Real Time Export Control (EMO-M Based) */}
                    {gridSettings?.noExport === true ||
                    gridSettings?.exportOptimization === true ? (
                      <div
                        className="col-md-12"
                        label="Real Time Export Control (EMO-M Based)"
                        status={configuredStatus.emConfiguredStatus}
                        displayStatus="1"
                        icon="notification_error_full_stroke"
                      >
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-1">
                          <div className="dropdown-divider"></div>

                          <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                              <span className="bold">
                                Selected export management type:
                              </span>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-2 ">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  id="customRadioEx2"
                                  name="noExport"
                                  className="custom-control-input islandableRadio  asCompatible"
                                  checked={gridSettings.noExport}
                                  onClick={exportManagementChangeHandler}
                                  disabled={
                                    gridSettings.noExport === true
                                      ? false
                                      : true
                                  }
                                />
                                <label
                                  className="custom-control-label line-height-25px"
                                  htmlFor="customRadioEx2"
                                >
                                  Export management real time
                                </label>
                              </div>
                            </div>
                            {/* don't delete this */}
                            {false &&
                              !(
                                siteType === embLabels.ISLANDABLE_GSX &&
                                architectureType === embLabels.PV_GENSET_ATS &&
                                gridSettings.mode == "FUEL_SAVER"
                              ) && (
                                <div className="col-sm-5 col-md-5 col-lg-5 col-xl-5 mb-2 ">
                                  <div className="custom-control custom-radio">
                                    <input
                                      type="radio"
                                      id="customRadioEx1"
                                      name="exportOptimization"
                                      className="custom-control-input islandableRadio asCompatible"
                                      checked={gridSettings.exportOptimization}
                                      onClick={exportManagementChangeHandler}
                                      style={
                                        siteType ===
                                          embLabels.PV_HYBRIDIZATION ||
                                        (siteType ===
                                          embLabels.ISLANDABLE_GSX &&
                                          architectureType ===
                                            embLabels.PV_GENSET_ATS)
                                          ? { cursor: "not-allowed" }
                                          : { cursor: "pointer" }
                                      }
                                      disabled={
                                        siteType ===
                                          embLabels.PV_HYBRIDIZATION ||
                                        (siteType ===
                                          embLabels.ISLANDABLE_GSX &&
                                          architectureType ===
                                            embLabels.PV_GENSET_ATS) ||
                                        gridSettings.exportOptimization ===
                                          false
                                          ? true
                                          : false
                                      }
                                    />
                                    <label
                                      className="custom-control-label line-height-25px"
                                      htmlFor="customRadioEx1"
                                    >
                                      Export management predictive optimization
                                    </label>
                                  </div>
                                </div>
                              )}
                          </div>

                          <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                              <div className="form-group row">
                                <label
                                  htmlFor="inputEmail11"
                                  className="col-sm-3 col-form-label"
                                >
                                  Targeted Max Export Power (kW)
                                  <span className="se-mandatory">*</span>
                                  <span className="se-block-enabled">
                                    <se-tooltip
                                      action={
                                        isTouchDevice() ? "click" : "hover"
                                      }
                                      position="right"
                                    >
                                      <se-icon
                                        option="raised"
                                        color="secondary"
                                        slot="tooltip"
                                      >
                                        information_circle
                                      </se-icon>
                                      <se-tooltip-content>
                                        If the value is negative, the system
                                        will allow to export energy from on-site
                                        generation to the grid. <br />
                                        If the value is positive, the system
                                        will ensure a minimum energy import from
                                        the grid.
                                        <br /> If the value is equals to 0 ,the
                                        system will forbid any export.
                                      </se-tooltip-content>
                                    </se-tooltip>
                                  </span>
                                </label>
                                <div className="col-sm-5">
                                  <input
                                    type="text"
                                    autoComplete="none"
                                    className="form-control form-control-md"
                                    name="maxExportPower"
                                    placeholder="Targeted Max Export Power (kW)"
                                    maxLength="4"
                                    value={maxExportPower}
                                    onChange={maxExportImportPowerChangeHandler}
                                  />
                                  {maxExportPower.toString() === "" && (
                                    <div className="invalid-feedback-custom">
                                      Targeted Max Export Power (kW) is
                                      required.
                                    </div>
                                  ) }
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                              <span className="bold">
                                Select export management functions
                              </span>
                            </div>
                          </div>

                          {clientType === embLabels.GREENSTRUXURE_PROJECT &&
                          siteType === embLabels.ISLANDABLE_GSX &&
                          architectureType === embLabels.PV_GENSET_ATS &&
                          gridSettings.mode === "FUEL_SAVER" ? (
                            <>
                              {/* <div className="row">
                            <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input asCompatible"
                                  name="lossOfComm"
                                  id="customControlValidation1"
                                  value={lossOfComm}
                                  checked={lossOfComm}
                                  disabled={
                                    gridSettings.noExport === true ||
                                    gridSettings.exportOptimization === true
                                      ? false
                                      : true
                                  }
                                  onChange={exportFunctionChangeHandler}
                                />
                                <label
                                  className="custom-control-label line-height-25"
                                  htmlFor="customControlValidation1"
                                >
                                  No export from BESS
                                </label>
                                <se-tooltip
                                  action={isTouchDevice() ? "click" : "hover"}
                                  position="right"
                                >
                                  <se-icon
                                    option="raised"
                                    color="secondary"
                                    slot="tooltip"
                                  >
                                    information_circle
                                  </se-icon>
                                  <se-tooltip-content>
                                    <dl style={{ width: "285px" }}>
                                      <dt>EMO-M will :</dt>
                                      <dd>
                                        - Open Battery breaker in case of loss
                                        of communication with Utility meter.
                                      </dd>
                                      <dd>
                                        - Open PV breaker in case of loss of
                                        communication with Utility meter/PV
                                        inverter.
                                      </dd>
                                    </dl>
                                  </se-tooltip-content>
                                </se-tooltip>
                              </div>
                            </div>
                          </div> */}
                              <div className="row">
                                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input asCompatible"
                                      name="batteryBufferingExport"
                                      id="customControlValidation4"
                                      value={
                                        batteryDetails.batteryBufferingExport
                                      }
                                      checked={
                                        batteryDetails.batteryBufferingExport
                                      }
                                      disabled={
                                        siteType === embLabels.PV_HYBRIDIZATION
                                          ? true
                                          : batteryDetails.batteryBufferingStatus ===
                                              true &&
                                            (gridSettings.noExport === true ||
                                              gridSettings.exportOptimization ===
                                                true)
                                          ? false
                                          : true
                                      }
                                      style={
                                        siteType === embLabels.PV_HYBRIDIZATION
                                          ? //  ||
                                            //   (siteType === embLabels.ISLANDABLE_GSX &&
                                            //     architectureType === embLabels.PV_GENSET_ATS)
                                            { cursor: "not-allowed" }
                                          : { cursor: "pointer" }
                                      }
                                      onChange={exportFunctionChangeHandler}
                                    />
                                    <label
                                      className="custom-control-label line-height-25"
                                      htmlFor="customControlValidation4"
                                    >
                                      Battery buffering
                                    </label>
                                    <span className="se-block-enabled">
                                      <se-tooltip
                                        action={
                                          [
                                            embLabels.ISLANDABLE,
                                            embLabels.GRID_CONNECTED,
                                          ].includes(siteType)
                                            ? isTouchDevice()
                                              ? "click"
                                              : "hover"
                                            : "hover"
                                        }
                                        position="right"
                                      >
                                        <se-icon
                                          option="raised"
                                          color="secondary"
                                          slot="tooltip"
                                          disabled={
                                            siteType ===
                                            embLabels.PV_HYBRIDIZATION
                                              ? //  ||
                                                //   (siteType === embLabels.ISLANDABLE_GSX &&
                                                //     architectureType ===
                                                //     embLabels.PV_GENSET_ATS)
                                                true
                                              : false
                                          }
                                        >
                                          information_circle
                                        </se-icon>
                                        <se-tooltip-content>
                                          {tooltipData?.batteryBuffering?.data}
                                        </se-tooltip-content>
                                      </se-tooltip>
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input asCompatible"
                                      name="pvCurtailmentExport"
                                      id="customControlValidation21"
                                      value={pvCurtailmentExport}
                                      checked={pvCurtailmentExport}
                                      disabled={
                                        gridSettings.noExport === true ||
                                        gridSettings.exportOptimization === true
                                          ? false
                                          : true
                                      }
                                      onChange={exportFunctionChangeHandler}
                                    />
                                    <label
                                      className="custom-control-label line-height-25"
                                      htmlFor="customControlValidation21"
                                    >
                                      PV curtailment
                                    </label>
                                    <Tooltip
                                      position="right"
                                      iconColor="secondary"
                                      information={
                                        tooltipData?.pVCurtailment?.data
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input asCompatible"
                                      name="utilityLSIEnable"
                                      id="customControlValidationE3"
                                      checked={utilityLSIEnable}
                                      disabled={
                                        gridSettings.noExport === true ||
                                        gridSettings.exportOptimization === true
                                          ? false
                                          : true
                                      }
                                      onChange={utilityLSIChangeHandler}
                                    />
                                    <label
                                      className="custom-control-label line-height-25"
                                      htmlFor="customControlValidationE3"
                                    >
                                      PV breakers opening
                                    </label>
                                    <Tooltip
                                      position="right"
                                      iconColor="secondary"
                                      information={
                                        tooltipData?.pVBreakersOpening?.data
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="row">
                                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input asCompatible"
                                      name="batteryBufferingExport"
                                      id="customControlValidation4"
                                      checked={
                                        siteType ===
                                          embLabels.PV_HYBRIDIZATION ||
                                        (siteType ===
                                          embLabels.ISLANDABLE_GSX &&
                                          architectureType ===
                                            embLabels.PV_GENSET_ATS)
                                          ? false
                                          : batteryDetails.batteryBufferingExport
                                      }
                                      disabled={
                                        siteType === embLabels.PV_HYBRIDIZATION
                                          ? true
                                          : batteryDetails.batteryBufferingStatus ===
                                              true &&
                                            (gridSettings.noExport === true ||
                                              gridSettings.exportOptimization ===
                                                true)
                                          ? false
                                          : true
                                      }
                                      style={
                                        siteType ===
                                          embLabels.PV_HYBRIDIZATION ||
                                        (siteType ===
                                          embLabels.ISLANDABLE_GSX &&
                                          architectureType ===
                                            embLabels.PV_GENSET_ATS)
                                          ? { cursor: "not-allowed" }
                                          : { cursor: "pointer" }
                                      }
                                      onChange={exportFunctionChangeHandler}
                                    />
                                    <label
                                      className="custom-control-label line-height-25"
                                      htmlFor="customControlValidation4"
                                    >
                                      Battery buffering
                                    </label>

                                    <span className="se-block-enabled">
                                      {console.log("siteType::::::", siteType)}
                                      <se-tooltip
                                        action={
                                          [
                                            embLabels.ISLANDABLE,
                                            embLabels.GRID_CONNECTED,
                                          ].includes(siteType)
                                            ? isTouchDevice()
                                              ? "click"
                                              : "hover"
                                            : "hover"
                                        }
                                        position="right"
                                      >
                                        <se-icon
                                          option="raised"
                                          color="secondary"
                                          slot="tooltip"
                                          disabled={
                                            siteType ===
                                              embLabels.PV_HYBRIDIZATION ||
                                            (siteType ===
                                              embLabels.ISLANDABLE_GSX &&
                                              architectureType ===
                                                embLabels.PV_GENSET_ATS)
                                              ? true
                                              : false
                                          }
                                        >
                                          information_circle
                                        </se-icon>
                                        <se-tooltip-content>
                                          {tooltipData?.batteryBuffering?.data}
                                        </se-tooltip-content>
                                      </se-tooltip>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {!(
                                clientType ===
                                  embLabels.GREENSTRUXURE_PROJECT &&
                                siteType === embLabels.GRID_CONNECTED_GSX &&
                                architectureType === embLabels.PV_BESS_NR
                              ) && (
                                <div className="row">
                                  <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input asCompatible"
                                        name="lossOfComm"
                                        id="customControlValidation12"
                                        value={lossOfComm}
                                        checked={lossOfComm}
                                        disabled={
                                          gridSettings.noExport === true ||
                                          gridSettings.exportOptimization ===
                                            true
                                            ? false
                                            : true
                                        }
                                        onChange={exportFunctionChangeHandler}
                                      />
                                      <label
                                        className="custom-control-label line-height-25"
                                        htmlFor="customControlValidation12"
                                      >
                                        Open BESS/PV breaker in case of loss of
                                        communication
                                      </label>
                                      <se-tooltip
                                        action={
                                          isTouchDevice() ? "click" : "hover"
                                        }
                                        position="right"
                                      >
                                        <se-icon
                                          option="raised"
                                          color="secondary"
                                          slot="tooltip"
                                        >
                                          information_circle
                                        </se-icon>
                                        <se-tooltip-content>
                                          <dl style={{ width: "285px" }}>
                                            <dt>EMO-M will :</dt>
                                            <dd>
                                              - Open Battery breaker in case of
                                              loss of communication with Utility
                                              meter.
                                            </dd>
                                            <dd>
                                              - Open PV breaker in case of loss
                                              of communication with Utility
                                              meter/PV inverter.
                                            </dd>
                                          </dl>
                                        </se-tooltip-content>
                                      </se-tooltip>
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="row">
                                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input asCompatible"
                                      name="pvCurtailmentExport"
                                      id="customControlValidation22"
                                      value={pvCurtailmentExport}
                                      checked={pvCurtailmentExport}
                                      disabled={
                                        gridSettings.noExport === true ||
                                        gridSettings.exportOptimization === true
                                          ? false
                                          : true
                                      }
                                      onChange={exportFunctionChangeHandler}
                                    />
                                    <label
                                      className="custom-control-label line-height-25"
                                      htmlFor="customControlValidation22"
                                    >
                                      PV curtailment
                                    </label>
                                    <Tooltip
                                      position="right"
                                      iconColor="secondary"
                                      information={
                                        tooltipData?.pVCurtailment?.data
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input asCompatible"
                                      name="utilityLSIEnable"
                                      id="customControlValidationE3"
                                      checked={utilityLSIEnable}
                                      disabled={
                                        gridSettings.noExport === true ||
                                        gridSettings.exportOptimization === true
                                          ? false
                                          : true
                                      }
                                      onChange={utilityLSIChangeHandler}
                                    />
                                    <label
                                      className="custom-control-label line-height-25"
                                      htmlFor="customControlValidationE3"
                                    >
                                      PV breakers opening
                                    </label>
                                    <Tooltip
                                      position="right"
                                      iconColor="secondary"
                                      information={
                                        tooltipData?.pVBreakersOpening?.data
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {((clientType === embLabels.GREENSTRUXURE_PROJECT &&
                            siteType === embLabels.GRID_CONNECTED_GSX &&
                            architectureType === embLabels.PV_BESS_NR) ||
                            (clientType === embLabels.GREENSTRUXURE_PROJECT &&
                              siteType === embLabels.ISLANDABLE_GSX &&
                              architectureType ===
                                embLabels.PV_GENSET_ATS)) && (
                            <div className="row">
                              <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input asCompatible"
                                    name="lossOfComm"
                                    id="customControlValidation23"
                                    value={lossOfComm}
                                    checked={lossOfComm}
                                    disabled={
                                      gridSettings.noExport === true ||
                                      gridSettings.exportOptimization === true
                                        ? false
                                        : true
                                    }
                                    onChange={exportFunctionChangeHandler}
                                  />
                                  <label
                                    className="custom-control-label line-height-25"
                                    htmlFor="customControlValidation23"
                                  >
                                    Degraded Mode
                                  </label>
                                  <Tooltip
                                    position="right"
                                    iconColor="secondary"
                                    information=" If EMA controller disconnected from EMO-M
                                  then charge the BESS to nominal state of
                                  charge. Export excess of PV Production to
                                  grid when BESS already charged to nominal."
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div
                        className="col-md-12"
                        label="Real Time Export Control (EMO-M Based)"
                        status="1"
                        displayStatus="0"
                        icon="notification_error_full_stroke"
                      ></div>
                    )}

                    {/* Real Time Import Control (EMA Controller Based) */}
                    { false && gridSettings?.noImport === true && (
                      <div
                        className="col-md-12"
                        label="Real Time Import Control (EMA Controller Based)"
                        status={configuredStatus.realTimeControlEMA}
                        displayStatus="1"
                        icon="notification_error_full_stroke"
                      >
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-1">
                          <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                              <div className="form-group row">
                                <label
                                  htmlFor="inputEmail113"
                                  className="col-sm-3 col-form-label"
                                >
                                  Import Start Hysteresis
                                  <span className="se-mandatory">*</span>
                                  <span className="se-block-enabled">
                                    <se-tooltip
                                      action={
                                        isTouchDevice() ? "click" : "hover"
                                      }
                                      position="right"
                                    >
                                      <se-icon
                                        option="raised"
                                        color="secondary"
                                        slot="tooltip"
                                      >
                                        information_circle
                                      </se-icon>
                                      <se-tooltip-content>
                                        {
                                          tooltipData
                                            ?.importStartHysteresisTooltipData
                                            ?.data
                                        }
                                      </se-tooltip-content>
                                    </se-tooltip>
                                  </span>
                                </label>
                                <div className="col-sm-5">
                                  <input
                                    type="text"
                                    autoComplete="none"
                                    className="form-control form-control-md"
                                    name="importStartHysteresis"
                                    placeholder="Targeted Start Hysteresis"
                                    maxLength="4"
                                    value={importStartHysteresis}
                                    onChange={hysteresisChangeHandler}
                                  />
                                  {importStartHysteresis?.toString() === "" ? (
                                    <div className="invalid-feedback-custom">
                                      Targeted Import Start Hysteresis is
                                      required.
                                    </div>
                                  ) : parseInt(importStartHysteresis) <= 1000 &&
                                    parseInt(importStartHysteresis) >= 0 ? (
                                    parseInt(importStartHysteresis) >=
                                    parseInt(importStopHysteresis) ? (
                                      <div className="invalid-feedback-custom">
                                        Targeted Import Start Hysteresis should
                                        be less than Import Stop Hysteresis
                                      </div>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    <div className="invalid-feedback-custom">
                                      Targeted Import Start Hysteresis should be
                                      in range (0 &lt; Max Import Start
                                      Hysteresis &gt; 1000)
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                              <div className="form-group row">
                                <label
                                  htmlFor="inputEmail113"
                                  className="col-sm-3 col-form-label"
                                >
                                  Import Stop Hysteresis
                                  <span className="se-mandatory">*</span>
                                  <span className="se-block-enabled">
                                    <se-tooltip
                                      action={
                                        isTouchDevice() ? "click" : "hover"
                                      }
                                      position="right"
                                    >
                                      <se-icon
                                        option="raised"
                                        color="secondary"
                                        slot="tooltip"
                                      >
                                        information_circle
                                      </se-icon>
                                      <se-tooltip-content>
                                        {
                                          tooltipData
                                            ?.importStopHysteresisTooltipData
                                            ?.data
                                        }
                                      </se-tooltip-content>
                                    </se-tooltip>
                                  </span>
                                </label>
                                <div className="col-sm-5">
                                  <input
                                    id="inputEmail113"
                                    type="text"
                                    autoComplete="none"
                                    className="form-control form-control-md"
                                    name="importStopHysteresis"
                                    placeholder="Targeted import Stop Hysteresis"
                                    maxLength="4"
                                    value={importStopHysteresis}
                                    onChange={hysteresisChangeHandler}
                                  />
                                  {importStopHysteresis?.toString() === "" ? (
                                    <div className="invalid-feedback-custom">
                                      Targeted Max Import Power (kW) is
                                      required.
                                    </div>
                                  ) : parseInt(importStopHysteresis) <= 1000 &&
                                    parseInt(importStopHysteresis) >= 0 ? (
                                    parseInt(importStopHysteresis) >
                                    parseInt(importStartHysteresis) ? (
                                      ""
                                    ) : (
                                      <div className="invalid-feedback-custom">
                                        Targeted Import Stop Hysteresis value
                                        should be greater than Import Start
                                        Hysteresis
                                      </div>
                                    )
                                  ) : (
                                    <div className="invalid-feedback-custom">
                                      Targeted Import Stop Hysteresis should be
                                      in range (0 &lt; Max Import Stop
                                      Hysteresis &gt; 1000)
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Manual Demand Response  */}
                    {gridSettings?.manualDemandResponse === true ? (
                      <div
                        className="col-md-12"
                        label="Manual Demand Response"
                        status={1}
                        displayStatus="1"
                        icon="notification_error_full_stroke"
                      >
                        <div className="row pt-2 pl-4">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1 pl-4 se-block-disabled">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input asCompatible"
                                name="selfConsumption"
                                id="customControlValidation3"
                                value={gridSettings?.manualDemandResponse}
                                checked={gridSettings?.manualDemandResponse}
                                onChange={
                                  gridSettings?.manualDemandResponse === true
                                    ? undefined
                                    : undefined
                                }
                              />
                              <label
                                className="custom-control-label line-height-25"
                                htmlFor="customControlValidation3"
                              >
                                Enabled
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="col-md-12"
                        label="dummy Manual Demand Response"
                        status="1"
                        displayStatus="0"
                        icon="notification_error_full_stroke"
                      ></div>
                    )}

                    {/* offGridMode Response  */}
                    {gridSettings?.offGridMode === true ? (
                      <div
                        className="col-md-12"
                        label="Off Grid Preparedness"
                        status={1}
                        displayStatus="1"
                        icon="notification_error_full_stroke"
                      >
                        <div className="row pt-2 pl-4">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1 pl-4 se-block-disabled">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input asCompatible"
                                name="selfConsumption"
                                id="customControlValidation3"
                                value={gridSettings?.offGridMode}
                                checked={gridSettings?.offGridMode}
                                onChange={
                                  gridSettings?.offGridMode === true
                                    ? undefined
                                    : undefined
                                }
                              />
                              <label
                                className="custom-control-label line-height-25"
                                htmlFor="customControlValidation3"
                              >
                                Enabled
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="col-md-12"
                        label="dummy Off Grid Preparedness"
                        status="1"
                        displayStatus="0"
                        icon="notification_error_full_stroke"
                      ></div>
                    )}

                    {/* selfConsumption */}
                    {gridSettings.selfConsumption === true ? (
                      <div
                        className="col-md-12"
                        label="Self Consumption"
                        status={1}
                        displayStatus="1"
                        icon="notification_error_full_stroke"
                      >
                        <div className="row pt-2 pl-4">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1 pl-4 se-block-disabled">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input asCompatible"
                                name="selfConsumption"
                                id="customControlValidation3"
                                value={gridSettings.selfConsumption}
                                checked={gridSettings.selfConsumption}
                                onChange={
                                  gridSettings.selfConsumption === true
                                    ? undefined
                                    : undefined
                                }
                              />
                              <label
                                className="custom-control-label line-height-25"
                                htmlFor="customControlValidation3"
                              >
                                Enabled
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="col-md-12"
                        label="dummy Self Consumption"
                        status="1"
                        displayStatus="0"
                        icon="notification_error_full_stroke"
                      ></div>
                    )}

                    <div
                      className="col-md-12"
                      label="dummy on grid"
                      status={1}
                      displayStatus="0"
                      icon="notification_error_full_stroke"
                    ></div>
                  </Accordion>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OnGridOptimization;
