import React from "react";

const NoAssetSelected = () => (
  <div className={"col-sm-12 col-md-12 col-lg-12 col-xl-12 info"}>
    <div className="mb-2">
      <se-icon color="standard" size="large">
        information_stroke{" "}
      </se-icon>
    </div>
    <div>
      Please select an item on the main workspace to reach its settings.{" "}
    </div>
  </div>
);

export default NoAssetSelected;
