import React from "react";
import ReactSelect, { components } from "react-select";
import MyErrorBoundary from "../../utils/ErrorBoundary";
const Option = (props) => {
  return (
    <MyErrorBoundary>
      
    <div>
      <components.Option {...props}>
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input asCompatible"
            name="vlanOptions"
            id="vlanOptions1"
            value={props.isSelected}
            checked={props.isSelected}
            onChange={() => props.selectOption(props.data)}
          />
          <label
            className="custom-control-label line-height-25"
            htmlFor="vlanOptions1"
          >
            {props.label}
          </label>
        </div>
      </components.Option>
    </div>
    </MyErrorBoundary>
  );
};
const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center"
};
// Code : Function not used
// const formatGroupLabel = data => (
//   <div style={groupStyles}>
//     <span>{data.label}</span>
//     <span style={groupBadgeStyles}>{data.options.length}</span>
//   </div>
// );

const MultiSelectionDropdown = ({
  secureData,
  trunkMembers,
  getTrunkMembers,
  selectedMemberType,
  
}) => {
  
  const dropDownStyles = {
    container:(provided)=>({
      ...provided,
      maxWidth:"100px",
  }),
  control:(provided) =>({
    ...provided,
    minHeight: "28px",
    width: "240px",
  }),
    valueContainer: (provided, state) => ({
      ...provided,
      textOverflow: "ellipsis",
      maxWidth: "90%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      display: "flex",
      flexWrap:"nowrap",
       }),
    placeholder: (provided) =>({
      ...provided,
      display:"inline-block",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "black",
      display: "flex",
      alignItems: "start",
      flexGap: "5px",
      fontFamily: "Nunito, sans-serif",
      fontStyle: "normal",
      fontSize: "0.875rem",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color:"#495057",
      "& svg": {
        width: "16px",
        height: "16px"
      },
      padding:"2px",
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: '#495057',
      "& svg": {
        width: "16px",
        height: "16px"
      },
      padding:"2px",
    })
    
  };

  
  const handleChange = (selected) => {
    getTrunkMembers(selected, selectedMemberType);
  };

  const optionsNetworks = () => {
    const networks = secureData?.securedCommunicationArchitecture.map(
      (network) => ({
        ...network,
        label: network.vlan,
        value: network.vlan,
      })
    );

    return networks;
  };
  const defaultNetworks = () => {
    const obj1 = optionsNetworks();
    const obj2 = trunkMembers?.hybridMembers;
    if (obj2.length === 0) {
      return obj2;
    } else {
      const members = obj1?.filter((obj1Item) => {
        for (let obj2Item of obj2) {
          if (obj1Item?.pvid === obj2Item?.pvid) {
            return true;
          }
        }
        return false;
      });

      return members;
    }
  };
  const multiValueContainer = ({ selectProps, data }) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex(selected => selected.label === label);
    const isLastSelected = index === allSelected.length - 1;
    const labelSuffix = isLastSelected ? ` --(${allSelected.length})` : ", ";
  // const labelSuffix =", ";
    const val = `${label}${labelSuffix}`;
   return <div style={{display:"inline-flex", fontSize:"14px", fontFamily:"Nunito, sans-serif",color:" #495057"}}>{val}</div>;
  };
  
  return (
    <>
      <ReactSelect
        menuMaxHeight={200}
        menuPortalTarget={document.body}
        options={optionsNetworks()}
        defaultValue={defaultNetworks()}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
         
          MultiValueContainer: multiValueContainer
        }}
        onChange={handleChange}
        allowSelectAll={true}
        hideClearIcon={true}
        styles={dropDownStyles}
        menuPlacement="auto"
      />
    </>
  );
};

export default MultiSelectionDropdown;
