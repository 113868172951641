/* istanbul ignore file */

import React, { Component } from "react";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { withRouter } from "react-router-dom";
import SECard from "../components/secard";
import SENews from "../components/newscard";
import ApiSession from "../api/ApiSession";
import Pagination from "react-js-pagination";
import UserManagement from "./userManagement";
import GlobalParametersHook from "./GlobalParametersHooks";
import LibraryManagementHook from "./LibraryManagementHook";
import Analytics from "./analytics";
import Onboarding from "react-onboarding-pro";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-onboarding-pro/build/index.css";
import "react-circular-progressbar/dist/styles.css";
import Dsc from "./dsc";
const config = {
  steps: [
    {
      title: "Projects",
      description:
        "In Projects page user can click on Create Project to start a new project.",
    },
    {
      title: "Information",
      description:
        "User can enter the project details, select the use case, Site Information details and Address required for the project and ensure to validate the project to proceed to design page, to configure DER’s.",
    },
    {
      title: "Configure DER’s",
      description:
        "User can configure the DER’s based on the use case selected in the Information page, and user would view the pre populated SLD design on the basis of use case selected under information page while creating a project, and here you can design SLD as per your need,  Maximum 12 CB’s can be added and 15 PV’s can be distributed among 3 CB’s. Until and unless all DER’s are configured user will not be allowed to proceed further to configure the usecase.",
    },
    {
      title: "Configure Use Cases",
      description:
        "Here you can configure the usecase for on grid optimization & off grid management, these usecase listed here are on the basis of your selection done under information page while creating a project, any usecase which are not selected or not used will be part of disabled use cases, here user can modify the parameters of each usecase and need to validate the usecase so that he new parameters are applied accordingly, without validating Configure use cases user will not be able to proceed further.",
    },
    {
      title: "System",
      description:
        "System page consist of Circuit breaker Communication settings, DER  Communication settings, & Load & EV Communication settings, these settings are pre-configured by default user can modify as per his system/network settings.",
    },
    {
      title: "BOM",
      description:
        "BOM page consist of EMA and EMO-M license displayed on the basis of your use case selected.",
    },
    {
      title: "Deploy",
      description:
        "User need to enter EMA and EMO-M license and unlock deployment for using below mentioned services,",
      type: "form", // Have an embedded form
      fields: [
        {
          label: "EcoStruxure Microgrid Advisor cloud services",
          type: "hidden",
        },
        {
          label: "EcoStruxure Microgrid Advisor Controller (Harmony iPC)",
          type: "hidden",
        },
        {
          label:
            "EcoStruxure Microgrid Operation - Medium Controller (M251 PLC)",
          type: "hidden",
        },
        {
          label: "EcoStruxure Microgrid Operation - Medium HMI (Magelis HMI)",
          type: "hidden",
        },
      ],
    },
  ],
  overlayClose: false,
};

const apiSession = new ApiSession();

class ProjectHome extends Component {
  constructor(props) {
    let userSampleData = {
      name: "",
      role: "",
      access: {},
    };
    super(props);
    this.state = {
      key:
        localStorage.getItem("selectedtab") !== null
          ? localStorage.getItem("selectedtab")
          : "projects",
      searchInput: "",
      searchQ2cInput: "",

      loading: false,
      sortProjectOption: 0,
      projects: [],
      projectsData: [],
      projectTotalCount: 0,
      activePage: 1,
      itemSliceFirst: 0,
      itemSliceLast: 5,
      itemsPerPage: 5,
      type: "",
      message: "",
      userScope:
        JSON.parse(localStorage.getItem("scope")) !== null
          ? JSON.parse(localStorage.getItem("scope"))
          : userSampleData,
      deleteProjectConfirmPopUpStatus: false,
      deleteProjectId: null,
      deleteProjectName: "",
      emaWebsiteName: "",
      deleteSiteStatus: false,
      projectSite: false,
      newsList: [],
      pageLength: 30,
      deleteProjectConfirmSitePopUpStatus: false,
      confirmSiteDeletion: false,
      userImages: {},
      copyProjectConfirmPopUpStatus: false,
      copyProjectId: null,
      copyProjectName: "",
      copyProjectSiteName: "",
      projectPercentageLoaded: 0,
      displayProjectPercentage: true,
      createProjectPopupStatus: false,
      createProject: {
        region: "ANSI",
        application: "Buildings",
        energyManagementSystem: "Autogrid Flex"
      },
      projectTypes: {},
    };
  }

  handleSearchInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    let search = e.target.value;
    this.setState({ projects: this.state.projectsData }, () => {
      const filteredCountries = this.state.projects?.filter((project) => {


        
        return (
          project.projectName.toLowerCase().indexOf(search.toLowerCase()) !==
          -1
          ||
          project.createdOn === search ||
          project.createdBy.firstName
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1 ||
          project.createdBy.lastName
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1 ||
             project.q2cOrderNumber && (project.q2cOrderNumber.toLowerCase().indexOf(search.toLowerCase()) !== -1) || 
          this.checkUserFilter(project.users, search)
        );
      });
      this.setState({ projects: filteredCountries });
    });
    this.setState({ activePage: 1 });
    this.setState({ itemSliceFirst: 0 });
    this.setState({ itemSliceLast: this.state.itemsPerPage });
  };
  checkUserFilter(users, search) {
    users?.filter((user) => {
      return user.firstName !== "" && user.firstName !== null
        ? user.firstName.toLowerCase().indexOf(search.toLowerCase()) !== -1
          ? true
          : false
        : true;
    });
  }

  resetProjectList() {
    this.setState({ projects: this.state.projectsData });
    this.setState({ searchInput: "" });
    this.setState({ activePage: 1 });
    this.setState({ itemSliceFirst: 0 });
    this.setState({ itemSliceLast: this.state.itemsPerPage });
  }

  onButtonClick() {
    sessionStorage.setItem("projectId", null);
    localStorage.setItem("pname", null);
    if (this.state.userScope.access.saveProjects === true) {
      // let { history } = this.props;
      // history.push({
      //   pathname: "/information",
      // });

      apiSession
        .getProjectTypes()
        .then((json) => {
          this.setState({ projectTypes: json });
        })
        .catch((err) => {
          // Do Nothing
        });

      this.setState({
        createProjectPopupStatus: true,
      });
    } else {
      this.callSnackbar("error", "Not Authorized");
    }
  }

  openProject(projectId, projectName, deploymentReady) {
    if (this.state.userScope.access.openProject === true) {
      let { history } = this.props;
      sessionStorage.setItem("projectId", projectId);
      localStorage.setItem("pname", projectName);

      localStorage.setItem("deploymentReady", deploymentReady);
      history.push({
        pathname: "/information",
      });
    }
  }

  handlePageChange(pageNumber) {
    let itemLast = pageNumber * this.state.itemsPerPage;
    let itemFirst = itemLast - this.state.itemsPerPage;
    this.setState({ activePage: pageNumber });
    this.setState({ itemSliceLast: itemLast }, () => {
      this.setState({ itemSliceFirst: itemFirst });
    });
  }

  onChangeSortOption = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (e.target.value) {
      let projectListData = Object.assign([], this.state.projects);
      switch (e.target.value) {
        case "1":
          projectListData.sort(function (a, b) {
            var projectNameA = a.projectName.toLowerCase(),
              projectNameB = b.projectName.toLowerCase();
            if (projectNameA < projectNameB)
              //sort string ascending
              return -1;
            if (projectNameA > projectNameB) return 1;
            return 0;
          });
          break;
        case "2":
          projectListData.sort(function (a, b) {
            var projectNameA = a.projectName.toLowerCase(),
              projectNameB = b.projectName.toLowerCase();
            if (projectNameA > projectNameB)
              //sort string desc
              return -1;
            if (projectNameA < projectNameB) return 1;
            return 0;
          });
          break;
        case "3":
          projectListData.sort(function (a, b) {
            var dateA = new Date(a.lastModificationDate),
              dateB = new Date(b.lastModificationDate);
            return dateA - dateB; //sort by date ascending
          });
          break;
        case "4":
          projectListData.sort(function (a, b) {
            var dateA = new Date(a.lastModificationDate),
              dateB = new Date(b.lastModificationDate);
            return dateB - dateA; //sort by date desc
          });
          break;

        case "5":
          projectListData.sort((a, b) => {
            return b.favourite - a.favourite;
          });
          break;
        default:
          break;
      }
      this.setState({ projects: projectListData });
      this.setState({ activePage: 1 });
      this.setState({ itemSliceFirst: 0 });
      this.setState({ itemSliceLast: this.state.itemsPerPage });
    }
  };

  componentDidMount() {
    this.loadProject();
    this.getNews();
    localStorage.setItem("assetEditStatus", "");
    localStorage.setItem("projectType", null);
    
  }

  loadProject() {
    sessionStorage.setItem("projectId", null);
    localStorage.setItem("pname", null);
    this.setState({ loading: true });
    apiSession
      .getProjectList(0, parseInt(this.state.pageLength))
      .then((json) => {
        this.setState({ projects: json.projects });
        this.setState({ projectsData: json.projects });
        this.setState({ projectTotalCount: json.projectCount });
        this.setState({ userImages: json.userImages });
        this.setState({ loading: false });
        if (json.projectCount) {
          const projectPercentageLoaded = Math.round(
            (json.projects.length / json.projectCount) * 100
          );
          this.setState({ projectPercentageLoaded: projectPercentageLoaded });
          if (projectPercentageLoaded === 100) {
            this.setState({ displayProjectPercentage: false });
          }
        } else {
          this.setState({ displayProjectPercentage: false });
        }

        let countData = (
          (json.projectCount - parseInt(this.state.pageLength)) /
          parseInt(this.state.pageLength)
        ).toFixed(2);
        let count = Math.ceil(countData);
        if (count) {
          for (let i = 1; i <= count; i++) {
            this.getProjectLoop(i, parseInt(this.state.pageLength));
          }
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
        this.setState({ loading: false });
        }
      });
  }

  getProjectLoop(page, count) {
    apiSession
      .getProjectList(page, count)
      .then((json) => {
        let copyProjects = Object.assign([], this.state.projects);
        let copyProjectsData = Object.assign([], this.state.projectsData);
        let copyUserImages = Object.assign({}, this.state.userImages);

        copyProjectsData.push(...json.projects);
        copyProjects.push(...json.projects);
        let newUserImages = Object.assign(copyUserImages, json.userImages);
        const projectPercentageLoaded = Math.round(
          (copyProjectsData.length / json.projectCount) * 100
        );
        if (projectPercentageLoaded === 100) {
          this.setState({ displayProjectPercentage: false });
        }
        this.setState({ projectPercentageLoaded: projectPercentageLoaded });
        this.setState({ projects: copyProjects });
        this.setState({ projectsData: copyProjectsData });
        this.setState({ userImages: newUserImages });
      })
      .catch((error) => { });
  }

  callSnackbar = (errorType, Body) => {
    this.setState({
      type: errorType,
      message: Body,
    });
    document.getElementById("snackbar-project").open = true;
  };

  componentDidUpdate(previousProps, previousState) {
    if (localStorage.getItem("selectedtab") !== null) {
      if (previousState.key !== localStorage.getItem("selectedtab")) {
        this.setState({ key: localStorage.getItem("selectedtab") });
      }
    }
  }

  deleteProject = (e, projectId, projectName) => {
    this.setState({ loadingProjectDelete: true });
    this.setState({ deleteProjectId: projectId });
    this.setState({ deleteProjectName: projectName });
    this.setState({ deleteProjectConfirmPopUpStatus: true });
    apiSession
      .getEMASite(projectId)
      .then((response) => {
        this.setState({ deleteSiteStatus: true });
        this.setState({ emaWebsiteName: response.siteName });
        this.setState({ loadingProjectDelete: false });
      })
      .catch((error) => {
        if (error.response !== undefined) {
        this.setState({ emaWebsiteName: "" });
        this.setState({ loadingProjectDelete: false });
        this.setState({ deleteSiteStatus: false });
        }
      });
    e.stopPropagation();
  };

  copyProject = (e, projectId, projectName) => {
    this.setState({
      copyProjectConfirmPopUpStatus: true,
      copyProjectId: projectId,
      copyProjectName: ``,
      copyProjectSiteName: ``,
    });

    e.stopPropagation();
  };
  removeProjectConfirm() {
    if (this.state.deleteProjectId) {
      if (this.state.projectSite === true) {
        this.deleteEMASite(this.state.deleteProjectId);
      } else {
        this.setState({ loading: true });
        this.removeProject(this.state.deleteProjectId);
      }
    }
    this.closeDeleteProjectPopUp();
  }

  copyProjectConfirm = () => {
    this.setState({ copyProjectConfirmPopUpStatus: false, loading: true });
    const jsonBody = {
      projectId: this.state.copyProjectId,
      projectName: this.state.copyProjectName,
      siteName: this.state.copyProjectSiteName,
    };
    apiSession
      .copyProject(jsonBody)
      .then((json) => {
        sessionStorage.setItem("projectId", json.projectId);
        localStorage.setItem("pname", this.state.copyProjectName);
        this.callSnackbar("success", json.message);
        this.setState({ loading: false });
        let { history } = this.props;
        history.push({
          pathname: "/information",
        });
      })
      .catch((error) => {
        if (error.response !== undefined) {
          this.setState({ loading: false });
          if (parseInt(error.response.status) === 400) {
            this.callSnackbar("error", error.error);
          } else if (error.response.status === 500) {
            this.callSnackbar("error", "Error in copying the project");
          } else {
            this.callSnackbar("error", "Error in copying the project");
          }
        } else {
          this.callSnackbar("error", "Error in copying the project");
        }
      });
  };

  deleteEMASite(projectId) {
    this.setState({ loading: true });
    apiSession
      .deleteEMASite(projectId)
      .then((response) => {
        this.removeProject(projectId);
        this.setState({ deleteSiteStatus: false });
      })
      .catch((error) => {
        if (error.response !== undefined) {
        this.setState({ loading: false });
        this.setState({ deleteSiteStatus: false });
        if (error.response !== undefined) {
          if (error.response.status === 400) {
            this.callSnackbar("error", error.response.data.message);
          }
        } else {
          this.callSnackbar("error", "Error in deletion of project");
        }}
      });
  }

  removeProject(projectId) {
    apiSession
      .deleteProject(projectId)
      .then((response) => {
        this.callSnackbar("success", "Deleted Successfully");
        let projectsList = Object.assign([], this.state.projects);
        var index = projectsList.findIndex(
          (obj) => obj.projectId === projectId
        );
        if (index !== -1) {
          delete projectsList[index];
          projectsList = projectsList?.filter(function (dataElement) {
            return dataElement !== undefined;
          });
          this.setState({ projects: projectsList });
        }
        this.setState({ loading: false });
        //window.location.reload(false);
      })
      .catch((error) => {
        if (error.response !== undefined) {
        this.setState({ loading: false });
        this.callSnackbar("error", "Error in deletion");
        }
      });
  }

  closeDeleteProjectPopUp() {
    this.setState({ deleteProjectId: null });
    this.setState({ deleteProjectName: "" });
    this.setState({ emaWebsiteName: "" });
    this.setState({ deleteProjectConfirmPopUpStatus: false });
    this.setState({ deleteSiteStatus: false });
  }

  closeCopyProjectPopUp = () => {
    this.setState({
      copyProjectConfirmPopUpStatus: false,
      copyProjectId: null,
      copyProjectName: "",
      copyProjectSiteName: "",
    });
  };

  handleCopyProjectChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleProjectSiteChange(e) {
    this.setState({ [e.target.name]: e.target.checked });
  }

  favouriteProject = (e, projectId, favouriteStatus) => {
    this.setState({ loading: true });
    if (favouriteStatus === false) {
      apiSession
        .saveFavourite(projectId)
        .then((response) => {
          this.setState({ loading: false });
          //this.updateFavourites(projectId, true);
          this.loadProject();
          this.callSnackbar("success", "Successfully marked favourite project");
        })
        .catch((error) => {
          if (error.response !== undefined) {
          this.setState({ loading: false });
          this.callSnackbar("error", "Error in marking favourite");
          }
        });
    } else {
      apiSession
        .deleteFavourite(projectId)
        .then((response) => {
          this.setState({ loading: false });
          //this.updateFavourites(projectId, false);
          this.loadProject();
          this.callSnackbar(
            "success",
            "Successfully unmarked favourite project"
          );
        })
        .catch((error) => {
          if (error.response !== undefined) {
          this.setState({ loading: false });
          this.callSnackbar("error", "Error in unmarking favourite");
          }
        });
    }
    e.stopPropagation();
  };

  updateFavourites(projectId, favouriteStatus) {
    let newProjectsData = Object.assign([], this.state.projects);
    newProjectsData.map((data, index) => {
      if (newProjectsData[index].projectId === projectId) {
        newProjectsData[index].favourite = favouriteStatus;
      }
      return null;
    });
    this.setState({ projects: newProjectsData });
    this.setState({ projectsData: newProjectsData });
  }

  getNews() {
    apiSession
      .getNews()
      .then((response) => {
        this.setState({ newsList: [] });
        this.setState({ newsList: response });
      })
      .catch((error) => {
        // Do nothing
      });
  }
  onSelectTab(key) {
    this.setState({ key });
    localStorage.setItem("selectedtab", key);
    if (key === "projects") {
      this.getNews();
    }
  }

  openOnboard() {
    Onboarding(config);
  }
  closeCreateProjectPopUp = () => {
    this.setState({
      createProjectPopupStatus: false,
      createProject: {
        ...this.state.createProject,
        region: "ANSI",
        application: "Buildings",
        energyManagementSystem: "Autogrid Flex"
      },
    });
  };

  onChangeMicrogridType = (e) => {
    const energyMgtSelect = this.state.projectTypes?.[
      e?.target?.value
    ]?.filter((a) => a?.energyManagementSystem === "Autogrid Flex");
     this.setState({
       createProject: {
         ...this.state.createProject,
         region: e.target.value,
         application: "Buildings",
         energyManagementSystem:
           energyMgtSelect.length > 0
             ? "Autogrid Flex"
             : energyMgtSelect?.[0]?.energyManagementSystem ??
               "Microgrid Advisor",
       },
     });  
 };
  onChangeSubProjectType = (e) => {
    if(e.target.value === "Access to Energy" && this.state.createProject?.region === "IEC"){
      this.setState({
        createProject: {
          ...this.state.createProject,
          application: e.target.value,
          energyManagementSystem: "Microgrid Advisor"
        },
      });
    }else{
      this.setState({
        createProject: {
          ...this.state.createProject,
          application: e.target.value,
        },
      });
    }
   
  };

  onChangeEnergyManagementSystem = (e) => {
    this.setState({
      createProject: {
        ...this.state.createProject,
        energyManagementSystem: e.target.value,
      },
    });
  };

  createProject = async () => {
    if (
      this.state.createProject?.region &&
      this.state.createProject?.application &&
      this.state.createProject?.energyManagementSystem

    ) {
      if(this.state.createProject?.application === "Access to Energy") {
        await this.setState({
          createProject: {
            ...this.state.createProject,
            energyManagementSystem: "Microgrid Advisor",
          },
        },()=>{

        });
      }
      let { history } = this.props;

      const projectType =
        this.state.projectTypes[this.state.createProject?.region]?.filter((item) => {



          return (this.state.createProject?.application === item.application &&
            this.state.createProject?.energyManagementSystem === item.energyManagementSystem)
        })



      localStorage.setItem("projectType", JSON.stringify(projectType?.[0]));
      this.setState({
        createProjectPopupStatus: false,
        createProject: {
          ...this.state.createProject,
          region: "",
          application: "",
          energyManagementSystem: ""
        },
      });

      history.push({
        pathname: "/information",
      });
    } else {
      this.callSnackbar("error", "Please Select Values");
    }
  };

  render() {

    return (
      <div>
        <se-container option="centered">
          <div className="container mt-1 mb-1">
            <Tabs
              id="controlled-tab-example"
              activeKey={this.state.key}
              onSelect={(key) => this.onSelectTab(key)}
            >
              <Tab eventKey="projects" title="PROJECTS">
                {this.state.key === "projects" ? (
                  <div className="container">
                    <se-loading loading={this.state.loading}></se-loading>
                    <div className="row">
                      <div className="col-sm-9 col-md-9 col-lg-9 col-xl-9 se-white-background">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3 px-0 text-right pt-1">
                          <div className="form-row justify-content-end align-items-center">
                            {this.state.displayProjectPercentage && (
                              <div
                                style={{
                                  width: 40,
                                  height: 45,
                                  padding: "2px",
                                }}
                              >
                                <CircularProgressbar
                                  value={this.state.projectPercentageLoaded}
                                  text={`${this.state.projectPercentageLoaded}%`}
                                  strokeWidth={10}
                                  styles={{
                                    text: {
                                      fontSize: "28px",
                                    },
                                  }}
                                />
                              </div>
                            )}
                            &nbsp;&nbsp;
                            <span
                              className="pointer"
                              style={
                                this.state.searchInput
                                  ? {}
                                  : { display: "none" }
                              }
                              onClick={this.resetProjectList.bind(this)}
                            >
                              <se-icon className="cheatSheetIcon se-icon icon_button ">
                                data_refresh
                              </se-icon>
                            </span>
                            &nbsp;&nbsp;

                            {/* <input
                              className="form-control  col-md-3 mr-2"
                              type="text"
                              name="searchQ2cInput"
                              value={this.state.searchQ2cInput}
                              placeholder="Search Q2C Number"
                              onChange={(e) => this.handleSearchQ2cInput(e)}
                              aria-label="Search"
                              autoComplete="none"
                            /> */}
                            <input
                              className="form-control  col-md-3 mr-2"
                              type="text"
                              name="searchInput"
                              value={this.state.searchInput}
                              placeholder="Search"
                              onChange={(e) => this.handleSearchInput(e)}
                              aria-label="Search"
                              autoComplete="none"
                            />


                            <select
                              className="form-control col-md-2 "
                              id="exampleFormControlSelect1"
                              name="sortProjectOption"
                              value={this.state.sortProjectOption}
                              onChange={this.onChangeSortOption.bind(this)}
                            >
                              <option value="0">Sort</option>
                              <option value="1">Name Asc</option>
                              <option value="2">Name Desc</option>
                              <option value="3">Modification Date Asc</option>
                              <option value="4">Modification Date Desc</option>
                              <option value="5">Favourites</option>
                            </select>
                            <se-button
                              option="raised"
                              color="primary"
                              icon="new_project"
                              onClick={
                                this.state.userScope.access.saveProjects ===
                                  true
                                  ? this.onButtonClick.bind(this)
                                  : ""
                              }
                              disabled={
                                this.state.userScope.access.saveProjects ===
                                  true
                                  ? false
                                  : true
                              }
                            >
                              Create Project
                            </se-button>
                            {/* <button type="button" disabled={this.state.userScope.access.saveProjects === true ? false : true}
                                                                onClick={this.state.userScope.access.saveProjects === true ?
                                                                    this.onButtonClick.bind(this) : ""
                                                                }


                                                                className=" btn btn-success btn-md ml-2 inline">
                                                                <se-icon className="cheatSheetIcon se-icon icon_button">new_project</se-icon>
                                                                <span className="ml-2">Create Project </span>
                                                            </button> */}
                          </div>
                        </div>

                        {this.state.projects
                          .slice(
                            this.state.itemSliceFirst,
                            this.state.itemSliceLast
                          )
                          .map((projectData, index) => (
                            <SECard
                              data={projectData}
                              onCardSelect={this.openProject.bind(
                                this,
                                projectData.projectId,
                                projectData.projectName,
                                projectData.deploymentReady
                              )}
                              deleteProjectStatus={
                                this.state.userScope.access.deleteProject ===
                                  true
                                  ? true
                                  : false
                              }
                              onDeleteProject={this.deleteProject.bind(this)}
                              onFavouriteProject={this.favouriteProject.bind(
                                this
                              )}
                              userImages={this.state.userImages}
                              copyProjectStatus={
                                this.state.userScope.access.saveProjects
                              }
                              onCopyProject={this.copyProject}
                            />
                          ))}
                        {this.state.projects.length === 0 &&
                          this.state.loading === true ? (
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 border listStyle mb-1 pt-2">
                            <p className="text-center se-font-16px-bold">
                              Please wait ... Loading Projects
                            </p>
                          </div>
                        ) : this.state.projects.length === 0 ? (
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 border listStyle mb-1 pt-2">
                            <p className="text-center se-font-16px-bold">
                              No projects available
                            </p>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="row align-items-center">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            {this.state.projects.length >
                              this.state.itemsPerPage ? (
                              <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.itemsPerPage}
                                totalItemsCount={this.state.projects.length}
                                pageRangeDisplayed={5}
                                innerclassName="pagination mt-1 mb-1"
                                onChange={this.handlePageChange.bind(this)}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            style={
                              this.state.projects.length !== 0
                                ? {}
                                : { display: "none" }
                            }
                            className="col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right"
                          >
                            <span>
                              Total Projects : {this.state.projects.length}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <SENews
                          newsList={this.state.newsList}
                          newsListLength={this.state.newsList.length}
                          onBoardingClick={this.openOnboard.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Tab>
              {this.state.userScope.access.getUsers === true ? (
                <Tab eventKey="user" title="USER MANAGEMENT">
                  {this.state.key === "user" ? <UserManagement /> : ""}
                </Tab>
              ) : (
                <div></div>
              )}

              {this.state.userScope.access.saveAssets === true ? (
                <Tab eventKey="asset" title="LIBRARY MANAGEMENT">
                  {this.state.key === "asset" ? <LibraryManagementHook /> : ""}
                </Tab>
              ) : (
                <div></div>
              )}

              {this.state.userScope.access.globalParams === true ? (
                <Tab eventKey="globalparam" title="GLOBAL PARAMETERS">
                  {this.state.key === "globalparam" ? (
                    <GlobalParametersHook />
                  ) : (
                    ""
                  )}
                </Tab>
              ) : (
                <div></div>
              )}

              {this.state.userScope.access.audit === true ? (
                <Tab eventKey="analytics" title="ANALYTICS">
                  {this.state.key === "analytics" ? <Analytics /> : ""}
                </Tab>
              ) : (
                <div></div>
              )}
              
               {this.state.userScope.access.dsc === true ? (
                <Tab eventKey="dsc" title="DSC">
                  {true? <Dsc /> : ""}
                </Tab>
              ) : (
                <div></div>
              )}
            </Tabs>
          </div>
          <se-snackbar
            id="snackbar-project"
            type={this.state.type}
            icon="information_stroke"
            message={this.state.message}
            duration="3000"
          ></se-snackbar>

          <se-dialog
            id="dialog-complex-delete-confirm"
            open={this.state.deleteProjectConfirmPopUpStatus}
            can-backdrop="false"
          >
            <se-loading loading={this.state.loadingProjectDelete}></se-loading>
            <se-dialog-header>Confirmation</se-dialog-header>
            <se-dialog-content>
              {this.state.deleteSiteStatus === true ? (
                <span className="se-font-14px">
                  EMA website is running for this project, do you want to delete
                  this EMA website ? If you delete , all site historical data
                  will be lost.
                  <br></br>
                  <br></br>
                  <span className="mt-2">
                    Do you confirm to delete this Project ?
                  </span>
                </span>
              ) : (
                <span className="se-font-14px">
                  <span className="mt-2">
                    Do you want to delete this Project - "
                    <b>{this.state.deleteProjectName}</b>" ?
                  </span>
                  <br></br>
                </span>
              )}

              <br></br>
              <br></br>
              <table className="table table-bordered">
                {this.state.deleteSiteStatus === true ? (
                  <tr>
                    <td>EcoStruxure MicroGrid Advisor(EMA) Website Name</td>
                    <td>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input asCompatible"
                          name="projectSite"
                          id="customControlValidation3"
                          checked={this.state.projectSite}
                          onChange={(e) => this.handleProjectSiteChange(e)}
                        />
                        <label
                          className="custom-control-label line-height-25"
                          htmlFor="customControlValidation3"
                        >
                          <b>{this.state.emaWebsiteName} </b>
                        </label>
                      </div>
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                {this.state.deleteSiteStatus === true ? (
                  <tr>
                    <td>EcoStruxure MicroGrid Build(EMB) Project Name</td>
                    <td>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input asCompatible"
                          name="projectSite"
                          id="customControlValidation3"
                          checked="true"
                          disabled="true"
                        />
                        <label
                          className="custom-control-label line-height-25"
                          htmlFor="customControlValidation3"
                        >
                          <b>{this.state.deleteProjectName} </b>
                        </label>
                      </div>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </table>
            </se-dialog-content>
            <se-dialog-footer>
              <se-button onClick={this.closeDeleteProjectPopUp.bind(this)}>
                Cancel
              </se-button>
              <se-button onClick={this.removeProjectConfirm.bind(this)}>
                OK
              </se-button>
            </se-dialog-footer>
          </se-dialog>

          <se-dialog
            id="dialog-complex-delete-confirm"
            open={this.state.copyProjectConfirmPopUpStatus}
            can-backdrop="false"
          >
            <se-loading loading={this.state.loadingProjectDelete}></se-loading>
            <se-dialog-header>Copy project</se-dialog-header>
            <se-dialog-content>
              <div className="col-md-12">
                <div className="form-group row mb-0">
                  <label
                    htmlFor="copyProjectName"
                    className="col-sm-9 col-form-label ml-3"
                  >
                    Enter new project name
                  </label>
                </div>
                <div className="form-group">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control form-control-sm"
                      id="copyProjectName"
                      name="copyProjectName"
                      maxlength="50"
                      value={this.state.copyProjectName}
                      onChange={this.handleCopyProjectChange}
                    />
                    <div
                      className="invalid-feedback-custom"
                      style={
                        this.state.copyProjectName ? { display: "none" } : {}
                      }
                    >
                      Project Name is mandatory
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group row mb-0">
                  <label
                    htmlFor="copyProjectSiteName"
                    className="col-sm-9 col-form-label ml-3"
                  >
                    Enter new site name
                  </label>
                </div>
                <div className="form-group mb-0">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control form-control-sm"
                      id="copyProjectSiteName"
                      name="copyProjectSiteName"
                      maxlength="50"
                      value={this.state.copyProjectSiteName}
                      onChange={this.handleCopyProjectChange}
                    />
                    <div
                      className="invalid-feedback-custom"
                      style={
                        this.state.copyProjectSiteName
                          ? { display: "none" }
                          : {}
                      }
                    >
                      Site Name is mandatory
                    </div>
                  </div>
                </div>
              </div>
              <br></br>
            </se-dialog-content>
            <se-dialog-footer>
              <se-button onClick={this.closeCopyProjectPopUp}>Cancel</se-button>
              <se-button
                onClick={this.copyProjectConfirm}
                disabled={[
                  this.state.copyProjectName,
                  this.state.copyProjectSiteName,
                ].includes("")}
              >
                OK
              </se-button>
            </se-dialog-footer>
          </se-dialog>

          <se-dialog
            id="dialog-create-project"
            open={this.state.createProjectPopupStatus}
            can-backdrop="false"
            size="large"
          >
            {/* <se-loading loading={this.state.loadingProjectDelete}></se-loading> */}
            <se-dialog-header>Create Project</se-dialog-header>
            <se-dialog-content className="se-dialog-content">
              {Object.keys(this.state.projectTypes).length !== 0 ? (
                <div className="form-group mb-0 px-3 d-flex flex-row flex-nowrap " style={{ gap: "10px" }} >
                  <div className="form-group">
                  <span>Select Your Region : </span>
                  <select
                    className="form-control "
                    style={{ width: "200px" }}
                    id="exampleFormControlSelect1"
                    name="sortProjectOption"
                    value={this.state.createProject.region}
                    onChange={this.onChangeMicrogridType}
                  >
                    {/* <option value="IEC" disabled selected hidden>
                      IEC
                    </option> */}
                    {Object.keys(this.state.projectTypes).map((project) => (
                      <option key={project} value={project}>{project}</option>
                    ))}
                  </select>
                  </div>
                  {this.state.createProject.region && (
                    <div className="form-group">
                    <span> Choose Application : </span>
                    <select
                      className="form-control "
                      style={{ width: "200px" }}
                      id="exampleFormControlSelect1"
                      name="sortProjectOption"
                      value={this.state.createProject.application}
                      onChange={this.onChangeSubProjectType}
                    >
                      <option value="Buildings" disabled selected hidden>
                        Buildings
                      </option>
                      {this.state.projectTypes[
                        this.state.createProject.region
                      ]?.filter((item, index, self) => {

                        return index === self.findIndex((t) => (
                          t.application === item.application
                        ))
                      }).map((projectType, index) => (
                        <option
                          key={projectType.application}
                          value={projectType.application}
                        // disabled={projectType.status === "D" ? true : false}
                        >
                          {projectType.application}
                        </option>
                      ))}
                    </select>
                    </div>  
                  )}

                  {this.state.createProject.application && (
                    <div className="form-group">
                    <span> Energy Management System : </span>
                    <select
                      className="form-control  "
                      style={{ width: "270px" }}
                      id="exampleFormControlSelect1"
                      name="sortProjectOption"
                      value={this.state.createProject.energyManagementSystem}
                      onChange={this.onChangeEnergyManagementSystem}
                    >
                      {/* <option value="Autogrid Flex" disabled selected >
                        Autogrid Flex
                      </option> */}

                      {this.state.projectTypes[
                        this.state.createProject.region
                      ]?.filter((item, index, self) => {

                        return item.application === this.state.createProject.application
                      }).map((projectType, index) => (
                       <>
                        <option
                          key={projectType.energyManagementSystem}
                          value={projectType.energyManagementSystem}
                          disabled={projectType.status === "D" ? true : false}
                        >
                          {projectType.energyManagementSystem}
                        </option></>
                      ))}


                    </select>
                    </div>
                  )}
                </div>
              ) : (
                <se-loading
                  loading={
                    Object.keys(this.state.projectTypes).length === 0
                      ? true
                      : false
                  }
                ></se-loading>
              )}
            </se-dialog-content>

            <se-dialog-footer>
              <se-button onClick={this.closeCreateProjectPopUp}>
                Cancel
              </se-button>
              <se-button onClick={this.createProject}>OK</se-button>
            </se-dialog-footer>
          </se-dialog>
        </se-container>
      </div>
    );
  }
}

export default withRouter(ProjectHome);
