import React, { useState } from "react";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import TERMS_OF_USE from "../docs/terms-of-use.pdf";

// import _ from "lodash";

import _ from 'lodash'
import {Document, Page, pdfjs} from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const LegalDocument = () => {
  const [pageCount, setPageCount] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPageCount(numPages);
  };
  const renderPages = () => {
    return _.times(pageCount, index => {
      return (
        <Page
          key={index}
          pageIndex={index}
          width={800}
          loading=""
          error={
            <se-icon size="xlarge">connection_cloud_connection_error</se-icon>
          }
          noData={
            <se-icon size="xlarge">connection_cloud_connection_error</se-icon>
          }
        />
      );
    });
  };

  return (
    <div className="legal-document">
      <div className="document-container">
        <div className="document">
          <Document
            file={TERMS_OF_USE}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<se-loading loading="true" />}
            error={
              <se-icon size="xlarge">connection_cloud_connection_error</se-icon>
            }
            noData={
              <se-icon size="xlarge">connection_cloud_connection_error</se-icon>
            }
            renderMode="svg"
          >
            {renderPages()}
          </Document>
        </div>
      </div>
    </div>
  );
};

export default LegalDocument;
