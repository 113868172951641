
import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";
import embLabels from "../../config/emb-labels-en.json";
import { useSelector } from "react-redux";

const SLDEcc = () => {
  const {
    architectureType,
    clientType,
    rectangleWidth,
    CBAssetList,
    siteType,
    switchboardType,
    loadSide,
    projectTypeValue,
    coordinates: { busbarCordinates },
  } = useContext(DesignAssetContext);
// This code is working for DaaS IEC , EXISTING SWITCH BOARD and NEW SWITCH BOARD
  return (
    <>
      {(["GRID_CONNECTED_GSX","GRID_CONNECTED"].includes(siteType)
       &&
       (switchboardType ==="" || switchboardType === embLabels.EXISTING_SWITCHBOARD)) ? (
        <svg>
          <g id="ECC1">
            <svg>
              <g
                id="ECC1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="ECC1" transform="translate(-532.000000, -514.000000)">
                  <g
                    id="SLD/Infos/Microgrid_Background"
                    transform="translate(532.000000, 514.000000)"
                  >
                    <rect
                      id="Rectangle-Copy-24"
                      stroke="#333333"
                      strokeWidth="3"
                      x="98"
                      y={loadSide==="LOAD_SIDE"?"180":"185"}
                      width="375"
                      height="195.3"
                      rx="16"
                    />
                    <text
                      id="AAA"
                      fontFamily="Nunito-Regular, Nunito"
                      fontSize="18"
                      fontWeight="normal"
                      fill="#333333"
                    >
                      <tspan x="280" y={loadSide==="LOAD_SIDE"?"360":"370"}>
                        Existing Switchboard
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </svg>
          </g>
          <g id="ECC2">
            <svg>
              <g
                id="ECC2"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="ECC2" transform="translate(-532.000000, -514.000000)">
                  <g
                    id="SLD/Infos/Microgrid_Background"
                    transform="translate(532.000000, 514.000000)"
                  >
                    <rect
                      id="Rectangle-Copy-24"
                      stroke="#333333"
                      strokeWidth="3"
                      x="500"
                      y={loadSide==="LOAD_SIDE"?"372":"332"}
                    
                      width={
                        // (CBAssetList[3].uiData.status===true?(CBAssetList[4].uiData.status===true?(CBAssetList[5].uiData.status===true?"675":"550"):"420"):"390")
                        rectangleWidth === 0 || rectangleWidth === null
                          ? 325
                          : rectangleWidth
                      }
                      height="200.3"
                      rx="16"
                    />
                    <text
                      id="AAA"
                      fontFamily="Nunito-Regular, Nunito"
                      fontSize="18"
                      fontWeight="normal"
                      fill="#333333"
                    >
                      <tspan x="720" y={loadSide==="LOAD_SIDE"?"560":"520"}>
                        Energy Control Center
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </svg>
          </g>
        </svg>
      ) : (
        <svg>
          <g id="ECC3">
            <svg>
              <g
                id="ECC3"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="ECC3" transform="translate(-532.000000, -514.000000)">
                  <g
                    id="SLD/Infos/Microgrid_Background"
                    transform="translate(532.000000, 514.000000)"
                  >
                    <rect
                      id="Rectangle-Copy-24"
                      stroke="#333333"
                      strokeWidth="3"
                      x="40"
                      y="195"
                      // width={
                      //   // architectureType === embLabels.PV_GENSET_ATS ? "310" : clientType === "GREENSTRUXURE" ? "550" : "400"
                      //   (CBAssetList[3].uiData.status===true?(CBAssetList[4].uiData.status===true?(CBAssetList[5].uiData.status===true?"850":"700"):"580"):"625")
                      // }
                      width={
                        rectangleWidth === 0 || rectangleWidth === null
                          ? 538
                          : rectangleWidth
                      }
                      height="205"
                      rx="16"
                    />
                    <text
                      id="AAA"
                      fontFamily="Nunito-Regular, Nunito"
                      fontSize="18"
                      fontWeight="normal"
                      fill="#333333"
                    >
                      <tspan x="380" y="385">
                        Energy Control Center
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </svg>
          </g>
        </svg>
      )}
    </>
  );
};

export default SLDEcc;