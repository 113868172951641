import React, { useContext, useEffect } from "react";

import LocationFinder from "mg-ui-location";
import ProjectUsers from "./ProjectUsers";
import { InformationPageContext } from "../CustomHooks/ContextApi";

import embLabels from "../../config/emb-labels-en.json";
import Tooltip from "../../utils/Tooltip";

const LANGUAGE = "en";
const CUSTOM_LABELS = LocationFinder.getLabelsForLanguage(LANGUAGE);

const SiteInformation = ({siteCreated,projectStatusData}) => {
  const {
    UserScope,
    siteType,
    siteInformation,
    informationToolTIP,
    locationData, 
    mandatoryMsgDisplay,
    renderLocationComponent,
    handlSiteInformationChange,
    setSiteInformation,
    locationCallback,
    disableValidateProject,
    onBackButtonClick,
    onValidateClick,
    clientType,
  } = useContext(InformationPageContext);

  useEffect(() => {
    if (projectType?.tag === "A2E_PROJECT") {
      setSiteInformation((prevSiteInformation) => ({
        userInterface: "none",
      gridNominalFrequency: 50,
      gridNominalVoltage: 400,
      }))
    }
  }, []);

  let projectType = JSON.parse(localStorage.getItem("projectType"));

  const projectTypeTag = projectType?.tag;
  
  return (
    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background">
      <div className="row">
        <div
        className={`col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-3 ${UserScope?.access.saveProjects && projectStatusData?.projectStatus === "COMPLETED" || siteCreated ? "se-block-disabled" : ""}`}
        >
          <se-icon color="primary">building_flat</se-icon>
          <span className="ml-2 se-font-14px-bold se-life-green">
            Site Information
          </span>
          <div className="dropdown-divider"></div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
              <div className="form-group row">
                <label
                  htmlFor="inputEmail3"
                  className="col-sm-2 col-form-label text-right"
                >
                  Local User Interface <span className="se-mandatory">*</span>
                </label>
                <div className="col-sm-10">
                  <select
                    className="custom-select"
                    name="userInterface"
                    value={siteInformation.userInterface}
                    onChange={handlSiteInformationChange}
                  >
                   

                    <option value="none">None</option>
                    {(siteType !== "GENSET_PVBESS_A2E"
                     && !( siteType === "GRID_CONNECTED" && projectType?.projectTypeValue === "MGaaS ANSI")) && (
                        <option value="12">12" HMI Screen</option>
                      )}
                      { projectType?.projectTypeValue === "MGaaS IEC" &&  <option value="15">15" HMI Screen</option>}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputEmail10"
                  className="col-sm-4 col-form-label text-right"
                >
                  Grid nominal frequency (Hz){" "}
                  <span className="se-mandatory">*</span>
                </label>
                <div className="col-sm-8">
                  {projectTypeTag === "A2E_PROJECT" ||  projectType?.projectTypeValue === "MGaaS IEC" ?
                    <select
                      className="custom-select"
                      name="gridNominalFrequency"
                      value={siteInformation.gridNominalFrequency }
                      onChange={handlSiteInformationChange}
                    >
                      
                      <option value="50">50 Hz</option>
                      <option value="60">60 Hz</option>
                    </select> :
                    <input
                      type="text"
                      autoComplete="none"
                      className="form-control form-control-md"
                      name="gridNominalFrequency"
                      placeholder="Grid nominal frequency (V)"
                      maxLength="3"
                      value={siteInformation.gridNominalFrequency}
                      onChange={handlSiteInformationChange}
                    />}
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
              {projectTypeTag === "A2E_PROJECT" || clientType === "A2E_PROJECT" ? (
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail10"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Grid nominal voltage (V)
                    <span className="se-mandatory">*</span>
                  </label>
                  <div className="col-sm-8">
                    <select
                      className="custom-select"
                      name="gridNominalVoltage"
                      placeholder="Grid nominal voltage (V)"
                      value={siteInformation.gridNominalVoltage}
                      onChange={handlSiteInformationChange}
                    >
                      <option value={380}>380 V</option>
                      <option value={400} >400 V</option>
                      <option value={415}>415 V</option>
                    </select>
                  </div>
                </div>
              ) : (
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail11"
                    className="col-sm-3 col-form-label text-right"
                  >
                    Grid nominal voltage (V)
                    <span className="se-mandatory">*</span>
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      autoComplete="none"
                      className="form-control form-control-md"
                      name="gridNominalVoltage"
                      placeholder="Grid nominal voltage (V)"
                      maxLength="3"
                      value={siteInformation.gridNominalVoltage}
                      onChange={handlSiteInformationChange}
                    />
                    <div
                      className="invalid-feedback-custom"
                      style={
                        siteInformation.gridNominalVoltage !== ""
                          ? { display: "none" }
                          : {}
                      }
                    >
                      Grid nominal voltage(V) is required.
                    </div>
                    {siteInformation.gridNominalVoltage !== "" &&
                      (parseInt(siteInformation.gridNominalVoltage) < 100 ||
                        parseInt(siteInformation.gridNominalVoltage) > 700) && (
                        <div className="invalid-feedback-custom">
                          Grid nominal voltage should be in range(100-700 V).
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
              <span className="se-font-14px-bold"> Address</span>
              <Tooltip
            position="right"
            iconColor="secondary"
            iconSize="small"
            // information="Select grid-tied functions and/or offgrid functions depending on
            // your site selection."
            information ={informationToolTIP?.address}
          />
            </div>
          </div>

          {renderLocationComponent && (
            <LocationFinder
              locationData={locationData}
              locationDataCallback={locationCallback}
              mandatoryMsgDisplay={mandatoryMsgDisplay}
              customLabels={CUSTOM_LABELS}
            />
          )}
        </div>
      </div>
      <ProjectUsers />

      {/* //*validation */}

      <div 
      className={`row mt-3 ${UserScope?.access.saveProjects && projectStatusData?.projectStatus === "COMPLETED" || siteCreated ? "se-block-disabled" : ""}`}
      >
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12  text-right">
          <se-button option="outline" onClick={onBackButtonClick}>
            Cancel
          </se-button>

          <se-button
            option="flat"
            color="primary"
            disabled={UserScope.access.saveProjects ? false : true}
            onClick={!disableValidateProject ? onValidateClick : undefined}
          >
            Validate
          </se-button>
        </div>
      </div>
    </div>
  );
};

export default SiteInformation;
