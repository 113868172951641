import React from "react";

import embLabels from "../../config/emb-labels-en.json";
import isTouchDevice from "../SE-Component/Tooltip";
import Tooltip from "../../utils/Tooltip";

const alphanumericRegWithHyphen = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const LicenceKeys = ({
  UserScope,
  gridSetting,
  unlockDeploy,
  siteType,
  architectureType,
  keyExpert,
  keyOperations,
  handleLicenseChange,
  validateExpertKey,
  validateOpertaionsKey,
  projectProgressStatus,
}) => {
  return (
    <div
      className={`row mt-2 justify-content-center se-white-background deploy-border`}
    >
      <div className="col-md-12 mt-2 text-center">
        <span className="se-font-14px-bold ">
          Enter licence keys for unlocking deployment of the solution
        </span>
      </div>
    

      {
        <div
          className={
              "col-md-5"
          }
        >
          {(
            <div className="license__comingSoon mt-2 mx-auto text-center">
              Contact Schneider Electric for implementation.
            </div>
          )}

          <div className="form-group justify-content-center">
            <div className="row">
              <label
                htmlFor="inputEmail3"
                className="col-sm-12 col-form-label "
              >
                <span className="se-life-green se-font-14px-bold">
                  Enter <b>Activation  code</b> for unlocking deployment of the solution
                </span>
                <span className="se-mandatory">*</span>
                <Tooltip
                    position ="right"                   
                    iconColor ="secondary"                    
                    information ="Enter activation code to unlock project for deployment"
                    />
               
              </label>
            </div>
            <div className="row">
              <div className="col-sm-9">
                <input
                  type="text"
                  autoComplete="off"
                  disabled = {projectProgressStatus?.projectStatus === "IN_PROGRESS"}
                  className="form-control form-control-md"
                  name="operationKey"
                  placeholder="NNNNNNNN-XXXX-NNNN-MMMM-NNNNNNNNNNNN"
                  maxLength="36"
                  value={keyOperations.operationKey}
                  
                  onChange={handleLicenseChange}
                />
                {keyOperations.operationKey === "" &&
                  keyOperations.KeyErrorStatusOperations && (
                    <div className="invalid-feedback-custom">
                      Licence Key is required.
                    </div>
                  )}
              </div>
              <div className="col-sm-3">
                <se-button 
                  option="flat"
                  disabled={alphanumericRegWithHyphen.test( keyOperations.operationKey) && projectProgressStatus?.projectStatus !== "IN_PROGRESS"  ? false : true}
                  onClick={validateOpertaionsKey}
                >
                  Submit 
                </se-button>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default LicenceKeys;
