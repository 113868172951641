import React, { useState } from "react";
import embLabels from "../../config/emb-labels-en.json";

const RoutingIpcTable = (props) => {
  const {
    data,
    ipcData,
    secureData,
    handleChange,
    enableMetricTextBox,
    componentId,
    deleteRowData,
    updateIpcRowData,
    isValidIp,
    cancelRowData,
  } = props;

  const newData = { ...data };
  const [selectedVlanName, setSelectedVlanName] = useState(
    newData?.vlanToRoute
  );
  const [selectedInterface, setSelectedInterface] = useState(
    newData?.interfaceVal
  );
  const [selectedMetricValues, setSelectedMetricValues] = useState(
    newData?.metric
  );
  const [isDelete, setIsDelete] = useState(true);
  const cancelUpdateHandler = () => {
    cancelRowData(data);
    setSelectedMetricValues(data.metric);
  };
  const deleteRowDataHandler = () => deleteRowData(data);
  return (
    <>
      <tr className="text-center">
        <td>
          {data.isModifiable ? (
            <select
              className="col-sm-12 col-lg-12 col-md-12 col-xl-12  form-control form-control-sm"
              name="vlanToRoute"
              value={selectedVlanName}
              onChange={(e) => {
                setSelectedVlanName(e.target.value);
                enableMetricTextBox(newData.id, newData);
                handleChange(data, e);
                setIsDelete(false);
              }}
            >
              {secureData?.map((vlanData) => (
                <option key={vlanData.id} value={vlanData.vlan}>
                  {vlanData.vlan}
                </option>
              ))}
            </select>
          ) : (
            data.vlanToRoute
          )}
        </td>
        <td>
          {data.isModifiable
            ? secureData?.filter((data) => data.vlan === selectedVlanName)[0]
                ?.networkAddress
            : data.destinationIpAddress}
        </td>
        <td>{data.cidr}</td>
        <td>
          {data.isModifiable ? (
            <select
              className="col-sm-12 col-lg-12 col-md-12 col-xl-12  form-control form-control-sm"
              name="interfaceVal"
              value={selectedInterface}
              onChange={(e) => {
                enableMetricTextBox(newData.id, newData);
                handleChange(data, e);
                setSelectedInterface(e.target.value);
                setIsDelete(false);
              }}
            >
              <option value="Eth1">Eth1</option>
              <option value="Eth2">Eth2</option>
            </select>
          ) : (
            data.interfaceVal
          )}
        </td>
        <td>
          {
            ipcData?.filter(
              (data) => data.interfaceVal === selectedInterface
            )[0]?.gateWayViaVlan
          }
        </td>
        {/* <td>{
            data.isModifiable?<select
            className="col-sm-12 col-lg-12 col-md-12 col-xl-12  form-control form-control-sm"
            value={selectedMetricValues[data.id] || ''}
            onChange={(e) => handleMetricSelection(e, data.id)}
          >
            <option value={selectedMetricValues[data.id]}>{selectedMetricValues[data.id] || 'select a metric value'}</option>
            {metricArray
              .filter((n) => !Object.values(selectedMetricValues).includes(n) &&( ![100,200].includes(n)))
              .map((n) => (
                <option key={n} value={n}>
                  {n}
                </option>
              ))}
          </select>:data?.metric
            }</td> */}
        <td width="15%">
          <span
            className={newData.metric ? "pointer" : ""}
            title={newData.metric ? "Click to edit" : ""}
            onClick={() =>
              data.isModifiable
                ? enableMetricTextBox(newData.id, newData)
                : undefined
            }
            style={componentId[data.id] ? { display: "none" } : {}}
          >
            {newData.metric}
          </span>
          {componentId[data.id] && (
            <div className="form-group">
              <input
                type="text"
                autoComplete="none"
                className={"form-control form-control-sm "}
                id="exampleFormControlInput22"
                name="metric"
                maxLength="3"
                placeholder="Enter metric value"
                value={selectedMetricValues}
                onChange={(e) => {
                  setSelectedMetricValues(e.target.value);
                  handleChange(newData, e);
                }}
              />

              <div
                className="invalid-feedback-custom"
                style={selectedMetricValues === "" ? {} : { display: "none" }}
              >
                Metric value is required.
              </div>

              {data.metric !== "" ? (
                <div
                  className="invalid-feedback-custom"
                  style={
                    isValidIp && selectedMetricValues != ""
                      ? {}
                      : { display: "none" }
                  }
                >
                  Already used this metric value.
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </td>
        <td>
          {componentId[data.id] ? (
            <>
              <se-icon
                size="medium"
                color="primary"
                title="Click to update"
                onClick={
                  selectedMetricValues !== "" && !isValidIp
                    ? updateIpcRowData
                    : undefined
                }
                disabled={
                  selectedMetricValues !== "" && !isValidIp ? false : true
                }
              >
                notification_ok_stroke
              </se-icon>

              <se-icon
                size="medium"
                title="Click to Cancel"
                onClick={cancelUpdateHandler}
                style={{ color: "red" }}
                //disabled={isDelete}
              >
                action_delete_cross
              </se-icon>
            </>
          ) : (
            <div className="d-flex flex-row justify-content-center">
              <span>
                <se-icon size="medium" disabled={true}>
                  notification_ok_stroke
                </se-icon>
              </span>
              {data.isModifiable && (
                <span className="pointer pt-1 ml-2">
                  <se-icon
                    size="small"
                    title="delete row"
                    color="primary"
                    onClick={deleteRowDataHandler}
                  >
                    action_delete
                  </se-icon>
                </span>
              )}
            </div>
          )}
        </td>
      </tr>
    </>
  );
};

export default RoutingIpcTable;
