import React from "react";

const TableHeading = () => (
  <table className="table table-bordered mb-0">
    <thead>
      <tr>
        <th width="13%">Name</th>
        <th width="10%">Asset Type</th>
        <th width="15%">
          IP Address<span className="se-mandatory">*</span>
        </th>
        <th width="15%">
          Mask<span className="se-mandatory">*</span>
        </th>
        <th width="15%">
          Gateway Address
          <span className="se-mandatory">*</span>
        </th>
        <th width="12%">
          Modbus Slave ID
          <span className="se-mandatory">*</span>
        </th>
        <th width="10%">Comments</th>
        <th width="10%" className="text-center">
          Action
        </th>
      </tr>
    </thead>
  </table>
);

export default TableHeading;
