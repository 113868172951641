import React, { useContext } from "react";
import useCBMouserOver from "../../pages/CustomHooks/useCBMouserOver";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";
import embLabels from "../../config/emb-labels-en.json"
import MyErrorBoundary from "../../utils/ErrorBoundary";
const BatteryExsisting = () => {
  const {
    CBAssetList: [, ,  { uiData, powerMeterData, cbConfigData }],
    CBAssetListDispatch,
    UserScope,
    pv1SelectHandler,
    newSBCbConfigDataHandler,
    assetDetailsReset,
    selectedValuesHandler,
    pmConfigDataHandler,
    padNumberFormat,
    PVDetails,
    callSnackbar,
    siteType,
    switchboardType,
    loadSide,
    existingPmConfigDataHandler

  } = useContext(DesignAssetContext);

  const [mouseOverData, CBMouseHandler] = useCBMouserOver();

  const pv1PmSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      // const assetBlock = "cbAssetDetailsBlock";
      const assetBlock = "existingPmAssetDetailsBlockGrid";
      const selectedAssetValue = "selectedAssetPM";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_PM_SELECT_EXISTING,
        payload: {
          index: 2,
        },
      });
      selectedValuesHandler(2, selectedAssetValue, assetBlock);
      // pmConfigDataHandler(2);
      existingPmConfigDataHandler(2);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

 
  return (
    <MyErrorBoundary>
      
   <>
   {["ISLANDABLE_GSX","ISLANDABLE"].includes(siteType)  &&
     ( switchboardType ==="" ||switchboardType === embLabels.EXISTING_SWITCHBOARD) &&(
      <svg className="pointer">
      <g id="pv1">
        <svg
          x={"0"}
          y={loadSide==="LOAD_SIDE"? "73":"45"}
          width="195px"
          height="130px"
          viewBox="0 0 10 149"
       
        >
          <title>
            {/* {PVDetails.pvset1Data[0] ? PVDetails.pvset1Data[0].groupName : ""} */}
          </title>
          <g
            id="Design"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Design/Assets-_-00"
              transform="translate(-1036.000000, -372.000000)"
            >
              <g
                id="SLD/PV/Uniline/Multiple_Done"
                transform="translate(1036.000000, 372.000000)"
              >
               
                <circle
                  id="Oval"
                  stroke={ "#333333"}
                  strokeWidth="3"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                  cx="57"
                  cy="50"
                  r="45.5"
                ></circle>
 
                <line
                  x1="55"
                  y1="95.287109"
                  x2="55"
                  y2="144"
                  id="Line-2"
                  stroke={ "#333333"}
                  strokeWidth="3"
                  strokeLinejoin="round"
                ></line>

              
                <text
                  id="solar_panel1"
                  fontFamily="se-icon"
                  fontSize="52"
                  fontWeight="normal"
                  fill="#0F0F0F"
                  stroke="#333333"
                >
                  <tspan x="31" y="72">
                  battery2_general
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </svg>
      </g>
      <g id="CB90">
        <line
          x1="141"
          x2="141"
          y1={loadSide==="LOAD_SIDE"?"200":"175"}
          y2={loadSide==="LOAD_SIDE"?"300":"285"}
          id="Line-2"
          strokeWidth="3"
          strokeDasharray="4 8"
          strokeLinecap="round"
          stroke="#333333"
        ></line>
      </g>
      <g id="power meter">
        <svg
          x="10"
          y={loadSide==="LOAD_SIDE"?"60":"30"}
        
          width="300px"
          height="140px"
          viewBox="0 0 100 160"
          onClick={pv1PmSelectHandler}
        >
          <title>{powerMeterData.name}</title>
 
          <line
            x1="30"
            y1="127"
            x2="80"
            y2="127"
            stroke={uiData.pmConfiguredGrid ? "#333" : "#E47F00"}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle
            id="Pm outer"
            stroke="#42B4E6"
            strokeWidth="3"
            cx="100"
            cy="127"
            r="26"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="rgba(0, 135, 205, 0.2)"
            style={uiData.existingPmSelected ? {} : { display: "none" }}
          ></circle>
          <circle
            id="pm inner"
            stroke={uiData.pmConfiguredGrid ? "#333" : "#E47F00"}
            strokeWidth="3"
            cx="100"
            cy="127"
            r="18.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="#FFFFFF"
          ></circle>
          <text
            id="PM"
            fontSize="16"
            fontWeight="normal"
            stroke="#333"
            fontFamily="Nunito-Regular, Nunito"
            fill="#333"
          >
            <tspan x="88" y="132">
              PM
            </tspan>
          </text>
        </svg>
      </g>
     </svg>)
     
   }
   </>
    </MyErrorBoundary>
  );
};

export default BatteryExsisting;
