
import React, { useContext } from "react";

import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";
import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";
import useCBMouserOver from "../../pages/CustomHooks/useCBMouserOver";
import embLabels from "../../config/emb-labels-en.json";

const Utility = () => {
  const {
    CBAssetList: [
      { uiData, configData, powerMeterData, cbConfigData },
      ,
      ,
      { uiData: pvExistingUiData },
    ],
    UserScope,
    assetDetailsDisplay, 
    CBAssetListDispatch,
    assetDetailsReset,
    callSnackbar,
    selectedValuesHandler,
    assetConfigDataHandler,
    setAssetDetailsDisplay,
    pmConfigDataHandler,
    newSBCbConfigDataHandler,
    siteType,
    switchboardType,
    loadSide,
    detailsBlock,
  } = useContext(DesignAssetContext);

  const [mouseOverData, CBMouseHandler] = useCBMouserOver();

  const utilitySelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "assetUtilityDetailsBlock";
      const selectedAssetValue = "selectedAsset";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_SELECT,
        payload: {
          index: 0,
          assetDetailsDisplay,
          setAssetDetailsDisplay,
        },
      });
      selectedValuesHandler(0, selectedAssetValue, assetBlock);
      assetConfigDataHandler(0);
    } else {
      callSnackbar(
        "error",
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const utilityPMSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      // const assetBlock = "cbAssetDetailsBlock";
      const assetBlock = "pmAssetDetailsBlockGrid";
      const selectedAssetValue = "selectedAssetPM";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_PM_SELECT,
        payload: {
          index: 0,
        },
      });
      selectedValuesHandler(0, selectedAssetValue, assetBlock);
      pmConfigDataHandler(0);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const utilityCBSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      // const assetBlock = "cbAssetDetailsBlock";
      const assetBlock = "newSBCbAssetDetailsBlock";
      const selectedAssetValue = "selectedAssetCB";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_CB_SELECT,
        payload: {
          index: 0,
        },
      });
      selectedValuesHandler(0, selectedAssetValue, assetBlock);
      // cbConfigDataHandler(4);
      newSBCbConfigDataHandler(0);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const hardWiredCbSelect = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "hardWiredCbDetailsBlockGrid";
      const selectedAssetValue = "";
      selectedValuesHandler(null, selectedAssetValue, assetBlock);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };
  return (
    <>
      {["GRID_CONNECTED_GSX","GRID_CONNECTED"].includes(siteType) &&
      (switchboardType === "" ||
        switchboardType === embLabels.EXISTING_SWITCHBOARD) ? (
        <svg className="pointer">
          <g id="utility">
            <svg
              x={
                loadSide === "LOAD_SIDE"
                  ? pvExistingUiData.existingPmRequired
                    ? "284"
                    : "124"
                  : pvExistingUiData.existingPmRequired
                  ? "284"
                  : uiData.xAxis
              }
              y={loadSide === "LOAD_SIDE" ? "0" : uiData.yAxis}
              width="222px"
              height="262px"
              viewBox="0 0 60 330"
              onClick={utilitySelectHandler}
            >
              <title>{configData.name}</title>
              <g
                id="Design"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Design/Assets-_-00"
                  transform="translate(-604.000000, -372.000000)"
                >
                  <g
                    id="SLD/Production/Uniline/Undefined"
                    transform="translate(604.000000, 372.000000)"
                  >
                    <g id="SLD/Production/Uniline/Tobedefine">
                      <g id="Group-7">
                        <rect
                          id="Rectangle"
                          x="0"
                          y="0"
                          width="96"
                          height="144"
                        ></rect>
                        <line
                          x1="48.5"
                          y1="96"
                          x2="48.5"
                          y2="223"
                          id="Line-2"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          stroke={uiData.configured ? "#333" : "#E47F00"}
                        ></line>

                        <circle
                          id="Oval"
                          style={uiData.selected ? {} : { display: "none" }}
                          stroke="#42B4E6"
                          strokeWidth="3"
                          cx="48"
                          cy="56"
                          r="55"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          fill="rgba(0, 135, 205, 0.2)"
                        ></circle>
                        <circle
                          id="Oval"
                          strokeWidth="3"
                          fill="#FFFFFF"
                          cx="48"
                          cy="56"
                          r="46.5"
                          stroke={uiData.configured ? "#333" : "#E47F00"}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></circle>

                        <text
                          id="segment_energy_infra"
                          fontFamily="se-icon"
                          fontSize="56"
                          fontWeight="normal"
                          fill="#333333"
                        >
                          <tspan x="20" y="76">
                            segment_energy_infrastructure
                          </tspan>
                        </text>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </g>

          <g id="power meter" className="pointer">
            <svg
              x="195"
              y={"0"}
              width="600px"
              height="440px"
              viewBox="0 0 600 490"
            >
              <title>PR</title>

              <circle
                id="pm inner"
                stroke="#333"
                strokeWidth="3"
                cx={pvExistingUiData.existingPmRequired ? "205" : "28"}
                cy="127"
                r="7"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="#ffffff"
              ></circle>

              <line
                x1={pvExistingUiData.existingPmRequired ? "212" : "37"}
                y1="127"
                x2="410"
                y2="127"
                stroke="#333"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />

              <line
                x1="410"
                y1={"127"}
                // y1="208.5"
                x2="410"
                y2={loadSide === "LOAD_SIDE" ? "460" : "400"}
                stroke="#333333"
                strokeWidth="3"
              />
              <circle
                id="Pm outer"
                stroke="#42B4E6"
                strokeWidth="3"
                cx="410"
                cy={loadSide === "LOAD_SIDE" ? "460" : "420"}
                r="26"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="rgba(0, 135, 205, 0.2)"
                style={
                  detailsBlock.hardWiredCbDetailsBlockGrid
                    ? {}
                    : { display: "none" }
                }
              ></circle>
              <circle
                id="pm inner"
                stroke="#333"
                strokeWidth="3"
                cx="410"
                cy={loadSide === "LOAD_SIDE" ? "460" : "420"}
                r="18.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="#FFFFFF"
              ></circle>
              <text
                id="PM"
                fontSize="16"
                fontWeight="normal"
                stroke="#333"
                fontFamily="Nunito-Regular, Nunito"
                fill="#333"
                onClick={hardWiredCbSelect}
              >
                <tspan x="400" y={loadSide === "LOAD_SIDE" ? "465" : "425"}>
                  PR
                </tspan>
              </text>
            </svg>
          </g>

          <g id="power meter" style={uiData.pmRequired ? {} : { display: "none"}}>
            <svg
              x={pvExistingUiData.existingPmRequired ? "280" : "120"}
              y={loadSide === "LOAD_SIDE" ? "35.2" : "45.2"}
              width="300px"
              height="140px"
              viewBox="0 0 100 160"
              onClick={utilityPMSelectHandler}
            >
              <title>{powerMeterData.name}</title>
              <line
                x1="30"
                y1="127"
                x2="80"
                y2="127"
                stroke={uiData.pmConfiguredGrid ? "#333":"#E47F00"}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle
                id="Pm outer"
                stroke="#42B4E6"
                strokeWidth="3"
                cx="100"
                cy="127"
                r="26"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="rgba(0, 135, 205, 0.2)"
                style={uiData.pmSelected ? {} : { display: "none" }}
              ></circle>
              <circle
                id="pm inner"
                stroke={uiData.pmConfiguredGrid ? "#333":"#E47F00"}
                strokeWidth="3"
                cx="100"
                cy="127"
                r="18.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="#FFFFFF"
              ></circle>
              <text
                id="PM"
                fontSize="16"
                fontWeight="normal"
                stroke="#333"
                fontFamily="Nunito-Regular, Nunito"
                fill="#333"
              >
                <tspan x="88" y="132">
                  PM
                </tspan>
              </text>
            </svg>
          </g>
        
          <g id="CB1">
            <svg
              width="75px"
              height="100px"
              viewBox="0 0 10 149"
              x={pvExistingUiData.existingPmRequired ? "375" : uiData.xAxisCB}
              y={loadSide === "LOAD_SIDE" ? "180" : uiData.yAxisCB}
              onClick={utilityCBSelectHandler}
            >
              <title>{cbConfigData.name}</title>
              <g id="Circuit Breaker" fill="none" fillRule="evenodd">
                <g
                  id="Circuit Breaker 1"
                  transform="translate(-651.000000, -512.000000)"
                  onMouseOver={CBMouseHandler}
                  onMouseOut={CBMouseHandler}
                >
                  <g
                    id="Circuit Breaker - closed"
                    transform="translate(604.000000, 514.000000)"
                    fill={uiData.CBSelected
                      ? "rgba(65, 181, 230, 0.35)"
                      : mouseOverData
                        ? "rgba(65, 181, 230, 0.35)"
                        : "none"}
                    stroke={uiData.CBSelected ? "#42B4E6" : "none"}
                  >
                    <g id="Group-15" strokeWidth="4" fillRule="evenodd">
                      <rect
                        id="Rectangle-Copy-20"
                        x="0"
                        y="12"
                        width="96"
                        height="122"
                      ></rect>
                      <g
                        id="Group-2"
                        transform="translate(48.000000, 0.000000)"
                        stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="4"
                      >
                        <line
                          x1="0.5"
                          y1="0"
                          x2="0.5"
                          y2="47.8298611"
                          id="Line-2"
                        ></line>
                      </g>
                    </g>
                    <line
                      x1="48.5"
                      y1="97.1701389"
                      x2="48.501111"
                      y2="145"
                      id="Line-2-Copy-15"
                      stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></line>
                    <path
                      d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                      id="Path-11"
                      stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                      strokeWidth="4"
                      strokeLinecap="round"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
          </g>
          
        </svg>
      ) : (
        <svg className="pointer" >
          <g id="utility">
            <svg
              x="24"
              y="65"
              width="220px"
              height="130px"
              viewBox="0 0 60 149"
              onClick={utilitySelectHandler}
            >
              <title>{configData.name}</title>
              <g
                id="Design"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Design/Assets-_-00"
                  transform="translate(-604.000000, -372.000000)"
                >
                  <g
                    id="SLD/Production/Uniline/Undefined"
                    transform="translate(604.000000, 372.000000)"
                  >
                    <g id="SLD/Production/Uniline/Tobedefine">
                      <g id="Group-7">
                        <rect
                          id="Rectangle"
                          x="0"
                          y="0"
                          width="96"
                          height="144"
                        ></rect>
                        <line
                          x1="48.5"
                          y1="96"
                          x2="48.5"
                          y2="149"
                          id="Line-2"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          stroke={uiData.configured ? "#333" : "#E47F00"}
                        ></line>

                        <circle
                          id="Oval"
                          style={uiData.selected ? {} : { display: "none" }}
                          stroke="#42B4E6"
                          strokeWidth="3"
                          cx="48"
                          cy="56"
                          r="55"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          fill="rgba(0, 135, 205, 0.2)"
                        ></circle>
                        <circle
                          id="Oval"
                          strokeWidth="3"
                          fill="#FFFFFF"
                          cx="48"
                          cy="56"
                          r="46.5"
                          stroke={uiData.configured ? "#333" : "#E47F00"}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></circle>

                        <text
                          id="segment_energy_infra"
                          fontFamily="se-icon"
                          fontSize="56"
                          fontWeight="normal"
                          fill="#333333"
                        >
                          <tspan x="20" y="76">
                            segment_energy_infrastructure
                          </tspan>
                        </text>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </g>
          <g id="power meter" style={uiData.pmRequired ? {} : { display: "none"}}>
            <svg
              x="20"
              y="55"
              width="300px"
              height="140px"
              viewBox="0 0 100 160"
              onClick={utilityPMSelectHandler}
            >
              <title>{powerMeterData.name}</title>
              <line
                x1="30"
                y1="127"
                x2="80"
                y2="127"
                stroke={uiData.pmConfiguredGrid ? "#333":"#E47F00"}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle
                id="Pm outer"
                stroke="#42B4E6"
                strokeWidth="3"
                cx="100"
                cy="127"
                r="26"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="rgba(0, 135, 205, 0.2)"
                style={uiData.pmSelected ? {} : { display: "none" }}
              ></circle>
              <circle
                id="pm inner"
                stroke={uiData.pmConfiguredGrid ? "#333":"#E47F00"}
                strokeWidth="3"
                cx="100"
                cy="127"
                r="18.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="#FFFFFF"
              ></circle>
              <text
                id="PM"
                fontSize="16"
                fontWeight="normal"
                stroke="#333"
                fontFamily="Nunito-Regular, Nunito"
                fill="#333"
              >
                <tspan x="88" y="132">
                  PM
                </tspan>
              </text>
            </svg>
          </g>
          {/* {(uiData.cbRequired) ? */}
          <g id="CB1">
            <svg
              width="75px"
              height="108px"
              viewBox="0 0 10 149"
              x="115"
              y="193"
              onClick={utilityCBSelectHandler}
            >
              <title>{cbConfigData.name}</title>
              <g id="Circuit Breaker" fill="none" fillRule="evenodd">
                <g
                  id="Circuit Breaker 1"
                  transform="translate(-651.000000, -512.000000)"
                  onMouseOver={CBMouseHandler}
                  onMouseOut={CBMouseHandler}
                >
                  <g
                    id="Circuit Breaker - closed"
                    transform="translate(604.000000, 514.000000)"
                    fill={
                      uiData.CBSelected
                        ? "rgba(65, 181, 230, 0.35)"
                        : mouseOverData
                        ? "rgba(65, 181, 230, 0.35)"
                        : "none"
                    }
                    stroke={uiData.CBSelected ? "#42B4E6" : "none"}
                  >
                    <g id="Group-15" strokeWidth="4" fillRule="evenodd">
                      <rect
                        id="Rectangle-Copy-20"
                        x="0"
                        y="12"
                        width="96"
                        height="122"
                      ></rect>
                      <g
                        id="Group-2"
                        transform="translate(48.000000, 0.000000)"
                        stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="4"
                      >
                        <line
                          x1="0.5"
                          y1="0"
                          x2="0.5"
                          y2="47.8298611"
                          id="Line-2"
                        ></line>
                      </g>
                    </g>
                    <line
                      x1="48.5"
                      y1="97.1701389"
                      x2="48.501111"
                      y2="145"
                      id="Line-2-Copy-15"
                      stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></line>
                    <path
                      d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                      id="Path-11"
                      stroke={uiData.CBConfigured ? "#333333" : "#E47F00"}
                      strokeWidth="4"
                      strokeLinecap="round"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
          </g>
        {/* //   :
        //   <g id="CB1">
        //   <line
        //       x1="150.5"
        //       y1="200"
        //       x2="150.5"
        //       y2="300"
        //     id="Line-2"
        //     strokeWidth="3"
        //     strokeDasharray="4 8"
        //     strokeLinecap="round"
        //     stroke="#333333"
        //   // strokeLinejoin="round"
        //   ></line>
        // </g>
        //   } */}
        </svg>
      )}
    </>
  );
};

export default Utility;