import React, { useState } from "react";
import { useSelector } from "react-redux";
import MultiSelectionDropdown from "../../components/component/MultiSelectionDropdown";


const MoxaCommunicationTable = ({
  data,
  handleSelectMemberType,
  handleAccessEnable,
  handleSelectAccessMember,
  getHybridMembers,
  dropdownValues,
  communicationType,
}) => {
  const newData = { ...data };
  
  const [selectedMemberType, setSelectedMemberType] = useState({
    memberType:newData.memberType,
    port:newData.port,
  }
    

  );
  const [accessEnable, setAccessEnable] = useState(newData.enable);
  const [port, setPort] = useState('');
  const [selectedAccessMember, setSelectedAccessMember] = useState(
    newData?.accessMembers?.vlan
  );
  const [selectedTrunkMember, setSelectedTrunkMember] = useState(newData);
  const secureData = useSelector((state) => state.secureArchitectureData.data);

 const getTrunkMembers = (members, port) => {
  dropdownValues(members,port,communicationType)
  }
  
  return (
    <tr className="text-center">
      <td width={"8%"}>{newData.port}</td>
      <td width={"8%"}>
      <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input asCompatible"
            name="vlanOptions"
            id={`vlanOptionsValues ${newData.id}`}
            value={accessEnable}
            checked={accessEnable}
            onChange={(e) => {
            setAccessEnable(e.target.checked);
            
            handleAccessEnable(e.target.checked, newData.port,communicationType);
          }}
          />
          <label
            className="custom-control-label line-height-25"
            htmlFor={`vlanOptionsValues ${newData.id}`}
          >
            
        </label>
         
        </div>
      </td>
      <td width={"10%"}>
        <select
          className="col-sm-12 col-lg-12 col-md-12 col-xl-12  form-control form-control-sm"
          name="memberType"
          value={selectedMemberType.memberType}
          onChange={(event) => {
            let member = event.target.value;
            handleSelectMemberType(member, newData.port,communicationType);
            setSelectedMemberType((prev)=>({
              ...prev,
              memberType:member,
              port:newData.port
            }));
          }}
        >
          <option value="ACCESS">ACCESS</option>
          <option value="HYBRID_TRUNK">HYBRID_TRUNK</option>
        </select>
      </td>
      <td width={"12%"}>
        <select
          className="col-sm-12 col-lg-12 col-md-12 col-xl-12  form-control form-control-sm"
          name="accessMember"
          value={selectedAccessMember}
          onChange={(event) => {
            handleSelectAccessMember(event, newData.port,communicationType);
            setSelectedAccessMember(event.target.value);
          }}
        >
          {secureData.securedCommunicationArchitecture.map((data)=>(
            <option key={data.id} value={data.vlan}>{data.vlan}</option>
          ))}
        
        </select>
      </td>
      <td width={"12%"} className="text-center">
        {selectedMemberType.memberType === "HYBRID_TRUNK" && (
          <MultiSelectionDropdown
            secureData={secureData}
            trunkMembers={selectedTrunkMember}
            setSelectedTrunkMember = {setSelectedTrunkMember}
            getHybridMembers={getHybridMembers}
            getTrunkMembers={getTrunkMembers}
            dropdownValues={dropdownValues}
            selectedMemberType ={selectedMemberType}
          />
        )}
      </td>
    </tr>
  );
};

export default MoxaCommunicationTable;
