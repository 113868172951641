import React from "react";
import PropTypes from "prop-types";
import MyErrorBoundary from "../../utils/ErrorBoundary";

const AccordionSection = ({
  isOpen,
  displayStatus,
  status,
  label,
  onClick,
  children,
  innerClick,
}) => {
  const onClickLabel = () => {
    onClick(label);
  };
  const clickInner = e => {
    if (!e) {
      e = window.event;
    }
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    innerClick();
  };

  return (
    <MyErrorBoundary>

    <div
      className={
        isOpen
          ? "accordionOpen accordionBorder mb-1"
          : "accordionClose accordionBorder mb-1"
      }
      style={displayStatus === "1" ? {} : { display: "none" }}
      
    >
      <div
        onClick={onClickLabel}
        className="col-md-12"
        style={{ cursor: "pointer" }}
      >
        <div className="row align-items-center">
          <div
            id="dc-tariff"
            data-test-id = "tariff-data"
            className={` text-left ${
              [ " Static Tariff Configuration"].includes(label) && isOpen
                ? "col-md-5"
                : "col-md-9"
            }`}
          >
            {!isOpen && <se-icon size="nano">arrow3_down</se-icon>}
            {isOpen && <se-icon size="nano">arrow3_up</se-icon>}
            &nbsp;{label}
          </div>
          {[ " Static Tariff Configuration"].includes(label) && isOpen && (
            <div className="col-md-4 text-left">
              <span onClick={clickInner}>
                <span>Select template</span>
                <se-icon size="nano">arrow3_down</se-icon>
              </span>
            </div>
          )}

          <div className="col-md-2 text-right">
            <span>
              {status === 1
                ? "Configured"
                : status === 2
                ? "Modified"
                : "Not configured yet"}
            </span>
          </div>
          <div className="col-md-1 text-right">
            {!isOpen && (
              <se-icon size="medium">
                electricity_polarity_positive_plus
              </se-icon>
            )}
            {isOpen && (
              <se-icon size="medium">
                electricity_polarity_negative_minus
              </se-icon>
            )}
          </div>
        </div>
      </div>
      {isOpen && <div className="accordionOpen">{children}</div>}
    </div>
    </MyErrorBoundary>
  );
};

export default AccordionSection;

AccordionSection.propTypes = {
  children: PropTypes.instanceOf(Object),
  isOpen: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  displayStatus: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};
