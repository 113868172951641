import React, { useContext } from "react";

import embLabels from "../../config/emb-labels-en.json";
import { InformationPageContext } from "../CustomHooks/ContextApi";
import Tooltip from "../../utils/Tooltip";

const TransitionType = () => {
  const {
    siteType,
    disabledFeatures,
    gridSettings,
    handleTransitionTypeChange,
    informationToolTIP,
  } = useContext(InformationPageContext);

  return (
    <div
      className={
        siteType === embLabels.OFF_GRID
          ? "col-sm-5 col-md-5 col-lg-5 col-xl-5  se-block-disabled "
          : disabledFeatures.disableOpertaions
          ? "col-sm-5 col-md-5 col-lg-5 col-xl-5  se-block-disabled border-left"
          : gridSettings.mode === ""
          ? "col-sm-5 col-md-5 col-lg-5 col-xl-5"
          : "col-sm-5 col-md-5 col-lg-5 col-xl-5 border-left "
      }
    >
      <div className="row">
        {gridSettings.mode && (
          <div className="col-md-12">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ml-0 mt-2 mb-1">
                <span>Transition Type </span>
                <Tooltip
                          position="right"                          
                          iconColor="secondary"
                          information={informationToolTIP?.transitionType}
                        />
              </div>
            </div>

            <div className="row ml-1">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                <span>On grid to Off grid </span>
              </div>
            </div>

            <div className="row ml-3">
              <div className="col-sm-7 col-md-7 col-lg-7 col-xl-7 ">
                <label className="line-height-25">
                  {" "}
                  Transition on bad grid detection
                </label>
              </div>
              <div className="col-sm-5 col-md-5 col-lg-5 col-xl-5 pr-0 pl-0">
              
                <div
                  className={`btn-group btn-group-toggle `}
                  data-toggle="buttons"
                >
                  <label
                    className={`btn btn-secondary  btn-sm ${
                      gridSettings.gridTransitionOutage === "OPEN"
                        ? " active toggle-se-active"
                        : " toggle-se-inactive"
                    }`}
                  >
                    <input
                      type="radio"
                      name="gridTransitionOutage"
                      id="option1"
                      value="OPEN"
                      autoComplete="none"
                      checked={gridSettings.gridTransitionOutage === "OPEN"}
                      onChange={handleTransitionTypeChange}
                    />{" "}
                    Open
                  </label>
                  <label
                    className={`btn btn-secondary  btn-sm ${
                      gridSettings.gridTransitionOutage === "FAST"
                        ? " active toggle-se-active"
                        : " toggle-se-inactive"
                    } ${siteType =="ISLANDABLE_GSX"? "d-none":"" }`}
                  >
                    <input
                      type="radio"
                      name="gridTransitionOutage"
                      id="option2"
                      value="FAST"
                      autoComplete="none"
                      checked={gridSettings.gridTransitionOutage === "FAST"}
                      onChange={handleTransitionTypeChange}
                    />{" "}
                    Fast
                  </label>
                </div>
              </div>
            </div>

            <div className="row ml-3">
              <div className="col-sm-7 col-md-7 col-lg-7 col-xl-7 ">
                <label className="line-height-25">
                  Transition on manual trigger
                </label>
              </div>
              <div className=" col-sm-5 col-md-5 col-lg-5 col-xl-5 pr-0 pl-0">
                <div
                  className={`btn-group btn-group-toggle  `}
                  data-toggle="buttons"
                >
                  <label
                    className={`btn btn-secondary  btn-sm ${
                      gridSettings.manualSwitch === "OPEN"
                        ? " active toggle-se-active"
                        : " toggle-se-inactive"
                    }`}
                  >
                    <input
                      type="radio"
                      name="manualSwitch"
                      id="option1"
                      value="OPEN"
                      autoComplete="none"
                      checked={gridSettings.manualSwitch === "OPEN"}
                      onChange={handleTransitionTypeChange}
                    />{" "}
                    Open
                  </label>
                  <label
                    className={`btn btn-secondary  btn-sm ${
                      gridSettings.manualSwitch === "FAST"
                        ? " active toggle-se-active"
                        : " toggle-se-inactive"
                    } ${siteType =="ISLANDABLE_GSX"? "d-none":"" }`}
                  >
                    <input
                      type="radio"
                      name="manualSwitch"
                      id="option2"
                      value="FAST"
                      autoComplete="none"
                      checked={gridSettings.manualSwitch === "FAST"}
                      onChange={handleTransitionTypeChange}
                    />{" "}
                    Fast
                  </label>
                  <label
                    className={`btn btn-secondary  btn-sm ${
                      gridSettings.manualSwitch === "CLOSE"
                        ? " active toggle-se-active"
                        : " toggle-se-inactive"
                    } ${siteType =="ISLANDABLE_GSX"? "d-none":"" }`}
                  >
                    <input
                      type="radio"
                      name="manualSwitch"
                      id="option3"
                      value="CLOSE"
                      autoComplete="none"
                      checked={gridSettings.manualSwitch === "CLOSE"}
                      onChange={handleTransitionTypeChange}
                    />{" "}
                    Close
                  </label>
                </div>
              </div>
            </div>

            <div className="row ml-1">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                <span>Off grid to On grid </span>
              </div>
            </div>

            <div className="row ml-3">
              <div className="col-sm-7 col-md-7 col-lg-7 col-xl-7">
                <label className="line-height-25">
                  Transition when grid is back or manual trigger
                </label>
              </div>
              <div className="col-sm-5 col-md-5 col-lg-5 col-xl-5 pr-0 pl-0">
                <div
                  className={`btn-group btn-group-toggle  `}
                  data-toggle="buttons"
                >
                  <label
                    className={`btn btn-secondary  btn-sm ${
                      gridSettings.gridTransitionOnline === "OPEN"
                        ? " active toggle-se-active"
                        : " toggle-se-inactive"
                    }`}
                  >
                    <input
                      type="radio"
                      name="gridTransitionOnline"
                      id="option1"
                      value="OPEN"
                      autoComplete="none"
                      checked={gridSettings.gridTransitionOnline === "OPEN"}
                      onChange={handleTransitionTypeChange}
                    />{" "}
                    Open
                  </label>
                  <label
                    className={`btn btn-secondary  btn-sm ${
                      gridSettings.gridTransitionOnline === "FAST"
                        ? " active toggle-se-active"
                        : " toggle-se-inactive"
                    } ${siteType =="ISLANDABLE_GSX"? "d-none":"" }` }
                  >
                    <input
                      type="radio"
                      name="gridTransitionOnline"
                      id="option2"
                      value="FAST"
                      autoComplete="none"
                      checked={gridSettings.gridTransitionOnline === "FAST"}
                      onChange={handleTransitionTypeChange}
                    />{" "}
                    Fast
                  </label>
                  <label
                    className={`btn btn-secondary  btn-sm ${
                      gridSettings.gridTransitionOnline === "CLOSE"
                        ? " active toggle-se-active"
                        : " toggle-se-inactive"
                    } `}
                  >
                    <input
                      type="radio"
                      name="gridTransitionOnline"
                      id="option3"
                      value="CLOSE"
                      autoComplete="none"
                      checked={gridSettings.gridTransitionOnline === "CLOSE"}
                      onChange={handleTransitionTypeChange}
                    />{" "}
                    Close
                  </label>
                </div>
              </div>
            </div>

            {gridSettings.mode === "FUEL_ERASER" && (
              <div className="row ml-3">
                <div className="col-sm-7 col-md-7 col-lg-7 col-xl-7 ">
                  <label className="line-height-25">
                    Genset to/from Battery
                  </label>
                </div>
                <div className="col-sm-5 col-md-5 col-lg-5 col-xl-5 pr-0 pl-0">
                  <div
                    className="btn-group btn-group-toggle"
                    data-toggle="buttons"
                  >
                    <label
                      className={`btn btn-secondary  btn-sm ${
                        gridSettings.gensetBattery === "OPEN"
                          ? " active toggle-se-active"
                          : " toggle-se-inactive"
                      }`}
                    >
                      <input
                        type="radio"
                        name="gensetBattery"
                        id="option1"
                        value="OPEN"
                        autoComplete="none"
                        checked={gridSettings.gensetBattery === "OPEN"}
                        onChange={handleTransitionTypeChange}
                      />{" "}
                      Open
                    </label>
                    <label
                      className={`btn btn-secondary  btn-sm ${
                        gridSettings.gensetBattery === "FAST"
                          ? " active toggle-se-active"
                          : " toggle-se-inactive"
                      }`}
                    >
                      <input
                        type="radio"
                        name="gensetBattery"
                        id="option2"
                        value="FAST"
                        autoComplete="none"
                        checked={gridSettings.gensetBattery === "FAST"}
                        onChange={handleTransitionTypeChange}
                      />{" "}
                      Fast
                    </label>
                    <label
                      className={`btn btn-secondary  btn-sm ${
                        gridSettings.gensetBattery === "CLOSE"
                          ? " active toggle-se-active"
                          : " toggle-se-inactive"
                      }`}
                    >
                      <input
                        type="radio"
                        name="gensetBattery"
                        id="option3"
                        value="CLOSE"
                        autoComplete="none"
                        checked={gridSettings.gensetBattery === "CLOSE"}
                        onChange={handleTransitionTypeChange}
                      />{" "}
                      Close
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TransitionType;
