import React, { memo } from "react";

const AlertIcon = ({ alert, onClose }) => {
  return (
    <se-dialog
      id="AlertDialog"
      open={alert.alertPopUpStatus}
      can-backdrop="false"
    >
      <se-dialog-header>{alert.title}</se-dialog-header>
      <se-dialog-content
        icon="information_circle"
        color="primary"
        className="se-header-font-size-16px se-life-green"
      >
        {alert.message}
      </se-dialog-content>
      <se-dialog-footer>
        <se-button onClick={onClose}>OK</se-button>
      </se-dialog-footer>
    </se-dialog>
  );
};

export default memo(AlertIcon);
