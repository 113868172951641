import React from "react";

const SystemSupervisionTable = props => {
  const {
    data,
    systemCommunicationData,
    componentId,
    enableTextBoxSystem,
    communicationState,
    handleChange,
    isValidIp,
    cancelUpdate,
    saveSystemCommunication,
  } = props;

  const enableTextBoxSystemHandler = () => enableTextBoxSystem(data.id);
  const cancelUpdateHandler = () => cancelUpdate(data.id);
  const saveSystemCommunicationHandler = () => saveSystemCommunication(data.id);

  return (
    <tr>
      <td width="13%">{data.name}</td>
      <td width="10%">{data.assetType}</td>
      <td width="15%">
        <span
          className={systemCommunicationData[data.id].modifiable && "pointer"}
          title={systemCommunicationData[data.id].modifiable && "Click to edit"}
          onClick={
            systemCommunicationData[data.id].modifiable &&
            enableTextBoxSystemHandler
          }
          style={componentId[data.id] ? { display: "none" } : {}}
        >
          {systemCommunicationData[data.id].ipAddress
            ? systemCommunicationData[data.id].ipAddress
            : "NA"}
        </span>
        <div
          className="form-group"
          style={componentId[data.id] ? {} : { display: "none" }}
        >
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput2"
            name="ipAddress"
            maxLength="15"
            value={communicationState.ipAddress}
            placeholder="Ip address"
            onChange={handleChange}
          />
          <div
            className="invalid-feedback-custom"
            style={
              communicationState.ipAddress === "" ? {} : { display: "none" }
            }
          >
            Ip address is required.
          </div>
          {communicationState.ipAddress !== "" && (
            <div
              className="invalid-feedback-custom"
              style={
                isValidIp(communicationState.ipAddress) === false
                  ? {}
                  : { display: "none" }
              }
            >
              Invalid Ip address.
            </div>
          )}
        </div>
      </td>
      <td width="15%">
        <span
          className={systemCommunicationData[data.id].modifiable && "pointer"}
          title={systemCommunicationData[data.id].modifiable && "Click to edit"}
          onClick={
            systemCommunicationData[data.id].modifiable &&
            enableTextBoxSystemHandler
          }
          style={componentId[data.id] ? { display: "none" } : {}}
        >
          {systemCommunicationData[data.id].mask
            ? systemCommunicationData[data.id].mask
            : "NA"}
        </span>
        <div
          className="form-group"
          style={componentId[data.id] ? {} : { display: "none" }}
        >
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput2"
            name="mask"
            maxLength="15"
            value={communicationState.mask}
            placeholder="mask"
            onChange={handleChange}
          />
          <div
            className="invalid-feedback-custom"
            style={communicationState.mask === "" ? {} : { display: "none" }}
          >
            Mask is required.
          </div>
          {communicationState.mask !== "" ? (
            <div
              className="invalid-feedback-custom"
              style={
                isValidIp(communicationState.mask) === false
                  ? {}
                  : { display: "none" }
              }
            >
              Invalid mask.
            </div>
          ) : (
            ""
          )}
        </div>
      </td>
      <td width="15%">
        <span
          className={systemCommunicationData[data.id].modifiable && "pointer"}
          title={systemCommunicationData[data.id].modifiable && "Click to edit"}
          onClick={
            systemCommunicationData[data.id].modifiable &&
            enableTextBoxSystemHandler
          }
          style={componentId[data.id] ? { display: "none" } : {}}
        >
          {systemCommunicationData[data.id].gateway
            ? systemCommunicationData[data.id].gateway
            : "NA"}
        </span>
        <div
          className="form-group"
          style={componentId[data.id] ? {} : { display: "none" }}
        >
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput2"
            name="gatewayAddress"
            maxLength="15"
            value={communicationState.gatewayAddress}
            placeholder="Gateway address"
            onChange={handleChange}
          />
          <div
            className="invalid-feedback-custom"
            style={
              communicationState.gatewayAddress === ""
                ? {}
                : { display: "none" }
            }
          >
            Gateway address is required.
          </div>
          {communicationState.gatewayAddress !== "" && (
            <div
              className="invalid-feedback-custom"
              style={
                isValidIp(communicationState.gatewayAddress) === false
                  ? {}
                  : { display: "none" }
              }
            >
              Invalid Gateway address.
            </div>
          )}
        </div>
      </td>
      <td width="12%">
        {systemCommunicationData[data.id].modbusSlave
          ? systemCommunicationData[data.id].modbusSlave
          : "NA"}
      </td>
      <td width="10%">
        <span
          className={systemCommunicationData[data.id].modifiable && "pointer"}
          title={systemCommunicationData[data.id].modifiable && "Click to edit"}
          onClick={
            systemCommunicationData[data.id].modifiable &&
            enableTextBoxSystemHandler
          }
          style={componentId[data.id] ? { display: "none" } : {}}
        >
          {systemCommunicationData[data.id].comment
            ? systemCommunicationData[data.id].comment
            : "NA"}
        </span>

        <div
          className="form-group"
          style={componentId[data.id] ? {} : { display: "none" }}
        >
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput2"
            name="comments"
            maxLength="50"
            value={communicationState.comments}
            placeholder="Comments"
            onChange={handleChange}
          />
        </div>
      </td>
      <td width="10%" className="text-center">
        <div className="pointer">
          {componentId[data.id] ? (
            <se-icon
              size="medium"
              color="primary"
              title="Click to update"
              disabled={
                communicationState.ipAddress === "" ||
                communicationState.mask === "" ||
                communicationState.gatewayAddress === ""
                  ? true
                  : false
              }
              onClick={
                communicationState.ipAddress === "" ||
                communicationState.mask === "" ||
                communicationState.gatewayAddress === ""
                  ? ""
                  : saveSystemCommunicationHandler
              }
            >
              notification_ok_stroke
            </se-icon>
          ) : (
            <div>
              <se-icon size="medium" disabled="true">
                notification_ok_stroke
              </se-icon>
            </div>
          )}

          {componentId[data.id] && (
            <se-icon
              size="medium"
              title="Click to cancel"
              onClick={cancelUpdateHandler}
            >
              action_delete_cross
            </se-icon>
          )}
        </div>
      </td>
    </tr>
  );
};

export default SystemSupervisionTable;
