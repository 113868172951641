import React, { Fragment } from "react";
import PropTypes from "prop-types";
import MyErrorBoundary from "../../utils/ErrorBoundary";

// *******************************************************
// RAIL
// *******************************************************
const railOuterStyle = {
  width: "100%",
  height: 50,
  transform: "translate(0%, -50%)",
  borderRadius: 7,
  cursor: "pointer",
  marginTop: 10,
  // border: '1px solid white',
};

const railInnerStyle = {
  position: "absolute",
  width: "100%",
  height: 40,
  transform: "translate(0%, -50%)",
  borderRadius: 7,
  pointerEvents: "none",
  backgroundColor: "#333333", 
};

export function SliderRail({ getRailProps }) {
  return (
    <Fragment>
      <div style={railOuterStyle} />
      {/* <div style={railInnerStyle} {...getRailProps()} /> */}
      <div style={railInnerStyle} {...getRailProps} />
    </Fragment>
  );
}

SliderRail.propTypes = {
  getRailProps: PropTypes.func.isRequired,
};

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
export function Handle({
  domain: [min, max],
  handle: { id, value, percent },
  disabled,
  getHandleProps,
}) {
  return (
    <MyErrorBoundary>
      
    <Fragment>
      <div
        style={{
          left: `${percent}%`,
          position: "absolute",
          transform: "translate(-50%, -50%)",
          WebkitTapHighlightColor: "rgba(0,0,0,0)",
          zIndex: 5,
          width: 28,
          height: 42,
          cursor: "pointer",
          // border: '1px solid white',
          backgroundColor: "none",
        }}
        {...getHandleProps(id)}
      />
      <div
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        style={{
          left: `${percent}%`,
          position: "absolute",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
          width: 24,
          height: 24,
          borderRadius: "50%",
          boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.3)",
          backgroundColor: disabled ? "#666" : "#ffc400",
        }}
      />
    </Fragment>
    </MyErrorBoundary>
  );
}

Handle.propTypes = {
  domain: PropTypes.array.isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Handle.defaultProps = {
  disabled: true,
};

// *******************************************************
// KEYBOARD HANDLE COMPONENT
// Uses a button to allow keyboard events
// *******************************************************
export function KeyboardHandle({
  domain: [min, max],
  handle: { id, value, percent },
  disabled,
  getHandleProps,
  values,
  index,
}) {
  let properValue = "";
  properValue = values[index];
  return (
    <MyErrorBoundary>
      
    <div
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      style={{
        left: `${percent}%`,
        position: "absolute",
        transform: "translate(-50%, -50%)",
        zIndex: 2,
        backgroundColor: "#FFFFFF",
        borderRadius: "100%",
        width: 16,
        height: 16,
        cursor: "pointer",
      }}
      {...getHandleProps(id)}
    >
      <div
        style={{
          borderLeft: "1px solid #ffffff",
          height: 40,
          marginTop: -12,
          marginLeft: 8,
          zIndex: 1,
        }}
      ></div>

      <div
        style={
          id === "$$-0"
            ? {
                position: "absolute",
                fontSize: 10,
                marginTop: -92,
                textAlign: "left",
                left: `${percent}%`,
                width: "90px",
              }
            : id === "$$-1"
            ? {
                position: "absolute",
                fontSize: 10,
                marginTop: -62,
                textAlign: "left",
                left: `${percent}%`,
                width: "90px",
              }
            : id === "$$-2"
            ? {
                position: "absolute",
                fontSize: 10,
                marginTop: -72,
                textAlign: "left",
                left: `${percent}%`,
                width: "90px",
              }
            : id === "$$-3"
            ? {
                position: "absolute",
                fontSize: 10,
                marginTop: -82,
                textAlign: "left",
                left: `${percent}%`,
                width: "90px",
              }
            : id === "$$-4"
            ? {
                position: "absolute",
                fontSize: 10,
                marginTop: -92,
                textAlign: "left",
                left: `${percent}%`,
                width: "90px",
              }
            : ""
        }
      >
        <span>
          {id === "$$-1" ? "SoC C" : ""}
          {id === "$$-2" ? "SoC B" : ""}
          {id === "$$-3" ? "SoC A" : ""}
        </span>

        <div style={id === "$$-0" || id === "$$-4" ? {} : { display: "none" }}>
          {id === "$$-0" ? "SoC Min" : "SoC Max"}
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          fontSize: 10,
          marginTop: 10,
          textAlign: "left",
          left: `${percent}%`,
          width: "90px",
        }}
      >
        {properValue}%
      </div>
    </div>
    </MyErrorBoundary>
  );
}

KeyboardHandle.propTypes = {
  domain: PropTypes.array.isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

KeyboardHandle.defaultProps = {
  disabled: true,
};

// *******************************************************
// TRACK COMPONENT
// *******************************************************
export function Track({ source, target, getTrackProps, disabled }) {
  return (
    <MyErrorBoundary>
      
      <div
        style={{
          position: "absolute",
          transform: "translate(0%, -50%)",
          height: 40,
          zIndex: 1,
          backgroundColor: disabled
            ? "#999"
            : source.id === "$$-0"
            ? "#DC0A0A"
            : source.id === "$$-1"
            ? "#E47F00"
            : source.id === "$$-2"
            ? "#9FA0A4"
            : source.id === "$$-3"
            ? "#CBCBCB"
            : "#333333",
          borderRadius: 0,
          cursor: "pointer",
          left: `${source.percent}%`,
          width: `${target.percent - source.percent}%`,
        }}
        {...getTrackProps()}
      />
    </MyErrorBoundary>
  );
}

Track.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  target: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getTrackProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Track.defaultProps = {
  disabled: false,
};

// *******************************************************
// TICK COMPONENT
// *******************************************************
export function Tick({ tick, count, format }) {
  return (
    <div>
      {/* <div
        style={{
          position: 'absolute',
          marginTop: 14,
          width: 1,
          height: 5,
          backgroundColor: 'rgb(200,200,200)',
          left: `${tick.percent}%`,
        }}
      /> */}
      {/* <div
        style={{
          position: 'absolute',
          marginTop: 22,
          fontSize: 10,
          textAlign: 'center',
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {format(tick.value)}-{count}
      </div> */}
    </div>
  );
}

Tick.propTypes = {
  tick: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  count: PropTypes.number.isRequired,
  format: PropTypes.func.isRequired,
};

// Tick.defaultProps = {
//   format: d => d,
// };
