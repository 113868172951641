import React, { useCallback, useContext, useState } from "react";
import { AssetBlockContext } from "../CustomHooks/ContextApi";
import embLabels from "../../config/emb-labels-en.json";
import Tooltip from "../../utils/Tooltip";
import cbImage from "../../images/CB.png";

const CBContactorDetails = ({ detailsBlock }) => {
  const {
    updateCbContactorConfigData,
    configData,
    handleCBConfigChange,
    handleCBContactorConfigChange,
    assetEditStatusUpdateAssetCB,
    selectedValues,
    siteType,
    clientType,
    CBAssetList,
    setAssetSelection,
    CBEVList,
  } = useContext(AssetBlockContext);
  const [sourceImage, setSourceImage] = useState(null);
  const cbConfigChangeHandler = (e) => {
    handleCBContactorConfigChange(e);
  };

  const setAssetHandler = () => {
    setAssetSelection("contactor");
  };

  const isAssetSelected = () => {
    let isAsset;
    let assetInfo = "";
    let assetModel = "";
    if (selectedValues.selectedAssetCB !== "" || selectedValues.selectedEvCB !== "") {
      isAsset = selectedValues.selectedAssetCB!==''?
        CBAssetList[selectedValues.selectedAssetCB]?.contactorConfigData
          ?.cbAssetDetails[0]?.assetId:
          CBEVList[selectedValues.selectedEvCB]?.contactorConfigData
            ?.cbAssetDetails[0]?.assetId;

      assetInfo =selectedValues.selectedAssetCB!==''?
        CBAssetList[selectedValues.selectedAssetCB]?.contactorConfigData
          ?.cbAssetDetails[0]?.assetInfo:
          CBEVList[selectedValues.selectedEvCB]?.contactorConfigData
            ?.cbAssetDetails[0]?.assetInfo;

      assetModel =selectedValues.selectedAssetCB!==''?
        CBAssetList[selectedValues.selectedAssetCB]?.contactorConfigData
          ?.cbAssetDetails[0]?.communicationModel:
          CBEVList[selectedValues.selectedEvCB]?.contactorConfigData
            ?.cbAssetDetails[0]?.communicationModel;
    }

    return [isAsset, assetInfo, assetModel];
  };

  const [isAsset, assetInfo, assetModel] = isAssetSelected();

  const getImage = useCallback(() => {
    if (
      CBAssetList[selectedValues.selectedAssetCB]?.contactorConfigData
        ?.cbAssetDetails[0]?.imageLink
    ) {
      return CBAssetList[selectedValues?.selectedAssetCB]?.contactorConfigData
        .cbAssetDetails[0]?.imageLink;
    } else if (
      CBEVList[selectedValues.selectedEvCB]?.contactorConfigData
        ?.cbAssetDetails[0]?.imageLink
    ) {
      return CBEVList[selectedValues.selectedEvCB]?.contactorConfigData
        ?.cbAssetDetails[0]?.imageLink;
    }
  }, [CBAssetList]);
  
  return (
    <>
      <div className="col-md-12 pt-2 se-white-background mt-2">
        <div className="row border-top">
          <div className="col-md-8 pt-2">
            <img
              src={cbImage}
              className="asset-image-icon"
              alt={"Contactor"}
              onError={(e) => {
                cbImage;
              }}
            />

            <span className="align-top line-height-30px bold">Contactor</span>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-sm-12 pt-2 se-grey-background se-border">
        <div className="row" style={!isAsset ? { display: "none" } : {}}>
          <div className="col-md-3">
            <img
              src={getImage() ?? cbImage}
              className="asset-image-display"
              alt={"Contactor"}
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-12 bold text-uppercase">{""}</div>
            </div>
            <div className="row">
              <div className="col-md-9">Contactor</div>
              <div className="col-md-3 text-right">
                <Tooltip
                  position="left"
                  iconColor="secondary"
                  information={assetInfo}
                />
              </div>
              <div className="col-md-12"> {assetModel}</div>
            </div>
          </div>
        </div>
        <div className="row" style={isAsset ? { display: "none" } : {}}>
          <div className="ml-2 mb-2 asset-selection"> No assets selected</div>
        </div>
        <div className="row">
          <div className=" col-md col-sm col-lg text-center">
            <se-button option="outline" size="small" onClick={setAssetHandler}>
              Asset Selection
            </se-button>
          </div>
        </div>
      </div>

      <div
        className="col-md-12 mt-2 se-white-background align-middle"
        style={!isAsset ? { display: "none" } : {}}
      >
        <div className="row">
          <label className="col-md-6 bold mt-2 mb-2 se-font-14px">
            Asset Settings
          </label>
        </div>
      </div>
      <div
        className="col-md-12 se-grey-background"
        style={!isAsset ? { display: "none" } : {}}
      >
        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">
            Name <span className="se-mandatory">*</span>
          </label>

          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput1"
            placeholder="Name"
            name="name"
            maxLength="50"
            value={
              configData?.contactorConfigData?.name
            }
            onChange={cbConfigChangeHandler}
          />
          <div
            className={`invalid-feedback-custom ${
              configData?.contactorConfigData?.name===""
                ? ""
                : "hidden"
            }`}
          >
            {embLabels.nameRequired}
          </div>
        </div>

        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">Description</label>
          <input
            type="text"
            autoComplete="none"
            className="form-control form-control-sm"
            id="exampleFormControlInput1"
            placeholder="Description"
            name="description"
            maxLength="100"
            value={configData.contactorConfigData.description}
            onChange={cbConfigChangeHandler}
          />
        </div>
      </div>
    </>
  );
};

export default CBContactorDetails;
