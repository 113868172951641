import React, { useContext } from "react";
import isTouchDevice from "../SE-Component/Tooltip";

import { DesignProgramContext } from "../CustomHooks/ContextApi";
import Tooltip from "../../utils/Tooltip";
import { useSelector } from "react-redux";

const TransitionManagement = () => {
  const {
    gridDetails,
    defaultsMinMax,
    handleRadioButtonChange,
    handleChange,
    configuredStatusModified,
  } = useContext(DesignProgramContext);
  const tooltipData = useSelector(state => state?.tooltipData?.tooltipData);
  const gridOutrageDetectionCaseChangeHandler = e => {
    handleRadioButtonChange(e);
    configuredStatusModified("tmConfiguredStatus");
  };

  const gridDetectionChangeHandler = e => {
    handleChange(e);
    configuredStatusModified("tmConfiguredStatus");
  };

  return (
    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-1">
      <div className="dropdown-divider"></div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2 ">
          <span className="bold">Select grid outage detection method</span>
        </div>

        <div className="col-md-2 mb-3">
          <div className="custom-control custom-radio">
            <input
              type="radio"
              id="customRadio3"
              name="gridOutrageDetectionCase"
              className="custom-control-input islandableRadio asCompatible"
              value="CB"
              checked={gridDetails.gridOutrageDetectionCase === "CB"}
              selected={gridDetails.gridOutrageDetectionCase}
              onChange={gridOutrageDetectionCaseChangeHandler}
            />
            <label
              className="custom-control-label  line-height-25px"
              htmlFor="customRadio3"
            >
              CB Measurement
            </label>
            <Tooltip
                position="top"
                iconColor="secondary"
                information=" Grid Outage detection is measured by micrologic control unit
                integrated in circuit breaker."
              />   
           
          </div>
        </div>
        <div className="col-md-10 mb-3 ">
          <div className="custom-control custom-radio">
            <input
              type="radio"
              id="customRadio2"
              name="gridOutrageDetectionCase"
              className="custom-control-input islandableRadio asCompatible"
              value="HWS"
              checked={gridDetails.gridOutrageDetectionCase === "HWS"}
              selected={gridDetails.gridOutrageDetectionCase}
              onChange={gridOutrageDetectionCaseChangeHandler}
            />
            <label
              className="custom-control-label  line-height-25px"
              htmlFor="customRadio2"
            >
              External Hardwired Signal{" "}
            </label>
            <Tooltip
                position="top"
                iconColor="secondary"
                information="Grid Outage detection performed by external protection relay. Information is sent to the EMO controller via dry contacts."
              />   
           
          </div>
        </div>

        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
          <div className="form-group row">
            <label htmlFor="inputEmail11" className="col-sm-6 col-form-label">
              Grid Lost Detection Timer(ms)
              <span className="se-mandatory">*</span>
              <Tooltip
                position="top"
                iconColor="secondary"
                information={tooltipData?.gridLostDetectionTimer?.data}
              />                
            </label>
            <div className="col-sm-6">
              <input
                type="text"
                autoComplete="none"
                className="form-control form-control-md"
                name="lostDetectionTimer"
                placeholder="Grid Lost Detection Timer(ms)"
                maxLength="4"
                value={gridDetails.lostDetectionTimer}
                onChange={gridDetectionChangeHandler}
              />
              <div
                className="invalid-feedback-custom"
                style={
                  gridDetails.lostDetectionTimer !== ""
                    ? { display: "none" }
                    : {}
                }
              >
                Grid Lost Detection Timer(ms) is required.
              </div>

              {gridDetails.lostDetectionTimer !== "" &&
              (parseInt(gridDetails.lostDetectionTimer) <
                defaultsMinMax.gridLostTimerMin ||
                parseInt(gridDetails.lostDetectionTimer) >
                  defaultsMinMax.gridLostTimerMax) ? (
                <div className="invalid-feedback-custom">
                  Grid Lost Detection Timer should be in range(
                  {defaultsMinMax.gridLostTimerMin}-
                  {defaultsMinMax.gridLostTimerMax} ms)
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div className="offset-md-6"></div>

        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
          <div className="form-group row">
            <label htmlFor="inputEmail11" className="col-sm-6 col-form-label">
              Good Grid Detection Timer(s)
              <span className="se-mandatory">*</span>
              <Tooltip
                position="top"
                iconColor="secondary"
                information={tooltipData?.goodGridDetectionTimer?.data}
              />                
            </label>
            <div className="col-sm-6">
              <input
                type="text"
                autoComplete="none"
                className="form-control form-control-md"
                name="gridDetectionTimer"
                placeholder="Good Grid Detection Timer(s)"
                maxLength="4"
                value={gridDetails.gridDetectionTimer}
                onChange={gridDetectionChangeHandler}
              />
              <div
                className="invalid-feedback-custom"
                style={
                  gridDetails.gridDetectionTimer !== ""
                    ? { display: "none" }
                    : {}
                }
              >
                Good Grid Detection Timer(s) is required.
              </div>
              {gridDetails.gridDetectionTimer !== "" &&
              (parseInt(gridDetails.gridDetectionTimer) <
                defaultsMinMax.goodGridDetectionTimerMin ||
                parseInt(gridDetails.gridDetectionTimer) >
                  defaultsMinMax.goodGridDetectionTimerMax) ? (
                <div className="invalid-feedback-custom">
                  Good Grid Detection Timer should be in range(
                  {defaultsMinMax.goodGridDetectionTimerMin}-
                  {defaultsMinMax.goodGridDetectionTimerMax} s)
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransitionManagement;
