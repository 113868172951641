import React from "react";

import embLabels from "../../config/emb-labels-en.json";

const SystemSettingTable = props => {
  
  const {
    data,
    siteType,
    architectureType,
    editedAssetType,
    componentId,
    enableTextBox,
    communicationState,
    handleChange,
    isValidIp,
    isValidSlaveID,
    cancelUpdate,
    saveCommunications,
    saveCommunicationsDer,
    savePowerMeter,
  } = props;
 
  const enableTextHandler = dataAsset => {
    enableTextBox(data.componentId, dataAsset);
  };
  const cancelUpdateHandler = () => cancelUpdate(data.componentId);
  const saveCommunicationHandler = dataAsset =>{
    
    saveCommunications(
      data.componentId,
      data.network,
      data.numberOfDeviceIFE,
      dataAsset
    );
  }
   
  const saveCommunicationDerHandler = dataAsset =>
    saveCommunicationsDer(data.componentId, data.network, dataAsset);
  const savePowerMeterHandler = dataAsset =>
    savePowerMeter(data.componentId, data.network, data.equipment, dataAsset);

  const isSaveButtonDisabled = () => {
    const isDisabled = [
      embLabels.PV_HYBRIDIZATION,
      embLabels.GRID_CONNECTED,
    ].includes(siteType)
      ? communicationState.slaveId === ""
        ? true
        : false
      : siteType === embLabels.ISLANDABLE_GSX
        ? architectureType === embLabels.PV_GENSET_ATS
          ? ["PM_Utility", "PM_Genset"].includes(data.equipment)
            ? communicationState.slaveId === ""
              ? true
              : false
            : [
              communicationState.ipAddress,
              communicationState.mask,
              communicationState.gatewayAddress,
              communicationState.slaveId,
            ].includes("")
              ? true
              : false
          : false
        : false;
    return isDisabled;
  };
  return data.systemSetting.length ? (
    data.systemSetting.map((dataAsset) => (
      <tr key={dataAsset.type}>
        <td width="13%">{data.equipment}</td>
        <td width="10%">
          {" "}
          {data.network === embLabels.HYBRID
            ? `${
                dataAsset.type === "PV"
                  ? "PCU"
                  : dataAsset.type === "BESS"
                  ? "MBMS"
                  : dataAsset.type
              }`
            : data.network === embLabels.BESS
            ? `${
                dataAsset.type === "PCS"
                  ? "BESS - PCU"
                  : dataAsset.type === "MBMS"
                  ? "BESS - MBMS"
                  : dataAsset.type
              }`
            : data.network}
        </td>

        <td width="15%">
          <span
            className={dataAsset.ipAddress ? "pointer" : ""}
            title={dataAsset.ipAddress ? "Click to edit" : ""}
            onClick={
              ["Serial", "Hardwired"].includes(data.componentType)
                ? undefined
                : () => enableTextHandler(dataAsset)
            }
            style={
              componentId[data.componentId] &&
              editedAssetType === dataAsset.type
                ? { display: "none" }
                : {}
            }
          >
            {dataAsset.ipAddress || "NA"}
          </span>
          {componentId[data.componentId] &&
            editedAssetType === dataAsset.type &&
            data.componentType !== "Serial" &&
            data.componentType !== "Hardwired" && (
              <div className="form-group">
                <input
                  type="text"
                  autoComplete="none"
                  className={"form-control form-control-sm "}
                  id="exampleFormControlInput2"
                  name="ipAddress"
                  maxLength="15"
                  placeholder="Ip address"
                  value={communicationState.ipAddress}
                  onChange={(e) => handleChange(e, data?.network)}
                />

                <div
                  className="invalid-feedback-custom"
                  style={
                    communicationState.ipAddress === ""
                      ? {}
                      : { display: "none" }
                  }
                >
                  Ip address is required.
                </div>

                {communicationState.ipAddress !== "" ? (
                  <div
                    className="invalid-feedback-custom"
                    style={
                      isValidIp(communicationState.ipAddress)
                        ? { display: "none" }
                        : {}
                    }
                  >
                    Invalid Ip address.
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
        </td>
        <td width="15%">
          <span
            className={dataAsset.mask ? "pointer" : ""}
            title={dataAsset.mask ? "Click to edit" : ""}
            onClick={
              ["Serial", "Hardwired"].includes(data.componentType)
                ? undefined
                : () => enableTextHandler(dataAsset)
            }
            style={
              componentId[data.componentId] &&
              editedAssetType === dataAsset.type
                ? { display: "none" }
                : {}
            }
          >
            {dataAsset.mask || "NA"}
          </span>
          {componentId[data.componentId] &&
          editedAssetType === dataAsset.type &&
          data.componentType !== "Serial" &&
          data.componentType !== "Hardwired" ? (
            <div className="form-group">
              <input
                type="text"
                autoComplete="none"
                className={"form-control form-control-sm "}
                id="exampleFormControlInput2"
                name="mask"
                maxLength="15"
                value={communicationState.mask}
                placeholder="mask"
                onChange={handleChange}
              />
              <div
                className="invalid-feedback-custom"
                style={
                  communicationState.mask === "" ? {} : { display: "none" }
                }
              >
                Mask is required.
              </div>
              {communicationState.mask !== "" ? (
                <div
                  className="invalid-feedback-custom"
                  style={
                    isValidIp(communicationState.mask) === false
                      ? {}
                      : { display: "none" }
                  }
                >
                  Invalid mask.
                </div>
              ) : (
                ""
              )}
            </div>
          ) : componentId[data.componentId] === true &&
            editedAssetType === dataAsset.type &&
            data.componentType === "Serial" &&
            !dataAsset.mask ? (
            "NA"
          ) : (
            ""
          )}
        </td>
        <td width="15%">
          <span
            className={dataAsset.gatewayAddress ? "pointer" : ""}
            title={dataAsset.gatewayAddress ? "Click to edit" : ""}
            onClick={
              ["Serial", "Hardwired"].includes(data.componentType)
                ? undefined
                : () => enableTextHandler(dataAsset)
            }
            style={
              componentId[data.componentId] &&
              editedAssetType === dataAsset.type
                ? { display: "none" }
                : {}
            }
          >
            {dataAsset.gatewayAddress || "NA"}
          </span>
          {componentId[data.componentId] &&
          editedAssetType === dataAsset.type &&
          data.componentType !== "Serial" &&
          data.componentType !== "Hardwired" ? (
            <div className="form-group">
              <input
                type="text"
                autoComplete="none"
                className={"form-control form-control-sm "}
                id="exampleFormControlInput2"
                name="gatewayAddress"
                maxLength="15"
                value={communicationState.gatewayAddress}
                placeholder="Gateway address"
                onChange={handleChange}
              />
              <div
                className="invalid-feedback-custom"
                style={
                  communicationState.gatewayAddress === ""
                    ? {}
                    : { display: "none" }
                }
              >
                Gateway address is required.
              </div>
              {communicationState.gatewayAddress !== "" ? (
                <div
                  className="invalid-feedback-custom"
                  style={
                    isValidIp(communicationState.gatewayAddress) === false
                      ? {}
                      : { display: "none" }
                  }
                >
                  Invalid Gateway address.
                </div>
              ) : (
                ""
              )}
            </div>
          ) : componentId[data.componentId] &&
            editedAssetType === dataAsset.type &&
            data.componentType === "Serial" &&
            !dataAsset.gatewayAddress ? (
            "NA"
          ) : (
            ""
          )}
        </td>
        <td width="12%">
          <span
            className={dataAsset.slaveId ? "pointer" : ""}
            title={dataAsset.slaveId ? "Click to edit" : ""}
            onClick={
              ["Hardwired"].includes(data.componentType)
                ? undefined
                : () => enableTextHandler(dataAsset)
            }
            style={
              componentId[data.componentId] &&
              editedAssetType === dataAsset.type
                ? { display: "none" }
                : {}
            }
          >
            {dataAsset.slaveId || "NA"}
          </span>
          <div
            className="form-group"
            style={
              componentId[data.componentId] &&
              editedAssetType === dataAsset.type
                ? {}
                : { display: "none" }
            }
          >
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput2"
              name="slaveId"
              maxLength="3"
              value={communicationState.slaveId}
              placeholder="Slave ID"
              onChange={handleChange}
              disabled={data.network === "EV_charging_station"}
            />
            <div
              className="invalid-feedback-custom"
              style={
                communicationState.slaveId === "" ? {} : { display: "none" }
              }
            >
              Slave ID is required.
            </div>
            {communicationState.slaveId !== "" ? (
              <div
                className="invalid-feedback-custom"
                style={
                  isValidSlaveID(communicationState.slaveId) === false
                    ? {}
                    : { display: "none" }
                }
              >
                Invalid Slave ID.
              </div>
            ) : (
              ""
            )}
          </div>
        </td>
        <td width="10%">
          <span
            className={dataAsset.slaveId ? "pointer" : ""}
            title={dataAsset.slaveId ? "Click to edit" : ""}
            onClick={
              ["Hardwired"].includes(data.componentType)
                ? undefined
                : () => enableTextHandler(dataAsset)
            }
            style={
              componentId[data.componentId] &&
              editedAssetType === dataAsset.type
                ? { display: "none" }
                : {}
            }
          >
            {dataAsset.comments || "NA"}
          </span>

          <div
            className="form-group"
            style={
              componentId[data.componentId] &&
              editedAssetType === dataAsset.type
                ? {}
                : { display: "none" }
            }
          >
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput2"
              name="comments"
              maxLength="50"
              value={communicationState.comments}
              placeholder="Comments"
              onChange={handleChange}
            />
          </div>
        </td>
        <td width="10%" className="text-center">
          <div className="pointer">
            {componentId[data.componentId] &&
            editedAssetType === dataAsset.type &&
            data.componentType !== "Serial" &&
            data.componentType !== "Hardwired" &&
            data.network !== "Power Meter" ? (
              <se-icon
                size="medium"
                color="primary"
                title="Click to update"
                disabled={
                  communicationState.ipAddress === "" ||
                  communicationState.mask === "" ||
                  communicationState.gatewayAddress === "" ||
                  communicationState.slaveId === ""
                    ? true
                    : false
                }
                onClick={
                  communicationState.ipAddress === "" ||
                  communicationState.mask === "" ||
                  communicationState.gatewayAddress === "" ||
                  communicationState.slaveId === ""
                    ? ""
                    : () => saveCommunicationHandler(dataAsset)
                }
              >
                notification_ok_stroke
              </se-icon>
            ) : componentId[data.componentId] === true &&
              editedAssetType === dataAsset.type &&
              data.componentType === "Serial" &&
              data.network !== "Power Meter" ? (
              <se-icon
                size="medium"
                color="primary"
                title="Click to update"
                disabled={communicationState.slaveId === "" ? true : false}
                onClick={
                  communicationState.slaveId === ""
                    ? ""
                    : () => saveCommunicationDerHandler(dataAsset)
                }
              >
                notification_ok_stroke
              </se-icon>
            ) : componentId[data.componentId] === true &&
              editedAssetType === dataAsset.type &&
              data.network === "Power Meter" ? (
              <se-icon
                size="medium"
                color="primary"
                title="Click to update"
                disabled={isSaveButtonDisabled()}
                onClick={
                  isSaveButtonDisabled()
                    ? undefined
                    : () => savePowerMeterHandler(dataAsset)
                }
              >
                notification_ok_stroke
              </se-icon>
            ) : (
              <div>
                <se-icon size="medium" disabled="true">
                  notification_ok_stroke
                </se-icon>
              </div>
            )}

            {componentId[data.componentId] &&
            editedAssetType === dataAsset.type ? (
              <se-icon
                size="medium"
                title="Click to cancel"
                onClick={cancelUpdateHandler}
              >
                action_delete_cross
              </se-icon>
            ) : (
              ""
            )}
          </div>
        </td>
      </tr>
    ))
  ) : (
    <tr key={data.network}>
      <td width="13%">{data.equipment}</td>
      <td width="10%">{data.network}</td>
      <td width="15%">NA</td>
      <td width="15%">NA</td>
      <td width="15%">NA</td>
      <td width="12%">NA</td>
      <td width="10%">NA</td>
      <td width="10%" className="text-center">
        <div className="pointer">
          <div>
            <se-icon size="medium" disabled="true">
              notification_ok_stroke
            </se-icon>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default SystemSettingTable;
