import { createSlice } from "@reduxjs/toolkit";
import {call, put, takeLatest} from 'redux-saga/effects'
import ApiSession from "../../api/ApiSession";
const apiSession = new ApiSession();
const initialState = {
   loading:false,
   data:null,
   error:null,
}
export const projectStatusInfoSlice = createSlice({
    name:'projectStatus',
    initialState,
    reducers:{
        getProjectInfoRequest:state=>{
            state.loading = true;
        },
        getProjectStatusInfo:(state, action )=>{
            state.loading = false;
            state.data = action.payload;
            state.error = null;
        },
        getProjectStatusFailure:(state,action)=>{
            state.loading = false;
            state.data = null;
            state.error = action.payload;
        }
    }
});
export const {
    getProjectInfoRequest
} = projectStatusInfoSlice.actions

export function* getProjectInfoSaga(){
    try {
        const projectInfo = yield call(apiSession.getProjectStatus, sessionStorage.getItem("projectId"));
        yield put(projectStatusInfoSlice.actions.getProjectStatusInfo(projectInfo));
      } catch (error) {
        yield put(projectStatusInfoSlice.actions.getProjectStatusFailure(error.message));
      }
}
export function* watchGetProjectInfo(){
    yield takeLatest(getProjectInfoRequest.type, getProjectInfoSaga)
}
export default projectStatusInfoSlice; 