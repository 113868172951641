
import React, { useCallback, useRef, useState } from "react";
import Pagination from "react-js-pagination";

import ViewAsset from "../components/component/ViewAsset";
import AddAsset from "../components/component/AddAsset";

import useMountEffect from "./CustomHooks/useMountEffect";
import Snackbar from "./SE-Component/Snackbar";
import userScope from "./CustomHooks/userScope";

import APISESSION from "../api/ApiSession";
const apiSession = new APISESSION();

const LibraryManagementHook = () => {
  const [UserScope] = userScope();
  const [loading, setLoading] = useState(true);
  const [enableAddEdit, setEnableAddEdit] = useState(false);
  const [addStatus, setAddStatus] = useState(false);
  const [searchAssetInput, setSearchAssetInput] = useState("");
  const [filteredByManufacturer, setFilteredByManufacturer] = useState("");
  const [filteredByType, setFilteredByType] = useState(0);

  const [assetDetails, setAssetDetails] = useState({
    assets: [],
    assetsData: [],
    assetsType: [],
    assetAttributes: [],
  });
  const [assetInfo, setAssetInfo] = useState({
    allManufacturerList: [],
    manufacturerList: {},
  });
  const [itemSliceDetails, setItemSliceDetails] = useState({
    activePage: 1,
    itemSliceFirst: 0,
    itemSliceLast: 10,
    itemsPerPage: 10,
  });
  const [viewAssets, setViewAssets] = useState({});
  const [updatedAsset, setUpdatedAsset] = useState({});
  const [deletedAsset, setDeletedAsset] = useState({});
  const [snackBar, setSnackBar] = useState({ type: "", message: "" });

  const [popupStatus, setPopupStatus] = useState({
    addAssetPopupStatus: false,
    viewAssetPopupStatus: false,
    deleteConfirmPopUpStatus: false,
  });
  const [renderFunction, setRenderFunction] = useState({
    renderAssetDetails: false,
  });

  const snackBarRef = useRef(null);

  useMountEffect(() => {
    listAssets();
  }, []);

  useMountEffect(() => {
    let allManufacturerListNew = [];
    let manufacturerListAll = {};
    if (renderFunction.renderAssetDetails) {
      assetDetails.assetsType.forEach(
        assetTypeData => (manufacturerListAll[assetTypeData.type] = [])
      );

      assetDetails.assets.forEach(asset => {
        if (manufacturerListAll[asset.type.type]) {
          manufacturerListAll[asset.type.type].push(asset.manufacturer);
          manufacturerListAll[asset.type.type].sort();
        }

        if (!allManufacturerListNew.includes(asset.manufacturer)) {
          allManufacturerListNew = [
            ...allManufacturerListNew,
            asset.manufacturer,
          ];
        }
      });

      setAssetInfo(prevState => ({
        ...prevState,
        allManufacturerList: allManufacturerListNew.sort(),
        manufacturerList: manufacturerListAll,
      }));
      setEnableAddEdit(true);
    }
  }, [renderFunction]);
/* istanbul ignore next */ 
  const listAssets = () => {
    apiSession
      .getAssets()
      .then(response => {
        setAssetDetails(prevState => ({
          ...prevState,
          assets: response,
          assetsData: response,
        }));

        listAssetType();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const listAssetType = () => {
    apiSession
      .getAssetType()
      .then(response => {
        setAssetDetails(prevState => ({
          ...prevState,
          assetsType: response,
        }));

        const renderFunctionsNew = { renderAssetDetails: true };
        setRenderFunction(renderFunctionsNew);
      })
      .catch(() => {});
  };
/* istanbul ignore next */ 
  const handleAssetSearchInput = e => {
    const { value } = e.target;
    let search = e.target.value;
    setFilteredByType(0);
    setFilteredByManufacturer("");
    setSearchAssetInput(value);

    const filteredAssets = assetDetails.assetsData.filter(
      assetData =>
        assetData.assetInfo.toLowerCase().indexOf(search.toLowerCase()) !==
          -1 ||
        assetData.manufacturer.toLowerCase().indexOf(search.toLowerCase()) !==
          -1 ||
        assetData.communicationModel
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1 ||
        assetData.type.type.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );

    setAssetDetails(prevState => ({
      ...prevState,
      assets: filteredAssets,
    }));

    if (value === "") {
      resetAssetList();
    }
    setItemSliceDetails(prevState => ({
      ...prevState,
      activePage: 1,
      itemSliceFirst: 0,
      itemSliceLast: prevState.itemsPerPage,
    }));
  };

  const onChangefilteredByType = e => {
    const { value } = e.target;

    resetAssetList();
    setFilteredByManufacturer("");
    setFilteredByType(value);

    if (parseInt(value) !== 0) {
      let assetTypeId = e.target.value;
      const filteredAssets = assetDetails.assetsData.filter(
        assetData => parseInt(assetData.type.id) === parseInt(assetTypeId)
      );
      setAssetDetails(prevState => ({
        ...prevState,
        assets: filteredAssets,
      }));
    } else {
      setAssetDetails(prevState => ({
        ...prevState,
        assets: prevState.assetsData,
      }));
    }
  };

  const onChangefilteredByManufacturer = e => {
    const { value } = e.target;

    resetAssetList();
    setFilteredByType(0);
    setFilteredByManufacturer(value);

    if (value !== "") {
      let assetManufacturer = e.target.value;
      const filteredAssets = assetDetails.assetsData.filter(
        assetData => assetData.manufacturer === assetManufacturer
      );

      setAssetDetails(prevState => ({
        ...prevState,
        assets: filteredAssets,
      }));
    } else {
      setAssetDetails(prevState => ({
        ...prevState,
        assets: prevState.assetsData,
      }));
    }
  };

  const resetAssetList = () => {
    setAssetDetails(prevState => ({
      ...prevState,
      assets: prevState.assetsData,
    }));
    setSearchAssetInput("");
    setFilteredByManufacturer("");
    setFilteredByType(0);
    setItemSliceDetails(prevState => ({
      ...prevState,
      activePage: 1,
      itemSliceFirst: 0,
      itemSliceLast: prevState.itemsPerPage,
    }));
  };

  const addAsset = () => {
    setAddStatus(true);
    setPopupStatus(prevState => ({
      ...prevState,
      addAssetPopupStatus: true,
    }));
  };

  const getAssetDetailsView = assetId => {
    setLoading(true);
    apiSession
      .getAssetById(assetId)
      .then(response => {
        setViewAssets(response);
        setPopupStatus(prevState => ({
          ...prevState,
          viewAssetPopupStatus: true,
        }));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        callSnackbar("error", "Error in displaying asset details");
      });
  };

  const closeViewPopup = () =>
    setPopupStatus(prevState => ({
      ...prevState,
      viewAssetPopupStatus: false,
    }));

  const editAsset = assetData => {
    setLoading(true);
    apiSession
      .getAssetById(assetData.assetId)
      .then(response => {
        setUpdatedAsset(response);
        setAddStatus(false);
        setPopupStatus(prevState => ({
          ...prevState,
          addAssetPopupStatus: true,
        }));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        callSnackbar("error", "Error in displaying asset details");
      });
  };

  const onCloseAssetPopUp = () => {
    setPopupStatus(prevState => ({
      ...prevState,
      addAssetPopupStatus: false,
    }));
    setAddStatus(false);
  };

  const onUpdateAsset = data => {
    setLoading(true);
    data.attributes = [];
    let newAttribues = [];
    if (addStatus === true) {
      newAttribues = [...assetDetails.assetAttributes];
      //newAttribues.map((attribData, index) => {
        newAttribues.forEach((attribData, index) => {
        newAttribues[index].id = 0;
        newAttribues[index].assetId = 0;
       // return null;
      });
    } else {
      newAttribues = Object.assign([], updatedAsset.attributes);
    }
    data.status = "A";
    data.assetName = "";
    data.attributes = newAttribues;
    saveAsset(data);
  };

  const saveAsset = jsonBody => {
    apiSession
      .saveAssets(jsonBody)
      .then(response => {
        resetAssetList();
        addUpdateDataToAssetList(response, addStatus);
        if (addStatus === true) {
          callSnackbar("success", "Asset added successfully");
        } else {
          callSnackbar("success", "Asset updated successfully");
        }
        setAssetDetails(prevState => ({
          ...prevState,
          assetAttributes: [],
        }));
        setAddStatus(false);
        setLoading(false);
        setPopupStatus(prevState => ({
          ...prevState,
          addAssetPopupStatus: false,
        }));
      })
      .catch(() => {
        setAssetDetails(prevState => ({
          ...prevState,
          assetAttributes: [],
        }));
        setAddStatus(false);
        setLoading(false);
        setPopupStatus(prevState => ({
          ...prevState,
          addAssetPopupStatus: false,
        }));
        callSnackbar("error", "Error in adding asset");
      });
  };

  const addUpdateDataToAssetList = (data, status) => {
    if (status) {
      let newAssetList = [...assetDetails.assetsData];
      newAssetList.push(data);
      setAssetDetails(prevState => ({
        ...prevState,
        assets: newAssetList,
        assetsData: newAssetList,
      }));
    } else {
      let assetIndex = 0;
      let newAssetList = [...assetDetails.assetsData];
      //newAssetList.map((assetData, index) => {
        newAssetList.forEach((assetData, index) => {
        if (assetData.assetId === data.assetId) {
          assetIndex = index;
        }
       // return null;
      });
      if (assetIndex) {
        newAssetList[assetIndex] = data;
      }
      setAssetDetails(prevState => ({
        ...prevState,
        assets: newAssetList,
        assetsData: newAssetList,
      }));
    }
  };

  const getAssetDetailsAttribute = assetType => {
    let attribuetsNew = assetDetails.assetsData.filter(
      assetsData => assetsData.type.id === parseInt(assetType)
    );

    let indexAssetId = 0;
    if (parseInt(assetType) === 2) {
      indexAssetId = 1;
    } else if (parseInt(assetType) === 3) {
      attribuetsNew = assetDetails.assets.filter(
        assetsData => assetsData.manufacturer === "STANDARD BESS TEMPLATE"
      );
      indexAssetId = 0;
    }
    apiSession
      .getAssetById(
        attribuetsNew[indexAssetId]?.assetId || updatedAsset?.assetId
      )
      .then(response => {
        setAssetDetails(prevState => ({
          ...prevState,
          assetAttributes: response.attributes,
        }));
      })
      .catch(() => {
        setAssetDetails(prevState => ({
          ...prevState,
          assetAttributes: [],
        }));
      });
  };

  const deleteAsset = assetData => {
    setLoading(true);
    apiSession
      .getAssetById(assetData.assetId)
      .then(response => {
        setPopupStatus(prevState => ({
          ...prevState,
          deleteConfirmPopUpStatus: true,
        }));
        setDeletedAsset(response);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        callSnackbar("error", "Error in deletion of assets");
      });
  };

  const removeAssetConfirm = () => removeAssets(deletedAsset);

  const removeAssets = assetData => {
    assetData.status = "D";
    assetData.assetName = "";
    apiSession
      .saveAssets(assetData)
      .then(() => {
        resetAssetList();
        callSnackbar("success", "Asset deleted successfully");
        removeFromAssetList(assetData.assetId);
        closeDeletePopUp();
      })
      .catch(() => {
        closeDeletePopUp();
        callSnackbar("error", "Error in asset deletion");
      });
  };

  const removeFromAssetList = id => {
    let newAssetList = Object.assign([], assetDetails.assetsData);
    let newAssetListDeleted = newAssetList.filter(
      dataElement => dataElement.assetId !== id
    );
    setAssetDetails(prevState => ({
      ...prevState,
      assets: newAssetListDeleted,
      assetsData: newAssetListDeleted,
    }));
    resetAssetList();
  };

  const cancelAssetConfirm = () => closeDeletePopUp();

  const closeDeletePopUp = () => {
    setPopupStatus(prevState => ({
      ...prevState,
      deleteConfirmPopUpStatus: false,
    }));
    setDeletedAsset({});
  };

  const handlePageChange = pageNumber => {
    let itemLast = pageNumber * itemSliceDetails.itemsPerPage;
    let itemFirst = itemLast - itemSliceDetails.itemsPerPage;
    setItemSliceDetails(prevState => ({
      ...prevState,
      activePage: pageNumber,
      itemSliceLast: itemLast,
      itemSliceFirst: itemFirst,
    }));
  };

  const callSnackbar = useCallback((type, message) => {
    setSnackBar({ type, message });
    snackBarRef.current.open = true;
  }, []);

  return (
    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background">
      <se-loading loading={loading}></se-loading>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="form-row justify-content-end align-items-center">
            {searchAssetInput && (
              <span className="pointer" onClick={resetAssetList}>
                <se-icon className="cheatSheetIcon se-icon icon_button ">
                  data_refresh
                </se-icon>
              </span>
            )}
            &nbsp;&nbsp;
            <input
              className="form-control form-control-sm mr-sm-2 col-md-2 inline"
              type="text"
              name="searchAssetInput"
              placeholder="Search"
              aria-label="Search"
              autoComplete="none"
              value={searchAssetInput}
              onChange={handleAssetSearchInput}
            />
            <span>Asset Type : &nbsp;</span>
            <select
              className="form-control form-control-sm col-md-2 inline p-0"
              id="exampleFormControlSelect2"
              name="filteredByType"
              value={filteredByType}
              onChange={onChangefilteredByType}
            >
              <option value="0">Select Asset Type</option>
              {assetDetails.assetsType.map(assetType => (
                <option key={assetType.id} value={assetType.id}>
                  {assetType.type}
                </option>
              ))}
            </select>
            <span>&nbsp;&nbsp;Manufacturer : &nbsp;</span>
            <select
              className="form-control form-control-sm col-md-2 inline p-0"
              id="exampleFormControlSelect2"
              name="filteredByManufacturer"
              value={filteredByManufacturer}
              onChange={onChangefilteredByManufacturer}
            >
              <option value="">Select Manufacturer</option>
              {assetInfo.allManufacturerList.map(manufacturerName => (
                <option key={manufacturerName} value={manufacturerName}>
                  {manufacturerName}
                </option>
              ))}
            </select>
            {UserScope.access.saveAssets === true && (
              <se-button
                option="raised"
                size="small"
                color="primary"
                icon="action_add_flat"
                disabled={
                  UserScope.access.addUsers && enableAddEdit ? false : true
                }
                onClick={addAsset}
              >
                Add Asset
              </se-button>
            )}
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="table-responsive">
            <table className="table table-bordered mb-0">
              <thead>
                <tr>
                  <th width="13%">Manufacturer Name</th>
                  <th width="10%">Asset Type</th>
                  <th width="10%">Asset Category</th>
                  <th
                    width="10%"
                    className="assetListTable"
                    title="Asset Model"
                  >
                    Model
                  </th>
                  <th width="10%">Version</th>
                  <th width="10%">Date</th>
                  <th width="5%">Status</th>
                  {UserScope.access.getAsset === true ||
                  UserScope.access.saveAssets === true ? (
                    <th width="10%" className="text-center">
                      Action
                    </th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>
                {assetDetails.assets
                  .slice(
                    itemSliceDetails.itemSliceFirst,
                    itemSliceDetails.itemSliceLast
                  )
                  .map((assetData, keyIndex) => (
                    <tr key={assetData.assetId}>
                      <td
                        width="13%"
                        className="assetListTable"
                        title={assetData.manufacturer}
                      >
                        {assetData.manufacturer}
                      </td>
                      <td
                        width="10%"
                        className="assetListTable"
                        title={assetData.type.type}
                      >
                        {assetData.type.type}
                      </td>
                      <td
                        width="10%"
                        className="assetListTable"
                        title={assetData.assetInfo}
                      >
                        {" "}
                        {assetData.assetInfo}
                      </td>
                      <td
                        width="10%"
                        className="assetListTable"
                        title={assetData.communicationModel}
                      >
                        {" "}
                        {assetData.communicationModel}
                      </td>
                      <td
                        width="10%"
                        className="assetListTable"
                        title={assetData.version}
                      >
                        {" "}
                        {assetData.version}
                      </td>
                      <td
                        width="10%"
                        className="assetListTable"
                        title={assetData.validationDate}
                      >
                        {" "}
                        {assetData.creationDate}
                      </td>
                      <td
                        width="5%"
                        className="assetListTable"
                        title={
                          assetData.validated === true ? "Active" : "Inactive"
                        }
                      >
                        {" "}
                        {assetData.validated === true ? "Active" : "Inactive"}
                      </td>

                      {UserScope.access.getAsset === true ||
                      UserScope.access.saveAssets === true ? (
                        <td width="10%" className="align-middle text-center">
                          {UserScope.access.getAsset === true && (
                            <span className="pointer">
                              <se-icon
                                size="small"
                                color="primary"
                                title="View More"
                                onClick={() =>
                                  getAssetDetailsView(assetData.assetId)
                                }
                              >
                                body_eye
                              </se-icon>
                            </span>
                          )}

                          <span>&nbsp; &nbsp;</span>
                          {UserScope.access.saveAssets === true && (
                            <span className="pointer">
                              <se-icon
                                size="small"
                                title="Edit"
                                disabled={enableAddEdit === true ? false : true}
                                color="primary"
                                onClick={() => editAsset(assetData)}
                              >
                                action_editor
                              </se-icon>
                            </span>
                          )}
                          <span>&nbsp; &nbsp;</span>
                          {UserScope.access.saveAssets === true && (
                            <span className="pointer">
                              <se-icon
                                size="small"
                                title="Delete"
                                color="primary"
                                disabled={enableAddEdit === true ? false : true}
                                onClick={() => deleteAsset(assetData)}
                              >
                                action_delete
                              </se-icon>
                            </span>
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div
            className="col-sm-12 col-md-12 col-lg-12 col-xl-12 border listStyle mb-1 pt-2"
            style={assetDetails.assets.length ? { display: "none" } : {}}
          >
            <p className="text-center se-font-16px-bold">No Assets available</p>
          </div>

          {assetDetails.assets.length > itemSliceDetails.itemsPerPage && (
            <Pagination
              activePage={itemSliceDetails.activePage}
              itemsCountPerPage={itemSliceDetails.itemsPerPage}
              totalItemsCount={assetDetails.assets.length}
              pageRangeDisplayed={5}
              innerclassName="pagination pagination-asset"
              onChange={handlePageChange}
            />
          )}
          <span className="float-right">
            count : {assetDetails.assets.length}
          </span>
        </div>
      </div>

      <Snackbar snackBar={snackBar} ref={snackBarRef} />

      {popupStatus.viewAssetPopupStatus && (
        <ViewAsset
          viewAssets={viewAssets}
          viewAssetPopupStatus={popupStatus.viewAssetPopupStatus}
          closeViewPopup={closeViewPopup}
        />
      )}

      {popupStatus.addAssetPopupStatus && (
        <AddAsset
          addAssetPopupStatus={popupStatus.addAssetPopupStatus}
          addStatus={addStatus}
          assetsType={assetDetails.assetsType}
          manufacturerList={assetInfo.manufacturerList}
          updatedAsset={updatedAsset}
          onCloseAssetPopUp={onCloseAssetPopUp}
          onUpdateAsset={onUpdateAsset}
          getAssetDetailsAttribute={getAssetDetailsAttribute}
        />
      )}

      <se-dialog
        id="dialog-complex-delete-confirm"
        open={popupStatus.deleteConfirmPopUpStatus}
        can-backdrop="false"
      >
        <se-dialog-header>Confirmation</se-dialog-header>
        <se-dialog-content>
          <span className="se-font-14px">
            Do you confirm to delete the Asset ?
          </span>
        </se-dialog-content>
        <se-dialog-footer>
          <se-button onClick={cancelAssetConfirm}>Cancel</se-button>
          <se-button onClick={removeAssetConfirm}>OK</se-button>
        </se-dialog-footer>
      </se-dialog>
    </div>
  );
};

export default LibraryManagementHook;
